import { GenericAbortSignal } from 'axios';

// Manage a collection of AbortController's
// These can be used by api layer to cancel existing api calls
export default class CancelApi {
  abortStore: { [index: string]: AbortController } = {};

  getSignal(propertyName: string): GenericAbortSignal {
    let controller = this.abortStore[propertyName];
    if (controller === undefined) {
      controller = new AbortController();
      this.abortStore[propertyName] = controller;
    }
    return controller.signal;
  }
}
