interface DialogCheckboxProps {
  id: string;
  label: string;
  selectedOption: string | boolean | undefined | null; // id of selected option. Undefined/Null if not set and using default
  defaultOption: string | boolean;
  onSelect: (id: string, option: string) => void;
  disabled?: boolean;
}

export default function DialogCheckbox({
  id,
  label,
  selectedOption,
  defaultOption,
  onSelect,
  disabled,
}: DialogCheckboxProps) {
  const usingDefault = selectedOption == null;
  const activeOption = usingDefault ? defaultOption : selectedOption;
  const activeOptionAsBool = typeof activeOption === 'boolean' ? activeOption : activeOption.toLowerCase() === 'true';
  const inputStyle = usingDefault ? 'opacity-50' : '';
  const note = usingDefault ? `Using default (${activeOptionAsBool ? 'Yes' : 'No'})` : undefined;

  return (
    <div className={`flex items-center p-4 ${disabled && 'opacity-50'}`}>
      <div className="flex items-center">
        <input
          id={id}
          aria-describedby={`helper-${id}`}
          type="checkbox"
          checked={activeOptionAsBool}
          className={`w-4 h-4 text-blue-500 bg-gray-100 border-gray-300 focus:ring-blue-500 ${inputStyle}`}
          onChange={(e) => {
            if (!disabled) onSelect(id, e.target.checked ? 'true' : 'false');
          }}
          disabled={disabled}
        />
      </div>
      <div className="ml-2 text-sm">
        <div className="font-medium text-gray-900">{label}</div>
        {note && (
          <div id={`helper-${id}`} className="text-xs font-normal text-gray-500">
            {note}
          </div>
        )}
      </div>
    </div>
  );
}

DialogCheckbox.defaultProps = {
  disabled: false,
};
