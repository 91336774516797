/* eslint-disable no-bitwise */
export default function randGen(seed: number) {
  let aa = seed;
  return () => {
    let t = aa;
    aa += 0x6d2b79f5;
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}
