import {
  BuildingOffice2Icon,
  GlobeAsiaAustraliaIcon,
  LockClosedIcon,
  PresentationChartBarIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { SettingsLevel } from '../../../models/settings';
import { capitalizeWords } from '../../../utils/helpers';

interface SettingsLevelTabsButtonProps {
  label: string;
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  active: boolean;
  selected: boolean;
  locked: boolean;
  onClick: () => void;
}

function SettingsLevelTabsButton({ label, Icon, active, selected, locked, onClick }: SettingsLevelTabsButtonProps) {
  let buttonStyle = 'text-gray-200';
  let iconStyle = 'text-gray-200';
  if (active) {
    if (selected) {
      buttonStyle = 'text-gray-900 border-b-2 border-blue-600';
      iconStyle = 'text-gray-900';
    } else {
      buttonStyle = 'text-gray-400 hover:text-gray-500 hover:border-gray-400';
      iconStyle = 'text-gray-400 group-hover:text-gray-500';
    }
  }
  return (
    <li className="grow">
      <button
        type="button"
        className={`inline-flex items-center justify-center py-2 px-12 border-b-2 group ${buttonStyle}`}
        onClick={onClick}
      >
        <Icon className={`w-6 h-6 mr-2 ${iconStyle}`} />
        {label}
        {locked && <LockClosedIcon className="w-4 h-4 ml-2 text-gray-400" />}
      </button>
    </li>
  );
}

// ------------------------------------------------------------------------------

interface SettingsLevelTabsProps {
  showReportTab: boolean;
  activeTab: SettingsLevel;
  setActiveTab: (tab: SettingsLevel) => void;
  orgName: string | undefined;
  lockedLevels: SettingsLevel[];
}

export default function SettingsLevelTabs({
  showReportTab,
  activeTab,
  setActiveTab,
  orgName,
  lockedLevels,
}: SettingsLevelTabsProps) {
  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {showReportTab && (
          <SettingsLevelTabsButton
            label="Report Settings"
            Icon={PresentationChartBarIcon}
            active
            selected={activeTab === SettingsLevel.ReportLevel}
            locked={lockedLevels.includes(SettingsLevel.ReportLevel)}
            onClick={() => setActiveTab(SettingsLevel.ReportLevel)}
          />
        )}
        <SettingsLevelTabsButton
          label="User Settings"
          Icon={UserIcon}
          active
          selected={activeTab === SettingsLevel.UserLevel}
          locked={false}
          onClick={() => setActiveTab(SettingsLevel.UserLevel)}
        />
        <SettingsLevelTabsButton
          label={orgName ? `${orgName} Settings` : 'Organisation Settings'}
          Icon={BuildingOffice2Icon}
          active
          selected={activeTab === SettingsLevel.OrgLevel}
          locked={lockedLevels.includes(SettingsLevel.OrgLevel)}
          onClick={() => setActiveTab(SettingsLevel.OrgLevel)}
        />
        <SettingsLevelTabsButton
          label="Global Settings"
          Icon={GlobeAsiaAustraliaIcon}
          active
          selected={activeTab === SettingsLevel.GlobalLevel}
          locked={lockedLevels.includes(SettingsLevel.GlobalLevel)}
          onClick={() => setActiveTab(SettingsLevel.GlobalLevel)}
        />
      </ul>
    </div>
  );
}
