import { useContext, useEffect, useState } from 'react';
import { HandRaisedIcon } from '@heroicons/react/24/outline';
import NiceModal from '@ebay/nice-modal-react';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import AdminGrid4x6, { AdminGrid4x6SingleColumn, AdminGrid4x6TripleColumn } from './AdminGrid';
import { useAdminTtile } from '../../hooks/useTitle';
import { AdminRole } from '../../models/roleModels';
import RoleTable from './controls/RoleTable';
import EditRoleModal from '../modals/EditRoleDialog';
import AdminButton from './controls/AdminButton';
import getTagColours from '../../utils/tagcolours';
import ConfirmModal from '../modals/ConfirmModal';
import { RootContext } from '../../stores/storeProvidor';

const AdminRoles = observer(() => {
  useAdminTtile('Role and Permission Management', HandRaisedIcon);

  const ctx = useContext(RootContext);
  const { uiState } = ctx;
  const adminStore = ctx.getAdminStore();
  const [selectedRoles, setSelectedRoles] = useState<AdminRole[]>([]);

  const doingDisable = selectedRoles.some((u) => u.isActive);
  const rolesByOrg = _.groupBy(selectedRoles, (r) => r.organisation.name);
  const orgGroups = Object.keys(rolesByOrg).sort();

  useEffect(() => {
    adminStore.refresh();
  }, [adminStore]);

  const onClickEdit = () => {
    if (selectedRoles.length === 1) NiceModal.show(EditRoleModal, { roleId: selectedRoles[0].id });
  };

  const onClickEnableRole = () => {
    const warningMsg = doingDisable ? (
      <div className="mt-4 font-bold text-red-700">
        WARNING: This will disable all associated users for this role...
      </div>
    ) : null;
    NiceModal.show(ConfirmModal, {
      title: doingDisable ? 'Disable Role' : 'Enable Role',
      body: (
        <div>
          <div>{`Are you sure you want to ${doingDisable ? 'disable' : 'enable'} the selected role?`}</div>
          {warningMsg}
        </div>
      ),
      onYes: () => {
        const modRoles = selectedRoles.map((r) => ({ ...r, isActive: !doingDisable }));
        adminStore
          .updateRoles(modRoles)
          .then(() => {
            uiState.successAlert = 'The roles have been updated';
          })
          .catch((e) => {
            uiState.errorAlert = `Failed to update selected roles: ${e.message}`;
          });
      },
      onNo: () => {
        uiState.warningAlert = 'Action cancelled...';
      },
    });
  };

  const onClickCopy = () => {
    if (selectedRoles.length === 1) {
      adminStore.copyRole(selectedRoles[0]).then(() => {
        uiState.successAlert = 'The role has been duplicated';
      });
    }
  };

  const seletedList =
    selectedRoles.length === 0 ? (
      <div className="text-slate-400 text-sm py-2">Select roles in grid...</div>
    ) : (
      orgGroups.map((o) => {
        const users = rolesByOrg[o];
        return (
          <>
            <div key={o} className="w-full text-sm font-bold">
              {o}:
            </div>
            {users.map((r) => (
              <span
                key={r.id}
                className={`text-sm font-medium px-2 py-0.5 rounded-md m-1 grow-0 ${getTagColours(
                  r.organisation.name
                )}`}
              >
                {r.name}
              </span>
            ))}
          </>
        );
      })
    );

  return (
    <AdminGrid4x6 className="bg-blue-100">
      <AdminGrid4x6SingleColumn className="p-2 flex flex-col">
        <h2 className="text-center w-full p-2">Role Management</h2>
        <div className="grow flex flex-col">
          <div>Actions:</div>
          <div className="bg-slate-300 width-full rounded-xl p-2 mb-2">
            {selectedRoles.length === 0 ? (
              <div className="text-slate-400 text-sm py-2">Select roles in grid...</div>
            ) : (
              <>
                {selectedRoles.length === 1 ? <AdminButton text="Copy Role" onClick={onClickCopy} /> : null}
                {selectedRoles.length > 0 ? (
                  <AdminButton text={doingDisable ? 'Disable Role' : 'Enable Role'} onClick={onClickEnableRole} />
                ) : null}
                {selectedRoles.length === 1 ? <AdminButton text="Edit Role" onClick={onClickEdit} /> : null}
              </>
            )}
          </div>
          <div>Selected Roles:</div>
          <div className="bg-slate-300 width-full rounded-xl px-2 flex flex-row flex-wrap">{seletedList}</div>
        </div>
      </AdminGrid4x6SingleColumn>

      <AdminGrid4x6TripleColumn className="flex flex-col border-x border-gray-300 bg-slate-500">
        <div className="flex-1 overflow-hidden">
          <RoleTable onSelected={setSelectedRoles} />
        </div>
      </AdminGrid4x6TripleColumn>
    </AdminGrid4x6>
  );
});

export default AdminRoles;
