import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnDef, GroupColumnDef } from '@tanstack/react-table';
import { ArrowsPointingInIcon, ChartBarIcon, ChartPieIcon, PuzzlePieceIcon } from '@heroicons/react/24/outline';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { RootContext } from '../../../stores/storeProvidor';
import { RowDetails } from '../../../models/reportModel';
import BaseResultGrid, {
  CellMouseEvent,
  GridInfo,
  GridOptions,
  GridSection,
  getBaseCellStyle,
} from '../../controls/table/BaseResultGrid';
import populateTreeForHeader, { getRowsInHeaderGroup } from '../../../utils/headerTree';
import { useNumberFormat } from '../../../hooks/useFormat';
import useHeaderExtract from '../../../hooks/useHeaderExtraction';
import { ContextSeparator, useContextMenu } from '../../global/ContextMenu';
import { ContextMenuEntry } from '../../../stores/uiStateStore';
import { CellClickContext } from '../../../stores/activeReportStore';
import { SubReportType } from './subReports/SubReportBase';
import useTimeout from '../../../hooks/useTimeout';
import RowScrollOffsetStore, { BufferedRowResult } from '../../../stores/rowScrollOffsetStore';
import Spinner from '../../controls/Spinner';

function getInstumentationCellStyle(section: GridSection, isSelected: boolean, isNumeric: boolean) {
  let style = 'border border-gray-200 px-1 h-4 text-nowrap text-ellipsis text-xs';

  if (section === GridSection.RowHeader || section === GridSection.ColumnHeader) {
    style += ' font-light tracking-tight';
  }

  // Background colour + hover
  if (isSelected) {
    style += ' bg-blue-200 hover:bg-blue-300';
  } else {
    style += ' bg-blue-100 hover:bg-red-300';
  }

  // Text/Number formatting
  if (isNumeric) {
    // Align digits for all numbers
    style += ' text-center slashed-zero tabular-nums';
  }

  return style;
}

const defaultGridOptions: GridOptions = {
  debugGrid: false,
  showRowIdColumn: false,
};

type ColDef = ColumnDef<RowDetails, unknown>;

interface ResultGridProps {
  reportId: string | undefined;
  options: GridOptions | undefined;
}

const ReportResultGrid = observer(({ reportId, options }: ResultGridProps) => {
  const {
    activeReportStore,
    activeUserStore,
    selectionStore,
    rowScrollOffsetStore: rowQueueStore,
  } = useContext(RootContext);
  const { formatDatatype } = useNumberFormat(reportId);

  const scrollDebounceTimout = 100;

  const [currentPage] = activeReportStore.getCurrentPage(reportId);
  const refreshHash = activeReportStore.resultStatusUpdateHash;

  const showCellOnly = (context: unknown) => {
    const ctx = context as CellClickContext;
    return !activeReportStore.isGridCellSelected(ctx.reportId, ctx.page, ctx.cellColumn, ctx.cellRow);
  };

  const showHasSelection = (context: unknown) => {
    const ctx = context as CellClickContext;
    return activeReportStore.hasGridSelection(ctx.reportId, ctx.page);
  };

  const menu = [
    {
      label: 'Spot Drilldown for Cell',
      action: (action, context) =>
        activeReportStore.attachSubReport(SubReportType.SpotList, 'Spot-Drilldown', context as CellClickContext, false),
      Icon: ArrowsPointingInIcon,
      show: (context) => showCellOnly(context as CellClickContext),
    },
    {
      label: 'Drilldown Cell Only',
      action: (action, context) =>
        activeReportStore.attachSubReport(
          SubReportType.Drilldown,
          'Cell-Drilldown',
          context as CellClickContext,
          false
        ),
      Icon: ArrowsPointingInIcon,
      show: (context) => showCellOnly(context as CellClickContext),
    },
    {
      label: 'Drilldown Selection',
      action: (action, context) =>
        activeReportStore.attachSubReport(
          SubReportType.Drilldown,
          'Selection-Drilldown',
          context as CellClickContext,
          true
        ),
      Icon: ArrowsPointingInIcon,
      show: (context) => showHasSelection(context as CellClickContext),
    },
    ContextSeparator,
    {
      label: 'Chart',
      action: (action, context) =>
        activeReportStore.attachSubReport(SubReportType.Chart, 'Chart', context as CellClickContext, true),
      Icon: ChartPieIcon,
      show: (context) => showHasSelection(context as CellClickContext),
    },
    {
      label: 'Cost Distribution',
      action: (action, context) =>
        activeReportStore.attachSubReport(SubReportType.Chart, 'Cell-Distribution', context as CellClickContext, true),
      Icon: ChartBarIcon,
      show: true,
    },
    ContextSeparator,
    {
      label: 'Show Cell Spots',
      action: (action, context) =>
        activeReportStore.attachSubReport(SubReportType.SpotList, 'Spot-List', context as CellClickContext, false),
      Icon: PuzzlePieceIcon,
      show: (context) => showCellOnly(context as CellClickContext),
    },
  ] as ContextMenuEntry[];
  const { showMenu } = useContextMenu(menu);

  const extractHeaderLabel = useHeaderExtract(reportId);

  const datatypesByIndex = useMemo(() => {
    const reportSpec = activeReportStore.getLayoutResult(reportId)?.reportAsRun;
    if (reportSpec?.layout.cellDts && refreshHash !== undefined) {
      return reportSpec.layout.cellDts.map((c) => selectionStore.getDatatypeFromTag(c));
    }
    return [];
  }, [selectionStore, reportId, activeReportStore, refreshHash]);

  // Get data to render...
  const results = activeReportStore.getPageResult(reportId, currentPage);

  const rowData = results?.body ?? [];
  const rowOffset = results?.startRow ?? 0;

  // Virtualise the scrolling...
  const scrollerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: results?.totalRows ?? 0,
    getScrollElement: () => scrollerRef.current,
    estimateSize: () => 32, // TODO: How do we calc this? Row height
    overscan: 6,
    paddingEnd: 30, // TODO: How do we calc this? Header height
  });

  const onCellClick = useCallback(
    (
      section: GridSection,
      colindex: number | undefined,
      rowindex: number | undefined,
      clickEvent: CellMouseEvent,
      isContext: boolean
    ) => {
      if (reportId !== undefined) {
        if (isContext) {
          showMenu(clickEvent.pageX, clickEvent.pageY, {
            reportId,
            page: currentPage,
            section,
            cellColumn: colindex,
            cellRow: rowindex,
          } as CellClickContext);
        } else if (section === GridSection.RowHeader && rowindex !== undefined && colindex !== undefined) {
          // Select a row header - add each child row
          const grp = getRowsInHeaderGroup(results?.rowHeaderSpans, rowindex - rowOffset, -colindex - 1);
          let first = true;
          grp.forEach((g) => {
            activeReportStore.setGridSelection(
              reportId,
              currentPage,
              section,
              undefined, // Select whole row
              g + rowOffset,
              !first || clickEvent.shiftKey // After first we always append to selection
            );
            first = false;
          });
        } else {
          // Select single cell
          activeReportStore.setGridSelection(reportId, currentPage, section, colindex, rowindex, clickEvent.shiftKey);
        }
      }
    },
    [reportId, showMenu, currentPage, results?.rowHeaderSpans, rowOffset, activeReportStore]
  );

  const getCellStyle = useCallback(
    (section: GridSection, colindex: number | undefined, rowindex: number | undefined) => {
      let isSelected = activeReportStore.isGridCellSelected(reportId, currentPage, colindex, rowindex);

      // Is row header selected?
      if (section === GridSection.RowHeader && results && rowindex !== undefined) {
        if (colindex === undefined) {
          return getInstumentationCellStyle(section, isSelected, true);
        }
        if (colindex === 0) {
          isSelected = activeReportStore.isGridCellSelected(reportId, currentPage, undefined, rowindex);
        } else {
          const grp = getRowsInHeaderGroup(results?.rowHeaderSpans, rowindex - rowOffset, -colindex - 1);
          // Header-level is only selected if all children level rows are selected
          isSelected = grp.every((r) =>
            activeReportStore.isGridCellSelected(reportId, currentPage, undefined, r + rowOffset)
          );
        }
      }

      return getBaseCellStyle(section, isSelected, true);
    },
    [reportId, currentPage, activeReportStore, rowOffset, results]
  );

  // eslint-disable-next-line arrow-body-style
  const gridInfo = useMemo(() => {
    return {
      colHeaderLevels: results?.colHeader ? results?.colHeader?.[0]?.length : 0,
      rowHeaderLevels: results?.rowHeader ? results?.rowHeader?.[0]?.length : 0,
      mergeRowHeaders: true, // false for 'indent' style
      pageId: currentPage,
      onGridClick: onCellClick,
      getStyle: getCellStyle,
      rowBaseOffset: rowOffset,
      options: options ?? defaultGridOptions,
    } as GridInfo;
  }, [results?.colHeader, results?.rowHeader, currentPage, onCellClick, getCellStyle, rowOffset, options]);

  // Draw content of a cell. Value depends on 'index' along header and data-type
  const cellAccessor = useCallback(
    (row: RowDetails, cellIndex: number): string => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const rowId = row.id;
      if (row?.cells !== undefined && cellIndex < row?.cells.length) {
        const cell = row.cells[cellIndex];
        const dt = datatypesByIndex[cell.dtIndex];
        return formatDatatype(cell.value, dt?.divisor ?? 1, dt?.valueType ?? 'string');
      }
      return '?';
    },
    [datatypesByIndex, formatDatatype]
  );

  const getColumns: () => ColDef[] = useCallback(() => {
    if (results?.colHeader === undefined || results?.rowHeader === undefined) {
      return [] as ColDef[];
    }

    const rowHeaders = results?.rowHeader;
    const maxOffset = rowHeaders.length;

    // Create a tree from col defs...
    const cols = populateTreeForHeader<ColDef>(
      results.colHeader,
      (raw) => extractHeaderLabel(raw),
      (node) => node.header as string,
      (cellIndex, layerIndex, name, isLeaf, parent) => {
        let node: ColDef | undefined;
        if (isLeaf) {
          node = {
            id: `C${cellIndex}`,
            header: name,
            columns: [],
            accessorFn: (row) => {
              if (row.id - gridInfo.rowBaseOffset >= maxOffset) {
                if (options?.debugGrid) {
                  // eslint-disable-next-line no-console
                  console.log(
                    'ERROR: Cell accessorFn; row.id out of bounds: ',
                    row.id,
                    gridInfo.rowBaseOffset,
                    row.id - gridInfo.rowBaseOffset,
                    maxOffset
                  );
                }
                return '?';
              }

              return cellAccessor(row, cellIndex);
            },
            meta: { headerColIndex: undefined, colIndex: cellIndex, rowHeaderSpanFn: undefined },
          } as ColDef;
        } else {
          // Outer layers - only need display name
          node = {
            id: `H${cellIndex}`,
            header: name,
            columns: [],
          } as ColDef;
        }

        if (parent) {
          // Link new node to the parent
          const pnode = parent as GroupColumnDef<RowDetails, unknown>;
          if (pnode.columns) pnode.columns.push(node);
        }

        return node;
      }
    );

    // Add row header columns.
    // Note unshift() inserts at beginning - meaning '0' is the innermost...
    for (let i = 0; i < gridInfo.rowHeaderLevels; i += 1) {
      cols.unshift({
        id: `RH${i}`,
        accessorFn: (row: RowDetails) => {
          const rowIndex = row.id - gridInfo.rowBaseOffset;
          const raw = rowIndex < maxOffset ? rowHeaders[rowIndex][i] : undefined;
          if (options?.debugGrid) {
            if (raw === undefined) {
              // eslint-disable-next-line no-console
              console.log(
                'ERROR: Row Header accessorFn: got undefined',
                row.id,
                gridInfo.rowBaseOffset,
                rowIndex,
                maxOffset
              );
            }
          }
          return extractHeaderLabel(raw);
        },
        header: '',
        meta: {
          headerColIndex: i,
          colIndex: undefined,
          rowHeaderSpanFn: (row, level) => {
            if (results.rowHeaderSpans === undefined) return 1;
            if (level === 0 || row < 0 || results.rowHeaderSpans.length <= row) return 1;
            return results.rowHeaderSpans[row][level - 1];
          },
        },
      } as ColumnDef<RowDetails, unknown>);
    }

    if (gridInfo.options.showRowIdColumn) {
      cols.unshift({
        id: `Rid`,
        accessorFn: (row: RowDetails) => `${row.id}`,
        header: 'Row ID',
        meta: {
          headerColIndex: -100,
          colIndex: undefined,
          rowHeaderSpanFn: () => 1,
        },
      } as ColumnDef<RowDetails, unknown>);
    }

    return cols;
  }, [
    results?.colHeader,
    results?.rowHeader,
    results?.rowHeaderSpans,
    extractHeaderLabel,
    options?.debugGrid,
    cellAccessor,
    gridInfo.rowBaseOffset,
    gridInfo.rowHeaderLevels,
    gridInfo.options.showRowIdColumn,
  ]);

  // The hash changes when the selection/settings are modified - so causes a redraw on each change
  const selectionHash =
    activeReportStore.refreshGridSelectionHash(reportId) + activeUserStore.getSettingsHash(reportId);
  const qStoreId = RowScrollOffsetStore.getId(reportId ?? 'unknown', currentPage);

  const [showLastBlockProgress, setShowLastBlockProgress] = useState<boolean>(false);

  // Timer used to debounce row requests generated by scrolling.
  // We wait for timer to expire after last scroll event before loading data.
  const [retriggerRowLoadDebounceTimer] = useTimeout(async () => {
    const { from, to } = rowQueueStore.getCurrentRowRange(qStoreId);
    if (from !== undefined && to !== undefined && reportId !== undefined) {
      // Load the new row range...
      setShowLastBlockProgress(true);
      try {
        await activeReportStore.loadReportDetails(reportId, currentPage, from, false, false, false);

        if (options?.debugGrid) {
          const rs = rowQueueStore.getRowSpec(qStoreId);
          // eslint-disable-next-line no-console
          console.log('Loaded Rows:', from, to, rs?.startRow, rs?.rowCount, rs?.totalRows, rs?.requestedRow);
        }
      } finally {
        setShowLastBlockProgress(false);
      }
    }
  }, scrollDebounceTimout);

  // Map each virtual row to the data in memory
  const mapVirtualRow = useCallback(
    (vx: VirtualItem) => {
      // Index is the unique position of row in the overall dataset
      const rowIndex = vx.index;

      // Offset is the position of the row in the memory dataset
      const rowDataOffset = vx.index - (results?.startRow ?? 0);

      // Check if we need to load the row - if so trigger timer for load, and show progress indicator
      const isLoading = rowQueueStore.requestRowIndex(qStoreId, rowIndex, retriggerRowLoadDebounceTimer);
      return { rowIndex, rowDataOffset, isLoading };
    },
    [results?.startRow, qStoreId, retriggerRowLoadDebounceTimer, rowQueueStore]
  );

  const rowLoadHash =
    results === undefined
      ? '?'
      : `${results?.totalRows}-${results?.body?.length ?? 0}-` +
        `${results?.colHeader?.length ?? 0}-${results?.startRow ?? 0}-` +
        `${currentPage}`;
  const [loadHash, setLoadHash] = useState<string>('');
  useEffect(() => {
    if (rowLoadHash !== loadHash) {
      setLoadHash(rowLoadHash);
      // eslint-disable-next-line no-console
      if (options?.debugGrid) console.log('Setting limits for:', results?.startRow, rowData.length, rowLoadHash);
      rowQueueStore.setLimits(qStoreId, results as BufferedRowResult);
    }
  }, [rowQueueStore, rowLoadHash, loadHash, qStoreId, results, options?.debugGrid, rowData.length]);

  // Nothing to show? - parent component should be showing progress...
  if (results === undefined || results.colHeader === undefined) {
    return <span />;
  }

  return (
    <>
      <div className="p-1 overflow-scroll overscroll-auto w-full h-full" ref={scrollerRef}>
        {/*
        This <div> is dynamically sized by the virtualizer to scale the scroll bar
        (vertical only) for the TOTAL number of rows in the result set.
        */}
        <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
          <BaseResultGrid
            data={rowData}
            columnFn={getColumns}
            info={gridInfo}
            refreshHash={`${selectionHash}`}
            virtualContext={{ virtualizer }}
            mapVirtualRowToData={mapVirtualRow}
          />
        </div>
      </div>
      <Spinner
        enable={showLastBlockProgress}
        text="Fetching rows..."
        onClick={() => {
          setShowLastBlockProgress(false);
        }}
      />
    </>
  );
});

export default ReportResultGrid;
