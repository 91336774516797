import { useContext, useEffect } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import SelectTable from '../../controls/table/SelectTable';
import { AdminUser } from '../../../models/userModel';
import { RootContext } from '../../../stores/storeProvidor';

interface UserTableProps {
  onSelected: (users: AdminUser[]) => void;
}

const UserTable = observer(({ onSelected }: UserTableProps) => {
  const adminStore = useContext(RootContext).getAdminStore();

  useEffect(() => {
    adminStore.refreshUserList();
  }, [adminStore]);

  const renderCell = (info: CellContext<AdminUser, unknown>): string | JSX.Element | undefined => {
    const isActive =
      info.row.original.isActive && info.row.original.role.isActive && info.row.original.role.organisation.isActive;
    let activeCls = isActive ? 'text-gray-800' : 'text-gray-400';
    const strikeCols = ['title', 'firstName', 'lastName', 'email', 'phone'];
    if (!isActive && strikeCols.includes(info.column.id)) {
      activeCls += ' line-through';
    }
    if (info.column.id === 'email') {
      return (
        <a href={`mailto:${info.getValue() as string}`} className={activeCls}>
          {info.getValue() as string}
        </a>
      );
    }
    return <span className={activeCls}>{info.getValue() as string}</span>;
  };

  const columnDefs: ColumnDef<AdminUser>[] = [
    {
      accessorKey: 'title',
      cell: (info) => renderCell(info),
      header: 'Title',
      size: 30,
    },
    {
      accessorKey: 'firstName',
      cell: (info) => renderCell(info),
      header: 'First Name',
    },
    {
      accessorKey: 'lastName',
      cell: (info) => renderCell(info),
      header: 'Last Name',
    },
    {
      accessorKey: 'email',
      cell: (info) => renderCell(info),
      header: 'Email',
      size: 350,
    },
    {
      accessorKey: 'phone',
      cell: (info) => renderCell(info),
      header: 'Phone',
      size: 150,
    },
    {
      id: 'role',
      accessorFn: (row) => (row.role.isActive ? row.role.name : `${row.role.name}(Disabled)`),
      cell: (info) => renderCell(info),
      header: 'Role',
      size: 250,
    },
    {
      id: 'org',
      accessorFn: (row) =>
        row.role.organisation.isActive ? row.role.organisation.name : `${row.role.organisation.name}(Disabled)`,
      cell: (info) => renderCell(info),
      header: 'Company',
    },
    {
      id: 'active',
      accessorFn: (row) => (row.isActive ? 'Active' : 'Disabled'),
      cell: (info) => renderCell(info),
      header: 'Active',
    },
  ];

  return (
    <SelectTable
      data={adminStore.users ?? []}
      columns={columnDefs}
      onSelectionChanged={onSelected}
      sortEnabled
      filterEnabled
    />
  );
});

export default UserTable;
