import AuthStore from './authStore';
import AvailableReportStore from './availableReportStore';
import ActiveReportStore from './activeReportStore';
import ActiveSpotStore from './activeSpotStore';
import UiStateStore from './uiStateStore';
import ActiveUserStore from './activeUserStore';
import SelectionStore from './selectionStore';
import AdminStore from './adminStore';
import LayoutDragStore from './layoutDragStore';
import SelectionDragStore from './selectionDragStore';
import RowScrollOffsetStore from './rowScrollOffsetStore';

/**
 * Root Store Class
 *
 * This is the one store to pass around for ALL state data.
 * Add sub-stores as needed. Pass in RootStore into constructor for
 * cross-store communication.
 *
 * All store should be made 'observable'; this allows and changes to automatically
 * propagate to any 'observer' components.
 *
 * If you are using the stores in any methods/components ie. you have a call to:
 *
 *  {xxStore} = useContext(RootContext)
 *
 * then your method/component should be wrapped in 'observable()'. Mobx can then
 * work out dependencies and changes and update when needed.
 *
 * Store structure based on ideas at:
 * - https://mobx.js.org/defining-data-stores.html#combining-multiple-stores
 * - https://dev.to/shevchenkonik/react-typescript-mobx-4mei
 */
export default class RootStore {
  authStore: AuthStore;

  activeUserStore: ActiveUserStore;

  availReportStore: AvailableReportStore;

  activeReportStore: ActiveReportStore;

  activeSpotStore: ActiveSpotStore;

  uiState: UiStateStore;

  selectionStore: SelectionStore;

  layoutDragStore: LayoutDragStore;

  selectionDragStore: SelectionDragStore;

  rowScrollOffsetStore: RowScrollOffsetStore;

  private adminStore: AdminStore | undefined;

  constructor() {
    this.authStore = new AuthStore(this);
    this.activeUserStore = new ActiveUserStore(this);
    this.availReportStore = new AvailableReportStore(this);
    this.activeReportStore = new ActiveReportStore(this);
    this.activeSpotStore = new ActiveSpotStore(this);
    this.uiState = new UiStateStore(this);
    this.selectionStore = new SelectionStore(this);
    this.layoutDragStore = new LayoutDragStore(this);
    this.selectionDragStore = new SelectionDragStore(this);
    this.rowScrollOffsetStore = new RowScrollOffsetStore(this);

    // Load only if used. Most users won't need this.
    this.adminStore = undefined;
  }

  getAdminStore(): AdminStore {
    if (this.adminStore === undefined) {
      this.adminStore = new AdminStore(this);
    }
    return this.adminStore;
  }

  get storeRefreshHash(): string | undefined {
    return this.activeUserStore.userId === undefined
      ? undefined
      : `${this.activeUserStore.userId}:${this.activeUserStore.activeSchemaTag}:${this.activeUserStore.getLocale()}`;
  }

  // Load all default user-based data here for all App pages - note order imposed by promises; we need things to load in dependency order
  refreshAllStores(runOnSettings: () => void): void {
    if (this.activeUserStore.userId === undefined) return;
    this.activeUserStore.refreshSettings().then(() => {
      runOnSettings();
      this.selectionStore.refreshSchema(false).then(() => {
        this.selectionStore.refreshDataTypes();
        this.selectionStore.refreshBreakouts();
        this.availReportStore.loadAvailableReports();
        this.selectionStore.refreshFilterLevels().then(() => {
          this.selectionStore.loadDictCoreForAllDatasets();
        });
      });
    });
  }
}
