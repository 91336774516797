import { makeAutoObservable } from 'mobx';
import type RootStore from './rootStore';
import { IconObject } from '../utils/types';
import { AlertType } from '../Components/global/AlertMsg';

export interface ContextMenuEntry {
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>> | undefined;
  label: string;
  action: ((action: string, contextObj: unknown) => void) | undefined;
  show: boolean | ((contextObj: unknown) => boolean) | undefined;
}

export const ContextMenuIdTag = 'context-menu-';

export interface ContextMenuDefinition {
  xPos: number;
  yPos: number;
  ttlSec: number;
  entries: ContextMenuEntry[];
  contextObj: unknown;
}

export interface ActiveAlert {
  key: string;
  msg: string;
  title: string;
  type: AlertType;
}

class UiStateStore {
  private rootStore: RootStore;

  appTitle = '';

  appTitleIcon?: IconObject | undefined = undefined;

  adminTitle = '';

  adminTitleIcon?: IconObject | undefined = undefined;

  selectedListReportIds: string[] = [];

  selectedListFilter = '';

  activeAlerts: ActiveAlert[] = [];

  alertKeyCount = 0;

  // Note autosave counter is shared across all data stores
  autoSaveNeeded = new Map<string, number>();

  // The report we are currently viewing
  activeReport?: string | undefined = undefined;

  activeContextMenu: ContextMenuDefinition | undefined = undefined;

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this);
  }

  setAppTitle(text: string, icon?: IconObject | undefined) {
    this.appTitle = text;
    this.appTitleIcon = icon;
  }

  setAdminTitle(text: string, icon?: IconObject | undefined) {
    this.adminTitle = text;
    this.adminTitleIcon = icon;
  }

  setSelectedListReportIds(ids: string[]) {
    this.selectedListReportIds = ids;
  }

  clearReportFromSelectedList(id: string) {
    this.selectedListReportIds = this.selectedListReportIds.filter((r) => r !== id);
  }

  setSelectedListFilter(filter: string) {
    this.selectedListFilter = filter;
  }

  setActiveReport(reportId: string | undefined) {
    this.activeReport = reportId;
  }

  get haveAnyActiveReports() {
    return !!this.activeReport;
  }

  get getActiveReportId() {
    return this.activeReport ?? '<none>';
  }

  get alerts() {
    return this.activeAlerts;
  }

  getTopMenuTitle(isApp: boolean) {
    return isApp ? this.appTitle : this.adminTitle;
  }

  getTopMenuIcon(isApp: boolean) {
    return isApp ? this.appTitleIcon : this.adminTitleIcon;
  }

  addAlert(msg: string, title: string | undefined, type: AlertType) {
    // If same alert is currently active, then skip it...
    if (this.activeAlerts.filter((a) => a.msg === msg && a.title === title && a.type === type).length > 0) return;

    this.alertKeyCount += 1;
    this.activeAlerts.push({
      msg,
      title,
      key: `${this.alertKeyCount}`,
      type,
    } as ActiveAlert);
  }

  set infoAlert(msg: string) {
    this.addAlert(msg, 'Note', 'Info');
  }

  set successAlert(msg: string) {
    this.addAlert(msg, 'Success', 'Success');
  }

  set warningAlert(msg: string) {
    this.addAlert(msg, 'Warning', 'Warning');
  }

  set errorAlert(msg: string) {
    this.addAlert(msg, 'Error', 'Error');
  }

  set criticalAlert(msg: string) {
    this.addAlert(msg, 'ERROR', 'Critical');
  }

  closeAlert(key: string) {
    const index = this.activeAlerts.map((e) => e.key).indexOf(key);
    if (index > -1) {
      this.activeAlerts.splice(index, 1);
    }
  }

  triggerAutoSave(storeTag: string) {
    const next = this.autoSaveNeeded.get(storeTag) ?? 0;
    this.autoSaveNeeded.set(storeTag, next + 1);
  }

  clearAutoSave(storeTag: string) {
    this.autoSaveNeeded.set(storeTag, 0);
  }

  get autoSavesNeeded(): number {
    return Array.from(this.autoSaveNeeded.values()).reduce((a, b) => a + b, 0);
  }

  get contextMenu() {
    return this.activeContextMenu;
  }

  setContextMenu(xPos: number, yPos: number, entries: ContextMenuEntry[], contextObj: unknown, ttlSec = 0) {
    this.activeContextMenu = {
      xPos,
      yPos,
      ttlSec,
      entries,
      contextObj,
    };
  }

  isContextMenuActive(): boolean {
    return this.activeContextMenu !== undefined;
  }

  clearContextMenu() {
    this.activeContextMenu = undefined;
  }
}

export default UiStateStore;
