import { useContext } from 'react';
import { Hub } from 'react-signalr/lib/signalr/types';
import { SignalRContext } from './SignalProvider';
import { RootContext } from '../stores/storeProvidor';
import { AlertType } from '../Components/global/AlertMsg';

export interface NotifyHubActions extends Hub {
  callbacksName: SrEventNames;
  callbacks: SrCallbacks;
}

// Events from Sever to Client
// Must match names on server - see INotifyClient.cs
enum SrEventNames {
  message = 'Message', // New message
  statusUpdate = 'StatusUpdate', // Status change
}

type SrCallbacks = {
  [SrEventNames.message]: (value: SrPayloadMessage) => void;
  [SrEventNames.statusUpdate]: (value: SrStatusUpdate) => void;
};

type SrPayloadMessage = {
  title: string;
  body: string;
  sender: string;
  urgency: AlertType | 'Notification';
};

type SrStatusUpdate = {
  tag: string;
  id: string;
  message: string;
};

export default function Notifications() {
  const { uiState, activeReportStore, selectionStore } = useContext(RootContext);

  SignalRContext.useSignalREffect(
    SrEventNames.message,
    (msg) => {
      if (msg.urgency === 'Notification') {
        // uiState.updateNotifications();
        uiState.errorAlert = 'TODO: handle "Notification"';
      } else {
        uiState.addAlert(msg.body, msg.title, msg.urgency);
      }
    },
    []
  );

  SignalRContext.useSignalREffect(
    SrEventNames.statusUpdate,
    (status) => {
      if (status.tag === 'ReportRunStatus') {
        activeReportStore.updateRunStatus(status.id);
      } else if (status.tag === 'SchemaUpdateStatus') {
        selectionStore.refreshSchema(true);
      } else {
        uiState.addAlert(`Unknown status received!: ${status.message} for ${status.id}`, status.tag, 'Warning');
      }
    },
    []
  );

  return null;
}
