import { DateTime } from 'luxon';
import { BaseStorage, ItemWithId } from '../utils/dragUtils';
import { ListObjectWithTags } from './listbase';

export enum SelectionType {
  Media = 'media',
  Dictionary = 'dictionary',
  Period = 'period',
  Datatypes = 'datatypes',
  Meta = 'meta',
  UnknownType = 'unknown',
}

/*
 * Names must match the names in the back end
 * See SelectionNodeType enum
 */
export enum SelectionNodeType {
  Root = 'root',
  Folder = 'folder',
  LevelFilter = 'levelfilter',
  LevelEntry = 'levelentry',
  EntryWithId = 'entrywithid',
  FixedFolder = 'fixedfolder',
  DateRange = 'daterange',
  DateEntry = 'dateentry',
  Unknown = 'unknown',
}

// A source object that user can drag to a selection tree
// All dataset entries, datatypes, etc. are represented by this object
export interface SelectionSourceObj {
  sid: string;
  name: string;
}

// Node in a selection tree
export interface SelectionNode {
  nid: string;
  nodeType: SelectionNodeType;
  name: string;
  tag: string | undefined;
  children: SelectionNode[] | undefined;
  expand: boolean | undefined;
}

// Summary of a users selection; as retreived from api. Contains meta info only
export interface SelectionSummary extends ListObjectWithTags {
  selectionType: SelectionType;
  description: string | undefined;
  owner: string;
  access: string;
  isActive: boolean;
  modified: DateTime;
  isReadOnly: boolean;
}

// Full details of selection; as retreived from api. Contains meta info and node-tree.
export interface SelectionDetails extends SelectionSummary {
  embedded: boolean;
  treeRoot: SelectionNode | undefined;
  needsSaving: boolean; // Local only
}

// Drag drop items - wrap each node/source object - keep track of drag attributes
export interface SelectionDragObj extends ItemWithId {
  node: SelectionNode | undefined; // defined if this is a tree node
  parent: SelectionDragObj | undefined; // defined if this is a tree node (non root)
  // sourceObj: SelectionSourceObj | undefined; // defined if this is a source object
  depth: number;
  isDragging: boolean;
  isOver: boolean;
  insertBefore: boolean;
  insertAfter: boolean;
  insertInside: boolean;
}

// Drag store - Complete collection of drag/drop items that the user manipulates
export interface SelectionItemStorage extends BaseStorage<SelectionDragObj> {
  treeGroup: SelectionDragObj[];
}

export interface SelectionLevelUiState {
  searchText: string;
}

export interface PagedEntries {
  nextOffset: number;
  totalCount: number;
  filteredCount: number;
  filter: string;
  containsFilter: boolean;
  entries: string[];
}
