function clipToLength<T>(
  newArray: T[],
  start: number,
  end: number,
  clipStart: boolean,
  maxLength: number
): [T[], number, number] {
  if (newArray.length > maxLength) {
    if (clipStart) {
      return [newArray.slice(-maxLength), end - maxLength + 1, end];
    }
    return [newArray.slice(0, maxLength), start, start + maxLength - 1];
  }
  return [newArray, start, end];
}

export default function mergeArrayToStore<T>(
  storeArray: T[] | undefined,
  storeStart: number | undefined,
  newArray: T[],
  newStart: number,
  maxLengthToKeep: number
): [T[], number, number] {
  const newEnd = newStart + newArray.length - 1;

  // Store not setup? Return new array only
  if (storeArray === undefined || storeStart === undefined) {
    return [newArray, newStart, newEnd];
  }

  // New array is empty? Return existing store only
  const storeEnd = storeStart + storeArray.length - 1;
  if (newArray.length === 0) {
    return [storeArray, storeStart, storeEnd];
  }

  // New array is within store bounds? Update range and return store only
  if (newStart >= storeStart && newEnd <= storeEnd) {
    const startPart = newStart > storeStart ? storeArray.slice(0, newStart - storeStart) : [];
    const endPart = storeEnd > newEnd ? storeArray.slice(newEnd - storeEnd) : [];
    const na = [...startPart, ...newArray, ...endPart];
    return [na, storeStart, storeEnd];
  }

  // New array is before store bounds?
  if (newStart <= storeStart) {
    // Adjoining or overlapping store...
    if (newEnd >= storeStart - 1) {
      const startPart = newArray;
      const endPart = storeStart === newEnd + 1 ? storeArray : storeArray.slice(1 + newEnd - storeStart);
      const na = [...startPart, ...endPart];
      return clipToLength(na, newStart, storeEnd, false, maxLengthToKeep);
    }

    // Before store with gap
    const gapLength = storeStart - newEnd - 1;
    if (gapLength >= maxLengthToKeep) return clipToLength(newArray, newStart, newEnd, false, maxLengthToKeep);
    const gap = new Array(gapLength).fill(undefined);
    const na = [...newArray, ...gap, ...storeArray];
    return clipToLength(na, newStart, storeEnd, false, maxLengthToKeep);
  }

  // New array is after store bounds?
  if (newEnd >= storeEnd) {
    // Adjoining or overlapping store...
    if (newStart <= storeEnd - 1) {
      const startPart = storeEnd + 1 === newStart ? storeArray : storeArray.slice(0, newStart - storeEnd - 1);
      const endPart = newArray;
      const na = [...startPart, ...endPart];
      return clipToLength(na, storeStart, newEnd, true, maxLengthToKeep);
    }

    // After store with gap
    const gapLength = newStart - storeEnd - 1;
    if (gapLength >= maxLengthToKeep) return clipToLength(newArray, newStart, newEnd, true, maxLengthToKeep);
    const gap = new Array(gapLength).fill(undefined);
    const na = [...storeArray, ...gap, ...newArray];
    return clipToLength(na, storeStart, newEnd, true, maxLengthToKeep);
  }

  return [newArray, newStart, newEnd];
}

export function arrayMove<T>(arr: (T | undefined)[], old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k) {
      arr.push(undefined);
      k -= 1;
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}
