import { BreakoutObj } from '../../../../models/breakoutModels';
import DropAxis from './DropAxis';
import { COLS_GROUP, PAGES_GROUP, ROWS_GROUP } from '../../../../hooks/useLayoutDragging';
import { LayoutItemStorage } from '../../../../utils/dragUtils';

interface PreviewGridProps {
  itemStore: LayoutItemStorage<BreakoutObj>;
  className: string;
  active: BreakoutObj | undefined;
  removeBreakout: (id: string) => void;
}

export default function PreviewGrid({ itemStore, className, active, removeBreakout }: PreviewGridProps) {
  return (
    <div className={`${className} select-none`}>
      <div className="m-2 mb-1 text-lg font-bold text-center text-slate-500">Report Designer</div>
      <div className="grid grid-cols-2 grid-rows-2 h-full pb-10">
        {/* WTF: Why is pb-10 needed? Why does content overlap parent if this is reduced? Play with h-full above and in parent... */}
        <DropAxis
          collectionId={PAGES_GROUP}
          title="Page Axis"
          className="col-start-1 m-1"
          breakouts={itemStore.pagesGroup}
          active={active}
          removeBreakout={removeBreakout}
        />

        <DropAxis
          collectionId={COLS_GROUP}
          title="Column Axis"
          className="col-start-2 m-1"
          breakouts={itemStore.colsGroup}
          active={active}
          removeBreakout={removeBreakout}
        />

        <DropAxis
          collectionId={ROWS_GROUP}
          title="Row Axis"
          className="col-start-1 m-1"
          breakouts={itemStore.rowsGroup}
          active={active}
          removeBreakout={removeBreakout}
        />

        <div className="col-start-2 m-1 relative border border-gray-300 overflow-hidden">
          <div className="flex flex-wrap">
            {[...Array(60).keys()].map((i) => (
              <div key={`sk-${i}`} className="flex-auto h-2.5 bg-gray-200 rounded-full w-12 m-1" />
            ))}
          </div>
          <div className="absolute left-2 top-12 text-gray-400 text-center font-light tracking-widest rotate-45 z-10">
            Results Here
          </div>
        </div>
      </div>
    </div>
  );
}
