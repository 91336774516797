/* eslint-disable no-console */
import { useState } from 'react';
import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DragIdGroupSeparator,
  ItemWithId,
  SOURCE_GROUP,
  baseId,
  getNewItemForId,
  groupFromId,
} from '../utils/dragUtils';
import {
  SelectionDragObj,
  SelectionItemStorage,
  SelectionNode,
  SelectionNodeType,
  SelectionType,
} from '../models/selectionModels';
import { simpleHash } from '../utils/helpers';
import {
  copySourceNodeToTreeParent,
  createCopyOfNode,
  deleteTreeNode,
  findNodeById,
  findParentNodeById,
  getRootFromSelectionStore,
  moveTreeNodeToParent,
  newNode,
} from '../utils/selectionTreeUtils';

export const TREE_GROUP = 'treeGroup';

export interface SelectionTreeItemDragData {
  parentNode: SelectionNode | undefined;
  thisNode: SelectionNode | undefined;
  createNodeFn: (() => SelectionNode) | undefined;
}

// -------------------------------------------------------------------------

function clearAllDropHints(currentStore: SelectionItemStorage): SelectionItemStorage {
  return {
    ...currentStore,
    treeGroup: [
      ...currentStore.treeGroup.map(
        (x) =>
          ({
            ...x,
            insertAfter: false,
            insertBefore: false,
            insertInside: false,
          } as SelectionDragObj)
      ),
    ],
  } as SelectionItemStorage;
}

function setTreeDropHint(currentStore: SelectionItemStorage, over: SelectionTreeItemDragData): SelectionItemStorage {
  const overId = over.thisNode?.nid ?? '';
  const treeGroup = [...currentStore.treeGroup];
  const overItem = treeGroup.find((x) => baseId(x) === overId);
  if (overItem !== undefined) {
    // overItem.insertAfter = true;
    overItem.insertInside = true;
  }
  return {
    ...currentStore,
    treeGroup,
  } as SelectionItemStorage;
}

// Hash used to determine if tree has changed
function getStorageHash(store: SelectionItemStorage): number {
  const txt = store.treeGroup
    .map(
      (x) =>
        `${x.id},${x.insertBefore ? 1 : 0},${x.insertInside ? 1 : 0},${x.insertAfter ? 1 : 0},${x.depth},${
          x.parent?.id
        }`
    )
    .join('|');
  return simpleHash(txt);
}

// -------------------------------------------------------------------------
// useSelectionDragging hook
export default function useSelectionDragging(
  key: string,
  dragStore: SelectionItemStorage,
  selectionType: SelectionType,
  onLayoutChanged: (instance: string, store: SelectionItemStorage) => void,
  onDragValidityChange: (instance: string, valid: boolean) => void,
  showLogs = false
) {
  const [startDragStoreState, setStartDragStoreState] = useState<SelectionItemStorage | undefined>();
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const [previousKey, setPreviousKey] = useState<string | undefined>(undefined);
  const [snapshot, setSnapshot] = useState<number>(getStorageHash(dragStore));

  if (key !== previousKey) {
    // Reset all storage in key change
    setPreviousKey(key);
    setActiveId(undefined);
    setStartDragStoreState(undefined);
  }

  const updateLayout = (newStore: SelectionItemStorage) => {
    const newSnapshot = getStorageHash(newStore);
    if (snapshot !== newSnapshot) {
      if (showLogs) console.log(`Layout Snapshot Changed`);
      onLayoutChanged(key, newStore);
    }
    setSnapshot(newSnapshot);
  };

  const createUnknownItem = (id: string): SelectionDragObj => {
    const node = newNode(SelectionNodeType.Unknown, 'unknown');
    return {
      id: `${id}#${node.nid}`,
      node,
    } as SelectionDragObj;
  };

  // -------------------------------------------------------------------------
  // Dragging helpers
  const isOverTreeSpace = (event: DragOverEvent | DragEndEvent): boolean => event?.over?.id === TREE_GROUP;

  const isFromTree = (event: DragOverEvent | DragEndEvent): boolean =>
    groupFromId({ id: event.active.id } as ItemWithId) === TREE_GROUP;

  const isFromSource = (event: DragOverEvent | DragEndEvent): boolean =>
    groupFromId({ id: event.active.id } as ItemWithId) === SOURCE_GROUP;

  const treeDragSpec = (
    event: DragOverEvent | DragEndEvent
  ): { active: SelectionTreeItemDragData; over: SelectionTreeItemDragData } => ({
    active: event.active.data?.current as SelectionTreeItemDragData,
    over: event.over?.data?.current as SelectionTreeItemDragData,
  });

  // This determines the logic for if a drag is allowed or not
  const isDropAllowed = (
    store: SelectionItemStorage,
    activeDragNode: SelectionNode | undefined,
    dropOverNode: SelectionNode | undefined,
    addingNewNode: boolean
  ): boolean => {
    if (activeDragNode === undefined || dropOverNode === undefined) return false;
    const dragType = activeDragNode.nodeType;
    const overType = dropOverNode.nodeType;

    // Cannot drop on self
    if (activeDragNode.nid === dropOverNode.nid) return false;

    // Cannot drop a parent onto its own child
    if (findNodeById(dropOverNode.nid, activeDragNode) !== undefined) return false;

    if (addingNewNode) {
      // Cannot have same node id twice in the tree (drag/drop will screw up if we do!)
      const alreadyExists = findNodeById(activeDragNode.nid, getRootFromSelectionStore(store)) !== undefined;
      if (alreadyExists) return false;
    }

    // Datatypes - can only be dropped to fixed folders
    if (selectionType === SelectionType.Datatypes) {
      return overType === SelectionNodeType.FixedFolder;
    }

    // LevelEntry can only be dropped to LevelFilter of the same type
    if (dragType === SelectionNodeType.LevelEntry) {
      const dragParent = findParentNodeById(activeDragNode.nid, getRootFromSelectionStore(store));
      let overParent;
      if (overType === SelectionNodeType.LevelEntry) {
        overParent = findParentNodeById(dropOverNode.nid, getRootFromSelectionStore(store));
      }
      if (overType === SelectionNodeType.LevelFilter) {
        overParent = findNodeById(dropOverNode.nid, getRootFromSelectionStore(store));
      }

      console.log(dragParent?.tag);
      console.log(overParent?.tag);
      if (overParent && dragParent?.tag === overParent?.tag && dragParent?.nid !== overParent?.nid) {
        return true;
      }

      return false;
    }

    // Everything else can be dropped on Root or Folder
    if (overType === SelectionNodeType.Folder) return true;
    if (overType === SelectionNodeType.Root) return true;

    // No drop allowed
    return false;
  };

  // -------------------------------------------------------------------------

  const handleDragStart = ({ active }: DragStartEvent): void => {
    if (showLogs) {
      console.log(`-----------------------------------------`);
      console.log(`handleDragStart: ${active.id.toString()}`);
    }
    setActiveId(active.id.toString());
    setStartDragStoreState(dragStore);
    onDragValidityChange(key, true);
  };

  // -------------------------------------------------------------------------

  const handleDragCancel = (): void => {
    if (showLogs) {
      console.log(`handleDragCancel: `);
      console.log(`-----------------------------------------`);
    }
    setActiveId(undefined);
    setStartDragStoreState(undefined);
  };

  // -------------------------------------------------------------------------

  const handleDragOver = (event: DragOverEvent): void => {
    if (startDragStoreState === undefined) return;

    if (showLogs) console.log(`\tFrom ${event.active.id} --> To Group: ${event.over?.id}`);

    let currentStore = clearAllDropHints(startDragStoreState);

    // What are we dragging? And dropping where?
    const fromSource = isFromSource(event);
    const fromTree = isFromTree(event);
    if (!fromSource && !fromTree) return;
    const { active, over } = treeDragSpec(event);

    if (fromSource) {
      // Add new node from source to the tree
      let destinationNode = over?.thisNode;
      if (isOverTreeSpace(event)) {
        // Drag to empty tree space means add to root
        destinationNode = getRootFromSelectionStore(currentStore);
        if (showLogs) console.log(`handleDragOver - Source: Drag to root`);
      }

      if (isDropAllowed(currentStore, active?.thisNode, destinationNode, true)) {
        if (showLogs) console.log(`handleDragOver - Source: Drop OK:`);

        currentStore = setTreeDropHint(currentStore, over);
        onDragValidityChange(key, true);
      }
    }

    if (fromTree) {
      // Moving a tree node
      if (isOverTreeSpace(event)) {
        // Drag to empty space -> Delete node
        if (showLogs) console.log(`handleDragOver - TreeNode: Delete Node`);

        // ??
        currentStore = setTreeDropHint(currentStore, over);
        onDragValidityChange(key, true);
      } else if (isDropAllowed(currentStore, active?.thisNode, over?.thisNode, false)) {
        // Move within the tree
        if (showLogs) console.log(`handleDragOver - TreeNode: Drop OK`);
        currentStore = setTreeDropHint(currentStore, over);
        onDragValidityChange(key, true);
      }
    }

    updateLayout(currentStore);
  };

  // -------------------------------------------------------------------------

  const handleDragEnd = (event: DragEndEvent): void => {
    if (startDragStoreState === undefined) return;

    // Clear all hints and cursors
    onDragValidityChange(key, true);
    let currentStore = clearAllDropHints(startDragStoreState);

    if (showLogs) console.log(`\tFrom ${event.active.id} --> To Group: ${event.over?.id}`);

    // What are we dragging? And dropping where?
    const fromSource = isFromSource(event);
    const fromTree = isFromTree(event);
    if (!fromSource && !fromTree) return;
    const { active, over } = treeDragSpec(event);

    if (fromSource) {
      // Add new node from source to the tree
      let destinationNode = over?.thisNode;
      if (isOverTreeSpace(event)) {
        // Drag to empty tree space means add to root
        destinationNode = getRootFromSelectionStore(currentStore);
        if (showLogs) console.log(`handleDragEnd - Source: Drag to root`);
      }

      if (isDropAllowed(currentStore, active?.thisNode, destinationNode, true)) {
        if (showLogs) console.log(`handleDragEnd - Source: Drop OK:`);

        // Copy from source or create via drag data, then insert into to the tree
        let nodeToAdd;
        if (active.createNodeFn) nodeToAdd = active.createNodeFn();
        else nodeToAdd = active?.thisNode ? createCopyOfNode(active.thisNode) : undefined;
        currentStore = copySourceNodeToTreeParent(currentStore, nodeToAdd, destinationNode);
      }
    }

    if (fromTree) {
      // Moving a tree node
      if (isOverTreeSpace(event)) {
        // Drag to empty space -> Delete node
        if (showLogs) console.log(`handleDragEnd - TreeNode: Delete Node`);
        if (active.thisNode) currentStore = deleteTreeNode(currentStore, active.thisNode?.nid, selectionType);
      } else if (isDropAllowed(currentStore, active?.thisNode, over?.thisNode, false)) {
        // Move within the tree
        if (showLogs) console.log(`handleDragEnd - TreeNode: Drop OK`);

        let dropNode = over.thisNode;
        if (dropNode?.nodeType === SelectionNodeType.LevelEntry) {
          // Drop on a LevelEntry means drop on its parent
          dropNode = findParentNodeById(dropNode.nid, getRootFromSelectionStore(currentStore));
        }

        currentStore = moveTreeNodeToParent(currentStore, active.thisNode, dropNode);
      }
    }

    updateLayout(currentStore);
    setActiveId(undefined);
  };

  // -------------------------------------------------------------------------
  // Make a copy of the active drag object, based on its id.
  // Object has to exist in the source container, so it can be copied.
  // Object returned here is used to render the drag overlay - i.e. object at the mouse pointer during a drag.
  const getActiveDragObj = () => {
    if (activeId) {
      const [bid, origContainer] = activeId.split(DragIdGroupSeparator);
      const objectSource = dragStore[origContainer as keyof SelectionItemStorage];
      if (bid && objectSource !== undefined && origContainer)
        return getNewItemForId(objectSource, bid, origContainer, createUnknownItem);
    }
    return undefined;
  };

  // -------------------------------------------------------------------------

  const removeObjById = (id: string) => {
    // updateLayout(removeItemById(itemGroups, id));
  };

  // -------------------------------------------------------------------------

  return {
    handleDragStart,
    handleDragCancel,
    handleDragOver,
    handleDragEnd,
    getActiveDragObj,
    removeObjById,
  };
}
