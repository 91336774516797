import { PropsWithChildren } from 'react';

export function ListHeading({ children }: PropsWithChildren) {
  return <div className="basis-1/3 text-sm font-bold h-6 text-right px-4">{children}:</div>;
}

export function ListData({ children }: PropsWithChildren) {
  return (
    <>
      <div className="grow">{children}</div>
      <div className="basis-full" /> {/* This is a spacer to force a new row */}
    </>
  );
}

export function AdminPanel({ children }: PropsWithChildren) {
  return (
    <div className="bg-blue-100 text-sm text-gray-700 mx-6 mb-2 p-4 rounded-lg border border-gray-600 flex flex-row flex-wrap select-none">
      {children}
    </div>
  );
}
