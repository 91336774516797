import {
  ChatBubbleOvalLeftEllipsisIcon,
  Cog6ToothIcon,
  PlusCircleIcon,
  LinkIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { SelectionNodeType } from '../../../../../models/selectionModels';
import SelectionToolButton from './SelectionToolButton';
import { TreeSelection } from '../../../../../stores/selectionDragStore';
import useSelectionDialogs from '../../../../../hooks/useSelectionDialogs';

interface SelectionControlsProps {
  updateKey: string;
  selected: TreeSelection[];
}

export default function SelectionTreeControls({ updateKey, selected }: SelectionControlsProps) {
  const hasSelected = selected.length > 0;
  const { deleteNode, editNodeSettings, renameNode, addNewNode, linkNode } = useSelectionDialogs(updateKey);

  const doAddNewNode = () => {
    const sel =
      selected && selected.length > 0 ? selected[0] : ({ id: 'root', type: SelectionNodeType.Root } as TreeSelection);
    addNewNode(sel.id);
  };

  const doDeleteNode = () => {
    const sel = selected && selected.length > 0 ? selected[0] : undefined;
    deleteNode(sel?.id ?? 'root');
  };

  const doEditNodeSettings = () => {
    const sel = selected && selected.length > 0 ? selected[0] : undefined;
    editNodeSettings(sel?.id ?? 'root');
  };

  const doRenameNode = () => {
    const sel = selected && selected.length > 0 ? selected[0] : undefined;
    renameNode(sel?.id ?? 'root');
  };
  const renameOk =
    selected &&
    selected.length === 1 &&
    (selected[0].type === SelectionNodeType.Root ||
      selected[0].type === SelectionNodeType.Folder ||
      selected[0].type === SelectionNodeType.LevelFilter);

  const doLinkNode = () => {
    const sel = selected && selected.length > 0 ? selected[0] : undefined;
    linkNode(sel?.id ?? 'root');
  };

  return (
    <div className="w-full h-full p-1">
      <div className="w-full flex flex-row justify-center">
        <SelectionToolButton Icon={PlusCircleIcon} action={doAddNewNode} enabled />
        <SelectionToolButton Icon={ChatBubbleOvalLeftEllipsisIcon} action={doRenameNode} enabled={renameOk} />
        <SelectionToolButton Icon={Cog6ToothIcon} action={doEditNodeSettings} enabled={hasSelected} />
        <SelectionToolButton Icon={LinkIcon} action={doLinkNode} enabled={hasSelected} />
        <SelectionToolButton Icon={TrashIcon} action={doDeleteNode} enabled />
      </div>
    </div>
  );
}
