import { useContext } from 'react';
import { RootContext } from '../../stores/storeProvidor';
import useClipboard from '../../hooks/useClipboard';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  title?: string | undefined;
  onClick?: ((text: string) => void) | undefined;
}

function CopyToClipboardButton({ textToCopy, title, onClick }: CopyToClipboardButtonProps) {
  const { uiState } = useContext(RootContext);
  const copyToClip = useClipboard();

  return (
    <button
      className="bg-green-200 text-gray-800 rounded-md px-2 text-xs hover:bg-green-300"
      type="button"
      onClick={() => {
        copyToClip(textToCopy);
        uiState.successAlert = 'Copied to clipboard...';
        onClick?.(textToCopy);
      }}
    >
      {title ?? 'Copy'}
    </button>
  );
}

CopyToClipboardButton.defaultProps = {
  title: undefined,
  onClick: undefined,
};

export default CopyToClipboardButton;
