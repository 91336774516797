import { SelectionTreeItemDragData } from '../../../../../hooks/useSelectionDragging';
import { Datatype } from '../../../../../models/datatypes';
import { SelectionNodeType } from '../../../../../models/selectionModels';
import { baseIdToSourceGroupId, newNode } from '../../../../../utils/selectionTreeUtils';
import { Draggable } from '../../../../controls/dnd';

interface DatatypeSelectListProps {
  items: Datatype[];
}

export default function DatatypeSelectList({ items }: DatatypeSelectListProps) {
  return (
    <div className="h-full w-full bg-gray-200 p-2 flex flex-col gap-1">
      {items?.map((d) => (
        <Draggable
          key={d.tag}
          id={baseIdToSourceGroupId(d.tag)}
          data={
            {
              parentNode: undefined,
              thisNode: newNode(SelectionNodeType.EntryWithId, d.longName, d.tag, d.tag),
            } as SelectionTreeItemDragData
          }
          useOverlay
        >
          <div key={d.tag} className="bg-white border border-gray-400 rounded-xl hover:bg-green-100">
            <div className="px-4 py-2 text-gray-800">
              {d.longName} ({d.shortName})
            </div>
          </div>
        </Draggable>
      ))}
    </div>
  );
}
