import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { ReactElement } from 'react';

interface ConfirmModalProps {
  title: string;
  body: string | ReactElement;
  onYes: () => void;
  onNo?: () => void;
  yesButtonText?: string;
  noButtonText?: string;
}

const ConfirmModal = NiceModal.create(
  ({ title, body, onYes, onNo, yesButtonText, noButtonText }: ConfirmModalProps) => {
    const modal = useModal();

    const onConfirm = () => {
      modal.remove();
      onYes();
    };

    const onCancel = () => {
      modal.remove();
      if (onNo) onNo();
    };

    return (
      <Modal show onClose={onCancel} size="sm" popup>
        <Modal.Body>
          <h3 className="pt-6 pb-2">{title}</h3>
          <div>{body}</div>
          <div className="flex justify-center gap-4 mt-4">
            <Button onClick={onConfirm}>{yesButtonText}</Button>
            <Button color="gray" onClick={onCancel}>
              {noButtonText}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

ConfirmModal.defaultProps = {
  onNo: undefined,
  yesButtonText: 'Yes',
  noButtonText: 'No',
};

export default ConfirmModal;
