import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext } from 'react';
import { RootContext } from '../../stores/storeProvidor';

const AccountModal = NiceModal.create(() => {
  const { uiState } = useContext(RootContext);
  const modal = useModal();

  const onOk = () => {
    modal.remove();
    uiState.successAlert = 'Updates saved';
  };

  const onCancel = () => {
    modal.remove();
  };

  return (
    <Modal show onClose={onCancel}>
      <Modal.Header>Account</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p>Account</p>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Debitis architecto aliquam incidunt. Eligendi
            fugit fugiat voluptatibus assumenda et vel voluptates earum quis illo a beatae, nostrum inventore harum cum
            dolor!
          </p>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Amet reiciendis vitae, facere velit ipsam, ratione
            possimus voluptates voluptatum cumque dolores ea error! Voluptas provident culpa vitae, quam atque saepe.
            Et?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onOk}>Ok</Button>
        <Button color="gray" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default AccountModal;
