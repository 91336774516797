/* eslint-disable react/no-unescaped-entities */
import { Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

function AboutPage() {
  const { t } = useTranslation(['home']);
  return (
    <>
      <h1 className="py-6 text-3xl">{t('about.title', { apptitle: import.meta.env.VITE_APP_TITLE })}</h1>
      <Card>
        <h5 className="text-2xl font-bold tracking-tight text-primary">{t('about.subtitle')}</h5>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {t('about.para_01', { apptitle: import.meta.env.VITE_APP_TITLE })}
        </p>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {t('about.para_02', { apptitle: import.meta.env.VITE_APP_TITLE })}
        </p>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {t('about.para_03', { apptitle: import.meta.env.VITE_APP_TITLE })}
        </p>
      </Card>
    </>
  );
}

export default AboutPage;
