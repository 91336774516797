import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useRouteError, Link } from 'react-router-dom';

type RouteError = {
  statusText?: string;
  message?: string;
};

export default function ErrorPage() {
  const error = useRouteError() as RouteError;

  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <div id="error-page" className="flex justify-center align-middle items-center text-center min-h-screen">
      <div className="rounded-lg p-8 bg-white">
        <div className="m-1 flex flex-col">
          <h1 className="text-primary p-2 mb-4 font-black">Oops! Sorry, about that 🙄</h1>
          <div>An unexpected error has just occurred...</div>
          <div className="italic mt-5 text-gray-400">
            &quot;{error?.statusText || error?.message || 'Unknown Error?'}&quot;
          </div>
          <div className="h-18">
            <div className="pt-6">
              <Link className="bg-primary text-gray-100 rounded-xl p-2" to="/">
                Back to home page
                <ArrowUturnLeftIcon className="ml-4 w-4 inline" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
