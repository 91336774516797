import { Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect } from 'react';
import { RootContext } from '../../../stores/storeProvidor';
import AppGrid4x6 from '../../app/AppGrid';
import DictSelectContainer from '../../controls/dictbox/DictSelectContainer';
import SelectionTree from '../../app/report/Selection/tree/SelectionTree';
import { SelectionType } from '../../../models/selectionModels';
import { createStorageKey } from '../../../stores/selectionDragStore';

// -----------------------------------------------------

function DictBoxChecks() {
  const { uiState, selectionDragStore } = useContext(RootContext);
  useEffect(() => {
    uiState.setAdminTitle('DictBox Tests', Square3Stack3DIcon);
  }, [uiState]);

  const reportId = 'QAChecks';

  const updateKey = createStorageKey(reportId, false, SelectionType.Dictionary);
  const dragStore = selectionDragStore.getSelectionDragItems(updateKey);
  const selected = selectionDragStore.getSelectedTreeItems(updateKey);
  const selType = SelectionType.Dictionary; // or SelectionType.Media

  return (
    <AppGrid4x6>
      <div className="col-start-0 col-span-1 row-start-2 row-end-6 m-2">
        <h1>DictBox Tests</h1>
        <p>Verify Dict Boxes:</p>
        <ul className="ml-4 list-disc list-inside">
          <li>Click on level buttons will enable/disable</li>
          <li>Level buttons can be dragged to reorder</li>
          <li>Double click on level button will open that level and make it active (red)</li>
          <li>Within a box; click to select an item</li>
          <li>
            Within a box; Double click - select item, then open up the next active level. Items are filtered by layer
            above
          </li>
          <li>Clear button must reset all selections and levels</li>
          <li>Must handle 100K+ rows within each box</li>
        </ul>
      </div>
      <div className="col-start-2 col-span-2 row-start-2 row-end-7 m-2 border border-gray-500 bg-white flex">
        {/* Need flex in parent to fill pane */}
        <DictSelectContainer selType={selType} isEmbedded reportId={reportId} />
      </div>
      <div className="col-start-4 col-span-2 row-start-3 row-end-5 m-2 border bg-white">
        <SelectionTree updateKey={reportId} store={dragStore} selected={selected} />
      </div>
    </AppGrid4x6>
  );
}

export default DictBoxChecks;
