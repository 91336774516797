import { DictEntry } from '../../../models/dictSelection';
import IconMultistateCheckbox, { CheckboxState } from '../../icons/multistateCheckbox';

type DictBoxRowProps = {
  entry: DictEntry;
  index: number;
  highlight: boolean;
  onSelect: (index: number, selected: boolean) => void;
  onDoubleClick: (index: number) => void;
  onHighlightRow: (index: number, selected: boolean, ctrl: boolean, shift: boolean) => void;
  yShift: number;
};

function DictBoxRow({
  entry,
  index,
  highlight,
  onSelect,
  onDoubleClick,
  onHighlightRow,
  yShift, // This is the virtualised row shift
}: DictBoxRowProps) {
  const state = entry.selected ? CheckboxState.Checked : CheckboxState.Unchecked;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`${highlight && 'bg-teal-100'} ${entry.selected && 'font-bold'} text-gray-500`}
      onClick={(e) => onHighlightRow(index, !highlight, e.ctrlKey, e.shiftKey)}
      onKeyUp={(e) => onHighlightRow(index, !highlight, e.ctrlKey, e.shiftKey)}
      onDoubleClick={() => onDoubleClick(index)}
      style={{
        transform: `translateY(${yShift}px)`,
      }}
    >
      <span
        role="checkbox"
        aria-checked={entry.selected}
        tabIndex={index}
        className="mx-1 focus:outline-none"
        onKeyUp={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
          onSelect(index, !entry.selected);
        }}
        onDoubleClick={() => onDoubleClick(index)}
      >
        <IconMultistateCheckbox checked={state} />
      </span>
      {entry.name}
    </div>
  );
}

export default DictBoxRow;
