import { CommandLineIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import AdminGrid4x6 from './AdminGrid';
import { useAdminTtile } from '../../hooks/useTitle';
import AdminSchemaSummaryList from './controls/AdminSchemaSummary';
import { RootContext } from '../../stores/storeProvidor';
import AdminSystemSummary from './controls/AdminSystemSummary';
import AdminUserActivity from './controls/AdminUserActivity';
import AdminReportActivity from './controls/AdminReportActivity';

const AdminDash = observer(() => {
  const adminStore = useContext(RootContext).getAdminStore();
  useAdminTtile('Administration Dashboard', CommandLineIcon);

  useEffect(() => {
    // Load everything on first use
    adminStore.refreshSchema();
    adminStore.refreshSystemSummary();
    adminStore.refreshReportActivity();
    adminStore.refreshUserActivity();
  }, [adminStore]);

  return (
    <AdminGrid4x6 className="bg-slate-400 text-gray-500 gap-2">
      <div className="col-span-2 row-span-2 ml-2 rounded-xl bg-gray-200">
        <div className="text-xl font-extrabold m-1 text-center pt-2">System Summary</div>
        <AdminSystemSummary
          summary={adminStore.getSystemSummary()}
          doRefresh={() => {
            adminStore.refreshSystemSummary();
          }}
        />
      </div>
      <div className="col-span-2 row-span-2 mr-2 rounded-xl bg-gray-200">
        <div className="text-xl font-extrabold m-1 text-center pt-2">Top Active Users</div>
        <AdminUserActivity
          activity={adminStore.getUserActivity()}
          doRefresh={() => {
            adminStore.refreshUserActivity();
          }}
        />
      </div>
      <div className="col-span-2 row-span-3 ml-2 mb-2 rounded-xl bg-gray-200">
        <div className="text-xl font-extrabold m-1 text-center pt-2">Data Sources</div>
        <AdminSchemaSummaryList
          schema={adminStore.getAllSchema()}
          doRefresh={() => {
            adminStore.refreshSchema();
          }}
        />
      </div>
      <div className="col-span-2 row-span-3 mr-2 mb-2 rounded-xl bg-gray-200">
        <div className="text-xl font-extrabold m-1 text-center pt-2">Report Activity</div>
        <AdminReportActivity
          activity={adminStore.getReportActivity()}
          doRefresh={() => {
            adminStore.refreshReportActivity();
          }}
        />
      </div>
    </AdminGrid4x6>
  );
});

export default AdminDash;
