import { XCircleIcon } from '@heroicons/react/24/outline';

interface SearchBarProps {
  className?: string;
  searchText: string;
  setsearchText: (text: string) => void;
}

export default function SearchBar({ className, searchText, setsearchText }: SearchBarProps) {
  return (
    <div className={`flex z-0 w-full pb-2 group ${className}`}>
      <input
        type="text"
        value={searchText}
        className="grow text-sm bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary"
        placeholder="Search reports..."
        onChange={(e) => {
          setsearchText(e.target.value);
        }}
      />
      <button
        type="button"
        disabled={searchText.length === 0}
        className={`w-6 pt-1 ${searchText.length === 0 ? 'text-gray-300' : ''}`}
        onClick={() => {
          setsearchText('');
        }}
      >
        <XCircleIcon className="h-8 w-8 pr-1" />
      </button>
    </div>
  );
}

SearchBar.defaultProps = {
  className: 'text-gray-500',
};
