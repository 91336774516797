import SchemaAPI from '../api/schemaApi';

class SchemaService {
  api: SchemaAPI;

  constructor() {
    this.api = new SchemaAPI();
  }
}

// Export only one instance of sevice (singleton)
const schemaService = new SchemaService();

export default schemaService;
