import { useContext, useEffect } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import SelectTable from '../../controls/table/SelectTable';
import { RootContext } from '../../../stores/storeProvidor';
import { AdminRole } from '../../../models/roleModels';

interface RoleTableProps {
  onSelected: (users: AdminRole[]) => void;
}

const RoleTable = observer(({ onSelected }: RoleTableProps) => {
  const adminStore = useContext(RootContext).getAdminStore();

  useEffect(() => {
    adminStore.refresh();
  }, [adminStore]);

  const renderCell = (info: CellContext<AdminRole, unknown>): string | JSX.Element | undefined => {
    const isActive = info.row.original.isActive && info.row.original.organisation.isActive;
    let activeCls = isActive ? 'text-gray-800' : 'text-gray-400';
    const strikeCols = ['name'];
    if (!isActive && strikeCols.includes(info.column.id)) {
      activeCls += ' line-through';
    }

    return <span className={activeCls}>{info.getValue() as string}</span>;
  };

  const columnDefs: ColumnDef<AdminRole>[] = [
    {
      accessorKey: 'name',
      cell: (info) => renderCell(info),
      header: 'Name',
      size: 300,
    },
    {
      accessorKey: 'description',
      cell: (info) => renderCell(info),
      header: 'Description',
      size: 400,
    },
    {
      id: 'org',
      accessorFn: (row) => (row.organisation.isActive ? row.organisation.name : `${row.organisation.name}(Disabled)`),
      cell: (info) => renderCell(info),
      header: 'Organisation',
      size: 100,
    },
    {
      id: 'active',
      accessorFn: (row) => (row.isActive ? 'Active' : 'Disabled'),
      cell: (info) => renderCell(info),
      header: 'Active',
    },
  ];

  return (
    <SelectTable
      data={adminStore.roles ?? []}
      columns={columnDefs}
      onSelectionChanged={onSelected}
      sortEnabled
      filterEnabled
    />
  );
});

export default RoleTable;
