import React from 'react';

interface AdminButtonProps {
  text: string;
  onClick: () => void;
  className?: string;
}

function AdminButton({ onClick, text, className }: AdminButtonProps) {
  return (
    <button
      type="button"
      className={`${className} mx-1 my-0.5 font-normal bg-green-300 border border-gray-400 rounded px-2 hover:bg-green-400`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

AdminButton.defaultProps = {
  className: '',
};

export default AdminButton;
