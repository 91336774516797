import { useContext, useMemo } from 'react';
import { RootContext } from '../../../../stores/storeProvidor';
import ReportPageSelect from '../ReportPageSelect';

interface SubReportInfoPanelProps {
  reportId: string | undefined;
  subReportId: string | undefined;
  className: string;
}

function SubReportInfoPanel({ reportId, subReportId, className }: SubReportInfoPanelProps) {
  const { activeReportStore } = useContext(RootContext);

  const subReport = useMemo(
    () => (reportId ? activeReportStore.getSubReport(reportId, undefined) : undefined),
    [activeReportStore, reportId]
  );

  if (reportId === undefined || subReportId === undefined) return null;

  return (
    <div className={`${className} bg-slate-400 p-2 rounded-xl flex flex-col`}>
      <div className="grow">
        <h1 className="flex-initial text-lg text-center w-full pb-4">{subReport?.title}</h1>
        <div>Dictionary:</div>
        <div>Media:</div>
        <div>Dates:</div>
        <hr className="my-2" />
        <div>Filters:</div>
      </div>

      <ReportPageSelect
        reportId={subReportId}
        onPageChanged={(pageId) => {
          activeReportStore.setPage(subReportId, pageId);
        }}
      />
    </div>
  );
}

export default SubReportInfoPanel;
