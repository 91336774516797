import { useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useFloating } from '@floating-ui/react';
import { LevelSelection } from '../../../models/dictSelection';
import DictBoxRow from './DictBoxRow';
import DictBoxFrame from './DictBoxFrame';
import DictBoxSearchBar from './DictBoxSearchBar';

interface DictBoxLevelProps {
  level: LevelSelection;
  position: number;
  isActive: boolean;
  onClose: () => void;
  onSearch: (search: string) => void;
  onSelect: (indices: number[], selected: boolean) => void;
  onOpenNextLevel: () => void;
}

function DictBoxLevel({ level, position, isActive, onClose, onSearch, onSelect, onOpenNextLevel }: DictBoxLevelProps) {
  const [highlightRows, setHighlightRows] = useState<number[]>([]);

  const selectedItemCount = level.viewItems.filter((r) => r.selected).length;
  const meta = `${selectedItemCount}/${level.viewItems.length}`;

  const { refs, floatingStyles } = useFloating({
    placement: 'right-start',
  });

  const onHighlightRow = (index: number, setToHighlight: boolean, ctrl: boolean, shift: boolean) => {
    if (!isActive) return;
    if (shift) {
      if (highlightRows.length === 0) {
        setHighlightRows([index]);
      } else {
        // Note that 'highlightRows[0]' is the LAST item we have added to the selection...
        const start = Math.min(highlightRows[0], index);
        const end = Math.max(highlightRows[0], index);
        const newSelection = Array.from({ length: Math.abs(end - start) + 1 }, (_, i) => start + i);
        setHighlightRows(newSelection);
      }
    } else if (ctrl) {
      if (setToHighlight) setHighlightRows([index, ...highlightRows]);
      else setHighlightRows([...highlightRows.filter((x) => x !== index)]);
    } else {
      setHighlightRows(setToHighlight ? [index] : []);
    }
  };

  const onSelectRow = (index: number, selected: boolean) => {
    if (!isActive) return;
    // If we have highlighted rows, and we are clicking on the checkbox
    // we should select/deselect them all
    if (highlightRows.includes(index)) {
      onSelect(highlightRows, selected);
    } else {
      onSelect([index], selected);
    }
  };

  const onDoubleClickRow = (index: number) => {
    if (!isActive) return;
    if (level.viewItems[index]?.selected === true) {
      // Only open next level if we have selected an item
      onOpenNextLevel();
    } else if (selectedItemCount === 0) {
      // SPECIAL CASE:
      // - If we have no selected items, we should select the current item
      // - AND open the next active level
      // This allows quick exploration of the tree...
      onSelect([index], true);
      onOpenNextLevel();
    }
  };

  // Virtualize the list
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: level.viewItems.length,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => 20, // <--- How do we calculate this? Its the row height, and needs to match content exactly
    overscan: 2, // Check top/bottom of list as we scroll. We want partial rows to draw correctly.
    paddingEnd: 0,
  });
  const virtualItems = virtualizer.getVirtualItems();
  const totalVirtualHight = virtualizer.getTotalSize();
  const listShift = virtualItems.length > 0 && totalVirtualHight > 0 ? virtualItems[0].start : 0;
  const canDrag = false; // TEST DRAG TARGET: isActive && level.items.filter((f) => f.selected).length > 0;

  return (
    <DictBoxFrame position={position} title={level.title} meta={meta} onClose={() => onClose()}>
      {isActive && <DictBoxSearchBar onSearch={onSearch} />}
      {canDrag && isActive && (
        <>
          <div className="h-0" ref={refs.setReference} />
          <div
            className="max-w-min h-24 absolute p-1 bg-gray-300 rounded-tr-xl rounded-br-xl shadow-xl border-t border-r border-b border-gray-500 rotate-90"
            ref={refs.setFloating}
            style={floatingStyles}
          >
            Drag to add
          </div>
        </>
      )}
      {level.viewItems.length === 0 && (
        <div className="mt-36 w-1/4 mx-auto text-center text-gray-300 text-lg">No entries match filter</div>
      )}
      <div className={`${!isActive && 'blur-[2px]'} overflow-y-auto`} ref={scrollContainerRef}>
        <div
          style={{
            height: `${totalVirtualHight}px`,
          }}
        >
          <div
            style={{
              // Shift whole list as we scroll - need for smooth virtualised action
              transform: `translateY(${listShift}px)`,
            }}
          >
            {virtualItems.map((virtualRow, uiIndex) => {
              const row = level.viewItems[virtualRow.index];
              return (
                <DictBoxRow
                  key={row.id}
                  entry={row}
                  index={virtualRow.index}
                  highlight={highlightRows.find((x) => x === virtualRow.index) !== undefined}
                  onSelect={onSelectRow}
                  onDoubleClick={onDoubleClickRow}
                  onHighlightRow={onHighlightRow}
                  yShift={virtualRow.start - listShift - uiIndex * virtualRow.size}
                />
              );
            })}
          </div>
        </div>
      </div>
    </DictBoxFrame>
  );
}

export default DictBoxLevel;
