import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { AdminUser } from '../../models/userModel';
import { capitalizeWords } from '../../utils/helpers';
import { RootContext } from '../../stores/storeProvidor';
import DialogSelect from '../controls/form/DialogSelect';
import AdminModalValidationBox from '../admin/controls/AdminModalValidationBox';

interface SetRoleModalProps {
  users: AdminUser[];
}

const SetRoleModal = NiceModal.create(({ users }: SetRoleModalProps) => {
  const modal = useModal();
  const rctx = useContext(RootContext);
  const [role, setRole] = useState<string>(users.length === 1 ? `${users[0].role.roleId}` : 'None');
  const [validationMsg, setValidationMsg] = useState<string[]>([]);
  const adminStore = rctx.getAdminStore();
  const orgs = _.uniq(_.map(users, (u) => u.role.organisation.orgId));
  if (orgs.length !== 1) throw new Error('Cannot set role for multiple users from different organisations');

  const roles = adminStore.getRolesByOrg(orgs[0]);

  const opt = roles.map((r) => `${r.id}|${r.name}`);
  const roleOptions = users.length === 1 ? opt : ['None|None', ...opt];

  const onConfirm = () => {
    if (role === 'None') {
      setValidationMsg([...validationMsg, 'You must select a role...']);
      return;
    }
    adminStore.updateUsersRole(users, parseInt(role, 10)).then(
      () => {
        modal.remove();
      },
      (e) => {
        setValidationMsg([e.message]);
      }
    );
  };

  const onCancel = () => {
    modal.remove();
  };

  return (
    <Modal show onClose={onCancel} size="sm" popup>
      <Modal.Body>
        <h3 className="pt-6 pb-2">
          Choose a role for {users.length > 1 ? 'multipe users' : `user: "${capitalizeWords(users[0].userName)}"`}
        </h3>
        <div>
          <DialogSelect
            id="role"
            label="Role"
            options={roleOptions}
            selectedOption={role}
            defaultOption="None"
            onSelect={(id, val) => {
              setRole(val);
              if (val !== 'None') setValidationMsg([]);
            }}
            disabled={false}
          />
        </div>
        <AdminModalValidationBox msgs={validationMsg} />
        <div className="flex justify-center gap-4 mt-4">
          <Button onClick={onConfirm}>Save</Button>
          <Button color="gray" onClick={onCancel}>
            Discard
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default SetRoleModal;
