import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  TableCellsIcon,
  GlobeAsiaAustraliaIcon,
  UserCircleIcon,
  ChartPieIcon,
  UsersIcon,
  BuildingOffice2Icon,
  AdjustmentsHorizontalIcon,
  HandRaisedIcon,
  TvIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import NiceModal from '@ebay/nice-modal-react';
import { observer } from 'mobx-react-lite';
import SideMenu, { SideMenuBottomSection } from '../Components/controls/SideMenu';
import SideMenuLink, { SideMenuSeparator } from '../Components/controls/SideMenuLink';
import TopMenu, { TopMenuButton } from '../Components/controls/TopMenu';
import Logo from '../Components/controls/Logo';
import TopMenuMessageAuto from '../Components/controls/TopMenuMessageAuto';
import WithPermission from '../permissions/WithPermission';
import Perms from '../permissions/permissions';
import AccountModal from '../Components/modals/AccountDialog';
import { RootContext } from '../stores/storeProvidor';

const AdminLayout = observer(() => {
  const rootStore = useContext(RootContext);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const hideSideBar = () => setShowSideMenu(false);
  const toggleSideBar = () => setShowSideMenu(!showSideMenu);

  useEffect(() => {
    if (rootStore.storeRefreshHash !== undefined) {
      // Load on startup
      rootStore.refreshAllStores(() => {
        rootStore.getAdminStore().refresh();
      });
    }
  }, [rootStore, rootStore.storeRefreshHash]);

  return (
    <>
      <SideMenu showSideMenu={showSideMenu} clearSideMenuFn={hideSideBar}>
        <SideMenuLink navTo="/admin" Icon={TvIcon} navText="Dashboard" onClicked={hideSideBar} />
        <SideMenuSeparator />
        <WithPermission permsNeeded={Perms.AdminUsers}>
          <SideMenuLink navTo="users" Icon={UsersIcon} navText="User Management" onClicked={hideSideBar} />
        </WithPermission>
        <WithPermission permsNeeded={Perms.AdminRoles}>
          <SideMenuLink navTo="roles" Icon={HandRaisedIcon} navText="Roles & Permissions" onClicked={hideSideBar} />
        </WithPermission>
        <WithPermission permsNeeded={Perms.AdminOrgs}>
          <SideMenuLink navTo="orgs" Icon={BuildingOffice2Icon} navText="Organisations" onClicked={hideSideBar} />
        </WithPermission>
        <SideMenuSeparator />
        <WithPermission permsNeeded={Perms.AdminDataSources}>
          <SideMenuLink navTo="data" Icon={GlobeAsiaAustraliaIcon} navText="Data Sources" onClicked={hideSideBar} />
        </WithPermission>
        <SideMenuSeparator />
        <SideMenuLink
          navTo="config"
          Icon={AdjustmentsHorizontalIcon}
          navText="Site Configuration"
          onClicked={hideSideBar}
        />
        <SideMenuBottomSection>
          <SideMenuLink navTo="/app" Icon={TableCellsIcon} navText="App" onClicked={hideSideBar} />
          <WithPermission permsNeeded={Perms.ViewQaArea}>
            <SideMenuLink navTo="/qa" Icon={EyeIcon} navText="QA" onClicked={hideSideBar} />
          </WithPermission>
          <WithPermission permsNeeded={Perms.MonitorStats}>
            <SideMenuLink navTo="/monitor" Icon={ChartPieIcon} navText="Monitoring" onClicked={hideSideBar} />
          </WithPermission>
        </SideMenuBottomSection>
      </SideMenu>

      <TopMenu toggleSideMenuFn={toggleSideBar} className="bg-primary text-gray-300">
        <Logo className="text-gray-300" clickFn={toggleSideBar} />
        <TopMenuMessageAuto clickFn={() => {}} isApp={false} />
        <TopMenuButton
          clickFn={() => {
            NiceModal.show(AccountModal);
          }}
          text="Account"
          Icon={UserCircleIcon}
          tip="Your account"
        />
      </TopMenu>

      <Outlet />
    </>
  );
});

export default AdminLayout;
