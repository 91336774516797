import { PropsWithChildren, useCallback, useContext, useState } from 'react';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';
import SelectionTree from './tree/SelectionTree';
import SelectionControls from './tree/SelectionControls';
import useSelectionDragging from '../../../../hooks/useSelectionDragging';
import { SelectionItemStorage, SelectionType } from '../../../../models/selectionModels';
import SelectionItem from './tree/SelectionItem';
import { RootContext } from '../../../../stores/storeProvidor';
import { createStorageKey } from '../../../../stores/selectionDragStore';

export interface SelectionContainerProps {
  containerId: string;
  isEmbedded: boolean; // If true selection is embedded in a report. containerId is then reportId
  selectionType: SelectionType;
}

/*
 * Container for report selection controls, and selection tree
 * Main aim of this container is to provide a drag/drop context between
 * the control and the selection tree...
 */
const SelectionContainer = observer(
  ({ containerId, isEmbedded, selectionType, children }: PropsWithChildren<SelectionContainerProps>) => {
    const { selectionDragStore } = useContext(RootContext);
    const [dragValid, setDragValid] = useState(true);

    const sensors = useSensors(
      useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
          distance: 10,
        },
      }),
      useSensor(TouchSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    // Get drag objects
    const updateKey = createStorageKey(containerId, isEmbedded, selectionType);
    const dragStore = selectionDragStore.getSelectionDragItems(updateKey);

    // This is the crrent selected (highlighted) item(s) in the tree
    const selected = selectionDragStore.getSelectedTreeItems(updateKey);

    // Callback when selection changes
    const onLayoutChanged = useCallback(
      (modKey: string, updatedStore: SelectionItemStorage) => {
        selectionDragStore.updateSelectionDragItems(modKey, updatedStore);
      },
      [selectionDragStore]
    );

    const onDragValidityChange = (key: string, valid: boolean) => {
      // selectionDragStore.updateDragValid(key, valid);
      setDragValid(valid);
    };

    const { handleDragStart, handleDragCancel, handleDragOver, handleDragEnd, getActiveDragObj } = useSelectionDragging(
      updateKey,
      dragStore,
      selectionType,
      onLayoutChanged,
      onDragValidityChange,
      true
    );

    const dragObject = getActiveDragObj();

    return (
      <div className="bg-gray-100 w-full h-full p-0.5">
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className="w-full h-full grid grid-cols-3 grid-rows-6 gap-1">
            {/* DictBoxes need flex in parent to fill pane */}
            <div className="col-span-2 row-span-6 flex">{children}</div>
            <div className=" col-span-1 row-span-5 overflow-hidden">
              <SelectionTree updateKey={updateKey} store={dragStore} selected={selected} />
            </div>
            <div className="bg-gray-100 col-span-1 row-span-1">
              <SelectionControls updateKey={updateKey} selected={selected} />
            </div>
          </div>
          <DragOverlay>
            {dragObject ? (
              <SelectionItem updateKey={updateKey} item={dragObject} dragging dragValid={dragValid} />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    );
  }
);

export default SelectionContainer;
