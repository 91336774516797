import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import SelectionItem from './SelectionItem';
import { SelectionDragObj, SelectionItemStorage, SelectionNodeType } from '../../../../../models/selectionModels';
import { Draggable, Droppable } from '../../../../controls/dnd';
import { SelectionTreeItemDragData, TREE_GROUP } from '../../../../../hooks/useSelectionDragging';
import { RootContext } from '../../../../../stores/storeProvidor';
import { TreeSelection } from '../../../../../stores/selectionDragStore';
import { baseId } from '../../../../../utils/dragUtils';

interface DraggableWrapperProps {
  updateKey: string;
  item: SelectionDragObj;
  isSelected: boolean;
}

function TreeItemDraggableWrapper({ updateKey, item, isSelected }: DraggableWrapperProps) {
  if (item.node?.nodeType === SelectionNodeType.Root) {
    // Root is NOT draggable (but is droppable)
    return <SelectionItem updateKey={updateKey} item={item} selected={isSelected} />;
  }

  // Draggable items
  return (
    <Draggable
      key={item.id}
      id={item.id}
      data={{ parentNode: item.parent, thisNode: item.node } as SelectionTreeItemDragData}
      useOverlay
    >
      <SelectionItem updateKey={updateKey} item={item} selected={isSelected} />
    </Draggable>
  );
}

interface SelectionTreeProps {
  updateKey: string;
  store: SelectionItemStorage;
  selected: TreeSelection[];
}

const SelectionTree = observer(({ updateKey, store, selected }: SelectionTreeProps) => {
  const { selectionDragStore, selectionStore } = useContext(RootContext);
  const collectionId = TREE_GROUP;
  const treeItems = store.treeGroup;

  /* THis diesnt really work...
  // Get current selection paths from associated DictBoxes (if any)
  // THis is not ideal - useEffect should not really trigger this - it
  // should be driven via the store
  const currentKey = selectionStore.getDictBoxSelectionHash(updateKey);
  const [lastKey, setLastKey] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (currentKey !== undefined) {
      selectionStore.addDictBoxSelectionToTree(updateKey, lastKey, selected);
      // setLastKey(currentKey);
    }
  }, [selectionStore, updateKey, lastKey, currentKey, selected]);
  */

  const clearSelection = () => {
    selectionDragStore.clearSelectedTreeItems(updateKey);
  };

  if (treeItems.length === 0) return <div>Empty...</div>;

  return (
    /* Overall container is dropable */
    <Droppable
      id={collectionId}
      renderItem={(ref, children) => (
        <div
          ref={ref}
          className="w-full h-full p-0.5 select-none bg-white border border-gray-300 overflow-y-auto"
          onClick={clearSelection}
          role="presentation"
        >
          {children}
        </div>
      )}
      data={{ parentNode: undefined, thisNode: undefined } as SelectionTreeItemDragData}
    >
      {treeItems.map((item) => (
        // Tree item dropable
        <Droppable
          key={item.id}
          id={item.id}
          renderItem={(ref, children) => <div ref={ref}>{children}</div>}
          data={{ parentNode: item.parent, thisNode: item.node } as SelectionTreeItemDragData}
        >
          <TreeItemDraggableWrapper
            updateKey={updateKey}
            item={item}
            isSelected={selected.find((x) => baseId(x) === baseId(item)) !== undefined}
          />
        </Droppable>
      ))}
    </Droppable>
  );
});

export default SelectionTree;
