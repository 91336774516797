import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import ProgressOverlay from '../../controls/ProgressOverlay';
import ReportPreview from './ReportPreview';
import ReportResultGrid from './ReportResultGrid';
import SubReportContainer from './subReports/SubReportContainer';
import { RootContext } from '../../../stores/storeProvidor';

interface ReportResultContainerProps {
  reportId: string | undefined;
  subReportId: string | undefined;
  showResults: boolean;
  progressMsg: string | undefined;
  className: string;
  onClickEmpty: () => void;
}

const ReportResultContainer = observer(
  ({ reportId, subReportId, showResults, progressMsg, className, onClickEmpty }: ReportResultContainerProps) => {
    const { activeUserStore } = useContext(RootContext);

    const gridOptions = {
      debugGrid: false,
      showRowIdColumn: activeUserStore.getSetting('showRowIds', reportId) === 'true',
    };

    const resultView =
      subReportId === undefined ? (
        <ReportResultGrid reportId={reportId} options={gridOptions} />
      ) : (
        <SubReportContainer reportId={reportId} subReportId={subReportId} />
      );
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={`relative bg-gray-100 ${className} border border-spacing-1`}
        onClick={onClickEmpty}
        id="report-container"
      >
        <ProgressOverlay active={progressMsg !== undefined}>
          <div className="grid grid-flow-row auto-rows-max">
            <div className="h-8 mx-auto text-xl font-semibold">Processing Report</div>
            <div className="text-center text-blue-800 text-lg">{progressMsg}...</div>
            <ArrowPathIcon className="text-blue-400 animate-spin w-24 h-24 mx-auto" />
          </div>
        </ProgressOverlay>
        {showResults ? resultView : <ReportPreview reportId={reportId} />}
      </div>
    );
  }
);

export default ReportResultContainer;
