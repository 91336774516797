import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Button, Modal, Tooltip } from 'flowbite-react';
import { ReactElement, useContext, useState } from 'react';
import { RootContext } from '../../stores/storeProvidor';
import { SettingsLevel } from '../../models/settings';

interface ConfirmModalWithHideProps {
  hideKey: string;
  title: string;
  body: string | ReactElement;
  onYes: () => void;
  onNo?: () => void;
  yesButtonText?: string;
  noButtonText?: string;
}

const ConfirmModalWithHide = NiceModal.create(
  ({ hideKey, title, body, onYes, onNo, yesButtonText, noButtonText }: ConfirmModalWithHideProps) => {
    const { activeUserStore } = useContext(RootContext);
    const modal = useModal();
    const [wantHide, setWantHide] = useState(false);
    const hideList = activeUserStore.getSetting('hideConfirmDialogs')?.split('|') ?? [];

    const onConfirm = () => {
      modal.remove();
      onYes();
      if (wantHide) {
        activeUserStore.updateSetting(
          'hideConfirmDialogs',
          hideList.concat(hideKey).join('|'),
          SettingsLevel.UserLevel
        );
      }
    };

    const onCancel = () => {
      modal.remove();
      if (onNo) onNo();
    };

    // User wants to hide this message, so just do the YES action
    if (hideList.find((x) => x === hideKey)) {
      onConfirm();
      return null;
    }

    return (
      <Modal show onClose={onCancel} size="sm" popup>
        <Modal.Body>
          <h3 className="pt-6 pb-2">{title}</h3>
          <div>{body}</div>
          <div className="flex justify-center text-xs border-t border-t-gray-200 mt-6 pt-4">
            <label htmlFor="hideConfirmDialog" className="ml-2">
              <input
                id="hideConfirmDialog"
                type="checkbox"
                className="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-1"
                onChange={(e) => setWantHide(e.target.checked)}
              />
              Hide this message in the future.
            </label>
            <Tooltip content="You can re-enable this from your user settings">
              <QuestionMarkCircleIcon className="w-4 h-4 ml-2 text-gray-400 inline" />
            </Tooltip>
          </div>
          <div className="flex justify-center gap-4 mt-4">
            <Button onClick={onConfirm}>{yesButtonText}</Button>
            <Button color="gray" onClick={onCancel}>
              {noButtonText}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

ConfirmModalWithHide.defaultProps = {
  onNo: undefined,
  yesButtonText: 'Yes',
  noButtonText: 'No',
};

export default ConfirmModalWithHide;
