import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootContext } from '../stores/storeProvidor';

export default function useOpenReport() {
  const { activeReportStore } = useContext(RootContext);
  const navigate = useNavigate();

  const openReportById = async (id: string): Promise<boolean> => {
    const ok = await activeReportStore.addReportToOpen(id);
    if (ok) {
      navigate('/app/openreports');
    }
    return ok;
  };

  const openNewReport = async (
    title: string | undefined = undefined,
    templateId: string | undefined = undefined
  ): Promise<string | undefined> => {
    const newId = await activeReportStore.createNewReportAndAddToOpen(title, undefined, templateId);
    // Wait before we navigate to the new report...
    // eslint-disable-next-line no-promise-executor-return
    await new Promise<void>((resolve) => setTimeout(resolve, 500));
    navigate('/app/openreports');
    return newId;
  };

  return { openReportById, openNewReport };
}
