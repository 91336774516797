import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { AdminPanel, ListData, ListHeading } from './AdminList';
import { useDateFormat } from '../../../hooks/useFormat';
import { SystemSummary } from '../models/SystemSummary';

type AdminSystemSummaryProps = {
  summary: SystemSummary | undefined;
  doRefresh: () => void;
};

function AdminSystemSummary({ summary, doRefresh }: AdminSystemSummaryProps) {
  const { formatDateTime } = useDateFormat();

  const pcActive = Math.round(
    summary?.processIdleSeconds === undefined || summary?.processActiveSeconds === undefined
      ? 0
      : (100 * summary.processActiveSeconds) / (summary.processActiveSeconds + summary.processIdleSeconds)
  );

  return (
    <AdminPanel>
      <ListHeading>Updated At</ListHeading>
      <ListData>
        {formatDateTime(summary?.timestamp, true)}
        <ArrowPathIcon className="h-5 text-gray-500 float-right hover:text-blue-700" onClick={doRefresh} />
      </ListData>

      <ListHeading>Active Users</ListHeading>
      <ListData>{summary?.activeUserCount ?? '?'}</ListData>

      <ListHeading>Active Orgs</ListHeading>
      <ListData>{summary?.activeOrgCount ?? '?'}</ListData>

      <ListHeading>Total Blocks (24h)</ListHeading>
      <ListData>{summary?.blocksProcessed ?? '?'}</ListData>

      <ListHeading>Ok Reports (24h)</ListHeading>
      <ListData>{summary?.completedReports ?? '?'}</ListData>

      <ListHeading>Error Reports (24h)</ListHeading>
      <ListData>{summary?.failedReports ?? '?'}</ListData>

      <ListHeading>Active Reports (24h)</ListHeading>
      <ListData>{summary?.inProgressReports ?? '?'}</ListData>

      <ListHeading>Active % (1h)</ListHeading>
      <ListData>{pcActive}%</ListData>

      <ListHeading>Tasks Run (1h)</ListHeading>
      <ListData>{summary?.totalTasks ?? 0}</ListData>

      <ListHeading>Queue Max (1h)</ListHeading>
      <ListData>{summary?.maxQueueCount ?? 0}</ListData>
    </AdminPanel>
  );
}

export default AdminSystemSummary;
