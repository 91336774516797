import { type GridSelection } from '../../../../models/reportModel';
import { type VizData, type VizOptions } from '../../../visualisations/VizData';

export enum SubReportType {
  Chart = 'Chart',
  SpotList = 'SpotList',
  Drilldown = 'Drilldown',
}

export interface SubReport {
  id: string;
  ownerReportId: string;
  subReportType: SubReportType;
  title: string;
  sourceCells: GridSelection;
  vizData: VizData | undefined;
  vizOptions: VizOptions | undefined;
}

export type SubReportBaseProps = {
  subReport: SubReport | undefined;
};
