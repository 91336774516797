import { LoginRequest, LoginResponse } from '../models/authModels';
import apiClient from './apiConfig';

// See options at: https://axios-http.com/docs/req_config

const AuthAPI = {
  async login(req: LoginRequest) {
    const response = await apiClient.api.post<LoginResponse>('/auth/login', req);
    return response.data;
  },

  async logout() {
    apiClient.api.get('/auth/logout');
    apiClient.clearAccessToken();
  },

  async refreshToken() {
    const response = await apiClient.api.get<LoginResponse>('/auth/refresh-token');
    return response.data;
  },

  async registerNewUser() {
    // TODO
    const response = await apiClient.api.request({
      url: '/auth/register',
      method: 'POST',
    });
    return response.data;
  },

  updateAccessToken(token: string) {
    apiClient.updateAccessToken(token);
  },

  clearAccessToken() {
    apiClient.clearAccessToken();
  },
};

export default AuthAPI;
