import { ResponsiveTreeMap } from '@nivo/treemap';

// See https://nivo.rocks/treemap/

const tmData = {
  name: 'nivo',
  color: 'hsl(294, 70%, 50%)',
  children: [
    {
      name: 'viz',
      color: 'hsl(280, 70%, 50%)',
      children: [
        {
          name: 'stack',
          color: 'hsl(213, 70%, 50%)',
          children: [
            {
              name: 'cchart',
              color: 'hsl(35, 70%, 50%)',
              loc: 34549,
            },
            {
              name: 'xAxis',
              color: 'hsl(226, 70%, 50%)',
              loc: 50856,
            },
            {
              name: 'yAxis',
              color: 'hsl(196, 70%, 50%)',
              loc: 175338,
            },
            {
              name: 'layers',
              color: 'hsl(330, 70%, 50%)',
              loc: 125504,
            },
          ],
        },
        {
          name: 'ppie',
          color: 'hsl(188, 70%, 50%)',
          children: [
            {
              name: 'chart',
              color: 'hsl(289, 70%, 50%)',
              children: [
                {
                  name: 'pie',
                  color: 'hsl(297, 70%, 50%)',
                  children: [
                    {
                      name: 'outline',
                      color: 'hsl(320, 70%, 50%)',
                      loc: 741,
                    },
                    {
                      name: 'slices',
                      color: 'hsl(211, 70%, 50%)',
                      loc: 182704,
                    },
                    {
                      name: 'bbox',
                      color: 'hsl(43, 70%, 50%)',
                      loc: 15902,
                    },
                  ],
                },
                {
                  name: 'donut',
                  color: 'hsl(55, 70%, 50%)',
                  loc: 161074,
                },
                {
                  name: 'gauge',
                  color: 'hsl(236, 70%, 50%)',
                  loc: 85824,
                },
              ],
            },
            {
              name: 'legends',
              color: 'hsl(97, 70%, 50%)',
              loc: 163197,
            },
          ],
        },
      ],
    },
    {
      name: 'colors',
      color: 'hsl(212, 70%, 50%)',
      children: [
        {
          name: 'rgb',
          color: 'hsl(113, 70%, 50%)',
          loc: 57682,
        },
        {
          name: 'hsl',
          color: 'hsl(72, 70%, 50%)',
          loc: 141274,
        },
      ],
    },
    {
      name: 'utils',
      color: 'hsl(115, 70%, 50%)',
      children: [
        {
          name: 'randomize',
          color: 'hsl(198, 70%, 50%)',
          loc: 151225,
        },
        {
          name: 'resetClock',
          color: 'hsl(196, 70%, 50%)',
          loc: 167765,
        },
        {
          name: 'noop',
          color: 'hsl(330, 70%, 50%)',
          loc: 188660,
        },
        {
          name: 'tick',
          color: 'hsl(19, 70%, 50%)',
          loc: 153872,
        },
        {
          name: 'forceGC',
          color: 'hsl(229, 70%, 50%)',
          loc: 160383,
        },
        {
          name: 'stackTrace',
          color: 'hsl(267, 70%, 50%)',
          loc: 112592,
        },
        {
          name: 'dbg',
          color: 'hsl(160, 70%, 50%)',
          loc: 151642,
        },
      ],
    },
    {
      name: 'generators',
      color: 'hsl(13, 70%, 50%)',
      children: [
        {
          name: 'address',
          color: 'hsl(242, 70%, 50%)',
          loc: 39534,
        },
        {
          name: 'city',
          color: 'hsl(242, 70%, 50%)',
          loc: 98908,
        },
        {
          name: 'animal',
          color: 'hsl(125, 70%, 50%)',
          loc: 145481,
        },
        {
          name: 'movie',
          color: 'hsl(293, 70%, 50%)',
          loc: 156749,
        },
        {
          name: 'user',
          color: 'hsl(342, 70%, 50%)',
          loc: 41095,
        },
      ],
    },
    {
      name: 'set',
      color: 'hsl(318, 70%, 50%)',
      children: [
        {
          name: 'clone',
          color: 'hsl(150, 70%, 50%)',
          loc: 10327,
        },
        {
          name: 'intersect',
          color: 'hsl(214, 70%, 50%)',
          loc: 170967,
        },
        {
          name: 'merge',
          color: 'hsl(51, 70%, 50%)',
          loc: 33946,
        },
        {
          name: 'reverse',
          color: 'hsl(276, 70%, 50%)',
          loc: 146062,
        },
        {
          name: 'toArray',
          color: 'hsl(49, 70%, 50%)',
          loc: 133226,
        },
        {
          name: 'toObject',
          color: 'hsl(228, 70%, 50%)',
          loc: 69599,
        },
        {
          name: 'fromCSV',
          color: 'hsl(298, 70%, 50%)',
          loc: 82379,
        },
        {
          name: 'slice',
          color: 'hsl(182, 70%, 50%)',
          loc: 87080,
        },
        {
          name: 'append',
          color: 'hsl(234, 70%, 50%)',
          loc: 83747,
        },
        {
          name: 'prepend',
          color: 'hsl(286, 70%, 50%)',
          loc: 33102,
        },
        {
          name: 'shuffle',
          color: 'hsl(135, 70%, 50%)',
          loc: 114895,
        },
        {
          name: 'pick',
          color: 'hsl(236, 70%, 50%)',
          loc: 97675,
        },
        {
          name: 'plouc',
          color: 'hsl(76, 70%, 50%)',
          loc: 94688,
        },
      ],
    },
    {
      name: 'text',
      color: 'hsl(78, 70%, 50%)',
      children: [
        {
          name: 'trim',
          color: 'hsl(233, 70%, 50%)',
          loc: 132732,
        },
        {
          name: 'slugify',
          color: 'hsl(328, 70%, 50%)',
          loc: 12527,
        },
        {
          name: 'snakeCase',
          color: 'hsl(132, 70%, 50%)',
          loc: 86865,
        },
        {
          name: 'camelCase',
          color: 'hsl(90, 70%, 50%)',
          loc: 40660,
        },
        {
          name: 'repeat',
          color: 'hsl(19, 70%, 50%)',
          loc: 162022,
        },
        {
          name: 'padLeft',
          color: 'hsl(281, 70%, 50%)',
          loc: 52340,
        },
        {
          name: 'padRight',
          color: 'hsl(13, 70%, 50%)',
          loc: 74177,
        },
        {
          name: 'sanitize',
          color: 'hsl(92, 70%, 50%)',
          loc: 87737,
        },
        {
          name: 'ploucify',
          color: 'hsl(237, 70%, 50%)',
          loc: 134087,
        },
      ],
    },
    {
      name: 'misc',
      color: 'hsl(114, 70%, 50%)',
      children: [
        {
          name: 'greetings',
          color: 'hsl(310, 70%, 50%)',
          children: [
            {
              name: 'hey',
              color: 'hsl(265, 70%, 50%)',
              loc: 35691,
            },
            {
              name: 'HOWDY',
              color: 'hsl(196, 70%, 50%)',
              loc: 148654,
            },
            {
              name: 'aloha',
              color: 'hsl(231, 70%, 50%)',
              loc: 125295,
            },
            {
              name: 'AHOY',
              color: 'hsl(46, 70%, 50%)',
              loc: 107280,
            },
          ],
        },
        {
          name: 'other',
          color: 'hsl(137, 70%, 50%)',
          loc: 191136,
        },
        {
          name: 'path',
          color: 'hsl(254, 70%, 50%)',
          children: [
            {
              name: 'pathA',
              color: 'hsl(232, 70%, 50%)',
              loc: 108390,
            },
            {
              name: 'pathB',
              color: 'hsl(244, 70%, 50%)',
              children: [
                {
                  name: 'pathB1',
                  color: 'hsl(347, 70%, 50%)',
                  loc: 168066,
                },
                {
                  name: 'pathB2',
                  color: 'hsl(144, 70%, 50%)',
                  loc: 163314,
                },
                {
                  name: 'pathB3',
                  color: 'hsl(317, 70%, 50%)',
                  loc: 19215,
                },
                {
                  name: 'pathB4',
                  color: 'hsl(149, 70%, 50%)',
                  loc: 140757,
                },
              ],
            },
            {
              name: 'pathC',
              color: 'hsl(22, 70%, 50%)',
              children: [
                {
                  name: 'pathC1',
                  color: 'hsl(263, 70%, 50%)',
                  loc: 70941,
                },
                {
                  name: 'pathC2',
                  color: 'hsl(345, 70%, 50%)',
                  loc: 35117,
                },
                {
                  name: 'pathC3',
                  color: 'hsl(161, 70%, 50%)',
                  loc: 90001,
                },
                {
                  name: 'pathC4',
                  color: 'hsl(171, 70%, 50%)',
                  loc: 94371,
                },
                {
                  name: 'pathC5',
                  color: 'hsl(263, 70%, 50%)',
                  loc: 197532,
                },
                {
                  name: 'pathC6',
                  color: 'hsl(9, 70%, 50%)',
                  loc: 191860,
                },
                {
                  name: 'pathC7',
                  color: 'hsl(221, 70%, 50%)',
                  loc: 89306,
                },
                {
                  name: 'pathC8',
                  color: 'hsl(246, 70%, 50%)',
                  loc: 132128,
                },
                {
                  name: 'pathC9',
                  color: 'hsl(73, 70%, 50%)',
                  loc: 30114,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

interface TreemapProps {
  data: number[][];
}

function TreemapPlot({ data }: TreemapProps) {
  return (
    <ResponsiveTreeMap
      data={tmData}
      identity="name"
      value="loc"
      valueFormat=".02s"
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
      labelSkipSize={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.2]],
      }}
      parentLabelPosition="left"
      parentLabelTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.1]],
      }}
    />
  );
}

export default TreemapPlot;
