export type SettingsBottomTab = {
  label: string;
  value: string;
};

type SettingsBottomTabsProps = {
  tabs: SettingsBottomTab[];
  activeTabIndex: number;
  onTabChange: (selTab: number) => void;
};

function SettingsBottomTabs({ tabs, activeTabIndex, onTabChange }: SettingsBottomTabsProps) {
  const handleTabClick = (tabIndex: number) => {
    onTabChange(tabIndex);
  };

  return (
    <div className="grow">
      <div className="max-w-screen-lg flex gap-x-2">
        {tabs.map((tab, index) => (
          <button
            type="button"
            key={tab.value}
            className={`py-3 px-4 text-sm font-medium rounded-xl ${
              activeTabIndex === index ? 'bg-blue-200 text-blue-700 shadow-lg' : 'bg-gray-200 text-gray-500'
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SettingsBottomTabs;
