import { Outlet } from 'react-router-dom';
import HomeNav from '../Components/home/HomeNav';

export default function HomeLayout() {
  return (
    <>
      <HomeNav />
      <div className="flex flex-wrap gap-8 px-16 pt-8">
        <Outlet />
      </div>
    </>
  );
}
