import { useCallback, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { TableCellsIcon } from '@heroicons/react/24/outline';
import { RootContext } from '../../../stores/storeProvidor';
import AppGrid4x6 from '../AppGrid';
import ReportList from './ReportList';
import ReportCard from './ReportCard';
import ActionButton from '../../controls/ActionButton';
import { useAppTitle } from '../../../hooks/useTitle';
import useOpenReport from '../../../hooks/useReport';

const ReportsHome = observer(() => {
  const { uiState, availReportStore, activeReportStore } = useContext(RootContext);
  const navigate = useNavigate();
  const { openNewReport } = useOpenReport();

  useAppTitle('Reports', TableCellsIcon);

  // Then call on initial load only - NOTE: this is whenever the Report page is shown.
  // If we want to load once only, then this will have to be managed by the ReportStore...
  useEffect(() => {
    availReportStore.loadAvailableReports();
  }, [availReportStore]);

  const selectedReports = useCallback(
    () => availReportStore.availableReports.filter((r) => uiState.selectedListReportIds.includes(r.id)),
    [availReportStore.availableReports, uiState.selectedListReportIds]
  );

  const openAllReports = () => {
    const proms = selectedReports().map((r) => activeReportStore.addReportToOpen(r.id));
    Promise.all(proms).then(() => {
      navigate('/app/openreports');
    });
  };

  const clearAllReports = () => {
    uiState.setSelectedListReportIds([]);
  };

  return (
    <AppGrid4x6 className="p-0 bg-gray-200">
      <div className="row-start-2 row-end-7">
        <ReportList />
      </div>
      <div className="col-span-2 row-start-2 row-end-7 border-x-2 border-gray-300">
        <h1 className="text-center text-xl py-1">Selected</h1>
        <div className="pr-8 overflow-y-auto h-full">
          <div>
            {selectedReports().map((report) => (
              <ReportCard report={report} key={report.id} />
            ))}
          </div>
        </div>
      </div>
      <div className="row-start-2 row-end-7">
        <h1 className="text-center text-xl py-1">Actions</h1>
        <ActionButton className="w-2/3 mx-12 my-2" onClick={openAllReports} disabled={selectedReports().length <= 1}>
          Open All
        </ActionButton>
        <ActionButton className="w-2/3 mx-12 my-2" onClick={clearAllReports} disabled={selectedReports().length < 1}>
          Clear Selected
        </ActionButton>
        <ActionButton
          className="w-2/3 mx-12 my-2"
          onClick={() => openNewReport(undefined, 'basicReport')}
          disabled={false}
        >
          New Report
        </ActionButton>
      </div>
    </AppGrid4x6>
  );
});

export default ReportsHome;
