import { createContext, ReactNode } from 'react';
import RootStore from './rootStore';

export const RootContext = createContext<RootStore>({} as RootStore);

type StoreProviderProps = {
  store: RootStore;
  children: ReactNode;
};

function StoreProvider({ children, store }: StoreProviderProps) {
  return <RootContext.Provider value={store}>{children}</RootContext.Provider>;
}

export default StoreProvider;
