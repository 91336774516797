/* eslint-disable class-methods-use-this */
import {
  DrilldownRunRequest,
  GridSelection,
  NewReportRequest,
  PageDimention,
  ReportDetails,
  ReportRunDetails,
  ReportRunRequest,
  ReportRunResponse,
  ReportStatus,
  ReportSummary,
  RowDetails,
  RunStatus,
} from '../models/reportModel';
import apiClient from './apiConfig';
import CancelApi from './apiUtils';
import { convertToDate } from '../utils/dates';
import { cleanSelectionTree } from '../utils/selectionTreeUtils';

class ReportAPI {
  private canceller = new CancelApi();

  async getAvailableReports(cancelExisting = false): Promise<ReportSummary[]> {
    const response = await apiClient.api.get<ReportSummary[]>(`/report/list`, {
      signal: cancelExisting ? this.canceller.getSignal(`reportAvail`) : undefined,
    });
    return response.data.map((rpt) => ({
      ...rpt,
      modified: convertToDate(rpt.modified),
    }));
  }

  async getReportDetails(reportId: string, cancelExisting = false) {
    const response = await apiClient.api.get<ReportDetails>(`/report/${reportId}`, {
      signal: cancelExisting ? this.canceller.getSignal(`Details_${reportId}`) : undefined,
    });
    return response.data;
  }

  async getRecentReports(cancelExisting = false) {
    const response = await apiClient.api.get<ReportSummary[]>(`/report/recent`, {
      params: { quantity: 10 },
      signal: cancelExisting ? this.canceller.getSignal(`reportRecent`) : undefined,
    });
    return response.data.map((rpt) => ({
      ...rpt,
      modified: convertToDate(rpt.modified),
    }));
  }

  // Run a report. If an existing run is underway we will cancel
  // the previous run (for the given report id)
  async runReport(
    reportId: string,
    schemaId: string,
    schemaVersion: string,
    ignoreBigReportWarning: boolean,
    cancelExisting = true
  ) {
    const req: ReportRunRequest = { reportId, schemaId, schemaVersion, IgnoreBigReportWarning: ignoreBigReportWarning };

    const response = await apiClient.api.post<ReportRunResponse>(`/report/run/`, req, {
      signal: cancelExisting ? this.canceller.getSignal(`runReport_${reportId}`) : undefined,
    });

    return response.data.runId;
  }

  async runDrilldownReport(
    drilldownReportId: string,
    originalReportId: string,
    originalRunId: string,
    selectedCells: GridSelection,
    schemaId: string,
    schemaVersion: string,
    ignoreBigReportWarning: boolean,
    cancelExisting = true
  ): Promise<string> {
    const req: DrilldownRunRequest = {
      drilldownReportId,
      originalReportId,
      originalRunId,
      page: selectedCells.selPage,
      cellStartColumn: selectedCells.selStart[0] ?? -1,
      cellStartRow: selectedCells.selStart[1] ?? -1,
      cellEndColumn: selectedCells.selEnd[0] ?? -1,
      cellEndRow: selectedCells.selEnd[1] ?? -1,
      schemaId,
      schemaVersion,
      IgnoreBigReportWarning: ignoreBigReportWarning,
    };

    const response = await apiClient.api.post<ReportRunResponse>(`/report/run/drilldown`, req, {
      signal: cancelExisting ? this.canceller.getSignal(`runDrilldown_${drilldownReportId}`) : undefined,
    });

    return response.data.runId;
  }

  // Get the status of a running report
  async getRunStatus(runId: string, cancelExisting = false) {
    const response = await apiClient.api.get<ReportStatus>(`/report/status/${runId}`, {
      signal: cancelExisting ? this.canceller.getSignal(`reportStatus_${runId}`) : undefined,
    });
    return {
      ...response.data,
      status: response.data.status as RunStatus,
      updatedAt: convertToDate(response.data.updatedAt),
      completdAt: convertToDate(response.data.completdAt),
    };
  }

  // Get the sizes for all pages in result
  async getResultDimentions(runId: string, cancelExisting = false): Promise<PageDimention[]> {
    interface ReportDimentionsResponse {
      runId: string;
      pages: PageDimention[];
      count: number;
    }
    const response = await apiClient.api.get<ReportDimentionsResponse>(`/result/pages/${runId}`, {
      signal: cancelExisting ? this.canceller.getSignal(`size_${runId}`) : undefined,
    });
    return response.data.pages;
  }

  async getResultReportAsRun(runId: string, cancelExisting = false): Promise<ReportRunDetails> {
    const response = await apiClient.api.get<ReportRunDetails>(`/result/spec/${runId}`, {
      signal: cancelExisting ? this.canceller.getSignal(`size_${runId}`) : undefined,
    });
    return response.data;
  }

  // Get the full row/column headers
  async getResultColumnHeaders(runId: string, pageId: number, cancelExisting = false): Promise<string[][]> {
    interface ReportColumnHeaderResponse {
      runId: string;
      id: number;
      headers: string[][];
    }
    const response = await apiClient.api.get<ReportColumnHeaderResponse>(`/result/columns/${runId}`, {
      params: { pageId },
      signal: cancelExisting ? this.canceller.getSignal(`hdrs_${runId}`) : undefined,
    });
    return response.data.headers;
  }

  // Get the full row/column headers
  async getResultRowHeaders(runId: string, pageId: number, startRow: number, rowCount: number, cancelExisting = false) {
    interface ReportRowHeaderResponse {
      runId: string;
      pageId: number;
      fromRow: number;
      toRow: number;
      rowHeaders: string[][];
    }
    const response = await apiClient.api.get<ReportRowHeaderResponse>(`/result/rows/${runId}`, {
      params: { pageId, startRow, rowCount },
      signal: cancelExisting ? this.canceller.getSignal(`hdrs_${runId}`) : undefined,
    });
    return {
      fromRow: response.data.fromRow,
      toRow: response.data.toRow,
      headers: response.data.rowHeaders,
    };
  }

  // Get the body-cells, for the given row range
  async getResultCells(runId: string, pageId: number, startRow: number, rowCount: number, cancelExisting = false) {
    interface ReportResultCellResponse {
      runId: string;
      pageId: number;
      fromRow: number;
      toRow: number;
      rows: RowDetails[];
    }
    const response = await apiClient.api.get<ReportResultCellResponse>(`/result/cells/${runId}`, {
      params: { pageId, startRow, rowCount },
      signal: cancelExisting ? this.canceller.getSignal(`rows_${runId}`) : undefined,
    });
    return {
      fromRow: response.data.fromRow,
      toRow: response.data.toRow,
      rowCells: response.data.rows,
    };
  }

  // Save an existing report
  async saveReportDetails(reportId: string, report: ReportDetails) {
    // To reduce the size of the data clean the selection-trees, ignoring UI based flags
    const data = {
      ...report,
      spec: {
        ...report.spec,
        selections: report.spec.selections.map((s) => ({
          ...s,
          treeRoot: s.treeRoot ? cleanSelectionTree(s.treeRoot) : undefined,
        })),
      },
    };
    const response = await apiClient.api.put<ReportDetails>(`/report`, data);
    return { status: response.data };
  }

  // Create a new report
  async createNewReport(
    name: string | undefined,
    description: string | undefined,
    templateId: string | undefined,
    cancelExisting = false
  ): Promise<string> {
    const req: NewReportRequest = { name, description, templateId };
    const response = await apiClient.api.post<ReportDetails>(`/report`, req, {
      signal: cancelExisting ? this.canceller.getSignal(`createReport`) : undefined,
    });
    return response.data.id;
  }

  // Mark a report as deleted
  async deleteReport(reportId: string) {
    await apiClient.api.delete(`/report/${reportId}`);
  }
}

export default ReportAPI;
