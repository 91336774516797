import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  ArrowUturnLeftIcon,
  PuzzlePieceIcon,
  PresentationChartBarIcon,
  ArrowRightOnRectangleIcon,
  QueueListIcon,
  ArrowsUpDownIcon,
  TableCellsIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import SideMenu from '../../controls/SideMenu';
import SideMenuLink from '../../controls/SideMenuLink';
import TopMenu from '../../controls/TopMenu';
import TopMenuMessageAuto from '../../controls/TopMenuMessageAuto';
import { RootContext } from '../../../stores/storeProvidor';

const QaLayout = observer(() => {
  const rootStore = useContext(RootContext);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const hideSideBar = () => setShowSideMenu(false);
  const toggleSideBar = () => setShowSideMenu(!showSideMenu);

  useEffect(() => {
    if (rootStore.storeRefreshHash !== undefined) {
      // Load on startup
      rootStore.refreshAllStores(() => {});
    }
  }, [rootStore, rootStore.storeRefreshHash]);

  return (
    <>
      <SideMenu showSideMenu={showSideMenu} clearSideMenuFn={hideSideBar}>
        <SideMenuLink navTo="/admin" Icon={ArrowUturnLeftIcon} navText="Back to App" onClicked={hideSideBar} />
        <SideMenuLink navTo="/qa" Icon={PuzzlePieceIcon} navText="UI Components" onClicked={hideSideBar} />
        <SideMenuLink navTo="/qa/chart" Icon={PresentationChartBarIcon} navText="Charts" onClicked={hideSideBar} />
        <SideMenuLink navTo="/qa/drag" Icon={ArrowRightOnRectangleIcon} navText="Drag & Drop" onClicked={hideSideBar} />
        <SideMenuLink navTo="/qa/accordion" Icon={QueueListIcon} navText="Accordion" onClicked={hideSideBar} />
        <SideMenuLink navTo="/qa/vscroll" Icon={ArrowsUpDownIcon} navText="Virtual Scroll" onClicked={hideSideBar} />
        <SideMenuLink navTo="/qa/gridscroll" Icon={TableCellsIcon} navText="Grid Scroll" onClicked={hideSideBar} />
        <SideMenuLink navTo="/qa/dictbox" Icon={Square3Stack3DIcon} navText="DictBoxes" onClicked={hideSideBar} />
      </SideMenu>

      <TopMenu toggleSideMenuFn={toggleSideBar} className="bg-blue-400 text-gray-800">
        <TopMenuMessageAuto
          clickFn={() => {
            console.log('?');
          }}
          isApp={false}
        />
      </TopMenu>

      <Outlet />
    </>
  );
});

export default QaLayout;
