import { ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { ColorSchemeId } from '@nivo/colors';
import _ from 'lodash';
import { useMemo } from 'react';

/*
 See: 
  https://nivo.rocks/bar/
 Example code:
  https://github.com/plouc/nivo/blob/master/examples/codesandbox/src/charts/Bar.tsx
*/

export type BarplotData = {
  [key: string]: string | number;
};

export type BarplotSpec = {
  data: BarplotData[];
  barIndex: string;
  barAxisLabel: string;
  valueAxisLabel: string;
  title: string;
};

export type BarplotClickHandler =
  | ((
      datum: ComputedDatum<BarplotData> & {
        color: string;
      },
      event: React.MouseEvent<SVGRectElement, MouseEvent>
    ) => void)
  | undefined;

export type BarPlotOptions = {
  verticalBars: boolean;
  stacked: boolean;
  colours: string;
  borderWidth: number;
  borderRadius: number;
  showAverage: boolean;
};

type BarplotProps = {
  spec: BarplotSpec;
  options: BarPlotOptions;
  onClick: BarplotClickHandler;
};

export default function BarPlotSvg({ spec, options, onClick }: BarplotProps) {
  const prototype = spec.data[0]; // First entry sets the keys for all others

  const keys = Object.keys(prototype);
  const index = keys.indexOf(spec.barIndex, 0);
  if (index > -1) {
    keys.splice(index, 1); // Remove the bar-index from the keys
  }

  const average = useMemo(
    () =>
      options.showAverage && !options.stacked ? _.mean(_.flatten(spec.data.map((d) => keys.map((k) => +d[k])))) : 0,
    [keys, spec.data, options.stacked, options.showAverage]
  );

  return (
    <ResponsiveBar
      data={spec.data}
      keys={keys}
      indexBy={spec.barIndex}
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: options.colours as ColorSchemeId }}
      layout={options.verticalBars ? 'vertical' : 'horizontal'}
      groupMode={options.stacked ? 'stacked' : 'grouped'}
      enableGridY={options.verticalBars}
      enableGridX={!options.verticalBars}
      markers={
        average > 0
          ? [
              {
                axis: options.verticalBars ? 'y' : 'x',
                value: average,
                lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 1 },
                legend: `Chart Average (${average.toFixed(1)})`,
                legendOrientation: options.verticalBars ? 'vertical' : 'horizontal',
              },
            ]
          : []
      }
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      borderWidth={options.borderWidth}
      borderRadius={options.borderRadius}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: options.verticalBars ? spec.barAxisLabel : spec.valueAxisLabel,
        legendPosition: 'middle',
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: options.verticalBars ? spec.valueAxisLabel : spec.barAxisLabel,
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel={spec.title}
      barAriaLabel={(e) => `${e.id}: ${e.formattedValue} : ${e.indexValue}`}
      onClick={onClick}
    />
  );
}
