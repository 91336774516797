interface ProgresBarProps {
  percent: number | undefined;
  topClass?: string;
  showBar?: boolean | undefined;
  barColour?: string;
  barBgColour?: string;
}

export default function ProgresBar({ percent, topClass, showBar, barColour, barBgColour }: ProgresBarProps) {
  const showIt = showBar === undefined ? (percent ?? 0) > 0 && (percent ?? 0) < 100 : showBar;
  if (!showIt || percent === undefined) return null;
  return (
    <div className={topClass}>
      <div className="text-center">{percent}%</div>
      <div className={`${barBgColour} rounded-full h-2.5`}>
        <div className={`${barColour} h-2.5 rounded-full`} style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
}

ProgresBar.defaultProps = {
  topClass: 'w-full',
  showBar: undefined,
  barColour: 'bg-green-700',
  barBgColour: 'bg-gray-200',
};
