import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';
import type RootStore from './rootStore';
import { ReportSummary } from '../models/reportModel';
import reportService from '../services/reportService';

// For async calls within Mobx stores there are several options to ensure actions are correctly triggered.
// See: https://mobx.js.org/actions.html#asynchronous-actions
// Here I'm typically using the try/catch/runInAction method...

class AvailableReportStore {
  private rootStore: RootStore;

  // All reports the user can open - summary only
  availableReports: ReportSummary[] = [];

  recentReports: ReportSummary[] = [];

  recentReportsStale = true;

  availableReportStatus = 'Initialising';

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this);
  }

  get getReports() {
    return this.availableReports;
  }

  isValidReportId(id: string) {
    const availableIds = this.availableReports.map((r) => r.id);
    return availableIds.includes(id);
  }

  async loadAvailableReports() {
    this.availableReports = [];
    this.availableReportStatus = 'Pending';

    try {
      const reports = await reportService.api.getAvailableReports(true);
      runInAction(() => {
        this.availableReports = reports;
        this.availableReportStatus = 'Complete';
      });
    } catch (e) {
      runInAction(() => {
        this.availableReportStatus = 'Failed to load reports';
      });
    }
  }

  async loadRecentReports() {
    try {
      const reports = await reportService.api.getRecentReports(true);
      runInAction(() => {
        this.recentReports = reports;
        this.recentReportsStale = false;
      });
    } catch (e) {
      runInAction(() => {
        this.recentReports = [];
      });
    }
  }

  setRecentListStale() {
    this.recentReportsStale = true;
  }

  getNewReportName(newNameBase: string, templateId: string | undefined) {
    let baseName = newNameBase;
    let desc;

    if (templateId !== undefined) {
      const template = this.availableReports.find((r) => r.id === templateId);
      if (template !== undefined) {
        baseName = template.name;
        desc = `Copy of report "${template.name}"`;
      }
    }

    const existingNames = this.availableReports
      .map((r: ReportSummary) => r.name)
      .filter((n) => n.startsWith(baseName) && n.length > baseName.length)
      .map((n) => _.parseInt(n.slice(baseName.length + 3)))
      .sort((a, b) => a - b);

    const nextId = existingNames.length === 0 ? 1 : existingNames[existingNames.length - 1] + 1;
    return [`${baseName} - ${nextId}`, desc];
  }
}

export default AvailableReportStore;
