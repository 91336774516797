import { PropsWithChildren, ReactNode, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { RootContext } from '../../../../stores/storeProvidor';

function SelectAccordion({ children }: PropsWithChildren) {
  return <div className="w-full h-full flex flex-col p-0.5 gap-1 overflow-hidden">{children}</div>;
}

interface SelectAccordionPanelProps {
  statekey: string;
  id: string;
  position: number;
  title: string;
  hint?: string | undefined;
  dragHandle?: ReactNode | undefined;
}

const AccordionPanel = observer(
  ({ statekey, id, position, title, hint, children, dragHandle }: PropsWithChildren<SelectAccordionPanelProps>) => {
    const { selectionStore } = useContext(RootContext);
    const active = selectionStore.getSelectionAccordionState(statekey, id, position);

    return (
      <div
        className={`${
          active ? 'flex-1 overflow-hidden' : 'flex-0'
        } bg-gray-100 rounded-md border border-gray-300 shadow-md`}
      >
        {/* Inner flex wrapper for title and body, so correct height flows to children */}
        <div className="flex flex-col h-full overflow-hidden">
          {/* TODO: possibly use grid or flex-row below so icons/title/hint/etc align correctly across all panel titles */}
          <div
            className={`${
              active ? 'rounded-t-md border-b border-gray-300 bg-teal-300' : 'rounded-md bg-slate-300'
            } px-2 h-8 flex-0`}
          >
            <button type="button" onClick={() => selectionStore.setAcordionActive(statekey, id)} className="w-full h-8">
              <ChevronDownIcon
                className={`${
                  active ? 'transform -rotate-90' : ''
                } h-6 w-6 float-left hover:font-semibold transition duration-500`}
              />
              <span className="float-left">{title}</span>
              {hint && <span className="text-sm font-light text-center mx-auto"> {hint}</span>}
              {dragHandle && <span className="float-right">{dragHandle}</span>}
            </button>
          </div>
          {/* 
            Children container must flex to fill height (when active) 
            Note 'overflow-hidden' on all parent divs above. This is esential to get the
            scroll bar to appear correctly in lower div's. It is also essential that each
            parent (of a scrollable div) has a height set. 'h-full' is ok, as is a 
            'flex-col' with 'flex-1' (as this forces the height of the div).
            Also note 'h-full' will give height of parent; so if you have more than one 
            child, you need to use 'flex-1' on the child div's to force them to fill the
            parent height; This is the reason for all of the flex layers here...
          */}
          {active ? <div className="flex-1 overflow-hidden">{children}</div> : null}
        </div>
      </div>
    );
  }
);

SelectAccordion.Panel = AccordionPanel;
export default SelectAccordion;
