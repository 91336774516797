interface DialogTextInputProps {
  id: string;
  label: string;
  defaultValue: string;
  onEdit: (value: string) => void;
  hasError?: boolean;
  inputType?: string;
  disabled?: boolean;
}

export default function DialogTextInput({
  id,
  label,
  defaultValue,
  onEdit,
  hasError,
  inputType,
  disabled,
}: DialogTextInputProps) {
  const inputState = hasError
    ? 'border-gray-300 bg-red-200 text-gray-900 focus:ring-red-500 focus:border-red-500'
    : 'border-gray-300 bg-gray-50 text-gray-900 focus:ring-blue-500 focus:border-blue-500';
  const inputBase = 'border text-sm rounded-lg block w-full p-2.5';

  return (
    <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {label}
      <input
        type={inputType}
        id={id}
        className={`${inputState} ${inputBase}`}
        placeholder={label}
        required
        onBlur={(e) => {
          onEdit(e.target.value);
        }}
        defaultValue={defaultValue}
      />
    </label>
  );
}

DialogTextInput.defaultProps = {
  hasError: false,
  inputType: 'text',
  disabled: false,
};
