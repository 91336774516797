import {
  Cell,
  ColumnDef,
  Header,
  Row,
  RowData,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { VirtualItem, Virtualizer } from '@tanstack/react-virtual';

export enum GridSection {
  TopLeft,
  ColumnHeader,
  RowHeader,
  Body,
}

export interface GridOptions {
  debugGrid: boolean;
  showRowIdColumn: boolean;
}

export type CellMouseEvent = React.MouseEvent<HTMLTableCellElement, MouseEvent>;

export interface GridInfo {
  colHeaderLevels: number;
  rowHeaderLevels: number;
  mergeRowHeaders: boolean;
  pageId: number;
  rowBaseOffset: number;
  options: GridOptions;
  onGridClick: (
    section: GridSection,
    colindex: number | undefined,
    rowindex: number | undefined,
    clickEvent: CellMouseEvent,
    isContext: boolean
  ) => void | undefined;
  getStyle: (section: GridSection, colindex: number | undefined, rowindex: number | undefined) => string;
}

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    headerColIndex: number | undefined;
    colIndex: number | undefined;
    rowHeaderSpanFn: ((row: number, level: number) => number) | undefined;
  }
}

export function getBaseCellStyle(section: GridSection, isSelected: boolean, isNumeric: boolean) {
  let style = 'border border-gray-300 px-1 h-4 text-nowrap text-ellipsis text-sm';

  if (section === GridSection.RowHeader || section === GridSection.ColumnHeader) {
    style += ' font-semibold tracking-tight text-left';
  }

  // Background colour + hover
  if (isSelected) {
    style += section === GridSection.Body ? ' bg-green-100 hover:bg-green-200' : ' bg-green-200 hover:bg-green-300';
  } else {
    style += section === GridSection.Body ? ' hover:bg-cyan-100' : ' bg-gray-200 hover:bg-gray-300';
  }

  // Text/Number formatting
  if (section === GridSection.Body && isNumeric) {
    // Align digits for all numbers
    style += ' text-right slashed-zero tabular-nums';
  }

  return style;
}

/*
 * TODO:
 * - Add 'indent' style for row headers
 * - Move all click handlers to a common global function (to avoid creating a new one for each cell). If possible...
 */

/*
 * Populate a single grid header cell.
 */
function getColumnHeaderCell<T>(header: Header<T, unknown>, info: GridInfo) {
  const { colSpan, column } = header;
  const { meta } = column.columnDef;
  const width = header.getSize();
  const isTopLeft = meta?.headerColIndex !== undefined;
  const headerStyle = info.getStyle(GridSection.ColumnHeader, column.columnDef.meta?.colIndex, undefined);

  const handleClick = (
    section: GridSection,
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    isContext: boolean
  ) => {
    if (info.onGridClick !== undefined) {
      info.onGridClick(section, column.columnDef.meta?.colIndex, undefined, e, isContext);
    }
  };

  if (isTopLeft) {
    // Top left corner - leave blank - not merging this atm - just hide inner borders
    return (
      <th
        key={header.id}
        aria-label="empty"
        onClick={(e) => {
          e.stopPropagation(); // Parent must NOT get click from grid
          handleClick(GridSection.TopLeft, e, false);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          handleClick(GridSection.TopLeft, e, true);
        }}
      />
    );
  }

  return (
    <th
      key={header.id}
      colSpan={colSpan}
      style={{ width }}
      className={headerStyle}
      onClick={(e) => {
        e.stopPropagation(); // Parent must NOT get click from grid
        handleClick(GridSection.ColumnHeader, e, false);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        handleClick(GridSection.TopLeft, e, true);
      }}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
}

/*
 * Populate a single 'row' axis header cell
 */
function getRowHeaderCell<T>(cell: Cell<T, unknown>, info: GridInfo, isFirstVirtualRowOnUi: boolean) {
  const colIndex = cell.column.columnDef.meta?.headerColIndex ?? 0;
  const isRowIdColumn = colIndex < 0;

  const headerStyle = info.getStyle(
    GridSection.RowHeader,
    isRowIdColumn ? undefined : -colIndex,
    cell.row.index + info.rowBaseOffset
  );

  // Calculate the row header span (merge cells) - see generateHeaderSpans() for format
  const getRowHdrSpan = cell.column.columnDef.meta?.rowHeaderSpanFn ?? (() => 1);
  let span = 1;
  if (colIndex > 0) {
    span = getRowHdrSpan(cell.row.index, colIndex);
    const priorSpan = cell.row.index > 0 ? getRowHdrSpan(cell.row.index - 1, colIndex) : -1;

    /*
     Skip cell if this is the next in the span sequence.
     Note though, that the first UI row must ignore these rules,
     if the span is to show correctly, as the UI must always have
     the span set for the first row shown.
     */
    if (!isFirstVirtualRowOnUi && span === priorSpan - 1) {
      span = 0;
    }
  }

  // Span zero means don't render the cell (only first cell of a span is rendered)
  if (span === 0 && info.mergeRowHeaders) return null;

  if (isRowIdColumn) {
    return (
      <td key={cell.id} className={headerStyle}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }

  return (
    // Allow cells to be interactive (eg for selection) hence hide these warnings
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <td
      key={cell.id}
      className={headerStyle}
      rowSpan={span}
      onClick={(e) => {
        e.stopPropagation(); // Parent must NOT get click from grid
        if (info.onGridClick !== undefined) {
          info.onGridClick(GridSection.RowHeader, -colIndex, cell.row.index + info.rowBaseOffset, e, false);
        }
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        if (info.onGridClick !== undefined) {
          info.onGridClick(GridSection.RowHeader, -colIndex, cell.row.index + info.rowBaseOffset, e, true);
        }
      }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
}

function isBodyColumn<T>(cell: Cell<T, unknown>): boolean {
  return cell.column.columnDef.meta?.headerColIndex === undefined;
}

/*
 * Populate a single grid body cell
 */
function getBodyCell<T>(cell: Cell<T, unknown>, info: GridInfo) {
  const handleClick = (
    section: GridSection,
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    isContext: boolean
  ) => {
    if (info.onGridClick !== undefined) {
      info.onGridClick(
        section,
        cell.column.columnDef.meta?.colIndex,
        cell.row.index + info.rowBaseOffset,
        e,
        isContext
      );
    }
  };

  const cellStyle = info.getStyle(
    GridSection.Body,
    cell.column.columnDef.meta?.colIndex,
    cell.row.index + info.rowBaseOffset
  );

  return (
    // Allow cells to be interactive (eg for selection) hence hide these warnings
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <td
      key={cell.id}
      className={cellStyle}
      onClick={(e) => {
        e.stopPropagation(); // Parent must NOT get click from grid
        handleClick(GridSection.Body, e, false);
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        handleClick(GridSection.Body, e, true);
      }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
}

export interface VirtScrollCtx {
  virtualizer: Virtualizer<HTMLDivElement, Element> | null;
}

interface BaseResultGridProps<T extends RowData> {
  data: T[];
  columnFn: () => ColumnDef<T, unknown>[];
  info: GridInfo;
  refreshHash: string;
  virtualContext?: VirtScrollCtx;
  mapVirtualRowToData?: (virtualRow: VirtualItem) => { rowIndex: number; rowDataOffset: number; isLoading: boolean };
  debugGrid?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BaseResultGrid<T extends RowData>({
  data,
  columnFn,
  info,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshHash,
  virtualContext,
  mapVirtualRowToData,
  debugGrid,
}: BaseResultGridProps<T>) {
  const table = useReactTable<T>({
    data,
    columns: columnFn(),
    getCoreRowModel: getCoreRowModel(),
  });

  const virtualRows = virtualContext?.virtualizer?.getVirtualItems();
  const { rows } = table.getRowModel();

  // Basic row draw (no virtualization)
  const drawRow = (row: Row<T>) => (
    <tr key={row.id}>
      {row
        .getVisibleCells()
        .map((cell) => (isBodyColumn(cell) ? getBodyCell(cell, info) : getRowHeaderCell(cell, info, false)))}
    </tr>
  );

  const tableShift = (virtualRows?.length ?? 0) > 0 ? virtualRows?.[0].start ?? 0 : 0;
  const mapIndexToRowFn =
    mapVirtualRowToData === undefined
      ? (vi: VirtualItem) => ({ rowIndex: vi.index, rowDataOffset: vi.index, isLoading: false })
      : mapVirtualRowToData;

  // Draw virtualized row
  const drawVirtualRow = (virtualItem: VirtualItem, index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { rowIndex, rowDataOffset, isLoading } = mapIndexToRowFn(virtualItem);

    if (debugGrid) {
      if (rowDataOffset >= rows.length) {
        // eslint-disable-next-line no-console
        console.log('VI Out Of Bounds', rowDataOffset, rows.length, isLoading);
      }
    }

    const row = rows[rowDataOffset];
    if (isLoading || row === undefined) {
      // Row is loading - cannot draw it yet...
      return (
        <tr
          key={`Loading-${index}`}
          style={{
            height: `${virtualItem.size}px`,
            transform: `translateY(${virtualItem.start - tableShift - index * virtualItem.size}px)`,
          }}
        >
          <td colSpan={table.getHeaderGroups()[0].headers.length} className="text-center">
            ...
          </td>
        </tr>
      );
    }

    return (
      <tr
        key={row.id}
        style={{
          height: `${virtualItem.size}px`,
          transform: `translateY(${virtualItem.start - tableShift - index * virtualItem.size}px)`,
        }}
      >
        {row
          .getVisibleCells()
          .map((cell) => (isBodyColumn(cell) ? getBodyCell(cell, info) : getRowHeaderCell(cell, info, index === 0)))}
      </tr>
    );
  };

  return (
    <table
      className="border border-gray-300 bg-gray-100 select-none"
      onContextMenu={(e) => e.preventDefault()}
      style={{
        transform: `translateY(${tableShift}px)`,
      }}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>{headerGroup.headers.map((header) => getColumnHeaderCell<T>(header, info))}</tr>
        ))}
      </thead>
      <tbody className="border-b border-gray-300">
        {virtualRows === undefined
          ? rows.map((row) => drawRow(row))
          : virtualRows.map((vrow, index) => drawVirtualRow(vrow, index))}
      </tbody>
    </table>
  );
}

BaseResultGrid.defaultProps = {
  virtualContext: undefined,
  mapVirtualRowToData: undefined,
  debugGrid: false,
};

export default BaseResultGrid;
