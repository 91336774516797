import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useState } from 'react';
import { RootContext } from '../../stores/storeProvidor';
import TagList from '../controls/TagList';

export type TagSelectModalProps = {
  tagsFor: string;
  currentTags: string[];
  selectedTag: string;
  updateTagsFn: (newTags: string[]) => void;
};

const TagSelectModal = NiceModal.create(
  ({ tagsFor, currentTags: origTags, selectedTag, updateTagsFn }: TagSelectModalProps) => {
    const { uiState } = useContext(RootContext);
    const [currentTags, setCurrentTags] = useState(origTags);

    // Use a hook to manage the modal state
    const modal = useModal();

    const onOk = () => {
      modal.remove();
      updateTagsFn(currentTags);
    };

    const onCancel = () => {
      modal.remove();
    };

    return (
      <Modal show onClose={onCancel}>
        <Modal.Header>Edit tags for: {tagsFor}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <TagList tags={currentTags} moreLimit={6} onClick={() => {}} />
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              TODO:
              <ul>
                <li>Select tags</li>
                <li>Remove tags</li>
                <li>Add new tags</li>
              </ul>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onOk}>Update</Button>
          <Button color="gray" onClick={onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default TagSelectModal;
