import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

interface EditLabelProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  textArea?: boolean;
}

export default function EditLabel({
  id,
  value: initialValue,
  onChange,
  placeholder,
  className,
  textArea,
}: EditLabelProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    // Debounce timer
    const timeout = setTimeout(() => {
      if (value !== initialValue) {
        onChange(value.trim());
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [initialValue, onChange, value]);

  return (
    <div className="flex group w-full">
      {textArea ? (
        <>
          <textarea
            id="message"
            rows={4}
            value={value}
            className={`${className} flex-1 block p-2.5 w-full text-md text-gray-900 rounded-lg border-0 bg-gray-300 border-gray-300 focus:ring-0 focus:bg-slate-200`}
            placeholder={placeholder}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <span className="flex-0 w-0">
            <PencilSquareIcon className="-ml-6 mt-2 w-6 stroke-slate-400 absolute z-0 invisible group-hover:visible" />
          </span>
        </>
      ) : (
        <>
          <input
            type="text"
            id={id}
            className={`${className} flex-1 block py-2.5 px-0 w-full text-gray-900 bg-transparent rounded-lg border-0 border-b-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-100 focus:bg-slate-200`}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder={placeholder}
          />
          <span className="flex-0 w-0">
            <PencilSquareIcon className="-ml-6 mt-2 w-6 stroke-slate-400 absolute z-0 invisible group-hover:visible" />
          </span>
        </>
      )}
    </div>
  );
}

EditLabel.defaultProps = {
  placeholder: '',
  className: 'text-md font-normal text-left',
  textArea: false,
};
