import { useTranslation } from 'react-i18next';
import { Button } from 'flowbite-react';
import homeImgUrl from '../../../assets/computer-gaa5ad0fc0_1280_cut.jpg';

function HomePage() {
  const { t } = useTranslation(['home']);
  return (
    <>
      <h1 className="px-4 text-3xl basis-2/3">
        <span className="text-primary">{import.meta.env.VITE_APP_TITLE}</span> {t('posttitle')}
      </h1>
      <div className="flex-auto w-1/4" />
      <img src={homeImgUrl} alt="" className="w-1/2 rounded-xl" />
      <div className="flex-auto w-1/3 px-4 py-12 bg-slate-100 rounded-xl border-2 border-slate-100 h-auto">
        <h5 className="text-3xl font-bold tracking-tight text-primary pb-6 text-center w-3/4 mx-auto">
          {t('intro.para_01', { apptitle: import.meta.env.VITE_APP_TITLE })}
        </h5>
        <p className="font-normal text-gray-700 dark:text-gray-400 pb-4">
          {t('intro.para_02', { apptitle: import.meta.env.VITE_APP_TITLE })}
        </p>
        <p className="font-normal text-gray-700 dark:text-gray-400">
          {t('intro.para_03', { apptitle: import.meta.env.VITE_APP_TITLE })}
        </p>
      </div>
      <div className="w-1/4 " />
      <div className="flex-auto w-1/3 bg-slate-100 p-4 place-content-center rounded-xl border-2 border-slate-100 h-50">
        <p className="text-xl font-semibold text-gray-900 text-center pb-6">
          {t('cta', { apptitle: import.meta.env.VITE_APP_TITLE })}
        </p>
        <Button
          size="xl"
          pill
          className="mx-auto bg-primary text-black text-2xl animate-pulse transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
        >
          {t('button.cta')}
        </Button>
      </div>
      <div className="flex-auto " />
    </>
  );
}

export default HomePage;
