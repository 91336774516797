import { useContext } from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import DatePicker from 'react-datepicker';
import { SelectionNode } from '../../../../../models/selectionModels';
import { AbsolutePanelState, newAbsoluteDateRangeNode } from '../../../../../utils/selectionTreeUtils';
import PeriodPanelPanel from './PeriodPanel';
import 'react-datepicker/dist/react-datepicker.css';
import { RootContext } from '../../../../../stores/storeProvidor';

interface AbsolutePeriodPanelProps {
  stateKey: string;
}

const AbsolutePeriodPanel = observer(({ stateKey }: AbsolutePeriodPanelProps) => {
  const { selectionDragStore, selectionStore } = useContext(RootContext);
  const [firstDate, lastDate] = selectionStore.getSchemaDateRange();
  const { startDate, endDate } = selectionDragStore.getAbsolutePanelState(stateKey);

  const startDateJs = startDate.toJSDate();
  const endDateJs = endDate.toJSDate();

  const createNodeOnDrop: () => SelectionNode = () => {
    const state = selectionDragStore.getAbsolutePanelState(stateKey);
    return newAbsoluteDateRangeNode(state);
  };

  const setStartDate = (newStart: Date) => {
    const start = DateTime.fromJSDate(newStart);
    if (start < endDate && firstDate && start >= firstDate && lastDate && start <= lastDate) {
      selectionDragStore.updateAbsolutePanelState(stateKey, {
        startDate: start,
        endDate: endDate < start ? start : endDate,
      } as AbsolutePanelState);
    }
  };

  const setEndDate = (newEnd: Date) => {
    const end = DateTime.fromJSDate(newEnd);
    if (end > startDate && firstDate && end >= firstDate && lastDate && end <= lastDate) {
      selectionDragStore.updateAbsolutePanelState(stateKey, {
        startDate: startDate > end ? end : startDate,
        endDate: end,
      } as AbsolutePanelState);
    }
  };

  const renderDayContents = (day: number, date: Date) => {
    const selDate = DateTime.fromJSDate(date);
    const validDate = firstDate && lastDate && selDate >= firstDate && selDate <= lastDate;
    const inRangeDate = selDate >= startDate && selDate <= endDate;
    let className = '';
    if (validDate) {
      if (inRangeDate) className = 'font-bold text-blue-600';
    } else className = 'text-gray-200';
    return <span className={className}>{day}</span>;
  };

  return (
    <PeriodPanelPanel
      title="Absolute Periods"
      hint=""
      tag="absolute"
      position={1}
      stateKey={stateKey}
      createNodeOnDropFn={createNodeOnDrop}
    >
      <div className="h-full w-full flex">
        <div className="grow border-r mr-1 p-2">
          <div>From:</div>
          <div>
            <DatePicker
              className="rounded-xl"
              dateFormat="dd/MM/yyyy"
              selected={startDateJs}
              onChange={(date: Date) => setStartDate(date)}
              renderDayContents={renderDayContents}
            />
          </div>
        </div>
        <div className="grow border-l p-2">
          <div>To:</div>
          <div>
            <DatePicker
              className="rounded-xl"
              dateFormat="dd/MM/yyyy"
              selected={endDateJs}
              onChange={(date: Date) => setEndDate(date)}
              renderDayContents={renderDayContents}
            />
          </div>
        </div>
      </div>
    </PeriodPanelPanel>
  );
});

export default AbsolutePeriodPanel;
