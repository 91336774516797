import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import DictSelectLevelBar from './DictSelectLevelBar';
import DictBoxContainer from './DictBoxContainer';
import { RootContext } from '../../../stores/storeProvidor';
import { DictSelectContainerSource } from '../../../models/dictSelection';
import Spinner from '../Spinner';
import { capitalizeWords } from '../../../utils/helpers';
import { SelectionType } from '../../../models/selectionModels';
import { createStorageKey } from '../../../stores/selectionDragStore';

// ------------------------------------------------------------

interface DictSelectContainerProps {
  selType: SelectionType;
  isEmbedded: boolean;
  reportId: string;
}

const DictSelectContainer = observer(({ selType, isEmbedded, reportId }: DictSelectContainerProps) => {
  const { selectionStore, uiState } = useContext(RootContext);
  const storageKey = createStorageKey(reportId, isEmbedded, selType);
  const dataSetName = selType.toString();

  const sourceStore: DictSelectContainerSource = selectionStore;

  const levels = sourceStore.getDictLevels(storageKey);
  const levelSelection = sourceStore.getDictSelection(storageKey);

  useEffect(() => {
    if (selectionStore.datasetLoadingHash !== undefined) {
      selectionStore.resetDictLevels(storageKey);
    }
  }, [selectionStore, selectionStore.datasetLoadingHash, storageKey]);

  const doAction = (action: string) => {
    if (action === 'clear') {
      sourceStore.resetDictLevels(storageKey);
    } else if (action.startsWith('clear-level')) {
      const levelTag = action.split(':')[1];
      selectionStore.closeDictLevel(storageKey, levelTag);
    } else {
      uiState.infoAlert = 'Not yet implemented';
    }
  };

  const closeLevel = (tag: string) => {
    sourceStore.closeDictLevel(storageKey, tag);
  };

  const searchLevel = (tag: string, search: string) => {
    sourceStore.filterDictLevelSelection(storageKey, tag, search);
  };

  const selectLevelItems = (tag: string, indices: number[], selected: boolean) => {
    const oldLevel = levelSelection.find((l) => l.tag === tag);
    if (oldLevel === undefined) return;
    sourceStore.updateDictSelection(storageKey, tag, indices, selected);
  };

  const openNextLevel = (tag: string) => {
    sourceStore.openNextDictLevel(storageKey, tag);
  };

  return (
    <div className="w-full m-1 border border-gray-200 rounded-md flex">
      <DictSelectLevelBar
        levels={levels}
        updateLevels={(l) => {
          sourceStore.updateDictLevels(storageKey, l);
        }}
        doAction={doAction}
      />
      <DictBoxContainer
        selection={levelSelection}
        onClose={closeLevel}
        onSearch={searchLevel}
        onSelect={selectLevelItems}
        onOpenNextLevel={openNextLevel}
      />
      <Spinner enable={levels.length === 0} text={`Loading ${capitalizeWords(dataSetName)} relationship...`} />
    </div>
  );
});

export default DictSelectContainer;
