import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline';
import NiceModal from '@ebay/nice-modal-react';
import { ReportSummary } from '../../../models/reportModel';
import TagList from '../../controls/TagList';
import ActionButton from '../../controls/ActionButton';
import { RootContext } from '../../../stores/storeProvidor';
import IconCross from '../../icons/cross';
import ConfirmModalWithHide from '../../modals/ConfirmModalWithHide';

interface ReportCardProps {
  report: ReportSummary;
}

export default function ReportCard({ report }: ReportCardProps) {
  const navigate = useNavigate();
  const { activeReportStore, uiState } = useContext(RootContext);

  const openReport = () => {
    activeReportStore.addReportToOpen(report.id).then(() => {
      navigate('/app/openreports');
    });
  };

  const copyReport = async () => {
    await activeReportStore.createNewReportAndAddToOpen(
      `Copy of ${report.name}`,
      `Copied from ${report.name}`,
      report.id
    );

    // Wait before we navigate to the new report...
    // eslint-disable-next-line no-promise-executor-return
    await new Promise<void>((resolve) => setTimeout(resolve, 500));

    navigate('/app/openreports');
  };

  const deleteReport = () => {
    NiceModal.show(ConfirmModalWithHide, {
      hideKey: 'deleteReport',
      title: 'Discard This Report?',
      body: (
        <div>
          <div className="font-semibold pb-2">Are you sure you want the delete this report?</div>
          <div>Deleted reports can be restored later via the Settings menu.</div>
        </div>
      ),
      onYes: async () => {
        await activeReportStore.deleteReport(report.id);
      },
    });
  };

  const closeCard = () => {
    uiState.clearReportFromSelectedList(report.id);
  };

  return (
    <div className="bg-gray-100 rounded-xl m-4 p-4 shadow-lg border border-slate-300 w-full flex flex-wrap">
      <div className="grow text-lg font-bold h-8">
        {report.name}
        {report.isReadOnly ? (
          <span className="ml-2 text-xs bg-red-500 text-red-100 px-1 py-0.5 rounded-xl border border-red-800">
            Read Only
          </span>
        ) : null}
      </div>
      <IconCross
        className="w-6 h-6 bg-gray-200 text-gray-400 rounded-md border hover:text-red-800"
        onClick={closeCard}
      />
      <div className="basis-full h-0" />
      <div className="basis-3/4">{report.description}</div>
      <div className="basis-1/4">
        <TagList tags={report.tags} moreLimit={5} />
      </div>
      <ActionButton
        className="w-32 bg-blue-700 mt-4"
        onClick={openReport}
        disabled={activeReportStore.isActiveReport(report.id) ?? false}
      >
        Open Report
      </ActionButton>
      <div className="grow">
        <div className="float-right mt-5">
          <button
            type="button"
            className="mx-2 bg-gray-200 text-gray-600 px-2 py-1 rounded-xl border-2 hover:border-blue-700"
            onClick={copyReport}
          >
            <DocumentDuplicateIcon className="w-6" />
          </button>
          <button
            type="button"
            className={`${
              report.isReadOnly ? 'bg-gray-100 text-gray-200' : 'bg-gray-200 text-gray-600 hover:border-blue-700'
            } px-2 py-1 rounded-xl border-2`}
            onClick={deleteReport}
          >
            <TrashIcon className="w-6" />
          </button>
        </div>
      </div>
    </div>
  );
}
