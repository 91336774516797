import apiClient from './apiConfig';
import CancelApi from './apiUtils';
import { FilterLevel, Schema, SchemaAccess } from '../models/schemaModels';
import { convertToDate } from '../utils/dates';
import { enumFromString } from '../utils/helpers';

class SchemaAPI {
  private canceller = new CancelApi();

  // List all available schema-versions
  async getVersions(cancelExisting = false): Promise<Schema[]> {
    const response = await apiClient.api.get<Schema[]>('schema', {
      signal: cancelExisting ? this.canceller.getSignal('schema') : undefined,
    });
    return response.data.map((schema) => ({
      ...schema,
      versions: schema.versions.map((ver) => ({
        versionId: ver.versionId,
        created: convertToDate(ver.created),
        updated: convertToDate(ver.updated),
        firstDate: convertToDate(ver.firstDate),
        lastDate: convertToDate(ver.lastDate),
        access: enumFromString(SchemaAccess, ver.access, SchemaAccess.Unknown),
        note: ver.note,
      })),
    }));
  }

  // List available filterable levels for schema
  async getFilterLevels(schemaId: string, cancelExisting = false): Promise<FilterLevel[]> {
    const response = await apiClient.api.get<FilterLevel[]>(`schema/levels/${schemaId}`, {
      signal: cancelExisting ? this.canceller.getSignal('schema') : undefined,
    });

    return response.data;
  }
}

export default SchemaAPI;
