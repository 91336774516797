import { DateTime } from 'luxon';

// Use to convert dates in models recieved by API.
export function convertToDate(value: unknown): DateTime {
  if (value instanceof DateTime) return value;
  if (typeof value === 'string' || value instanceof String) return DateTime.fromISO(value as string);
  return value as DateTime;
}

export function formatDateOnly(date: DateTime, locale: string, size: 's' | 'm' | 'l'): string {
  // ALL dates must be a luxon.DateTime
  if (!(date instanceof DateTime)) return '<<Bad Date>>';

  let format: Intl.DateTimeFormatOptions = DateTime.DATE_HUGE; // 'Tuesday, October 14, 1983'

  // '10/14/1983'
  if (size === 's') format = DateTime.DATE_SHORT;
  // 'Fri, Oct 14, 1983'
  else if (size === 'm') format = DateTime.DATE_MED_WITH_WEEKDAY;

  return date.setLocale(locale).toLocaleString(format);
}

export function formatTimeOnly(date: DateTime, locale: string, use12h: boolean, incSec: boolean): string {
  // ALL date/times must be a luxon.DateTime
  if (!(date instanceof DateTime)) return '<<Bad Time>>';

  if (use12h)
    return incSec
      ? date.setLocale(locale).toLocaleString(DateTime.TIME_WITH_SECONDS) // '07:30:45 PM'
      : date.setLocale(locale).toLocaleString(DateTime.TIME_SIMPLE); // '07:30 PM'
  return incSec
    ? date.setLocale(locale).toLocaleString(DateTime.TIME_24_WITH_SECONDS) // '19:30:45'
    : date.setLocale(locale).toLocaleString(DateTime.TIME_24_SIMPLE); // '19:30'
}

export function formatDateTime(date: DateTime | string, locale: string): string {
  const d = date instanceof DateTime ? date : convertToDate(date as string);
  return `${formatDateOnly(d, locale, 's')} @ ${formatTimeOnly(d, locale, false, true)}`;
}

export function startOfYear(date: DateTime, yearsBack: number): DateTime {
  const year = date.year - yearsBack;
  return DateTime.fromObject({ year, month: 1, day: 1 });
}

export function endOfYear(date: DateTime, yearsBack: number): DateTime {
  const year = date.year - yearsBack;
  return DateTime.fromObject({ year, month: 12, day: 31 });
}

export function startOfFinancialYear(startOfFinYear: DateTime, date: DateTime, yearsBack: number): DateTime {
  const year = date.month < startOfFinYear.month ? date.year - yearsBack - 1 : date.year - yearsBack;
  return DateTime.fromObject({ year, month: startOfFinYear.month, day: startOfFinYear.day });
}

export function endOfFinancialYear(startOfFinYear: DateTime, date: DateTime, yearsBack: number): DateTime {
  const year = date.month < startOfFinYear.month ? date.year - yearsBack : date.year - yearsBack + 1;
  const eofy = startOfFinYear.minus({ days: 1 });
  return DateTime.fromObject({ year, month: eofy.month, day: eofy.day });
}

export function startOfMonth(date: DateTime, monthsBack: number): DateTime {
  const midMonth = date.minus({ months: monthsBack });
  return DateTime.fromObject({ year: midMonth.year, month: midMonth.month, day: 1 });
}

export function endOfMonth(date: DateTime, monthsBack: number): DateTime {
  const midMonth = date.minus({ months: monthsBack - 1 });
  return DateTime.fromObject({ year: midMonth.year, month: midMonth.month, day: 1 }).minus({ days: 1 });
}

export function startOfQuarter(date: DateTime, qtrsBack: number): DateTime {
  const monthsToStartOfQtr = (date.month - 1) % 3;
  const midQtr = date.minus({ months: monthsToStartOfQtr + qtrsBack * 3 });
  return startOfMonth(midQtr, 0);
}

export function endOfQuarter(date: DateTime, qtrsBack: number): DateTime {
  const monthsToStartOfQtr = (date.month - 1) % 3;
  const midQtr = date.minus({ months: monthsToStartOfQtr + qtrsBack * 3 - 2 });
  return endOfMonth(midQtr, 0);
}

// NOTE: Expect sow to be 'ISO weekday', i.e. 1-7, where 1 is Monday and 7 is Sunday - see Luxon docs
export function startOfWeek(sow: number, date: DateTime, weeksBack: number): DateTime {
  const daysBackToSow = date.weekday > sow ? date.weekday - sow : 7 - sow + date.weekday;
  return date.minus({ days: daysBackToSow, weeks: weeksBack });
}

// NOTE: Expect sow to be 'ISO weekday', i.e. 1-7, where 1 is Monday and 7 is Sunday - see Luxon docs
export function endOfWeek(sow: number, date: DateTime, weeksBack: number): DateTime {
  const eow = sow > 1 ? sow - 1 : 7;
  const daysForwardToEow = eow > date.weekday ? eow - date.weekday : eow - date.weekday + 7;
  return date.minus({ days: -daysForwardToEow, weeks: weeksBack });
}
