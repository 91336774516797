import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Bars2Icon } from '@heroicons/react/24/outline';
import { DictLevel } from '../../../models/dictSelection';
import { Sortable } from '../dnd';
import { arrayMove } from '../../../utils/dragUtils';

// -----------------------------------------------------------------

interface LevelToolButtonProps {
  title: string;
  onClick: () => void;
  enabled: boolean;
}

function LevelToolButton({ title, onClick, enabled }: LevelToolButtonProps) {
  const colour = enabled ? 'bg-teal-400' : 'bg-teal-300';
  return (
    <button
      type="button"
      className={`${colour} text-white rounded-xl text-sm p-1 w-full mb-1 hover:opacity-90`}
      onClick={() => enabled && onClick()}
    >
      {title}
    </button>
  );
}

// -----------------------------------------------------------------

interface LevelButtonProps {
  level: DictLevel;
  onClick: () => void;
  onDoubleClick: () => void;
}

function LevelButton({ level, onClick, onDoubleClick }: LevelButtonProps) {
  let colour = level.active ? 'bg-blue-500' : 'bg-slate-400';
  if (level.current) colour = level.active ? 'bg-red-500' : 'bg-red-300';
  // if (level.current) colour += ' outline outline-orange-300';
  return (
    <button
      type="button"
      className={`${colour} text-white rounded-xl text-sm p-1 m-1 w-36 hover:opacity-90`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {level.title}
    </button>
  );
}

// -----------------------------------------------------------------

interface DictSelectLevelBarProps {
  levels: DictLevel[];
  updateLevels: (levels: DictLevel[]) => void;
  doAction: (action: string) => void;
}

function DictSelectLevelBar({ levels, updateLevels, doAction }: DictSelectLevelBarProps) {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sortedLevels = levels
    .slice()
    .sort((a, b) => a.sort - b.sort)
    .filter((l) => l.visible);

  const toggleLevel = (toggledLevel: DictLevel) => {
    const newLevels = sortedLevels.map((level) => {
      // Current level is always active
      if (level.tag === toggledLevel.tag && level.current === false) {
        return { ...level, active: !level.active };
      }
      return level;
    });
    updateLevels(newLevels);
  };

  const enableLevel = (enabledLevel: DictLevel) => {
    const newLevels = sortedLevels.map((level) => ({
      ...level,
      current: level.tag === enabledLevel.tag,
      active: level.tag === enabledLevel.tag ? true : level.active, // Force current level to active
    }));
    updateLevels(newLevels);
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const tags = sortedLevels.map((x) => x.tag);
      const oldIndex = tags.indexOf(active.id as string);
      const newIndex = tags.indexOf(over?.id as string);
      // Move and RE-INDEX the levels...
      const newLevels = arrayMove(sortedLevels, oldIndex, newIndex).map((x, i) => ({ ...x, sort: i }));
      updateLevels(newLevels);
    }
  };

  return (
    <div className="bg-gray-200 p-1 flex flex-col">
      <div className="grow">
        <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
          <SortableContext
            id="dictlevels"
            items={sortedLevels.map((x) => x.tag)}
            strategy={verticalListSortingStrategy}
          >
            {sortedLevels.map((level) => (
              <Sortable
                key={level.tag}
                id={level.tag}
                useOverlay={false}
                renderItem={(ref, sortChildren, style, listeners, attributes) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <div ref={ref} style={style} {...attributes} className="mx-auto">
                    {sortChildren}
                    {/* Note: listener is connected to the drag-handle only */}
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Bars2Icon {...listeners} className="h-5 inline text-gray-500 mb-1" />
                  </div>
                )}
              >
                <LevelButton
                  level={level}
                  onClick={() => toggleLevel(level)}
                  onDoubleClick={() => (level.current ? doAction(`clear-level:${level.tag}`) : enableLevel(level))}
                />
              </Sortable>
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <div>
        <LevelToolButton title="Clear" enabled onClick={() => doAction('clear')} />
        <LevelToolButton title="New Items Only" enabled={false} onClick={() => doAction('newItems')} />
      </div>
    </div>
  );
}

export default DictSelectLevelBar;
