import { ReactNode, useContext } from 'react';
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  Cog6ToothIcon,
  DocumentIcon,
  DocumentPlusIcon,
  FolderIcon,
  FolderPlusIcon,
  LinkIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { SelectionDragObj, SelectionNode, SelectionNodeType } from '../../../../../models/selectionModels';
import { RootContext } from '../../../../../stores/storeProvidor';
import useSelectionDialogs from '../../../../../hooks/useSelectionDialogs';

const iconStyle = 'w-4 h-4 inline';
const icons = new Map<SelectionNodeType, ReactNode>();

function getIconForType(node: SelectionNode | undefined): ReactNode | undefined {
  if (icons.size === 0) {
    icons.set(SelectionNodeType.Folder, <FolderIcon className={iconStyle} />);
    icons.set(SelectionNodeType.LevelFilter, <Square2StackIcon className={iconStyle} />);
    icons.set(SelectionNodeType.LevelEntry, <ArrowRightIcon className={iconStyle} />);
    icons.set(SelectionNodeType.Root, <DocumentIcon className={iconStyle} />);
    icons.set(SelectionNodeType.EntryWithId, <StarIcon className={iconStyle} />);
    icons.set(SelectionNodeType.DateRange, <CalendarDaysIcon className={iconStyle} />);
    icons.set(SelectionNodeType.DateEntry, <StarIcon className={iconStyle} />);
  }

  if (node === undefined) return undefined;

  let icon = icons.get(node.nodeType);

  if (!node.expand) {
    if (node.nodeType === SelectionNodeType.Folder) icon = <FolderPlusIcon className={iconStyle} />;
    if (node.nodeType === SelectionNodeType.Root) icon = <DocumentPlusIcon className={iconStyle} />;
    if (node.nodeType === SelectionNodeType.LevelFilter) icon = <Square3Stack3DIcon className={iconStyle} />;
    if (node.nodeType === SelectionNodeType.DateRange) icon = <CalendarIcon className={iconStyle} />;
  }

  return icon;
}

// -----------------------------------------------------------------------

interface SelectionItemProps {
  item: SelectionDragObj;
  updateKey: string;
  selected?: boolean;
  dragging?: boolean; // True if being rendered in the <DragOverlay> (ie its being shown as dragging)
  dragValid?: boolean; // True if the item can be dropped here
}

function SelectionItem({ item, updateKey, selected, dragging, dragValid }: SelectionItemProps) {
  const indent = {
    paddingLeft: `${item.depth * 1}rem`,
  };
  const { selectionDragStore } = useContext(RootContext);
  const { deleteNode, editNodeSettings, renameNode, linkNode } = useSelectionDialogs(updateKey);

  const icon = getIconForType(item.node);

  let style = '';
  if (!dragging) {
    style = 'w-full';
    if (item.insertAfter) style += ' border-b-2 border-blue-300 border-box';
    if (item.insertBefore) style += ' border-t-2 border-blue-300 border-box';
    if (item.insertInside) style += ' border-2 border-blue-300 border-box';
    if (selected) style += ' bg-blue-100';
  } else {
    style = dragValid ? 'cursor-pointer' : 'cursor-not-allowed';
  }

  let name = 'Unknown';
  if (item.node) name = item.node.name;
  // if (item.sourceObj) name = item.sourceObj.name;

  const onToggleSelection = (event: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    selectionDragStore.toggleSelectedTreeItem(updateKey, item.id);
  };

  const onExpandSelection = (event: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    selectionDragStore.toggleExpandForTreeItem(updateKey, item.id);
  };

  const toolClass = 'w-5 h-5 mx-1 inline text-gray-500 hover:text-black';
  const tools = (
    <span className="float-right">
      <button
        type="button"
        className=""
        onClick={(e) => {
          e.stopPropagation();
          editNodeSettings(item.id);
        }}
      >
        <Cog6ToothIcon className={toolClass} />
      </button>

      <button
        type="button"
        className=""
        onClick={(e) => {
          e.stopPropagation();
          renameNode(item.id);
        }}
      >
        <ChatBubbleOvalLeftEllipsisIcon className={toolClass} />
      </button>

      <button
        type="button"
        className=""
        onClick={(e) => {
          e.stopPropagation();
          linkNode(item.id);
        }}
      >
        <LinkIcon className={toolClass} />
      </button>

      <button
        type="button"
        className=""
        onClick={(e) => {
          e.stopPropagation();
          deleteNode(item.id);
        }}
      >
        <TrashIcon className={toolClass} />
      </button>
    </span>
  );

  const content = (
    <>
      <span onClick={onExpandSelection} role="button" tabIndex={0} onKeyDown={onExpandSelection} className="mr-1">
        {icon}
      </span>
      <span onClick={onToggleSelection} role="button" tabIndex={0} onKeyDown={onToggleSelection} className="">
        {name}
      </span>
      {selected ? tools : null}
      {/*
          <span className="inline-flex w-3 h-3 mx-0.5 mt-1 bg-green-200 rounded-full float-right" />
          <span className="inline-flex w-3 h-3 mx-0.5 mt-1 bg-red-500 rounded-full float-right" />
      */}
    </>
  );

  if (dragging) {
    return <div>{content}</div>;
  }
  return (
    <div style={indent} className={style}>
      {content}
    </div>
  );
}

SelectionItem.defaultProps = {
  selected: false,
  dragging: false,
  dragValid: true,
};

export default SelectionItem;
