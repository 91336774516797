import { ArrowPathIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { useDateFormat } from '../../../hooks/useFormat';
import { Schema } from '../../../models/schemaModels';
import { ListHeading, ListData, AdminPanel } from './AdminList';

export function AdminSchemaSummary({
  schema,
  updatedAt,
  doRefresh,
}: {
  schema: Schema;
  updatedAt: DateTime;
  doRefresh: () => void;
}) {
  const { formatDate, formatDateTime } = useDateFormat();

  const clickSchemaVersion = (schemaId: string, versionId: string) => {
    // eslint-disable-next-line no-console
    console.log(`Schema version ${schemaId}.${versionId} clicked`);
  };

  return (
    <AdminPanel>
      <ListHeading>Updated At</ListHeading>
      <ListData>
        {formatDateTime(updatedAt, true)}
        <ArrowPathIcon className="h-5 text-gray-500 float-right hover:text-blue-700" onClick={doRefresh} />
      </ListData>

      <ListHeading>Name</ListHeading>
      <ListData>{schema.name}</ListData>

      <ListHeading>Id</ListHeading>
      <ListData>{schema.schemaId}</ListData>

      <ListHeading>Description</ListHeading>
      <ListData>{schema.description}</ListData>

      <ListHeading>Versions</ListHeading>
      <ListData>
        <div className="ml-12">
          {schema.versions.map((v, i) => (
            <div
              key={v.versionId}
              className={`${i % 2 === 1 ? 'bg-gray-200' : 'bg-gray-300'} p-2 rounded-md flex flex-row flex-wrap`}
            >
              <ListHeading>Version Id</ListHeading>
              <ListData>
                {v.versionId}{' '}
                {i === 0 ? (
                  <div className="inline text-green-700 text-sm font-bold bg-green-200 px-2 py-1 rounded-xl">
                    Latest
                  </div>
                ) : null}
                <Cog6ToothIcon
                  className="h-6 w-6 text-gray-500 float-right hover:text-blue-700"
                  onClick={() => clickSchemaVersion(schema.schemaId, v.versionId)}
                />
              </ListData>
              <ListHeading>Note</ListHeading>
              <ListData>{v.note}</ListData>
              <ListHeading>Created</ListHeading>
              <ListData>{formatDateTime(v.created, true)}</ListData>
              <ListHeading>Updated</ListHeading>
              <ListData>{formatDateTime(v.updated, true)}</ListData>
              <ListHeading>Data Range</ListHeading>
              <ListData>
                {formatDate(v.firstDate)} to {formatDate(v.lastDate)}
              </ListData>
            </div>
          ))}
        </div>
      </ListData>
    </AdminPanel>
  );
}

function AdminSchemaSummaryList({ schema, doRefresh }: { schema: Schema[]; doRefresh: () => void }) {
  const [updated, setUpdated] = useState<DateTime>(DateTime.now());
  return (
    <div className="overflow-auto">
      {schema.map((s) => (
        <AdminSchemaSummary
          key={s.schemaId}
          schema={s}
          updatedAt={updated}
          doRefresh={() => {
            doRefresh();
            setUpdated(DateTime.now());
          }}
        />
      ))}
    </div>
  );
}

export default AdminSchemaSummaryList;
