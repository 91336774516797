import { useContext, useEffect, useState } from 'react';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import NiceModal from '@ebay/nice-modal-react';
import { observer } from 'mobx-react-lite';
import AdminGrid4x6, { AdminGrid4x6SingleColumn, AdminGrid4x6TripleColumn } from './AdminGrid';
import { Organisation } from '../../models/orgModels';
import OrganisationTable from './controls/OrganisationTable';
import { useAdminTtile } from '../../hooks/useTitle';
import AdminButton from './controls/AdminButton';
import getTagColours from '../../utils/tagcolours';
import { RootContext } from '../../stores/storeProvidor';
import ConfirmModal from '../modals/ConfirmModal';
import AddNewOrgModal from '../modals/AddNewOrgModal';
import EditOrgModal from '../modals/EditOrgModal';

const AdminOrgs = observer(() => {
  useAdminTtile('Organisation Management', BuildingOffice2Icon);

  const ctx = useContext(RootContext);
  const { uiState } = ctx;
  const adminStore = ctx.getAdminStore();

  useEffect(() => {
    adminStore.refresh();
  }, [adminStore]);

  const [selectedOrgs, setSelectedOrgs] = useState<Organisation[]>([]);
  const doingDisable = selectedOrgs.some((u) => u.isActive);

  const onClickEnable = () => {
    const warningMsg = doingDisable ? (
      <div className="mt-4 font-bold text-red-700">
        WARNING: This will disable all associated users and roles for this organisation...
      </div>
    ) : null;
    NiceModal.show(ConfirmModal, {
      title: doingDisable ? 'Disable Organisation' : 'Enable Organisation',
      body: (
        <div>
          <div>{`Are you sure you want to ${doingDisable ? 'disable' : 'enable'} the selected organisations?`}</div>
          {warningMsg}
        </div>
      ),
      onYes: () => {
        const modOrg = selectedOrgs.map((o) => ({ ...o, isActive: !doingDisable }));
        adminStore
          .updateOrg(modOrg)
          .then(() => {
            uiState.successAlert = 'The organisations have been updated';
          })
          .catch((e) => {
            uiState.errorAlert = `Failed to update selected organisations: ${e.message}`;
          });
      },
      onNo: () => {
        uiState.warningAlert = 'Action cancelled...';
      },
    });
  };
  const onClickAdd = () => {
    NiceModal.show(AddNewOrgModal);
  };
  const onClickEdit = () => {
    if (selectedOrgs.length === 1) NiceModal.show(EditOrgModal, { org: selectedOrgs[0] });
  };

  const seletedList =
    selectedOrgs.length === 0 ? (
      <div className="text-slate-400 text-sm py-2">Select organisation in grid...</div>
    ) : (
      <>
        {selectedOrgs.map((o) => (
          <span key={o.id} className={`text-sm font-medium px-2 py-0.5 rounded-md m-1 grow-0 ${getTagColours(o.name)}`}>
            {o.name}
          </span>
        ))}
      </>
    );

  return (
    <AdminGrid4x6 className="bg-blue-100">
      <AdminGrid4x6SingleColumn className="p-2 flex flex-col">
        <h2 className="text-center w-full p-2">Organisation Management</h2>
        <div className="grow flex flex-col">
          <div>Actions:</div>
          <div className="bg-slate-300 width-full rounded-xl p-2 mb-2">
            <AdminButton text="Add New Org" onClick={onClickAdd} />
            {selectedOrgs.length > 0 ? (
              <AdminButton text={doingDisable ? 'Disable Org' : 'Enable Org'} onClick={onClickEnable} />
            ) : null}
            {selectedOrgs.length === 1 ? <AdminButton text="Edit Org" onClick={onClickEdit} /> : null}
          </div>
          <div>Selected Organisations:</div>
          <div className="bg-slate-300 width-full rounded-xl px-2 flex flex-row flex-wrap">{seletedList}</div>
        </div>
      </AdminGrid4x6SingleColumn>
      <AdminGrid4x6TripleColumn className="flex flex-col border-x border-gray-300 bg-slate-500">
        <div className="flex-1 overflow-hidden">
          <OrganisationTable onSelected={setSelectedOrgs} />
        </div>
      </AdminGrid4x6TripleColumn>
    </AdminGrid4x6>
  );
});

export default AdminOrgs;
