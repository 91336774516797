import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useState } from 'react';
import DialogTextInput from '../controls/form/DialogTextInput';
import { CreateOrgDto } from '../../models/userModel';
import { RootContext } from '../../stores/storeProvidor';
import AdminModalValidationBox from '../admin/controls/AdminModalValidationBox';

const emptyOrg = {
  name: '',
  description: '',
} as CreateOrgDto;

const AddNewOrgModal = NiceModal.create(() => {
  const modal = useModal();
  const rctx = useContext(RootContext);
  const adminStore = rctx.getAdminStore();
  const [org, setOrg] = useState<CreateOrgDto>(emptyOrg);
  const [validationMsg, setValidationMsg] = useState<string[]>([]);

  const hasError = (id: string) => validationMsg.some((msg) => msg.startsWith(id));
  const disableSave = () => validationMsg.length > 1 || (validationMsg.length === 1 && !hasError('err'));

  const onConfirm = () => {
    // Validate
    const msg: string[] = [];
    if (org.name === '') msg.push('name|Organisation Name is required');
    if (org.description === '') msg.push('description|Description is required');
    setValidationMsg(msg);
    if (msg.length !== 0) {
      return;
    }

    // Call the API to create the user
    adminStore.createNewOrg(org).then(
      () => {
        modal.remove();
      },
      (e) => {
        setValidationMsg([`err|${e.message}`]);
      }
    );
  };

  const onCancel = () => {
    modal.remove();
  };

  return (
    <Modal show onClose={onCancel} size="sm" popup>
      <Modal.Body>
        <h3 className="pt-6 pb-2">Add a new Organisation</h3>
        <DialogTextInput
          id="name"
          label="Orginisation Name"
          defaultValue=""
          onEdit={(v) => setOrg({ ...org, name: v })}
          hasError={hasError('name')}
        />
        <DialogTextInput
          id="desc"
          label="Description"
          defaultValue=""
          onEdit={(v) => setOrg({ ...org, description: v })}
          hasError={hasError('description')}
        />

        <AdminModalValidationBox msgs={validationMsg} />

        <div className="flex justify-center gap-4 mt-4">
          <Button onClick={onConfirm} disabled={disableSave()}>
            Save
          </Button>
          <Button color="gray" onClick={onCancel}>
            Discard
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default AddNewOrgModal;
