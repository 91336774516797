import { useContext } from 'react';
import {
  CalculatorIcon,
  CalendarDaysIcon,
  GlobeAsiaAustraliaIcon,
  PaperClipIcon,
  RadioIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import PreviewLayout from './Preview/PreviewLayout';
import SelectionTabs, { SelectionOption } from './SelectionTabs';
import { RootContext } from '../../../stores/storeProvidor';
import SelectionContainer from './Selection/SelectionContainer';
import DatasetSelection from './Selection/dataset/DatasetSelection';
import DatatypeSelection from './Selection/dataTypes/DatatypeSelection';
import PeriodSelection from './Selection/period/PeriodSelection';
import { SelectionType } from '../../../models/selectionModels';
import DictSelectContainer from '../../controls/dictbox/DictSelectContainer';

const options: SelectionOption[] = [
  { label: 'Report Designer', id: 'layout', disabled: false, error: false, icon: TableCellsIcon },
  { label: 'Dictionary', id: 'dict', disabled: false, error: false, icon: GlobeAsiaAustraliaIcon },
  { label: 'Media', id: 'media', disabled: false, error: false, icon: RadioIcon },
  { label: 'Period', id: 'dates', disabled: false, error: false, icon: CalendarDaysIcon },
  { label: 'Datatypes', id: 'dts', disabled: false, error: false, icon: CalculatorIcon },
  { label: 'Other', id: 'meta', disabled: false, error: false, icon: PaperClipIcon },
];

export interface ReportPreviewProps {
  reportId: string | undefined;
}

const ReportPreview = observer(({ reportId }: ReportPreviewProps) => {
  const { activeReportStore } = useContext(RootContext);
  const activeReport = activeReportStore.getActiveReport(reportId);
  if (!activeReport) return <div>No selected Report?</div>;

  let activeContainer;
  if (reportId) {
    if (activeReport.selectionTabId === 'media') {
      activeContainer = (
        <SelectionContainer containerId={reportId} isEmbedded selectionType={SelectionType.Media}>
          {/* <DatasetSelection containerId={reportId} isEmbedded selectionType={SelectionType.Media} /> */}
          <DictSelectContainer selType={SelectionType.Media} isEmbedded reportId={reportId} />
        </SelectionContainer>
      );
    } else if (activeReport.selectionTabId === 'dict') {
      activeContainer = (
        <SelectionContainer containerId={reportId} isEmbedded selectionType={SelectionType.Dictionary}>
          {/* <DatasetSelection containerId={reportId} isEmbedded selectionType={SelectionType.Dictionary} /> */}
          <DictSelectContainer selType={SelectionType.Dictionary} isEmbedded reportId={reportId} />
        </SelectionContainer>
      );
    } else if (activeReport.selectionTabId === 'dates') {
      activeContainer = (
        <SelectionContainer containerId={reportId} isEmbedded selectionType={SelectionType.Period}>
          <PeriodSelection containerId={reportId} isEmbedded />
        </SelectionContainer>
      );
    } else if (activeReport.selectionTabId === 'dts') {
      activeContainer = (
        <SelectionContainer containerId={reportId} isEmbedded selectionType={SelectionType.Datatypes}>
          <DatatypeSelection containerId={reportId} isEmbedded />
        </SelectionContainer>
      );
    } else if (activeReport.selectionTabId === 'meta') {
      activeContainer = (
        <SelectionContainer containerId={reportId} isEmbedded selectionType={SelectionType.Meta}>
          <DatasetSelection containerId={reportId} isEmbedded selectionType={SelectionType.Meta} />
        </SelectionContainer>
      );
    }
  }

  if (activeContainer === undefined) {
    activeContainer = (
      <PreviewLayout
        reportId={reportId}
        onLayoutUpdated={() => {
          activeReportStore.setReportNeedsRunning(reportId);
        }}
      />
    );
  }

  const onClick = (id: string) => {
    activeReportStore.setSelectionTab(reportId as string, id);
  };

  return (
    <div className="overflow-hidden w-full h-full">
      <div className="flex flex-col h-full">
        <div className="flex-none bg-slate-800">
          <SelectionTabs tabs={options} activeId={activeReport.selectionTabId ?? options[0].id} onClick={onClick} />
        </div>
        <div className="grow h-5/6">{activeContainer}</div>
      </div>
    </div>
  );
});

export default ReportPreview;
