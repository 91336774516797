import { simpleHash } from './helpers';

const tagColourSchemes = [
  'bg-blue-600 text-red-300',
  'bg-yellow-300 text-red-500',
  'bg-yellow-500 text-yellow-100',
  'bg-yellow-100 text-yellow-800',
  'bg-orange-100 text-orange-500',
  'bg-orange-400 text-orange-100',
  'bg-red-700 text-yellow-100',
  'bg-rose-300 text-rose-800',
  'bg-purple-200 text-purple-800',
  'bg-pink-200 text-pink-800',
  'bg-blue-200 text-blue-800',
  'bg-green-100 text-green-800',
  'bg-purple-600 text-orange-200',
];

export default function getTagColours(tag: string): string {
  const pos = simpleHash(tag) % (tagColourSchemes.length - 1);
  return tagColourSchemes[pos];
}
