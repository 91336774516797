import useInterval from '../../hooks/useInterval';

export type AlertType = 'Info' | 'Success' | 'Warning' | 'Error' | 'Critical';

interface AlertMsgProps {
  type: AlertType;
  id: string;
  ttl: number;
  msg?: string | undefined;
  title?: string | undefined;
  closeFn: (id: string) => void;
}

function AlertMsg({ type, id, ttl, msg, title, closeFn }: AlertMsgProps) {
  useInterval(() => {
    closeFn(id);
  }, ttl);

  let colour = 'text-gray-800 bg-blue-300';
  let font = 'text-md font-normal';
  let icon = (
    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-700 bg-blue-200 rounded-lg">
      <svg viewBox="0 0 512 512" fill="currentColor">
        <path d="M256 56C145.72 56 56 145.72 56 256s89.72 200 200 200 200-89.72 200-200S366.28 56 256 56zm0 82a26 26 0 11-26 26 26 26 0 0126-26zm48 226h-88a16 16 0 010-32h28v-88h-16a16 16 0 010-32h32a16 16 0 0116 16v104h28a16 16 0 010 32z" />
      </svg>
    </div>
  );

  if (type === 'Success') {
    colour = 'text-gray-800 bg-green-200';
    icon = (
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-100 bg-green-700 rounded-lg">
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  } else if (type === 'Warning') {
    colour = 'text-gray-800 bg-orange-100';
    icon = (
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-100 bg-orange-700 rounded-lg">
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  } else if (type === 'Error') {
    colour = 'text-gray-800 bg-red-200';
    icon = (
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-50 bg-red-700 rounded-lg">
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  } else if (type === 'Critical') {
    colour = 'text-yellow-50 bg-red-800 font-black';
    icon = (
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-900 bg-yellow-100 rounded-lg">
        <svg viewBox="0 0 24 24" fill="currentColor">
          <path d="M11 7h2v7h-2zm0 8h2v2h-2z" />
          <path d="M21.707 7.293l-5-5A.996.996 0 0016 2H8a.996.996 0 00-.707.293l-5 5A.996.996 0 002 8v8c0 .266.105.52.293.707l5 5A.996.996 0 008 22h8c.266 0 .52-.105.707-.293l5-5A.996.996 0 0022 16V8a.996.996 0 00-.293-.707zM20 15.586L15.586 20H8.414L4 15.586V8.414L8.414 4h7.172L20 8.414v7.172z" />
        </svg>
      </div>
    );
    font = 'text-xl font-semibold';
  }

  return (
    <div className={`flex flex-wrap w-full p-2 rounded-lg shadow-xl ${colour}`} role="alert">
      <div className="basis-2/12 h-8">{icon}</div>
      <div className="basis-8/12 h-8 pt-1 text-center font-bold">{title ? <span>{title}</span> : null}</div>
      <button
        type="button"
        className="basis-1/12 ml-auto -mx-1.5 -my-1.5 text-gray-500 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-600 p-1.5 hover:bg-gray-300 inline-flex h-8 w-8"
        aria-label="Close"
        onClick={() => {
          closeFn(id);
        }}
      >
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {msg ? <div className={`basis-full text-center ${font}`}>{msg}</div> : null}
      {/* children ? <div className="ml-3">{children}</div> : null */}
    </div>
  );
}

AlertMsg.defaultProps = {
  msg: undefined,
  title: undefined,
};

export default AlertMsg;
