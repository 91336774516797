/* eslint-disable no-console */
import { useState } from 'react';
import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DragIdGroupSeparator,
  LayoutItemStorage,
  ItemWithId,
  addNewToContainer,
  arrayMove,
  baseId,
  getNewItemForId,
  groupFromId,
  moveBetweenContainers,
  removeItemById,
  SOURCE_GROUP,
} from '../utils/dragUtils';
import { simpleHash } from '../utils/helpers';

// Group names MUST match members of ItemStorage
export const PAGES_GROUP = 'pagesGroup';
export const ROWS_GROUP = 'rowsGroup';
export const COLS_GROUP = 'colsGroup';

function getStorageHash<T extends ItemWithId>(store: LayoutItemStorage<T>): number {
  const txt = [
    ...store.pagesGroup.map((x) => x.id),
    ...store.rowsGroup.map((x) => x.id),
    ...store.colsGroup.map((x) => x.id),
  ].join('');
  return simpleHash(txt);
}

export default function useLayoutDragging<T extends ItemWithId>(
  instanceKey: string | undefined,
  itemGroups: LayoutItemStorage<T>,
  onLayoutChanged: (instance: string | undefined, store: LayoutItemStorage<T>) => void,
  createUnknownItem: (id: string) => T,
  showLogs = false
) {
  const [startDragItemGroupState, setStartDragItemGroupState] = useState<LayoutItemStorage<T> | undefined>();
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const [snapshot, setSnapshot] = useState<number>(getStorageHash(itemGroups));
  const [previousKey, setPreviousKey] = useState<string | undefined>(undefined);

  if (instanceKey !== previousKey) {
    // Reset all storage
    setPreviousKey(instanceKey);
    setStartDragItemGroupState(undefined);
    setActiveId(undefined);
    setSnapshot(getStorageHash(itemGroups));
  }

  const sourceItems = itemGroups.sourceGroup;

  // -------------------------------------------------------------------------

  const updateLayout = (newStore: LayoutItemStorage<T>) => {
    const newSnapshot = getStorageHash(newStore);
    if (snapshot !== newSnapshot) {
      if (showLogs) console.log(`Layout Snapshot Changed`);
      onLayoutChanged(instanceKey, newStore);
    }
    setSnapshot(newSnapshot);
  };

  // -------------------------------------------------------------------------

  const handleDragStart = ({ active }: DragStartEvent): void => {
    if (showLogs) {
      console.log(`-----------------------------------------`);
      console.log(`handleDragStart: `);
    }
    setActiveId(active.id.toString());

    // Start drag state is kept to allow source to be cleanly added to one group only
    setStartDragItemGroupState(itemGroups);
  };

  // -------------------------------------------------------------------------

  const handleDragCancel = (): void => {
    if (showLogs) {
      console.log(`handleDragCancel: `);
      console.log(`-----------------------------------------`);
    }
    setActiveId(undefined);
    setStartDragItemGroupState(undefined);
  };

  // -------------------------------------------------------------------------

  const handleDragOver = ({ active, over }: DragOverEvent): void => {
    const overId = over?.id;

    if (showLogs) console.log('handleDragOver:');

    if (!overId) {
      if (showLogs) console.log('\tOverId == NULL');
      return;
    }

    const overContainer = over.data.current?.sortable?.containerId || over.id;
    const bId = baseId({ id: active.id.toString() } as ItemWithId);
    const activeContainer = groupFromId({ id: active.id.toString() } as ItemWithId);
    if (activeContainer === undefined) throw new Error('Drag ID does not contain group');

    // Move between lists (but only from 'source' and never to)
    if (activeContainer !== overContainer && overContainer !== SOURCE_GROUP) {
      if (showLogs) console.log(`\tDrag from '${activeContainer}' to '${overContainer}'`);
      const updateStore = () => {
        const collection = itemGroups[overContainer as keyof LayoutItemStorage<T>];
        const overItem = collection.find((x) => baseId(x) === bId); // Find by 'baseId'
        const overIndex = overItem !== undefined ? over.data.current?.sortable.index ?? 0 : collection.length + 1;

        if (activeContainer === SOURCE_GROUP) {
          // Drag from source does NOT delete original
          if (showLogs) console.log(`\t\tSOURCE Dragging from '${activeContainer}' @ '${overIndex}' - copy item`);
          return addNewToContainer(
            startDragItemGroupState ?? itemGroups, // Use ORIGINAL state so hover over multiple will clean up old hovers
            overContainer,
            overIndex,
            getNewItemForId(sourceItems, bId, overContainer, createUnknownItem)
          );
        }

        // Else move between containers

        // Drag to first
        if (showLogs)
          console.log(`\t\tDragging between ; Remove from '${activeContainer}',  Add to '${overContainer}'`);
        return moveBetweenContainers(
          startDragItemGroupState ?? itemGroups, // Use ORIGINAL state so hover over multiple will clean up old hovers
          activeContainer,
          overContainer,
          overIndex,
          getNewItemForId(sourceItems, bId, overContainer, createUnknownItem)
        );
      };

      updateLayout(updateStore());
    } else if (showLogs) console.log(`\tNo action Active:${activeContainer}  Over:${overContainer}`);

    // Else move within a list - let the Sortable code handle this
  };

  // -------------------------------------------------------------------------

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    const activeContainer = active.data.current?.sortable?.containerId;
    const overContainer = over?.data.current?.sortable?.containerId || over?.id;

    if (showLogs) console.log('handleDragEnd:');

    if (!over || overContainer === SOURCE_GROUP) {
      // Drag over nothing OR over source means delete original
      if (showLogs) console.log(`\t DROP: Over is NULL - Active is '${activeContainer}'`);

      const trueActiveContainer = groupFromId({ id: active.id.toString() } as T);
      // But don't delete 'source' items
      if (trueActiveContainer !== SOURCE_GROUP) {
        // Drag to nowhere - delete active item
        if (showLogs) console.log(`\t DROP: Delete active item is '${trueActiveContainer}' @ '${active.id}'`);
        updateLayout(removeItemById(itemGroups, active.id.toString()));
      }

      setActiveId(undefined);
      setStartDragItemGroupState(undefined);
      if (showLogs) console.log(`-----------------------------------------`);
      return;
    }

    const activeIndex = active.data.current?.sortable?.index;
    const bId = baseId({ id: active.id.toString() } as ItemWithId);

    if (showLogs) console.log(`\t DROP: Active:'${activeContainer}'; Over '${overContainer}'; Item '${bId}'`);

    if (bId !== baseId({ id: over.id.toString() } as ItemWithId) && overContainer !== SOURCE_GROUP) {
      const collection = itemGroups[overContainer as keyof LayoutItemStorage<T>];
      const overItem = collection.find((x) => baseId(x) === bId); // Find by 'baseId'
      const overIndex = overItem !== undefined ? over.data.current?.sortable.index ?? 0 : collection.length + 1;

      if (showLogs)
        console.log(
          `\t DROP: active ID:'${bId}' (${active.id.toString()}); Over ID '${baseId({
            id: over.id.toString(),
          } as ItemWithId)}' (over.id.toString())`
        );

      const updatedGroups = () => {
        let newItems = itemGroups;
        if (activeContainer === overContainer) {
          // Move item within current array
          if (showLogs) console.log(`\t DROP same container: '${activeContainer}' - Move item in array posn`);

          newItems = {
            ...itemGroups,
            [overContainer]: arrayMove(itemGroups[overContainer as keyof LayoutItemStorage<T>], activeIndex, overIndex),
          };
        } else if (activeContainer !== undefined) {
          if (activeContainer === SOURCE_GROUP && startDragItemGroupState) {
            // Copy new item from Source - Important: only allow drop to a SINGLE group (not every group we hover over)
            // To do this we use the original state from 'startDragItemGroupState' on every insertion...
            if (showLogs)
              console.log(
                `\t DROP different container: '${activeContainer}' => '${overContainer}' - Add new copy of source item`
              );
            newItems = addNewToContainer(
              startDragItemGroupState,
              overContainer,
              overIndex,
              getNewItemForId(sourceItems, bId, overContainer, createUnknownItem)
            );
          } else {
            // Move item between sortable array
            if (showLogs)
              console.log(
                `\t DROP different container: '${activeContainer}' => '${overContainer}' - Delete and re-insert`
              );

            newItems = moveBetweenContainers(
              itemGroups,
              activeContainer,
              overContainer,
              overIndex,
              getNewItemForId(sourceItems, bId, overContainer, createUnknownItem)
            );
          }
        }

        return newItems;
      };

      updateLayout(updatedGroups());
    }

    setActiveId(undefined);
    setStartDragItemGroupState(undefined);

    if (showLogs) console.log(`-----------------------------------------`);
  };

  // -------------------------------------------------------------------------

  const getActiveDragObj = () => {
    const [bid, origContainer] = activeId?.split(DragIdGroupSeparator) ?? [undefined, undefined];
    if (bid && origContainer) return getNewItemForId(sourceItems, bid, origContainer, createUnknownItem);
    return undefined;
  };

  // -------------------------------------------------------------------------

  const removeObjById = (id: string) => {
    updateLayout(removeItemById(itemGroups, id));
  };

  // -------------------------------------------------------------------------

  return {
    handleDragStart,
    handleDragCancel,
    handleDragOver,
    handleDragEnd,
    getActiveDragObj,
    removeObjById,
  };
}
