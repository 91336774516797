import { useContext } from 'react';
import { RootContext } from '../../../../stores/storeProvidor';
import { SubReport } from './SubReportBase';

interface SubReportSelectorProps {
  reportId: string;
  subReports: SubReport[];
  activeSubReportId: string | undefined;
}

function SubReportSelector({ reportId, subReports, activeSubReportId }: SubReportSelectorProps) {
  const { activeReportStore } = useContext(RootContext);
  const baseReportSelectId = 'base-rpt-id';

  const onSubReportChanged = (subReportId: string) => {
    activeReportStore.setActiveSubReport(reportId, subReportId === baseReportSelectId ? undefined : subReportId);
  };

  const options = [{ id: undefined, title: 'Base Report' }, ...subReports];
  const baseIsSelected = activeSubReportId === undefined;

  return (
    <div className="flex-none bg-slate-200 rounded-xl p-2">
      <div className="font-bold text-lg text-center">Sub Reports</div>
      <select
        id="sub-reports"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 block w-full p-2"
        onChange={(e) => onSubReportChanged(e.target.value)}
        value={activeSubReportId ?? baseReportSelectId}
      >
        {options.map((s) => (
          <option key={s?.id ?? 'baseRpt'} value={s?.id ?? baseReportSelectId}>
            {s.title}
          </option>
        ))}
      </select>
      <div className="mt-2 flex gap-1">
        <button
          className="text-sm text-white bg-stone-400 rounded-lg px-2"
          type="button"
          onClick={() => {
            activeReportStore.clearSubReports(reportId, undefined);
          }}
        >
          Clear All
        </button>
        <button
          className={`text-sm text-white bg-stone-400 rounded-lg px-2 ${baseIsSelected ? 'opacity-50' : ''}`}
          type="button"
          onClick={() => {
            activeReportStore.clearSubReports(reportId, activeSubReportId);
          }}
          disabled={baseIsSelected}
        >
          Delete Sub Report
        </button>
        <button
          className={`grow text-sm text-white bg-green-400 rounded-lg px-2 ${baseIsSelected ? 'opacity-50' : ''}`}
          type="button"
          onClick={() => {
            activeReportStore.setActiveSubReport(reportId, undefined);
          }}
          disabled={baseIsSelected}
        >
          Base Report
        </button>
      </div>
    </div>
  );
}

export default SubReportSelector;
