import { makeAutoObservable } from 'mobx';
import { DateTime } from 'luxon';
import type RootStore from './rootStore';
import { SelectionItemStorage, SelectionNode, SelectionNodeType, SelectionType } from '../models/selectionModels';
import {
  AbsolutePanelState,
  addChildFolderNode,
  areTreesTheSame,
  copySourceNodeToTreeParent,
  deleteTreeNode,
  findNodeById,
  getRootFromSelectionStore,
  replaceParentsChildren,
  selectionSourceToDragItems,
  selectionTreeToDragItems,
  toggleExpandTreeNode,
  updateTreeNodeName,
} from '../utils/selectionTreeUtils';
import { baseId } from '../utils/dragUtils';
import {
  RelativePanelState,
  getDefaultRelativeDate,
  updateRelativeDateState as validateRelativeDateState,
} from '../utils/relativeDateUtils';

export interface TreeSelection {
  id: string;
  type: SelectionNodeType;
  hasChildren: boolean;
}

export function createStorageKey(containerId: string, embedded: boolean, selectionType: SelectionType) {
  return `${containerId}~${embedded ? 'E' : 'S'}~${selectionType}`;
}

export function extractDatasetFromStorageKey(key: string): string {
  const bits = key.split('~');
  return bits.length === 3 ? bits[2] : 'unknown';
}

export function CreateLevelKey(storageKey: string, levelId: string) {
  return `${storageKey}-${levelId}`;
}

export function baseKey(updateKey: string) {
  return updateKey.split('.')[0];
}

export function splitKey(updateKey: string): {
  containerId: string;
  isEmbedded: boolean;
  selectionType: SelectionType;
} {
  const [containerId, embedded, selType] = baseKey(updateKey).split('~');
  return {
    containerId,
    isEmbedded: embedded === 'E',
    selectionType: selType as SelectionType,
  };
}

// Holds selection items for drag/drop
class SelectionDragStore {
  private rootStore: RootStore;

  private activeDragInfo = new Map<string, SelectionItemStorage>();

  private selectedIds = new Map<string, Set<string>>();

  private selectedLevelIds = new Map<string, Map<string, string>>();

  private relativePanelState = new Map<string, RelativePanelState>();

  private absolutePanelState = new Map<string, AbsolutePanelState>();

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this);
  }

  getSelectionDragItems(updateKey: string): SelectionItemStorage {
    const { containerId, isEmbedded, selectionType } = splitKey(updateKey);
    let store = this.activeDragInfo.get(updateKey);
    if (store === undefined) {
      const nodeTree = this.rootStore.selectionStore.getSelectionTree(containerId, isEmbedded, selectionType);
      const sourceItems = this.rootStore.selectionStore.getSelectionSourceItems(selectionType);
      store = {
        sourceGroup: selectionSourceToDragItems(sourceItems),
        treeGroup: nodeTree ? selectionTreeToDragItems(nodeTree) : [],
      } as SelectionItemStorage;

      this.activeDragInfo.set(updateKey, store);
    }

    return store;
  }

  updateSelectionDragItems(updateKey: string, updatedStore: SelectionItemStorage) {
    const oldTree = getRootFromSelectionStore(
      this.activeDragInfo.get(updateKey) ??
        ({ actionGroup: [], sourceGroup: [], treeGroup: [] } as SelectionItemStorage)
    );
    // Save to local store
    this.activeDragInfo.set(baseKey(updateKey), updatedStore);

    // Has tree changed? (it may not have - eg UI changes only for drag/drop)
    const newTree = getRootFromSelectionStore(updatedStore);
    if (!areTreesTheSame(oldTree, newTree)) {
      const { containerId, isEmbedded, selectionType } = splitKey(updateKey);
      this.rootStore.selectionStore.updateSelectionTree(newTree, containerId, isEmbedded, selectionType);
    }
  }

  getSelectedTreeItems(updateKey: string): TreeSelection[] {
    const key = baseKey(updateKey);
    const store = this.activeDragInfo.get(key);
    if (store === undefined) return [];
    const selected = store.treeGroup.filter((item) => this.selectedIds.get(key)?.has(baseId(item)));
    return selected.map(
      (item) =>
        ({
          id: item.id,
          type: item.node?.nodeType ?? SelectionNodeType.Unknown,
          hasChildren: (item.node?.children?.length ?? 0) > 0,
        } as TreeSelection)
    );
  }

  clearSelectedTreeItems(updateKey: string) {
    const key = baseKey(updateKey);
    this.selectedIds.set(key, new Set<string>());
  }

  toggleSelectedTreeItem(updateKey: string, id: string) {
    const bid = baseId({ id });
    const key = baseKey(updateKey);
    let sels = this.selectedIds.get(key);
    if (!sels) {
      this.selectedIds.set(key, new Set<string>());
      sels = this.selectedIds.get(key);
    }
    if (sels?.has(bid)) sels.delete(bid);
    else {
      sels?.clear(); // Only allow one selection at a time
      sels?.add(bid);
    }
  }

  setSelectedTreeItem(updateKey: string, id: string) {
    const key = baseKey(updateKey);
    let sels = this.selectedIds.get(key);
    if (!sels) {
      this.selectedIds.set(key, new Set<string>());
      sels = this.selectedIds.get(key);
    }
    sels?.clear(); // Only allow one selection at a time
    sels?.add(baseId({ id }));
  }

  toggleExpandForTreeItem(updateKey: string, id: string) {
    const key = baseKey(updateKey);
    const store = this.activeDragInfo.get(key);
    if (store === undefined) return;
    this.updateSelectionDragItems(key, toggleExpandTreeNode(store, baseId({ id })));
  }

  deleteNode(updateKey: string, id: string) {
    const key = baseKey(updateKey);
    const { selectionType } = splitKey(updateKey);
    const store = this.activeDragInfo.get(key);
    if (store === undefined) return;
    this.updateSelectionDragItems(key, deleteTreeNode(store, baseId({ id }), selectionType));
  }

  renameTreeNode(updateKey: string, id: string, name: string) {
    const key = baseKey(updateKey);
    const store = this.activeDragInfo.get(key);
    if (store === undefined) return;
    this.updateSelectionDragItems(key, updateTreeNodeName(store, baseId({ id }), name));
  }

  addNewFolder(updateKey: string, parentId: string, folderName: string): string | undefined {
    const key = baseKey(updateKey);
    const store = this.activeDragInfo.get(key);
    if (store === undefined) return undefined;

    const { storage, newFolderNid } = addChildFolderNode(store, baseId({ id: parentId }), folderName);
    this.updateSelectionDragItems(key, storage);

    return newFolderNid;
  }

  addNodeToTree(updateKey: string, nodeToAdd: SelectionNode, parentNid: string | undefined = undefined) {
    // Get selected tree node for destination (use root if none)
    let dropNodeId = parentNid;
    if (dropNodeId === undefined) dropNodeId = this.getSelectedTreeItems(updateKey)[0]?.id ?? 'root';

    // Insert node and update store
    let currentStore = this.getSelectionDragItems(updateKey);
    currentStore = copySourceNodeToTreeParent(currentStore, nodeToAdd, dropNodeId);
    this.updateSelectionDragItems(updateKey, currentStore);
  }

  replaceNodesChildren(updateKey: string, parentNid: string, children: SelectionNode[]) {
    const currentStore = this.getSelectionDragItems(updateKey);
    this.updateSelectionDragItems(updateKey, replaceParentsChildren(currentStore, baseId({ id: parentNid }), children));
  }

  findNode(updateKey: string, nodeId: string, defaultToRoot: boolean): SelectionNode | undefined {
    const key = baseKey(updateKey);
    const store = this.activeDragInfo.get(key);
    if (store === undefined) return undefined;
    const root = getRootFromSelectionStore(store);
    return findNodeById(nodeId, root) ?? (defaultToRoot ? root : undefined);
  }

  getLevelSelection(levelKey: string): Map<string, string> {
    const sels = this.selectedLevelIds.get(levelKey);
    return sels === undefined ? new Map<string, string>() : sels;
  }

  updateLevelSelection(levelKey: string, id: string, name: string, flag: boolean) {
    const sels = this.selectedLevelIds.get(levelKey);
    if (sels) {
      if (flag) sels.set(id, name);
      else sels.delete(id);
    } else if (flag) {
      this.selectedLevelIds.set(levelKey, new Map<string, string>([[id, name]]));
    }
  }

  updateLevelMultiSelection(levelKey: string, filteredIds: string[], flag: boolean) {
    let sels = this.selectedLevelIds.get(levelKey);
    if (sels === undefined) {
      this.selectedLevelIds.set(levelKey, new Map<string, string>());
      sels = this.selectedLevelIds.get(levelKey);
    }

    if (flag)
      filteredIds.forEach((entry) => {
        const [name, id] = entry.split('|');
        sels?.set(id, name);
      });
    else {
      filteredIds.forEach((entry) => {
        const [, id] = entry.split('|');
        sels?.delete(id);
      });
    }
  }

  getRelativePanelState(stateKey: string): RelativePanelState {
    const state = this.relativePanelState.get(stateKey);
    return state || getDefaultRelativeDate();
  }

  setRelativePanelState(stateKey: string, state: RelativePanelState) {
    const newState = validateRelativeDateState(state, this.getRelativePanelState(stateKey));
    this.relativePanelState.set(stateKey, newState);
  }

  clearActiveSelection(statekey: string) {
    // Keys will only ever exist in one store, depending on type. Just try everywhere.

    // Active level selection only
    const lid = this.rootStore.selectionStore.selectionAccordionState.get(statekey);
    const levelKey = CreateLevelKey(statekey, lid ?? '');
    this.selectedLevelIds.delete(levelKey);

    // Any period selections
    this.relativePanelState.delete(statekey);
  }

  getAbsolutePanelState(stateKey: string): AbsolutePanelState {
    const state = this.absolutePanelState.get(stateKey);
    if (state) return state;
    const dateRange = this.rootStore.selectionStore.getSchemaDateRange();
    const lastDate = dateRange[1] ?? DateTime.local();
    return { startDate: lastDate.minus({ month: 1 }), endDate: lastDate };
  }

  updateAbsolutePanelState(stateKey: string, state: AbsolutePanelState) {
    this.absolutePanelState.set(stateKey, state);
  }
}

export default SelectionDragStore;
