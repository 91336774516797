interface SelectionToolButtonProps {
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  action: () => void;
  enabled: boolean;
  className?: string;
}

export default function SelectionToolButton({ Icon, action, enabled, className }: SelectionToolButtonProps) {
  let style = 'w-8 h-8 mx-1 p-1 inline rounded-md border border-gray-300 ';
  style += enabled ? 'text-gray-500 bg-white hover:text-black hover:border-blue-600' : 'text-gray-400 bg-gray-100';
  return (
    <div className={className}>
      <button
        type="button"
        className={style}
        onClick={(e) => {
          e.stopPropagation();
          action();
        }}
        disabled={!enabled}
      >
        <Icon />
      </button>
    </div>
  );
}

SelectionToolButton.defaultProps = {
  className: '',
};
