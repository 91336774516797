import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AdminGrid4x6, { AdminGrid4x6SingleColumn, AdminGrid4x6TripleColumn } from './AdminGrid';
import { useAdminTtile } from '../../hooks/useTitle';
import { GridCard } from '../controls/GridCard';
import { RootContext } from '../../stores/storeProvidor';
import Perms from '../../permissions/permissions';
import reportService from '../../services/reportService';
import useTimeout from '../../hooks/useTimeout';
import settingsService from '../../services/settingsService';

const AdminConfig = observer(() => {
  const rc = useContext(RootContext);
  const { activeUserStore } = rc;
  const adminStore = rc.getAdminStore();
  const [accessLevel, setAccessLevel] = useState<string>('');
  const [reportList, setReportList] = useState<string[]>();
  const [defaultTemplate, setDefaultTemplate] = useState<string>('none');
  const [baseTemplate, setBaseTemplate] = useState<string>('none');
  const [spotTemplate, setSpotTemplate] = useState<string>('none');

  useAdminTtile('Site Configuration', AdjustmentsHorizontalIcon);

  const refreshLevels = useCallback(() => {
    if (accessLevel === '') return;

    settingsService.api
      .getRawSettings(accessLevel, 'reportTemplateDefault', true)
      .then((v) => setDefaultTemplate(v ?? 'none'));

    settingsService.api
      .getRawSettings(accessLevel, 'reportTemplateBasic', true)
      .then((v) => setBaseTemplate(v ?? 'none'));

    settingsService.api
      .getRawSettings(accessLevel, 'reportTemplateSpotList', true)
      .then((v) => setSpotTemplate(v ?? 'none'));
  }, [accessLevel]);

  const accessOptions = useMemo(() => {
    const opts: string[] = [];
    if (activeUserStore.isSuperAdmin) {
      // Super admin can see all orgs + global
      opts.push('00000000-0000-0000-0000-000000000000|Global');
      adminStore.organisations.forEach((org) => {
        if (org.isActive) opts.push(`${org.uid}|${org.name}`);
      });
    } else {
      // Normal user access depends on their perms
      if (activeUserStore.hasPermission(Perms.EditGlobalSettings)) {
        opts.push('00000000-0000-0000-0000-000000000000|Global');
      }
      if (activeUserStore.hasPermission(Perms.EditOrgSettings)) {
        opts.push(`${activeUserStore.user?.orgId}|${activeUserStore.user?.orgName}`);
      }
    }
    setAccessLevel(opts.length > 0 ? opts[0].split('|')[0] : '');
    refreshLevels();
    return opts;
  }, [activeUserStore, adminStore.organisations, refreshLevels]);

  useEffect(() => {
    reportService.api
      .getAvailableReports()
      .then((r) => {
        const reportlist = r
          .filter((c) => c.isActive)
          .sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
          .map((c) => `${c.id}|${c.name}`);
        setReportList(['none|>> No selection at this level <<', ...reportlist]);
      })
      .catch(() => setReportList(['err|Error getting report']));
  }, []);

  const [triggerSave] = useTimeout(() => {
    // eslint-disable-next-line no-console
    console.log('Saving settings...', accessLevel, defaultTemplate, baseTemplate, spotTemplate);
    settingsService.api
      .saveRawSettings(accessLevel, 'reportTemplateDefault', defaultTemplate === 'none' ? undefined : defaultTemplate)
      .then(() => {
        settingsService.api.saveRawSettings(
          accessLevel,
          'reportTemplateBasic',
          baseTemplate === 'none' ? undefined : baseTemplate
        );
      })
      .then(() => {
        settingsService.api.saveRawSettings(
          accessLevel,
          'reportTemplateSpotList',
          spotTemplate === 'none' ? undefined : spotTemplate
        );
      })
      .then(() => {
        refreshLevels();
      });
  }, 500);

  if (accessOptions.length === 0) {
    return (
      <AdminGrid4x6 className="bg-blue-100">
        <div className="bg-red-500 text-white p-6 m-6 rounded-xl text-3xl text-center">
          You do not have access to these settings. Please contact your administrator for changes...
        </div>
      </AdminGrid4x6>
    );
  }

  const sep = <div className="border-b-2 border-gray-300 h-0 w-full" />;

  const templateControl = (label: string, choice: string, onChange: (v: string) => void) => (
    <div className="flex my-1">
      <div className="w-1/4 text-right">{label}:&nbsp;</div>
      <select
        id={label}
        value={choice}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-1/2 p-1 focus:ring-blue-500 focus:border-blue-500"
        onChange={(e) => onChange(e.target.value)}
      >
        {reportList?.map((opt) => {
          const [id, text] = opt.split('|');
          return (
            <option key={id} value={id}>
              {text}
            </option>
          );
        })}
      </select>
    </div>
  );

  return (
    <AdminGrid4x6 className="bg-blue-100">
      <AdminGrid4x6SingleColumn className="p-2">
        <h1>Site Configuration</h1>
        <div className="p-4">Cross organisation configuration + regional settings</div>
        {sep}
        <div className="text-center my-2">
          Your current role: <span className="font-bold">{activeUserStore.getUser?.roleName}</span>
        </div>
        {sep}
        <div className="text-center my-2">
          Show settings for&nbsp;
          <select
            id="configaccesslevel"
            value={accessLevel}
            className="inline bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-36 p-1 focus:ring-blue-500 focus:border-blue-500"
            onChange={(e) => {
              setAccessLevel(e.target.value);
              refreshLevels();
            }}
          >
            {accessOptions.map((opt) => {
              const [val, text] = opt.split('|');
              return (
                <option key={val} value={val}>
                  {text}
                </option>
              );
            })}
          </select>
          &nbsp;access
        </div>
        {sep}
      </AdminGrid4x6SingleColumn>
      <AdminGrid4x6TripleColumn className="text-justify border-x-2 border-gray-400 p-6">
        <GridCard>
          <div>
            <div className="text-md font-semibold">Report Templates</div>
            {templateControl('Default Report Template', defaultTemplate, (v) => {
              setDefaultTemplate(v);
              triggerSave();
            })}
            {templateControl('Base Report Template', baseTemplate, (v) => {
              setBaseTemplate(v);
              triggerSave();
            })}
            {templateControl('Spot Report Template', spotTemplate, (v) => {
              setSpotTemplate(v);
              triggerSave();
            })}
          </div>
        </GridCard>
        <GridCard>
          <div>
            Other organisation configuration + regional settings:
            <ul className="list-disc pl-6">
              <li>Default Locale</li>
              <li>Currency type and format</li>
              <li>Other value formats</li>
              <li>App Language</li>
              <li>Theme + Logo</li>
            </ul>
          </div>
        </GridCard>
      </AdminGrid4x6TripleColumn>
    </AdminGrid4x6>
  );
});

export default AdminConfig;
