import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import ErrorPage from '../error-page';
import HomeLayout from './HomeLayout';
import HomePage from '../Components/home/HomePage';
import AboutPage from '../Components/home/AboutPage';
import RegisterPage from '../Components/home/RegisterPage';
import ContactPage from '../Components/home/ContactPage';
import AuthenticatedRoute from '../auth/ProtectedRoute';
import AppLayout from './AppLayout';
import AdminLayout from './AdminLayout';
import AppHome from '../Components/app/AppHome';
import AdminDash from '../Components/admin/AdminDash';
import ReportsHome from '../Components/app/report/ReportsHome';
import AdminUsers from '../Components/admin/AdminUsers';
import MonitorDash from '../Components/monitor/MonitorDashboard';
import Report from '../Components/app/report/Report';
import AdminRoles from '../Components/admin/AdminRoles';
import AdminOrgs from '../Components/admin/AdminOrgs';
import AdminDataSources from '../Components/admin/AdminDataSorces';
import AdminConfig from '../Components/admin/AdminConfig';
import UiChecks from '../Components/admin/qa/UiChecks';
import QaLayout from '../Components/admin/qa/QaLayout';
import ChartChecks from '../Components/admin/qa/ChartChecks';
import DragChecks from '../Components/admin/qa/DragChecks';
import SelectAccordianChecks from '../Components/admin/qa/SelectAccordionCheck';
import ScrollVirtualizerChecks from '../Components/admin/qa/ScrollVirtualizerChecks';
import ScrollGridChecks from '../Components/admin/qa/ScrollGridChecks';
import DictBoxChecks from '../Components/admin/qa/DictBoxChecks';

// See https://reactrouter.com/en/main/route/route for options

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Open access Routes - Anonymous access ok */}
      <Route element={<HomeLayout />} path="/" errorElement={<ErrorPage />}>
        <Route element={<HomePage />} index />
        <Route element={<AboutPage />} path="about" />
        <Route element={<RegisterPage />} path="register" />
        <Route element={<ContactPage />} path="contact" />
      </Route>

      {/* Protected Routes - Any logged in User has access */}
      <Route element={<AuthenticatedRoute />} errorElement={<ErrorPage />}>
        <Route element={<AppLayout />} path="/app">
          <Route element={<AppHome />} index />
          <Route element={<ReportsHome />} path="myreports" />
          <Route element={<Report />} path="openreports" />
        </Route>
      </Route>

      {/* Protected Routes - Admin User has to be logged in */}
      <Route element={<AuthenticatedRoute />} errorElement={<ErrorPage />}>
        <Route element={<AdminLayout />} path="/admin">
          <Route element={<AdminDash />} index />
          <Route element={<AdminUsers />} path="users" />
          <Route element={<AdminRoles />} path="roles" />
          <Route element={<AdminOrgs />} path="orgs" />
          <Route element={<AdminDataSources />} path="data" />
          <Route element={<AdminConfig />} path="config" />
        </Route>
      </Route>

      {/* Protected Routes - Admin User has to be logged in */}
      <Route element={<AuthenticatedRoute />} errorElement={<ErrorPage />}>
        <Route element={<QaLayout />} path="/qa">
          <Route element={<UiChecks />} index />
          <Route element={<ChartChecks />} path="chart" />
          <Route element={<DragChecks />} path="drag" />
          <Route element={<SelectAccordianChecks />} path="accordion" />
          <Route element={<ScrollVirtualizerChecks />} path="vscroll" />
          <Route element={<ScrollGridChecks />} path="gridscroll" />
          <Route element={<DictBoxChecks />} path="dictbox" />
        </Route>
      </Route>

      {/* Protected Routes - Admin User has to be logged in */}
      <Route element={<AuthenticatedRoute />} errorElement={<ErrorPage />}>
        <Route element={<MonitorDash />} path="/monitor" />
      </Route>
    </>
  )
);

export default router;

// See https://github.com/remix-run/react-router/blob/f59ee5488bc343cf3c957b7e0cc395ef5eb572d2/docs/advanced-guides/migrating-5-to-6.md#relative-routes-and-links
// See https://saje.hashnode.dev/protected-routes-in-react-router-v6#heading-using-outlet
