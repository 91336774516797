import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootContext } from '../../../../../stores/storeProvidor';
import { SelectionType } from '../../../../../models/selectionModels';
import SelectionActionBar from '../SelectionActionBar';
import SelectAccordion from '../SelectAccordion';
import SelectionLevelPanel from './SelectLevelPanel';
import { createStorageKey } from '../../../../../stores/selectionDragStore';

interface DatasetSelectionProps {
  containerId: string;
  isEmbedded: boolean; // If true selection is embedded in a report. containerId is then reportId
  selectionType: SelectionType;
}

/*
 * NOTE: This is the OLD dataset selections
 * This is still used for the META(Other) selection tab.
 * Once a new 'OTHER' selection is fully implemented, this can be removed.
 */
const DatasetSelection = observer(({ containerId, isEmbedded, selectionType }: DatasetSelectionProps) => {
  const { selectionStore, selectionDragStore } = useContext(RootContext);
  const levels = selectionStore.getLevelsByType(selectionType, true);
  const stateKey = createStorageKey(containerId, isEmbedded, selectionType);

  return (
    <div className="select-none flex flex-row h-full w-full">
      <div className="grow">
        <SelectAccordion>
          {levels?.map((level, position) => (
            <SelectionLevelPanel key={level.id} level={level} position={position} stateKey={stateKey} />
          ))}
        </SelectAccordion>
      </div>
      <SelectionActionBar
        onAdd={() => selectionStore.addActiveSelectionToTree(stateKey, selectionType)}
        onMoveUp={() => {}}
        onMoveDown={() => {}}
        onClearSelection={() => selectionDragStore.clearActiveSelection(stateKey)}
      />
    </div>
  );
});

export default DatasetSelection;
