type AdminModalValidationBoxProps = {
  msgs: string[];
};

function AdminModalValidationBox({ msgs }: AdminModalValidationBoxProps) {
  if (msgs.length === 0) return null;
  return (
    <div className="text-red-700 text-sm bg-red-300 px-4 py-1 mt-4 font-bold rounded-md border-4 border-red-600 animate-pulse">
      {msgs.map((m) => {
        const sp = m.split('|');
        return <div key={m}>{sp.length === 1 ? sp[0] : sp[1]}</div>;
      })}
    </div>
  );
}

export default AdminModalValidationBox;
