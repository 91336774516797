interface CheckBoxProps {
  id: string;
  checked: boolean;
  disabled: boolean;
  onChange: (newState: boolean) => void;
}

export default function CheckBox({ id, checked, disabled, onChange }: CheckBoxProps) {
  return (
    <input
      id={id}
      type="checkbox"
      value=""
      checked={checked}
      onChange={(e) => {
        e.preventDefault();
        const f = e.target.checked;
        if (!disabled) {
          onChange(f);
        }
      }}
      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-1"
    />
  );
}
