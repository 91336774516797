interface DialogSelectProps {
  id: string;
  label: string;
  options: string[]; // All options, each defined as: 'id|name'
  selectedOption: string | undefined | null; // id of selected option. Undefined/Null if not set and using default
  defaultOption: string; // id of default option
  onSelect: (id: string, option: string) => void;
  disabled?: boolean;
}

export default function DialogSelect({
  id,
  label,
  options,
  selectedOption,
  defaultOption,
  onSelect,
  disabled,
}: DialogSelectProps) {
  const usingDefault = selectedOption == null;
  const activeOption = usingDefault ? defaultOption : selectedOption;
  const inputStyle = usingDefault ? 'text-gray-400' : 'text-gray-900';
  return (
    <label htmlFor={id} className={`block text-sm font-medium text-gray-900 ${disabled && 'opacity-50'}`}>
      {label}
      <select
        id={id}
        className={`${inputStyle} bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2`}
        onChange={(e) => {
          if (!disabled) onSelect(id, e.target.value);
        }}
        value={activeOption}
        disabled={disabled}
      >
        {options.map((option) => {
          const [oid, txt] = option.split('|');
          const txt2 = usingDefault && oid === defaultOption ? `${txt} (Default)` : txt;
          return (
            <option key={oid} value={oid}>
              {txt2}
            </option>
          );
        })}
      </select>
    </label>
  );
}

DialogSelect.defaultProps = {
  disabled: false,
};
