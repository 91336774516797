import { DictEntry } from '../models/dictSelection';

export const FILTER_SEPARATOR = '|';

export const FILTER_MODE_EXACT = 'exact';
export const FILTER_MODE_STARTS = 'starts';
export const FILTER_MODE_CONTAINS = 'contains';

export const FILTER_MODE_NOT = '!';

export const FILTER_ORDER_NAME_ASC = 'a';
export const FILTER_ORDER_NAME_DESC = 'd';
export const FILTER_ORDER_SELECTED_ASC = 's';
export const FILTER_ORDER_ID_ASC = 'u';
export const FILTER_ORDER_ID_DESC = 'r';

export const filterOrdersDictionary = [
  FILTER_ORDER_NAME_ASC,
  FILTER_ORDER_NAME_DESC,
  FILTER_ORDER_SELECTED_ASC,
  FILTER_ORDER_ID_ASC,
  FILTER_ORDER_ID_DESC,
];

export function getNextSortOrder(current: string): string {
  const currentIndex = filterOrdersDictionary.indexOf(current);
  return filterOrdersDictionary[(currentIndex + 1) % filterOrdersDictionary.length];
}

const compareNameWithSelect = (a: DictEntry, b: DictEntry): number => {
  if (a.selected > b.selected) return -1; // Note order: want selected items first
  if (a.selected < b.selected) return 1;
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

const compareId = (a: DictEntry, b: DictEntry): number => {
  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;
  return 0;
};

export default function filterDatasetEntries(unfiltered: DictEntry[], filter: string): DictEntry[] {
  const filterBits = filter.toLowerCase().split(FILTER_SEPARATOR);
  if (filterBits.length !== 4) return unfiltered;

  // See DictBoxSearchBar for filter source

  const order = filterBits[0].trim();
  const negated = filterBits[1].trim() === FILTER_MODE_NOT;
  const mode = filterBits[2].trim();
  const filterText = filterBits[3].trim();

  let filtered = [...unfiltered];
  if (filterText.length > 0) {
    if (mode === FILTER_MODE_EXACT) {
      filtered = unfiltered.filter((e) => {
        const name = e.name.toLowerCase();
        return negated ? name !== filterText : name === filterText;
      });
    } else if (mode === FILTER_MODE_STARTS) {
      filtered = unfiltered.filter((e) => {
        const name = e.name.toLowerCase();
        return negated ? !name.startsWith(filterText) : name.startsWith(filterText);
      });
    } else if (mode === FILTER_MODE_CONTAINS) {
      filtered = unfiltered.filter((e) => {
        const name = e.name.toLowerCase();
        return negated ? !name.includes(filterText) : name.includes(filterText);
      });
    }
  }

  // NOTE: we are assuming the unfiltered list is already sorted by NAME

  if (order === FILTER_ORDER_NAME_DESC) {
    return filtered.reverse();
  }

  if (order === FILTER_ORDER_SELECTED_ASC) {
    return filtered.sort(compareNameWithSelect);
  }

  if (order === FILTER_ORDER_ID_ASC) {
    return filtered.sort(compareId);
  }

  if (order === FILTER_ORDER_ID_DESC) {
    filtered.sort(compareId).reverse();
  }

  return filtered;
}
