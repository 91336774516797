import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootContext } from './storeProvidor';
import useTimeout from '../hooks/useTimeout';

const autoSaveTimeout = 5000;

const AutoSave = observer(() => {
  const { activeReportStore, selectionStore, uiState } = useContext(RootContext);

  const [triggerTimeoutFn, clearTimeoutFn] = useTimeout(() => {
    // Each store is responsible for saving its own data
    activeReportStore.autoSaveAllReports();
    selectionStore.autoSaveAllSelections();
  }, autoSaveTimeout);

  const { autoSavesNeeded } = uiState;
  useEffect(() => {
    // autoSaveNeeded is incremented on each change the user makes.
    // We want to 'retrigger' the auto save on each change - so we ensure the delay value is changed each time
    // (hence ' - uiState.autoSaveNeeded') so useInterval() will restart the timer...
    if (autoSavesNeeded === 0) clearTimeoutFn();
    else triggerTimeoutFn();
  }, [autoSavesNeeded, clearTimeoutFn, triggerTimeoutFn]);

  return null;
});

export default AutoSave;
