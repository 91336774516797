import ReportAPI from '../api/reportApi';

class ReportService {
  api: ReportAPI;

  constructor() {
    this.api = new ReportAPI();
  }
}

// Export only one instance of sevice (singleton)
const reportService = new ReportService();
export default reportService;
