import SettingsAPI from '../api/settingsApi';

class SettingsService {
  api: SettingsAPI;

  constructor() {
    this.api = new SettingsAPI();
  }
}

// Export only one instance of sevice (singleton)
const settingsService = new SettingsService();

export default settingsService;
