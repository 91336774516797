import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { ReactElement, RefObject, useRef, useState } from 'react';

interface RenameModalProps {
  title: string;
  originalName: string;
  description?: string | ReactElement | undefined;
  onConfirm: (newName: string) => void;
  onCancel?: () => void;
}

const RenameModal = NiceModal.create(({ title, originalName, description, onConfirm, onCancel }: RenameModalProps) => {
  const modal = useModal();

  // TODO: Ideally want useState() to do this but it causes focus issues as you type
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onRename = () => {
    modal.remove();
    const name = inputRef.current?.value;
    if (name && name.length > 0) {
      onConfirm(name);
    }
  };

  const onDiscard = () => {
    modal.remove();
    if (onCancel) onCancel();
  };

  return (
    <Modal show onClose={onCancel} size="sm" popup>
      <Modal.Body>
        <form onSubmit={onRename}>
          <h3 className="pt-6 pb-2">{title}</h3>
          <div>{description}</div>
          <div className="my-6">
            <label htmlFor="base-input" className="block mb-2 text-base font-normal text-gray-900 dark:text-white">
              New name:
              <input
                ref={inputRef}
                type="text"
                placeholder="Enter a new name..."
                defaultValue={originalName}
                id="base-input"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </label>
          </div>
          <div className="flex justify-center gap-4 mt-4">
            {/* <Button onClick={onRename} disabled={inputRef.current?.value.length === 0}> TODO - cannot use ref value dynamically like this! */}
            <Button type="submit">Rename</Button>
            <Button color="gray" onClick={onDiscard}>
              Cancel
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
});

RenameModal.defaultProps = {
  description: undefined,
  onCancel: undefined,
};

export default RenameModal;
