import { DateTime } from 'luxon';
import {
  endOfFinancialYear,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfFinancialYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from './dates';

export interface RelativePanelState {
  fromBase: string;
  fromOffsetPeriodUnits: string;
  fromOffsetPeriodCount: number;
  fromRange: [number, number];
  toBase: string;
  toOffsetPeriodUnits: string;
  toOffsetPeriodCount: number;
  toRange: [number, number];
}

export function getRelativeBaseOptions() {
  // NOTE: We expect to/from base tags to match the backend - see DateCalcs.cs
  const relativeBaseOptionsFrom = [
    'soy:Start of Year',
    'sofy:Start of Financial Year',
    'soq:Start of Quarter',
    'som:Start of Month',
    'sow:Start of Week',
    'days:Days Back',
    'week:Weeks Back',
    'mth:Months Back',
    'qtr:Quarters Back', // TODO: Remove
    'yr:Years Back',
  ];

  const relativeBaseOptionsTo = [
    'last:Latest day of data',
    'eoy:End of Last Year',
    'eofy:End of Last Financial Year',
    'eoq:End of Last Quarter',
    'eom:End of Last Month',
    'eow:End of Last Week',
    'days:Days Back from Latest',
    'week:Weeks Back from Latest',
    'mth:Months Back from Latest',
    'qtr:Quarters Back from Latest', // TODO: Remove
    'yr:Years Back from Latest',
  ];

  return [relativeBaseOptionsFrom, relativeBaseOptionsTo];
}

const yearOptions = ['soy', 'sofy', 'yr', 'eoy', 'eofy'];
const qtrOptions = ['soq', 'qtr', 'eoq'];
const monthOptions = ['som', 'mth', 'eom'];
const weekOptions = ['sow', 'week', 'eow'];
const dayOptions = ['days'];
const unitOptions = ['days', 'week', 'mth', 'qtr', 'yr'];

export enum RelativePeriodUnits {
  Year = 'y',
  Quarter = 'q',
  Month = 'm',
  Week = 'w',
  Day = 'd',
}

export function getPeriodUnitName(unit: RelativePeriodUnits, quantity: number) {
  const singular = quantity === 1;
  let unitName = singular ? 'Day' : 'Days';
  if (unit === RelativePeriodUnits.Week) unitName = singular ? 'Week' : 'Weeks';
  else if (unit === RelativePeriodUnits.Month) unitName = singular ? 'Month' : 'Months';
  else if (unit === RelativePeriodUnits.Quarter) unitName = singular ? 'Quarter' : 'Quarters';
  else if (unit === RelativePeriodUnits.Year) unitName = singular ? 'Year' : 'Years';
  return unitName;
}

export function getDefaultRelativeDate(): RelativePanelState {
  return {
    fromBase: 'soy',
    fromOffsetPeriodUnits: RelativePeriodUnits.Year,
    fromOffsetPeriodCount: 0,
    fromRange: [0, 5],
    toBase: 'last',
    toOffsetPeriodUnits: RelativePeriodUnits.Day,
    toOffsetPeriodCount: 0,
    toRange: [0, 0],
  } as RelativePanelState;
}

export function updateRelativeDateState(
  newState: RelativePanelState,
  oldState: RelativePanelState
): RelativePanelState {
  const ret = { ...newState };

  if (newState.fromBase !== oldState.fromBase) {
    if (yearOptions.includes(newState.fromBase)) {
      ret.fromOffsetPeriodUnits = RelativePeriodUnits.Year;
      ret.fromRange = [0, 5];
    } else if (qtrOptions.includes(newState.fromBase)) {
      ret.fromOffsetPeriodUnits = RelativePeriodUnits.Quarter;
      ret.fromRange = [0, 12];
    } else if (monthOptions.includes(newState.fromBase)) {
      ret.fromOffsetPeriodUnits = RelativePeriodUnits.Month;
      ret.fromRange = [0, 24];
    } else if (weekOptions.includes(newState.fromBase)) {
      ret.fromOffsetPeriodUnits = RelativePeriodUnits.Week;
      ret.fromRange = [0, 52];
    } else if (dayOptions.includes(newState.fromBase)) {
      ret.fromOffsetPeriodUnits = RelativePeriodUnits.Day;
      ret.fromRange = [1, 365];
    }
    // eslint-disable-next-line prefer-destructuring
    ret.fromOffsetPeriodCount = ret.fromRange[0];
    if (unitOptions.includes(newState.fromBase)) {
      ret.fromOffsetPeriodUnits += '.units';
    }
  }

  if (newState.toBase !== oldState.toBase) {
    if (newState.toBase === 'last') {
      ret.toRange = [0, 0];
    } else if (yearOptions.includes(newState.toBase)) {
      ret.toOffsetPeriodUnits = RelativePeriodUnits.Year;
      ret.toRange = [1, 5];
    } else if (qtrOptions.includes(newState.toBase)) {
      ret.toOffsetPeriodUnits = RelativePeriodUnits.Quarter;
      ret.toRange = [1, 12];
    } else if (monthOptions.includes(newState.toBase)) {
      ret.toOffsetPeriodUnits = RelativePeriodUnits.Month;
      ret.toRange = [1, 24];
    } else if (weekOptions.includes(newState.toBase)) {
      ret.toOffsetPeriodUnits = RelativePeriodUnits.Week;
      ret.toRange = [1, 52];
    } else if (dayOptions.includes(newState.toBase)) {
      ret.toOffsetPeriodUnits = RelativePeriodUnits.Day;
      ret.toRange = [0, 365];
    }
    ret.toOffsetPeriodCount = 0;
    if (unitOptions.includes(newState.fromBase)) {
      ret.toOffsetPeriodUnits += '.units';
    }
  }

  // TODO: Is this right? Wnat to prevent users from selecting 'from' date that
  // are after the 'to' date - try year/finyear/qtr/etc
  if (ret.toOffsetPeriodUnits === ret.fromOffsetPeriodUnits) {
    ret.toRange = [1, ret.toRange[1]];
  }

  return ret;
}

export interface DateSummary {
  summary: string;
  date: DateTime | undefined;
  error: string | undefined;
}

// TODO - get this from settings, or maybe from schema?
const sofy = DateTime.fromISO('2020-07-01');
const sow = 7; // ISO weekday, 1-7, where 1 is Monday and 7 is Sunday - see Luxon docs

export function getRelativeDateFromSummary(
  state: RelativePanelState,
  dbStartDate?: DateTime,
  toDate?: DateTime
): DateSummary {
  let ret: DateSummary = { summary: '', error: 'Unknown End Date', date: undefined };
  if (dbStartDate === undefined || toDate === undefined) return ret;

  let endDate = toDate;
  const count = state.fromOffsetPeriodCount;

  if (state.fromBase === 'soy') {
    endDate = startOfYear(endDate, count);
    ret = {
      summary: `Start-Of-Year${count > 0 ? `, minus ${count} years` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'sofy') {
    endDate = startOfFinancialYear(sofy, endDate, count);
    ret = {
      summary: `Start-Of-Year${count > 0 ? `, minus ${count} years` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'soq') {
    endDate = startOfQuarter(endDate, count);
    ret = {
      summary: `Start-Of-Quarter${count > 0 ? `, minus ${count} quarters` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'som') {
    endDate = startOfMonth(endDate, count);
    ret = {
      summary: `Start-Of-Month${count > 0 ? `, minus ${count} months` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'sow') {
    endDate = startOfWeek(sow, endDate, count);
    ret = {
      summary: `Start-Of-Week${count > 0 ? `, minus ${count} weeks` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'days') {
    endDate = endDate.minus({ days: count });
    ret = {
      summary: `'To' date, minus ${count} days`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'week') {
    endDate = endDate.minus({ weeks: count });
    ret = {
      summary: `'To' date, minus ${count} weeks`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'mth') {
    endDate = endDate.minus({ months: count });
    ret = {
      summary: `'To' date, minus ${count} months`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'qtr') {
    endDate = endDate.minus({ months: count * 3 });
    ret = {
      summary: `'To' date, minus ${count} quarters`,
      date: endDate,
      error: undefined,
    };
  } else if (state.fromBase === 'yr') {
    endDate = endDate.minus({ years: count });
    ret = {
      summary: `'To' date, minus ${count} years`,
      date: endDate,
      error: undefined,
    };
  }

  return ret;
}

export function getRelativeDateToSummary(state: RelativePanelState, dbEndDate?: DateTime): DateSummary {
  let ret: DateSummary = { summary: '', error: 'Unknown Data End Date', date: undefined };
  if (dbEndDate === undefined) return ret;

  let endDate = dbEndDate;
  const count = state.toOffsetPeriodCount;

  // Last Day
  if (state.toBase === 'last') {
    ret = { summary: 'Latest day of data', date: endDate, error: undefined };
  } else if (state.toBase === 'eoy') {
    endDate = endOfYear(dbEndDate, count);
    ret = {
      summary: `End-Of-Year${count > 0 ? `, minus ${count} years` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'eofy') {
    endDate = endOfFinancialYear(sofy, dbEndDate, count);
    ret = {
      summary: `End-Of-Financial-Year${count > 0 ? `, minus ${count} years` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'eoq') {
    endDate = endOfQuarter(dbEndDate, count);
    ret = {
      summary: `End-Of-Quarter${count > 0 ? `, minus ${count} quarters` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'eom') {
    endDate = endOfMonth(dbEndDate, count);
    ret = {
      summary: `End-Of-Month${count > 0 ? `, minus ${count} months` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'eow') {
    endDate = endOfWeek(sow, endDate, count);
    ret = {
      summary: `End-Of-Week${count > 0 ? `, minus ${count} weeks` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'day') {
    endDate = endDate.minus({ weeks: count });
    ret = {
      summary: `Latest${count > 0 ? `, minus ${count} days` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'week') {
    endDate = endDate.minus({ weeks: count });
    ret = {
      summary: `Latest${count > 0 ? `, minus ${count} weeks` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'mth') {
    endDate = endDate.minus({ months: count });
    ret = {
      summary: `Latest${count > 0 ? `, minus ${count} months` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'qtr') {
    endDate = endDate.minus({ months: count * 3 });
    ret = {
      summary: `Latest${count > 0 ? `, minus ${count} quarters` : ''}`,
      date: endDate,
      error: undefined,
    };
  } else if (state.toBase === 'yr') {
    endDate = endDate.minus({ years: count });
    ret = {
      summary: `Latest${count > 0 ? `, minus ${count} years` : ''}`,
      date: endDate,
      error: undefined,
    };
  }

  return ret;
}

export function getRelativePeriodNodeTitle(state: RelativePanelState): string {
  let title = 'Relative Period';

  if (state.toOffsetPeriodCount === 0) {
    if (state.fromBase === 'soy' && state.toBase === 'last') title = 'Year-To-Date';
    if (state.fromBase === 'sofy' && state.toBase === 'last') title = 'Financial-Year-To-Date';
    if (state.fromBase === 'soq' && state.toBase === 'last') title = 'Quarter-To-Date';
    if (state.fromBase === 'som' && state.toBase === 'last') title = 'Month-To-Date';

    if (state.fromBase === 'soy' && state.toBase === 'eoy') title = 'Last Year';
    if (state.fromBase === 'sofy' && state.toBase === 'eofy') title = 'Last Financial-Year';
    if (state.fromBase === 'soq' && state.toBase === 'eoq') title = 'Last Quarter';
    if (state.fromBase === 'som' && state.toBase === 'eom') title = 'Last Month';
    if (state.fromBase === 'sow' && state.toBase === 'eow') title = 'Last Week';
  } else {
    title = 'Relative Period: TODO';
  }

  if (state.fromOffsetPeriodCount > 0) {
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Day)
      title = `${title} minus ${state.fromOffsetPeriodCount} days`;
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Week)
      title = `${title} minus ${state.fromOffsetPeriodCount} weeks`;
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Month)
      title = `${title} minus ${state.fromOffsetPeriodCount} months`;
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Year)
      title = `${title} minus ${state.fromOffsetPeriodCount} years`;
  }

  return title;
}

export function getRelativePeriodNodeRange(state: RelativePanelState): { from: string; to: string } {
  // Expect to/from base tags to match the backend - see DateCalcs.cs
  let from = state.fromBase;
  let to = state.toBase;

  if (state.fromOffsetPeriodCount > 0) {
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Day) from = `${from},-${state.fromOffsetPeriodCount}D`;
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Week) from = `${from},-${state.fromOffsetPeriodCount}W`;
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Month) from = `${from},-${state.fromOffsetPeriodCount}M`;
    if (state.fromOffsetPeriodUnits === RelativePeriodUnits.Year) from = `${from},-${state.fromOffsetPeriodCount}Y`;
  }

  if (to === 'last') to = 'end';
  if (state.toOffsetPeriodCount > 0) {
    if (state.toOffsetPeriodUnits === RelativePeriodUnits.Day) to = `${from},-${state.toOffsetPeriodCount}D`;
    if (state.toOffsetPeriodUnits === RelativePeriodUnits.Week) to = `${from},-${state.toOffsetPeriodCount}W`;
    if (state.toOffsetPeriodUnits === RelativePeriodUnits.Month) to = `${from},-${state.toOffsetPeriodCount}M`;
    if (state.toOffsetPeriodUnits === RelativePeriodUnits.Year) to = `${from},-${state.toOffsetPeriodCount}Y`;
  }

  return { from, to };
}
