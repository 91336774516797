import { useContext } from 'react';
import SelectAccordion from '../SelectAccordion';
import SelectionActionBar from '../SelectionActionBar';
import { createStorageKey } from '../../../../../stores/selectionDragStore';
import { SelectionType } from '../../../../../models/selectionModels';
import RelativePeriodPanel from './RelativePeriodPanel';
import AbsolutePeriodPanel from './AbsolutePeriodPanel';
import { RootContext } from '../../../../../stores/storeProvidor';

interface PeriodSelectionProps {
  containerId: string;
  isEmbedded: boolean; // If true selection is embedded in a report. containerId is then reportId
}

export default function PeriodSelection({ containerId, isEmbedded }: PeriodSelectionProps) {
  const { selectionStore, selectionDragStore } = useContext(RootContext);
  const stateKey = createStorageKey(containerId, isEmbedded, SelectionType.Period);

  return (
    <div className="select-none flex flex-row h-full w-full">
      <div className="grow">
        <SelectAccordion>
          <RelativePeriodPanel stateKey={stateKey} />
          <AbsolutePeriodPanel stateKey={stateKey} />
        </SelectAccordion>
      </div>
      <SelectionActionBar
        onAdd={() => selectionStore.addActivePeriodToTree(stateKey)}
        onMoveUp={undefined}
        onMoveDown={undefined}
        onClearSelection={() => selectionDragStore.clearActiveSelection(stateKey)}
      />
    </div>
  );
}
