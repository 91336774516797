import { DateTime } from 'luxon';

export enum SchemaAccess {
  Creating = 'creating',
  Qa = 'qa',
  Live = 'live',
  Deleted = 'delted',
  Unknown = 'unknown',
}

export interface SchemaVersion {
  versionId: string;
  created: DateTime;
  updated: DateTime;
  firstDate: DateTime;
  lastDate: DateTime;
  access: SchemaAccess;
  note: string;
}

export interface Schema {
  schemaId: string;
  name: string;
  description: string;
  versions: SchemaVersion[];
}

export interface FilterLevel {
  id: string;
  dataset: string;
  name: string;
  description: string;
  idTag: string;
  type: string;
  result: string;
  sort: number;
}
