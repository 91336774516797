import { useCallback, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import AppGrid4x6 from '../AppGrid';
import ReportResultContainer from './ReportResultContainer';
import { RootContext } from '../../../stores/storeProvidor';
import ActionButton from '../../controls/ActionButton';
import ReportTabs from './ReportTabs';
import { HasResultsFromStatus, ShowProgressFromStatus } from '../../../models/reportModel';
import ReportInfoContainer from './ReportInfoContainer';
import ReportResultInfoPanel from './ReportResultInfoPanel';
import ReportEditInfoPanel from './ReportEditInfoPanel';
import SubReportInfoPanel from './subReports/SubReportInfoPanel';
import SubReportSelector from './subReports/SubReportSelector';
// import useCaptureAsImage from '../../../hooks/useCaptureAsImage';

const Report = observer(() => {
  const { uiState, activeReportStore, layoutDragStore } = useContext(RootContext);
  const navigate = useNavigate();

  // const { downloadAsImage } = useCaptureAsImage();

  const reportId = uiState.getActiveReportId;
  const activeReport = activeReportStore.getActiveReport(reportId);
  const reportDetails = activeReport?.report;
  const layoutResult = activeReportStore.getLayoutResult(reportId);

  // Use callbacks to get up-to-date state at render time
  const runStatus = layoutResult?.runStatus?.status;
  const haveResults = HasResultsFromStatus(runStatus);
  const isRunning = ShowProgressFromStatus(runStatus);
  const needsRunning = activeReport?.needsRunning;
  const runStatusMsg = runStatus ?? 'None';
  const isEditting = activeReport?.isEditting ?? true;
  const hasLayoutError = layoutDragStore.getReportLayoutValidationMessages(reportId).length > 0;
  const activeSubReportTitle = activeReportStore.getSubReport(reportId, undefined)?.title;

  // eslint-disable-next-line no-constant-condition
  if (false) {
    // eslint-disable-next-line no-console
    console.log('Report render:', reportId, isEditting, haveResults, isRunning, needsRunning, runStatusMsg);
  }

  useEffect(() => {
    if (reportDetails?.name === undefined) {
      // No open reports - redirect back to report home
      navigate('/app/myreports');
      return;
    }
    if (activeSubReportTitle) uiState.setAppTitle(`Report: ${reportDetails.name} -> ${activeSubReportTitle}`);
    else uiState.setAppTitle(`Report: ${reportDetails.name}`);
  }, [uiState, reportDetails?.name, navigate, activeSubReportTitle]);

  const reportAction = useCallback(() => {
    if (activeReport) {
      activeReportStore.setActiveSubReport(reportId, undefined); // any click on report clears active sub report - back to base report always
      if (isEditting) {
        if (needsRunning || !haveResults) {
          activeReportStore.runReport(reportId);
          // Cannot wait on promise. This is only to push to queue.
          // Instead exit edit mode when results arrive.
          // See updateRunStatus()
        } else {
          activeReportStore.setEditMode(reportId, false);
        }
      } else {
        activeReportStore.setEditMode(reportId, true);
      }
    }
  }, [activeReport, activeReportStore, haveResults, isEditting, needsRunning, reportId]);

  if (reportId === undefined) {
    return <div>Report not found</div>;
  }

  let buttonText = 'Edit Report';
  let buttonColour = 'bg-green-400';
  if (isEditting) {
    buttonText = haveResults ? 'Show Results' : 'Run Report';
    buttonColour = haveResults ? 'bg-blue-400' : 'bg-emerald-600';
  }

  const subReportId = activeReportStore.getActiveSubReportId(reportId);

  let resultPanel;
  if (isEditting) {
    resultPanel = <ReportEditInfoPanel className="flex-1" reportId={reportId} />;
  } else if (subReportId === undefined) {
    resultPanel = <ReportResultInfoPanel className="flex-1" reportId={reportId} />;
  } else {
    resultPanel = <SubReportInfoPanel className="flex-1" reportId={reportId} subReportId={subReportId} />;
  }

  const subReports = activeReportStore.getSubReportList(reportId);
  const subReportSelectPanel =
    subReports.length > 0 && !isEditting ? (
      <SubReportSelector reportId={reportId} subReports={subReports} activeSubReportId={subReportId} />
    ) : null;

  return (
    <AppGrid4x6>
      <div className="flex flex-col row-start-2 row-end-7 p-2 gap-2">
        <ReportInfoContainer className="shrink" reportId={reportId} />
        <hr className="my-2" />
        {resultPanel}
        {subReportSelectPanel}
        <ActionButton
          className={`flex-0 ${buttonColour} w-4/5 mx-auto my-2`}
          onClick={reportAction}
          disabled={isRunning || hasLayoutError}
        >
          {buttonText}
        </ActionButton>

        {/* Test image capture
        <ActionButton
          className={`flex-0 ${buttonColour} w-4/5 mx-auto my-2`}
          onClick={() => {
            downloadAsImage('report-container', 'sample.png');
          }}
          disabled={isRunning || hasLayoutError}
        >
          TEST
        </ActionButton>
        */}
      </div>
      <div className="flex flex-col col-span-3 row-start-2 row-end-6 text-justify border-l-2 border-gray-400">
        <ReportResultContainer
          className="flex-1 overflow-hidden"
          reportId={reportDetails?.id}
          subReportId={subReportId}
          showResults={!isEditting}
          progressMsg={isRunning ? runStatusMsg : undefined}
          onClickEmpty={() => {
            if (!isEditting) activeReportStore.clearGridSelection(reportId);
          }}
        />
      </div>
      <div className="col-span-3 row-start-6 row-end-7">
        <ReportTabs className="pl-1 h-12 bg-gray-800" />
      </div>
    </AppGrid4x6>
  );
});

export default Report;
