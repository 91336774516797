// Based on https://blog.galmalachi.com/react-and-jwt-authentication-the-right-way

import { useEffect, useRef, useContext } from 'react';
import { RootContext } from '../stores/storeProvidor';

// eslint-disable-next-line @typescript-eslint/ban-types
export default function useTokenExpiration(onTokenRefreshRequired: Function) {
  const clearAutomaticRefresh = useRef<number>();
  // const [tokenExpiration, setTokenExpiration] = useState<Date>();
  const { authStore } = useContext(RootContext);

  useEffect(() => {
    // Get a new access token with the refresh token when it expires
    const expiry = authStore.getTokenExpiry;
    if (expiry instanceof Date && !Number.isNaN(expiry.valueOf())) {
      const now = new Date();
      const triggerAfterMs = expiry.getTime() - now.getTime();

      // Timer to refresh when token expires
      clearAutomaticRefresh.current = window.setTimeout(async () => {
        onTokenRefreshRequired();
      }, triggerAfterMs);
    }

    return () => {
      window.clearTimeout(clearAutomaticRefresh.current);
    };
  }, [onTokenRefreshRequired, authStore]);

  const setTokenExpiration = (expiry: Date | undefined) => {
    authStore.setTokenExpiry(expiry);
  };

  const clearAutomaticTokenRefresh = () => {
    window.clearTimeout(clearAutomaticRefresh.current);
    authStore.setTokenExpiry(undefined);
    setTokenExpiration(undefined);
  };

  return {
    clearAutomaticTokenRefresh,
    setTokenExpiration,
  };
}
