import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { BreakoutObj } from '../../../../models/breakoutModels';
import { Droppable, Sortable } from '../../../controls/dnd';
import { BreakoutItem } from './BreakoutItem';
import { baseId } from '../../../../utils/dragUtils';
import { PAGES_GROUP } from '../../../../hooks/useLayoutDragging';

function AxisTitle({ title }: { title: string }) {
  return <div className="text-md text-gray-700 font-light italic text-center p-2 tracking-widest">{title}</div>;
}

interface DropAxisProps {
  collectionId: string;
  title: string;
  className: string;
  breakouts: BreakoutObj[];
  active: BreakoutObj | undefined;
  removeBreakout: (id: string) => void;
}

export default function DropAxis({ collectionId, title, className, breakouts, active, removeBreakout }: DropAxisProps) {
  return (
    <SortableContext id={collectionId} items={breakouts.map((x) => x.id)} strategy={rectSortingStrategy}>
      <Droppable
        id={collectionId}
        renderItem={(ref, children, isOver) => (
          <div
            ref={ref}
            className={`bg-slate-200 ${className} ${isOver ? 'border-4 border-blue-800' : 'border border-gray-500'}`}
          >
            {children}
          </div>
        )}
      >
        <AxisTitle title={title} />
        {breakouts.length === 0 ? (
          <div className="text-center font-bold text-xl opacity-25">
            <div className="italic">Drag items here...</div>
            {collectionId !== PAGES_GROUP ? (
              <div className="text-red-500 text-base pt-4">The {title} must contain at least one breakout...</div>
            ) : null}
          </div>
        ) : (
          <div className="">
            <div className="w-3/4 mx-auto flex flex-col justify-items-center">
              {breakouts.map((item) => (
                <Sortable
                  key={item.id}
                  id={item.id}
                  useOverlay={false}
                  renderItem={(ref, sortChildren, style, listeners, attributes) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <div ref={ref} style={style} {...listeners} {...attributes} className="">
                      {sortChildren}
                    </div>
                  )}
                >
                  <BreakoutItem
                    obj={item}
                    isActive={active ? baseId(item) === baseId(active) : false}
                    isSelected={false}
                    closeFn={() => removeBreakout(item.id)}
                  />
                </Sortable>
              ))}
            </div>
          </div>
        )}
      </Droppable>
    </SortableContext>
  );
}
