import { makeAutoObservable } from 'mobx';
import { COLS_GROUP, PAGES_GROUP, ROWS_GROUP } from '../hooks/useLayoutDragging';
import { BreakoutObj, BreakoutType } from '../models/breakoutModels';
import { LayoutItemStorage, baseId, getNewItemWithGroupInId, SOURCE_GROUP } from '../utils/dragUtils';
import type RootStore from './rootStore';

// Holds current layout for each open report
class LayoutDragStore {
  private rootStore: RootStore;

  private activeDragInfoByReportId = new Map<string, LayoutItemStorage<BreakoutObj>>();

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this);
  }

  getReportLayoutDragItems(reportId: string | undefined, allBreakouts: BreakoutObj[]): LayoutItemStorage<BreakoutObj> {
    const empty = { sourceGroup: [], pagesGroup: [], rowsGroup: [], colsGroup: [] } as LayoutItemStorage<BreakoutObj>;
    if (reportId) {
      const store = this.activeDragInfoByReportId.get(reportId);
      if (store) return store;
      const [pageItems, rowItems, colItems] = this.rootStore.activeReportStore.getReportLayout(reportId, allBreakouts);
      const newStore = {
        // NOTE: all items passed in MUST have their id appended by the group name, ie: <id>|<group>
        sourceGroup: allBreakouts.map((o) => getNewItemWithGroupInId(o, SOURCE_GROUP)),
        pagesGroup: pageItems ? pageItems.map((o) => getNewItemWithGroupInId(o, PAGES_GROUP)) : [],
        rowsGroup: rowItems ? rowItems.map((o) => getNewItemWithGroupInId(o, ROWS_GROUP)) : [],
        colsGroup: colItems ? colItems.map((o) => getNewItemWithGroupInId(o, COLS_GROUP)) : [],
      } as LayoutItemStorage<BreakoutObj>;
      this.updateReportLayoutDragItems(reportId, newStore);
      return newStore;
    }
    return empty;
  }

  updateReportLayoutDragItems(reportId: string, updatedStore: LayoutItemStorage<BreakoutObj>) {
    return this.activeDragInfoByReportId.set(reportId, updatedStore);
  }

  cleanupLayoutDragItems(reportId: string) {
    this.activeDragInfoByReportId.delete(reportId);
  }

  getReportLayoutValidationMessages(reportId: string): string[] {
    const store = this.activeDragInfoByReportId.get(reportId);
    if (store === undefined) return [];

    const msgs: string[] = [];
    const usedItems = [
      ...store.pagesGroup.map((x) => baseId({ id: x.id })),
      ...store.rowsGroup.map((x) => baseId({ id: x.id })),
      ...store.colsGroup.map((x) => baseId({ id: x.id })),
    ];
    const usedTypes = new Set([
      ...store.pagesGroup.map((x) => x.type),
      ...store.rowsGroup.map((x) => x.type),
      ...store.colsGroup.map((x) => x.type),
    ]);

    const counts = new Map<string, number>();
    const dups = new Set<string>();
    usedItems.forEach((e) => {
      const c = counts.get(e);
      if (c === undefined) counts.set(e, 1);
      else {
        counts.set(e, c + 1);
        dups.add(e);
      }
    });

    // Rows and cols must have items
    if (store.rowsGroup.length === 0) msgs.push('You must have a breakout on the left (row) axis');
    if (store.colsGroup.length === 0) msgs.push('You must have a breakout on the top (column) axis');

    // Datatypes have to be on one axis
    if (usedItems.filter((x) => x === 'dts').length === 0) msgs.push('Datatypes must be on one axis');

    // Are items dragged multiple times?
    if (dups.size > 0) msgs.push('You have the same breakout dragged to multiple axis. Remove from one.');

    // Cannot have more than 10 breakout items in whole layout
    if (usedItems.length > 10) msgs.push('A report cannnot have more than 10 breakouts...');

    // Unknown items
    if (usedTypes.has(BreakoutType.UnknownType))
      msgs.push("There are 'Unknown' items. Please remove these before runnning.");

    return msgs;
  }
}

export default LayoutDragStore;
