import { Data, DraggableAttributes, useDraggable, useDroppable } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

type DndDropProps = {
  renderItem?: (
    ref: (element: HTMLElement | null) => void,
    children: ReactNode | undefined,
    isOver: boolean
  ) => ReactElement;
  id: string;
  onOverFn?: (over: boolean) => void | undefined;
  data?: Data | undefined;
};

export function Droppable({ renderItem, children, id, onOverFn, data }: PropsWithChildren<DndDropProps>) {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data,
  });

  if (onOverFn !== undefined) {
    if (isOver) onOverFn(true);
    else onOverFn(false);
  }

  return renderItem ? renderItem(setNodeRef, children, isOver) : <div ref={setNodeRef}>{children}</div>;
}

Droppable.defaultProps = {
  renderItem: undefined,
  onOverFn: undefined,
  data: undefined,
};

// --------------------------------------------------------------------------

type DndDragProps = {
  id: string;
  useOverlay?: boolean;
  data?: Data | undefined;
  className?: string;
};

export function Draggable({ children, id, useOverlay, data, className }: PropsWithChildren<DndDragProps>) {
  const { attributes, listeners, setNodeRef, transform, active, isDragging } = useDraggable({
    id,
    data,
  });

  // Update position only if not using <DragOverlay>
  const style =
    transform && (useOverlay ?? false) === false
      ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        }
      : undefined;

  // eslint-disable-next-line no-console
  // if (active?.id) console.log(`Draggable: ${active.id} ${isDragging}`);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={setNodeRef} style={style} className={className} {...listeners} {...attributes}>
      {children}
    </div>
  );
}

Draggable.defaultProps = {
  useOverlay: true,
  data: undefined,
  className: undefined,
};

// --------------------------------------------------------------------------

type DndSortableProps = {
  renderItem?: (
    ref: (element: HTMLElement | null) => void,
    children: ReactNode | undefined,
    style: React.CSSProperties | undefined,
    listeners: SyntheticListenerMap | undefined,
    attributes: DraggableAttributes
  ) => ReactElement;
  id: string;
  useOverlay?: boolean;
};

export function Sortable({ renderItem, children, id, useOverlay }: PropsWithChildren<DndSortableProps>) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  // Update position only if not using <DragOverlay>
  const style =
    (transform || transition) && (useOverlay ?? false) === false
      ? {
          transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
          transition,
          opacity: isDragging ? 0.25 : 1,
        }
      : undefined;

  if (renderItem) {
    // render-prop if we need to override base item
    return renderItem(setNodeRef, children, style, listeners, attributes);
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
}

Sortable.defaultProps = {
  renderItem: undefined,
  useOverlay: true,
};
