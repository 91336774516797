import { useState, useContext } from 'react';
import { Navbar } from 'flowbite-react';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import NavbarLink from '../controls/NavbarLink';
import ActionButton from '../controls/ActionButton';
import LoginDialog from './LoginDialog';
import { RootContext } from '../../stores/storeProvidor';

const HomeNav = observer(() => {
  const { t, i18n } = useTranslation(['home']);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const { authStore, activeUserStore: userStore, uiState } = useContext(RootContext);

  function closeLogin() {
    setShowLoginDialog(false);
  }

  function onButtonClick() {
    if (userStore.isAuthenticated) {
      authStore.logout();
      uiState.successAlert = t('logoutmsg');
    } else {
      setShowLoginDialog(true);
    }
  }

  return (
    <>
      <Navbar fluid rounded className="bg-primary">
        <Navbar.Brand href="/">
          <ChartBarSquareIcon className="h-10 w-10 text-secondary hover:animate-spin" />
          <span className="self-center whitespace-nowrap text-xl font-semibold text-primary">
            {import.meta.env.VITE_APP_TITLE}
          </span>
        </Navbar.Brand>
        <div className="flex md:order-2">
          <ActionButton
            onClick={() => {
              onButtonClick();
            }}
          >
            {userStore.isAuthenticated ? t('button.logout') : t('button.login')}
          </ActionButton>
          
          <ActionButton
            className="bg-teal-200 mr-2"
            onClick={() => {
              let next = i18n.languages.indexOf(i18n.language) + 1;
              if (next >= i18n.languages.length) next = 0;
              i18n.changeLanguage(i18n.languages[next]);
            }}
          >
            {i18n.language.toUpperCase()}
          </ActionButton>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          <NavbarLink to="/">{t('nav.home')}</NavbarLink>
          <NavbarLink to="/about">{t('nav.about')}</NavbarLink>
          <NavbarLink to="/register">{t('nav.register')}</NavbarLink>
          <NavbarLink to="/contact">{t('nav.contact')}</NavbarLink>
          {userStore.isAuthenticated ? <NavbarLink to="/app">{t('nav.app')}</NavbarLink> : ''}
        </Navbar.Collapse>
      </Navbar>
      <LoginDialog showLogin={showLoginDialog} onClose={closeLogin} />
    </>
  );
});

export default HomeNav;
