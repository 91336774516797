import { makeAutoObservable, runInAction } from 'mobx';
import type RootStore from './rootStore';
import authService from '../services/authService';
import { AuthToken, LoginRequest } from '../models/authModels';

class AuthStore {
  private rootStore: RootStore;

  token?: AuthToken = undefined;

  initialRefreshDone = false; // Need refresh after startup

  constructor(root: RootStore) {
    this.rootStore = root;

    makeAutoObservable(this);
  }

  async login(email: string, password: string) {
    try {
      const response = await authService.api.login({ email, password } as LoginRequest);
      this.rootStore.activeUserStore.setUser(response.user);
      this.updateToken(response as AuthToken);
    } catch (e) {
      this.rootStore.activeUserStore.clearUser();
      this.clearToken();
      throw e;
    } finally {
      runInAction(() => {
        this.initialRefreshDone = true;
      });
    }
  }

  async logout() {
    authService.api.logout().finally(() => {
      runInAction(() => {
        this.clearToken();
        this.rootStore.activeUserStore.clearUser();
        this.initialRefreshDone = false;
      });
    });
  }

  async refreshToken() {
    try {
      const response = await authService.api.refreshToken();
      this.rootStore.activeUserStore.setUser(response.user);
      this.updateToken(response as AuthToken);
    } catch (e) {
      this.rootStore.activeUserStore.clearUser();
      this.clearToken();
    } finally {
      runInAction(() => {
        this.initialRefreshDone = true;
      });
    }
  }

  clearToken() {
    this.updateToken(undefined);
  }

  async updateToken(tok: AuthToken | undefined) {
    this.token = tok;
    if (this.token?.accessToken !== undefined) {
      authService.api.updateAccessToken(this.token.accessToken);
    } else {
      authService.api.clearAccessToken();
    }
  }

  setTokenExpiry(expiry: Date | undefined) {
    if (expiry === undefined) {
      this.clearToken();
    } else if (this.token !== undefined) {
      this.token.tokenExpiration = expiry;
    }
  }

  get getToken() {
    return this.token?.accessToken;
  }

  get getTokenExpiry() {
    return this.token?.tokenExpiration;
  }
}

export default AuthStore;
