import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Button } from 'flowbite-react';
import { PuzzlePieceIcon } from '@heroicons/react/24/outline';
import NiceModal from '@ebay/nice-modal-react';
import AdminGrid4x6 from '../AdminGrid';
import { RootContext } from '../../../stores/storeProvidor';
import DemoModal from '../../modals/DemoDialog';
import ConfirmModal from '../../modals/ConfirmModal';
import TestTable, { TableSpanTest } from '../../controls/table/TestTable';
import EditLabel from '../../controls/EditLabel';
import useTimeout from '../../../hooks/useTimeout';

type CardProps = {
  span?: string;
};

function QaCard({ span, children }: PropsWithChildren<CardProps>) {
  return <div className={`${span} bg-gray-100 m-1 p-2 rounded-xl border-2 flex flex-wrap`}>{children}</div>;
}

QaCard.defaultProps = {
  span: 'col-span-auto',
};

let labelTest = '';

function UiChecks() {
  const { uiState, activeUserStore: userStore } = useContext(RootContext);
  useEffect(() => {
    uiState.setAdminTitle('UI Tests', PuzzlePieceIcon);
  }, [uiState]);

  const [triggerCount, setTriggerCount] = useState(0);
  const [resolveCount, setResolveCount] = useState(0);
  const [triggerTimer, resetTimer] = useTimeout(() => {
    setResolveCount(resolveCount + 1);
  }, 5000);

  const showDemoModal = () => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(DemoModal, { name: userStore.user?.firstName ?? '<no-name>' });
  };

  const showConfirmModal = () => {
    NiceModal.show(ConfirmModal, {
      title: 'Delete Report',
      body: (
        <div>
          Are you sure you want the delete the report <b>Very-Important-Report</b> ?
        </div>
      ),
      onYes: () => {
        uiState.successAlert = 'You clicked "Yes"';
      },
      onNo: () => {
        uiState.warningAlert = 'You clicked "No"';
      },
    });
  };

  return (
    <AdminGrid4x6 className="bg-blue-50">
      <QaCard>
        <h1 className="text-xl font-bold text-center w-full">Alert Checks</h1>
        <Button
          className="m-1 text-xs font-light bg-red-500"
          onClick={() => {
            uiState.errorAlert = "I'm an error!";
          }}
        >
          Error Alert
        </Button>
        <Button
          className="m-1 text-xs font-light bg-orange-500"
          onClick={() => {
            uiState.warningAlert = "I'm a Warning!";
          }}
        >
          Warning Alert
        </Button>
        <Button
          className="m-1 text-xs font-light bg-green-500"
          onClick={() => {
            uiState.successAlert = "I'm a Success!";
          }}
        >
          Success Alert
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            uiState.infoAlert = "I'm some Info!";
          }}
        >
          Info Alert
        </Button>
        <Button
          className="m-1 text-xs font-light bg-yellow-500"
          onClick={() => {
            uiState.criticalAlert = "I'm very important!";
          }}
        >
          Critical Alert
        </Button>
      </QaCard>

      <QaCard>
        <h1 className="text-xl font-bold text-center w-full">Modal Checks</h1>
        <Button className="m-1 text-xs font-light bg-lime-500" onClick={showDemoModal}>
          Show Test Dialog
        </Button>
        <Button className="m-1 text-xs font-light bg-amber-600" onClick={showConfirmModal}>
          Confirmation Dialog
        </Button>
      </QaCard>

      <QaCard span="col-span-2">
        <h1 className="text-xl font-bold text-center w-full">Table Test</h1>
        <TestTable />
      </QaCard>

      <QaCard span="col-span-2">
        <h1 className="text-xl font-bold text-center w-full">Span Test</h1>
        <TableSpanTest />
      </QaCard>

      <QaCard>
        <h1 className="text-xl font-bold text-center w-full">Label Edit</h1>
        <EditLabel
          id="testa"
          value={labelTest}
          onChange={(val) => {
            labelTest = val;
            uiState.successAlert = `Text changed to "${val}"`;
          }}
          placeholder="Name"
        />
      </QaCard>
      <QaCard>
        <h1 className="text-xl font-bold text-center w-full">Retriggerable Timer Check</h1>
        <Button
          className="m-1 text-xs font-light bg-green-300"
          onClick={() => {
            triggerTimer();
            setTriggerCount(triggerCount + 1);
          }}
        >
          Trigger timer (5 sec)
        </Button>
        <Button
          className="m-1 text-xs font-light bg-red-300"
          onClick={() => {
            resetTimer();
          }}
        >
          Clear timer
        </Button>
        <div className="w-full">
          <ul>
            <li>Timer Trigger Count: {triggerCount}</li>
            <li>Timer Complete Count: {resolveCount}</li>
          </ul>
        </div>
      </QaCard>
    </AdminGrid4x6>
  );
}

export default UiChecks;
