import { useContext, useEffect, useRef, useState } from 'react';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { Button } from 'flowbite-react';
import { range, round } from 'lodash';
import { useVirtualizer } from '@tanstack/react-virtual';
import { RootContext } from '../../../stores/storeProvidor';
import AppGrid4x6 from '../../app/AppGrid';

function randomString(length: number) {
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = Math.floor(Math.random() * length); i > 0; i -= 1)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

function ScrollVirtualizerChecks() {
  const { uiState } = useContext(RootContext);
  const [debugMode, setDebugMode] = useState(false);
  const [showHeaders, setShowHeaders] = useState(false);
  const [rowData, setRowData] = useState<string[]>([]);

  const headerHeight = 70;

  useEffect(() => {
    uiState.setAdminTitle('Scroll Virtualizer Tests', ArrowsUpDownIcon);
  }, [uiState]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const rowsLength = rowData.length;

  // See: https://tanstack.com/virtual/v3/docs/framework/react/examples/table
  // and: https://tanstack.com/virtual/v3/docs/framework/react/examples/variable
  const virtualizer = useVirtualizer({
    count: rowsLength,
    getScrollElement: () => scrollContainerRef.current,
    estimateSize: () => 34, // <--- How do we calculate this? Its the row height, and needs to match content exactly
    overscan: 4, // Check top/bottom of list as we scroll. We want partial rows to draw correctly.
    // paddingStart: 0,
    paddingEnd: showHeaders ? headerHeight : 0,
    // scrollMargin: headerHeight
    // scrollPaddingEnd: headerHeight,
  });

  /*
    The virtualizer only draws the visible rows, out of a larger dataset.
    The virtualiser does this by inflating the height of the element contained by the scroll bar; This
    make the scroll bar match the 'actual' rows. Then a list of 'virtual' rows is drawn - each with its own
    height/offset. The scroll bar will move this virtual list of items. Each item is mapped back to the actual
    data.

    Now using a <table> can cause issues with this. The actual html for the table, only ever covers the 
    visible display (plus any 'overscan' specified). ie it is NOT the full height of the virtual list.
    Because the table is within the scrollable div, it is scrolled too unless we offset it. If this is missed,
    you will find that the cell formatting (especially borders and backgrounds) will not be
    drawn correctly past the first page, as the underlying table has been scrolled off the top of the screen.
    
    None of the online examples seem to mention this. See:
    - https://tanstack.com/virtual/latest/docs/framework/react/examples/table

    I've fixed this here. Below we shift the Y position of the table by the offset of the first visible row 
    (see the <table> defined below). This then has to be taken into account when offsetting the rows 
    within the table (see the <tr>'s defined below).

    This fixes the scroll/formatting issues.
  */

  const totalVirtualHight = virtualizer.getTotalSize();
  const virtualItems = virtualizer.getVirtualItems();

  // Shift table by offset of first visible row (NOT scrollOffset !)
  const tableShift = virtualItems.length > 0 && totalVirtualHight > 0 ? virtualItems[0].start : 0;

  return (
    <AppGrid4x6>
      <div className="col-start-0 col-span-1 row-start-2 row-end-6 m-2">
        <h1>Scroll Virtualizer Test</h1>
        <p>Check scroll virtualize:</p>
        <ul className="ml-4 list-disc list-inside">
          <li>Must handle massive amounts of rows</li>
          <li>Expands as more total rows are added</li>
          <li>Scroll to bottom shows full extent of last row</li>
          <li>Window resize maintains scroll extent and position</li>
          <li>Page-Size changes as rows are added</li>
          <li>Check console - verify only visible rows are drawn</li>
          <li>
            <span className="bg-red-700 text-yellow-100 p-1 rounded-md">IMPORTANT:</span> verify that the formatting
            (especially borders/background) are maintained as you scroll past the first page.
          </li>
        </ul>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            setDebugMode(debugMode === false);
          }}
        >
          {debugMode ? 'Hide' : 'Show'} Debug info (in console)
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            setRowData([...rowData, `${rowData.length}|${randomString(10)} ${randomString(10)}`]);
          }}
        >
          Add a row
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            const nr = range(0, 100).map((i: number) => `${rowData.length + i}|${randomString(10)} ${randomString(5)}`);
            setRowData([...rowData, ...nr]);
          }}
        >
          Add 100 rows
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            const nr = range(0, 10000).map(
              (i: number) => `${rowData.length + i}|${randomString(5)} ${randomString(10)}`
            );
            setRowData([...rowData, ...nr]);
          }}
        >
          Add 10K rows
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            setRowData([]);
          }}
        >
          Clear Rows
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            setShowHeaders(showHeaders === false);
          }}
        >
          {showHeaders ? 'Hide' : 'Show'} Table Header
        </Button>
      </div>
      <div className="col-start-2 col-span-2 row-start-2 row-end-7 m-2">
        <div className="w-full h-full bg-red-200 p-4 overflow-auto" ref={scrollContainerRef}>
          <div style={{ height: `${totalVirtualHight}px` }} className="bg-white select-none w-full">
            <table
              className="w-full border-collapse border border-slate-500 bg-green-100"
              style={{
                transform: `translateY(${tableShift}px)`,
              }}
            >
              {showHeaders && (
                <thead>
                  <tr
                    className="bg-gray-200 border border-gray-400 text-left text-4xl"
                    style={{
                      height: `${headerHeight}px`,
                    }}
                  >
                    <th className="w-12 px-4">Row</th>
                    <th className="px-4 ">Random Stuff</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {/* Only draw the virtual rows - note mapping back to actual row */}
                {virtualItems.map((virtualRow, uiIndex) => {
                  if (rowData.length > virtualRow.index) {
                    const row = rowData[virtualRow.index] as string;
                    const [rowNum, rowInfo] = row.split('|');

                    if (debugMode && (uiIndex === 0 || uiIndex === virtualItems.length - 1)) {
                      // eslint-disable-next-line no-console
                      console.log(
                        `${uiIndex === 0 ? 'FROM' : '  TO'} UI Row: ${uiIndex} -> Data Row: ${
                          virtualRow.index
                        }, Offset: ${virtualRow.start}px, Table Shift: ${tableShift}px`
                      );
                    }

                    return (
                      <tr
                        key={rowNum}
                        className="border border-blue-600 text-left"
                        style={{
                          height: `${virtualRow.size}px`,
                          transform: `translateY(${virtualRow.start - tableShift - uiIndex * virtualRow.size}px)`,
                        }}
                      >
                        <td className="px-4 font-black">{rowNum}</td>
                        <td
                          className={`px-4 capitalize ${
                            virtualRow.index % 2 === 0 ? 'bg-orange-300' : 'bg-orange-200'
                          }`}
                        >
                          {rowInfo}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-start-4 col-span-2 row-start-3 row-end-5 m-2">
        <div className="bg-blue-100 p-4 rounded-xl border border-slate-500 shadow-2xl">
          Debug:
          <ul className="ml-4 list-disc list-inside">
            <li>Rows drawn by UI: {virtualItems.length}</li>
            <li>Total Rows: {rowsLength}</li>
            <li>Table Shift: {tableShift}</li>
            <li>Total Height: {totalVirtualHight}</li>
            <li>Scroll Offset: {round(virtualizer.scrollOffset, 3)}</li>
            <li>Scroll Container Top: {round(scrollContainerRef.current?.scrollTop ?? 0, 3)}</li>
            <li>Scroll Container Height: {scrollContainerRef.current?.scrollHeight}</li>
          </ul>
        </div>
      </div>
    </AppGrid4x6>
  );
}

export default ScrollVirtualizerChecks;
