import { ArrowRightIcon, BarsArrowDownIcon, BarsArrowUpIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'flowbite-react';

interface ActionBarButtonProps {
  onClick: (() => void) | undefined;
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  hint: string;
}

function ActionBarButton({ onClick, Icon, hint }: ActionBarButtonProps) {
  const button = (
    <button
      type="button"
      className={`border border-gray-300 rounded-md px-2 py-2 shadow-md mx-auto my-4 ${
        onClick ? 'bg-slate-100  hover:bg-green-200' : 'opacity-30'
      }`}
      onClick={onClick || (() => {})}
      disabled={!onClick}
    >
      <Icon className="w-6 h-6" />
    </button>
  );

  return button;
  /* Tool tip? - Works but is a bit annoying
  return (
    <Tooltip content={hint} animation="duration-1000">
      {button}
    </Tooltip>
  );
  */
}

// ---------------------------------------------------------------------

interface SelectionActionBarProps {
  onAdd: (() => void) | undefined;
  onMoveUp: (() => void) | undefined;
  onMoveDown: (() => void) | undefined;
  onClearSelection: (() => void) | undefined;
}

export default function SelectionActionBar({ onAdd, onMoveUp, onMoveDown, onClearSelection }: SelectionActionBarProps) {
  return (
    <div className="w-16 h-full flex flex-col px-1">
      <div className="flex-1" />
      <ActionBarButton onClick={onAdd} Icon={ArrowRightIcon} hint="Add selection to tree" />
      <ActionBarButton onClick={onMoveUp} Icon={BarsArrowUpIcon} hint="Move tree selection up" />
      <ActionBarButton onClick={onMoveDown} Icon={BarsArrowDownIcon} hint="Move tree selection down" />
      <ActionBarButton onClick={onClearSelection} Icon={XCircleIcon} hint="Clear selection" />
      <div className="flex-1" />
    </div>
  );
}
