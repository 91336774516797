import SpotListResultGrid from '../SpotListResultGrid';
import SpotListSummaryBar from './SpotListSummaryBar';
import { SubReportBaseProps } from './SubReportBase';
import SubReportTitleBar from './SubReportTitleBar';

function SpotListSubReport({ subReport }: SubReportBaseProps) {
  return (
    <div className="h-full w-full p-1 flex flex-col gap-1">
      <SubReportTitleBar subReport={subReport} />
      <div className="grow w-full bg-gray-200 border border-gray-400 rounded-xl p-1 flex flex-col gap-1">
        {subReport && <SpotListSummaryBar subReport={subReport} />}
        <SpotListResultGrid reportId={subReport?.ownerReportId} options={undefined} />
      </div>
    </div>
  );
}

export default SpotListSubReport;
