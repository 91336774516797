import { ReactNode } from 'react';

export interface ProgressOverlayProps {
  active: boolean;
  children: ReactNode;
  // onCancelFn: () => void;
}

/*
 * Note parent class MUST have 'relative' in className, so we can center over top
 */
export default function ProgressOverlay({ active, children }: ProgressOverlayProps) {
  return (
    <div hidden={!active} className="absolute top-0 left-0 w-full h-full">
      <div className="opacity-75 w-full h-full grid grid-cols-1 grid-rows-1 bg-gray-200">
        <div className="place-self-center bg-slate-300 p-8 rounded-xl shadow-2xl">{children}</div>
      </div>
    </div>
  );
}
