import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext } from 'react';
import { RootContext } from '../../stores/storeProvidor';

const DemoModal = NiceModal.create(({ name }: { name: string }) => {
  const { uiState } = useContext(RootContext);

  // Use a hook to manage the modal state
  const modal = useModal();

  const onOk = () => {
    modal.remove();
    uiState.successAlert = 'Yes; you accepted!';
  };

  const onCancel = () => {
    modal.remove();
    uiState.warningAlert = 'Ohh - You cancelled?';
  };

  const onDecline = () => {
    modal.remove();
    uiState.criticalAlert = 'How dare you decline!';
  };

  return (
    <Modal show onClose={onCancel}>
      <Modal.Header>Terms of Service</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p>Hello {name}</p>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            With less than a month to go before the European Union enacts new consumer privacy laws for its citizens,
            companies around the world are updating their terms of service agreements to comply.
          </p>
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant
            to ensure a common set of data rights in the European Union. It requires organizations to notify users as
            soon as possible of high-risk data breaches that could personally affect them.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onOk}>I accept</Button>
        <Button color="gray" onClick={onDecline}>
          Decline
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default DemoModal;
