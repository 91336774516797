import { useContext, useEffect } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import SelectTable from '../../controls/table/SelectTable';
import { RootContext } from '../../../stores/storeProvidor';
import { ContactUser, Organisation } from '../../../models/orgModels';
import useClipboard from '../../../hooks/useClipboard';

function ContactList({ contacts }: { contacts: ContactUser[] }) {
  const copyToClip = useClipboard();
  return (
    <ul className="list-decimal ml-4">
      {contacts.map((u) => (
        <li key={u.email}>
          <div className="font-bold">
            {u.firstName} {u.lastName} {u.isActive ? '' : '(Inactive)'}
          </div>
          <button type="button" onClick={() => copyToClip(u.email)} className="group">
            {u.email}
            <ClipboardDocumentCheckIcon className="text-gray-600 ml-0.5 h-4 w-4 inline group-hover:animate-pulse" />
          </button>
          <div>{u.phone}</div>
        </li>
      ))}
    </ul>
  );
}

interface OrgTableProps {
  onSelected: (users: Organisation[]) => void;
}

const OrganisationTable = observer(({ onSelected }: OrgTableProps) => {
  const adminStore = useContext(RootContext).getAdminStore();

  useEffect(() => {
    adminStore.refreshOrganisations();
  }, [adminStore]);

  const renderCell = (info: CellContext<Organisation, unknown>): string | JSX.Element | undefined => {
    let activeCls = info.row.original.isActive ? 'text-gray-800' : 'text-gray-400';
    const strikeCols = ['name'];
    if (!info.row.original.isActive && strikeCols.includes(info.column.id)) {
      activeCls += ' line-through';
    }

    if (info.column.id === 'contacts') {
      return <ContactList contacts={info.row.original.contacts} />;
    }
    return <span className={activeCls}>{info.getValue() as string}</span>;
  };

  const columnDefs: ColumnDef<Organisation>[] = [
    {
      accessorKey: 'name',
      cell: (info) => renderCell(info),
      header: 'Name',
      size: 100,
    },
    {
      accessorKey: 'description',
      cell: (info) => renderCell(info),
      header: 'Description',
      size: 400,
    },
    {
      id: 'contacts',
      cell: (info) => renderCell(info),
      header: 'Primary Contacts',
      size: 400,
    },
    {
      id: 'active',
      accessorFn: (row) => (row.isActive ? 'Active' : 'Disabled'),
      cell: (info) => renderCell(info),
      header: 'Active',
    },
  ];

  return (
    <SelectTable
      data={adminStore.organisations ?? []}
      columns={columnDefs}
      onSelectionChanged={onSelected}
      sortEnabled
      filterEnabled
    />
  );
});

export default OrganisationTable;
