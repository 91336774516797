import { PropsWithChildren, useState } from 'react';
import { AppSettings, SettingsLevel, getSettingsKeys } from '../../../models/settings';
import DialogCheckbox from '../form/DialogCheckbox';
import DialogSelect from '../form/DialogSelect';
import { stringForEnum } from '../../../utils/helpers';

// ---------------------------------------------------------------------------------------------------

function SettingsPageContainer({ children }: PropsWithChildren) {
  return (
    <div className="space-y-6 h-96">
      <form>
        <div className="grid gap-4 md:grid-cols-3">{children}</div>
      </form>
    </div>
  );
}

// ---------------------------------------------------------------------------------------------------
// Defaults for each level depend on whats set in the next level up.
function getDefaultForLevel(levels: AppSettings[], activeLevel: SettingsLevel): AppSettings {
  const defaults = { ...levels[activeLevel] };
  for (let i = activeLevel + 1; i < levels.length; i += 1) {
    const parentDefaults = getDefaultForLevel(levels, i);
    getSettingsKeys().forEach((key) => {
      if (defaults[key] == null) defaults[key] = parentDefaults[key];
    });
  }
  return defaults;
}

// ---------------------------------------------------------------------------------------------------

export type SettingsPageProps = {
  levels: AppSettings[];
  activeLevel: SettingsLevel;
  updateLevel: (newLevel: AppSettings | string) => void;
};

// ---------------------------------------------------------------------------------------------------

export function SettingsGeneralPage({ levels, activeLevel, updateLevel }: SettingsPageProps) {
  const defaults = getDefaultForLevel(levels, activeLevel);
  const [clearConfirmFlag, setClearConfirmFlag] = useState(false);
  return (
    <SettingsPageContainer>
      <DialogSelect
        id="locale"
        label="Locale for date, time and number formats"
        options={['en-AU|Australia', 'en-CA|Canada', 'en-US|United States', 'en-GB|United Kingdom', 'it-IT|Italian']}
        selectedOption={levels[activeLevel].locale}
        defaultOption={defaults.locale ?? 'en-AU'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], locale: option });
        }}
        disabled={activeLevel === SettingsLevel.GlobalLevel}
      />
      <DialogCheckbox
        id="showItemIds"
        label="Show data id's? (for dianostics only)"
        selectedOption={levels[activeLevel].showSelectionIds}
        defaultOption={defaults.showSelectionIds ?? false}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], showSelectionIds: option });
        }}
        disabled={activeLevel > SettingsLevel.UserLevel}
      />
      <DialogCheckbox
        id="showRowIds"
        label="Show result row id's? (for dianostics only)"
        selectedOption={levels[activeLevel].showRowIds}
        defaultOption={defaults.showRowIds ?? false}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], showRowIds: option });
        }}
        disabled={activeLevel > SettingsLevel.UserLevel}
      />
      <DialogCheckbox
        id="confirmDlg"
        label="Reset and show all confirmation dialogs?"
        selectedOption={clearConfirmFlag}
        defaultOption={false}
        onSelect={(id, option) => {
          setClearConfirmFlag(option === 'true');
          updateLevel(`${stringForEnum(SettingsLevel, activeLevel)}|clearhide`);
        }}
        disabled={activeLevel !== SettingsLevel.UserLevel}
      />
    </SettingsPageContainer>
  );
}

// ---------------------------------------------------------------------------------------------------

export function SettingsPeriodPage({ levels, activeLevel, updateLevel }: SettingsPageProps) {
  const defaults = getDefaultForLevel(levels, activeLevel);
  return (
    <SettingsPageContainer>
      <DialogCheckbox
        id="use12h"
        label="Show times in 12 hour format?"
        selectedOption={levels[activeLevel].use12hTime}
        defaultOption={defaults.use12hTime ?? false}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], use12hTime: option });
        }}
      />

      <DialogSelect
        id="moy"
        label="Month-of-year format"
        options={['number|10', 'narrow|O', 'short|Oct', 'long|October']}
        selectedOption={levels[activeLevel].moyFormat}
        defaultOption={defaults.moyFormat ?? 'short'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], moyFormat: option });
        }}
      />

      <DialogSelect
        id="dow"
        label="Generic day format (Day-of-week)"
        options={['number|4', 'narrow|T', 'short|Thurs', 'long|Thursday']}
        selectedOption={levels[activeLevel].dowFormat}
        defaultOption={defaults.dowFormat ?? 'short'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], dowFormat: option });
        }}
      />

      <DialogSelect
        id="hod"
        label="Generic hour format (Hour-of-day)"
        options={['H|9 (0-23)', 'HH|09 (00-23)', 'HH:00|09:00', 'h a|9 AM', 'hh a|09 AM', 'h:00 a|9:00 AM']}
        selectedOption={levels[activeLevel].hodFormat}
        defaultOption={defaults.hodFormat ?? 'HH:00'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], hodFormat: option });
        }}
      />

      <DialogSelect
        id="mod"
        label="Generic minute format (Minute-of-day)"
        options={['HH:mm|13:54', 'h:mm a|1:54 PM', 'number|834 (0-1439)']}
        selectedOption={levels[activeLevel].modFormat}
        defaultOption={defaults.modFormat ?? 'HH:mm'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], modFormat: option });
        }}
      />

      <DialogSelect
        id="amth"
        label="Absolute month format"
        options={['MM/yy|10/24', 'MMM yyyy|Oct 2024', 'MMM yy|Oct 24', 'yyyy MMMM|2024 October']}
        selectedOption={levels[activeLevel].absMthFormat}
        defaultOption={defaults.absMthFormat ?? 'MMM yyyy'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], absMthFormat: option });
        }}
      />

      <DialogSelect
        id="awk"
        label="Absolute week format"
        options={['yyyy WW|2024 34', 'wC d/MM/yyyy|wC 16/04/2024', 'D|16/4/2024', 'DD|Apr 16, 2024']}
        selectedOption={levels[activeLevel].absWkFormat}
        defaultOption={defaults.absWkFormat ?? 'LLL yy'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], absWkFormat: option });
        }}
      />

      <DialogSelect
        id="aday"
        label="Absolute day format"
        options={['d/MM/yyyy|16/04/2024', 'D|16/4/2024', 'DD|Apr 16, 2024']}
        selectedOption={levels[activeLevel].absDayFormat}
        defaultOption={defaults.absDayFormat ?? 'd/MM/yyyy'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], absDayFormat: option });
        }}
      />
    </SettingsPageContainer>
  );
}

// ---------------------------------------------------------------------------------------------------

export function SettingsNumberPage({ levels, activeLevel, updateLevel }: SettingsPageProps) {
  const defaults = getDefaultForLevel(levels, activeLevel);
  return (
    <SettingsPageContainer>
      <DialogSelect
        id="lvs"
        label="Low Value Symbol"
        options={['|None', '*|Asterisk (*)', '.|Dot(.)', '-|Dash(-)']}
        selectedOption={levels[activeLevel].lowValueSymbol}
        defaultOption={defaults.lowValueSymbol ?? ''}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], lowValueSymbol: option });
        }}
      />
      <DialogCheckbox
        id="bzv"
        label="Blank zero values"
        selectedOption={levels[activeLevel].blankZeroValues}
        defaultOption={defaults.blankZeroValues ?? false}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], blankZeroValues: option });
        }}
      />
      <DialogSelect
        id="currency"
        label="Currency Type"
        options={[
          'AUD|Australian Dollars',
          'USD|US Dollars',
          'GBP|British Pounds',
          'EUR|Euros',
          'NZD|New Zealand Dollars',
        ]}
        selectedOption={levels[activeLevel].currency}
        defaultOption={defaults.currency ?? 'AUD'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], currency: option });
        }}
      />
      <DialogSelect
        id="cdp"
        label="Currency Decimal Places"
        options={['0|0', '1|1', '2|2', '3|3', '4|4']}
        selectedOption={levels[activeLevel].currencyDecimalPlaces}
        defaultOption={defaults.currencyDecimalPlaces ?? '2'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], currencyDecimalPlaces: option });
        }}
      />
      <DialogSelect
        id="cdiv"
        label="Currency Divisor"
        options={['1|Units', '1000|Thousands', '1000000|Millions']}
        selectedOption={levels[activeLevel].currencyDivisor}
        defaultOption={defaults.currencyDivisor ?? '1'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], currencyDivisor: option });
        }}
      />
      <DialogSelect
        id="odp"
        label="Other Decimal Places"
        options={['0|0', '1|1', '2|2', '3|3', '4|4']}
        selectedOption={levels[activeLevel].otherDecimalPlaces}
        defaultOption={defaults.otherDecimalPlaces ?? '2'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], otherDecimalPlaces: option });
        }}
      />
      <hr />
      <DialogSelect
        id="odiv"
        label="Other Divisor"
        options={['1|Units', '1000|Thousands', '1000000|Millions']}
        selectedOption={levels[activeLevel].otherDivisor}
        defaultOption={defaults.otherDivisor ?? '1'}
        onSelect={(id, option) => {
          updateLevel({ ...levels[activeLevel], otherDivisor: option });
        }}
      />
    </SettingsPageContainer>
  );
}
