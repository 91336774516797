import { DateTime, Duration } from 'luxon';
import { useContext } from 'react';
import { AppSettings } from '../models/settings';
import { formatDateOnly, formatTimeOnly } from '../utils/dates';
import { RootContext } from '../stores/storeProvidor';

interface UseDateFormatReturn {
  formatDate: (date: DateTime | undefined, size?: 's' | 'm' | 'l') => string;
  formatTime: (date: DateTime | undefined, withSecs?: boolean) => string;
  formatDateTime: (date: DateTime | undefined, withSecs?: boolean) => string;
  formatDuration: (duration: Duration | undefined) => string;
}

export function useDateFormat(
  reportId: string | undefined = undefined, // Given reportId, we'll use report level settings. If undef we'll use user level settings
  overrideSettings: AppSettings | undefined = undefined
): UseDateFormatReturn {
  const { activeUserStore } = useContext(RootContext);
  const locale = overrideSettings?.locale ?? activeUserStore.getLocale(reportId);
  const use12hTime =
    (overrideSettings?.use12hTime ?? activeUserStore.getSetting('use12hTime', reportId) ?? false) === 'true';
  const rtf = new Intl.RelativeTimeFormat(locale, { style: 'short' });

  const formatDate = (date: DateTime | undefined, size: 's' | 'm' | 'l' = 's') =>
    date !== undefined ? formatDateOnly(date, locale, size) : '<<None>>';

  const formatTime = (date: DateTime | undefined, withSecs = false) =>
    date !== undefined ? formatTimeOnly(date, locale, use12hTime, withSecs) : '<<None>>';

  const formatDateTime = (date: DateTime | undefined, withSecs = false) =>
    date !== undefined
      ? `${formatDateOnly(date, locale, 'm')} ${formatTimeOnly(date, locale, use12hTime, withSecs)}`
      : '<<None>>';

  const formatDuration = (duration: Duration | undefined) => {
    // Implement using: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat
    // TODO: switch units based on size
    if (duration === undefined) return '<<None>>';
    const { days } = duration.shiftTo('days').toObject();
    return rtf.format(days ?? 0, 'day');
  };

  return { formatDate, formatTime, formatDateTime, formatDuration };
}

// ----------------------------------------------------------------------------------------------

export type FormatNumberStyles = 'string' | 'int' | 'float' | 'currency' | 'percent';

interface UseNumberFormatReturn {
  formatCurrency: (value: number) => string;
  formatNumber: (value: number) => string;
  formatPercentage: (value: number) => string;
  formatDatatype: (value: string | undefined, divisor: number, style: FormatNumberStyles) => string;
}

export function useNumberFormat(
  reportId: string | undefined = undefined, // Given reportId, we'll use report level settings. If undef we'll use user level settings
  overrideSettings: AppSettings | undefined = undefined
): UseNumberFormatReturn {
  const { activeUserStore } = useContext(RootContext);
  const currencyFormatter = activeUserStore.getCurrencyFormat(reportId, overrideSettings);
  const otherFormatter = activeUserStore.getNumberFormat(reportId, overrideSettings);
  const percentFormatter = activeUserStore.getPercentFormat(reportId, overrideSettings);
  const curDiv = parseInt(
    overrideSettings?.currencyDivisor ?? activeUserStore.getSetting('currencyDivisor', reportId) ?? '1',
    10
  );
  const numDiv = parseInt(
    overrideSettings?.otherDivisor ?? activeUserStore.getSetting('otherDivisor', reportId) ?? '1',
    10
  );
  const lowValueSymbol =
    overrideSettings?.lowValueSymbol ?? activeUserStore.getSetting('lowValueSymbol', reportId) ?? '*';
  const blankZeroValues =
    (overrideSettings?.blankZeroValues ?? activeUserStore.getSetting('blankZeroValues', reportId) ?? 'true') === 'true';

  // Basic number formatting
  const formatCurrency = (value: number) => currencyFormatter.format(value);
  const formatNumber = (value: number) => otherFormatter.format(value);
  const formatPercentage = (value: number) => percentFormatter.format(value);

  const formatDatatype = (value: string | undefined, divisor: number, style: FormatNumberStyles) => {
    // Maybe a string or blank - just return
    if (style === 'string' || value === '' || value === undefined) return value ?? '';

    // Apply datatype and formatting divisors
    let realVal = parseFloat(value) / divisor;
    realVal = style === 'currency' ? realVal / curDiv : realVal / numDiv;

    let dp = 0;
    if (style === 'currency') dp = currencyFormatter.resolvedOptions().maximumFractionDigits ?? 0;
    else if (style === 'percent') dp = percentFormatter.resolvedOptions().maximumFractionDigits ?? 0;
    else dp = otherFormatter.resolvedOptions().maximumFractionDigits ?? 0;

    // Is zero? (less than our dp.s)
    if (realVal === 0.0 && blankZeroValues) return '';

    // Is low value?
    if (Math.abs(realVal) > 0.0 && Math.abs(realVal) < 1 / 10.0 ** dp) {
      if (lowValueSymbol.length > 0) return lowValueSymbol;
    }

    if (style === 'currency') return formatCurrency(realVal);
    if (style === 'percent') return formatPercentage(realVal);

    return formatNumber(realVal);
  };

  return { formatCurrency, formatNumber, formatPercentage, formatDatatype };
}
