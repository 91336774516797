import {
  ColumnDef,
  FilterFn,
  Header,
  RowData,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, XCircleIcon } from '@heroicons/react/24/outline';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import DebouncedInput from './DebouncedInput';

interface SelectTableProps<T extends RowData> {
  data: T[];
  columns: ColumnDef<T, unknown>[];
  sortEnabled?: boolean;
  filterEnabled?: boolean;
  onSelectionChanged?: (rows: T[]) => void;
  selectClass?: string;
}

function SelectTable<T>({
  data,
  columns,
  sortEnabled,
  filterEnabled,
  onSelectionChanged,
  selectClass,
}: SelectTableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const selectTag = '~';

  const objectFilter: FilterFn<T> = (row, columnId, value) => {
    // TODO: Really need to check the 'Cell' value (as displayed) as well as object properties, as column-def formats values for display
    // eg 'Active' cannot be found in search atm, as object is bool
    const rowObj = row.original as T;
    const e = Object.values(rowObj as object) as string[];
    return e.some((v) => {
      if (typeof v === 'string') {
        return v.toString().toLowerCase().includes(value.toLowerCase());
      }
      return false;
    });
  };

  const columnsWithSort = useMemo<ColumnDef<T, unknown>[]>(() => {
    // Insert first column to hold check-boxes - detect and render below
    const selectHdr = {
      id: selectTag,
      size: 20,
    };
    return [selectHdr as ColumnDef<T, unknown>].concat(columns);
  }, [columns]);

  const table = useReactTable<T>({
    data,
    columns: columnsWithSort,
    state: {
      sorting: sortEnabled ? sorting : undefined,
      globalFilter: filterEnabled ? globalFilter : undefined,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: filterEnabled ? setGlobalFilter : undefined,
    globalFilterFn: filterEnabled ? objectFilter : undefined,
  });

  let getHeaderCell = (header: Header<T, unknown>) =>
    header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());
  if (sortEnabled) {
    // If sort is enabled, click header toggles sort state...
    getHeaderCell = (header) =>
      header.isPlaceholder ? null : (
        <button
          type="button"
          className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
          onClick={header.column.getToggleSortingHandler()}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {{
            asc: <ChevronDoubleUpIcon className="inline h-4 w-4" />,
            desc: <ChevronDoubleDownIcon className="inline h-4 w-4" />,
          }[header.column.getIsSorted() as string] ?? null}
        </button>
      );
  }

  // Extract to separate var so we can add to dependencies below
  const rowSel = table.getState().rowSelection;

  useEffect(() => {
    const selected = data.filter((p, i) => rowSel[i]);
    if (onSelectionChanged && selected) {
      onSelectionChanged(selected);
    }
  }, [rowSel, data, onSelectionChanged]);

  return (
    <div className="p-1 overflow-auto bg-gray-100">
      {filterEnabled ? (
        <div className="pb-1">
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 text-md shadow border border-block border-gray-700"
            placeholder="Search..."
          />
          <XCircleIcon
            className="inline h-8 w-8 ml-1 pb-1 text-gray-400 hover:text-red-800"
            onClick={() => setGlobalFilter('')}
          />
        </div>
      ) : null}
      <div>
        <table className="border border-gray-300 bg-gray-100">
          {/* ---- HEADER WITH GROUPING---- */}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                    className="border-b border-r border-gray-300 py-1 px-2 bg-gray-200"
                  >
                    {header.id === selectTag ? (
                      <IndeterminateCheckbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                      />
                    ) : (
                      getHeaderCell(header)
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* ---- BODY ---- */}
          <tbody className="border-b border-gray-300">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`border-r border-b border-gray-300 px-1 ${cell.row.getIsSelected() ? selectClass : ''}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {cell.getContext().column.id === selectTag ? (
                      <div className="px-1">
                        <IndeterminateCheckbox
                          checked={row.getIsSelected()}
                          disabled={!row.getCanSelect()}
                          indeterminate={row.getIsSomeSelected()}
                          onChange={row.getToggleSelectedHandler()}
                        />
                      </div>
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

SelectTable.defaultProps = {
  sortEnabled: false,
  filterEnabled: false,
  onSelectionChanged: () => {},
  selectClass: 'bg-teal-100',
};

export default SelectTable;
