import { useContext } from 'react';
import { RootContext } from '../stores/storeProvidor';

export default function useClipboard() {
  const { uiState } = useContext(RootContext);
  const copyToClip = async (text: string) => {
    try {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
      } else {
        // Old browser fallback
        document.execCommand(text);
      }
      uiState.successAlert = 'Copied to clipboard...';
    } catch (e) {
      uiState.errorAlert = 'Failed to copy to clipboard!';
    }
  };
  return copyToClip;
}
