import { BreakoutObj, BreakoutType } from '../../../../models/breakoutModels';
import IconCross from '../../../icons/cross';

const colourForType: Map<BreakoutType, string> = new Map([
  [BreakoutType.Media, 'bg-blue-300 text-blue-900'],
  [BreakoutType.PeriodAbsolute, 'bg-amber-300 text-yellow-700'],
  [BreakoutType.PeriodGeneric, 'bg-orange-300 text-yellow-600'],
  [BreakoutType.Dictionary, 'bg-emerald-300 text-teal-800'],
  [BreakoutType.Datatypes, 'bg-lime-300 text-amber-800'],
  [BreakoutType.Meta, 'bg-purple-300 text-red-800'],
  [BreakoutType.UnknownType, 'bg-red-600 text-white font-bold italic'],
]);

export type BreakoutItemProps = {
  obj: BreakoutObj | undefined;
  isActive?: boolean | undefined;
  isSelected?: boolean | undefined;
  closeFn?: (id: string) => void | undefined;
};

export function BreakoutItem({ obj, isActive, isSelected, closeFn }: BreakoutItemProps) {
  if (obj === undefined) {
    return <div className="bg-gray-100 font-medium mx-1 px-2.5 py-0.5 my-1">Drag here...</div>;
  }

  let style = 'font-medium m-1 px-2.5 py-0.5 rounded text-center border border-slate-400 shadow-md min-w-max';
  let colour = colourForType.get(obj.type) ?? 'bg-black text-white';
  if (isActive) colour = 'bg-stone-400 text-neutral-600';
  else if (isSelected) colour = `${colour} opacity-25 hover:opacity-100`;
  if (colour !== undefined) style = style.concat(` ${colour}`);

  return (
    <div className={style}>
      {obj.name}
      {closeFn !== undefined ? (
        <IconCross
          className="inline ml-2 mr-0 items-center opacity-25 bg-gray-100 text-gray-800 rounded-md hover:opacity-90"
          onClick={(e) => {
            e.stopPropagation();
            closeFn(obj.id);
          }}
        />
      ) : null}
    </div>
  );
}

BreakoutItem.defaultProps = {
  isActive: false,
  isSelected: false,
  closeFn: undefined,
};
