/* eslint-disable prettier/prettier */
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useState } from 'react';
import IndeterminateCheckbox from './IndeterminateCheckbox';

type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  status: string;
  progress: number;
  selected: boolean;
  hover: boolean;
};

const defaultData: Person[] = [
  {
    firstName: 'tanner',
    lastName: 'linsley',
    age: 24,
    visits: 100,
    status: 'Active',
    progress: 50,
    selected: false,
    hover: false,
  },
  {
    firstName: 'tandy',
    lastName: 'miller',
    age: 40,
    visits: 40,
    status: 'Single',
    progress: 80,
    selected: false,
    hover: false,
  },
  {
    firstName: 'joe',
    lastName: 'dirte',
    age: 45,
    visits: 20,
    status: 'Complicated',
    progress: 10,
    selected: false,
    hover: false,
  },
];

const columnHelper = createColumnHelper<Person>();

const columns = [
  columnHelper.accessor((row) => row.firstName, {
    id: 'select',
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
  }),
  // Grouping Column
  columnHelper.group({
    header: 'User Name',
    // footer: (props) => props.column.id,
    columns: [
      // Sub-col-1 - by property-name + object
      columnHelper.accessor('firstName', {
        cell: (info) => info.getValue(),
        // footer: (info) => info.column.id,
      }),
      // Sub-col-2 - via accessor function
      columnHelper.accessor((row) => row.lastName, {
        id: 'lastName',
        // Special formatting for column values
        cell: (info) => <span className="text-orange-700 capitalize">{info.getValue()}</span>,
        header: () => <span>Last Name</span>,
        // footer: (info) => info.column.id,
      }),
    ],
  }),
  columnHelper.accessor('age', {
    header: () => 'Age',
    cell: (info) => info.renderValue(),
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor('visits', {
    // Special formatting for header content (Text only - NOT whole cell!)
    header: () => <span className="text-green-300 bg-green-800">Visits</span>,
    cell: (info) => <span className="text-green-300">{info.getValue()}</span>,
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor('status', {
    // Default cell accessor
    header: 'Status',
    cell: (info) => <span className="">{info.getValue()}</span>,
  }),
  columnHelper.accessor('progress', {
    header: 'Profile Progress',
    // footer: (info) => info.column.id,
    enableHiding: true,
  }),
];

function TestTable() {
  const [data, setData] = useState(() => [...defaultData]);

  const addToData = () => {
    const newRec = {
      firstName: `Blah-${data.length}`,
      lastName: 'Blue',
      age: 21 + data.length,
      visits: 260,
      status: 'Bananna',
      progress: -10 + data.length,
      selected: false,
    } as Person;

    setData(data.concat([newRec]));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // enableColumnResizing: true,
    // columnResizeMode: 'onChange',
  });

  // table.setColumnSizing((old) => old);

  const selectedPeople = () => {
    const sel = table.getState();
    return data.filter((p, i) => sel.rowSelection[i]);
  };

  const mouseOver = (event: React.MouseEvent<HTMLElement>) => {
    // eslint-disable-next-line no-console
    console.log(event);

    const tr: HTMLElement = event.currentTarget;
    tr.style.backgroundColor = 'orange';
  };

  const mouseOut = (event: React.MouseEvent<HTMLElement>) => {
    // eslint-disable-next-line no-console
    console.log(event);

    const tr: HTMLElement = event.currentTarget;
    tr.style.backgroundColor = '';
  };

  return (
    <>
      <div className="p-2">
        <table className="border border-gray-300">
          {/* ---- HEADER WITH GROUPING---- */}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="border-b border-r border-gray-300 py-1 px-2 bg-gray-400"
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* ---- BODY ---- */}
          <tbody className="border-b border-gray-300">
            {table.getRowModel().rows.map((row) => (
              // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
              <tr key={row.id} onMouseOver={mouseOver} onMouseLeave={mouseOut}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`border-r border-b border-gray-300 px-1 ${
                      cell.row.getIsSelected() ? 'bg-blue-200' : ''
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

          {/* ---- FOOTER ---- 
          <tfoot className="text-gray-500">
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="border-b border-r border-gray-300 py-1 px-2 font-normal"
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        */}
        </table>
        <div className="h-4" />
        <button type="button" onClick={addToData} className="border border-gray-700 p-2 bg-green-300 rounded-xl">
          Add Records
        </button>
      </div>
      <div>
        <h3>Selections</h3>
        {selectedPeople().map((p, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${p.firstName}+${i}`}>{p.firstName} </div>
        ))}
      </div>
    </>
  );
}

export default TestTable;

export function TableSpanTest() {
  const headerClass = 'border-r w-24 text-center bg-gray-300';
  const bodyClass = 'border-r text-center hover:bg-cyan-100';
  return (
    <table className="border">
      <thead className="border-b">
        <tr className="border-b">
          <th className={`${headerClass} bg-rose-200`} rowSpan={2} colSpan={2}>A</th>
          <th className={`${headerClass} bg-gray-400`} colSpan={2}>B</th>
          <th className={`${headerClass} bg-gray-500`} colSpan={2}>C</th>
        </tr>
        <tr className="border-b">
          <th className={`${headerClass}`}>E</th>
          <th className={`${headerClass}`}>F</th>
          <th className={`${headerClass}`}>G</th>
          <th className={`${headerClass}`}>H</th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-b">
          <td className={`${bodyClass} bg-gray-400`} rowSpan={3}>X</td>
          <td className={`${bodyClass} bg-gray-300`}>Y</td>
          <td className={`${bodyClass}`}>1</td>
          <td className={`${bodyClass}`}>2</td>
          <td className={`${bodyClass}`}>3</td>
          <td className={`${bodyClass}`}>4</td>
        </tr>
        <tr className="border-b">
          <td className={`${bodyClass} bg-gray-300`} rowSpan={2}>Z</td>
          <td className={`${bodyClass}`}>5</td>
          <td className={`${bodyClass}`}>6</td>
          <td className={`${bodyClass}`}>7</td>
          <td className={`${bodyClass}`}>8</td>
        </tr>
        <tr className="border-b">
          <td className={`${bodyClass}`}>9</td>
          <td className={`${bodyClass}`}>10</td>
          <td className={`${bodyClass}`}>11</td>
          <td className={`${bodyClass}`}>12</td>
        </tr>
      </tbody>
    </table>
  );
}
