import { LevelSelection } from '../../../models/dictSelection';
import DictBoxLevel from './DictBoxLevel';

interface DictBoxContainerProps {
  selection: LevelSelection[];
  onClose: (tag: string) => void;
  onSearch: (tag: string, search: string) => void;
  onSelect: (tag: string, indices: number[], selected: boolean) => void;
  onOpenNextLevel: (tag: string) => void;
}

function DictBoxContainer({ selection, onClose, onSearch, onSelect, onOpenNextLevel }: DictBoxContainerProps) {
  return (
    <div className="grow relative overflow-hidden bg-slate-100">
      {selection.map((l, i) => (
        <DictBoxLevel
          key={l.tag}
          level={l}
          position={i}
          isActive={i === selection.length - 1}
          onClose={() => onClose(l.tag)}
          onSearch={(s) => onSearch(l.tag, s)}
          onSelect={(j, s) => onSelect(l.tag, j, s)}
          onOpenNextLevel={() => onOpenNextLevel(l.tag)}
        />
      ))}
    </div>
  );
}

export default DictBoxContainer;
