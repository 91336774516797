/* eslint-disable react/no-unescaped-entities */
import { Card, TextInput } from 'flowbite-react';
import { Link } from 'react-router-dom';
import ActionButton from '../controls/ActionButton';

function RegisterPage() {
  function doRegister() {
    // eslint-disable-next-line no-console
    console.log('Register clicked');
  }

  return (
    <>
      <h1 className="py-6 text-3xl">Register</h1>
      <div className="md:flex">
        <Card className="mr-12 mb-6 md:w-1/2 w-full">
          <h5 className="font-bold tracking-tight text-primary">Do you have a key?</h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            Copy your key from your introduciton email, and paste it below to register your account:
          </p>
          <form className="flex flex-col gap-4">
            <div className="pt-4">
              <TextInput id="regkey" type="text" placeholder="<copy key from invitation email here>" required />
            </div>
            <ActionButton useSubmit onClick={doRegister}>
              Register
            </ActionButton>
          </form>
        </Card>
        <Card className="mb-6 md:w-1/2 w-full">
          <h5 className="font-bold tracking-tight text-primary">Don't have a key?</h5>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            If your company has a {import.meta.env.VITE_APP_TITLE} account, please ask your administrator to send you an
            invite.
          </p>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            To sign up for an account, please&nbsp;
            <Link to="/contact" className="text-primary font-bold hover:underline">
              contact us
            </Link>
          </p>
        </Card>
      </div>
    </>
  );
}

export default RegisterPage;
