import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  AcademicCapIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  DocumentDuplicateIcon,
  GlobeAsiaAustraliaIcon,
  HomeIcon,
  SwatchIcon,
  TableCellsIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import NiceModal from '@ebay/nice-modal-react';
import { useTranslation } from 'react-i18next';
import SideMenu, { SideMenuBottomSection } from '../Components/controls/SideMenu';
import SideMenuLink, { SideMenuSeparator } from '../Components/controls/SideMenuLink';
import TopMenu, { TopMenuButton } from '../Components/controls/TopMenu';
import { RootContext } from '../stores/storeProvidor';
import Logo from '../Components/controls/Logo';
import TopMenuMessageAuto from '../Components/controls/TopMenuMessageAuto';
import WithPermission from '../permissions/WithPermission';
import Perms from '../permissions/permissions';
import DataSourceSelectModal from '../Components/modals/DataSourceSelectModal';
import SettingsModal from '../Components/modals/SettingsDialog';
import AccountModal from '../Components/modals/AccountDialog';
import useOpenReport from '../hooks/useReport';
import useTimeout from '../hooks/useTimeout';
import { useLocalStorage } from '../hooks/useLocalStorage';

const AppLayout = observer(() => {
  const { t } = useTranslation(['app']);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const hideSideBar = () => setShowSideMenu(false);
  const toggleSideBar = () => setShowSideMenu(!showSideMenu);
  const rootStore = useContext(RootContext);
  const { uiState, activeReportStore, activeUserStore } = rootStore;
  const { openReportById, openNewReport } = useOpenReport();
  const [getDefaultReportId, setDefaultReportId] = useLocalStorage<string | undefined>('default-report-id');

  const [triggerNewReport] = useTimeout(async () => {
    // Default report id is saved locally
    const defaultReportId = getDefaultReportId();
    let ok = false;

    // Try to open the 'Default Report' as stored in local storage
    if (defaultReportId !== undefined) {
      ok = await openReportById(defaultReportId);
    }

    // If failed or first time, create a new report
    if (!ok || defaultReportId === undefined) {
      // Create basic report if none set
      const templateId = activeUserStore.getSetting('reportTemplateDefault');
      const newId = await openNewReport('Default Report', templateId);
      setDefaultReportId(newId);
    }
  }, 500);

  useEffect(() => {
    const firstLoad = rootStore.activeUserStore.getSetting('locale') === undefined;

    if (rootStore.storeRefreshHash !== undefined) {
      // Load on startup
      rootStore.refreshAllStores(() => {
        if (firstLoad && activeReportStore.getCountOfActiveReports === 0) {
          // Need timer to 'debounce' the call to open a new report
          triggerNewReport();
        }
      });
    }
  }, [rootStore, activeReportStore, openNewReport, triggerNewReport]);

  return (
    <>
      <SideMenu showSideMenu={showSideMenu} clearSideMenuFn={hideSideBar}>
        <SideMenuLink navTo="" Icon={HomeIcon} navText={t('layout.home')} onClicked={hideSideBar} />
        <SideMenuLink navTo="myreports" Icon={TableCellsIcon} navText={t('layout.reports')} onClicked={hideSideBar} />
        <SideMenuLink
          navTo="admin"
          Icon={SwatchIcon}
          navText={t('layout.selection')}
          onClicked={hideSideBar}
          disabled
        />
        <SideMenuLink
          navTo="openreports"
          Icon={DocumentDuplicateIcon}
          navText={t('layout.open')}
          onClicked={hideSideBar}
          disabled={!uiState.haveAnyActiveReports}
          badge={uiState.haveAnyActiveReports ? `${activeReportStore.getCountOfActiveReports}` : undefined}
        />
        <SideMenuSeparator />
        <SideMenuLink
          Icon={GlobeAsiaAustraliaIcon}
          navText={t('layout.data')}
          onClicked={() => {
            hideSideBar();
            NiceModal.show(DataSourceSelectModal);
          }}
        />
        <SideMenuSeparator />
        <SideMenuLink
          Icon={Cog6ToothIcon}
          navText={t('layout.settings')}
          onClicked={() => {
            hideSideBar();
            NiceModal.show(SettingsModal);
          }}
        />
        <SideMenuLink
          Icon={UserCircleIcon}
          navText={t('layout.account')}
          onClicked={() => {
            hideSideBar();
            NiceModal.show(AccountModal);
          }}
        />
        <SideMenuBottomSection>
          <SideMenuLink navTo="/help" Icon={AcademicCapIcon} navText={t('layout.help')} onClicked={hideSideBar} />
          <WithPermission permsNeeded={Perms.AllAdmin}>
            <SideMenuLink navTo="/admin" Icon={CommandLineIcon} navText={t('layout.admin')} onClicked={hideSideBar} />
          </WithPermission>
        </SideMenuBottomSection>
      </SideMenu>

      <TopMenu toggleSideMenuFn={toggleSideBar}>
        <Logo clickFn={toggleSideBar} />
        <TopMenuMessageAuto
          clickFn={() => {
            // eslint-disable-next-line no-console
            console.log('TODO: Show messages');
          }}
          isApp
        />
        {/* <TopMenuButton
          clickFn={() => {
            // eslint-disable-next-line no-alert
            uiState.warningAlert = '"Search Everything" not yet implemented';
          }}
          Icon={MagnifyingGlassIcon}
          tip={t('layout.tip.search')}
        /> */}
        <TopMenuButton
          clickFn={() => {
            NiceModal.show(AccountModal);
          }}
          text={t('layout.account')}
          Icon={UserCircleIcon}
          tip={t('layout.tip.account')}
        />
        {/* <TopMenuButton
          clickFn={() => {
            // eslint-disable-next-line no-alert
            alert('TODO: Show notifications');
          }}
          Icon={ChatBubbleOvalLeftEllipsisIcon}
          tip={t('layout.tip.notification')}
        /> */}
      </TopMenu>

      <Outlet />
    </>
  );
});

export default AppLayout;
