/* eslint-disable no-console */
import { useCallback, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootContext } from '../stores/storeProvidor';
import useTokenExpiration from './useTokenExpiration';

const UseAuth = observer(() => {
  const { authStore } = useContext(RootContext);

  async function refresh() {
    await authStore.refreshToken();
    console.log('Token refreshed for startup');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshToken = useCallback(refresh, []);

  // TODO - automatically refresh token on expiry
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { clearAutomaticTokenRefresh, setTokenExpiration } = useTokenExpiration(refreshToken);

  useEffect(() => {
    // try to get new token on first render using refresh token
    refreshToken();
  }, [refreshToken]);

  return <div />;
});

export default UseAuth;
