import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type RootStore from './rootStore';
import { FilterLevel, Schema, SchemaAccess, SchemaVersion } from '../models/schemaModels';
import { Datatype } from '../models/datatypes';
import selectionService from '../services/selectionService';
import schemaService from '../services/schemaService';
import { BreakoutObj } from '../models/breakoutModels';
import {
  SelectionDetails,
  SelectionLevelUiState,
  SelectionNode,
  SelectionNodeType,
  SelectionSummary,
  SelectionType,
} from '../models/selectionModels';
import { EmbeddedSelection } from '../models/reportModel';
import {
  createNewRoot,
  newAbsoluteDateRangeNode,
  newDateRangeNode,
  newLevelFilterNode,
  newNode,
} from '../utils/selectionTreeUtils';
import { CreateLevelKey, extractDatasetFromStorageKey, TreeSelection } from './selectionDragStore';
import { getRelativePeriodNodeRange, getRelativePeriodNodeTitle } from '../utils/relativeDateUtils';
import { DictCore, DictEntry, DictLevel, DictSelectContainerSource, LevelSelection } from '../models/dictSelection';
import filterDatasetEntries from '../utils/datasetFilter';
import { strArrayHash } from '../utils/helpers';
import { baseId } from '../utils/dragUtils';

export const DATATYPE_LEVEL_TAG = 'dts';

/*
 Order the Schema versions so that they are in date order, and only include 'LIVE' / 'QA' versions
 as appropriate. The 'Latest' version is always first in this list.
 */
export function getOrderedVersions(vers: SchemaVersion[], canViewQaVersions: boolean): SchemaVersion[] {
  let validVers = vers
    .filter((v) => v.access === SchemaAccess.Live)
    .sort((a, b) => b.updated.toUnixInteger() - a.updated.toUnixInteger());

  // Add QA versions if allowed
  if (canViewQaVersions) {
    const qaVers = vers
      .filter((v) => v.access === SchemaAccess.Qa)
      .sort((a, b) => b.updated.toUnixInteger() - a.updated.toUnixInteger());
    validVers = [...validVers, ...qaVers];
  }
  return validVers;
}

function getVersionId(wantedVersion: string | undefined, vers: SchemaVersion[], canViewQaVersions: boolean): string {
  const orderedVers = getOrderedVersions(vers, canViewQaVersions);
  const ver = orderedVers.find(
    (v, i) => v.versionId === wantedVersion || (i === 0 && (wantedVersion === undefined || wantedVersion === 'Latest'))
  );

  return ver?.versionId ?? vers[0].versionId;
}

interface SelectLevelStore {
  items: string[];
  filterViaApi: boolean; // Else filter locally
  apiFilterText: string; // If via API
  totalCount: number;
}

function compareSchema(newSchema: Schema[], oldSchema: Schema[]): string[] {
  const changes: string[] = [];

  const olds = oldSchema.map((s) => ({ ...s, versions: s.versions.map((v) => ({ ...v })) }));

  newSchema.forEach((s) => {
    const old = olds.find((o) => o.schemaId === s.schemaId);
    if (old) {
      s.versions.forEach((v) => {
        // Only notify new or updated 'LIVE' versions
        if (v.access === SchemaAccess.Live) {
          const oldVer = old.versions.find(
            (c) => c.versionId === v.versionId && c.updated.toISO() === v.updated.toISO()
          );
          if (oldVer === undefined) {
            changes.push(s.name);
          }
        }
      });
    }
  });

  return changes;
}

class SelectionStore implements DictSelectContainerSource {
  private rootStore: RootStore;

  schema: Schema[];

  datatypes: Datatype[]; // TODO: save schema we loaded for - reload on change

  breakouts: BreakoutObj[]; // TODO: save schema we loaded for - reload on change

  filterLevelsByArea: Map<string, FilterLevel[]>;

  availableSelections: Map<SelectionType, SelectionSummary[]>;

  recentSelections: Map<SelectionType, SelectionSummary[]>;

  activeSelections: Map<string, SelectionDetails>;

  autoSaveSelectionIdSet: Set<string>;

  selectionAccordionState: Map<string, string>;

  selectionLevelState: Map<string, SelectionLevelUiState>;

  selectionLevelItems: Map<string, SelectLevelStore>;

  dataSetLevels: Map<string, DictLevel[]>;

  dataSetSelections: Map<string, LevelSelection[]>;

  datasetSelectionHash: Map<string, string>;

  datasetTreeUpdateHash: Map<string, string>;

  dataSetCore: Map<string, DictCore>;

  datasetCoreHash: string | undefined;

  constructor(root: RootStore) {
    this.rootStore = root;

    this.schema = [];
    this.datatypes = [];
    this.breakouts = [];
    this.filterLevelsByArea = new Map<string, FilterLevel[]>();
    this.availableSelections = new Map<SelectionType, SelectionSummary[]>();
    this.recentSelections = new Map<SelectionType, SelectionSummary[]>();
    this.activeSelections = new Map<string, SelectionDetails>();
    this.autoSaveSelectionIdSet = new Set<string>();
    this.selectionAccordionState = new Map<string, string>();
    this.selectionLevelState = new Map<string, SelectionLevelUiState>();
    this.selectionLevelItems = new Map<string, SelectLevelStore>();
    this.dataSetLevels = new Map<string, DictLevel[]>();
    this.dataSetSelections = new Map<string, LevelSelection[]>();
    this.datasetSelectionHash = new Map<string, string>();
    this.datasetTreeUpdateHash = new Map<string, string>();
    this.dataSetCore = new Map<string, DictCore>();
    this.datasetCoreHash = undefined;

    makeAutoObservable(this);
  }

  async refreshDataTypes() {
    const schemaId = this.rootStore.activeUserStore.activeSchemaId;
    if (schemaId === undefined) return;
    try {
      const respone = await selectionService.api.getAvailableDatatypes(schemaId);
      runInAction(() => {
        this.datatypes = respone;
      });
    } catch (e) {
      runInAction(() => {
        this.datatypes = [];
      });
    }
  }

  async refreshSchema(notifyOnChange: boolean) {
    try {
      const respone = await schemaService.api.getVersions();
      runInAction(() => {
        if (notifyOnChange) {
          const result = compareSchema(respone, this.schema);
          result.forEach((s) => {
            this.rootStore.uiState.infoAlert = `Dataset "${s}" has just been updated and is now available for use`;
          });
        }
        this.schema = respone;

        if (this.rootStore.activeUserStore.activeSchemaId === undefined) {
          this.rootStore.activeUserStore.setSchemaAndVersion(this.schema[0].schemaId, undefined);
          this.rootStore.uiState.infoAlert = `No active schema was selected. Deafulting to ${this.schema[0].schemaId}`;
        }
      });
    } catch (e) {
      runInAction(() => {
        this.schema = [];
      });
    }
  }

  async refreshBreakouts() {
    const schemaId = this.rootStore.activeUserStore.activeSchemaId;
    if (schemaId === undefined) return;
    try {
      const respone = await selectionService.api.getAvailableBreakouts(schemaId);
      runInAction(() => {
        this.breakouts = respone;
      });
    } catch (e) {
      runInAction(() => {
        this.breakouts = [];
      });
    }
  }

  async refreshFilterLevels() {
    const schemaId = this.rootStore.activeUserStore.activeSchemaId;
    if (schemaId === undefined) return;
    try {
      const respone = await schemaService.api.getFilterLevels(schemaId);
      runInAction(() => {
        const areas = Array.from(new Set<string>(respone.map((f) => f.dataset)));
        this.filterLevelsByArea = new Map<string, FilterLevel[]>(
          areas.map((a) => [a.toLowerCase(), respone.filter((f) => f.dataset === a).sort((f) => f.sort)])
        );
      });
    } catch (e) {
      runInAction(() => {
        this.filterLevelsByArea = new Map<string, FilterLevel[]>();
      });
    }
  }

  getValidSchemaAndVersion() {
    const canViewQaVersions = true; // TODO: activeUserStore.hasPermission('VIEW_QA_VERSIONS');
    const schema = this.schema.find((s) => s.schemaId === this.rootStore.activeUserStore.activeSchemaId);
    if (schema) {
      const verId = getVersionId(
        this.rootStore.activeUserStore.activeSchemaVersion,
        schema.versions,
        canViewQaVersions
      );
      return [schema.schemaId, verId];
    }
    return [undefined, undefined];
  }

  getCurrentSchemaName() {
    const filtschema = this.schema.find((s) => s.schemaId === this.rootStore.activeUserStore.activeSchemaId);
    if (filtschema) {
      return [filtschema.name, this.rootStore.activeUserStore.activeSchemaVersion];
    }
    return ['<No Data Currently Available>', ''];
  }

  getSchemaDateRange(): [DateTime | undefined, DateTime | undefined] {
    const canViewQaVersions = true; // TODO: activeUserStore.hasPermission('VIEW_QA_VERSIONS');
    const schema = this.schema.find((s) => s.schemaId === this.rootStore.activeUserStore.activeSchemaId);
    if (schema) {
      const verId = getVersionId(
        this.rootStore.activeUserStore.activeSchemaVersion,
        schema.versions,
        canViewQaVersions
      );
      const ver = schema.versions.find((v) => v.versionId === verId);
      return [ver?.firstDate, ver?.lastDate];
    }
    return [undefined, undefined];
  }

  getDatatypeFromTag(tag: string): Datatype | undefined {
    // TODO - this assumes a 1:1 mapping of base-type to data-type
    // This will not be the case, and 'formula' needs to be parsed to
    // get all sub types then run calc...
    const dt = this.datatypes.find((d) => d.tag === tag);
    return dt;
  }

  getNamesForBreakoutIds(ids: string[]): string[] {
    return ids.map((id) => {
      if (id === DATATYPE_LEVEL_TAG) return 'Datatypes';
      const fn = this.breakouts.find((f) => f.id === id);
      return fn !== undefined ? fn.name : '<unknown>';
    });
  }

  getAllDatatypes(): Datatype[] {
    return this.datatypes;
  }

  getNamesForDtIds(dtIds: string[]): string[] {
    return dtIds.map((id) => {
      const dn = this.datatypes.filter((d) => d.tag === id);
      return dn.length === 1 ? dn[0].longName : '<unknown>';
    });
  }

  getBreakoutsByType(schemaId: string | undefined): BreakoutObj[] {
    if (schemaId === undefined) return [];
    return this.breakouts;
  }

  getLevelsByType(datasetType: string, ordered = false): FilterLevel[] | undefined {
    let levels = this.filterLevelsByArea.get(datasetType.toLowerCase());
    if (ordered) {
      // TODO - get users custom order here from their settings, and reorder here
      levels = levels?.slice().reverse();
    }
    return levels;
  }

  getSelectionDetails(selectionId: string): SelectionDetails | undefined {
    return this.activeSelections.get(selectionId);
  }

  async loadLinkedSelections(reportSelections: EmbeddedSelection[]) {
    const linkedSelections = reportSelections.filter((s) => s.linkedId && !s.treeRoot);
    const promises = linkedSelections.map((s) => this.loadSelectionDetails(s.linkedId ?? ''));
    await Promise.all(promises);
  }

  async loadSelectionDetails(selectionId: string): Promise<SelectionDetails | undefined> {
    try {
      const sel = await selectionService.api.getLinkedSelectionDetails(selectionId);
      runInAction(async () => {
        if (sel) this.activeSelections.set(selectionId, sel);
        return sel;
      });
    } catch (e) {
      this.rootStore.uiState.errorAlert = `Failed to load selection details: ${e}`;
      return undefined;
    }
    return undefined;
  }

  getSelectionTree(
    containerId: string | undefined,
    isEmbedded: boolean,
    selectionType: SelectionType
  ): SelectionNode | undefined {
    if (containerId === undefined) return undefined;
    let linkedId = containerId;

    // Selection is embedded in the report; containerId === reportId
    if (isEmbedded) {
      const reportSel = this.rootStore.activeReportStore.getReportSelection(containerId, selectionType);
      if (reportSel) {
        if (reportSel.linkedId) {
          // Contains a link to a selection
          linkedId = reportSel.linkedId;
        } else {
          // Contains an embedded set of nodes
          return reportSel?.treeRoot;
        }
      } else {
        // No selection in report - create an empty one?
        return createNewRoot(selectionType);
      }
    }

    // Selection is linked
    const sel = this.getSelectionDetails(linkedId);
    return sel?.treeRoot;
  }

  updateSelectionTree(newTree: SelectionNode, containerId: string, isEmbedded: boolean, selectionType: SelectionType) {
    let linkId = containerId;
    // Save to report or linked selection?
    if (isEmbedded) {
      const reportSel = this.rootStore.activeReportStore.getReportSelection(containerId, selectionType);
      if (reportSel?.linkedId) {
        linkId = reportSel.linkedId;
      } else {
        // Not linked - so assume embedded - update tree now via report store
        this.rootStore.activeReportStore.updateReportEmbeddedSelection(containerId, selectionType, newTree);
        return;
      }
    }

    // Update linked selection - mark linked selection as dirty
    const sel = this.activeSelections.get(linkId);
    if (sel) {
      sel.treeRoot = newTree;
      sel.needsSaving = true;
      this.triggerAutoSave(linkId);
    }
  }

  async saveActiveSelection(selectionId: string) {
    const sel = this.activeSelections.get(selectionId);
    if (sel) {
      try {
        await selectionService.api.saveLinkedSelectionDetails(sel);
        runInAction(() => {
          const sel2 = this.activeSelections.get(selectionId);
          if (sel2) {
            sel2.needsSaving = false;
            this.autoSaveSelectionIdSet.delete(selectionId);
            this.rootStore.uiState.successAlert = `Your selection changes '${sel2?.name ?? '?'}' have been saved`;
          }
        });
      } catch (e) {
        runInAction(() => {
          this.rootStore.uiState.errorAlert = `Failed to save selection details: ${e}`;
        });
      }
    }
  }

  async autoSaveAllSelections() {
    // Clone because we are about to change the undelying set on each save
    const ids = _.clone(this.autoSaveSelectionIdSet.values());
    await Promise.all(Array.from(ids).map((selId) => this.saveActiveSelection(selId)));
    runInAction(() => {
      this.autoSaveSelectionIdSet.clear();
      this.rootStore.uiState.clearAutoSave('selections');
    });
  }

  triggerAutoSave(selId: string) {
    this.autoSaveSelectionIdSet.add(selId);
    this.rootStore.uiState.triggerAutoSave('selections');
  }

  getSelectionSourceItems(selectionType: SelectionType): SelectionNode[] {
    if (selectionType === SelectionType.Datatypes) {
      return this.getAllDatatypes().map((d) => newNode(SelectionNodeType.EntryWithId, d.shortName, d.tag, d.tag));
    }

    if (selectionType === SelectionType.Period) {
      return [
        newDateRangeNode('Relative Period', '', '', 'relative'),
        newDateRangeNode('Absolute Period', '', '', 'absolute'),
      ];
    }

    if (
      selectionType === SelectionType.Dictionary ||
      selectionType === SelectionType.Media ||
      selectionType === SelectionType.Meta
    ) {
      const levels = this.getLevelsByType(selectionType);
      return levels?.map((l) => newNode(SelectionNodeType.EntryWithId, l.name, l.id)) ?? [];
    }

    // eslint-disable-next-line no-console
    console.log(
      `SelectionType "${selectionType}" is currently not handled. Fill in getSelectionSourceItems() for this type`
    );
    return [];
  }

  getSelectionAccordionState(statekey: string, id: string, position: number) {
    let activeId = this.selectionAccordionState.get(statekey);
    if (activeId === undefined && position === 0) {
      this.selectionAccordionState.set(statekey, id);
      activeId = id;
    }
    const active = activeId === id;
    return active;
  }

  setAcordionActive(statekey: string, id: string) {
    this.selectionAccordionState.set(statekey, id);
  }

  setSearchText(statekey: string, text: string) {
    let current = this.selectionLevelState.get(statekey);
    if (current === undefined) {
      current = { searchText: '' } as SelectionLevelUiState;
    }
    current.searchText = text;
    this.selectionLevelState.set(statekey, current);
  }

  getSearchText(statekey: string) {
    const current = this.selectionLevelState.get(statekey);
    if (current === undefined) return '';
    return current.searchText;
  }

  async refreshLevelItems(levelId: string, searchText: string): Promise<void> {
    const level = this.selectionLevelItems.get(levelId);
    if (level?.filterViaApi === true) {
      if (searchText === level.apiFilterText) return;
    } else if (level !== undefined) {
      return;
    }

    const entriesPerPage = 500;

    const [schemaId, versionId] = this.getValidSchemaAndVersion();
    if (schemaId === undefined) return;

    try {
      // TODO - handle paging in the local store too...
      const ret = await selectionService.api.getItemsForLevel(
        `${schemaId}.${versionId}`,
        levelId,
        searchText,
        true,
        0,
        entriesPerPage
      );
      runInAction(() => {
        this.selectionLevelItems.set(levelId, {
          filterViaApi: ret.totalCount > entriesPerPage,
          apiFilterText: searchText,
          items: ret.entries,
          totalCount: ret.totalCount,
        });
      });
    } catch (e) {
      runInAction(() => {
        this.selectionLevelItems.delete(levelId);
      });
    }
  }

  getLevelItems(levelId: string, searchText: string): SelectLevelStore {
    const level = this.selectionLevelItems.get(levelId);
    if (level !== undefined) {
      if (searchText.length > 0 && !level.filterViaApi) {
        return {
          items: level.items.filter((i) => i.toLowerCase().includes(searchText.toLowerCase())),
          filterViaApi: false,
          apiFilterText: searchText,
          totalCount: level.totalCount,
        } as SelectLevelStore;
      }
      return level;
    }
    return { items: [], filterViaApi: false, apiFilterText: '', totalCount: 0 } as SelectLevelStore;
  }

  addActiveSelectionToTree(updateKey: string, selectionType: SelectionType) {
    // Get active level (ie which level is currently being displayed in Accordion)
    const lid = this.selectionAccordionState.get(updateKey);
    const activeLevel = this.getLevelsByType(selectionType)?.filter(
      (l, i) => l.id === lid || (lid === undefined && i === 0)
    )[0];

    if (!activeLevel) return;
    const levelKey = CreateLevelKey(updateKey, activeLevel.id);

    // Get selections for this level
    const selections = this.rootStore.selectionDragStore.getLevelSelection(levelKey);

    // Create new node to add to tree
    const nodeToAdd = newLevelFilterNode(activeLevel.id, activeLevel.name, selections);
    this.rootStore.selectionDragStore.addNodeToTree(updateKey, nodeToAdd);
  }

  addActivePeriodToTree(updateKey: string) {
    // Get active level (ie which level is currently being displayed in Accordion)
    const lid = this.selectionAccordionState.get(updateKey);

    if (lid === 'relative') {
      // Relative Periods
      const state = this.rootStore.selectionDragStore.getRelativePanelState(updateKey);
      const title = getRelativePeriodNodeTitle(state);
      const { from, to } = getRelativePeriodNodeRange(state);
      const nodeToAdd = newDateRangeNode(title, from, to);
      this.rootStore.selectionDragStore.addNodeToTree(updateKey, nodeToAdd);
    } else {
      // Absolute Periods
      const state = this.rootStore.selectionDragStore.getAbsolutePanelState(updateKey);
      this.rootStore.selectionDragStore.addNodeToTree(updateKey, newAbsoluteDateRangeNode(state));
    }
  }

  async loadDictCoreForAllDatasets(forceUpdate = false): Promise<void> {
    const areas = Array.from(this.filterLevelsByArea.keys()).filter((a) => a !== SelectionType.Meta);
    await Promise.all(areas.map((a) => this.loadDictCoreForDataset(a, forceUpdate)));
  }

  async loadDictCoreForDataset(dataset: string, forceUpdate = false): Promise<void> {
    const [schemaId, versionId] = this.getValidSchemaAndVersion();
    if (schemaId === undefined) return;

    const key = `${dataset}.${schemaId}.${versionId}`;
    if (!forceUpdate && this.dataSetCore.has(key)) return;

    try {
      const pa = selectionService.api.getDictCore(`${schemaId}.${versionId}`, dataset);
      const pb = selectionService.api.getDictRelationship(`${schemaId}.${versionId}`, dataset);
      const [retCore, rel] = await Promise.all([pa, pb]);
      runInAction(() => {
        this.dataSetCore.set(key, { ...retCore, relationship: rel });
        this.datasetCoreHash = Array.from(this.dataSetCore.keys()).join(',');
      });
    } catch (e) {
      runInAction(() => {
        this.rootStore.uiState.errorAlert = `Failed to load dictionary core: ${e}`;
      });
    }
  }

  get datasetLoadingHash() {
    return this.datasetCoreHash;
  }

  getDictCore(dataset: string): DictCore | undefined {
    const [schemaId, versionId] = this.getValidSchemaAndVersion();
    const key = `${dataset}.${schemaId}.${versionId}`;
    return this.dataSetCore.get(key);
  }

  getLevelPrototype(storageKey: string): DictLevel[] {
    const dataset = extractDatasetFromStorageKey(storageKey);
    return this.getDictCore(dataset)?.levels ?? [];
  }

  getCoreLevelSelection(storageKey: string, levelTag: string, filterLevels: LevelSelection[]): DictEntry[] {
    const dataset = extractDatasetFromStorageKey(storageKey);
    const coreDefs = this.getDictCore(dataset);
    if (coreDefs === undefined) return [];

    const tagPositions = coreDefs.levels.map((t) => t.tag);

    // Filter relationship by items selected in prior levels
    const filters = filterLevels.map((l) => l.coreItems.filter((i) => i.selected).map((i) => i.id));
    const levelOffsets = filterLevels.map((l) => tagPositions.indexOf(l.tag));
    let wantedRelationships = coreDefs.relationship;
    for (let i = 0; i < filters.length; i += 1) {
      wantedRelationships = wantedRelationships.filter((r) => filters[i].includes(r[levelOffsets[i]]));
    }

    // Extract the unique ids for the level we are populating
    const wantedLevelPos = tagPositions.indexOf(levelTag);
    const itemsForLevel = _.uniq(wantedRelationships.map((r) => r[wantedLevelPos]));

    return itemsForLevel
      .map(
        (i) =>
          ({
            id: i,
            name: coreDefs.itemNames[wantedLevelPos].get(i) ?? '?',
            selected: false,
          } as DictEntry)
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  createNewLevelSelection(storageKey: string, levelTag: string, filterLevels: LevelSelection[]): LevelSelection {
    const protoLevels = this.getLevelPrototype(storageKey);
    const proto = protoLevels.find((l) => l.tag === levelTag);
    const coreItems = this.getCoreLevelSelection(storageKey, levelTag, filterLevels);
    return {
      tag: proto?.tag ?? levelTag,
      title: proto?.title ?? '?',
      coreItems,
      viewItems: coreItems,
    } as LevelSelection;
  }

  updateDataSetLevels(storageKey: string, levels: DictLevel[]) {
    this.dataSetLevels.set(storageKey, levels);
    this.updateSelectionHash(storageKey);
  }

  updateDataSetSelection(storageKey: string, sel: LevelSelection[]) {
    this.dataSetSelections.set(storageKey, sel);
    this.updateSelectionHash(storageKey);
  }

  resetDictLevels(storageKey: string) {
    this.updateDataSetLevels(storageKey, this.getLevelPrototype(storageKey));
    this.updateDataSetSelection(storageKey, []);
  }

  getDictLevels(storageKey: string): DictLevel[] {
    const levels = this.dataSetLevels.get(storageKey);
    return levels ?? [];
  }

  updateDictLevels(storageKey: string, levels: DictLevel[]) {
    const oldCurrent = this.dataSetLevels.get(storageKey)?.find((l) => l.current);
    const newCurrent = levels.find((l) => l.current);

    // Save changes
    this.updateDataSetLevels(storageKey, levels);

    // If 'current' has changed, add the new level boxes
    if (oldCurrent?.tag !== newCurrent?.tag) {
      this.openDictLevel(storageKey, newCurrent?.tag);
    }
  }

  filterDictLevelSelection(storageKey: string, levelTag: string, search: string): void {
    const allLevels = this.dataSetSelections.get(storageKey);
    if (allLevels === undefined || allLevels.length === 0) return;

    // Last level is the 'current' level - can only filter current level
    const currentLevelSelection = allLevels[allLevels.length - 1];
    currentLevelSelection.viewItems = filterDatasetEntries(currentLevelSelection.coreItems, search);

    this.updateDataSetSelection(
      storageKey,
      allLevels.map((l) => (l.tag === levelTag ? currentLevelSelection : l))
    );
  }

  openNextDictLevel(storageKey: string, tag: string): void {
    const currentLevels = this.dataSetLevels.get(storageKey) ?? [];
    const currentActiveLevelTags = currentLevels.filter((g) => g.active).map((l) => l.tag);
    const openLevel = currentActiveLevelTags.indexOf(tag) + 1;
    if (openLevel >= 0 && openLevel < currentActiveLevelTags.length) {
      this.openDictLevel(storageKey, currentActiveLevelTags[openLevel]);
    }
  }

  openDictLevel(storageKey: string, tag: string | undefined) {
    if (tag === undefined) return;
    const currentLevels = this.dataSetSelections.get(storageKey) ?? [];
    const currentLevelTags = currentLevels.map((l) => l.tag);
    const levelAlreadyOpen = currentLevelTags ? currentLevelTags.indexOf(tag) : -1;

    let newLevels: LevelSelection[] = [];
    if (levelAlreadyOpen >= 0) {
      // if level is already open - clear all levels below it
      newLevels = currentLevels.slice(0, levelAlreadyOpen + 1);
    } else {
      // If level is not open - add it to the end
      const levelToAdd = this.createNewLevelSelection(storageKey, tag, currentLevels);
      newLevels = [...currentLevels, levelToAdd];
    }

    this.updateDataSetSelection(
      storageKey,
      newLevels.map((l, i) => ({ ...l, sort: i, current: i === newLevels.length - 1 }))
    );

    // Make the last level 'current'
    this.updateDataSetLevels(
      storageKey,
      (this.dataSetLevels.get(storageKey) ?? []).map((l) => ({
        ...l,
        current: l.tag === tag,
        active: l.tag === tag ? true : l.active,
      }))
    );
  }

  closeDictLevel(storageKey: string, tag: string) {
    const currentLevels = this.dataSetSelections.get(storageKey) ?? [];
    const currentLevelTags = currentLevels.map((l) => l.tag);

    if (currentLevelTags.length > 1) {
      const posnToClose = currentLevelTags.indexOf(tag) - 1;
      if (posnToClose >= 0) {
        const tagToClose = currentLevelTags[posnToClose];
        // Ensure the NEXT level is now the 'current' level
        this.openDictLevel(storageKey, tagToClose);
        return;
      }
    }

    // Close all levels - clear current button
    this.updateDataSetSelection(storageKey, []);
    this.updateDataSetLevels(
      storageKey,
      (this.dataSetLevels.get(storageKey) ?? []).map((l) => ({
        ...l,
        current: false,
      }))
    );
  }

  getDictSelection(storageKey: string): LevelSelection[] {
    const sel = this.dataSetSelections.get(storageKey);
    return sel ?? [];
  }

  updateDictSelection(storageKey: string, tag: string, indices: number[], selected: boolean) {
    const currentSelection = this.dataSetSelections.get(storageKey);
    if (currentSelection === undefined || currentSelection.length === 0) return;

    const level = currentSelection.find((l) => l.tag === tag);
    if (level === undefined) return;

    for (let i = 0; i < indices.length; i += 1) {
      const idx = indices[i];

      // Index is to the VIEW item only (view items may be filtered)
      const viewItem = level.viewItems[idx];
      viewItem.selected = selected;

      // Find matching CORE item and update that too
      const ci = level.coreItems.find((f) => f.id === viewItem.id);
      if (ci !== undefined) ci.selected = selected;
    }

    this.updateDataSetSelection(
      storageKey,
      currentSelection.map((l) => (l.tag === tag ? level : l))
    );
  }

  // This hash will update/change on any dictBox level or selection change
  // Use to trigger tree updates on change...
  updateSelectionHash(storageKey: string) {
    const levels = this.getDictLevels(storageKey);
    const sel = this.getDictSelection(storageKey);

    const levelHash = strArrayHash(levels.map((l) => `${l.tag}:${l.sort}`));
    const selHash = strArrayHash(
      sel.map((l) => `${l.tag}:${strArrayHash(l.coreItems.filter((g) => g.selected).map((f) => `${f.id}`))}`)
    );
    this.datasetSelectionHash.set(storageKey, `${levelHash}-${selHash}`);

    // Trigger a tree update on any change
    this.addDictBoxSelectionToTree(storageKey);
  }

  getDictBoxSelectionHash(storageKey: string) {
    return this.datasetSelectionHash.get(storageKey);
  }

  getDictBoxTreeUpdateHash(storageKey: string) {
    return this.datasetTreeUpdateHash.get(storageKey);
  }

  setDictBoxTreeUpdateHash(storageKey: string) {
    return this.datasetTreeUpdateHash.set(storageKey, this.getDictBoxSelectionHash(storageKey) ?? '?');
  }

  /*
   * TEMP method to add the DictBox selection into the tree.
   * THis is NOT the final way to do this - we are only looking at the
   * lowest level here... Ideally we need to add the whole path to the tree
   */
  addDictBoxSelectionToTree(storageKey: string) {
    const sds = this.rootStore.selectionDragStore;
    const sel = this.getDictSelection(storageKey);
    const currentHash = this.getDictBoxSelectionHash(storageKey);

    // Only update on dictbox change...
    const lastTreeUpdateHash = this.getDictBoxTreeUpdateHash(storageKey);
    if (currentHash === lastTreeUpdateHash) return; // No change
    this.setDictBoxTreeUpdateHash(storageKey);

    const currentSelection: TreeSelection[] = sds.getSelectedTreeItems(storageKey);

    // Get selections for the lowest level that has items ticked
    let lowestPos = sel.length - 1;
    while (lowestPos >= 0) {
      if (sel[lowestPos].coreItems.filter((x) => x.selected).length > 0) break;
      lowestPos -= 1;
    }

    if (lowestPos < 0) return; // Nothing selected

    const selections = new Map(sel[lowestPos].coreItems.filter((x) => x.selected).map((y) => [y.id, y.name]));
    const levelTag = sel[lowestPos].tag;

    // Add to the current selection if possible
    const parentNode = currentSelection[0];
    let parentNid: string | undefined = parentNode !== undefined ? baseId(parentNode) : undefined;
    const selectionTreeNodeType = parentNode?.type;
    if (selectionTreeNodeType === SelectionNodeType.Root || selectionTreeNodeType === undefined) {
      // If root or none - then add a new folder
      parentNid = sds.addNewFolder(storageKey, 'root', 'Base Level Selection');
    }

    if (parentNid === undefined) return;

    // Ensure the parent is selected, so when user clicks again, we add to same group
    sds.setSelectedTreeItem(storageKey, parentNid);

    // Recreate child items every time!
    // Not ideal, but will allow unticked items to be automatically removed
    // Also when a new level is choosen, well automatically update the tree...

    const nodeToAdd = newLevelFilterNode(levelTag, levelTag, selections);
    nodeToAdd.expand = true;

    // Add a new level
    this.rootStore.selectionDragStore.replaceNodesChildren(storageKey, parentNid, [nodeToAdd]);
  }
}

export default SelectionStore;
