/* eslint-disable no-restricted-syntax */
import { DateTime } from 'luxon';
import {
  SelectionDragObj,
  SelectionItemStorage,
  SelectionNode,
  SelectionNodeType,
  SelectionType,
} from '../models/selectionModels';
import { DragIdGroupSeparator, baseId } from './dragUtils';

export interface AbsolutePanelState {
  startDate: DateTime;
  endDate: DateTime;
}

// -------------------------------------------------------------------------

export function getSelectionTypeUserText(stype: SelectionType): string {
  switch (stype) {
    case SelectionType.Dictionary:
      return 'Dictionary';
    case SelectionType.Media:
      return 'Media';
    case SelectionType.Period:
      return 'Period';
    case SelectionType.Datatypes:
      return 'Data-Type';
    case SelectionType.Meta:
      return 'Other';
    default:
      return '<?>';
  }
}
// -------------------------------------------------------------------------

export function getNewNodeId() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 10) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
// -------------------------------------------------------------------------

// Create a new node - set sensible defaults
export function newNode(
  nodeType: SelectionNodeType,
  name: string,
  id: string | undefined = undefined,
  tag: string | undefined = undefined
): SelectionNode {
  return {
    nid: id ?? getNewNodeId(),
    nodeType,
    name,
    tag,
    children: [],
    expand: true,
  } as SelectionNode;
}

export function newLevelFilterNode(levelId: string, levelName: string, selection: Map<string, string>): SelectionNode {
  const uId = getNewNodeId();

  const children = Array.from(selection.entries()).map((x) => {
    const [id, nme] = x;
    return {
      nid: `${id}#${uId}`,
      nodeType: SelectionNodeType.LevelEntry,
      name: nme,
      tag: id,
      children: [],
      expand: false,
    } as SelectionNode;
  });

  let name = `${levelName} Filter`;
  if (children.length < 3) {
    const names = children.map((x) => x.name).join(', ');
    name = `${levelName} : ${names}`;
  }

  return {
    nid: `${levelId}#${uId}`,
    nodeType: SelectionNodeType.LevelFilter,
    name,
    tag: levelId,
    children,
    expand: false,
  } as SelectionNode;
}

export function newDateRangeNode(
  title: string,
  from: string,
  to: string,
  id: string | undefined = undefined
): SelectionNode {
  const nid = id ?? getNewNodeId();
  return {
    nid,
    nodeType: SelectionNodeType.DateRange,
    name: title,
    tag: `${from}|${to}`,
    children: [
      newNode(SelectionNodeType.DateEntry, `From: ${from}`, `${nid}#from`, `from|${from}`),
      newNode(SelectionNodeType.DateEntry, `To: ${to}`, `${nid}#to`, `to|${to}`),
    ],
    expand: false,
  } as SelectionNode;
}

export function newAbsoluteDateRangeNode(state: AbsolutePanelState) {
  return newDateRangeNode(
    `${state.startDate.toFormat('dd/MM/yyyy')} to ${state.endDate.toFormat('dd/MM/yyyy')}`,
    state.startDate.toFormat('yyyy-MM-dd'),
    state.endDate.toFormat('yyyy-MM-dd')
  );
}

export function createCopyOfNode(nodeToCopy: SelectionNode) {
  return {
    ...nodeToCopy,
    nid: baseId({ id: nodeToCopy.nid }),
  } as SelectionNode;
}

// -------------------------------------------------------------------------

export function createNewRoot(selectionType: SelectionType): SelectionNode {
  const typeName = getSelectionTypeUserText(selectionType);
  const root = newNode(SelectionNodeType.Root, `${typeName} Selection`);
  root.nid = 'root'; // Root node has a known nid

  if (selectionType === SelectionType.Datatypes) {
    // Datatypes have a fixed folder structure with fixed ids
    const baseFolder = newNode(SelectionNodeType.FixedFolder, 'Base Data-Types', 'basedts', 'basedts');
    const totalsFolder = newNode(SelectionNodeType.FixedFolder, 'Total Data-Types', 'totaldts', 'totaldts');
    const grandTotalsFolder = newNode(
      SelectionNodeType.FixedFolder,
      'Grand Total Data-Types',
      'gtotaldts',
      'gtotaldts'
    );
    root.children = [baseFolder, totalsFolder, grandTotalsFolder];
  }

  return root;
}

// -------------------------------------------------------------------------
/* Create the drag item array from the tree.
 * Note that the linking between tree nodes drives what is avaialble for drag/drop.
 */
export function selectionTreeToDragItems(treeRootNode: SelectionNode): SelectionDragObj[] {
  const dragObjs: SelectionDragObj[] = [];
  const addNodeAndChildren = (node: SelectionNode, depth: number, parent: SelectionDragObj | undefined) => {
    const obj = {
      id: `${node.nid}${DragIdGroupSeparator}treeGroup`,
      node,
      parent,
      depth,
      // sourceObj: undefined,
      isDragging: false,
      isOver: false,
      insertBefore: false,
      insertAfter: false,
      insertInside: false,
    } as SelectionDragObj;
    dragObjs.push(obj);
    if (node.expand) node.children?.forEach((c) => addNodeAndChildren(c, depth + 1, obj));
  };
  addNodeAndChildren(treeRootNode, 0, undefined);
  return dragObjs;
}

// -------------------------------------------------------------------------
export function baseIdToSourceGroupId(bid: string): string {
  return `${bid}${DragIdGroupSeparator}sourceGroup`;
}

// -------------------------------------------------------------------------
// Wrap source objects in drag objects
export function selectionSourceToDragItems(sourceItems: SelectionNode[]): SelectionDragObj[] {
  return sourceItems.map(
    (x) =>
      ({
        id: baseIdToSourceGroupId(x.nid),
        node: x,
        parent: undefined,
        depth: 0,
        // sourceObj: x,
        isDragging: false,
        isOver: false,
        insertBefore: false,
        insertAfter: false,
        insertInside: false,
      } as SelectionDragObj)
  );
}

// -------------------------------------------------------------------------

export function getRootFromSelectionStore(store: SelectionItemStorage): SelectionNode {
  const root = store.treeGroup[0].node; // ALWAYS first item in treeGroup
  if (root === undefined) throw new Error('SelectionStore has no root node');
  if (root.nodeType !== SelectionNodeType.Root) throw new Error('Root node has incorrect type');
  return root;
}

// -------------------------------------------------------------------------

function copyNodeTree(root: SelectionNode): SelectionNode {
  const copyNode = (node: SelectionNode): SelectionNode =>
    ({
      ...node,
      children: node.children?.map((c) => copyNode(c)),
    } as SelectionNode);
  return copyNode(root);
}

// -------------------------------------------------------------------------
// Compare two trees - return true if trees are itentical
export function areTreesTheSame(oldTree: SelectionNode, newTree: SelectionNode): boolean {
  const areNodesTheSame = (o: SelectionNode, n: SelectionNode): boolean => {
    if (o.nid !== n.nid) return false;
    if (o.nodeType !== n.nodeType) return false;
    if (o.name !== n.name) return false;
    if (o.children?.length !== n.children?.length) return false;
    for (let i = 0; i < (o.children?.length ?? 0); i += 1) {
      if (o.children && n.children && !areNodesTheSame(o.children[i], n.children[i])) return false;
    }
    return true;
  };
  return areNodesTheSame(oldTree, newTree);
}

// -------------------------------------------------------------------------

export function findNodeById(nid: string | undefined, current: SelectionNode): SelectionNode | undefined {
  if (nid) {
    if (nid === current.nid) return current;
    for (const c of current.children ?? []) {
      const found = findNodeById(nid, c);
      if (found) return found;
    }
  }
  return undefined;
}

export function findParentNodeById(nid: string | undefined, current: SelectionNode): SelectionNode | undefined {
  if (nid) {
    for (const c of current.children ?? []) {
      if (c.nid === nid) return current;
      const found = findParentNodeById(nid, c);
      if (found) return found;
    }
  }
  return undefined;
}

export function isChildOf(nid: string | undefined, parent: SelectionNode | undefined): boolean {
  if (nid && parent) {
    for (const c of parent.children ?? []) {
      if (c.nid === nid) return true;
      const found = isChildOf(nid, c);
      if (found) return true;
    }
  }
  return false;
}

export function forEachChildNode(
  parent: SelectionNode | undefined,
  recurse: boolean,
  action: (node: SelectionNode) => void
) {
  if (parent) {
    for (const c of parent.children ?? []) {
      action(c);
      if (recurse) forEachChildNode(c, true, action);
    }
  }
}

// -------------------------------------------------------------------------

function getCoreId(nid: string): string {
  return nid.split('#')[0];
}

function doChildrenIncludeCoreId(parent: SelectionNode | undefined, node: SelectionNode): boolean {
  const coreId = getCoreId(node.nid);
  return parent?.children?.find((x) => getCoreId(x.nid) === coreId) !== undefined;
}

// -------------------------------------------------------------------------

export function moveTreeNodeToParent(
  currentStore: SelectionItemStorage,
  nodeToMove: SelectionNode | undefined,
  destinationParent: SelectionNode | undefined
): SelectionItemStorage {
  const root = getRootFromSelectionStore(currentStore);
  if (root === undefined) return currentStore;

  // Cannot mutate currentStore/nodes - so make a copy
  const tree = copyNodeTree(root);

  // Find copies of nodes to operate on (must exist in the tree)
  const node = findNodeById(nodeToMove?.nid, tree);
  const parent = findParentNodeById(nodeToMove?.nid, tree);
  const dest = findNodeById(destinationParent?.nid, tree);

  // Skip if destination is already the current parent
  if (node === undefined || parent === undefined || dest === undefined || parent === dest) return currentStore;
  if (isChildOf(dest.nid, node)) return currentStore; // Cannot move to a child of ourself (would create a loop)

  // Now remove from current parent
  parent.children = parent.children?.filter((x) => x.nid !== node.nid);
  // Add to new parent
  if (!doChildrenIncludeCoreId(dest, node)) dest.children = dest.children ? [...dest.children, node] : [node];

  return {
    ...currentStore,
    treeGroup: selectionTreeToDragItems(tree), // Convert tree back to drag items
  };
}

// -------------------------------------------------------------------------

export function copySourceNodeToTreeParent(
  currentStore: SelectionItemStorage,
  nodeToInsert: SelectionNode | undefined, // MUST be mutable! Copy original nodes!
  destinationParent: SelectionNode | string | undefined
): SelectionItemStorage {
  const root = getRootFromSelectionStore(currentStore);
  if (root === undefined || nodeToInsert === undefined) return currentStore;

  // Cannot mutate currentStore/nodes - so make a copy
  const tree = copyNodeTree(root);

  // Find copies of nodes to operate on (must exist in the tree)
  const nid =
    typeof destinationParent === 'string'
      ? baseId({ id: destinationParent })
      : (destinationParent as SelectionNode).nid;
  const dest = findNodeById(nid, tree);
  if (nodeToInsert === undefined || dest === undefined) return currentStore;

  // Add to new parent - expand so we can see what we added
  dest.children = dest.children ? [...dest.children, nodeToInsert] : [nodeToInsert];
  dest.expand = true;

  return {
    ...currentStore,
    treeGroup: selectionTreeToDragItems(tree), // Convert tree back to drag items
  };
}

// -------------------------------------------------------------------------

export function deleteTreeNode(
  currentStore: SelectionItemStorage,
  nidToDelete: string,
  selectionType: SelectionType
): SelectionItemStorage {
  const root = getRootFromSelectionStore(currentStore);
  if (root === undefined) return currentStore;

  // Cannot mutate currentStore/nodes - so make a copy
  let tree = copyNodeTree(root);
  const node = findNodeById(nidToDelete, tree);
  if (node?.nodeType === SelectionNodeType.Root) {
    // To delete root  - replace root-node with a new empty node
    tree = createNewRoot(selectionType);
  } else if (node && node?.nodeType !== SelectionNodeType.FixedFolder) {
    // Now remove node from parent
    const parent = findParentNodeById(nidToDelete, tree);
    if (parent?.children) {
      parent.children = parent.children.filter((x) => x.nid !== nidToDelete);
    }
  }
  return {
    ...currentStore,
    treeGroup: selectionTreeToDragItems(tree), // Convert tree back to drag items
  };
}

// -------------------------------------------------------------------------

export function replaceParentsChildren(
  currentStore: SelectionItemStorage,
  parentNid: string,
  children: SelectionNode[]
) {
  const root = getRootFromSelectionStore(currentStore);
  if (root === undefined) return currentStore;
  const tree = copyNodeTree(root);
  const parentNode = findNodeById(parentNid, tree);
  if (parentNode !== undefined) parentNode.children = children;
  return {
    ...currentStore,
    treeGroup: selectionTreeToDragItems(tree), // Convert tree back to drag items
  };
}

// -------------------------------------------------------------------------

export function updateTreeNodeName(currentStore: SelectionItemStorage, nid: string, name: string) {
  const root = getRootFromSelectionStore(currentStore);
  if (root === undefined) return currentStore;
  const tree = copyNodeTree(root);
  const node = findNodeById(nid, tree);
  if (node && node?.nodeType !== SelectionNodeType.FixedFolder) node.name = name;
  return {
    ...currentStore,
    treeGroup: selectionTreeToDragItems(tree), // Convert tree back to drag items
  };
}

// -------------------------------------------------------------------------

export function addChildFolderNode(
  currentStore: SelectionItemStorage,
  parentNid: string | undefined,
  folderName: string
): { storage: SelectionItemStorage; newFolderNid: string | undefined } {
  let newFolderNid: string | undefined;
  const root = getRootFromSelectionStore(currentStore);

  if (root === undefined) return { storage: currentStore, newFolderNid };

  // Cannot mutate currentStore/nodes - so make a copy
  const tree = copyNodeTree(root);
  const parentNode = parentNid ? findNodeById(parentNid, tree) : tree;
  if (parentNode) {
    const newNde = newNode(SelectionNodeType.Folder, folderName);
    newFolderNid = newNde.nid;
    parentNode.children = parentNode.children ? [...parentNode.children, newNde] : [newNde];
    parentNode.expand = true;
  }

  const storage = {
    ...currentStore,
    treeGroup: selectionTreeToDragItems(tree), // Convert tree back to drag items
  };
  return { storage, newFolderNid };
}

// -------------------------------------------------------------------------

export function toggleExpandTreeNode(currentStore: SelectionItemStorage, nid: string) {
  const expandAllowedTypes = [SelectionNodeType.Folder, SelectionNodeType.LevelFilter, SelectionNodeType.DateRange];
  const root = getRootFromSelectionStore(currentStore);
  if (root === undefined) return currentStore;
  const tree = copyNodeTree(root);
  const node = findNodeById(nid, tree);
  if (node) {
    const expandAllowed = expandAllowedTypes.find((t) => t === node.nodeType) !== undefined;
    node.expand = expandAllowed ? !node.expand : undefined;
    // Root cannot be collapsed
    if (node.nodeType === SelectionNodeType.Root) node.expand = true;
  }
  return {
    ...currentStore,
    treeGroup: selectionTreeToDragItems(tree), // Convert tree back to drag items
  };
}

// -------------------------------------------------------------------------
// Clean a node-tree ready for sending via api. Ensure any null/undefined are
// not included in the json.
export function cleanSelectionTree(node: SelectionNode): SelectionNode {
  if (node) {
    const children = node.children?.map((c) => cleanSelectionTree(c));
    const data = {} as SelectionNode;
    data.nid = node.nid;
    data.nodeType = node.nodeType;
    data.name = node.name;
    if (node.tag) data.tag = node.tag;
    if (children?.length) data.children = children;
    if (node.expand) data.expand = true;
    return data;
  }
  return node;
}

export function summariseSelectionTree(treeRootNode: SelectionNode): string[] {
  const msg: string[] = [];
  forEachChildNode(treeRootNode, true, (node) => {
    if (
      node.nodeType === SelectionNodeType.LevelEntry ||
      node.nodeType === SelectionNodeType.DateRange ||
      node.nodeType === SelectionNodeType.EntryWithId
    ) {
      msg.push(node.name);
    }
  });
  return msg;
}
