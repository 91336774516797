import { RefObject, useLayoutEffect, useState } from 'react';

function getDimensionObject(node: HTMLElement): DOMRect {
  const rect = node.getBoundingClientRect();
  // Old browsers may not have top/left as well as x/y.
  // If need to handle this then create separate dimention object
  // See https://github.com/Swizec/useDimensions/blob/master/src/index.ts
  return rect;
}

interface UseDimentionsProps {
  targetRef: RefObject<HTMLElement>;
  liveMeasure?: boolean | undefined;
}

export default function useDimensions({ targetRef, liveMeasure }: UseDimentionsProps) {
  const [dimensions, setDimensions] = useState<DOMRect>({} as DOMRect);

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (targetRef.current !== null) {
      const node = targetRef.current;
      const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
      measure();
      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);
        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [targetRef, liveMeasure]);

  return dimensions;
}

useDimensions.propDefaults = {
  liveMeasure: false,
};

/*
TEST Component

import { useRef } from 'react';
import useDimensions from './useDimentions';

export default function MeasuredNode() {
  const ref = useRef<HTMLParagraphElement>({} as HTMLParagraphElement);
  const { x, y, width, height } = useDimensions({ targetRef: ref, liveMeasure: true });

  return (
    <p ref={ref}>
      I am a paragraph at ({x}px, {y}px) position with a width of {width}px and height of {height}px
    </p>
  );
}

*/
