import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RootContext } from '../stores/storeProvidor';

const AuthenticatedRoute = observer(() => {
  const { activeUserStore: userStore, authStore } = useContext(RootContext);

  const user = userStore.getUser;

  // eslint-disable-next-line no-console
  console.log('Current User:', user?.email);

  if (authStore.initialRefreshDone && !userStore.isAuthenticated) {
    // No user - redirect to home
    return <Navigate to="/" replace />;
  }

  if (!authStore.initialRefreshDone || userStore.isAuthenticated) {
    // Access allowed for role
    return <Outlet />;
  }

  // We do have a user, but they don't have access to this path - return to Users App home
  return <Navigate to="/app" replace />;
});

export default AuthenticatedRoute;
