import { ListObjectWithTags } from '../../models/listbase';
import SearchListItem from './SearchListItem';
import { toggleSelection } from '../../utils/helpers';

interface SearchListProps {
  parentClass?: string;
  items: ListObjectWithTags[];
  selections: string[];
  updateSelections: (selection: string[]) => void;
}

export default function SearchList({ parentClass, items, selections, updateSelections }: SearchListProps) {
  const rowClicked = (obj: ListObjectWithTags) => {
    updateSelections(toggleSelection(selections, obj.id));
  };

  let listBody = (
    <div className={`mt-9 text-gray-300 text-center h-14 items-center ${parentClass}`}>Nothing to show...</div>
  );

  if (items.length > 0) {
    listBody = (
      <ul>
        {items.map((row, pos) => (
          <SearchListItem
            onClickFn={rowClicked}
            item={row}
            selected={selections.includes(row.id)}
            key={row.id}
            index={pos}
          />
        ))}
      </ul>
    );
  }

  return <div className={`mb-2 border-2 border-gray-300 ${parentClass} overflow-y-auto`}>{listBody}</div>;
}

SearchList.defaultProps = {
  parentClass: '',
};
