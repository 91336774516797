import { ReactNode } from 'react';
import { TopMenuGridFiller } from '../controls/TopMenu';

interface AppGridProps {
  children: ReactNode;
  className?: string;
}

export default function AppGrid4x6({ children, className }: AppGridProps) {
  // Fill screen with grid; First row height must match <TopMenu>
  return (
    <div
      className={`w-screen h-screen grid grid-cols-4 grid-rows-[3rem_1fr_1fr_1fr_1fr_3rem] ${className} overflow-hidden`}
    >
      <TopMenuGridFiller />
      {children}
    </div>
  );
}

AppGrid4x6.defaultProps = {
  className: '',
};

// -----------------------------------------------------------------------------

interface AppGridSectionProps {
  children: ReactNode;
  className: string;
}

export function AppGridSingleColFullHeight({ children, className }: AppGridSectionProps) {
  return <div className={`row-start-2 row-end-7 col-span-1 ${className}`}>{children}</div>;
}

// -----------------------------------------------------------------------------

export function AdminGrid4x6DoubleColumn({ children, className }: AppGridSectionProps) {
  return <div className={`row-start-2 row-end-7 col-span-2 ${className}`}>{children}</div>;
}

// -----------------------------------------------------------------------------
