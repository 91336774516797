import { useEffect, useRef } from 'react';

/*
 * Retriggerable Timer
 * Usage:
    let [count, setCount] = useState(0);

    const [triggerFn, clearFn] = useTimeout(() => {
      setCount(count + 1);
    }, 1000);

    triggerFn();

    Note triggerFn() and clearFn() can be called repeatedly to retrigger the timer
    Timer will fire, after given delay, after last trigger
 */

export default function useTimeout(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();
  const timerId = useRef<string | number | NodeJS.Timeout | undefined>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const actionFn = () => {
    if (savedCallback?.current) savedCallback.current();
  };

  // Clear existing timer
  const reset = () => {
    if (timerId?.current !== undefined) clearTimeout(timerId.current);
  };

  // Clear existing and restart timer
  const trigger = () => {
    reset();
    timerId.current = setTimeout(actionFn, delay);
  };

  return [trigger, reset];
}
