/* eslint-disable class-methods-use-this */
import { BreakoutGroups, BreakoutObj, BreakoutType } from '../models/breakoutModels';
import { Datatype } from '../models/datatypes';
import { DictCore, DictLevel } from '../models/dictSelection';
import { PagedEntries, SelectionDetails, SelectionSummary, SelectionType } from '../models/selectionModels';
import { convertToDate } from '../utils/dates';
import apiClient from './apiConfig';
import CancelApi from './apiUtils';

class SelectionAPI {
  private canceller = new CancelApi();

  async getAvailableDatatypes(schemaId: string, cancelExisting = false): Promise<Datatype[]> {
    const response = await apiClient.api.get<Datatype[]>(`select/datatypes/${schemaId}`, {
      signal: cancelExisting ? this.canceller.getSignal(`availDts`) : undefined,
    });
    return response.data;
  }

  async getAvailableBreakouts(schemaId: string, cancelExisting = false): Promise<BreakoutObj[]> {
    const response = await apiClient.api.get<BreakoutGroups[]>(`select/breakouts/${schemaId}`, {
      signal: cancelExisting ? this.canceller.getSignal(`Bo`) : undefined,
    });
    const r = response.data
      .map((g) =>
        g.breakouts.map(
          (b) =>
            ({
              ...b,
              groupName: g.groupName,
              type: BreakoutType[g.groupType as keyof typeof BreakoutType] ?? BreakoutType.Meta,
            } as BreakoutObj)
        )
      )
      .flat(1);
    return r;
  }

  async getLinkedSelectionDetails(selectionId: string, cancelExisting = false): Promise<SelectionDetails> {
    const response = await apiClient.api.get<SelectionDetails>(`select/${selectionId}`, {
      signal: cancelExisting ? this.canceller.getSignal(`Bo`) : undefined,
    });
    return {
      ...response.data,
      selectionType:
        SelectionType[response.data.selectionType as unknown as keyof typeof SelectionType] ??
        SelectionType.UnknownType,
      needsSaving: false,
    } as SelectionDetails;
  }

  async saveLinkedSelectionDetails(selection: SelectionDetails) {
    const response = await apiClient.api.put<SelectionDetails>(`/select`, selection);
    return { status: response.data };
  }

  async createNewLinkedSelection(wantedType: SelectionType): Promise<SelectionDetails> {
    const req = { selectionType: wantedType };
    const response = await apiClient.api.post(`/select`, req);
    return response.data as SelectionDetails;
  }

  async getAvailableLinkedSelection(cancelExisting = false) {
    const response = await apiClient.api.get<SelectionSummary[]>(`/select/list`, {
      signal: cancelExisting ? this.canceller.getSignal(`selectAvail`) : undefined,
    });
    return response.data.map((sel) => ({
      ...sel,
      modified: convertToDate(sel.modified),
    }));
  }

  async getRecentLinkedSelection(cancelExisting = false) {
    const response = await apiClient.api.get<SelectionSummary[]>(`/select/recent`, {
      params: { quantity: 10, selectionType: 'media' }, // TODO - fix this
      signal: cancelExisting ? this.canceller.getSignal(`selectRecent`) : undefined,
    });
    return response.data.map((sel) => ({
      ...sel,
      modified: convertToDate(sel.modified),
    }));
  }

  async getItemsForLevel(
    schemaId: string,
    levelId: string,
    filterText: string,
    includeContains: boolean,
    offset: number,
    pageSize: number,
    cancelExisting = true
  ): Promise<PagedEntries> {
    const response = await apiClient.api.get<PagedEntries>(`/select/elements`, {
      params: {
        schemaWithVersion: schemaId, // Includes version
        levelId,
        filterText,
        includeContains,
        offset,
        pageSize,
      },
      signal: cancelExisting ? this.canceller.getSignal(`getItemsForLevel`) : undefined,
    });
    return response.data;
  }

  async getDictCore(schemaWithVersion: string, dataset: string, cancelExisting = true): Promise<DictCore> {
    type DictCoreDto = {
      schema: string;
      dataset: string;
      levels: DictLevel[];
      itemNames: string[][];
    };

    const response = await apiClient.api.get<DictCoreDto>(`/select/dictcore`, {
      params: {
        schemaWithVersion,
        dataset,
      },
      signal: cancelExisting ? this.canceller.getSignal(`dictcore`) : undefined,
    });

    const itemNames: Map<string, string>[] = response.data.itemNames.map(
      (e) =>
        new Map(
          e.map((f) => {
            const b = f.split('|');
            return [b[0], b[1]];
          })
        )
    );

    return {
      schema: response.data.schema,
      dataset: response.data.dataset,
      levels: response.data.levels,
      itemNames,
    } as DictCore;
  }

  async getDictRelationship(schemaWithVersion: string, dataset: string, cancelExisting = true): Promise<string[][]> {
    const response = await apiClient.api.get<string[]>(`/select/dictrelationship`, {
      params: {
        schemaWithVersion,
        dataset,
      },
      signal: cancelExisting ? this.canceller.getSignal(`dictrelationship`) : undefined,
    });

    return response.data.map((r) => r.split('|'));
  }
}

export default SelectionAPI;
