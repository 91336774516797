import { useState } from 'react';
import IconMultistateCheckbox, { CheckboxState } from '../../../../icons/multistateCheckbox';

interface SelectLevelChoiceProps {
  id: string;
  name: string;
  hint?: string | undefined;
  checked: boolean;
  disabled: boolean;
  showId: boolean;
  onChange: (id: string, name: string, f: boolean) => void;
}

function SelectLevelChoice({ id, name, hint, checked, disabled, showId, onChange }: SelectLevelChoiceProps) {
  return (
    <button
      className={`${
        disabled ? 'opacity-50' : ''
      } border-b border-gray-200 text-sm px-1 py-0.5 w-full text-left hover:bg-blue-100`}
      onClick={(e) => {
        e.preventDefault();
        if (!disabled) {
          onChange(id, name, !checked);
        }
      }}
      type="button"
    >
      <span className="mr-2 text-slate-500">
        <IconMultistateCheckbox checked={checked ? CheckboxState.Checked : CheckboxState.Unchecked} />
      </span>
      <span className={checked ? 'font-semibold' : ''}>{name}</span>
      {showId ? <span className="px-2 text-gray-400">({id})</span> : null}
      {hint && <span className="text-xs font-light pt-0.5 float-right"> {hint}</span>}
    </button>
  );
}

SelectLevelChoice.defaultProps = {
  hint: undefined,
};

// ---------------------------------------------------------------------------------

interface SelectLevelListProps {
  levelItems: string[];
  selectedItems: Map<string, string>;
  onChange: (id: string, name: string, f: boolean) => void;
  showIds: boolean;
}

export default function SelectLevelList({ levelItems, selectedItems, onChange, showIds }: SelectLevelListProps) {
  // TODO - how do we only render visible items in the list? Does React already do this?
  // Gonna be needed for large lists

  // TODO: Take advantage of fixed length ids for faster parsing

  return (
    <div className="flex-1 w-full overflow-auto">
      {levelItems.map((item) => {
        const [name, id] = item.split('|');
        return (
          <SelectLevelChoice
            key={id}
            id={id}
            name={name}
            checked={selectedItems.has(id)}
            disabled={false}
            showId={showIds}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
}
