export default class VizDatatype {
  private dtId: string;

  private name: string;

  public divisor: number;

  public decimals: number;

  constructor(id: string, name: string) {
    this.dtId = id;
    this.name = name;
    this.divisor = 1000;
    this.decimals = 2;
  }

  get title(): string {
    if (this.divisor === 1) return this.name;
    return `${this.name} (${this.divisor})`;
  }

  formatValue(value: number): number {
    const dpDiv = 10 ** this.decimals;
    const v = (value * dpDiv) / this.divisor;
    return Math.round(v) / dpDiv;
  }

  autoSetDivisor(maxValue: number): void {
    if (maxValue < 1000) {
      this.divisor = 1;
      this.decimals = 2;
    } else if (maxValue < 1000000) {
      this.divisor = 1000;
      this.decimals = 2;
    } else if (maxValue < 1000000000) {
      this.divisor = 1000000;
      this.decimals = 1;
    } else {
      this.divisor = 1000000000;
      this.decimals = 2;
    }
  }
}
