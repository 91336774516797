import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useState } from 'react';
import DialogTextInput from '../controls/form/DialogTextInput';
import { AdminUser } from '../../models/userModel';
import DialogSelect from '../controls/form/DialogSelect';
import { RootContext } from '../../stores/storeProvidor';
import AdminModalValidationBox from '../admin/controls/AdminModalValidationBox';

const EditUserModal = NiceModal.create(({ user }: { user: AdminUser }) => {
  const modal = useModal();
  const rctx = useContext(RootContext);
  const adminStore = rctx.getAdminStore();
  const [storedUser, setStoredUser] = useState<AdminUser>({ ...user });
  const [validationMsg, setValidationMsg] = useState<string[]>([]);
  const creatorsOrgId = rctx.activeUserStore.getUser?.orgId;

  const hasError = (id: string) => validationMsg.some((msg) => msg.startsWith(id));
  const disableSave = () => validationMsg.length > 1 || (validationMsg.length === 1 && !hasError('err'));

  const onConfirm = () => {
    // Validate
    const msg: string[] = [];
    if (storedUser.userName === '') msg.push('username|User Name is required');
    if (storedUser.firstName === '') msg.push('firstname|First Name is required');
    if (storedUser.lastName === '') msg.push('lastname|Last Name is required');
    if (storedUser.email === '') msg.push('email|Email is required');
    if (storedUser.phone === '') msg.push('phone|Phone number is required');
    setValidationMsg(msg);
    if (msg.length !== 0) {
      return;
    }

    // Call the API to create the user
    adminStore.updateUser(storedUser).then(
      () => {
        modal.remove();
      },
      (e) => {
        setValidationMsg([`err|${e.message}`]);
      }
    );
  };

  const onCancel = () => {
    modal.remove();
  };

  return (
    <Modal show onClose={onCancel} size="sm" popup>
      <Modal.Body>
        <h3 className="pt-6 pb-2">Edit user: {storedUser.userName}</h3>
        <DialogTextInput
          id="username"
          label="User Name"
          defaultValue={storedUser.userName}
          onEdit={(v) => setStoredUser({ ...storedUser, userName: v })}
          hasError={hasError('username')}
        />
        <DialogSelect
          id="title"
          label="Title"
          options={['Ms|Ms', 'Mrs|Mrs', 'Mr|Mr', 'Dr|Dr', 'None|None']}
          selectedOption={user.title === '' ? 'None' : user.title}
          defaultOption={storedUser.title}
          onSelect={(id, val) => {
            setStoredUser({ ...storedUser, title: val === 'None' ? '' : val });
          }}
          disabled={false}
        />
        <DialogTextInput
          id="givenname"
          label="Given Name"
          defaultValue={storedUser.firstName}
          onEdit={(v) => setStoredUser({ ...storedUser, firstName: v })}
          hasError={hasError('firstname')}
        />
        <DialogTextInput
          id="lastname"
          label="Last Name"
          defaultValue={storedUser.lastName}
          onEdit={(v) => setStoredUser({ ...storedUser, lastName: v })}
          hasError={hasError('lastname')}
        />
        <DialogTextInput
          id="email"
          label="Email"
          defaultValue={storedUser.email}
          onEdit={(v) => setStoredUser({ ...storedUser, email: v })}
          hasError={hasError('email')}
          inputType="email"
        />
        <DialogTextInput
          id="phone"
          label="Phone"
          defaultValue={storedUser.phone}
          onEdit={(v) => setStoredUser({ ...storedUser, phone: v })}
          hasError={hasError('phone')}
          inputType="tel"
        />

        <AdminModalValidationBox msgs={validationMsg} />

        <div className="flex justify-center gap-4 mt-4">
          <Button onClick={onConfirm} disabled={disableSave()}>
            Save
          </Button>
          <Button color="gray" onClick={onCancel}>
            Discard
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default EditUserModal;
