import { useContext, PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { RootContext } from '../stores/storeProvidor';
import Perms from './permissions';

interface WithPermissionProps {
  permsNeeded: string | string[] | undefined;
}

const WithPermission = observer(({ children, permsNeeded }: PropsWithChildren<WithPermissionProps>) => {
  const { activeUserStore } = useContext(RootContext);
  const userPerms = activeUserStore.getUser?.permissions ?? [];

  if (Perms.HasPermission(userPerms, permsNeeded === undefined ? Perms.AllAdmin : permsNeeded))
    return <div>{children}</div>;
  return null;
});

export default WithPermission;
