import { ResponsiveLine } from '@nivo/line';
import { VizProps } from './VizData';

// See https://nivo.rocks/line/

const lineData = [
  {
    id: 'japan',
    color: 'hsl(120, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 211,
      },
      {
        x: 1,
        y: 165,
      },
      {
        x: 2,
        y: 182,
      },
      {
        x: 3,
        y: 233,
      },
      {
        x: 4,
        y: 238,
      },
      {
        x: 5,
        y: 143,
      },
      {
        x: 6,
        y: 237,
      },
      {
        x: 7,
        y: 123,
      },
      {
        x: 8,
        y: 240,
      },
      {
        x: 9,
        y: 99,
      },
      {
        x: 10,
        y: 148,
      },
      {
        x: 11,
        y: 289,
      },
      {
        x: 12,
        y: 266,
      },
      {
        x: 13,
        y: 26,
      },
      {
        x: 14,
        y: 45,
      },
      {
        x: 15,
        y: 105,
      },
      {
        x: 16,
        y: 249,
      },
      {
        x: 17,
        y: 244,
      },
      {
        x: 18,
        y: 70,
      },
      {
        x: 19,
        y: 90,
      },
      {
        x: 20,
        y: 108,
      },
      {
        x: 21,
        y: 128,
      },
      {
        x: 22,
        y: 187,
      },
      {
        x: 23,
        y: 27,
      },
      {
        x: 24,
        y: 157,
      },
      {
        x: 25,
        y: 229,
      },
      {
        x: 26,
        y: 82,
      },
      {
        x: 27,
        y: 240,
      },
      {
        x: 28,
        y: 123,
      },
      {
        x: 29,
        y: 248,
      },
      {
        x: 30,
        y: 90,
      },
      {
        x: 31,
        y: 41,
      },
      {
        x: 32,
        y: 12,
      },
      {
        x: 33,
        y: 39,
      },
      {
        x: 34,
        y: 8,
      },
      {
        x: 35,
        y: 126,
      },
      {
        x: 36,
        y: 87,
      },
      {
        x: 37,
        y: 222,
      },
      {
        x: 38,
        y: 173,
      },
      {
        x: 39,
        y: 46,
      },
      {
        x: 40,
        y: 113,
      },
      {
        x: 41,
        y: 260,
      },
      {
        x: 42,
        y: 178,
      },
      {
        x: 43,
        y: 213,
      },
      {
        x: 44,
        y: 65,
      },
      {
        x: 45,
        y: 270,
      },
      {
        x: 46,
        y: 41,
      },
      {
        x: 47,
        y: 27,
      },
      {
        x: 48,
        y: 292,
      },
      {
        x: 49,
        y: 191,
      },
      {
        x: 50,
        y: 120,
      },
      {
        x: 51,
        y: 231,
      },
      {
        x: 52,
        y: 287,
      },
      {
        x: 53,
        y: 241,
      },
      {
        x: 54,
        y: 204,
      },
      {
        x: 55,
        y: 111,
      },
      {
        x: 56,
        y: 92,
      },
      {
        x: 57,
        y: 41,
      },
      {
        x: 58,
        y: 113,
      },
      {
        x: 59,
        y: 201,
      },
      {
        x: 60,
        y: 42,
      },
      {
        x: 61,
        y: 269,
      },
      {
        x: 62,
        y: 182,
      },
      {
        x: 63,
        y: 25,
      },
      {
        x: 64,
        y: 210,
      },
      {
        x: 65,
        y: 106,
      },
      {
        x: 66,
        y: 68,
      },
      {
        x: 67,
        y: 184,
      },
      {
        x: 68,
        y: 34,
      },
      {
        x: 69,
        y: 218,
      },
      {
        x: 70,
        y: 19,
      },
      {
        x: 71,
        y: 76,
      },
      {
        x: 72,
        y: 288,
      },
      {
        x: 73,
        y: 285,
      },
      {
        x: 74,
        y: 264,
      },
      {
        x: 75,
        y: 66,
      },
      {
        x: 76,
        y: 62,
      },
      {
        x: 77,
        y: 193,
      },
      {
        x: 78,
        y: 196,
      },
      {
        x: 79,
        y: 256,
      },
      {
        x: 80,
        y: 81,
      },
      {
        x: 81,
        y: 62,
      },
      {
        x: 82,
        y: 35,
      },
      {
        x: 83,
        y: 193,
      },
      {
        x: 84,
        y: 201,
      },
      {
        x: 85,
        y: 191,
      },
      {
        x: 86,
        y: 150,
      },
      {
        x: 87,
        y: 194,
      },
      {
        x: 88,
        y: 147,
      },
      {
        x: 89,
        y: 9,
      },
      {
        x: 90,
        y: 65,
      },
      {
        x: 91,
        y: 180,
      },
      {
        x: 92,
        y: 167,
      },
      {
        x: 93,
        y: 16,
      },
      {
        x: 94,
        y: 258,
      },
      {
        x: 95,
        y: 280,
      },
      {
        x: 96,
        y: 300,
      },
      {
        x: 97,
        y: 162,
      },
      {
        x: 98,
        y: 151,
      },
      {
        x: 99,
        y: 125,
      },
      {
        x: 100,
        y: 151,
      },
      {
        x: 101,
        y: 72,
      },
      {
        x: 102,
        y: 220,
      },
      {
        x: 103,
        y: 134,
      },
      {
        x: 104,
        y: 61,
      },
      {
        x: 105,
        y: 268,
      },
      {
        x: 106,
        y: 288,
      },
      {
        x: 107,
        y: 62,
      },
      {
        x: 108,
        y: 275,
      },
      {
        x: 109,
        y: 240,
      },
      {
        x: 110,
        y: 164,
      },
      {
        x: 111,
        y: 224,
      },
      {
        x: 112,
        y: 78,
      },
      {
        x: 113,
        y: 43,
      },
      {
        x: 114,
        y: 192,
      },
      {
        x: 115,
        y: 147,
      },
      {
        x: 116,
        y: 155,
      },
      {
        x: 117,
        y: 97,
      },
      {
        x: 118,
        y: 154,
      },
      {
        x: 119,
        y: 243,
      },
      {
        x: 120,
        y: 76,
      },
    ],
  },
  {
    id: 'brazil',
    color: 'hsl(196, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 62,
      },
      {
        x: 1,
        y: 227,
      },
      {
        x: 2,
        y: 278,
      },
      {
        x: 3,
        y: 229,
      },
      {
        x: 4,
        y: 31,
      },
      {
        x: 5,
        y: 284,
      },
      {
        x: 6,
        y: 246,
      },
      {
        x: 7,
        y: 5,
      },
      {
        x: 8,
        y: 136,
      },
      {
        x: 9,
        y: 254,
      },
      {
        x: 10,
        y: 117,
      },
      {
        x: 11,
        y: 51,
      },
      {
        x: 12,
        y: 73,
      },
      {
        x: 13,
        y: 97,
      },
      {
        x: 14,
        y: 179,
      },
      {
        x: 15,
        y: 12,
      },
      {
        x: 16,
        y: 205,
      },
      {
        x: 17,
        y: 24,
      },
      {
        x: 18,
        y: 132,
      },
      {
        x: 19,
        y: 259,
      },
      {
        x: 20,
        y: 208,
      },
      {
        x: 21,
        y: 195,
      },
      {
        x: 22,
        y: 12,
      },
      {
        x: 23,
        y: 74,
      },
      {
        x: 24,
        y: 153,
      },
      {
        x: 25,
        y: 296,
      },
      {
        x: 26,
        y: 121,
      },
      {
        x: 27,
        y: 230,
      },
      {
        x: 28,
        y: 21,
      },
      {
        x: 29,
        y: 209,
      },
      {
        x: 30,
        y: 127,
      },
      {
        x: 31,
        y: 174,
      },
      {
        x: 32,
        y: 119,
      },
      {
        x: 33,
        y: 296,
      },
      {
        x: 34,
        y: 213,
      },
      {
        x: 35,
        y: 39,
      },
      {
        x: 36,
        y: 149,
      },
      {
        x: 37,
        y: 255,
      },
      {
        x: 38,
        y: 101,
      },
      {
        x: 39,
        y: 63,
      },
      {
        x: 40,
        y: 255,
      },
      {
        x: 41,
        y: 50,
      },
      {
        x: 42,
        y: 166,
      },
      {
        x: 43,
        y: 282,
      },
      {
        x: 44,
        y: 224,
      },
      {
        x: 45,
        y: 53,
      },
      {
        x: 46,
        y: 94,
      },
      {
        x: 47,
        y: 208,
      },
      {
        x: 48,
        y: 157,
      },
      {
        x: 49,
        y: 29,
      },
      {
        x: 50,
        y: 12,
      },
      {
        x: 51,
        y: 296,
      },
      {
        x: 52,
        y: 285,
      },
      {
        x: 53,
        y: 128,
      },
      {
        x: 54,
        y: 29,
      },
      {
        x: 55,
        y: 158,
      },
      {
        x: 56,
        y: 175,
      },
      {
        x: 57,
        y: 206,
      },
      {
        x: 58,
        y: 173,
      },
      {
        x: 59,
        y: 14,
      },
      {
        x: 60,
        y: 132,
      },
      {
        x: 61,
        y: 111,
      },
      {
        x: 62,
        y: 102,
      },
      {
        x: 63,
        y: 203,
      },
      {
        x: 64,
        y: 173,
      },
      {
        x: 65,
        y: 268,
      },
      {
        x: 66,
        y: 247,
      },
      {
        x: 67,
        y: 56,
      },
      {
        x: 68,
        y: 163,
      },
      {
        x: 69,
        y: 109,
      },
      {
        x: 70,
        y: 153,
      },
      {
        x: 71,
        y: 155,
      },
      {
        x: 72,
        y: 28,
      },
      {
        x: 73,
        y: 34,
      },
      {
        x: 74,
        y: 51,
      },
      {
        x: 75,
        y: 227,
      },
      {
        x: 76,
        y: 69,
      },
      {
        x: 77,
        y: 140,
      },
      {
        x: 78,
        y: 118,
      },
      {
        x: 79,
        y: 182,
      },
      {
        x: 80,
        y: 179,
      },
      {
        x: 81,
        y: 112,
      },
      {
        x: 82,
        y: 213,
      },
      {
        x: 83,
        y: 16,
      },
      {
        x: 84,
        y: 277,
      },
      {
        x: 85,
        y: 64,
      },
      {
        x: 86,
        y: 19,
      },
      {
        x: 87,
        y: 238,
      },
      {
        x: 88,
        y: 278,
      },
      {
        x: 89,
        y: 198,
      },
      {
        x: 90,
        y: 106,
      },
      {
        x: 91,
        y: 181,
      },
      {
        x: 92,
        y: 292,
      },
      {
        x: 93,
        y: 19,
      },
      {
        x: 94,
        y: 99,
      },
      {
        x: 95,
        y: 30,
      },
      {
        x: 96,
        y: 87,
      },
      {
        x: 97,
        y: 212,
      },
      {
        x: 98,
        y: 251,
      },
      {
        x: 99,
        y: 101,
      },
      {
        x: 100,
        y: 64,
      },
      {
        x: 101,
        y: 109,
      },
      {
        x: 102,
        y: 277,
      },
      {
        x: 103,
        y: 135,
      },
      {
        x: 104,
        y: 226,
      },
      {
        x: 105,
        y: 82,
      },
      {
        x: 106,
        y: 191,
      },
      {
        x: 107,
        y: 239,
      },
      {
        x: 108,
        y: 45,
      },
      {
        x: 109,
        y: 11,
      },
      {
        x: 110,
        y: 82,
      },
      {
        x: 111,
        y: 294,
      },
      {
        x: 112,
        y: 191,
      },
      {
        x: 113,
        y: 219,
      },
      {
        x: 114,
        y: 121,
      },
      {
        x: 115,
        y: 253,
      },
      {
        x: 116,
        y: 2,
      },
      {
        x: 117,
        y: 9,
      },
      {
        x: 118,
        y: 68,
      },
      {
        x: 119,
        y: 2,
      },
      {
        x: 120,
        y: 108,
      },
    ],
  },
  {
    id: 'france',
    color: 'hsl(266, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 16,
      },
      {
        x: 1,
        y: 209,
      },
      {
        x: 2,
        y: 139,
      },
      {
        x: 3,
        y: 164,
      },
      {
        x: 4,
        y: 26,
      },
      {
        x: 5,
        y: 255,
      },
      {
        x: 6,
        y: 85,
      },
      {
        x: 7,
        y: 297,
      },
      {
        x: 8,
        y: 108,
      },
      {
        x: 9,
        y: 270,
      },
      {
        x: 10,
        y: 206,
      },
      {
        x: 11,
        y: 212,
      },
      {
        x: 12,
        y: 260,
      },
      {
        x: 13,
        y: 110,
      },
      {
        x: 14,
        y: 174,
      },
      {
        x: 15,
        y: 276,
      },
      {
        x: 16,
        y: 158,
      },
      {
        x: 17,
        y: 207,
      },
      {
        x: 18,
        y: 255,
      },
      {
        x: 19,
        y: 6,
      },
      {
        x: 20,
        y: 183,
      },
      {
        x: 21,
        y: 282,
      },
      {
        x: 22,
        y: 30,
      },
      {
        x: 23,
        y: 13,
      },
      {
        x: 24,
        y: 45,
      },
      {
        x: 25,
        y: 98,
      },
      {
        x: 26,
        y: 62,
      },
      {
        x: 27,
        y: 131,
      },
      {
        x: 28,
        y: 144,
      },
      {
        x: 29,
        y: 267,
      },
      {
        x: 30,
        y: 35,
      },
      {
        x: 31,
        y: 174,
      },
      {
        x: 32,
        y: 145,
      },
      {
        x: 33,
        y: 181,
      },
      {
        x: 34,
        y: 152,
      },
      {
        x: 35,
        y: 241,
      },
      {
        x: 36,
        y: 279,
      },
      {
        x: 37,
        y: 4,
      },
      {
        x: 38,
        y: 249,
      },
      {
        x: 39,
        y: 101,
      },
      {
        x: 40,
        y: 174,
      },
      {
        x: 41,
        y: 153,
      },
      {
        x: 42,
        y: 141,
      },
      {
        x: 43,
        y: 197,
      },
      {
        x: 44,
        y: 77,
      },
      {
        x: 45,
        y: 81,
      },
      {
        x: 46,
        y: 251,
      },
      {
        x: 47,
        y: 106,
      },
      {
        x: 48,
        y: 235,
      },
      {
        x: 49,
        y: 270,
      },
      {
        x: 50,
        y: 19,
      },
      {
        x: 51,
        y: 67,
      },
      {
        x: 52,
        y: 63,
      },
      {
        x: 53,
        y: 62,
      },
      {
        x: 54,
        y: 290,
      },
      {
        x: 55,
        y: 92,
      },
      {
        x: 56,
        y: 199,
      },
      {
        x: 57,
        y: 109,
      },
      {
        x: 58,
        y: 137,
      },
      {
        x: 59,
        y: 251,
      },
      {
        x: 60,
        y: 140,
      },
      {
        x: 61,
        y: 137,
      },
      {
        x: 62,
        y: 179,
      },
      {
        x: 63,
        y: 24,
      },
      {
        x: 64,
        y: 175,
      },
      {
        x: 65,
        y: 142,
      },
      {
        x: 66,
        y: 0,
      },
      {
        x: 67,
        y: 163,
      },
      {
        x: 68,
        y: 138,
      },
      {
        x: 69,
        y: 243,
      },
      {
        x: 70,
        y: 113,
      },
      {
        x: 71,
        y: 47,
      },
      {
        x: 72,
        y: 262,
      },
      {
        x: 73,
        y: 6,
      },
      {
        x: 74,
        y: 112,
      },
      {
        x: 75,
        y: 197,
      },
      {
        x: 76,
        y: 35,
      },
      {
        x: 77,
        y: 142,
      },
      {
        x: 78,
        y: 279,
      },
      {
        x: 79,
        y: 50,
      },
      {
        x: 80,
        y: 286,
      },
      {
        x: 81,
        y: 83,
      },
      {
        x: 82,
        y: 76,
      },
      {
        x: 83,
        y: 83,
      },
      {
        x: 84,
        y: 258,
      },
      {
        x: 85,
        y: 226,
      },
      {
        x: 86,
        y: 77,
      },
      {
        x: 87,
        y: 76,
      },
      {
        x: 88,
        y: 29,
      },
      {
        x: 89,
        y: 135,
      },
      {
        x: 90,
        y: 58,
      },
      {
        x: 91,
        y: 98,
      },
      {
        x: 92,
        y: 268,
      },
      {
        x: 93,
        y: 135,
      },
      {
        x: 94,
        y: 259,
      },
      {
        x: 95,
        y: 21,
      },
      {
        x: 96,
        y: 194,
      },
      {
        x: 97,
        y: 277,
      },
      {
        x: 98,
        y: 241,
      },
      {
        x: 99,
        y: 289,
      },
      {
        x: 100,
        y: 239,
      },
      {
        x: 101,
        y: 119,
      },
      {
        x: 102,
        y: 219,
      },
      {
        x: 103,
        y: 116,
      },
      {
        x: 104,
        y: 112,
      },
      {
        x: 105,
        y: 68,
      },
      {
        x: 106,
        y: 40,
      },
      {
        x: 107,
        y: 20,
      },
      {
        x: 108,
        y: 42,
      },
      {
        x: 109,
        y: 102,
      },
      {
        x: 110,
        y: 56,
      },
      {
        x: 111,
        y: 36,
      },
      {
        x: 112,
        y: 289,
      },
      {
        x: 113,
        y: 271,
      },
      {
        x: 114,
        y: 143,
      },
      {
        x: 115,
        y: 176,
      },
      {
        x: 116,
        y: 90,
      },
      {
        x: 117,
        y: 293,
      },
      {
        x: 118,
        y: 63,
      },
      {
        x: 119,
        y: 235,
      },
      {
        x: 120,
        y: 62,
      },
    ],
  },
  {
    id: 'us',
    color: 'hsl(29, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 43,
      },
      {
        x: 1,
        y: 266,
      },
      {
        x: 2,
        y: 53,
      },
      {
        x: 3,
        y: 1,
      },
      {
        x: 4,
        y: 81,
      },
      {
        x: 5,
        y: 182,
      },
      {
        x: 6,
        y: 294,
      },
      {
        x: 7,
        y: 129,
      },
      {
        x: 8,
        y: 148,
      },
      {
        x: 9,
        y: 208,
      },
      {
        x: 10,
        y: 87,
      },
      {
        x: 11,
        y: 139,
      },
      {
        x: 12,
        y: 179,
      },
      {
        x: 13,
        y: 57,
      },
      {
        x: 14,
        y: 60,
      },
      {
        x: 15,
        y: 178,
      },
      {
        x: 16,
        y: 55,
      },
      {
        x: 17,
        y: 146,
      },
      {
        x: 18,
        y: 45,
      },
      {
        x: 19,
        y: 289,
      },
      {
        x: 20,
        y: 165,
      },
      {
        x: 21,
        y: 146,
      },
      {
        x: 22,
        y: 105,
      },
      {
        x: 23,
        y: 109,
      },
      {
        x: 24,
        y: 182,
      },
      {
        x: 25,
        y: 97,
      },
      {
        x: 26,
        y: 264,
      },
      {
        x: 27,
        y: 50,
      },
      {
        x: 28,
        y: 197,
      },
      {
        x: 29,
        y: 148,
      },
      {
        x: 30,
        y: 107,
      },
      {
        x: 31,
        y: 76,
      },
      {
        x: 32,
        y: 15,
      },
      {
        x: 33,
        y: 288,
      },
      {
        x: 34,
        y: 235,
      },
      {
        x: 35,
        y: 256,
      },
      {
        x: 36,
        y: 172,
      },
      {
        x: 37,
        y: 184,
      },
      {
        x: 38,
        y: 259,
      },
      {
        x: 39,
        y: 279,
      },
      {
        x: 40,
        y: 53,
      },
      {
        x: 41,
        y: 3,
      },
      {
        x: 42,
        y: 244,
      },
      {
        x: 43,
        y: 251,
      },
      {
        x: 44,
        y: 194,
      },
      {
        x: 45,
        y: 245,
      },
      {
        x: 46,
        y: 223,
      },
      {
        x: 47,
        y: 30,
      },
      {
        x: 48,
        y: 250,
      },
      {
        x: 49,
        y: 102,
      },
      {
        x: 50,
        y: 214,
      },
      {
        x: 51,
        y: 258,
      },
      {
        x: 52,
        y: 23,
      },
      {
        x: 53,
        y: 173,
      },
      {
        x: 54,
        y: 149,
      },
      {
        x: 55,
        y: 30,
      },
      {
        x: 56,
        y: 54,
      },
      {
        x: 57,
        y: 180,
      },
      {
        x: 58,
        y: 47,
      },
      {
        x: 59,
        y: 225,
      },
      {
        x: 60,
        y: 167,
      },
      {
        x: 61,
        y: 35,
      },
      {
        x: 62,
        y: 246,
      },
      {
        x: 63,
        y: 270,
      },
      {
        x: 64,
        y: 104,
      },
      {
        x: 65,
        y: 8,
      },
      {
        x: 66,
        y: 195,
      },
      {
        x: 67,
        y: 129,
      },
      {
        x: 68,
        y: 277,
      },
      {
        x: 69,
        y: 250,
      },
      {
        x: 70,
        y: 121,
      },
      {
        x: 71,
        y: 15,
      },
      {
        x: 72,
        y: 190,
      },
      {
        x: 73,
        y: 258,
      },
      {
        x: 74,
        y: 77,
      },
      {
        x: 75,
        y: 199,
      },
      {
        x: 76,
        y: 83,
      },
      {
        x: 77,
        y: 218,
      },
      {
        x: 78,
        y: 19,
      },
      {
        x: 79,
        y: 23,
      },
      {
        x: 80,
        y: 120,
      },
      {
        x: 81,
        y: 38,
      },
      {
        x: 82,
        y: 183,
      },
      {
        x: 83,
        y: 18,
      },
      {
        x: 84,
        y: 118,
      },
      {
        x: 85,
        y: 235,
      },
      {
        x: 86,
        y: 192,
      },
      {
        x: 87,
        y: 64,
      },
      {
        x: 88,
        y: 248,
      },
      {
        x: 89,
        y: 293,
      },
      {
        x: 90,
        y: 105,
      },
      {
        x: 91,
        y: 191,
      },
      {
        x: 92,
        y: 137,
      },
      {
        x: 93,
        y: 42,
      },
      {
        x: 94,
        y: 164,
      },
      {
        x: 95,
        y: 217,
      },
      {
        x: 96,
        y: 80,
      },
      {
        x: 97,
        y: 34,
      },
      {
        x: 98,
        y: 55,
      },
      {
        x: 99,
        y: 60,
      },
      {
        x: 100,
        y: 285,
      },
      {
        x: 101,
        y: 34,
      },
      {
        x: 102,
        y: 98,
      },
      {
        x: 103,
        y: 266,
      },
      {
        x: 104,
        y: 11,
      },
      {
        x: 105,
        y: 111,
      },
      {
        x: 106,
        y: 116,
      },
      {
        x: 107,
        y: 45,
      },
      {
        x: 108,
        y: 62,
      },
      {
        x: 109,
        y: 192,
      },
      {
        x: 110,
        y: 32,
      },
      {
        x: 111,
        y: 8,
      },
      {
        x: 112,
        y: 150,
      },
      {
        x: 113,
        y: 159,
      },
      {
        x: 114,
        y: 76,
      },
      {
        x: 115,
        y: 89,
      },
      {
        x: 116,
        y: 153,
      },
      {
        x: 117,
        y: 171,
      },
      {
        x: 118,
        y: 24,
      },
      {
        x: 119,
        y: 107,
      },
      {
        x: 120,
        y: 84,
      },
    ],
  },
  {
    id: 'germany',
    color: 'hsl(115, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 259,
      },
      {
        x: 1,
        y: 300,
      },
      {
        x: 2,
        y: 185,
      },
      {
        x: 3,
        y: 236,
      },
      {
        x: 4,
        y: 120,
      },
      {
        x: 5,
        y: 172,
      },
      {
        x: 6,
        y: 109,
      },
      {
        x: 7,
        y: 26,
      },
      {
        x: 8,
        y: 137,
      },
      {
        x: 9,
        y: 72,
      },
      {
        x: 10,
        y: 7,
      },
      {
        x: 11,
        y: 11,
      },
      {
        x: 12,
        y: 133,
      },
      {
        x: 13,
        y: 226,
      },
      {
        x: 14,
        y: 23,
      },
      {
        x: 15,
        y: 286,
      },
      {
        x: 16,
        y: 236,
      },
      {
        x: 17,
        y: 59,
      },
      {
        x: 18,
        y: 214,
      },
      {
        x: 19,
        y: 283,
      },
      {
        x: 20,
        y: 148,
      },
      {
        x: 21,
        y: 10,
      },
      {
        x: 22,
        y: 119,
      },
      {
        x: 23,
        y: 182,
      },
      {
        x: 24,
        y: 64,
      },
      {
        x: 25,
        y: 110,
      },
      {
        x: 26,
        y: 264,
      },
      {
        x: 27,
        y: 142,
      },
      {
        x: 28,
        y: 22,
      },
      {
        x: 29,
        y: 288,
      },
      {
        x: 30,
        y: 62,
      },
      {
        x: 31,
        y: 211,
      },
      {
        x: 32,
        y: 194,
      },
      {
        x: 33,
        y: 86,
      },
      {
        x: 34,
        y: 13,
      },
      {
        x: 35,
        y: 71,
      },
      {
        x: 36,
        y: 92,
      },
      {
        x: 37,
        y: 9,
      },
      {
        x: 38,
        y: 219,
      },
      {
        x: 39,
        y: 3,
      },
      {
        x: 40,
        y: 164,
      },
      {
        x: 41,
        y: 67,
      },
      {
        x: 42,
        y: 46,
      },
      {
        x: 43,
        y: 32,
      },
      {
        x: 44,
        y: 108,
      },
      {
        x: 45,
        y: 125,
      },
      {
        x: 46,
        y: 69,
      },
      {
        x: 47,
        y: 299,
      },
      {
        x: 48,
        y: 295,
      },
      {
        x: 49,
        y: 148,
      },
      {
        x: 50,
        y: 142,
      },
      {
        x: 51,
        y: 187,
      },
      {
        x: 52,
        y: 196,
      },
      {
        x: 53,
        y: 94,
      },
      {
        x: 54,
        y: 195,
      },
      {
        x: 55,
        y: 252,
      },
      {
        x: 56,
        y: 241,
      },
      {
        x: 57,
        y: 220,
      },
      {
        x: 58,
        y: 256,
      },
      {
        x: 59,
        y: 44,
      },
      {
        x: 60,
        y: 26,
      },
      {
        x: 61,
        y: 166,
      },
      {
        x: 62,
        y: 269,
      },
      {
        x: 63,
        y: 105,
      },
      {
        x: 64,
        y: 108,
      },
      {
        x: 65,
        y: 72,
      },
      {
        x: 66,
        y: 285,
      },
      {
        x: 67,
        y: 171,
      },
      {
        x: 68,
        y: 160,
      },
      {
        x: 69,
        y: 107,
      },
      {
        x: 70,
        y: 78,
      },
      {
        x: 71,
        y: 0,
      },
      {
        x: 72,
        y: 53,
      },
      {
        x: 73,
        y: 75,
      },
      {
        x: 74,
        y: 34,
      },
      {
        x: 75,
        y: 272,
      },
      {
        x: 76,
        y: 283,
      },
      {
        x: 77,
        y: 100,
      },
      {
        x: 78,
        y: 138,
      },
      {
        x: 79,
        y: 115,
      },
      {
        x: 80,
        y: 44,
      },
      {
        x: 81,
        y: 142,
      },
      {
        x: 82,
        y: 242,
      },
      {
        x: 83,
        y: 17,
      },
      {
        x: 84,
        y: 253,
      },
      {
        x: 85,
        y: 26,
      },
      {
        x: 86,
        y: 269,
      },
      {
        x: 87,
        y: 76,
      },
      {
        x: 88,
        y: 163,
      },
      {
        x: 89,
        y: 155,
      },
      {
        x: 90,
        y: 28,
      },
      {
        x: 91,
        y: 60,
      },
      {
        x: 92,
        y: 152,
      },
      {
        x: 93,
        y: 171,
      },
      {
        x: 94,
        y: 182,
      },
      {
        x: 95,
        y: 193,
      },
      {
        x: 96,
        y: 178,
      },
      {
        x: 97,
        y: 83,
      },
      {
        x: 98,
        y: 70,
      },
      {
        x: 99,
        y: 116,
      },
      {
        x: 100,
        y: 98,
      },
      {
        x: 101,
        y: 75,
      },
      {
        x: 102,
        y: 39,
      },
      {
        x: 103,
        y: 209,
      },
      {
        x: 104,
        y: 149,
      },
      {
        x: 105,
        y: 57,
      },
      {
        x: 106,
        y: 280,
      },
      {
        x: 107,
        y: 102,
      },
      {
        x: 108,
        y: 8,
      },
      {
        x: 109,
        y: 15,
      },
      {
        x: 110,
        y: 83,
      },
      {
        x: 111,
        y: 263,
      },
      {
        x: 112,
        y: 261,
      },
      {
        x: 113,
        y: 197,
      },
      {
        x: 114,
        y: 79,
      },
      {
        x: 115,
        y: 118,
      },
      {
        x: 116,
        y: 247,
      },
      {
        x: 117,
        y: 254,
      },
      {
        x: 118,
        y: 110,
      },
      {
        x: 119,
        y: 152,
      },
      {
        x: 120,
        y: 2,
      },
    ],
  },
  {
    id: 'norway',
    color: 'hsl(29, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 63,
      },
      {
        x: 1,
        y: 18,
      },
      {
        x: 2,
        y: 76,
      },
      {
        x: 3,
        y: 257,
      },
      {
        x: 4,
        y: 72,
      },
      {
        x: 5,
        y: 223,
      },
      {
        x: 6,
        y: 271,
      },
      {
        x: 7,
        y: 222,
      },
      {
        x: 8,
        y: 216,
      },
      {
        x: 9,
        y: 79,
      },
      {
        x: 10,
        y: 68,
      },
      {
        x: 11,
        y: 94,
      },
      {
        x: 12,
        y: 104,
      },
      {
        x: 13,
        y: 120,
      },
      {
        x: 14,
        y: 227,
      },
      {
        x: 15,
        y: 24,
      },
      {
        x: 16,
        y: 276,
      },
      {
        x: 17,
        y: 100,
      },
      {
        x: 18,
        y: 170,
      },
      {
        x: 19,
        y: 233,
      },
      {
        x: 20,
        y: 229,
      },
      {
        x: 21,
        y: 82,
      },
      {
        x: 22,
        y: 141,
      },
      {
        x: 23,
        y: 223,
      },
      {
        x: 24,
        y: 234,
      },
      {
        x: 25,
        y: 263,
      },
      {
        x: 26,
        y: 190,
      },
      {
        x: 27,
        y: 238,
      },
      {
        x: 28,
        y: 119,
      },
      {
        x: 29,
        y: 170,
      },
      {
        x: 30,
        y: 289,
      },
      {
        x: 31,
        y: 173,
      },
      {
        x: 32,
        y: 266,
      },
      {
        x: 33,
        y: 288,
      },
      {
        x: 34,
        y: 278,
      },
      {
        x: 35,
        y: 27,
      },
      {
        x: 36,
        y: 107,
      },
      {
        x: 37,
        y: 70,
      },
      {
        x: 38,
        y: 10,
      },
      {
        x: 39,
        y: 64,
      },
      {
        x: 40,
        y: 51,
      },
      {
        x: 41,
        y: 290,
      },
      {
        x: 42,
        y: 244,
      },
      {
        x: 43,
        y: 210,
      },
      {
        x: 44,
        y: 144,
      },
      {
        x: 45,
        y: 208,
      },
      {
        x: 46,
        y: 277,
      },
      {
        x: 47,
        y: 291,
      },
      {
        x: 48,
        y: 28,
      },
      {
        x: 49,
        y: 134,
      },
      {
        x: 50,
        y: 137,
      },
      {
        x: 51,
        y: 87,
      },
      {
        x: 52,
        y: 111,
      },
      {
        x: 53,
        y: 207,
      },
      {
        x: 54,
        y: 256,
      },
      {
        x: 55,
        y: 73,
      },
      {
        x: 56,
        y: 268,
      },
      {
        x: 57,
        y: 6,
      },
      {
        x: 58,
        y: 41,
      },
      {
        x: 59,
        y: 257,
      },
      {
        x: 60,
        y: 123,
      },
      {
        x: 61,
        y: 192,
      },
      {
        x: 62,
        y: 196,
      },
      {
        x: 63,
        y: 168,
      },
      {
        x: 64,
        y: 21,
      },
      {
        x: 65,
        y: 282,
      },
      {
        x: 66,
        y: 95,
      },
      {
        x: 67,
        y: 169,
      },
      {
        x: 68,
        y: 93,
      },
      {
        x: 69,
        y: 277,
      },
      {
        x: 70,
        y: 123,
      },
      {
        x: 71,
        y: 50,
      },
      {
        x: 72,
        y: 230,
      },
      {
        x: 73,
        y: 259,
      },
      {
        x: 74,
        y: 149,
      },
      {
        x: 75,
        y: 82,
      },
      {
        x: 76,
        y: 117,
      },
      {
        x: 77,
        y: 170,
      },
      {
        x: 78,
        y: 65,
      },
      {
        x: 79,
        y: 58,
      },
      {
        x: 80,
        y: 244,
      },
      {
        x: 81,
        y: 89,
      },
      {
        x: 82,
        y: 268,
      },
      {
        x: 83,
        y: 52,
      },
      {
        x: 84,
        y: 44,
      },
      {
        x: 85,
        y: 284,
      },
      {
        x: 86,
        y: 186,
      },
      {
        x: 87,
        y: 105,
      },
      {
        x: 88,
        y: 10,
      },
      {
        x: 89,
        y: 70,
      },
      {
        x: 90,
        y: 151,
      },
      {
        x: 91,
        y: 289,
      },
      {
        x: 92,
        y: 94,
      },
      {
        x: 93,
        y: 112,
      },
      {
        x: 94,
        y: 17,
      },
      {
        x: 95,
        y: 54,
      },
      {
        x: 96,
        y: 123,
      },
      {
        x: 97,
        y: 83,
      },
      {
        x: 98,
        y: 257,
      },
      {
        x: 99,
        y: 221,
      },
      {
        x: 100,
        y: 141,
      },
      {
        x: 101,
        y: 237,
      },
      {
        x: 102,
        y: 45,
      },
      {
        x: 103,
        y: 125,
      },
      {
        x: 104,
        y: 170,
      },
      {
        x: 105,
        y: 262,
      },
      {
        x: 106,
        y: 174,
      },
      {
        x: 107,
        y: 245,
      },
      {
        x: 108,
        y: 279,
      },
      {
        x: 109,
        y: 15,
      },
      {
        x: 110,
        y: 273,
      },
      {
        x: 111,
        y: 224,
      },
      {
        x: 112,
        y: 169,
      },
      {
        x: 113,
        y: 179,
      },
      {
        x: 114,
        y: 124,
      },
      {
        x: 115,
        y: 218,
      },
      {
        x: 116,
        y: 114,
      },
      {
        x: 117,
        y: 132,
      },
      {
        x: 118,
        y: 119,
      },
      {
        x: 119,
        y: 9,
      },
      {
        x: 120,
        y: 270,
      },
    ],
  },
  {
    id: 'china',
    color: 'hsl(18, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 75,
      },
      {
        x: 1,
        y: 48,
      },
      {
        x: 2,
        y: 187,
      },
      {
        x: 3,
        y: 117,
      },
      {
        x: 4,
        y: 231,
      },
      {
        x: 5,
        y: 75,
      },
      {
        x: 6,
        y: 66,
      },
      {
        x: 7,
        y: 131,
      },
      {
        x: 8,
        y: 132,
      },
      {
        x: 9,
        y: 208,
      },
      {
        x: 10,
        y: 222,
      },
      {
        x: 11,
        y: 100,
      },
      {
        x: 12,
        y: 282,
      },
      {
        x: 13,
        y: 29,
      },
      {
        x: 14,
        y: 25,
      },
      {
        x: 15,
        y: 214,
      },
      {
        x: 16,
        y: 9,
      },
      {
        x: 17,
        y: 179,
      },
      {
        x: 18,
        y: 59,
      },
      {
        x: 19,
        y: 217,
      },
      {
        x: 20,
        y: 5,
      },
      {
        x: 21,
        y: 152,
      },
      {
        x: 22,
        y: 123,
      },
      {
        x: 23,
        y: 35,
      },
      {
        x: 24,
        y: 54,
      },
      {
        x: 25,
        y: 31,
      },
      {
        x: 26,
        y: 36,
      },
      {
        x: 27,
        y: 271,
      },
      {
        x: 28,
        y: 154,
      },
      {
        x: 29,
        y: 46,
      },
      {
        x: 30,
        y: 31,
      },
      {
        x: 31,
        y: 231,
      },
      {
        x: 32,
        y: 246,
      },
      {
        x: 33,
        y: 297,
      },
      {
        x: 34,
        y: 137,
      },
      {
        x: 35,
        y: 141,
      },
      {
        x: 36,
        y: 298,
      },
      {
        x: 37,
        y: 141,
      },
      {
        x: 38,
        y: 108,
      },
      {
        x: 39,
        y: 176,
      },
      {
        x: 40,
        y: 38,
      },
      {
        x: 41,
        y: 212,
      },
      {
        x: 42,
        y: 176,
      },
      {
        x: 43,
        y: 133,
      },
      {
        x: 44,
        y: 47,
      },
      {
        x: 45,
        y: 283,
      },
      {
        x: 46,
        y: 91,
      },
      {
        x: 47,
        y: 32,
      },
      {
        x: 48,
        y: 66,
      },
      {
        x: 49,
        y: 261,
      },
      {
        x: 50,
        y: 247,
      },
      {
        x: 51,
        y: 234,
      },
      {
        x: 52,
        y: 88,
      },
      {
        x: 53,
        y: 11,
      },
      {
        x: 54,
        y: 25,
      },
      {
        x: 55,
        y: 90,
      },
      {
        x: 56,
        y: 107,
      },
      {
        x: 57,
        y: 209,
      },
      {
        x: 58,
        y: 174,
      },
      {
        x: 59,
        y: 193,
      },
      {
        x: 60,
        y: 184,
      },
      {
        x: 61,
        y: 67,
      },
      {
        x: 62,
        y: 30,
      },
      {
        x: 63,
        y: 201,
      },
      {
        x: 64,
        y: 235,
      },
      {
        x: 65,
        y: 270,
      },
      {
        x: 66,
        y: 191,
      },
      {
        x: 67,
        y: 98,
      },
      {
        x: 68,
        y: 72,
      },
      {
        x: 69,
        y: 138,
      },
      {
        x: 70,
        y: 193,
      },
      {
        x: 71,
        y: 150,
      },
      {
        x: 72,
        y: 203,
      },
      {
        x: 73,
        y: 13,
      },
      {
        x: 74,
        y: 80,
      },
      {
        x: 75,
        y: 270,
      },
      {
        x: 76,
        y: 48,
      },
      {
        x: 77,
        y: 29,
      },
      {
        x: 78,
        y: 2,
      },
      {
        x: 79,
        y: 299,
      },
      {
        x: 80,
        y: 31,
      },
      {
        x: 81,
        y: 117,
      },
      {
        x: 82,
        y: 234,
      },
      {
        x: 83,
        y: 40,
      },
      {
        x: 84,
        y: 296,
      },
      {
        x: 85,
        y: 176,
      },
      {
        x: 86,
        y: 140,
      },
      {
        x: 87,
        y: 146,
      },
      {
        x: 88,
        y: 126,
      },
      {
        x: 89,
        y: 117,
      },
      {
        x: 90,
        y: 111,
      },
      {
        x: 91,
        y: 19,
      },
      {
        x: 92,
        y: 247,
      },
      {
        x: 93,
        y: 56,
      },
      {
        x: 94,
        y: 240,
      },
      {
        x: 95,
        y: 129,
      },
      {
        x: 96,
        y: 222,
      },
      {
        x: 97,
        y: 144,
      },
      {
        x: 98,
        y: 74,
      },
      {
        x: 99,
        y: 82,
      },
      {
        x: 100,
        y: 55,
      },
      {
        x: 101,
        y: 266,
      },
      {
        x: 102,
        y: 21,
      },
      {
        x: 103,
        y: 95,
      },
      {
        x: 104,
        y: 200,
      },
      {
        x: 105,
        y: 295,
      },
      {
        x: 106,
        y: 23,
      },
      {
        x: 107,
        y: 92,
      },
      {
        x: 108,
        y: 291,
      },
      {
        x: 109,
        y: 120,
      },
      {
        x: 110,
        y: 251,
      },
      {
        x: 111,
        y: 0,
      },
      {
        x: 112,
        y: 9,
      },
      {
        x: 113,
        y: 246,
      },
      {
        x: 114,
        y: 136,
      },
      {
        x: 115,
        y: 155,
      },
      {
        x: 116,
        y: 89,
      },
      {
        x: 117,
        y: 299,
      },
      {
        x: 118,
        y: 111,
      },
      {
        x: 119,
        y: 54,
      },
      {
        x: 120,
        y: 109,
      },
    ],
  },
  {
    id: 'algeria',
    color: 'hsl(305, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 3,
      },
      {
        x: 1,
        y: 141,
      },
      {
        x: 2,
        y: 94,
      },
      {
        x: 3,
        y: 189,
      },
      {
        x: 4,
        y: 270,
      },
      {
        x: 5,
        y: 18,
      },
      {
        x: 6,
        y: 178,
      },
      {
        x: 7,
        y: 81,
      },
      {
        x: 8,
        y: 43,
      },
      {
        x: 9,
        y: 214,
      },
      {
        x: 10,
        y: 173,
      },
      {
        x: 11,
        y: 234,
      },
      {
        x: 12,
        y: 140,
      },
      {
        x: 13,
        y: 251,
      },
      {
        x: 14,
        y: 49,
      },
      {
        x: 15,
        y: 184,
      },
      {
        x: 16,
        y: 252,
      },
      {
        x: 17,
        y: 253,
      },
      {
        x: 18,
        y: 298,
      },
      {
        x: 19,
        y: 225,
      },
      {
        x: 20,
        y: 84,
      },
      {
        x: 21,
        y: 121,
      },
      {
        x: 22,
        y: 174,
      },
      {
        x: 23,
        y: 44,
      },
      {
        x: 24,
        y: 187,
      },
      {
        x: 25,
        y: 166,
      },
      {
        x: 26,
        y: 50,
      },
      {
        x: 27,
        y: 90,
      },
      {
        x: 28,
        y: 112,
      },
      {
        x: 29,
        y: 250,
      },
      {
        x: 30,
        y: 95,
      },
      {
        x: 31,
        y: 2,
      },
      {
        x: 32,
        y: 7,
      },
      {
        x: 33,
        y: 216,
      },
      {
        x: 34,
        y: 119,
      },
      {
        x: 35,
        y: 165,
      },
      {
        x: 36,
        y: 145,
      },
      {
        x: 37,
        y: 208,
      },
      {
        x: 38,
        y: 194,
      },
      {
        x: 39,
        y: 70,
      },
      {
        x: 40,
        y: 62,
      },
      {
        x: 41,
        y: 249,
      },
      {
        x: 42,
        y: 160,
      },
      {
        x: 43,
        y: 152,
      },
      {
        x: 44,
        y: 258,
      },
      {
        x: 45,
        y: 292,
      },
      {
        x: 46,
        y: 109,
      },
      {
        x: 47,
        y: 24,
      },
      {
        x: 48,
        y: 274,
      },
      {
        x: 49,
        y: 132,
      },
      {
        x: 50,
        y: 299,
      },
      {
        x: 51,
        y: 253,
      },
      {
        x: 52,
        y: 190,
      },
      {
        x: 53,
        y: 19,
      },
      {
        x: 54,
        y: 135,
      },
      {
        x: 55,
        y: 269,
      },
      {
        x: 56,
        y: 153,
      },
      {
        x: 57,
        y: 116,
      },
      {
        x: 58,
        y: 220,
      },
      {
        x: 59,
        y: 186,
      },
      {
        x: 60,
        y: 273,
      },
      {
        x: 61,
        y: 159,
      },
      {
        x: 62,
        y: 80,
      },
      {
        x: 63,
        y: 172,
      },
      {
        x: 64,
        y: 207,
      },
      {
        x: 65,
        y: 247,
      },
      {
        x: 66,
        y: 6,
      },
      {
        x: 67,
        y: 231,
      },
      {
        x: 68,
        y: 210,
      },
      {
        x: 69,
        y: 71,
      },
      {
        x: 70,
        y: 121,
      },
      {
        x: 71,
        y: 138,
      },
      {
        x: 72,
        y: 123,
      },
      {
        x: 73,
        y: 259,
      },
      {
        x: 74,
        y: 187,
      },
      {
        x: 75,
        y: 129,
      },
      {
        x: 76,
        y: 42,
      },
      {
        x: 77,
        y: 108,
      },
      {
        x: 78,
        y: 70,
      },
      {
        x: 79,
        y: 245,
      },
      {
        x: 80,
        y: 246,
      },
      {
        x: 81,
        y: 232,
      },
      {
        x: 82,
        y: 71,
      },
      {
        x: 83,
        y: 149,
      },
      {
        x: 84,
        y: 159,
      },
      {
        x: 85,
        y: 55,
      },
      {
        x: 86,
        y: 237,
      },
      {
        x: 87,
        y: 23,
      },
      {
        x: 88,
        y: 209,
      },
      {
        x: 89,
        y: 44,
      },
      {
        x: 90,
        y: 143,
      },
      {
        x: 91,
        y: 131,
      },
      {
        x: 92,
        y: 145,
      },
      {
        x: 93,
        y: 154,
      },
      {
        x: 94,
        y: 18,
      },
      {
        x: 95,
        y: 205,
      },
      {
        x: 96,
        y: 58,
      },
      {
        x: 97,
        y: 155,
      },
      {
        x: 98,
        y: 276,
      },
      {
        x: 99,
        y: 212,
      },
      {
        x: 100,
        y: 28,
      },
      {
        x: 101,
        y: 95,
      },
      {
        x: 102,
        y: 247,
      },
      {
        x: 103,
        y: 210,
      },
      {
        x: 104,
        y: 247,
      },
      {
        x: 105,
        y: 202,
      },
      {
        x: 106,
        y: 70,
      },
      {
        x: 107,
        y: 97,
      },
      {
        x: 108,
        y: 71,
      },
      {
        x: 109,
        y: 88,
      },
      {
        x: 110,
        y: 34,
      },
      {
        x: 111,
        y: 220,
      },
      {
        x: 112,
        y: 101,
      },
      {
        x: 113,
        y: 271,
      },
      {
        x: 114,
        y: 121,
      },
      {
        x: 115,
        y: 125,
      },
      {
        x: 116,
        y: 174,
      },
      {
        x: 117,
        y: 244,
      },
      {
        x: 118,
        y: 277,
      },
      {
        x: 119,
        y: 75,
      },
      {
        x: 120,
        y: 78,
      },
    ],
  },
  {
    id: 'mexico',
    color: 'hsl(192, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 140,
      },
      {
        x: 1,
        y: 287,
      },
      {
        x: 2,
        y: 203,
      },
      {
        x: 3,
        y: 231,
      },
      {
        x: 4,
        y: 223,
      },
      {
        x: 5,
        y: 69,
      },
      {
        x: 6,
        y: 35,
      },
      {
        x: 7,
        y: 7,
      },
      {
        x: 8,
        y: 68,
      },
      {
        x: 9,
        y: 142,
      },
      {
        x: 10,
        y: 227,
      },
      {
        x: 11,
        y: 288,
      },
      {
        x: 12,
        y: 139,
      },
      {
        x: 13,
        y: 137,
      },
      {
        x: 14,
        y: 19,
      },
      {
        x: 15,
        y: 50,
      },
      {
        x: 16,
        y: 155,
      },
      {
        x: 17,
        y: 101,
      },
      {
        x: 18,
        y: 92,
      },
      {
        x: 19,
        y: 16,
      },
      {
        x: 20,
        y: 61,
      },
      {
        x: 21,
        y: 257,
      },
      {
        x: 22,
        y: 94,
      },
      {
        x: 23,
        y: 195,
      },
      {
        x: 24,
        y: 140,
      },
      {
        x: 25,
        y: 44,
      },
      {
        x: 26,
        y: 151,
      },
      {
        x: 27,
        y: 176,
      },
      {
        x: 28,
        y: 112,
      },
      {
        x: 29,
        y: 99,
      },
      {
        x: 30,
        y: 44,
      },
      {
        x: 31,
        y: 252,
      },
      {
        x: 32,
        y: 25,
      },
      {
        x: 33,
        y: 260,
      },
      {
        x: 34,
        y: 112,
      },
      {
        x: 35,
        y: 120,
      },
      {
        x: 36,
        y: 49,
      },
      {
        x: 37,
        y: 197,
      },
      {
        x: 38,
        y: 47,
      },
      {
        x: 39,
        y: 20,
      },
      {
        x: 40,
        y: 147,
      },
      {
        x: 41,
        y: 205,
      },
      {
        x: 42,
        y: 210,
      },
      {
        x: 43,
        y: 56,
      },
      {
        x: 44,
        y: 198,
      },
      {
        x: 45,
        y: 127,
      },
      {
        x: 46,
        y: 249,
      },
      {
        x: 47,
        y: 158,
      },
      {
        x: 48,
        y: 150,
      },
      {
        x: 49,
        y: 14,
      },
      {
        x: 50,
        y: 280,
      },
      {
        x: 51,
        y: 169,
      },
      {
        x: 52,
        y: 214,
      },
      {
        x: 53,
        y: 256,
      },
      {
        x: 54,
        y: 290,
      },
      {
        x: 55,
        y: 161,
      },
      {
        x: 56,
        y: 211,
      },
      {
        x: 57,
        y: 145,
      },
      {
        x: 58,
        y: 170,
      },
      {
        x: 59,
        y: 93,
      },
      {
        x: 60,
        y: 268,
      },
      {
        x: 61,
        y: 275,
      },
      {
        x: 62,
        y: 137,
      },
      {
        x: 63,
        y: 67,
      },
      {
        x: 64,
        y: 134,
      },
      {
        x: 65,
        y: 286,
      },
      {
        x: 66,
        y: 255,
      },
      {
        x: 67,
        y: 235,
      },
      {
        x: 68,
        y: 134,
      },
      {
        x: 69,
        y: 280,
      },
      {
        x: 70,
        y: 177,
      },
      {
        x: 71,
        y: 258,
      },
      {
        x: 72,
        y: 252,
      },
      {
        x: 73,
        y: 124,
      },
      {
        x: 74,
        y: 45,
      },
      {
        x: 75,
        y: 159,
      },
      {
        x: 76,
        y: 228,
      },
      {
        x: 77,
        y: 119,
      },
      {
        x: 78,
        y: 80,
      },
      {
        x: 79,
        y: 219,
      },
      {
        x: 80,
        y: 162,
      },
      {
        x: 81,
        y: 230,
      },
      {
        x: 82,
        y: 176,
      },
      {
        x: 83,
        y: 8,
      },
      {
        x: 84,
        y: 255,
      },
      {
        x: 85,
        y: 29,
      },
      {
        x: 86,
        y: 93,
      },
      {
        x: 87,
        y: 167,
      },
      {
        x: 88,
        y: 143,
      },
      {
        x: 89,
        y: 254,
      },
      {
        x: 90,
        y: 225,
      },
      {
        x: 91,
        y: 232,
      },
      {
        x: 92,
        y: 159,
      },
      {
        x: 93,
        y: 166,
      },
      {
        x: 94,
        y: 67,
      },
      {
        x: 95,
        y: 182,
      },
      {
        x: 96,
        y: 185,
      },
      {
        x: 97,
        y: 47,
      },
      {
        x: 98,
        y: 68,
      },
      {
        x: 99,
        y: 107,
      },
      {
        x: 100,
        y: 23,
      },
      {
        x: 101,
        y: 8,
      },
      {
        x: 102,
        y: 235,
      },
      {
        x: 103,
        y: 87,
      },
      {
        x: 104,
        y: 139,
      },
      {
        x: 105,
        y: 132,
      },
      {
        x: 106,
        y: 1,
      },
      {
        x: 107,
        y: 174,
      },
      {
        x: 108,
        y: 261,
      },
      {
        x: 109,
        y: 47,
      },
      {
        x: 110,
        y: 18,
      },
      {
        x: 111,
        y: 30,
      },
      {
        x: 112,
        y: 225,
      },
      {
        x: 113,
        y: 192,
      },
      {
        x: 114,
        y: 224,
      },
      {
        x: 115,
        y: 17,
      },
      {
        x: 116,
        y: 187,
      },
      {
        x: 117,
        y: 97,
      },
      {
        x: 118,
        y: 130,
      },
      {
        x: 119,
        y: 227,
      },
      {
        x: 120,
        y: 143,
      },
    ],
  },
  {
    id: 'finland',
    color: 'hsl(246, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 114,
      },
      {
        x: 1,
        y: 65,
      },
      {
        x: 2,
        y: 140,
      },
      {
        x: 3,
        y: 115,
      },
      {
        x: 4,
        y: 166,
      },
      {
        x: 5,
        y: 288,
      },
      {
        x: 6,
        y: 135,
      },
      {
        x: 7,
        y: 236,
      },
      {
        x: 8,
        y: 253,
      },
      {
        x: 9,
        y: 125,
      },
      {
        x: 10,
        y: 107,
      },
      {
        x: 11,
        y: 218,
      },
      {
        x: 12,
        y: 140,
      },
      {
        x: 13,
        y: 22,
      },
      {
        x: 14,
        y: 247,
      },
      {
        x: 15,
        y: 53,
      },
      {
        x: 16,
        y: 39,
      },
      {
        x: 17,
        y: 8,
      },
      {
        x: 18,
        y: 210,
      },
      {
        x: 19,
        y: 44,
      },
      {
        x: 20,
        y: 55,
      },
      {
        x: 21,
        y: 3,
      },
      {
        x: 22,
        y: 15,
      },
      {
        x: 23,
        y: 196,
      },
      {
        x: 24,
        y: 148,
      },
      {
        x: 25,
        y: 193,
      },
      {
        x: 26,
        y: 149,
      },
      {
        x: 27,
        y: 188,
      },
      {
        x: 28,
        y: 174,
      },
      {
        x: 29,
        y: 266,
      },
      {
        x: 30,
        y: 88,
      },
      {
        x: 31,
        y: 212,
      },
      {
        x: 32,
        y: 61,
      },
      {
        x: 33,
        y: 4,
      },
      {
        x: 34,
        y: 42,
      },
      {
        x: 35,
        y: 209,
      },
      {
        x: 36,
        y: 229,
      },
      {
        x: 37,
        y: 248,
      },
      {
        x: 38,
        y: 247,
      },
      {
        x: 39,
        y: 244,
      },
      {
        x: 40,
        y: 9,
      },
      {
        x: 41,
        y: 200,
      },
      {
        x: 42,
        y: 207,
      },
      {
        x: 43,
        y: 235,
      },
      {
        x: 44,
        y: 197,
      },
      {
        x: 45,
        y: 47,
      },
      {
        x: 46,
        y: 166,
      },
      {
        x: 47,
        y: 118,
      },
      {
        x: 48,
        y: 176,
      },
      {
        x: 49,
        y: 266,
      },
      {
        x: 50,
        y: 236,
      },
      {
        x: 51,
        y: 75,
      },
      {
        x: 52,
        y: 202,
      },
      {
        x: 53,
        y: 252,
      },
      {
        x: 54,
        y: 91,
      },
      {
        x: 55,
        y: 184,
      },
      {
        x: 56,
        y: 261,
      },
      {
        x: 57,
        y: 192,
      },
      {
        x: 58,
        y: 85,
      },
      {
        x: 59,
        y: 76,
      },
      {
        x: 60,
        y: 128,
      },
      {
        x: 61,
        y: 4,
      },
      {
        x: 62,
        y: 115,
      },
      {
        x: 63,
        y: 213,
      },
      {
        x: 64,
        y: 7,
      },
      {
        x: 65,
        y: 134,
      },
      {
        x: 66,
        y: 179,
      },
      {
        x: 67,
        y: 187,
      },
      {
        x: 68,
        y: 46,
      },
      {
        x: 69,
        y: 27,
      },
      {
        x: 70,
        y: 83,
      },
      {
        x: 71,
        y: 117,
      },
      {
        x: 72,
        y: 280,
      },
      {
        x: 73,
        y: 123,
      },
      {
        x: 74,
        y: 126,
      },
      {
        x: 75,
        y: 99,
      },
      {
        x: 76,
        y: 215,
      },
      {
        x: 77,
        y: 119,
      },
      {
        x: 78,
        y: 249,
      },
      {
        x: 79,
        y: 176,
      },
      {
        x: 80,
        y: 24,
      },
      {
        x: 81,
        y: 36,
      },
      {
        x: 82,
        y: 14,
      },
      {
        x: 83,
        y: 270,
      },
      {
        x: 84,
        y: 166,
      },
      {
        x: 85,
        y: 235,
      },
      {
        x: 86,
        y: 160,
      },
      {
        x: 87,
        y: 121,
      },
      {
        x: 88,
        y: 28,
      },
      {
        x: 89,
        y: 15,
      },
      {
        x: 90,
        y: 14,
      },
      {
        x: 91,
        y: 90,
      },
      {
        x: 92,
        y: 271,
      },
      {
        x: 93,
        y: 269,
      },
      {
        x: 94,
        y: 237,
      },
      {
        x: 95,
        y: 82,
      },
      {
        x: 96,
        y: 259,
      },
      {
        x: 97,
        y: 229,
      },
      {
        x: 98,
        y: 239,
      },
      {
        x: 99,
        y: 113,
      },
      {
        x: 100,
        y: 295,
      },
      {
        x: 101,
        y: 274,
      },
      {
        x: 102,
        y: 254,
      },
      {
        x: 103,
        y: 123,
      },
      {
        x: 104,
        y: 191,
      },
      {
        x: 105,
        y: 63,
      },
      {
        x: 106,
        y: 26,
      },
      {
        x: 107,
        y: 231,
      },
      {
        x: 108,
        y: 24,
      },
      {
        x: 109,
        y: 37,
      },
      {
        x: 110,
        y: 91,
      },
      {
        x: 111,
        y: 92,
      },
      {
        x: 112,
        y: 143,
      },
      {
        x: 113,
        y: 20,
      },
      {
        x: 114,
        y: 132,
      },
      {
        x: 115,
        y: 121,
      },
      {
        x: 116,
        y: 289,
      },
      {
        x: 117,
        y: 5,
      },
      {
        x: 118,
        y: 43,
      },
      {
        x: 119,
        y: 189,
      },
      {
        x: 120,
        y: 265,
      },
    ],
  },
  {
    id: 'australia',
    color: 'hsl(0, 70%, 50%)',
    data: [
      {
        x: 0,
        y: 104,
      },
      {
        x: 1,
        y: 223,
      },
      {
        x: 2,
        y: 37,
      },
      {
        x: 3,
        y: 141,
      },
      {
        x: 4,
        y: 220,
      },
      {
        x: 5,
        y: 187,
      },
      {
        x: 6,
        y: 218,
      },
      {
        x: 7,
        y: 229,
      },
      {
        x: 8,
        y: 174,
      },
      {
        x: 9,
        y: 172,
      },
      {
        x: 10,
        y: 46,
      },
      {
        x: 11,
        y: 124,
      },
      {
        x: 12,
        y: 98,
      },
      {
        x: 13,
        y: 44,
      },
      {
        x: 14,
        y: 165,
      },
      {
        x: 15,
        y: 161,
      },
      {
        x: 16,
        y: 210,
      },
      {
        x: 17,
        y: 10,
      },
      {
        x: 18,
        y: 73,
      },
      {
        x: 19,
        y: 209,
      },
      {
        x: 20,
        y: 45,
      },
      {
        x: 21,
        y: 26,
      },
      {
        x: 22,
        y: 264,
      },
      {
        x: 23,
        y: 66,
      },
      {
        x: 24,
        y: 136,
      },
      {
        x: 25,
        y: 23,
      },
      {
        x: 26,
        y: 16,
      },
      {
        x: 27,
        y: 183,
      },
      {
        x: 28,
        y: 151,
      },
      {
        x: 29,
        y: 286,
      },
      {
        x: 30,
        y: 295,
      },
      {
        x: 31,
        y: 93,
      },
      {
        x: 32,
        y: 51,
      },
      {
        x: 33,
        y: 157,
      },
      {
        x: 34,
        y: 95,
      },
      {
        x: 35,
        y: 291,
      },
      {
        x: 36,
        y: 137,
      },
      {
        x: 37,
        y: 178,
      },
      {
        x: 38,
        y: 82,
      },
      {
        x: 39,
        y: 216,
      },
      {
        x: 40,
        y: 209,
      },
      {
        x: 41,
        y: 211,
      },
      {
        x: 42,
        y: 115,
      },
      {
        x: 43,
        y: 184,
      },
      {
        x: 44,
        y: 1,
      },
      {
        x: 45,
        y: 63,
      },
      {
        x: 46,
        y: 47,
      },
      {
        x: 47,
        y: 117,
      },
      {
        x: 48,
        y: 182,
      },
      {
        x: 49,
        y: 178,
      },
      {
        x: 50,
        y: 146,
      },
      {
        x: 51,
        y: 286,
      },
      {
        x: 52,
        y: 167,
      },
      {
        x: 53,
        y: 54,
      },
      {
        x: 54,
        y: 157,
      },
      {
        x: 55,
        y: 281,
      },
      {
        x: 56,
        y: 167,
      },
      {
        x: 57,
        y: 266,
      },
      {
        x: 58,
        y: 117,
      },
      {
        x: 59,
        y: 213,
      },
      {
        x: 60,
        y: 144,
      },
      {
        x: 61,
        y: 16,
      },
      {
        x: 62,
        y: 148,
      },
      {
        x: 63,
        y: 223,
      },
      {
        x: 64,
        y: 62,
      },
      {
        x: 65,
        y: 123,
      },
      {
        x: 66,
        y: 179,
      },
      {
        x: 67,
        y: 28,
      },
      {
        x: 68,
        y: 295,
      },
      {
        x: 69,
        y: 3,
      },
      {
        x: 70,
        y: 20,
      },
      {
        x: 71,
        y: 60,
      },
      {
        x: 72,
        y: 132,
      },
      {
        x: 73,
        y: 12,
      },
      {
        x: 74,
        y: 273,
      },
      {
        x: 75,
        y: 193,
      },
      {
        x: 76,
        y: 163,
      },
      {
        x: 77,
        y: 238,
      },
      {
        x: 78,
        y: 96,
      },
      {
        x: 79,
        y: 235,
      },
      {
        x: 80,
        y: 157,
      },
      {
        x: 81,
        y: 249,
      },
      {
        x: 82,
        y: 195,
      },
      {
        x: 83,
        y: 55,
      },
      {
        x: 84,
        y: 155,
      },
      {
        x: 85,
        y: 93,
      },
      {
        x: 86,
        y: 201,
      },
      {
        x: 87,
        y: 143,
      },
      {
        x: 88,
        y: 130,
      },
      {
        x: 89,
        y: 156,
      },
      {
        x: 90,
        y: 53,
      },
      {
        x: 91,
        y: 103,
      },
      {
        x: 92,
        y: 85,
      },
      {
        x: 93,
        y: 165,
      },
      {
        x: 94,
        y: 31,
      },
      {
        x: 95,
        y: 149,
      },
      {
        x: 96,
        y: 91,
      },
      {
        x: 97,
        y: 125,
      },
      {
        x: 98,
        y: 144,
      },
      {
        x: 99,
        y: 135,
      },
      {
        x: 100,
        y: 192,
      },
      {
        x: 101,
        y: 233,
      },
      {
        x: 102,
        y: 17,
      },
      {
        x: 103,
        y: 90,
      },
      {
        x: 104,
        y: 223,
      },
      {
        x: 105,
        y: 2,
      },
      {
        x: 106,
        y: 234,
      },
      {
        x: 107,
        y: 32,
      },
      {
        x: 108,
        y: 109,
      },
      {
        x: 109,
        y: 242,
      },
      {
        x: 110,
        y: 169,
      },
      {
        x: 111,
        y: 94,
      },
      {
        x: 112,
        y: 140,
      },
      {
        x: 113,
        y: 77,
      },
      {
        x: 114,
        y: 232,
      },
      {
        x: 115,
        y: 72,
      },
      {
        x: 116,
        y: 181,
      },
      {
        x: 117,
        y: 53,
      },
      {
        x: 118,
        y: 253,
      },
      {
        x: 119,
        y: 162,
      },
      {
        x: 120,
        y: 8,
      },
    ],
  },
];

function LinePlotCanvas({ data }: VizProps) {
  return (
    <ResponsiveLine
      data={lineData}
      margin={{ top: 50, right: 160, bottom: 50, left: 60 }}
      xScale={{ type: 'linear' }}
      yScale={{ type: 'linear', stacked: true, min: 0, max: 2500 }}
      yFormat=" >-.2f"
      curve="monotoneX"
      axisTop={null}
      axisRight={{
        tickValues: [0, 500, 1000, 1500, 2000, 2500],
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: '.2s',
        legend: '',
        legendOffset: 0,
      }}
      axisBottom={{
        tickValues: [0, 20, 40, 60, 80, 100, 120],
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: '.2f',
        legend: 'price',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        tickValues: [0, 500, 1000, 1500, 2000, 2500],
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: '.2s',
        legend: 'volume',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      enableGridX={false}
      colors={{ scheme: 'spectral' }}
      lineWidth={1}
      pointSize={4}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={1}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh
      gridXValues={[0, 20, 40, 60, 80, 100, 120]}
      gridYValues={[0, 500, 1000, 1500, 2000, 2500]}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 140,
          translateY: 0,
          itemsSpacing: 2,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 12,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
}

export default LinePlotCanvas;
