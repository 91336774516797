import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import NiceModal from '@ebay/nice-modal-react';
import { RootContext } from '../../../stores/storeProvidor';
import EditLabel from '../../controls/EditLabel';
import TagList from '../../controls/TagList';
import TagSelectModal from '../../modals/TagSelectModal';

interface ReportInfoContainerProps {
  reportId: string;
  className: string;
}

const ReportInfoContainer = observer(({ reportId, className }: ReportInfoContainerProps) => {
  const { activeReportStore } = useContext(RootContext);

  const activeReport = activeReportStore.getActiveReport(reportId);
  const reportDetails = activeReport?.report;

  if (reportDetails === undefined || activeReport === undefined) {
    return <div>No report</div>;
  }

  const editTags = (tag: string) => {
    NiceModal.show(TagSelectModal, {
      tagsFor: reportDetails.name,
      currentTags: reportDetails.tags,
      selectedTag: tag,
      updateTagsFn: (newTags: string[]) => {
        activeReportStore.setReportTags(reportId, newTags);
      },
    });
  };

  return (
    <div className={className}>
      <EditLabel
        id="rpt_name"
        value={reportDetails.name}
        onChange={(val) => {
          activeReportStore.setReportNameAndNote(reportId, val, undefined);
        }}
        placeholder="Report Name"
        className="text-xl font-bold text-center"
      />
      <EditLabel
        id="rpt_desc"
        value={reportDetails.description}
        onChange={(val) => {
          activeReportStore.setReportNameAndNote(reportId, undefined, val);
        }}
        placeholder="Note"
        textArea
      />
      <TagList tags={reportDetails.tags} moreLimit={6} onClick={editTags} />
    </div>
  );
});

export default ReportInfoContainer;
