import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RootContext } from '../../stores/storeProvidor';
import { ContextMenuEntry, ContextMenuIdTag } from '../../stores/uiStateStore';
import useDimensions from '../../hooks/useDimentions';
import useTimeout from '../../hooks/useTimeout';
import { simpleHash } from '../../utils/helpers';

interface ContextMenuItemProps {
  entry: ContextMenuEntry;
  clearMenu: () => void;
  context: unknown;
}

function ContextMenuItem({ entry, clearMenu, context }: ContextMenuItemProps) {
  const onClick = () => {
    clearMenu();
    if (entry.action) entry.action(entry.label, context);
  };
  if (entry.label.length > 0) {
    return (
      <li className="px-2 rounded-lg hover:bg-slate-200 hover:font-semibold">
        <button type="button" onClick={onClick} id={`${ContextMenuIdTag}${simpleHash(entry.label)}`}>
          {entry.Icon && <entry.Icon className="h-5 inline-block mr-2 mb-1" />}
          {entry.label}
        </button>
      </li>
    );
  }
  return <hr />;
}

const ContextMenuWrapper = observer(() => {
  const { uiState } = useContext(RootContext);
  const { activeContextMenu: contextMenu } = uiState;
  const ref = useRef<HTMLDivElement>(null);

  // Handle click away from menu
  // TODO: This isn't ideal, as we have a constant listener for EVERY click - even where there is no menu.
  // Tried moving this to the uiState store, but it didn't work...
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tgt = event.target;
      const clickId = (tgt as unknown as HTMLDivElement | undefined)?.id;
      if (!clickId?.startsWith(ContextMenuIdTag)) {
        uiState.clearContextMenu(); // Any click away closes menu
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [uiState, ref]);

  // Close after TTL expires
  const [triggerTTL] = useTimeout(() => {
    uiState.clearContextMenu();
  }, (contextMenu?.ttlSec ?? 0) * 1000);

  // Get size of menu
  const { width: menuWidth, height: menuHeight } = useDimensions({ targetRef: ref, liveMeasure: true });
  // TODO: why does this not work?

  // Position the menu
  useLayoutEffect(() => {
    if (contextMenu === undefined) return;

    triggerTTL();

    let posX = contextMenu.xPos;
    let posY = contextMenu.yPos;

    /* TODO - see above - fix menu positioning when at edge of window
    console.log(posX);
    console.log(posX + menuWidth);
    console.log(window.innerWidth);
    */

    // Check if the menu goes beyond the right edge of the window
    if (posX + menuWidth > window.innerWidth) {
      posX = window.innerWidth - menuWidth;
    }

    // Check if the menu goes beyond the bottom edge of the window
    if (posY + menuHeight > window.innerHeight) {
      posY = window.innerHeight - menuHeight;
    }

    ref.current?.style.setProperty('left', `${posX}px`);
    ref.current?.style.setProperty('top', `${posY}px`);
  }, [contextMenu, menuWidth, menuHeight, triggerTTL]);

  // No menu - nothing to do
  if (contextMenu === undefined) return null;

  // Filter menu items
  const filteredItems = contextMenu.entries.filter((entry) => {
    if (typeof entry.show === 'function') return entry.show(contextMenu.contextObj);
    if (entry.show !== undefined) return entry.show;
    return true;
  });

  const menuItems = filteredItems
    .filter((entry, i) => {
      if (entry.label.length !== 0) return true;
      if (i === 0 || i === filteredItems.length - 1) return false;
      return entry.label !== filteredItems[i - 1].label;
    })
    .map((entry, i) => ({
      key: entry.label.length > 0 ? entry.label : `sep${i}`,
      menu: entry,
    }));

  const clearMenu = () => uiState.clearContextMenu();

  // Show menu - there can only be one
  return (
    <div ref={ref} className="fixed max-w-xs z-40" id="context-menu-wrapper" onContextMenu={(e) => e.preventDefault()}>
      <ul className="flex flex-col rounded-lg shadow-xl overflow-hidden bg-white border border-gray-400 p-1 m-1 gap-1">
        {menuItems.map((entry) => (
          <ContextMenuItem key={entry.key} entry={entry.menu} clearMenu={clearMenu} context={contextMenu.contextObj} />
        ))}
      </ul>
    </div>
  );
});

// Hook to setup and show menu
export function useContextMenu(entries: ContextMenuEntry[], ttlSec = 5) {
  const { uiState } = useContext(RootContext);
  const [menu] = useState<ContextMenuEntry[]>(entries);

  const showMenu = (xPos: number, yPos: number, contextObj: unknown) => {
    uiState.setContextMenu(xPos, yPos, menu, contextObj, ttlSec);
  };

  const hideMenu = () => {
    uiState.clearContextMenu();
  };

  return { showMenu, hideMenu };
}

export const ContextSeparator = { label: '', action: undefined, Icon: undefined, show: undefined } as ContextMenuEntry;

export default ContextMenuWrapper;
