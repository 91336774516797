import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useDateFormat } from '../../../hooks/useFormat';
import { AdminPanel } from './AdminList';
import { UserActivity } from '../models/Activity';
import UserAdminLink from './UserAdminLink';

type AdminUserActivityProps = {
  activity: UserActivity[] | undefined;
  doRefresh: () => void;
};

export default function AdminUserActivity({ activity, doRefresh }: AdminUserActivityProps) {
  const { formatDate, formatTime, formatDateTime } = useDateFormat();
  const lastTime = activity?.sort((a, b) => a.timestamp.diff(b.timestamp).milliseconds)[0]?.timestamp;
  return (
    <AdminPanel>
      <div className="flex-none text-sm font-bold h-6 text-left px-4">Updated At</div>
      <div className="grow w-64">
        {formatDateTime(lastTime, true)}
        <ArrowPathIcon className="h-5 text-gray-500 float-right hover:text-blue-700" onClick={doRefresh} />
      </div>
      <div className="basis-96" />
      <div className="h-64 grow overflow-auto grid grid-flow-row-dense grid-cols-3">
        {activity?.map((ra, i) => {
          const zeb = i % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100';
          return (
            <div key={`${ra.userId}:aua`}>
              <div className={`p-2 col-span-1 ${zeb}`}>
                <div>
                  {`${formatDate(ra.timestamp, 's')}`}
                  <br />
                  {`@ ${formatTime(ra.timestamp, true)}`}
                </div>
                <div>
                  User: <UserAdminLink uid={ra.userId} name={ra.name} />
                </div>
              </div>
              <div className={`p-2 col-span-2 ${zeb}`}>
                <div>{`Successful Runs: ${ra.reportsRunOk}`}</div>
                <div>{`Failed Runs: ${ra.reportsRunError}`}</div>
                <div>{`API Activity: ${ra.apiActivity}`}</div>
              </div>
            </div>
          );
        })}
      </div>
    </AdminPanel>
  );
}
