import { useContext, useEffect, useState } from 'react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import NiceModal from '@ebay/nice-modal-react';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import AdminGrid4x6, { AdminGrid4x6SingleColumn, AdminGrid4x6TripleColumn } from './AdminGrid';
import UserTable from './controls/UserTable';
import { AdminUser } from '../../models/userModel';
import { useAdminTtile } from '../../hooks/useTitle';
import AdminButton from './controls/AdminButton';
import ConfirmModal from '../modals/ConfirmModal';
import { RootContext } from '../../stores/storeProvidor';
import getTagColours from '../../utils/tagcolours';
import AddNewUserModal from '../modals/AddNewUserModal';
import SetRoleModal from '../modals/SetRoleModal';
import EditUserModal from '../modals/EditUserModal';

const AdminUsers = observer(() => {
  useAdminTtile('User Management', UserGroupIcon);

  const ctx = useContext(RootContext);
  const { uiState } = ctx;
  const adminStore = ctx.getAdminStore();
  const [selectedUsers, setSelectedUsers] = useState<AdminUser[]>([]);
  const doingDisable = selectedUsers.some((u) => u.isActive);

  useEffect(() => {
    adminStore.refresh();
  }, [adminStore]);

  const onClickEnable = () => {
    NiceModal.show(ConfirmModal, {
      title: doingDisable ? 'Bulk Disable Users' : 'Bulk Enable Users',
      body: <div>{`Are you sure you want to ${doingDisable ? 'disable' : 'enable'} the selected users?`}</div>,
      onYes: () => {
        adminStore
          .bulkUpdateUsers(selectedUsers, { isActive: !doingDisable })
          .then(() => {
            uiState.successAlert = 'The users accounts have been updated';
          })
          .catch((e) => {
            uiState.errorAlert = `Failed to update selected accounts: ${e.message}`;
          });
      },
      onNo: () => {
        uiState.warningAlert = 'Action cancelled...';
      },
    });
  };

  const onClickAdd = () => {
    NiceModal.show(AddNewUserModal);
  };

  const onClickAddBulk = () => {
    uiState.warningAlert = 'Adding bulk users not implemented yet, but dialog will allow a CSV to be uploaded...';
  };

  const onClickSetRole = () => {
    NiceModal.show(SetRoleModal, { users: selectedUsers });
  };

  const onClickEdit = () => {
    if (selectedUsers.length === 1) NiceModal.show(EditUserModal, { user: selectedUsers[0] });
  };

  const usersByOrg = _.groupBy(selectedUsers, (u) => u.role.organisation.name);
  const orgGroups = Object.keys(usersByOrg).sort();

  const singleOrg = _.uniq(_.map(selectedUsers, (u) => u.role.organisation.orgId)).length === 1;

  const seletedList =
    selectedUsers.length === 0 ? (
      <div className="text-slate-400 text-sm py-2">Select users in grid...</div>
    ) : (
      orgGroups.map((o) => {
        const users = usersByOrg[o];
        return (
          <>
            <div key={o} className="w-full text-sm font-bold">
              {o}:
            </div>
            {users.map((u) => (
              <span
                key={u.id}
                className={`text-sm font-medium px-2 py-0.5 rounded-md m-1 grow-0 ${getTagColours(
                  u.role.organisation.name
                )}`}
              >
                {`${u.firstName} ${u.lastName}`}
              </span>
            ))}
          </>
        );
      })
    );

  return (
    <AdminGrid4x6 className="bg-blue-100">
      <AdminGrid4x6SingleColumn className="p-2 flex flex-col">
        <h2 className="text-center w-full p-2">User Management</h2>
        <div className="grow flex flex-col">
          <div>Actions:</div>
          <div className="bg-slate-300 width-full rounded-xl p-2 mb-2">
            <AdminButton text="Add New User" onClick={onClickAdd} />
            <AdminButton text="Bulk Add" onClick={onClickAddBulk} />
            {selectedUsers.length > 0 ? (
              <AdminButton text={doingDisable ? 'Disable Users' : 'Enable Users'} onClick={onClickEnable} />
            ) : null}
            {selectedUsers.length === 1 ? <AdminButton text="Edit User" onClick={onClickEdit} /> : null}
            {singleOrg ? <AdminButton text="Set Role" onClick={onClickSetRole} /> : null}
          </div>
          <div>Selected Users:</div>
          <div className="bg-slate-300 width-full rounded-xl px-2 flex flex-row flex-wrap">{seletedList}</div>
        </div>
      </AdminGrid4x6SingleColumn>
      <AdminGrid4x6TripleColumn className="flex flex-col border-x border-gray-300 bg-slate-500">
        <div className="flex-1 overflow-hidden">
          <UserTable onSelected={setSelectedUsers} />
        </div>
      </AdminGrid4x6TripleColumn>
    </AdminGrid4x6>
  );
});

export default AdminUsers;
