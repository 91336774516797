import { makeAutoObservable, runInAction } from 'mobx';
import _, { forEach } from 'lodash';
import type RootStore from './rootStore';
import {
  ActiveReport,
  AxisId,
  ReportPageResult,
  RunStatus,
  LayoutAxis,
  PageDimention,
  EmbeddedSelection,
  GridSelection,
  ReportLayoutResult,
  HasResultsFromStatus,
  ReportStatus,
} from '../models/reportModel';
import reportService from '../services/reportService';
import { simpleHash, isEqual, createNewUUID } from '../utils/helpers';
import { GridSection } from '../Components/controls/table/BaseResultGrid';
import { BreakoutObj, BreakoutType, DATATYPE_BREAKOUT_ID, createUnknownBreakout } from '../models/breakoutModels';
import { LayoutItemStorage, ItemWithId, baseId, insertAtIndex } from '../utils/dragUtils';
import { SelectionNode, SelectionType } from '../models/selectionModels';
import { DATATYPE_LEVEL_TAG } from './selectionStore';
import { SubReport, SubReportType } from '../Components/app/report/subReports/SubReportBase';
import { VizData } from '../Components/visualisations/VizData';
import { getAxisLevelsWithDatatypes } from '../utils/layoutUtils';
import calculateBestVizType from '../Components/visualisations/VizTypes';
import showReportTooLargeModal from '../Components/modals/ReportTooLargeWarningModal';
import mergeArrayToStore from '../utils/arrayHelpers';
import RowScrollOffsetStore from './rowScrollOffsetStore';
import { generateHeaderSpans } from '../utils/headerTree';
import { AppSettings, EmptySettings, settingsAreSame } from '../models/settings';

export interface CellClickContext {
  reportId: string;
  page: number;
  section: GridSection;
  cellColumn: number | undefined;
  cellRow: number | undefined;
}

export const getPageResultMapKey = (reportId: string, pageId: number) => `${reportId}:${pageId}`;

class ActiveReportStore {
  private rootStore: RootStore;

  // Active Reports are the 'Open' reports (i.e tabs along the bottom)
  // Note this does NOT include SubReports (Charts/Drilldowns) as these
  // are stored within the active report
  activeReports: Map<string, ActiveReport>;

  // NOTE: We had the maps below within ActiveReport, but then we have a Map<> within a Map<> and Mobx
  // observable would not go that deep. Moved here so we can get reaction on result changes...

  // Keep results separate from activeReports so we can have re-use result store for drillowns
  // Similar to note above - do NOT combine with pageResults, to prevent Mobx issues
  layoutResults: Map<string, ReportLayoutResult>; // All active results (i.e for reports and drillowns)

  // Use getPageResultMapKey() to get key for report/page
  pageResults: Map<string, ReportPageResult>; // Results per page

  nextSortPosition = 0;

  rowPageSize = 50; // Number of rows we assume are visible on screen at a time

  rowBatchSize = 1000; // Number of rows to load at a time

  autoSaveReportIdSet: Set<string>;

  resultStatusUpdateHash = '';

  constructor(root: RootStore) {
    this.rootStore = root;
    this.activeReports = new Map<string, ActiveReport>();
    this.pageResults = new Map<string, ReportPageResult>();
    this.layoutResults = new Map<string, ReportLayoutResult>();
    this.autoSaveReportIdSet = new Set<string>();
    makeAutoObservable(this);
  }

  isActiveReport(reportId: string) {
    return this.activeReports.has(reportId);
  }

  get getOpenReportsInSortOrder() {
    const rpts = Array.from(this.activeReports.values()).filter((r) => r.report !== undefined);
    rpts.sort((a, b) => a.sortPosition - b.sortPosition);
    return rpts.map((r) => r.report);
  }

  get getCountOfActiveReports() {
    return this.activeReports.size;
  }

  getActiveReport(reportId: string | undefined): ActiveReport | undefined {
    return reportId ? this.activeReports.get(reportId) : undefined;
  }

  getActiveReportIdFromSubReport(reportId: string | undefined): string | undefined {
    if (!reportId) return undefined;

    // Id is the main report - just return
    const ar = this.activeReports.get(reportId);
    if (ar) return reportId;

    // Sub-report - find main report that owns the given report Id
    const d = Array.from(this.activeReports).find((r) => r[1].subReports.has(reportId));
    return d?.[0];
  }

  getLayoutResult(reportId: string | undefined): ReportLayoutResult | undefined {
    return reportId ? this.layoutResults.get(reportId) : undefined;
  }

  getPageResult(reportId: string | undefined, pageId: number): ReportPageResult | undefined {
    if (reportId === undefined) return undefined;
    const pageKey = getPageResultMapKey(reportId, pageId);
    let pr = this.pageResults.get(pageKey);
    if (pr === undefined) {
      // Create on first access, if doesn't exist
      pr = {
        page: pageId,
        startRow: 0,
        rowCount: 0,
        totalRows: 0, // Note: this is updated when we know the dimentions...
      } as ReportPageResult;
      this.pageResults.set(pageKey, pr);
    }
    return pr;
  }

  setIgnoreLargeReportWarning(reportId: string) {
    const ar = this.getActiveReport(reportId);
    if (ar) {
      ar.ignoreLargeReportWarning = true;
    }
  }

  // Preload report details, as soon as its added to the open list
  async addReportToOpen(reportId: string) {
    if (this.isActiveReport(reportId)) {
      this.rootStore.uiState.setActiveReport(reportId); // Make active
      return true;
    }

    if (!this.rootStore.availReportStore.isValidReportId(reportId)) {
      return false;
    }

    let ok = false;

    this.activeReports.set(reportId, {
      report: undefined,
      isLoading: true,
      loadMessage: 'Loading report...',
      needsRunning: true,
      needsSaving: false,
      isEditting: true,
      sortPosition: this.nextSortPosition,
      selectionTabId: undefined, // = first tab
      subReports: new Map<string, SubReport>(),
      activeSubReport: undefined, // undef means 'main report'
      layoutResult: { runStatus: RunStatus.NotQueued, currentPage: 0 },
      ignoreLargeReportWarning: false,
    } as ActiveReport);

    this.nextSortPosition += 1;

    try {
      const reportDetails = await reportService.api.getReportDetails(reportId, true);
      runInAction(() => {
        const rpt = this.getActiveReport(reportId);
        if (rpt) {
          rpt.report = reportDetails;
          rpt.loadMessage = 'Successfully loaded report';
          this.rootStore.uiState.setActiveReport(reportId); // Make active
          this.rootStore.availReportStore.setRecentListStale();

          // Load linked selections - no async - load in background
          this.rootStore.selectionStore.loadLinkedSelections(reportDetails.spec.selections);
          ok = true;
        } else {
          ok = false;
        }
      });
    } catch (e) {
      runInAction(() => {
        const rpt = this.getActiveReport(reportId);
        if (rpt) {
          rpt.loadMessage = 'Failed to load report';
        }
        ok = false;
      });
    }
    return ok;
  }

  closeActiveReport(reportId: string) {
    let nextId: string | undefined;
    const rpt = this.getActiveReport(reportId);
    if (rpt) {
      const isCurrentReport = this.rootStore.uiState.getActiveReportId === reportId;
      if (isCurrentReport) {
        const orpts = Array.from(this.activeReports.values()).filter((r) => r.report !== undefined);
        orpts.sort((a, b) => a.sortPosition - b.sortPosition);
        let nextRpts = orpts.filter((r) => r.sortPosition > rpt.sortPosition);
        if (nextRpts.length > 0) {
          nextId = nextRpts[0].report?.id;
        } else {
          nextRpts = orpts.filter((r) => r.sortPosition < rpt.sortPosition);
          if (nextRpts.length > 0) {
            nextId = nextRpts[nextRpts.length - 1].report?.id;
          }
        }
      }

      // Ensure any changes are saved before we close...
      this.saveActiveReport(reportId).then(() => {
        this.closeReport(reportId);

        if (nextId || this.activeReports.size === 0) {
          this.rootStore.uiState.setActiveReport(nextId); // Make next active
        }
      });
    }
    return this.activeReports.size === 0;
  }

  closeReport(reportId: string) {
    const rpt = this.getActiveReport(reportId);

    // Get all ids we need to delete - if this is an active report include all subreports
    const idsToDelete = rpt ? Array.from(rpt.subReports.keys()) : [];
    idsToDelete.push(reportId);

    forEach(idsToDelete, (id) => this.clearResultsForReport(id));

    // Delete main report
    if (rpt) {
      this.activeReports.delete(reportId);
      this.rootStore.layoutDragStore.cleanupLayoutDragItems(reportId);
      this.rootStore.rowScrollOffsetStore.cleanupRowSpecs(reportId);
    }
  }

  clearResultsForReport(reportId: string) {
    // Clear page results
    const lr = this.getLayoutResult(reportId);
    const pages = lr?.pageDims?.length ?? 1;
    for (let pageId = 0; pageId < pages; pageId += 1) {
      this.pageResults.delete(getPageResultMapKey(reportId ?? 'none', pageId));
    }
    // And main result
    this.layoutResults.delete(reportId);
  }

  async saveActiveReport(reportId: string) {
    const rpt = this.getActiveReport(reportId);
    if (rpt?.report !== undefined && rpt.needsSaving) {
      if (rpt.report.isReadOnly) {
        this.rootStore.uiState.errorAlert = `Report '${rpt.report.name}' is readonly. Changes will not be saved unless you save to a new report`;
      } else {
        try {
          await reportService.api.saveReportDetails(reportId, rpt.report);
          runInAction(() => {
            const rptb = this.getActiveReport(reportId);
            if (rptb !== undefined) {
              rptb.needsSaving = false;
            }
            this.autoSaveReportIdSet.delete(reportId);
            this.rootStore.uiState.successAlert = `Your changes to '${rpt?.report?.name ?? '?'}' have been saved`;
          });
        } catch (e) {
          runInAction(() => {
            this.rootStore.uiState.errorAlert = `Failed to save report '${rpt?.report?.name ?? '?'}'`;
          });
        }
      }
    }
  }

  async autoSaveAllReports() {
    // Clone because we are about to change the undelying set on each save
    const ids = _.clone(this.autoSaveReportIdSet.values());
    await Promise.all(Array.from(ids).map((reportId) => this.saveActiveReport(reportId)));
    runInAction(() => {
      this.autoSaveReportIdSet.clear();
      this.rootStore.uiState.clearAutoSave('reports');
    });
  }

  triggerAutoSave(reportId: string) {
    this.autoSaveReportIdSet.add(reportId);
    this.rootStore.uiState.triggerAutoSave('reports');
  }

  async createNewReportAndAddToOpen(
    newName: string | undefined,
    description: string | undefined,
    templateId: string | undefined
  ): Promise<string | undefined> {
    let [name, desc] = [newName, description];
    if (!newName || !newName.length) {
      const newNameBase = '<New Report>';
      [name, desc] = this.rootStore.availReportStore.getNewReportName(newNameBase, templateId);
    }

    let newReportId: string | undefined;

    // Create the report
    try {
      newReportId = await reportService.api.createNewReport(name, desc, templateId);
      if (newReportId !== undefined) {
        runInAction(async () => {
          this.rootStore.uiState.successAlert = templateId ? `Copied report.` : `Created new report`;

          // reload available list
          await this.rootStore.availReportStore.loadAvailableReports();
          runInAction(async () => {
            // Add to the open list
            await this.addReportToOpen(newReportId ?? 'badId');
          });
        });
      }
    } catch (e) {
      runInAction(() => {
        this.rootStore.uiState.errorAlert = `Failed to create new report; ${(e as Error).message}`;
      });
    }

    return newReportId;
  }

  async deleteReport(reportId: string) {
    // Close if open
    await this.closeActiveReport(reportId);
    runInAction(async () => {
      // Delete from db
      try {
        await reportService.api.deleteReport(reportId);
        runInAction(async () => {
          // Reload available list
          await this.rootStore.availReportStore.loadAvailableReports();
          this.rootStore.uiState.successAlert = `The report has been deleted`;
        });
      } catch (e) {
        runInAction(async () => {
          // Reload available list
          this.rootStore.uiState.errorAlert = `Failed to delete report; ${(e as Error).message}`;
        });
      }
    });
  }

  reportRunStatus(reportId: string | undefined): RunStatus | undefined {
    const lr = this.getLayoutResult(reportId);
    return lr?.runStatus?.status;
  }

  reportDimentions(reportId: string | undefined): PageDimention[] | undefined {
    const lr = this.getLayoutResult(reportId);
    return lr?.pageDims;
  }

  setReportNeedsRunning(reportId: string | undefined) {
    // Note: if reportId is a Sub-Report then rpt below will be undef
    const rpt = this.getActiveReport(reportId);
    if (reportId) {
      if (rpt) rpt.needsRunning = true;

      // Clear any existing results
      this.clearResultsForReport(reportId);

      // Reset existing results
      this.layoutResults.set(reportId, {
        runId: undefined,
        runStatus: {
          status: RunStatus.NotQueued,
          message: 'Run needed...',
        } as ReportStatus,
        currentPage: 0,
        reportAsRun: undefined,
        pageDims: undefined,
        selection: undefined,
      } as ReportLayoutResult);

      // Reset sub reports (main report only)
      if (rpt) {
        rpt.activeSubReport = undefined;
        rpt.subReports.clear();
      }
    }
  }

  async runReport(reportId: string) {
    this.setReportNeedsRunning(reportId);

    const lr = this.getLayoutResult(reportId);
    if (lr?.runStatus) lr.runStatus.status = RunStatus.Pending;

    const [schemaId, schemaVersion] = this.rootStore.selectionStore.getValidSchemaAndVersion();
    if (schemaId === undefined || schemaVersion === undefined) {
      this.rootStore.uiState.errorAlert = 'No data selected. Choose a data-source to continue...';
      return;
    }

    const ignoreLargeWarning = this.getActiveReport(reportId)?.ignoreLargeReportWarning ?? false;

    await this.saveActiveReport(reportId);

    try {
      const runId = await reportService.api.runReport(reportId, schemaId, schemaVersion, ignoreLargeWarning, true);
      runInAction(() => {
        const lra = this.getLayoutResult(reportId);
        if (lra) {
          lra.runId = runId;
          lra.runStatus = {
            ...lra.runStatus,
            message: 'Report queued for processing...',
          } as ReportStatus;
          this.rootStore.availReportStore.setRecentListStale();
        }
      });
    } catch (e) {
      runInAction(() => {
        const err = e as Error;
        const lrb = this.getLayoutResult(reportId);
        if (lrb) {
          lrb.runId = undefined;
          lrb.runStatus = {
            ...lrb.runStatus,
            status: RunStatus.Failed,
            message: err.message,
          } as ReportStatus;
          this.rootStore.uiState.errorAlert = lrb.runStatus.message;
        }
      });
    }
  }

  async updateRunStatus(reportId: string) {
    const runId = this.getLayoutResult(reportId)?.runId;
    if (runId !== undefined) {
      try {
        const status = await reportService.api.getRunStatus(runId, true);
        runInAction(async () => {
          const ar = this.getActiveReport(reportId); // Only main report will be 'active', Will be undef for sub-reports
          const lr = this.getLayoutResult(reportId);
          if (lr) {
            lr.pcComplete = status.pcDone;

            const previousStatus = lr?.runStatus?.status;
            lr.runStatus = status;
            if (lr.runStatus.status === RunStatus.Failed) {
              if (ar) {
                ar.isEditting = true; // Go into 'edit' mode (Main report only)
                ar.needsRunning = true;
              }

              // See reportProcessingService.cs for message source
              const msg = lr.runStatus.message.split('|Msg:')[1];
              this.rootStore.uiState.errorAlert = `Report failed: ${msg}`;
            } else if (lr.runStatus.status === RunStatus.RetryNeeded) {
              if (ar) {
                ar.isEditting = true; // Go into 'edit' mode (Main report only)
                ar.needsRunning = true;
              }

              showReportTooLargeModal(() => {
                runInAction(() => {
                  this.setIgnoreLargeReportWarning(reportId);
                  this.rootStore.uiState.infoAlert = 'Attempting to run large report. This may take a while...';
                  this.runReport(reportId);
                });
              });
            } else if (HasResultsFromStatus(lr.runStatus.status)) {
              // Start loading dimentions(pages), headers(cols) and results(rows)
              await this.loadReportDetails(
                reportId,
                lr.currentPage,
                -1,
                ar?.needsRunning ?? true,
                previousStatus !== RunStatus.Complete, // Keep reloading page until we are 'complete'; the total row size will be updated
                false
              );
              runInAction(() => {
                // Go into 'result' mode (Main report only)
                const ar2 = this.getActiveReport(reportId);
                if (ar2) {
                  ar2.isEditting = false;
                  ar2.needsRunning = false;
                }
              });
            }
          }
        });
      } catch (e) {
        runInAction(() => {
          const err = e as Error;
          const rptErr = this.getActiveReport(reportId);
          const lrErr = this.getLayoutResult(reportId);
          if (lrErr) {
            if (rptErr) rptErr.isEditting = true; // Go into 'edit' mode (Main report only)
            lrErr.runStatus = {
              ...lrErr.runStatus,
              status: RunStatus.Failed,
              message: err.message,
            } as ReportStatus;
            this.rootStore.uiState.errorAlert = 'Error when getting results';
          }
        });
      }
    }
  }

  async loadReportDetails(
    reportId: string,
    page: number,
    wantedStartRow = -1,
    firstLoad = false,
    updateDimentions = false,
    reloadPage = false
  ) {
    let fromRow = wantedStartRow;
    if (updateDimentions || firstLoad) {
      // Always load dimentions while loading, as this will change as blocks are progressed
      await this.loadResultDimentions(reportId);
    }

    if (firstLoad) {
      // First time only - subsequent pages are loaded on demand as user scrolls
      await this.loadResultReportSpec(reportId);
      await this.loadResultColumnHeaders(reportId, 0);
      await this.loadResultRows(reportId, 0, 0);
    } else {
      // Subsequent loads
      const rs = this.rootStore.rowScrollOffsetStore.getRowSpec(RowScrollOffsetStore.getId(reportId, page));

      if (reloadPage) {
        // New Page; Reload columns and rows
        await this.loadResultColumnHeaders(reportId, page);

        // Get previous start row - restore if so
        fromRow = rs !== undefined ? rs.startRow : 0;
      }

      if (fromRow >= 0 && rs?.startRow !== fromRow) {
        // console.log('Loading rows from %d', fromRow);
        await this.loadResultRows(reportId, page, fromRow);
      }
    }

    runInAction(() => {
      const pr = this.getPageResult(reportId, page);
      this.resultStatusUpdateHash = `${reportId}-Rows-${page}-${fromRow}-${pr?.rowCount}-${pr?.totalRows}`;
    });
  }

  setEditMode(reportId: string, edit: boolean) {
    const rpt = this.getActiveReport(reportId);
    if (rpt !== undefined) {
      rpt.isEditting = edit;
    }
  }

  getCurrentPage(reportId: string | undefined): [number, number] {
    const lr = this.getLayoutResult(reportId ?? '');
    if (lr === undefined || !HasResultsFromStatus(lr?.runStatus?.status)) return [0, 0];
    return [lr.currentPage, lr.pageDims?.length ?? 0];
  }

  async setPage(reportId: string, nextpage: number) {
    const lr = this.getLayoutResult(reportId ?? '');
    if (lr) {
      lr.currentPage = nextpage;
    }
    await this.loadReportDetails(reportId, nextpage, -1, false, false, true);
  }

  getResultPageLayoutLevels(reportId: string): string[] {
    const lr = this.getLayoutResult(reportId);
    if (lr?.reportAsRun !== undefined) {
      const pagesNoDatatypes = lr.reportAsRun.layout.pages.map((l) => l.level);
      const pageDatatypeLevel = this.getPageLevelDatatypePosiiton(reportId);
      if (pageDatatypeLevel === undefined) return pagesNoDatatypes;

      // Datatype on pages - insert level now
      pagesNoDatatypes.splice(pageDatatypeLevel, 0, DATATYPE_LEVEL_TAG);
      return pagesNoDatatypes;
    }
    return [];
  }

  async loadResultDimentions(reportId: string) {
    const lr = this.getLayoutResult(reportId);
    if (!lr || !lr.runId || !HasResultsFromStatus(lr?.runStatus?.status)) return;
    try {
      const dims = await reportService.api.getResultDimentions(lr.runId);
      runInAction(() => {
        const lrb = this.getLayoutResult(reportId);
        if (lrb) {
          lrb.pageDims = dims;

          // Update dimentions for each page
          dims.forEach((pd, i) => {
            const pr = this.getPageResult(reportId, i);
            if (pr) {
              pr.totalRows = pd.rows;
            }
          });
        }
      });
    } catch (e) {
      runInAction(() => {
        const lrc = this.getLayoutResult(reportId);
        if (lrc) {
          lrc.pageDims = undefined;
          lrc.runStatus = {
            ...lrc.runStatus,
            status: RunStatus.Failed,
            message: 'Failed to load result dimentions',
          } as ReportStatus;
          this.rootStore.uiState.errorAlert = lrc.runStatus.message;
        }
      });
    }
  }

  async loadResultReportSpec(reportId: string) {
    const lr = this.getLayoutResult(reportId);
    if (!lr || !lr.runId || !HasResultsFromStatus(lr?.runStatus?.status)) return;
    if (lr.reportAsRun === undefined) {
      try {
        const spec = await reportService.api.getResultReportAsRun(lr.runId);
        runInAction(() => {
          const lrb = this.getLayoutResult(reportId);
          if (lrb) {
            lrb.reportAsRun = spec.report;
          }
        });
      } catch (e) {
        runInAction(() => {
          const lrc = this.getLayoutResult(reportId);
          if (lrc) {
            lrc.reportAsRun = undefined;
            lrc.runStatus = {
              ...lrc.runStatus,
              status: RunStatus.Failed,
              message: 'Failed to load "report-as-run"',
            } as ReportStatus;
            this.rootStore.uiState.errorAlert = lrc.runStatus.message;
          }
        });
      }
    }
  }

  async loadResultColumnHeaders(reportId: string, pageId: number) {
    const runId = this.getLayoutResult(reportId)?.runId ?? 'badId';
    try {
      const colHdrs = await reportService.api.getResultColumnHeaders(runId, pageId);
      runInAction(() => {
        const pageResult = this.getPageResult(reportId, pageId);
        if (pageResult) {
          pageResult.colHeader = colHdrs;
        }
      });
    } catch (e) {
      runInAction(() => {
        const lr = this.getLayoutResult(reportId);
        if (lr) {
          lr.reportAsRun = undefined;
          lr.runStatus = {
            ...lr.runStatus,
            status: RunStatus.Failed,
            message: 'Failed to load result columns',
          } as ReportStatus;
          this.rootStore.uiState.errorAlert = lr.runStatus.message;
        }
      });
    }
  }

  async loadResultRows(reportId: string, pageId: number, wantedRowStart = 0) {
    const lra = this.getLayoutResult(reportId);
    const runId = lra?.runId ?? 'badId';
    const pageResult = this.getPageResult(reportId, pageId);

    if (pageResult === undefined || !HasResultsFromStatus(lra?.runStatus?.status)) return;

    const wantedRowEnd = wantedRowStart + this.rowPageSize;
    if (pageResult.startRow <= wantedRowStart && pageResult.startRow + pageResult.rowCount - 1 >= wantedRowEnd) return; // Already loaded

    try {
      const [rowHdrs, rowCells] = await Promise.all([
        reportService.api.getResultRowHeaders(runId, pageId, wantedRowStart, this.rowBatchSize),
        reportService.api.getResultCells(runId, pageId, wantedRowStart, this.rowBatchSize),
      ]);
      runInAction(() => {
        const pr = this.getPageResult(reportId, pageId);
        if (pr) {
          // Append/clean the rows - keep this.maxRowBatchLimit rows in memory
          let newStart = -1;
          let newEnd = -1;

          [pr.rowHeader, newStart, newEnd] = mergeArrayToStore(
            pr.rowHeader,
            pr.startRow,
            rowHdrs.headers,
            rowHdrs.fromRow,
            this.rowBatchSize
          );

          // Calculate header spans on load...
          pr.rowHeaderSpans = generateHeaderSpans(rowHdrs.headers);

          [pr.body] = mergeArrayToStore(pr.body, pr.startRow, rowCells.rowCells, rowCells.fromRow, this.rowBatchSize);

          pr.startRow = newStart;
          pr.rowCount = newEnd - newStart + 1;
        }
      });
    } catch (e) {
      runInAction(() => {
        const lrb = this.getLayoutResult(reportId);
        if (lrb) {
          lrb.reportAsRun = undefined;
          lrb.runStatus = {
            ...lrb.runStatus,
            status: RunStatus.Failed,
            message: 'Failed to load result rows',
          } as ReportStatus;
          this.rootStore.uiState.errorAlert = lrb.runStatus.message;
        }
      });
    }
  }

  setReportNameAndNote(reportId: string, name: string | undefined, note: string | undefined) {
    const rpt = this.getActiveReport(reportId);
    if (rpt?.report !== undefined) {
      if (name !== undefined && rpt.report.name !== name) {
        rpt.report.name = name;
        rpt.needsSaving = true;
        this.triggerAutoSave(reportId);
      }
      if (note !== undefined && rpt.report.description !== note) {
        rpt.report.description = note;
        rpt.needsSaving = true;
        this.triggerAutoSave(reportId);
      }
    }
  }

  setReportTags(reportId: string, newTags: string[]) {
    const rpt = this.getActiveReport(reportId);
    if (rpt?.report !== undefined) {
      if (!isEqual(rpt.report.tags, newTags)) {
        rpt.report.tags = newTags;
        rpt.needsSaving = true;
        this.triggerAutoSave(reportId);
      }
    }
  }

  getReportSelection(reportId: string, selectionType: SelectionType): EmbeddedSelection | undefined {
    const rpt = this.getActiveReport(reportId);
    if (rpt?.report?.spec) {
      return rpt.report.spec.selections.find((s) => s.selectionType === selectionType);
    }
    return undefined;
  }

  saveReportSelection(reportId: string, selection: EmbeddedSelection) {
    const rpt = this.getActiveReport(reportId);
    if (rpt?.report?.spec.selections) {
      const others = rpt.report.spec.selections.filter((s) => s.selectionType !== selection.selectionType);
      rpt.report.spec.selections = [...others, selection];
    }
  }

  updateReportLinkedSelection(reportId: string, selectionType: SelectionType, linkedId: string) {
    this.saveReportSelection(reportId, {
      selectionType,
      linkedId,
      treeRoot: undefined,
    } as EmbeddedSelection);

    const rpt = this.getActiveReport(reportId);
    if (rpt) {
      rpt.needsSaving = true;
      this.triggerAutoSave(reportId);
    }
  }

  updateReportEmbeddedSelection(reportId: string, selectionType: SelectionType, rootNode: SelectionNode) {
    this.saveReportSelection(reportId, {
      selectionType,
      linkedId: undefined,
      treeRoot: rootNode,
    } as EmbeddedSelection);

    this.setReportNeedsRunning(reportId);

    const rpt = this.getActiveReport(reportId);
    if (rpt) {
      rpt.needsSaving = true;
      this.triggerAutoSave(reportId);
    }
  }

  clearGridSelection(reportId: string) {
    const lr = this.getLayoutResult(reportId);
    if (lr) lr.selection = undefined;
  }

  setGridSelection(
    reportId: string,
    page: number,
    section: GridSection,
    colindex: number | undefined,
    rowindex: number | undefined,
    appendToExisting: boolean
  ) {
    const lr = this.getLayoutResult(reportId);
    if (!lr) return;

    // Selection must be on one page only
    if (lr.selection?.selPage !== page) lr.selection = undefined;

    if (section === GridSection.TopLeft) {
      // Toggle select-all
      if (lr.selection === undefined) lr.selection = { selPage: page, selStart: [-1, -1], selEnd: [-1, -1] };
      else lr.selection = undefined;
    } else {
      let c = colindex;
      let r = rowindex;
      // Row or column select
      if (section === GridSection.ColumnHeader) r = undefined;
      else if (section === GridSection.RowHeader) c = undefined;

      if (lr.selection === undefined || !appendToExisting) {
        lr.selection = { selPage: page, selStart: [c, r], selEnd: [c, r] };
      }

      if (lr.selection !== undefined && appendToExisting) {
        // Ensure start < end
        const [co, ro] = lr.selection.selStart;
        lr.selection.selStart = [
          co === undefined ? undefined : Math.min(c ?? -1, co ?? -1),
          ro === undefined ? undefined : Math.min(r ?? -1, ro ?? -1),
        ];
        lr.selection.selEnd = [
          co === undefined ? undefined : Math.max(c ?? -1, co ?? -1),
          ro === undefined ? undefined : Math.max(r ?? -1, ro ?? -1),
        ];
      }
    }
  }

  isGridCellSelected(
    reportId: string | undefined,
    page: number,
    col: number | undefined,
    row: number | undefined
  ): boolean {
    const sel = this.getLayoutResult(reportId)?.selection;
    if (sel === undefined) return false; // No selection
    if (sel.selPage !== page) return false; // Wrong page
    if (sel.selStart[0] === -1) return true; // All selected

    const rowMatch = row !== undefined ? row >= (sel.selStart[1] ?? -1) && row <= (sel.selEnd[1] ?? -1) : false;
    const colMatch = col !== undefined ? col >= (sel.selStart[0] ?? -1) && col <= (sel.selEnd[0] ?? -1) : false;

    if (sel.selStart[0] === undefined) {
      // Rows selected
      return rowMatch;
    }

    if (sel.selStart[1] === undefined) {
      // Columns selected
      return colMatch;
    }

    // Cells selected
    return rowMatch && colMatch;
  }

  hasGridSelection(reportId: string | undefined, page: number): boolean {
    const lr = this.getLayoutResult(reportId);
    if (lr?.selection === undefined) return false; // No selection
    if (lr.selection.selPage !== page) return false; // Wrong page
    return true;
  }

  refreshGridSelectionHash(reportId: string | undefined): number {
    const lr = this.getLayoutResult(reportId);
    return simpleHash(`${lr?.selection?.selEnd}${lr?.selection?.selEnd}`);
  }

  getPageLevelDatatypePosiiton(reportId: string): number | undefined {
    const lr = this.getLayoutResult(reportId);
    if (lr?.reportAsRun?.layout.dtPosition.axis === AxisId.Page) {
      return lr.reportAsRun.layout.dtPosition.index;
    }
    return undefined; // ie. Dts not on page axis
  }

  getCellDatatypes(reportId: string) {
    const lr = this.getLayoutResult(reportId);
    if (lr?.reportAsRun !== undefined) {
      return lr.reportAsRun.layout.cellDts;
    }
    return [];
  }

  getReportLayout(reportId: string, prototypeBreakouts: BreakoutObj[]): [BreakoutObj[], BreakoutObj[], BreakoutObj[]] {
    const dtBreakout = prototypeBreakouts.find((b) => b.type === BreakoutType.Datatypes);
    if (!dtBreakout) return [[], [], []];
    const ar = this.getActiveReport(reportId);
    const spec = ar?.report?.spec;

    let pageAxis =
      spec?.layout?.pages?.map(
        (a) => prototypeBreakouts.find((o) => o.id === a.level) ?? createUnknownBreakout(a.level)
      ) ?? [];
    let rowAxis =
      spec?.layout?.rows?.map(
        (a) => prototypeBreakouts.find((o) => o.id === a.level) ?? createUnknownBreakout(a.level)
      ) ?? [];
    let colAxis =
      spec?.layout?.columns?.map(
        (a) => prototypeBreakouts.find((o) => o.id === a.level) ?? createUnknownBreakout(a.level)
      ) ?? [];

    // Datatypes are NOT stored in axis, but must be displayed to user as if they are...
    const axis = spec?.layout?.dtPosition?.axis;
    const dtIndex = spec?.layout?.dtPosition?.index ?? 0;
    if (axis) {
      if (axis === AxisId.Page) pageAxis = insertAtIndex(pageAxis, dtIndex, dtBreakout);
      if (axis === AxisId.Row) rowAxis = insertAtIndex(rowAxis, dtIndex, dtBreakout);
      if (axis === AxisId.Columns) colAxis = insertAtIndex(colAxis, dtIndex, dtBreakout);
    } else {
      // No Datatypes in layout - force onto pages
      insertAtIndex(pageAxis, 0, dtBreakout);
    }

    // Innermost-level (0) should be visually at bottom
    pageAxis.reverse();
    rowAxis.reverse();
    colAxis.reverse();

    return [pageAxis, rowAxis, colAxis];
  }

  saveReportLayout(reportId: string, store: LayoutItemStorage<BreakoutObj>) {
    const rpt = this.getActiveReport(reportId);
    const spec = rpt?.report?.spec;
    if (!spec) return;

    let dtAxis: AxisId | undefined;
    let dtPos: number | undefined;

    const extractBreakoutPositionsForAxis = (layoutAxis: BreakoutObj[], axis: AxisId): string[] => {
      let axisItems = layoutAxis.map((x) => baseId({ id: x.id } as ItemWithId));
      axisItems.reverse(); // Reverse arrays (see getReportLayout() above)
      const pos = axisItems.findIndex((x) => x === DATATYPE_BREAKOUT_ID);
      if (pos >= 0) {
        dtPos = pos;
        dtAxis = axis;
        axisItems = axisItems.filter((x) => x !== DATATYPE_BREAKOUT_ID);
      }
      return axisItems;
    };

    // Reverse and extract datatype axis and position
    const pages = extractBreakoutPositionsForAxis(store.pagesGroup, AxisId.Page);
    const rows = extractBreakoutPositionsForAxis(store.rowsGroup, AxisId.Row);
    const cols = extractBreakoutPositionsForAxis(store.colsGroup, AxisId.Columns);

    // TODO: for now fix total datatypes to same as cells
    const totalDts: string[] = spec.layout.cellDts;
    spec.layout.grandTotalDts = spec.layout.cellDts;

    // Save axis back to report
    spec.layout.pages = pages.map((o) => ({ level: o, totalDts } as LayoutAxis));
    spec.layout.rows = rows.map((o) => ({ level: o, totalDts } as LayoutAxis));
    spec.layout.columns = cols.map((o) => ({ level: o, totalDts } as LayoutAxis));

    // Save datatype position
    spec.layout.dtPosition.axis = dtAxis ?? AxisId.Page;
    spec.layout.dtPosition.index = dtPos ?? 0;

    // Mark as modified + need re-running
    rpt.needsRunning = true;
    rpt.needsSaving = true;
    this.triggerAutoSave(reportId);
  }

  setSelectionTab(reportId: string, tabId: string) {
    const ar = this.getActiveReport(reportId);
    if (ar) {
      ar.selectionTabId = tabId;
    }
  }

  /* Entry point to add a sub-report to an active report.
     Sub-reports are drilldowns, charts etc. that are attached to a cells range in the main report.
     Sub-reports can be editted, but are NOT saved with the main report. An option to convert to a
     'full' report is provided.
     Some sub-reports (e.g. drilldowns) will contain grid results. The results are stored along with
     all other results, in 'layoutResults'.
   */
  attachSubReport(subReportType: SubReportType, title: string, context: CellClickContext, useSelection: boolean) {
    const mainReportId = this.getActiveReportIdFromSubReport(context.reportId);
    if (mainReportId === undefined) return;

    const mainReport = this.getActiveReport(mainReportId);
    if (mainReport === undefined) return;

    const origResult = this.getLayoutResult(context.reportId);
    if (origResult === undefined) return;

    // eslint-disable-next-line no-console
    console.log(`Attach sub-report ${subReportType} to ${context.reportId} page ${context.page} at ${context.section}`);

    const selectedCells = useSelection
      ? ({ ...origResult.selection } as GridSelection)
      : ({
          selPage: context.page,
          selStart: [context.cellColumn, context.cellRow],
          selEnd: [context.cellColumn, context.cellRow],
        } as GridSelection);

    if (selectedCells === undefined) return;

    const subReportId = createNewUUID();

    // Work out a unique title
    const getTitle = (index: number) => (index > 1 ? `${title} (${index})` : subReportType);
    const titles = Array.from(mainReport.subReports.values()).map((sr) => sr.title);
    let index = 1;
    while (titles.includes(getTitle(index))) index += 1;

    // Add as a sub report
    mainReport.subReports.set(subReportId, {
      id: subReportId,
      ownerReportId: mainReportId,
      subReportType,
      title: getTitle(index),
      sourceCells: selectedCells,
      vizData: undefined,
    } as SubReport);

    // Make this sub-report active
    mainReport.activeSubReport = subReportId;

    // Run appropriate sub-report processing
    switch (subReportType) {
      case SubReportType.Drilldown:
        // Drilldown - create via server
        this.runDrilldownReport(context.reportId, origResult.runId ?? '', selectedCells, subReportId);
        break;
      case SubReportType.SpotList:
      case SubReportType.Chart:
        {
          // Save data for vizualisation - work out the best vizualisation to use...
          const sr = mainReport.subReports.get(subReportId);
          if (sr) {
            const vd = this.getGridSelectionsForCells(context.reportId, selectedCells);
            sr.vizData = vd;
            sr.vizOptions = calculateBestVizType(vd);
          }
        }
        break;
      default:
        break;
    }
  }

  getSubReportList(reportId: string): SubReport[] {
    const ar = this.getActiveReport(reportId);
    if (ar?.subReports === undefined) return [];
    return Array.from(ar.subReports.values());
  }

  setActiveSubReport(reportId: string, subReportId: string | undefined) {
    const ar = this.getActiveReport(reportId);
    if (ar === undefined) return;
    ar.activeSubReport = subReportId;
  }

  getActiveSubReportId(reportId: string): string | undefined {
    const ar = this.getActiveReport(reportId);
    if (ar === undefined) return undefined;
    return ar.activeSubReport;
  }

  getSubReport(reportId: string, subReportId: string | undefined): SubReport | undefined {
    const ar = this.getActiveReport(reportId);
    if (ar === undefined) return undefined;
    const id = subReportId ?? ar.activeSubReport;
    return id ? ar.subReports.get(id) : undefined;
  }

  moveToNextSubReport(reportId: string, previous: boolean) {
    const ar = this.getActiveReport(reportId);
    if (ar === undefined) return;
    const ids = Array.from(ar.subReports.keys());
    const activeIndex = ids.indexOf(ar.activeSubReport ?? '');
    if (activeIndex >= 0 && activeIndex < ids.length) {
      let nextIndex = activeIndex + (previous ? -1 : 1);
      if (nextIndex < 0) nextIndex = ids.length - 1;
      if (nextIndex >= ids.length) nextIndex = 0;
      ar.activeSubReport = ids[nextIndex];
    }
  }

  clearSubReports(reportId: string, subReportId: string | undefined) {
    const ar = this.getActiveReport(reportId);
    if (ar === undefined) return;
    const idsToClose = [] as string[];
    if (subReportId === undefined) {
      idsToClose.push(...ar.subReports.keys());
      ar.activeSubReport = undefined; // Back to base report
      ar.subReports.clear();
    } else {
      let ids = Array.from(ar.subReports.keys());
      const activeIndex = ids.indexOf(ar.activeSubReport ?? '');

      ar.subReports.delete(subReportId);
      ids = Array.from(ar.subReports.keys());

      if (activeIndex >= 0 && activeIndex < ids.length) {
        ar.activeSubReport = ids[activeIndex];
      } else if (ids.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        ar.activeSubReport = ids[0];
      } else {
        ar.activeSubReport = undefined;
      }

      idsToClose.push(subReportId);
    }

    // Clear results for any closed sub-reports
    forEach(idsToClose, (id) => this.clearResultsForReport(id));
  }

  async runDrilldownReport(
    sourceReportId: string,
    sourceRunId: string,
    sourceSelectedCells: GridSelection,
    destinationSubReportId: string
  ) {
    const [schemaId, schemaVersion] = this.rootStore.selectionStore.getValidSchemaAndVersion();
    if (schemaId === undefined || schemaVersion === undefined) return;

    this.setReportNeedsRunning(destinationSubReportId);

    const lr = this.getLayoutResult(destinationSubReportId);
    if (lr?.runStatus) lr.runStatus.status = RunStatus.Pending;

    const ignoreLargeWarning = this.getActiveReport(destinationSubReportId)?.ignoreLargeReportWarning ?? false;

    try {
      const drilldownRunId = await reportService.api.runDrilldownReport(
        destinationSubReportId,
        sourceReportId,
        sourceRunId,
        sourceSelectedCells,
        schemaId,
        schemaVersion,
        ignoreLargeWarning
      );
      runInAction(() => {
        const ddlr = this.getLayoutResult(destinationSubReportId);
        if (ddlr) {
          ddlr.runStatus = {
            ...ddlr.runStatus,
            status: RunStatus.Pending,
            message: 'Report queued for processing...',
          } as ReportStatus;

          // Save drilldown runId
          ddlr.runId = drilldownRunId;
          ddlr.currentPage = 0;
        }
      });
    } catch (e) {
      runInAction(() => {
        // If we fail - then close sub-report
        this.clearResultsForReport(destinationSubReportId);
        this.rootStore.uiState.errorAlert = 'Failed to create drilldown';
        this.clearSubReports(sourceReportId, destinationSubReportId);
      });
    }
  }

  /*
   * Get the grid headers and values for a given cell selection.
   * Return a common block of data for any chart/visualisation.
   */
  getGridSelectionsForCells(reportId: string, selectedCells: GridSelection): VizData {
    const activeReport = this.getActiveReport(reportId);
    const reportResult = this.getLayoutResult(reportId);
    const pageResult = this.getPageResult(reportId, selectedCells.selPage);
    const data = new VizData(reportId, activeReport?.report?.name ?? 'Sub Report...', true);
    if (
      reportResult === undefined ||
      reportResult.reportAsRun === undefined ||
      pageResult === undefined ||
      pageResult.colHeader === undefined ||
      pageResult.rowHeader === undefined ||
      selectedCells.selEnd === undefined ||
      selectedCells.selStart === undefined
    )
      return data; // Empty

    const pageDims = reportResult.pageDims ? reportResult.pageDims[selectedCells.selPage] : undefined;

    // Rows have to be offset into the current block (see TODO below)
    const startRow = pageResult?.startRow;
    const offsetIfRow = (pos: number | undefined) => (pos === undefined ? undefined : pos - startRow);

    // Get headers, but limit to the cells selected...
    const getHeaders = (gs: GridSelection, row: boolean): string[][] => {
      const pos = row ? 1 : 0;
      const src = row ? pageResult.rowHeader : pageResult.colHeader;
      if (src === undefined) return [];
      if (
        gs.selStart[pos] === undefined ||
        gs.selEnd[pos] === undefined ||
        gs.selStart[pos] === -1 ||
        gs.selEnd[pos] === -1
      ) {
        // Undef or -1 mean whole row/col
        const r = [] as string[][];
        for (let i = 0; i < src.length; i += 1) {
          for (let j = 0; j < src[i].length; j += 1) {
            r.push([src[i][j]]);
          }
        }
        return _.clone(src);
      }

      let sliceFrom: number | undefined = gs.selStart[pos];
      let sliceTo: number | undefined = gs.selEnd[pos] === undefined ? undefined : (gs.selEnd[pos] as number) + 1;
      if (row) {
        sliceFrom = offsetIfRow(sliceFrom);
        sliceTo = offsetIfRow(sliceTo);
      }

      return _.clone(src.slice(sliceFrom, sliceTo));
    };

    // TODO: this currently will only work for the 'active' virtual block of data
    // If we have a full column selected, we will not have all data locally to chart.
    // We will need to fetch the full column data from the server - either from the main
    // api or a custom api. Can do this here, but this method will have to be async, and
    // we'll have to show progress...
    // The same goes for a block of selections that crosses the block boundary, though
    // this is less likely to happen, as it is tricky for the user to select cells this way.
    // Drilldowns will not have this issue as they are generated in the server.

    // Get headers - take full row select into account
    const colHeaders = getHeaders(selectedCells, false);
    const columnLevels = getAxisLevelsWithDatatypes(reportResult.reportAsRun.layout, AxisId.Columns);

    const rowHeaders = getHeaders(selectedCells, true);
    const rowLevels = getAxisLevelsWithDatatypes(reportResult.reportAsRun.layout, AxisId.Row);

    if (pageResult.body === undefined) return data; // Empty

    data.setColumns(colHeaders, columnLevels);
    data.setRows(rowHeaders, rowLevels);

    const pageLevels = getAxisLevelsWithDatatypes(reportResult.reportAsRun.layout, AxisId.Page);
    data.setPages(pageDims?.levels ?? [], pageLevels ?? []);

    // Get cells, but limit to the cells selected...
    const allRows =
      selectedCells.selStart[1] === undefined ||
      selectedCells.selEnd[1] === undefined ||
      selectedCells.selStart[1] === -1 ||
      selectedCells.selEnd[1] === -1;
    const allCols =
      selectedCells.selStart[0] === undefined ||
      selectedCells.selEnd[0] === undefined ||
      selectedCells.selStart[0] === -1 ||
      selectedCells.selEnd[0] === -1;
    const wantedRows = allRows
      ? pageResult.body
      : pageResult.body?.slice(
          offsetIfRow(selectedCells.selStart[1]),
          offsetIfRow((selectedCells.selEnd[1] as number) + 1)
        );
    const rawCells = wantedRows.map((row) =>
      allCols ? row.cells : row.cells.slice(selectedCells.selStart[0], (selectedCells.selEnd[0] as number) + 1)
    );

    // Extract data to values
    const cellsAsNumbers = rawCells.map((row) =>
      row.map((cell) => {
        const val = Number.parseFloat(cell.value) / 100.0; // TODO: use datatypes multiplier here....
        return Number.isNaN(val) ? 0 : val;
      })
    );

    // TODO: Add datatypes to data...

    data.setCells(cellsAsNumbers);

    return data;
  }

  getPercentComplete(reportId: string | undefined): number | undefined {
    return this.getLayoutResult(reportId)?.pcComplete;
  }

  getReportSettings(reportId: string): AppSettings {
    const ar = this.getActiveReport(reportId);
    return ar?.report?.spec?.reportSettings ?? EmptySettings;
  }

  updateReportSettings(reportId: string, settings: AppSettings) {
    const ar = this.getActiveReport(reportId);
    const existingSettings = ar?.report?.spec?.reportSettings;
    if (existingSettings === undefined || settingsAreSame(existingSettings, settings)) return;
    if (ar?.report !== undefined) {
      ar.report.spec.reportSettings = settings;
      ar.needsSaving = true;
      this.triggerAutoSave(reportId);
    }
  }
}

export default ActiveReportStore;
