import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useState } from 'react';
import _ from 'lodash';
import { RootContext } from '../../stores/storeProvidor';
import EditLabel from '../controls/EditLabel';
import CheckBox from '../controls/CheckBox';

const EditRoleModal = NiceModal.create(({ roleId }: { roleId: string }) => {
  const rootStore = useContext(RootContext);
  const { uiState } = rootStore;
  const adminStore = rootStore.getAdminStore();
  const modal = useModal();

  const [initialRole] = useState(adminStore.findRoleById(roleId));
  const [role, setRole] = useState(adminStore.findRoleById(roleId));

  const isModified = !_.isEqual(role, initialRole);

  const availablePermissions = adminStore.permissions;

  if (role === undefined) return null;

  const onOk = async () => {
    try {
      await adminStore.updateRoles([role]);
      modal.remove();
      uiState.successAlert = 'Role changes saved';
    } catch (e) {
      uiState.errorAlert = 'Failed to save changed!';
    }
  };

  const onCancel = () => {
    modal.remove();
  };

  const currentPerms = role.permissions.map((p) => p.access);

  return (
    <Modal show onClose={onCancel}>
      <Modal.Header>Edit Role: {role.name}</Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          <div className="text-lg font-bold">{role.name}</div>
          <div className="italic font-light">Organisation:</div>
          <div>{role?.organisation?.name ?? 'Unknown'}</div>
          <div className="italic font-light">Description:</div>
          <EditLabel
            id="desc"
            value={role.description ?? 'None'}
            onChange={(desc: string) => {
              role.description = desc;
              setRole(role);
            }}
          />
          <div className="italic font-light">Permissions:</div>
          <div className="bg-gray-100 border border-gray-400 p-2 h-64 mx-auto overflow-auto">
            <table className="table-auto">
              <tbody>
                {availablePermissions.map((p) => (
                  <tr key={p.access} className="w-full">
                    <td>
                      <CheckBox
                        id={p.access}
                        disabled={false}
                        checked={currentPerms.includes(p.access)}
                        onChange={(f) => {
                          if (f) {
                            role.permissions.push(p);
                          } else {
                            role.permissions = role.permissions.filter((o) => o.access === p.access);
                          }
                          setRole(role);
                        }}
                      />
                    </td>
                    <td>
                      <div className="font-medium text-gray-900 px-2">{p.access}</div>
                    </td>
                    <td>
                      <div className="font-light text-sm">{p.description}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onOk} disabled={!isModified}>
          Save
        </Button>
        <Button color="gray" onClick={onCancel}>
          {isModified ? 'Discard' : 'Close'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default EditRoleModal;
