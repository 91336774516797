import { makeAutoObservable, runInAction } from 'mobx';
import _ from 'lodash';
import { User } from '../models/userModel';
import type RootStore from './rootStore';
import { AppSettings, SettingsLevel, getSettingsKeys, getSettingsValue, setSettingsValue } from '../models/settings';
import settingsService from '../services/settingsService';
import Perms from '../permissions/permissions';
import { simpleHash } from '../utils/helpers';

class ActiveUserStore {
  private rootStore: RootStore;

  user?: User | undefined = undefined;

  allSettings: AppSettings[];

  settingsModified: boolean;

  constructor(root: RootStore) {
    this.rootStore = root;

    this.allSettings = [];
    this.settingsModified = false;

    makeAutoObservable(this);
  }

  setUser(newUser: User) {
    this.user = newUser;
  }

  clearUser() {
    this.user = undefined;
  }

  get getUser() {
    return this.user;
  }

  get userId() {
    return this.user?.id;
  }

  get isAuthenticated() {
    return this.user !== undefined;
  }

  get isSuperAdmin() {
    if (this.user === undefined) return false;
    return Perms.HasPermission(this.user.permissions, Perms.SuperAdmin);
  }

  hasPermission(perm: string | string[]): boolean {
    if (this.user === undefined) return false;
    return Perms.HasPermission(this.user.permissions, perm);
  }

  async refreshSettings() {
    try {
      const respone = await settingsService.api.getSettings();
      runInAction(() => {
        // TEMP
        // respone[0].currentSchema = 'aumonthly';
        // TEMP

        this.allSettings = respone;
      });
    } catch (e) {
      runInAction(() => {
        this.allSettings = [];
      });
    }
  }

  async saveSettingsLevel(level: SettingsLevel = SettingsLevel.UserLevel, reportId: string | undefined = undefined) {
    const settingsLevel = _.cloneDeep(this.allSettings[level]);
    if (level === SettingsLevel.ReportLevel && reportId !== undefined) {
      // Report settings saved to active-report only
      // Note this assumes that this.allSettings[0] has been updated prior to this call...
      this.rootStore.activeReportStore.updateReportSettings(reportId, settingsLevel);
      return;
    }

    // User/Org/Global are saved via API
    await settingsService.api.saveSettings(level, settingsLevel);
  }

  getLocale(reportId: string | undefined = undefined): string {
    return this.getSetting('locale', reportId) ?? 'en-AU';
  }

  get activeSchemaId(): string | undefined {
    return this.getSetting('currentSchema');
  }

  get activeSchemaVersion(): string | undefined {
    return this.getSetting('currentSchemaVersion');
  }

  get activeSchemaTag(): string {
    return `${this.activeSchemaId}:${this.activeSchemaVersion}`;
  }

  setSchemaAndVersion(schemaId: string, version: string | undefined) {
    this.updateSetting('currentSchemaVersion', version ?? 'Latest', SettingsLevel.UserLevel, false);
    this.updateSetting('currentSchema', schemaId, SettingsLevel.UserLevel);
  }

  /*
   Access a setting by name
   - If reportId is provided, we'll check from the report level up.
   - If reportId is not provided, we'll check from the user level up.
   */
  getSetting(tag: keyof AppSettings, reportId: string | undefined = undefined): string | undefined {
    let levels = this.allSettings;
    if (reportId !== undefined) {
      // Copy report settings to levels before we check value
      levels = [this.rootStore.activeReportStore.getReportSettings(reportId), ...this.allSettings.slice(1)];
    }
    for (let level = 0; level < levels.length; level += 1) {
      const val = getSettingsValue(levels[level], tag);
      if (val !== undefined) return val;
    }
    return undefined;
  }

  getSettingLevels(reportId: string | undefined): AppSettings[] {
    if (reportId === undefined) return this.allSettings;
    const reportSettings = this.rootStore.activeReportStore.getReportSettings(reportId);
    return [reportSettings, ...this.allSettings.slice(1)];
  }

  // Update individual setting (not report level)
  async updateSetting(tag: keyof AppSettings, value: string | undefined, level: SettingsLevel, doSave = true) {
    if (level === SettingsLevel.ReportLevel) throw new Error('Cannot update ReportLevel via updateSetting');
    this.settingsModified = setSettingsValue(this.allSettings[level], tag, value);
    if (this.settingsModified && doSave) {
      await this.saveSettingsLevel(level, undefined);
      runInAction(() => {
        this.settingsModified = false;
      });
    }
  }

  // Update all (eg from dialog)
  async updateSettingLevels(settings: AppSettings[], levels: SettingsLevel[], reportId: string | undefined) {
    for (let i = 0; i < levels.length; i += 1) {
      this.allSettings[levels[i]] = settings[levels[i]];
    }
    await Promise.all(levels.map((i) => this.saveSettingsLevel(i, reportId)));
    runInAction(() => {
      this.settingsModified = false;
    });
  }

  getSettingsHash(reportId: string | undefined): number {
    const s = this.getSettingLevels(reportId)
      .map((x) =>
        getSettingsKeys()
          .map((key) => (x[key] !== null ? x[key] : ''))
          .join('')
      )
      .join(';');
    return simpleHash(s);
  }

  getCurrencyFormat(
    reportId: string | undefined = undefined,
    overrideSettings: AppSettings | undefined = undefined
  ): Intl.NumberFormat {
    const locale = overrideSettings?.locale ?? this.getLocale(reportId);
    const currency = overrideSettings?.currency ?? this.getSetting('currency', reportId) ?? 'AUD';
    const dp = parseInt(
      overrideSettings?.currencyDecimalPlaces ?? this.getSetting('currencyDecimalPlaces', reportId) ?? '2',
      10
    );

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol', // 'narrowSymbol', 'code'
      maximumFractionDigits: dp,
      currencySign: 'standard', // or 'accounting' - show -ve as ($3,234)
      signDisplay: 'auto',
    });
  }

  getNumberFormat(
    reportId: string | undefined = undefined,
    overrideSettings: AppSettings | undefined = undefined
  ): Intl.NumberFormat {
    const locale = overrideSettings?.locale ?? this.getLocale(reportId);
    const dp = parseInt(
      overrideSettings?.otherDecimalPlaces ?? this.getSetting('otherDecimalPlaces', reportId) ?? '2',
      10
    );

    return new Intl.NumberFormat(locale, {
      style: 'decimal',
      maximumFractionDigits: dp,
      signDisplay: 'auto',
    });
  }

  getPercentFormat(
    reportId: string | undefined = undefined,
    overrideSettings: AppSettings | undefined = undefined
  ): Intl.NumberFormat {
    const locale = overrideSettings?.locale ?? this.getLocale('locale');
    const dp = parseInt(
      overrideSettings?.otherDecimalPlaces ?? this.getSetting('otherDecimalPlaces', reportId) ?? '2',
      10
    );

    return new Intl.NumberFormat(locale, {
      style: 'percent',
      maximumFractionDigits: dp,
      signDisplay: 'auto',
    });
  }

  formatNumber(value: number, dp = 2, reportId: string | undefined = undefined): string {
    const locale = this.getLocale(reportId);
    return value.toLocaleString(locale, { minimumFractionDigits: dp });
  }
}

export default ActiveUserStore;
