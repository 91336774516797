/* eslint-disable react/no-array-index-key */
import { Tooltip } from 'flowbite-react';
import getTagColours from '../../utils/tagcolours';
import { capitalizeWords, concatValues } from '../../utils/helpers';

interface TagListProps {
  tags: string[];
  moreLimit: number;
  className?: string | undefined;
  onClick?: (tag: string) => void;
}

export default function TagList({ tags, moreLimit, className, onClick }: TagListProps) {
  let baseStyle = 'text-xs font-medium mr-2 px-2.5 py-0.5 rounded w-8';
  if (className !== undefined) baseStyle = `${className} ${baseStyle}`;

  if (tags && tags.length > 0) {
    if (tags.length > moreLimit) {
      return (
        <Tooltip
          content={concatValues(
            tags.map((t) => capitalizeWords(t)),
            true
          )}
        >
          <span
            className={`bg-gray-300 text-gray-800  ${baseStyle}`}
            onClick={() => {
              if (onClick !== undefined) onClick('');
            }}
            onKeyDown={() => {
              if (onClick !== undefined) onClick('');
            }}
            role="button"
            tabIndex={0}
          >
            +{moreLimit}
          </span>
        </Tooltip>
      );
    }

    return (
      <>
        {tags.map((t, i) => (
          <span
            key={`${t}-${i}`}
            className={`${getTagColours(t)} ${baseStyle}`}
            onClick={() => {
              if (onClick !== undefined) onClick(t);
            }}
            onKeyDown={() => {
              if (onClick !== undefined) onClick(t);
            }}
            role="button"
            tabIndex={0}
          >
            {t}
          </span>
        ))}
      </>
    );
  }

  return <span />;
}

TagList.defaultProps = {
  className: undefined,
  onClick: undefined,
};
