import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { SelectionTreeItemDragData } from '../../../../hooks/useSelectionDragging';
import { Draggable } from '../../../controls/dnd';

interface SelectAcordianDragHandleProps {
  id: string;
  dragData: SelectionTreeItemDragData;
}

export default function SelectAcordianDragHandle({ id, dragData }: SelectAcordianDragHandleProps) {
  return (
    <Draggable key={id} id={id} data={dragData} useOverlay className="inline">
      {/* 
        NOTE: if the <div> and &nbsp below are removed, the dragging seems to fail 
        to register correctly with dndKit, and the dragged item cannot be dropped.
        I'm not sure why this is, but basically an SVG icon by itself does not seem
        to work as the 'active' item...
        */}
      <div>
        &nbsp;
        <ArrowRightOnRectangleIcon className="h-6 w-6 float-right text-gray-400 hover:text-gray-700" />
      </div>
    </Draggable>
  );
}
