import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useDateFormat } from '../../../hooks/useFormat';
import { ReportActivity } from '../models/Activity';
import { AdminPanel } from './AdminList';
import UserAdminLink from './UserAdminLink';

type AdminReportActivityProps = {
  activity: ReportActivity[] | undefined;
  doRefresh: () => void;
};

export default function AdminReportActivity({ activity, doRefresh }: AdminReportActivityProps) {
  const { formatDate, formatTime, formatDateTime } = useDateFormat();
  const lastTime = activity?.sort((a, b) => b.timestamp.diff(a.timestamp).milliseconds)[0]?.timestamp;

  return (
    <AdminPanel>
      <div className="flex-none text-sm font-bold h-6 text-left px-4">Updated At</div>
      <div className="grow w-64">
        {formatDateTime(lastTime, true)}
        <ArrowPathIcon className="h-5 text-gray-500 float-right hover:text-blue-700" onClick={doRefresh} />
      </div>
      <div className="basis-96" />
      <div className="h-64 grow overflow-auto grid grid-flow-row-dense grid-cols-5">
        {activity?.map((ra, i) => {
          const zeb = i % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100';
          return (
            <div key={`${ra.runId}:ara`} className="">
              <div className={`p-2 col-span-1 text-xs ${zeb}`}>
                <div>
                  {`${formatDate(ra.timestamp, 's')}`}
                  <br />
                  {`@ ${formatTime(ra.timestamp, true)}`}
                </div>
              </div>
              <div className={`p-2 col-span-2 ${zeb}`}>
                <div>{`Days: ${ra.daysCovered}`}</div>
                <div>{`Cardinality: ${ra.cardinality}`}</div>
                <div>{`Blocks: ${ra.blockCount}`}</div>
                <div>{`Run Time: ${ra.runDuration}`}</div>
              </div>
              <div className={`p-2 col-span-2 ${zeb}`}>
                <div>
                  Status:{' '}
                  <span
                    className={
                      ra.runStatus === 'Failed'
                        ? 'bg-red-400 rounded-lg px-2 text-xs animate-pulse'
                        : 'bg-green-300 rounded-lg px-2 text-xs font-bold'
                    }
                  >{`${ra.runStatus}`}</span>
                </div>
                <div>
                  User: <UserAdminLink uid={ra.callerId} name={ra.callerName} />
                </div>
                <div>{`Schema: ${ra.schema}`}</div>
              </div>
            </div>
          );
        })}
      </div>
    </AdminPanel>
  );
}
