import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { RootContext } from '../../stores/storeProvidor';
import CopyToClipboardButton from '../controls/CopyToClipboardButton';
import { formatDateTime } from '../../utils/dates';

export type ReportInfoModalProps = {
  reportId: string;
};

const ReportInfoModal = NiceModal.create(({ reportId }: ReportInfoModalProps) => {
  const { activeReportStore, activeUserStore } = useContext(RootContext);
  const modal = useModal();

  const onCancel = () => {
    modal.remove();
  };

  const report = activeReportStore.getActiveReport(reportId);
  if (report?.report === undefined) return null;

  return (
    <Modal show onClose={onCancel}>
      <Modal.Header>
        <InformationCircleIcon className="h-8 inline mr-2" />
        Report Info
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-wrap">
          <div className="font-bold w-36 text-right mr-2">Report Id:</div>
          <div className="grow">{reportId}</div>
          <CopyToClipboardButton textToCopy={reportId} onClick={onCancel} />

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Owner Id:</div>
          <div className="grow">{report.report.owner ?? '?'}</div>
          <CopyToClipboardButton textToCopy={report.report.owner ?? '?'} onClick={onCancel} />

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Your User Id:</div>
          <div className="grow">{activeUserStore.userId ?? '?'}</div>
          <CopyToClipboardButton textToCopy={report.report.owner ?? '?'} onClick={onCancel} />

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Access:</div>
          <div className="grow">{report.report.access ?? '?'}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Report Type:</div>
          <div className="grow">{report.report.spec.reportType ?? '?'}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Read Only:</div>
          <div className="grow">{report.report.isReadOnly ? 'Yes' : 'No'}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Modified:</div>
          <div className="grow">{formatDateTime(report.report.modified, activeUserStore.getLocale(reportId))}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={onCancel}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ReportInfoModal;
