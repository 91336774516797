import { PropsWithChildren } from 'react';

export function GridCard({ children }: PropsWithChildren) {
  return (
    <div className="my-2 ml-2 py-3 px-2 row-span-5 col-span-1 bg-white border border-gray-300 rounded-lg shadow">
      {children}
    </div>
  );
}

export function GridCardHalf({ children }: PropsWithChildren) {
  return (
    <div className="my-2 ml-2 py-3 px-2 row-span-2 col-span-1 bg-white border border-gray-300 rounded-lg shadow">
      {children}
    </div>
  );
}
