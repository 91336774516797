import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowsPointingInIcon, ChartBarIcon, ChartPieIcon } from '@heroicons/react/24/outline';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { DateTime } from 'luxon';
import { RootContext } from '../../../stores/storeProvidor';
import BaseResultGrid, {
  CellMouseEvent,
  GridInfo,
  GridOptions,
  GridSection,
  getBaseCellStyle,
} from '../../controls/table/BaseResultGrid';
import { FormatNumberStyles, useDateFormat, useNumberFormat } from '../../../hooks/useFormat';
import useHeaderExtract from '../../../hooks/useHeaderExtraction';
import { ContextSeparator, useContextMenu } from '../../global/ContextMenu';
import { ContextMenuEntry } from '../../../stores/uiStateStore';
import { CellClickContext } from '../../../stores/activeReportStore';
import { SubReportType } from './subReports/SubReportBase';
import useTimeout from '../../../hooks/useTimeout';
import RowScrollOffsetStore, { BufferedRowResult } from '../../../stores/rowScrollOffsetStore';
import Spinner from '../../controls/Spinner';
import { SpotFieldDefintions, SpotListResult, SpotRowDetails } from '../../../models/reportModel';

const defaultGridOptions: GridOptions = {
  debugGrid: false,
  showRowIdColumn: true,
};

type SpotColumnDef = ColumnDef<SpotRowDetails, unknown>;

interface SpotGridProps {
  reportId: string | undefined;
  options: GridOptions | undefined;
}

const SpotListResultGrid = observer(({ reportId, options }: SpotGridProps) => {
  const {
    activeSpotStore,
    activeReportStore,
    activeUserStore,
    rowScrollOffsetStore: rowQueueStore,
  } = useContext(RootContext);
  const { formatDatatype } = useNumberFormat(reportId);
  const { formatDate, formatTime, formatDateTime } = useDateFormat(reportId);
  const extractHeaderLabel = useHeaderExtract(reportId);

  const scrollDebounceTimout = 100;

  const showSingleRowSelectionOnly = (context: unknown) => {
    const ctx = context as CellClickContext;
    return !activeReportStore.isGridCellSelected(ctx.reportId, 0, undefined, ctx.cellRow);
  };

  const showHasSelection = (context: unknown) => {
    const ctx = context as CellClickContext;
    return activeReportStore.hasGridSelection(ctx.reportId, 0);
  };

  const menu = [
    {
      label: 'Show Distribution Chart',
      action: (action, context) => { },
      Icon: ArrowsPointingInIcon,
      show: (context) => showSingleRowSelectionOnly(context as CellClickContext),
    },
    ContextSeparator,
    {
      label: 'Chart',
      action: (action, context) =>
        activeReportStore.attachSubReport(SubReportType.Chart, 'Chart', context as CellClickContext, true),
      Icon: ChartPieIcon,
      show: (context) => showHasSelection(context as CellClickContext),
    },
    {
      label: 'Copy Spot Details',
      action: (action, context) => { },
      Icon: ChartBarIcon,
      show: true,
    },
  ] as ContextMenuEntry[];
  const { showMenu } = useContextMenu(menu);

  const spotResult = useMemo(
    () =>
      activeSpotStore.getReportSpotResult(reportId ?? 'unknown') ??
      ({
        spotDef: {
          schema: 'unknown',
          fields: [],
        } as SpotFieldDefintions,
        fieldOrder: [],
        spots: [],
        startRow: 0,
        rowCount: 0,
        totalRows: 0,
      } as SpotListResult),
    [activeSpotStore, reportId]
  );

  const fieldPositionMap = useMemo(
    () => new Map(spotResult.spotDef.fields.map((field, index) => [field.tag, index])),
    [spotResult]
  );

  // Virtualise the scrolling...
  const scrollerRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: spotResult.totalRows,
    getScrollElement: () => scrollerRef.current,
    estimateSize: () => 32, // TODO: How do we calc this? Row height
    overscan: 6,
    paddingEnd: 30, // TODO: How do we calc this? Header height
  });

  const onCellClick = useCallback(
    (
      section: GridSection,
      colindex: number | undefined,
      rowindex: number | undefined,
      clickEvent: CellMouseEvent,
      isContext: boolean
    ) => {
      if (reportId !== undefined) {
        if (isContext) {
          showMenu(clickEvent.pageX, clickEvent.pageY, {
            reportId,
            page: 0,
            section,
            cellColumn: colindex,
            cellRow: rowindex,
          } as CellClickContext);
        } else {
          // Select single cell
          activeReportStore.setGridSelection(reportId, 0, section, undefined, rowindex, clickEvent.shiftKey);
        }
      }
    },
    [reportId, showMenu, activeReportStore]
  );

  const getCellStyle = useCallback(
    (section: GridSection, colindex: number | undefined, rowindex: number | undefined) => {
      const isSelected = activeReportStore.isGridCellSelected(reportId, 0, undefined, rowindex);
      return getBaseCellStyle(section, isSelected, true);
    },
    [reportId, activeReportStore]
  );

  // eslint-disable-next-line arrow-body-style
  const gridInfo = useMemo(() => {
    return {
      colHeaderLevels: 1,
      rowHeaderLevels: 0,
      mergeRowHeaders: true, // false for 'indent' style
      pageId: 0,
      onGridClick: onCellClick,
      getStyle: getCellStyle,
      rowBaseOffset: spotResult.startRow ?? 0,
      options: options ?? defaultGridOptions,
    } as GridInfo;
  }, [onCellClick, getCellStyle, spotResult.startRow, options]);

  // Draw content of a cell. Value depends on 'index' along header and data-type
  const cellAccessor = useCallback(
    (row: SpotRowDetails, fieldName: string): string | undefined => {
      const fieldIndex = fieldPositionMap.get(fieldName);
      if (fieldIndex !== undefined) {
        const cellValue = row.fields[fieldIndex];
        const field = spotResult.spotDef.fields[fieldIndex];

        // Dates and times
        if (field.format === 'datetime' || field.format === 'time' || field.format === 'date') {
          const d = DateTime.fromISO(cellValue);
          if (field.format === 'time') return formatTime(d, true);
          if (field.format === 'date') return formatDate(d, 's');
          return formatDateTime(d, true);
        }

        if (field.format === 'label') {
          return extractHeaderLabel(cellValue);
        }

        // Numbers
        return formatDatatype(cellValue, field?.meta?.divisor ?? 1, field.format as FormatNumberStyles);
      }

      return undefined;
    },
    [
      extractHeaderLabel,
      fieldPositionMap,
      formatDatatype,
      formatDate,
      formatDateTime,
      formatTime,
      spotResult.spotDef.fields,
    ]
  );

  const getColumns: () => SpotColumnDef[] = useCallback(() => {
    if (spotResult.spotDef === undefined) {
      return [] as SpotColumnDef[];
    }

    let fieldOrder = [...(spotResult.fieldOrder ?? [])];
    if (gridInfo.options?.showRowIdColumn) {
      fieldOrder = ['index', ...fieldOrder];
    }

    // eslint-disable-next-line arrow-body-style
    const cols = fieldOrder.map((f, i) => {
      return {
        id: `C${i}`,
        header: spotResult.spotDef.fields.filter((g) => g.tag === f)[0]?.name ?? 'Index',
        accessorFn: (row) => {
          if (row.index - spotResult.startRow >= spotResult.spots.length) return '?';
          return cellAccessor(row, f) ?? `${row.index - spotResult.startRow}`;
        },
        meta: { headerColIndex: undefined, colIndex: i, field: f, rowHeaderSpanFn: undefined },
      } as SpotColumnDef;
    });

    return cols;
  }, [
    cellAccessor,
    gridInfo.options?.showRowIdColumn,
    spotResult.fieldOrder,
    spotResult.spotDef,
    spotResult.spots.length,
    spotResult.startRow,
  ]);

  // The hash changes when the selection/settings are modified - so causes a redraw on each change
  const selectionHash =
    activeReportStore.refreshGridSelectionHash(reportId) + activeUserStore.getSettingsHash(reportId);
  const qStoreId = RowScrollOffsetStore.getId(reportId ?? 'unknown', 0);

  const [showLastBlockProgress, setShowLastBlockProgress] = useState<boolean>(false);

  // Timer used to debounce row requests generated by scrolling.
  // We wait for timer to expire after last scroll event before loading data.
  const [retriggerRowLoadDebounceTimer] = useTimeout(async () => {
    const { from, to } = rowQueueStore.getCurrentRowRange(qStoreId);
    if (from !== undefined && to !== undefined && reportId !== undefined) {
      // Load the new row range...
      setShowLastBlockProgress(true);
      try {
        await activeReportStore.loadReportDetails(reportId, 0, from, false, false, false);

        if (options?.debugGrid) {
          const rs = rowQueueStore.getRowSpec(qStoreId);
          // eslint-disable-next-line no-console
          console.log('Loaded Rows:', from, to, rs?.startRow, rs?.rowCount, rs?.totalRows, rs?.requestedRow);
        }
      } finally {
        setShowLastBlockProgress(false);
      }
    }
  }, scrollDebounceTimout);

  // Map each virtual row to the data in memory
  const mapVirtualRow = useCallback(
    (vx: VirtualItem) => {
      // Index is the unique position of row in the overall dataset
      const rowIndex = vx.index;

      // Offset is the position of the row in the memory dataset
      const rowDataOffset = vx.index - (spotResult.startRow ?? 0);

      // Check if we need to load the row - if so trigger timer for load, and show progress indicator
      const isLoading = rowQueueStore.requestRowIndex(qStoreId, rowIndex, retriggerRowLoadDebounceTimer);
      return { rowIndex, rowDataOffset, isLoading };
    },
    [qStoreId, retriggerRowLoadDebounceTimer, rowQueueStore, spotResult.startRow]
  );

  const rowLoadHash = `${spotResult.totalRows}`;
  const [loadHash, setLoadHash] = useState<string>('');
  useEffect(() => {
    if (rowLoadHash !== loadHash) {
      setLoadHash(rowLoadHash);
      if (options?.debugGrid)
        // eslint-disable-next-line no-console
        console.log('Setting limits for:', spotResult.startRow, spotResult.spots.length, rowLoadHash);
      rowQueueStore.setLimits(qStoreId, spotResult as BufferedRowResult);
    }
  }, [rowQueueStore, rowLoadHash, loadHash, qStoreId, options?.debugGrid, spotResult]);

  // Nothing to show? - parent component should be showing progress...
  if (spotResult === undefined) {
    return <span />;
  }

  return (
    <>
      <div className="p-1 overflow-scroll overscroll-auto" ref={scrollerRef}>
        {/*
        This <div> is dynamically sized by the virtualizer to scale the scroll bar
        (vertical only) for the TOTAL number of rows in the result set.
        */}
        <div style={{ height: `${virtualizer.getTotalSize()}px` }}>
          <BaseResultGrid
            data={spotResult.spots}
            columnFn={getColumns}
            info={gridInfo}
            refreshHash={`${selectionHash}`}
            virtualContext={{ virtualizer }}
            mapVirtualRowToData={mapVirtualRow}
          />
        </div>
      </div>
      <Spinner
        enable={showLastBlockProgress}
        text="Fetching spots..."
        onClick={() => {
          setShowLastBlockProgress(false);
        }}
      />
    </>
  );
});

export default SpotListResultGrid;
