import * as React from 'react';

export enum CheckboxState {
  Unchecked = 0,
  Checked = 1,
  IndeterminateHalf = 2,
  CheckedOutline = 3,
  IndeterminateFull = 4,
  BlankFilled = 5,
  Offline = 6,
  MultipleChecked = 7,
}

interface IconCheckboxProps extends React.SVGProps<SVGSVGElement> {
  checked: CheckboxState;
}
const checkboxStyle = 'h-4 w-4 inline';
const icon = [
  // checkbox-blank-outline | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-0">
    <path d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2m0 2v14H5V5h14z" />
  </svg>,
  // checkbox-marked | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-1">
    <path d="M10 17l-5-5 1.41-1.42L10 14.17l7.59-7.59L19 8m0-5H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2z" />
  </svg>,
  // checkbox-intermediate-variant | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-2">
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 16H5V5h14v14M7 17V7h10" />
  </svg>,
  // checkbox-outline | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-3">
    <path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2m0 2v14H5V5h14m-9 12l-4-4 1.41-1.42L10 14.17l6.59-6.59L18 9" />
  </svg>,
  // checkbox-intermediate | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-4">
    <path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2m0 16H5V5h14v14m-2-2H7V7h10v10z" />
  </svg>,
  // checkbox-blank | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-5">
    <path d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2z" />
  </svg>,
  // checkbox-blank-off-outline | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-6">
    <path d="M22.11 21.46L2.39 1.73 1.11 3 3 4.9V19a2 2 0 002 2h14.1l1.74 1.73 1.27-1.27M5 19V6.89L17.11 19H5M8.2 5l-2-2H19a2 2 0 012 2v12.8l-2-2V5H8.2z" />
  </svg>,
  // checkbox-multiple-marked | https://reactsvgicons.com/search?q=checkbox
  <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" className={checkboxStyle} key="cb-7">
    <path d="M22 16a2 2 0 01-2 2H8a2 2 0 01-2-2V4c0-1.11.89-2 2-2h12a2 2 0 012 2v12m-6 4v2H4a2 2 0 01-2-2V7h2v13h12m-3-6l7-7-1.41-1.41L13 11.17 9.91 8.09 8.5 9.5 13 14z" />
  </svg>,
];

function IconMultistateCheckbox(props: IconCheckboxProps) {
  const { checked } = props;
  return icon[checked];
}

export default IconMultistateCheckbox;
