/* eslint-disable import/prefer-default-export */
import { AxisId, ReportLayout } from '../models/reportModel';
import { DATATYPE_LEVEL_TAG } from '../stores/selectionStore';
import { insertAtIndex } from './dragUtils';

/*
 * Get the levels for a given axis. If the axis is the datatype axis then
 * insert the datatype level at the correct position
 */
export function getAxisLevelsWithDatatypes(layout: ReportLayout, axis: AxisId): string[] {
  let levels = [] as string[];

  if (axis === AxisId.Columns) levels = layout.columns.map((l) => l.level) ?? [];
  else if (axis === AxisId.Row) levels = layout.rows.map((l) => l.level) ?? [];
  else levels = layout.pages.map((l) => l.level) ?? [];

  if (axis === layout.dtPosition.axis) levels = insertAtIndex(levels, layout.dtPosition.index, DATATYPE_LEVEL_TAG);

  return levels;
}
