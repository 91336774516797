import { Bars4Icon } from '@heroicons/react/24/outline';
import { Tooltip } from 'flowbite-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Placement } from '@floating-ui/core';
import { IconObject } from '../../utils/types';

interface TopMenuProps {
  toggleSideMenuFn: () => void;
  children: ReactNode;
  className?: string;
}

export default function TopMenu({ toggleSideMenuFn, children, className }: TopMenuProps) {
  return (
    <nav className={`fixed top-0 left-0 flex h-12 w-screen border-b overflow-hidden ${className}`}>
      <button type="button" className="mx-4 h-12" aria-label="Open Side Menu" onClick={toggleSideMenuFn}>
        <Bars4Icon className="w-8 h-8" />
      </button>
      {children}
    </nav>
  );
}

TopMenu.defaultProps = {
  className: 'bg-white text-gray-600',
};

// -----------------------------------------------------------------------

interface TopMenuItemPropsWithTip extends TopMenuItemProps {
  tip?: string | undefined;
  tipPlacement?: 'auto' | Placement | undefined;
}

export function TopMenuButton({ clickFn, text, Icon, navTo, tip, tipPlacement }: TopMenuItemPropsWithTip) {
  const navigate = useNavigate();

  const doClick = () => {
    if (clickFn) clickFn();
    if (navTo) navigate(navTo);
  };

  const button = (
    <button type="button" className="flex items-center bg-green-100 h-8 rounded-md px-2 w-auto" onClick={doClick}>
      {Icon === undefined ? '' : <Icon className="h-8 w-8 pr-1" />}
      <span className="text-md font-medium">{text}</span>
    </button>
  );

  return (
    <div className="flex grow-0 items-center px-1 text-gray-600">
      {tip ? (
        <Tooltip content={tip} placement={tipPlacement ?? 'auto'} animation="duration-1000">
          {button}
        </Tooltip>
      ) : (
        <span>{button}</span>
      )}
    </div>
  );
}

TopMenuButton.defaultProps = {
  Icon: undefined,
  text: '',
  clickFn: undefined,
  navTo: undefined,
  tip: undefined,
  tipPlacement: 'left',
};

// -----------------------------------------------------------------------

interface TopMenuItemProps {
  clickFn?: () => void;
  text?: string;
  Icon?: IconObject | undefined;
  navTo?: string;
}

export function TopMenuMessage({ clickFn, text, Icon, navTo }: TopMenuItemProps) {
  const navigate = useNavigate();

  const doClick = () => {
    if (clickFn) clickFn();
    if (navTo) navigate(navTo);
  };

  return (
    <div className="flex grow justify-center mx-2 overflow-clip h-12">
      <div
        className="flex justify-center items-center h-10 mt-1 font-semibold w-1/2"
        onClick={doClick}
        onKeyDown={doClick}
        role="button"
        tabIndex={0}
      >
        {Icon === undefined ? '' : <Icon className="h-8 w-8 pr-1" />}
        {text}
      </div>
    </div>
  );
}

TopMenuMessage.defaultProps = {
  Icon: undefined,
  text: '',
  clickFn: undefined,
  navTo: undefined,
};

// -----------------------------------------------------------------------

export function TopMenuGridFiller() {
  // Reserve space for the <TopMenu> item. Height here must match TopMenu
  // First row of grid must match this height too. Note: h-12 = 3rem = 48px
  // This MUST be first item in grid
  return <div className="col-span-full h-12" />;
}
