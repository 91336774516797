import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { RootContext } from '../../stores/storeProvidor';
import CopyToClipboardButton from '../controls/CopyToClipboardButton';
import { formatDateTime } from '../../utils/dates';
import RowScrollOffsetStore from '../../stores/rowScrollOffsetStore';

export type ResultInfoModalProps = {
  reportId: string;
};

const ResultInfoModal = NiceModal.create(({ reportId }: ResultInfoModalProps) => {
  const { activeReportStore, activeUserStore, rowScrollOffsetStore } = useContext(RootContext);
  const modal = useModal();

  const onCancel = () => {
    modal.remove();
  };

  const report = activeReportStore.getActiveReport(reportId);
  if (report?.report === undefined) return null;

  const lr = activeReportStore.getLayoutResult(reportId);
  const rs = rowScrollOffsetStore.getRowSpec(RowScrollOffsetStore.getId(reportId, lr?.currentPage ?? 0));

  const data = {
    reportId,
    ownerId: report.report.owner ?? '?',
    access: report.report.access ?? '?',
    reportType: report.report.spec.reportType ?? '?',
    isReadOnly: report.report.isReadOnly ? 'Yes' : 'No',
    modified: formatDateTime(report.report.modified, activeUserStore.getLocale(reportId)),
    currentPage: `${(lr?.currentPage ?? 0) + 1} of ${lr?.pageDims?.length ?? 0}`,
    runId: lr?.runId ?? '?',
    runStatus: lr?.runStatus?.status ?? 'None',
    runMessage: lr?.runStatus?.message ?? 'None',
    pcDone: lr?.runStatus?.pcDone ?? 0,
    blockLoaded: lr?.runStatus?.blocks.length ?? 0,
    totalRows: rs?.totalRows,
    rowLoadedStartRow: rs?.startRow,
    rowLoadedCount: rs?.rowCount,
    lastRequestedRow: rs?.requestedRow,
  };

  return (
    <Modal show onClose={onCancel}>
      <Modal.Header>
        <InformationCircleIcon className="h-8 inline mr-2" />
        Report Result Infomation
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-wrap">
          <div className="font-bold w-36 text-right mr-2">Report Id:</div>
          <div className="grow">{data.reportId}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Owner Id:</div>
          <div className="grow">{data.ownerId}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Access:</div>
          <div className="grow">{data.access}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Report Type:</div>
          <div className="grow">{data.reportType}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Read Only:</div>
          <div className="grow">{data.isReadOnly}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Modified:</div>
          <div className="grow">{data.modified}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Current Page:</div>
          <div className="grow">{data.currentPage}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Run Id:</div>
          <div className="grow">{data.runId}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Run Status:</div>
          <div className="grow">{data.runStatus}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Run Message:</div>
          <div className="grow">{data.runMessage}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">% Complete:</div>
          <div className="grow">{data.pcDone}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Blocks:</div>
          <div className="grow">{data.blockLoaded}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Total Rows:</div>
          <div className="grow">{data.totalRows}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Loaded Start Row:</div>
          <div className="grow">{data.rowLoadedStartRow}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Loaded Count:</div>
          <div className="grow">{data.rowLoadedCount}</div>

          <div className="basis-full h-1" />
          <div className="font-bold w-36 text-right mr-2">Requested Row:</div>
          <div className="grow">{data.lastRequestedRow}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={onCancel}>
          Done
        </Button>
        <CopyToClipboardButton title="Copy to Clipboard" textToCopy={JSON.stringify(data)} onClick={onCancel} />
      </Modal.Footer>
    </Modal>
  );
});

export default ResultInfoModal;
