import { PropsWithChildren } from 'react';

export interface NqButtonProps extends PropsWithChildren<unknown> {
  useSubmit?: boolean;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

function ActionButton({ useSubmit, onClick, children, className, disabled }: NqButtonProps) {
  const doClick = () => {
    if (onClick !== undefined && !disabled) {
      onClick();
    }
  };

  let cls = className;
  cls += ` text-white font-medium rounded-full text-sm px-5 py-2.5 text-center`;
  if (disabled) {
    cls += ' bg-blue-400 opacity-50 cursor-not-allowed';
  } else {
    cls += ' bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300';
  }

  return (
    <button type={useSubmit ? 'submit' : 'button'} className={cls} onClick={doClick} disabled={disabled}>
      {children}
    </button>
  );
}

ActionButton.defaultProps = {
  useSubmit: false,
  onClick: undefined,
  className: 'bg-primary hover:bg-secondary',
  disabled: false,
};

export default ActionButton;
