import { VizData, VizOptions } from './VizData';
import VizDatatype from './VizDatatype';

export default function calculateBestVizType(data: VizData): VizOptions {
  const dims = data.getDimensions(false);
  const multiDts = data.hasMultipleDatatypes();

  const activeRowAxis = dims.filter((d) => d.axis === 'row').sort((a, b) => b.itemCount - a.itemCount);
  const activeColAxis = dims.filter((d) => d.axis === 'col').sort((a, b) => b.itemCount - a.itemCount);

  const sourceAxis =
    activeRowAxis[0].itemCount > activeColAxis[0].itemCount
      ? [activeRowAxis[0], activeColAxis[0]]
      : [activeColAxis[0], activeRowAxis[0]];

  return { vizualisationType: 'BarPlotH', sourceAxis, datatypes: new Map<string, VizDatatype>() } as VizOptions;
}
