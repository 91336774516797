/*
 * Given a list of objects, with property 'propName', append all
 * values into a coma separated list. Optional 'and' can be included
 * for last item.
 */
export function concatPropertyValues<T, K extends keyof T>(objs: T[], propName: K, includeAnd = false): string {
  const arr = objs.map((o: T) => o[propName] as string);
  if (includeAnd && arr.length > 1) {
    arr[arr.length - 1] = `and ${arr[arr.length - 1]}`;
  }
  return arr.join(', ');
}

/*
 * Same as above but for string array
 */
export function concatValues(vals: string[], includeAnd = false): string {
  if (vals.length === 0) return '';
  if (vals.length === 1) return vals[0];
  if (includeAnd) return `${vals.slice(0, -1).join(', ')} and ${vals[vals.length - 1]}`;
  return vals.join(', ');
}

export function capitalizeWords(input: string): string {
  return input
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/*
 * toggleSelection()
 * Given a selection array, we either:
 * - Add the id if it not in array
 * - Remove id if it is in array already
 * Returns new copy of selection array
 */
export function toggleSelection(currentSelection: string[], toggleId: string): string[] {
  const newSelection: string[] = [];
  for (let i = 0; i < currentSelection.length; i += 1) {
    newSelection.push(currentSelection[i]);
  }
  const index = currentSelection.indexOf(toggleId);
  if (index > -1) {
    newSelection.splice(index, 1);
  } else {
    newSelection.push(toggleId);
  }
  return newSelection;
}

/*
 * Hash a string to get a int
 */
export function simpleHash(input: string): number {
  let hash = 0;
  const len = input.length;
  for (let i = 0; i < len; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) ^ (hash + input.charCodeAt(i));
    // eslint-disable-next-line no-bitwise
    hash &= 0x7fffffff; // to 32bit integer
  }
  return hash;
}

export function strArrayHash(input: string[]): number {
  // eslint-disable-next-line no-bitwise
  return input.map((v) => simpleHash(v)).reduce((a, b) => a ^ b, 0);
}

export function objArrayHash(input: object[]): number {
  // eslint-disable-next-line no-bitwise
  return input.map((v) => simpleHash(v.toString())).reduce((a, b) => a ^ b, 0);
}

/*
 * Check if arrays contain same items (any order)
 */
export function isEqual(arr1: string[], arr2: string[]): boolean {
  return arr1.sort().join() === arr2.sort().join();
}

/*
 * Filter an array to unique items only
 */
export function distinct(arr: string[]): string[] {
  const onlyUnique = (value: string, index: number, array: string[]) => array.indexOf(value) === index;
  return arr.filter(onlyUnique);
}

/*
 * Split a name from:
 *     's0000|Metro' => ['s0000', 'Metro']
 * If name does NOT have separator we expect:
 *     'incidence' => ['incidence', 'incidence']
 */
export function splitName(name: string | undefined): string[] {
  if (name !== undefined) {
    const parts = name.split('|');
    return parts.length === 2 ? parts : [name, name];
  }
  return ['id?', 'val?'];
}

/*
 * Parse a string into an enum value.
 * Allow case-insensitive matching.
 * Given a string that matches no enum value, return the 'unknownVal' enum value.
 */
export function enumFromString<T extends Record<string, unknown>>(
  enumType: T,
  str: string,
  unknownVal: T[keyof T]
): T[keyof T] {
  const key = Object.keys(enumType).find((g) => g.toLowerCase() === str.toLowerCase());
  const casted = key as keyof T;
  return enumType[casted] ?? unknownVal;
}

export function stringForEnum<T extends Record<string, unknown>>(enumType: T, value: T[keyof T]): string | undefined {
  const key = Object.keys(enumType).find((k) => enumType[k] === value);
  return key || undefined;
}

export function isNullOrUndef(obj: unknown): boolean {
  return obj === null || obj === undefined;
}

export function createNewUUID() {
  const url = URL.createObjectURL(new Blob());
  const [id] = url.toString().split('/').reverse();
  URL.revokeObjectURL(url);
  return id;
}
