import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TableCellsIcon } from '@heroicons/react/24/outline';
import { Button } from 'flowbite-react';
import { ColumnDef } from '@tanstack/react-table';
import _, { capitalize, range, round } from 'lodash';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';
import { RootContext } from '../../../stores/storeProvidor';
import AppGrid4x6 from '../../app/AppGrid';
import BaseResultGrid, {
  GridInfo,
  GridOptions,
  GridSection,
  getBaseCellStyle,
} from '../../controls/table/BaseResultGrid';
import useTimeout from '../../../hooks/useTimeout';
import { BufferedRowResult } from '../../../stores/rowScrollOffsetStore';
import Spinner from '../../controls/Spinner';
import randGen from '../../../utils/randUtils';

// Table help: https://borstch.com/blog/development/react-tanstack-table-examples-from-basics-to-advanced-usage

interface TestRowDetails {
  id: number; // Row index
  cells: string[]; // Entry for each column
}

type TestColDef = ColumnDef<TestRowDetails, unknown>;

interface TestResult extends BufferedRowResult {
  colHeaders: string[];
  rows: TestRowDetails[];
}

interface GridStats {
  uiRowsDrawn: number;
  firstUiRow: number;
  lastUiRow: number;
  totalRows: number;
  rowsInMemory: number;
  firstRowInMemory: number;
  lastRowInMemory: number;
  loadingStatus: string;
  tableShift: number;
  scrollOffset: number;
  scrollContainerTop: number;
  scrollContainerHeight: number;
}

// Test grid. Note for real data the equivalent component is <ReportResultsGrid>
function TestGridWithVirtualScroll({
  data,
  logger,
  divClass,
  updateStats,
  loadDataForRange,
}: {
  data: TestResult;
  logger: (msg: string) => void;
  divClass: string;
  updateStats: (stats: GridStats) => void;
  loadDataForRange: (start: number, end: number) => void;
}) {
  const { rowScrollOffsetStore: rowQueueStore } = useContext(RootContext);
  const rowQueueId = 'TestGridWithVirtualScroll';

  function getColumns(): TestColDef[] {
    if (data.totalRows === 0) return [];
    const nodes = ['Row Id', ...data.colHeaders].map(
      // eslint-disable-next-line arrow-body-style
      (col, index) => {
        return {
          id: `C${index}`,
          header: col,
          accessorFn: (rw: TestRowDetails) => {
            if (index === 0) return `${rw.id}`;
            if (rw.cells.length >= index) return rw.cells[index - 1];
            return '?';
          },
          meta: { colIndex: index, headerColIndex: undefined, rowHeaderSpanFn: undefined },
        } as TestColDef;
      }
    );
    return nodes;
  }

  const gridInfo = {
    colHeaderLevels: 0,
    rowHeaderLevels: 0,
    mergeRowHeaders: true,
    pageId: 0,
    rowBaseOffset: 0, // TODO - set to start offset of data
    onGridClick: (colindex: number | undefined, rowindex: number | undefined) => {
      logger(`Click: col=${colindex} row=${rowindex}`);
    },
    getStyle: (section: GridSection): string => getBaseCellStyle(section, false, true),
    options: { debugGrid: true, showRowIdColumn: false } as GridOptions,
  } as GridInfo;

  const [oldrh, setOldrh] = useState<string>('');
  const scrollerRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: data.totalRows,
    getScrollElement: () => scrollerRef.current,
    estimateSize: () => 32, // Row height
    overscan: 6,
    paddingEnd: 30, // Header height
  });

  // Timer used to debounce row requests generated by scrolling
  const [rowLoadTimerTrigger] = useTimeout(() => {
    const { from, to } = rowQueueStore.getCurrentRowRange(rowQueueId);
    if (from !== undefined && to !== undefined) loadDataForRange(from, to);
  }, 100);

  // Map the virtual row (UI) to the data row; take into account the 'start-row' of the loaded data.
  // If we are requesting data outside the loaded range, then trigger a load of the new rows.
  const mapVirtualRow = useCallback(
    (vi: VirtualItem) => {
      // Index is the unique position of row in the overall dataset
      const rowIndex = vi.index;

      // Offset is the position of the row in the memory dataset
      const rowDataOffset = vi.index - data.startRow;

      // Check if we need to load the row - if so trigger timer for load, and show progress indicator
      const isLoading = rowQueueStore.requestRowIndex(rowQueueId, rowIndex, rowLoadTimerTrigger);
      return { rowIndex, rowDataOffset, isLoading };
    },
    [data.startRow, rowLoadTimerTrigger, rowQueueStore]
  );

  const scrollDivHight = virtualizer.getTotalSize();

  const rh = `${data.rows.length}-${data.colHeaders.length}-${data.startRow}`;

  const doStatsUpdate = () => {
    const vrows = virtualizer.getVirtualItems();
    updateStats({
      uiRowsDrawn: vrows?.length ?? 0,
      firstUiRow: vrows?.[0]?.index ?? 0,
      lastUiRow: vrows?.[vrows.length - 1]?.index ?? 0,
      totalRows: data.totalRows,
      rowsInMemory: data.rows.length,
      firstRowInMemory: data.startRow,
      lastRowInMemory: data.startRow + data.rows.length - 1,
      loadingStatus: 'todo', // status,
      tableShift: (vrows?.length ?? 0) > 0 ? vrows?.[0].start ?? 0 : 0,
      scrollOffset: virtualizer.scrollOffset,
      scrollContainerTop: vrows?.[0]?.start ?? 0,
      scrollContainerHeight: scrollDivHight,
    } as GridStats);
  };

  // Update triggered on each scroll. Use timer so UI is not slowed by excessive updates
  const [statsUpdateTimerTrigger, statsUpdateTimerClear] = useTimeout(() => {
    doStatsUpdate();
  }, 100);

  // Update on refresh-handle change
  useEffect(() => {
    if (rh !== oldrh) {
      statsUpdateTimerClear();
      statsUpdateTimerTrigger();
      setOldrh(rh);

      rowQueueStore.setLimits(rowQueueId, data as BufferedRowResult);
    }
  }, [statsUpdateTimerClear, oldrh, rh, statsUpdateTimerTrigger, rowQueueStore, data]);

  return (
    <div
      ref={scrollerRef}
      className={`overflow-auto ${divClass}`}
      onScroll={() => {
        statsUpdateTimerTrigger();
      }}
    >
      <div style={{ height: `${scrollDivHight}px` }}>
        <BaseResultGrid
          data={data.rows}
          columnFn={getColumns}
          info={gridInfo}
          refreshHash={rh}
          virtualContext={{ virtualizer }}
          mapVirtualRowToData={mapVirtualRow}
        />
      </div>
    </div>
  );
}

function randomData(rows: number, startId: number, columns: number, maxlength: number): TestRowDetails[] {
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  const separator = '|';
  const sr = range(0, rows).map((v, ix) => {
    const r = randGen(ix + startId);
    return range(0, columns)
      .map(() => {
        let result = '';
        for (let i = Math.floor(3 + r() * (maxlength - 3)); i > 0; i -= 1)
          result += chars[Math.floor(r() * chars.length)];
        return capitalize(result);
      })
      .join(separator);
  });

  return sr.map((row, index) => ({ id: startId + index, cells: row.split('|') } as TestRowDetails));
}

function ScrollGridChecks() {
  const { uiState } = useContext(RootContext);
  const [stats, setStats] = useState<GridStats>({} as GridStats);

  const [gridRowData, setGridRowData] = useState<TestResult>({
    colHeaders: ['Name', 'Description', 'Value', 'Date', 'Status'],
    rows: [],
    startRow: 0,
    totalRows: 0,
    rowCount: 1000, // fixed size
  });

  const [showProgress, setShowProgress] = useState<boolean>(false);

  useEffect(() => {
    uiState.setAdminTitle('Scroll Virtualizer Grid Tests', TableCellsIcon);
  }, [uiState]);

  const appendRows = (start: number, requestedCount: number) => {
    const maxRowsInMemory = 1000;
    const countInMem = _.min([requestedCount, maxRowsInMemory]) ?? maxRowsInMemory;
    const bs = _.max([start - (maxRowsInMemory - countInMem) / 2, 0]) ?? 0;

    const clone = { ...gridRowData };
    clone.totalRows += requestedCount;
    const reqRows = _.min([maxRowsInMemory, clone.totalRows]) ?? clone.totalRows;
    clone.rows = randomData(reqRows, bs, gridRowData.colHeaders.length, 10);
    clone.startRow = start;
    setGridRowData(clone);
  };

  const loadRowsAtIndex = async (start: number, end: number) => {
    // Simulate 1 sec delay in loading data
    await new Promise((res) => {
      setTimeout(res, 1000);
    });

    const clone = {
      ...gridRowData,
      startRow: start,
      rowCount: end - start,
      rows: randomData(end - start + 1, start, gridRowData.colHeaders.length, 10),
    };
    setGridRowData(clone);
  };

  return (
    <AppGrid4x6>
      <div className="col-start-0 col-span-1 row-start-2 row-end-6 m-2">
        <h1>Grid Virtualizer Test</h1>
        <p>Check scroll virtualize:</p>
        <ul className="ml-4 list-disc list-inside">
          <li>Must handle massive amounts of rows</li>
          <li>Only 1000 row in memory at any time</li>
          <li>Load new chunk (with delay) when scroll to unloaded area</li>
          <li>Scroll range must map to expected row index</li>
        </ul>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            appendRows(0, 10);
          }}
        >
          Add 10 Rows
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            appendRows(0, 10000);
          }}
        >
          Add 10K Rows
        </Button>
        <Button
          className="m-1 text-xs font-light bg-blue-500"
          onClick={() => {
            setGridRowData({
              colHeaders: ['Name', 'Description', 'Value', 'Date', 'Status'],
              rows: [],
              startRow: 0,
              rowCount: 1000,
              totalRows: 0,
            });
          }}
        >
          Clear Rows
        </Button>
      </div>
      <div className="col-start-2 col-span-2 row-start-2 row-end-7 m-2">
        <TestGridWithVirtualScroll
          data={gridRowData}
          logger={(msg) => {
            uiState.infoAlert = msg;
          }}
          divClass="w-full h-full bg-teal-400 p-4"
          updateStats={(s) => {
            setStats(s);
          }}
          loadDataForRange={async (start, end) => {
            // eslint-disable-next-line no-console
            console.log(`Load rows ${start} to ${end}`);
            setShowProgress(true);
            try {
              await loadRowsAtIndex(start, end);
            } finally {
              setShowProgress(false);
            }
          }}
        />
        <Spinner
          enable={showProgress}
          text="Fetching rows..."
          onClick={() => {
            setShowProgress(false);
          }}
        />
      </div>
      <div className="col-start-4 col-span-2 row-start-3 row-end-5 m-2">
        <div className="bg-blue-100 p-4 rounded-xl border border-slate-500 shadow-2xl">
          Debug:
          <ul className="ml-4 list-disc list-inside">
            <li>Total Rows: {stats.totalRows}</li>
            <li>Rows in Memory: {stats.rowsInMemory}</li>
            <li>First Row in Memory: {stats.firstRowInMemory}</li>
            <li>Last Row in Memory: {stats.lastRowInMemory}</li>
            <li>Rows drawn by UI: {stats.uiRowsDrawn}</li>
            <li>First UI Row: {stats.firstUiRow}</li>
            <li>Last UI Row: {stats.lastUiRow}</li>
            <li>
              Loading Status: <span className="font-bold">{stats.loadingStatus}</span>
            </li>
            <li>Table Shift: {stats.tableShift}px</li>
            <li>Scroll Offset: {round(stats.scrollOffset, 2)}px</li>
            <li>Scroll Container Top: {stats.scrollContainerTop}px</li>
            <li>Scroll Container Height: {stats.scrollContainerHeight}px</li>
          </ul>
        </div>
      </div>
    </AppGrid4x6>
  );
}

export default ScrollGridChecks;
