import AuthAPI from '../api/authApi';

class AuthService {
  api: typeof AuthAPI;

  constructor() {
    this.api = AuthAPI;
  }
}

// Export only one instance of sevice (singleton)
const authService = new AuthService();
export default authService;
