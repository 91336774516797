import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { DateTime } from 'luxon';
import PeriodPanelPanel from './PeriodPanel';
import { PeriodCountEntry, PeriodDropdown, PeriodUnitEntry } from './PeriodControls';
import { RootContext } from '../../../../../stores/storeProvidor';
import {
  DateSummary,
  RelativePeriodUnits,
  getPeriodUnitName,
  getRelativeBaseOptions,
  getRelativeDateFromSummary,
  getRelativeDateToSummary,
  getRelativePeriodNodeRange,
  getRelativePeriodNodeTitle,
} from '../../../../../utils/relativeDateUtils';
import { SelectionNode } from '../../../../../models/selectionModels';
import { newDateRangeNode } from '../../../../../utils/selectionTreeUtils';

// -----------------------------------------------------------------------------------

interface DateSummaryPanelProps {
  summary: DateSummary;
}

function DateSummaryPanel({ summary }: DateSummaryPanelProps) {
  return (
    <div className="text-center italic font-bold bg-white text-gray-700 m-4 py-4 rounded-xl border border-gray-300 shadow">
      {summary.error ? <div className="text-red-500">{summary.error}</div> : <div>{summary.summary}</div>}
      {summary.date && (
        <div className="text-sm text-gray-400 font-normal">
          Currently: {summary.date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        </div>
      )}
    </div>
  );
}

// -----------------------------------------------------------------------------------

interface RelativePeriodPanelProps {
  stateKey: string;
}

const RelativePeriodPanel = observer(({ stateKey }: RelativePeriodPanelProps) => {
  const { selectionDragStore, selectionStore } = useContext(RootContext);
  const [firstDate, lastDate] = selectionStore.getSchemaDateRange();

  const state = selectionDragStore.getRelativePanelState(stateKey);
  const toSummary = getRelativeDateToSummary(state, lastDate);
  const fromSummary = getRelativeDateFromSummary(state, firstDate, toSummary.date);
  const [relativeBaseOptionsFrom, relativeBaseOptionsTo] = getRelativeBaseOptions();

  let fromCountDropdown = null;
  if (state.fromOffsetPeriodUnits.split('.').length > 1) {
    fromCountDropdown = (
      <PeriodUnitEntry
        id="from-num"
        label={`Count of ${getPeriodUnitName(
          state.fromOffsetPeriodUnits.split('.')[0] as RelativePeriodUnits,
          2
        )} back`}
        range={state.fromRange}
        selected={state.fromOffsetPeriodCount}
        onChange={(v) => selectionDragStore.setRelativePanelState(stateKey, { ...state, fromOffsetPeriodCount: v })}
      />
    );
  } else {
    fromCountDropdown = (
      <PeriodCountEntry
        id="from-num"
        units={state.fromOffsetPeriodUnits as RelativePeriodUnits}
        range={state.fromRange}
        selected={state.fromOffsetPeriodCount}
        onChange={(v) => selectionDragStore.setRelativePanelState(stateKey, { ...state, fromOffsetPeriodCount: v })}
      />
    );
  }

  let toCountDropdown = null;
  if (state.toOffsetPeriodUnits.split('.').length > 1) {
    toCountDropdown = (
      <PeriodUnitEntry
        id="to-num"
        label={`Count of ${getPeriodUnitName(state.toOffsetPeriodUnits.split('.')[0] as RelativePeriodUnits, 2)} back`}
        range={state.toRange}
        selected={state.toOffsetPeriodCount}
        onChange={(v) => selectionDragStore.setRelativePanelState(stateKey, { ...state, toOffsetPeriodCount: v })}
      />
    );
  } else if (state.toRange[1] !== 0) {
    toCountDropdown = (
      <PeriodCountEntry
        id="to-num"
        units={state.toOffsetPeriodUnits as RelativePeriodUnits}
        range={state.toRange}
        selected={state.toOffsetPeriodCount}
        onChange={(v) => selectionDragStore.setRelativePanelState(stateKey, { ...state, toOffsetPeriodCount: v })}
      />
    );
  }

  const createNodeOnDrop: () => SelectionNode = () => {
    const title = getRelativePeriodNodeTitle(state);
    const { from, to } = getRelativePeriodNodeRange(state);
    return newDateRangeNode(title, from, to);
  };

  return (
    <PeriodPanelPanel
      title="Relative Periods"
      hint=""
      tag="relative"
      position={0}
      stateKey={stateKey}
      createNodeOnDropFn={createNodeOnDrop}
    >
      <div className="h-full w-full flex">
        {/* From Pane */}
        <div className="flex-1 border-r-2 border-gray-300 p-1 flex flex-col">
          <PeriodDropdown
            id="from-base"
            label="From"
            options={relativeBaseOptionsFrom}
            selected={state.fromBase}
            onChange={(v) => selectionDragStore.setRelativePanelState(stateKey, { ...state, fromBase: v })}
          />
          {fromCountDropdown}
          <div className="flex-1" />
          <DateSummaryPanel summary={fromSummary} />
        </div>
        {/* To Pane */}
        <div className="flex-1 p-1 flex flex-col">
          <PeriodDropdown
            id="to-base"
            label="To"
            options={relativeBaseOptionsTo}
            selected={state.toBase}
            onChange={(v) => selectionDragStore.setRelativePanelState(stateKey, { ...state, toBase: v })}
          />
          {toCountDropdown}
          <div className="flex-1" />
          <DateSummaryPanel summary={toSummary} />
        </div>
      </div>
    </PeriodPanelPanel>
  );
});

export default RelativePeriodPanel;
