import { useContext, useEffect } from 'react';
import { QueueListIcon } from '@heroicons/react/24/outline';
import { RootContext } from '../../../stores/storeProvidor';
import AppGrid4x6 from '../../app/AppGrid';
import SelectAccordion from '../../app/report/Selection/SelectAccordion';

function TestRows({ count }: { count: number }) {
  const makeId = (length: number) => {
    let result = '';
    const characters = '        eeeeeeeeeettttttttaaaaaaaoooooonnnnniiiiihhhhhsssssrrrrrlllldddduuccmmwwyyffggppbbvkjx';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  return (
    <>
      {Array.from({ length: count }, (x, i) => i).map((r) => (
        <div key={makeId(3)} className="px-2 border-b font-mono">{`${r + 1} -> ${makeId(20)}`}</div>
      ))}
    </>
  );
}

function SelectAccordianChecks() {
  const { uiState } = useContext(RootContext);
  useEffect(() => {
    uiState.setAdminTitle('Accordion Tests', QueueListIcon);
  }, [uiState]);

  return (
    <AppGrid4x6>
      <div className="col-start-0 col-span-1 row-start-2 row-end-6 m-2">
        <h1>Accordian Test</h1>
        <p>Check that accordion:</p>
        <ul className="ml-4 list-disc list-inside">
          <li>Expands and collapses on title click</li>
          <li>Does not overflow its boundaries</li>
          <li>All titles are always visible</li>
          <li>Oversize content has a vertical scroll-bar</li>
          <li>Above should all apply even if browser window height is reduced</li>
          <li>Make sure all items in list can be seen (especially at bottom of scroll)</li>
        </ul>
      </div>
      <div className="col-start-2 col-span-2 row-start-2 row-end-7 m-2">
        <div className="w-full h-full bg-purple-100 p-2 rounded-xl">
          <SelectAccordion>
            <SelectAccordion.Panel statekey="testKey" title="First" hint="5 rows" id="abc_1" position={0}>
              <div className="h-full overflow-y-auto bg-indigo-100">
                <TestRows count={5} />
              </div>
            </SelectAccordion.Panel>
            <SelectAccordion.Panel statekey="testKey" title="Second" hint="15 rows" id="abc_2" position={1}>
              <div className="h-full overflow-y-auto bg-pink-50">
                <TestRows count={15} />
              </div>
            </SelectAccordion.Panel>
            <SelectAccordion.Panel statekey="testKey" title="Third" hint="25 rows" id="abc_3" position={2}>
              <div className="h-full overflow-y-auto bg-orange-50">
                <TestRows count={25} />
              </div>
            </SelectAccordion.Panel>
            <SelectAccordion.Panel statekey="testKey" title="Fourth" hint="40 rows" id="abc_4" position={3}>
              <div className="h-full flex flex-col">
                <div className="flex-0 bg-lime-300 h-16 w-full text-center pt-4">
                  {'---> Fixed Height Row - eg. for Search Box <---'}
                </div>
                <div className="flex-1 overflow-y-auto bg-teal-50">
                  <TestRows count={40} />
                </div>
              </div>
            </SelectAccordion.Panel>
          </SelectAccordion>
        </div>
      </div>
    </AppGrid4x6>
  );
}

export default SelectAccordianChecks;
