import { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface LinkProps {
  to: string;
}

function NavbarLink({ to, children }: PropsWithChildren<LinkProps>) {
  const location = useLocation();
  const isActive = location.pathname === to;
  const className = isActive ? 'underline text-secondary' : '';

  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
}

export default NavbarLink;
