type SelectionTabButtonProps = {
  label: string;
  id: string;
  isActive: boolean;
  isDisabled: boolean;
  hasError: boolean;
  onClick: (id: string) => void;
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>> | undefined;
};

function SelectionTabButton({ label, id, isActive, isDisabled, hasError, onClick, Icon }: SelectionTabButtonProps) {
  let colour = 'bg-gray-300 hover:text-blue-800';
  if (isActive) colour = 'bg-gray-100';
  if (isDisabled) colour = 'bg-gray-500 text-gray-400';
  if (hasError) colour = 'bg-red-600 text-gray-100';
  return (
    <li className={`-ml-1 mt-1 pl-2 rounded-t-xl border-l border-gray-800 ${colour}`}>
      <button
        type="button"
        className={`inline-block py-2 pr-3 ${hasError ? 'animate-pulse' : ''} ${
          isDisabled ? 'cursor-not-allowed' : ''
        }`}
        disabled={isDisabled}
        onClick={() => onClick(id)}
      >
        {Icon ? <Icon className="h-4 w-4 mr-2 inline" /> : null}
        {label}
      </button>
    </li>
  );
}

export type SelectionOption = {
  label: string;
  id: string;
  disabled: boolean;
  error: boolean;
  icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
};

type SelectionTabsProps = {
  tabs: SelectionOption[];
  onClick: (id: string) => void;
  activeId: string;
};

export default function SelectionTabs({ tabs, onClick, activeId }: SelectionTabsProps) {
  return (
    <div className="border-b border-gray-200">
      <ul className="flex flex-wrap text-sm font-medium text-center ml-2">
        {tabs.map((o) => (
          <SelectionTabButton
            key={o.id}
            label={o.label}
            id={o.id}
            isActive={o.id === activeId}
            isDisabled={o.disabled}
            hasError={o.error}
            onClick={onClick}
            Icon={o.icon}
          />
        ))}
      </ul>
    </div>
  );
}
