import { DateTime } from 'luxon';
import { ListObjectWithTags } from './listbase';
import { SelectionType, SelectionNode } from './selectionModels';
import { SubReport } from '../Components/app/report/subReports/SubReportBase';
import { BufferedRowResult } from '../stores/rowScrollOffsetStore';
import { AppSettings } from './settings';
import type { FormatNumberStyles } from '../hooks/useFormat';

export enum AxisId {
  Page = 'page',
  Row = 'row',
  Columns = 'column',
}

export interface ReportSummary extends ListObjectWithTags {
  id: string;
  description: string;
  owner: string;
  access: string;
  isActive: boolean;
  modified: DateTime;
  isReadOnly: boolean;
}

export interface LevelFilter {
  level: string;
  filter: string[];
}

export interface EmbeddedSelection {
  selectionType: SelectionType;
  linkedId: string | undefined; // Set if linked and not embedded
  treeRoot: SelectionNode | undefined; // Set if embedded
}

export interface LayoutAxis {
  level: string;
  totalDts: string[];
}

export interface DatatypePosition {
  axis: AxisId;
  index: number;
}

export interface ReportLayout {
  cellDts: string[];
  grandTotalDts: string[];
  dtPosition: DatatypePosition;
  pages: LayoutAxis[];
  rows: LayoutAxis[];
  columns: LayoutAxis[];
}

export interface ReportSpec {
  reportType: string;
  selections: EmbeddedSelection[];
  layout: ReportLayout;
  reportSettings: AppSettings;
}

// Hmm really all internals should be readonly too
export interface ReadOnlyReportSpec {
  readonly version: string;
  readonly reportType: string;
  readonly selections: EmbeddedSelection[];
  readonly layout: ReportLayout;
}

export interface ReportRunDetails {
  report: ReadOnlyReportSpec;
  blocksSplitByLevel: string | undefined;
  cardinalityEstimate: number;
  daysCovered: number;
  indexId: string;
  runId: string;
  versionId: string;
}

export interface ReportDetails extends ReportSummary {
  spec: ReportSpec;
}

/*
 Expect this to match : ReportStatusStorage.ProcessingStatus
 See: ...\src\common\ReportModels\CacheModels\ReportStatusStorage.cs 
 */
export enum RunStatus {
  NotQueued = 'NotQueued',
  Pending = 'Pending',
  Processing = 'Processing',
  AnalysisComplete = 'AnalysisComplete',
  FirstBlockComplete = 'FirstBlockComplete',
  ProcessingBlocks = 'ProcessingBlocks',
  Complete = 'Complete',
  Failed = 'Failed',
  RetryNeeded = 'RetryNeeded',
}

export function HasResultsFromStatus(stats: RunStatus | undefined): boolean {
  return stats === RunStatus.Complete || stats === RunStatus.ProcessingBlocks || stats === RunStatus.FirstBlockComplete;
}

export function ShowProgressFromStatus(stats: RunStatus | undefined): boolean {
  return stats === RunStatus.Pending || stats === RunStatus.Processing || stats === RunStatus.AnalysisComplete;
}

export interface PageDimention {
  id: number;
  rows: number;
  cols: number;
  levels: string[];
}

export interface CellDetails {
  value: string;
  dtIndex: number; // Index into cells datatype (style/formtting will change depending on datatype)
}

export interface RowDetails {
  id: number; // Row index
  cells: CellDetails[]; // Entry for each column
}

export interface ReportPageResult extends BufferedRowResult {
  page: number;
  colHeader: string[][] | undefined; // All column Headings
  rowHeader: string[][] | undefined; // headers for range given in BufferedRowResult
  rowHeaderSpans: number[][] | undefined; // Spans for range given in BufferedRowResult
  body: RowDetails[] | undefined; // Cells for range given in BufferedRowResult
}

export interface GridSelection {
  selPage: number;
  selStart: [number | undefined, number | undefined];
  selEnd: [number | undefined, number | undefined];
}

export interface ActiveReport {
  report: ReportDetails | undefined; // Layout etc that user is editting
  subReports: Map<string, SubReport>;
  sortPosition: number; // Position of report tab
  isEditting: boolean; // Edit or result mode
  needsRunning: boolean; // If true then need to rerun to get results
  needsSaving: boolean; // If true we must save
  ignoreLargeReportWarning: boolean;
  isLoading: boolean;
  loadMessage: string;
  selectionTabId: string | undefined;
  activeSubReport: string | undefined;
}

export interface ReportStatus {
  status: RunStatus;
  sentAt: string;
  updatedAt: DateTime;
  completdAt: DateTime;
  pcDone: number; // 0-100 where 100 is fully done
  blocks: string[]; // TODO; does UI care about blocks?
  message: string;
  schema: string;
}

export interface ReportLayoutResult {
  runId: string | undefined;
  runStatus: ReportStatus | undefined;
  pageDims: PageDimention[] | undefined;
  reportAsRun: ReadOnlyReportSpec | undefined; // This is layout etc from result blob (ie as-we-ran)
  currentPage: number; // Result page
  selection: GridSelection | undefined; // Result grid cell selection - NOTE: only once page can be selected at a time (ATM)
  pcComplete: number | undefined; // Progress 0-100 where 100 is fully done
}

export interface ReportRunRequest {
  reportId: string;
  schemaId: string;
  schemaVersion: string;
  IgnoreBigReportWarning: boolean;
}

export interface NewReportRequest {
  name: string | undefined;
  description: string | undefined;
  templateId: string | undefined;
}

export interface ReportRunResponse {
  message: string;
  runId: string;
}

export interface GridPosition {
  col: number | undefined;
  row: number | undefined;
}

export interface DrilldownRunRequest {
  drilldownReportId: string;
  originalReportId: string;
  originalRunId: string;
  page: number;
  cellStartColumn: number;
  cellStartRow: number;
  cellEndColumn: number;
  cellEndRow: number;
  schemaId: string;
  schemaVersion: string;
  IgnoreBigReportWarning: boolean;
}

export interface SpotFieldMeta {
  dataset: string | undefined;
  divisor: number | undefined;
}

export type SpotFieldFormat = FormatNumberStyles | 'datetime' | 'date' | 'time' | 'label';

export interface SpotField {
  tag: string;
  name: string;
  format: SpotFieldFormat;
  meta: SpotFieldMeta | undefined;
}

export interface SpotFieldDefintions {
  schema: string;
  fields: SpotField[];
}

export interface SpotRowDetails {
  index: number; // Row index
  fields: string[]; // Entry for each column - SpotDef sets the order
}

export interface SpotListResult extends BufferedRowResult {
  spotDef: SpotFieldDefintions;
  fieldOrder: string[] | undefined;
  spots: SpotRowDetails[];
}
