import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { PresentationChartBarIcon } from '@heroicons/react/24/outline';
import { Serie } from '@nivo/line';
import { ScatterPlotDatum, ScatterPlotRawSerie } from '@nivo/scatterplot';
import _ from 'lodash';
import { RootContext } from '../../../stores/storeProvidor';
import AppGrid4x6 from '../../app/AppGrid';
import PieChart from '../../visualisations/PieChart';
import BarPlotSvg, { BarPlotOptions, BarplotSpec } from '../../visualisations/BarPlotSvg';
import BarplotCanvas from '../../visualisations/BarPlotCanvas';
import BumpPlot from '../../visualisations/BumpPlot';
import LinePlotSvg from '../../visualisations/LinePlotSvg';
import ScatterPlotSvg from '../../visualisations/ScatterPlotSvg';
import ScatterPlotCanvas from '../../visualisations/ScatterPlotCanvas';
import LinePlotCanvas from '../../visualisations/LinePlotCanvas';
import HeatmapPlot from '../../visualisations/HeatmapPlot';
import TreemapPlot from '../../visualisations/TreemapPlot';
import { VizData, VizOptions } from '../../visualisations/VizData';

function PlotContainer({ children, title }: PropsWithChildren<{ title: string }>) {
  return (
    <>
      <h2>{title}</h2>
      <div className="w-full h-5/6 bg-gray-200 border border-gray-400 rounded-xl my-6">{children}</div>
    </>
  );
}

function OptionButton({ label, onClick }: { label: string; onClick: () => void }) {
  return (
    <button
      type="button"
      className="bg-teal-300 rounded-xl py-1 px-2 m-2 border border-gray-400"
      onClick={() => {
        onClick();
      }}
    >
      {label}
    </button>
  );
}

function ColourSelect({ active, onClick }: { active: string; onClick: (colour: string) => void }) {
  const schemes = [
    'nivo',
    'category10',
    'accent',
    'dark2',
    'paired',
    'pastel1',
    'pastel2',
    'set1',
    'set2',
    'set3',
    'brown_blueGreen',
    'purpleRed_green',
    'pink_yellowGreen',
    'purple_orange',
    'red_blue',
    'red_grey',
    'red_yellow_blue',
    'red_yellow_green',
    'spectral',
    'blues',
    'greens',
    'greys',
    'oranges',
    'purples',
    'reds',
    'blue_green',
    'blue_purple',
    'green_blue',
    'orange_red',
    'purple_blue_green',
    'purple_blue',
    'purple_red',
    'red_purple',
    'yellow_green_blue',
    'yellow_green',
    'yellow_orange_brown',
    'yellow_orange_red',
  ];
  return (
    <select
      className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
      onChange={(e) => {
        onClick(e.target.value);
      }}
      value={active}
    >
      {schemes.map((s) => (
        <option key={s} value={s}>{`Colour Scheme: ${_.capitalize(s)}`}</option>
      ))}
    </select>
  );
}

const defaultLineData = [
  {
    id: 'japan',
    // color: 'hsl(76, 70%, 50%)',
    data: [
      {
        x: 'Jan 23',
        y: 119,
      },
      {
        x: 'Feb 23',
        y: 197,
      },
      {
        x: 'Mar 23',
        y: 266,
      },
      {
        x: 'Apr 23',
        y: 0,
      },
      {
        x: 'May 23',
        y: 182,
      },
      {
        x: 'Jun 23',
        y: 258,
      },
      {
        x: 'Jul 23',
        y: 77,
      },
      {
        x: 'Aug 23',
        y: 86,
      },
      {
        x: 'Sept 23',
        y: 73,
      },
      {
        x: 'Oct 23',
        y: 219,
      },
      {
        x: 'Nov 23',
        y: 270,
      },
      {
        x: 'Dec 23',
        y: 59,
      },
    ],
  },
  {
    id: 'france',
    // color: 'hsl(267, 70%, 50%)',
    data: [
      {
        x: 'Jan 23',
        y: 65,
      },
      {
        x: 'Feb 23',
        y: 89,
      },
      {
        x: 'Mar 23',
        y: 188,
      },
      {
        x: 'Apr 23',
        y: 153,
      },
      {
        x: 'May 23',
        y: 147,
      },
      {
        x: 'Jun 23',
        y: 164,
      },
      {
        x: 'Jul 23',
        y: 117,
      },
      {
        x: 'Aug 23',
        y: 62,
      },
      {
        x: 'Sept 23',
        y: 244,
      },
      {
        x: 'Oct 23',
        y: 106,
      },
      {
        x: 'Nov 23',
        y: 128,
      },
      {
        x: 'Dec 23',
        y: 46,
      },
    ],
  },
  {
    id: 'us',
    // color: 'hsl(1, 70%, 50%)',
    data: [
      {
        x: 'Jan 23',
        y: 143,
      },
      {
        x: 'Feb 23',
        y: 29,
      },
      {
        x: 'Mar 23',
        y: 182,
      },
      {
        x: 'Apr 23',
        y: 112,
      },
      {
        x: 'May 23',
        y: 107,
      },
      {
        x: 'Jun 23',
        y: 72,
      },
      {
        x: 'Jul 23',
        y: 106,
      },
      {
        x: 'Aug 23',
        y: 228,
      },
      {
        x: 'Sept 23',
        y: 149,
      },
      {
        x: 'Oct 23',
        y: 42,
      },
      {
        x: 'Nov 23',
        y: 213,
      },
      {
        x: 'Dec 23',
        y: 201,
      },
    ],
  },
  {
    id: 'germany',
    // color: 'hsl(322, 70%, 50%)',
    data: [
      {
        x: 'Jan 23',
        y: 285,
      },
      {
        x: 'Feb 23',
        y: 95,
      },
      {
        x: 'Mar 23',
        y: 204,
      },
      {
        x: 'Apr 23',
        y: 197,
      },
      {
        x: 'May 23',
        y: 197,
      },
      {
        x: 'Jun 23',
        y: 78,
      },
      {
        x: 'Jul 23',
        y: 166,
      },
      {
        x: 'Aug 23',
        y: 70,
      },
      {
        x: 'Sept 23',
        y: 92,
      },
      {
        x: 'Oct 23',
        y: 81,
      },
      {
        x: 'Nov 23',
        y: 296,
      },
      {
        x: 'Dec 23',
        y: 205,
      },
    ],
  },
  {
    id: 'norway',
    // color: 'hsl(24, 70%, 50%)',
    data: [
      {
        x: 'Jan 23',
        y: 172,
      },
      {
        x: 'Feb 23',
        y: 111,
      },
      {
        x: 'Mar 23',
        y: 203,
      },
      {
        x: 'Apr 23',
        y: 256,
      },
      {
        x: 'May 23',
        y: 69,
      },
      {
        x: 'Jun 23',
        y: 25,
      },
      {
        x: 'Jul 23',
        y: 183,
      },
      {
        x: 'Aug 23',
        y: 55,
      },
      {
        x: 'Sept 23',
        y: 261,
      },
      {
        x: 'Oct 23',
        y: 48,
      },
      {
        x: 'Nov 23',
        y: 296,
      },
      {
        x: 'Dec 23',
        y: 254,
      },
    ],
  },
];

const defaultScatterData = [
  {
    id: 'group A',
    data: [
      {
        x: 70,
        y: 2,
      },
      {
        x: 46,
        y: 115,
      },
      {
        x: 71,
        y: 1,
      },
      {
        x: 12,
        y: 58,
      },
      {
        x: 48,
        y: 1,
      },
      {
        x: 40,
        y: 28,
      },
      {
        x: 26,
        y: 119,
      },
      {
        x: 73,
        y: 9,
      },
      {
        x: 8,
        y: 65,
      },
      {
        x: 12,
        y: 5,
      },
      {
        x: 97,
        y: 56,
      },
      {
        x: 67,
        y: 61,
      },
      {
        x: 89,
        y: 101,
      },
      {
        x: 65,
        y: 71,
      },
      {
        x: 20,
        y: 75,
      },
      {
        x: 81,
        y: 27,
      },
      {
        x: 38,
        y: 110,
      },
      {
        x: 80,
        y: 83,
      },
      {
        x: 18,
        y: 90,
      },
      {
        x: 68,
        y: 77,
      },
      {
        x: 57,
        y: 72,
      },
      {
        x: 81,
        y: 63,
      },
      {
        x: 97,
        y: 6,
      },
      {
        x: 3,
        y: 37,
      },
      {
        x: 23,
        y: 81,
      },
      {
        x: 43,
        y: 15,
      },
      {
        x: 75,
        y: 8,
      },
      {
        x: 12,
        y: 60,
      },
      {
        x: 49,
        y: 86,
      },
      {
        x: 15,
        y: 34,
      },
      {
        x: 15,
        y: 73,
      },
      {
        x: 18,
        y: 91,
      },
      {
        x: 85,
        y: 27,
      },
      {
        x: 71,
        y: 19,
      },
      {
        x: 69,
        y: 55,
      },
      {
        x: 82,
        y: 118,
      },
      {
        x: 12,
        y: 110,
      },
      {
        x: 79,
        y: 49,
      },
      {
        x: 9,
        y: 89,
      },
      {
        x: 23,
        y: 106,
      },
      {
        x: 29,
        y: 27,
      },
      {
        x: 48,
        y: 116,
      },
      {
        x: 38,
        y: 80,
      },
      {
        x: 13,
        y: 64,
      },
      {
        x: 73,
        y: 45,
      },
      {
        x: 42,
        y: 58,
      },
      {
        x: 55,
        y: 52,
      },
      {
        x: 23,
        y: 21,
      },
      {
        x: 50,
        y: 54,
      },
      {
        x: 83,
        y: 75,
      },
    ],
  },
  {
    id: 'group B',
    data: [
      {
        x: 49,
        y: 116,
      },
      {
        x: 40,
        y: 91,
      },
      {
        x: 93,
        y: 34,
      },
      {
        x: 63,
        y: 21,
      },
      {
        x: 6,
        y: 24,
      },
      {
        x: 35,
        y: 10,
      },
      {
        x: 87,
        y: 46,
      },
      {
        x: 99,
        y: 116,
      },
      {
        x: 23,
        y: 36,
      },
      {
        x: 60,
        y: 93,
      },
      {
        x: 1,
        y: 56,
      },
      {
        x: 45,
        y: 29,
      },
      {
        x: 100,
        y: 116,
      },
      {
        x: 65,
        y: 18,
      },
      {
        x: 54,
        y: 33,
      },
      {
        x: 12,
        y: 19,
      },
      {
        x: 45,
        y: 1,
      },
      {
        x: 87,
        y: 100,
      },
      {
        x: 36,
        y: 28,
      },
      {
        x: 73,
        y: 5,
      },
      {
        x: 76,
        y: 13,
      },
      {
        x: 49,
        y: 4,
      },
      {
        x: 63,
        y: 48,
      },
      {
        x: 68,
        y: 53,
      },
      {
        x: 37,
        y: 50,
      },
      {
        x: 74,
        y: 76,
      },
      {
        x: 49,
        y: 26,
      },
      {
        x: 16,
        y: 96,
      },
      {
        x: 92,
        y: 93,
      },
      {
        x: 60,
        y: 44,
      },
      {
        x: 66,
        y: 118,
      },
      {
        x: 66,
        y: 71,
      },
      {
        x: 40,
        y: 25,
      },
      {
        x: 34,
        y: 7,
      },
      {
        x: 43,
        y: 96,
      },
      {
        x: 27,
        y: 78,
      },
      {
        x: 66,
        y: 44,
      },
      {
        x: 59,
        y: 50,
      },
      {
        x: 1,
        y: 54,
      },
      {
        x: 8,
        y: 33,
      },
      {
        x: 51,
        y: 88,
      },
      {
        x: 5,
        y: 26,
      },
      {
        x: 91,
        y: 56,
      },
      {
        x: 56,
        y: 2,
      },
      {
        x: 80,
        y: 71,
      },
      {
        x: 86,
        y: 87,
      },
      {
        x: 37,
        y: 52,
      },
      {
        x: 54,
        y: 14,
      },
      {
        x: 64,
        y: 105,
      },
      {
        x: 99,
        y: 15,
      },
    ],
  },
  {
    id: 'group C',
    data: [
      {
        x: 93,
        y: 47,
      },
      {
        x: 84,
        y: 22,
      },
      {
        x: 21,
        y: 43,
      },
      {
        x: 25,
        y: 116,
      },
      {
        x: 84,
        y: 24,
      },
      {
        x: 20,
        y: 111,
      },
      {
        x: 22,
        y: 43,
      },
      {
        x: 50,
        y: 60,
      },
      {
        x: 23,
        y: 72,
      },
      {
        x: 95,
        y: 78,
      },
      {
        x: 61,
        y: 79,
      },
      {
        x: 42,
        y: 115,
      },
      {
        x: 57,
        y: 44,
      },
      {
        x: 86,
        y: 12,
      },
      {
        x: 4,
        y: 51,
      },
      {
        x: 94,
        y: 98,
      },
      {
        x: 89,
        y: 103,
      },
      {
        x: 14,
        y: 12,
      },
      {
        x: 51,
        y: 58,
      },
      {
        x: 42,
        y: 48,
      },
      {
        x: 7,
        y: 106,
      },
      {
        x: 69,
        y: 58,
      },
      {
        x: 34,
        y: 87,
      },
      {
        x: 8,
        y: 72,
      },
      {
        x: 31,
        y: 79,
      },
      {
        x: 21,
        y: 56,
      },
      {
        x: 78,
        y: 28,
      },
      {
        x: 37,
        y: 80,
      },
      {
        x: 74,
        y: 18,
      },
      {
        x: 11,
        y: 76,
      },
      {
        x: 38,
        y: 50,
      },
      {
        x: 79,
        y: 102,
      },
      {
        x: 76,
        y: 96,
      },
      {
        x: 61,
        y: 102,
      },
      {
        x: 63,
        y: 117,
      },
      {
        x: 16,
        y: 46,
      },
      {
        x: 37,
        y: 108,
      },
      {
        x: 44,
        y: 52,
      },
      {
        x: 88,
        y: 4,
      },
      {
        x: 9,
        y: 28,
      },
      {
        x: 84,
        y: 44,
      },
      {
        x: 69,
        y: 18,
      },
      {
        x: 27,
        y: 11,
      },
      {
        x: 4,
        y: 4,
      },
      {
        x: 53,
        y: 35,
      },
      {
        x: 35,
        y: 42,
      },
      {
        x: 96,
        y: 82,
      },
      {
        x: 87,
        y: 68,
      },
      {
        x: 9,
        y: 98,
      },
      {
        x: 95,
        y: 35,
      },
    ],
  },
  {
    id: 'group D',
    data: [
      {
        x: 50,
        y: 21,
      },
      {
        x: 21,
        y: 69,
      },
      {
        x: 69,
        y: 80,
      },
      {
        x: 8,
        y: 103,
      },
      {
        x: 21,
        y: 86,
      },
      {
        x: 20,
        y: 109,
      },
      {
        x: 98,
        y: 106,
      },
      {
        x: 53,
        y: 74,
      },
      {
        x: 49,
        y: 37,
      },
      {
        x: 11,
        y: 70,
      },
      {
        x: 1,
        y: 75,
      },
      {
        x: 80,
        y: 87,
      },
      {
        x: 17,
        y: 37,
      },
      {
        x: 26,
        y: 56,
      },
      {
        x: 69,
        y: 19,
      },
      {
        x: 28,
        y: 92,
      },
      {
        x: 100,
        y: 96,
      },
      {
        x: 37,
        y: 120,
      },
      {
        x: 50,
        y: 79,
      },
      {
        x: 54,
        y: 21,
      },
      {
        x: 27,
        y: 59,
      },
      {
        x: 6,
        y: 71,
      },
      {
        x: 5,
        y: 95,
      },
      {
        x: 19,
        y: 113,
      },
      {
        x: 81,
        y: 42,
      },
      {
        x: 67,
        y: 116,
      },
      {
        x: 87,
        y: 30,
      },
      {
        x: 86,
        y: 0,
      },
      {
        x: 100,
        y: 4,
      },
      {
        x: 44,
        y: 31,
      },
      {
        x: 37,
        y: 105,
      },
      {
        x: 40,
        y: 9,
      },
      {
        x: 73,
        y: 102,
      },
      {
        x: 20,
        y: 47,
      },
      {
        x: 94,
        y: 93,
      },
      {
        x: 83,
        y: 98,
      },
      {
        x: 40,
        y: 71,
      },
      {
        x: 43,
        y: 67,
      },
      {
        x: 64,
        y: 23,
      },
      {
        x: 76,
        y: 113,
      },
      {
        x: 7,
        y: 37,
      },
      {
        x: 62,
        y: 78,
      },
      {
        x: 38,
        y: 81,
      },
      {
        x: 79,
        y: 92,
      },
      {
        x: 79,
        y: 107,
      },
      {
        x: 32,
        y: 73,
      },
      {
        x: 83,
        y: 91,
      },
      {
        x: 25,
        y: 11,
      },
      {
        x: 57,
        y: 80,
      },
      {
        x: 19,
        y: 45,
      },
    ],
  },
  {
    id: 'group E',
    data: [
      {
        x: 25,
        y: 10,
      },
      {
        x: 74,
        y: 61,
      },
      {
        x: 77,
        y: 3,
      },
      {
        x: 44,
        y: 74,
      },
      {
        x: 88,
        y: 69,
      },
      {
        x: 40,
        y: 94,
      },
      {
        x: 94,
        y: 76,
      },
      {
        x: 84,
        y: 100,
      },
      {
        x: 80,
        y: 16,
      },
      {
        x: 47,
        y: 62,
      },
      {
        x: 29,
        y: 26,
      },
      {
        x: 63,
        y: 76,
      },
      {
        x: 78,
        y: 83,
      },
      {
        x: 90,
        y: 117,
      },
      {
        x: 39,
        y: 120,
      },
      {
        x: 39,
        y: 108,
      },
      {
        x: 74,
        y: 93,
      },
      {
        x: 24,
        y: 88,
      },
      {
        x: 98,
        y: 49,
      },
      {
        x: 58,
        y: 91,
      },
      {
        x: 84,
        y: 55,
      },
      {
        x: 95,
        y: 117,
      },
      {
        x: 59,
        y: 39,
      },
      {
        x: 1,
        y: 1,
      },
      {
        x: 74,
        y: 58,
      },
      {
        x: 11,
        y: 79,
      },
      {
        x: 16,
        y: 62,
      },
      {
        x: 87,
        y: 4,
      },
      {
        x: 59,
        y: 67,
      },
      {
        x: 20,
        y: 116,
      },
      {
        x: 7,
        y: 36,
      },
      {
        x: 91,
        y: 72,
      },
      {
        x: 65,
        y: 120,
      },
      {
        x: 47,
        y: 39,
      },
      {
        x: 89,
        y: 109,
      },
      {
        x: 30,
        y: 45,
      },
      {
        x: 4,
        y: 53,
      },
      {
        x: 94,
        y: 49,
      },
      {
        x: 36,
        y: 97,
      },
      {
        x: 55,
        y: 52,
      },
      {
        x: 15,
        y: 85,
      },
      {
        x: 98,
        y: 63,
      },
      {
        x: 49,
        y: 3,
      },
      {
        x: 48,
        y: 106,
      },
      {
        x: 25,
        y: 20,
      },
      {
        x: 39,
        y: 24,
      },
      {
        x: 21,
        y: 61,
      },
      {
        x: 37,
        y: 20,
      },
      {
        x: 15,
        y: 81,
      },
      {
        x: 100,
        y: 24,
      },
    ],
  },
];

const defaultBarOptions = {
  verticalBars: true,
  stacked: false,
  colours: 'nivo',
  borderWidth: 1,
  borderRadius: 5,
  showAverage: true,
} as BarPlotOptions;

function createBarData(): BarplotSpec {
  const countries = ['Japan', 'France', 'Australia', 'Morroco', 'Angola', 'Dubai'];
  const data = countries.map((c) => ({
    country: c,
    tv: +(200 + Math.random() * 300).toFixed(1),
    radio: +(100 + Math.random() * 300).toFixed(1),
    print: +(20 + Math.random() * 300).toFixed(1),
    cinema: +(Math.random() * 300).toFixed(1),
    magazines: +(Math.random() * 300).toFixed(1),
    online: +(100 + Math.random() * 300).toFixed(1),
  }));

  return {
    data,
    barIndex: 'country',
    barAxisLabel: 'Region',
    valueAxisLabel: 'Expenditure $000',
    title: 'Bar Chart Test',
  } as BarplotSpec;
}

function ChartChecks() {
  const { uiState } = useContext(RootContext);
  useEffect(() => {
    uiState.setAdminTitle('Chart Tests', PresentationChartBarIcon);
  }, [uiState]);

  const [activeOption, setActiveOption] = useState('barsvg');

  const testData = new VizData('abc', 'test');

  const [lineData, setLineData] = useState<Serie[]>(defaultLineData);
  const [scatterData, setScatterData] = useState<ScatterPlotRawSerie<ScatterPlotDatum>[]>(defaultScatterData);

  const [barData, setBarData] = useState<BarplotSpec>(createBarData());
  const [barOptions, setBarOptions] = useState<BarPlotOptions>(defaultBarOptions);

  const randomiseLineData = () => {
    const newData = [...lineData];
    for (let i = 0; i < newData.length; i += 1) {
      const s = newData[i];
      for (let j = 0; j < s.data.length; j += 1) {
        s.data[j].y = Math.random() * 300;
      }
    }
    setLineData(newData);
  };

  const randomiseScatterData = () => {
    const newData = [...scatterData];
    for (let i = 0; i < newData.length; i += 1) {
      const s = newData[i];
      for (let j = 0; j < s.data.length; j += 1) {
        s.data[j].x = Math.random() * 300;
        s.data[j].y = Math.random() * 300;
      }
    }
    setScatterData(newData);
  };

  let chart = null;
  let vizOptions = null;
  if (activeOption === 'barsvg') {
    chart = (
      <PlotContainer title="Bar Chart Test (Small Data)">
        <BarPlotSvg
          spec={barData}
          options={barOptions}
          onClick={(b) => {
            uiState.infoAlert = `Clicked: ${b.indexValue}/${b.id}`;
          }}
        />
      </PlotContainer>
    );
    vizOptions = (
      <div>
        <ColourSelect active={barOptions.colours} onClick={(c) => setBarOptions({ ...barOptions, colours: c })} />
        <OptionButton label="Refresh Data" onClick={() => setBarData(createBarData())} />
        <OptionButton
          label={barOptions.verticalBars ? 'Horizontal' : 'Vertical'}
          onClick={() => setBarOptions({ ...barOptions, verticalBars: !barOptions.verticalBars })}
        />
        <OptionButton
          label={barOptions.stacked ? 'Grouped' : 'Stacked'}
          onClick={() => setBarOptions({ ...barOptions, stacked: !barOptions.stacked })}
        />
        <OptionButton
          label={barOptions.showAverage ? 'Hide Average' : 'Show Average'}
          onClick={() => setBarOptions({ ...barOptions, showAverage: !barOptions.showAverage })}
        />
      </div>
    );
  }
  if (activeOption === 'barcanvas') {
    chart = (
      <PlotContainer title="Canvas Bar Chart (Big data)">
        <BarplotCanvas data={[]} />
      </PlotContainer>
    );
  }
  if (activeOption === 'pie') {
    chart = (
      <PlotContainer title="Pie Chart Test">
        <PieChart data={[]} />
      </PlotContainer>
    );
  }
  if (activeOption === 'bump') {
    chart = (
      <PlotContainer title="Bump Plot Test">
        <BumpPlot data={[]} />
      </PlotContainer>
    );
  }
  if (activeOption === 'linesvg') {
    chart = (
      <PlotContainer title="Line Plot Test (Small Data)">
        <LinePlotSvg data={lineData} xlegend="Period" ylegend="Expenditure $000" stacked />
      </PlotContainer>
    );
    vizOptions = (
      <div>
        <OptionButton label="Refresh Data" onClick={() => randomiseLineData()} />
      </div>
    );
  }
  if (activeOption === 'scatteresvg') {
    chart = (
      <PlotContainer title="Scatter Plot Test (Small data)">
        <ScatterPlotSvg data={scatterData} />
      </PlotContainer>
    );
    vizOptions = (
      <div>
        <OptionButton label="Refresh Data" onClick={() => randomiseScatterData()} />
      </div>
    );
  }
  if (activeOption === 'heat') {
    chart = (
      <PlotContainer title="Heat Map Test">
        <HeatmapPlot data={[]} />
      </PlotContainer>
    );
  }
  if (activeOption === 'calendar') {
    chart = (
      <PlotContainer title="Calendar Plot Test (year by day)">
        <div>
          TODO: See <a href="https://nivo.rocks/calendar/">Calendar Plot</a>
        </div>
      </PlotContainer>
    );
  }
  if (activeOption === 'timerange') {
    chart = (
      <PlotContainer title="Timerange Plot Test (period by day)">
        <div>
          TODO: See <a href="https://nivo.rocks/time-range/">Time-Range Plot</a>
        </div>
      </PlotContainer>
    );
  }
  if (activeOption === 'treemap') {
    chart = (
      <PlotContainer title="Tree Map Test">
        <TreemapPlot data={[]} />
      </PlotContainer>
    );
  }
  if (activeOption === 'linecanvas') {
    chart = (
      <PlotContainer title="Canvas Line Chart (Big data)">
        <LinePlotCanvas data={testData} options={{} as VizOptions} />
      </PlotContainer>
    );
  }
  if (activeOption === 'scattercanvas') {
    chart = (
      <PlotContainer title="Canvas Scatter Chart (Big data)">
        <ScatterPlotCanvas data={testData} options={{} as VizOptions} />
      </PlotContainer>
    );
  }

  return (
    <AppGrid4x6>
      <div className="row-start-2 row-end-7 m-2">
        <h1>Visualisation Checks</h1>
        <div className="h-4" />
        <select
          className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
          onChange={(e) => {
            setActiveOption(e.target.value);
          }}
          value={activeOption}
        >
          <option value="barsvg">SVG Bar Chart</option>
          <option value="linesvg">SVG Line Chart</option>
          <option value="scatteresvg">SVG Scatter Chart</option>
          <option value="pie">Pie Chart</option>
          <option value="bump">Bump Plot</option>
          <option value="heat">Heatmap</option>
          <option value="calendar">Calendar Plot</option>
          <option value="timerange">Time Range Plot</option>
          <option value="treemap">Tree Map</option>
          <option value="barcanvas">Canvas Bar Chart</option>
          <option value="linecanvas">Canvas Line Chart</option>
          <option value="scattercanvas">Canvas Scatter Chart</option>
        </select>
        {vizOptions && (
          <div className="bg-slate-200 border border-slate-400 rounded-xl p-2 my-2">
            <h3>Options</h3>
            {vizOptions}
          </div>
        )}
      </div>
      <div className="col-span-3 row-start-2 row-end-7 border-x-2 border-gray-400 p-2">{chart}</div>
    </AppGrid4x6>
  );
}

export default ChartChecks;
