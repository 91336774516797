import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import home_en from './en/home_en.json';
import app_en from './en/app_en.json';
import admin_en from './en/admin_en.json';
import home_it from './it/home_it.json';
import app_it from './it/app_it.json';
import admin_it from './it/admin_it.json';

i18next.use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      home: home_en,
      app: app_en,
      admin: admin_en,
    },
    it: {
      home: home_it,
      app: app_it,
      admin: admin_it,
    },
  },
  fallbackLng: ['en', 'it', 'dev'],
  // defaultNS,
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});
