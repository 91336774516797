import SelectionAPI from '../api/selectionApi';

class SelectionService {
  api: SelectionAPI;

  constructor() {
    this.api = new SelectionAPI();
  }
}

// Export only one instance of sevice (singleton)
const selectionService = new SelectionService();

export default selectionService;
