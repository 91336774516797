import { useState } from 'react';
import { SubReport } from '../app/report/subReports/SubReportBase';
import SubReportTitleBar from '../app/report/subReports/SubReportTitleBar';
import BarPlotSvg, { BarPlotOptions, BarplotData, BarplotSpec } from './BarPlotSvg';
import { VizData, VizOptions } from './VizData';
import VizDatatype from './VizDatatype';

const defaultDatatype = new VizDatatype('cost', '$');

function getBarPlotData(data: VizData, options: VizOptions): BarplotSpec {
  // Take first two source axis
  if (options.sourceAxis.length < 2) return { data: [], barIndex: '', barAxisLabel: '', valueAxisLabel: '', title: '' };
  const haxis = options.sourceAxis[0];
  const vaxis = options.sourceAxis[1];

  const hdata = data.getAxisData(haxis.name);
  const vdata = data.getAxisData(vaxis.name);

  const dt = options.datatypes.get(data.datatypeId) ?? defaultDatatype;
  dt.autoSetDivisor(data.maxValue);

  const barData = hdata.map((h, i) => {
    const bar = { bar: h } as BarplotData;
    vdata.forEach((v) => {
      const values = data.getValuesAsArray(vaxis, v, dt);
      bar[v] = values[i];
    });
    return bar;
  });

  return {
    data: barData,
    barIndex: 'bar',
    barAxisLabel: haxis.name,
    valueAxisLabel: `${vaxis.name} - $(000)`, // TODO: Label needs to be set for the datatype - How do we get this?
    title: data.vizTitle,
  } as BarplotSpec;
}

function VizOptionButton({ label, onClick }: { label: string; onClick: () => void }) {
  return (
    <button
      type="button"
      className="bg-green-300 rounded-xl py-1 px-2 mr-2 border border-gray-300 text-xs"
      onClick={() => {
        onClick();
      }}
    >
      {label}
    </button>
  );
}

interface VizWrapperProps {
  subReport: SubReport;
  data: VizData;
  options: VizOptions;
}

const defaultBarOptions = {
  verticalBars: true,
  stacked: false,
  colours: 'greens',
  borderWidth: 1,
  borderRadius: 3,
  showAverage: true,
} as BarPlotOptions;

/*
 * Wrapper for all visualisations. Allows options to dynamically change the
 * type of visualisation showing.
 */
export default function VizWrapper({ subReport, data, options }: VizWrapperProps) {
  const [barOptions, setBarOptions] = useState<Map<string, BarPlotOptions>>(new Map<string, BarPlotOptions>());
  const getBarOptions = (id: string) => barOptions.get(id) ?? defaultBarOptions;
  const updateBarOption = (id: string, key: keyof BarPlotOptions, val: unknown) => {
    barOptions.set(id, { ...getBarOptions(id), [key]: val } as BarPlotOptions);
    setBarOptions(new Map(barOptions));
  };
  const toggleBarOption = (id: string, key: keyof BarPlotOptions) => {
    const bo = getBarOptions(id);
    updateBarOption(id, key, !bo[key]);
  };

  let targetViz = null;
  let vizOptions = null;

  if (options.vizualisationType === 'BarPlotH') {
    const barSpec = getBarPlotData(data, options);

    const bo = getBarOptions(subReport.id);

    targetViz = (
      <BarPlotSvg
        spec={barSpec}
        onClick={(b) => {
          // eslint-disable-next-line no-console
          console.log('Clicked Bar:', b);
        }}
        options={bo}
      />
    );

    vizOptions = (
      <div>
        <VizOptionButton
          label={bo.verticalBars ? 'Horizontal' : 'Vertical'}
          onClick={() => toggleBarOption(subReport.id, 'verticalBars')}
        />
        <VizOptionButton
          label={bo.stacked ? 'Grouped' : 'Stacked'}
          onClick={() => toggleBarOption(subReport.id, 'stacked')}
        />
        <VizOptionButton
          label={bo.showAverage ? 'Hide Average' : 'Show Average'}
          onClick={() => toggleBarOption(subReport.id, 'showAverage')}
        />
      </div>
    );
  }

  return (
    <div className="h-full w-full p-1 flex flex-col gap-1">
      <SubReportTitleBar subReport={subReport} />
      <div className="w-full h-5/6 bg-gray-200 border border-gray-400 rounded-xl">{targetViz}</div>
      <div className="grow w-full bg-gray-200 border border-gray-400 rounded-xl p-2">{vizOptions}</div>
    </div>
  );
}
