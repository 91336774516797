import apiClient from './apiConfig';
import CancelApi from './apiUtils';
import { AdminUser, CreateOrgDto, CreateUserDto } from '../models/userModel';
import { Organisation } from '../models/orgModels';
import { AdminRole, AvailablePermission } from '../models/roleModels';
import { convertToDate } from '../utils/dates';
import { Schema } from '../models/schemaModels';
import { ReportActivity, UserActivity } from '../Components/admin/models/Activity';
import { SystemSummary } from '../Components/admin/models/SystemSummary';

class AdminAPI {
  private canceller = new CancelApi();

  async getUsers(cancelExisting = false) {
    const response = await apiClient.api.get<AdminUser[]>('admin/users', {
      signal: cancelExisting ? this.canceller.getSignal(`users`) : undefined,
    });
    return response.data.map((user) => ({
      ...user,
      passwordExpiry: user.passwordExpiry === undefined ? undefined : convertToDate(user.passwordExpiry),
      created: convertToDate(user.created),
      modified: convertToDate(user.modified),
      role: {
        ...user.role,
        created: convertToDate(user.role.created),
        modified: convertToDate(user.role.modified),
      },
    }));
  }

  async createNewUser(user: CreateUserDto, cancelExisting = false) {
    const response = await apiClient.api.post<AdminUser>('admin/user', user, {
      signal: cancelExisting ? this.canceller.getSignal(`updateuser`) : undefined,
    });
    return response.data;
  }

  async updateUser(user: AdminUser, cancelExisting = false) {
    const response = await apiClient.api.put<AdminUser>('admin/user', user, {
      signal: cancelExisting ? this.canceller.getSignal(`updateuser`) : undefined,
    });
    return response.data;
  }

  async getOrgs(cancelExisting = false) {
    const response = await apiClient.api.get<Organisation[]>('admin/orgs', {
      signal: cancelExisting ? this.canceller.getSignal(`orgs`) : undefined,
    });
    return response.data.map((org) => ({
      ...org,
      created: convertToDate(org.created),
      modified: convertToDate(org.modified),
    }));
  }

  async createNewOrg(user: CreateOrgDto, cancelExisting = false) {
    const response = await apiClient.api.post<Organisation>('admin/org', user, {
      signal: cancelExisting ? this.canceller.getSignal(`createOrg`) : undefined,
    });
    return response.data;
  }

  async updateOrg(org: Organisation, cancelExisting = false) {
    const response = await apiClient.api.put<Organisation>('admin/org', org, {
      signal: cancelExisting ? this.canceller.getSignal(`updateorg`) : undefined,
    });
    return response.data;
  }

  async getRoles(cancelExisting = false) {
    const response = await apiClient.api.get<AdminRole[]>('admin/roles', {
      signal: cancelExisting ? this.canceller.getSignal(`roles`) : undefined,
    });
    return response.data.map((role) => ({
      ...role,
      created: convertToDate(role.created),
      modified: convertToDate(role.modified),
    }));
  }

  async copyRole(role: AdminRole, cancelExisting = false) {
    const response = await apiClient.api.post<AdminRole>(
      'admin/copyrole',
      {
        name: `${role.name} (Copy)`,
        description: '', // role.description,
        isActive: false, // role.isActive,
        roleIdToCopy: role.id,
      },
      {
        signal: cancelExisting ? this.canceller.getSignal(`createrole`) : undefined,
      }
    );
    return response.data;
  }

  async updateRole(role: AdminRole, cancelExisting = false) {
    const response = await apiClient.api.put<AdminRole>(
      'admin/role',
      {
        id: role.id,
        name: role.name,
        description: role.description,
        isActive: role.isActive,
        permissions: role.permissions.map((p) => p.access),
      },
      {
        signal: cancelExisting ? this.canceller.getSignal(`updaterole`) : undefined,
      }
    );
    return response.data;
  }

  async setUserRoles(uid: string, rid: number, cancelExisting = false) {
    await apiClient.api.put(
      'admin/user/role',
      { userId: uid, roleId: rid },
      {
        signal: cancelExisting ? this.canceller.getSignal(`setRole`) : undefined,
      }
    );
  }

  async getAvailablePermissions(cancelExisting = false): Promise<AvailablePermission[]> {
    const response = await apiClient.api.get<AvailablePermission[]>('admin/perms', {
      signal: cancelExisting ? this.canceller.getSignal(`perms`) : undefined,
    });
    return response.data;
  }

  async getAdminSchema(cancelExisting = false): Promise<Schema[]> {
    const response = await apiClient.api.get<Schema[]>('admin/schema', {
      signal: cancelExisting ? this.canceller.getSignal(`schema`) : undefined,
    });
    return response.data.map((schema) => ({
      ...schema,
      versions: schema.versions.map((ver) => ({
        ...ver,
        created: convertToDate(ver.created),
        updated: convertToDate(ver.updated),
        firstDate: convertToDate(ver.firstDate),
        lastDate: convertToDate(ver.lastDate),
      })),
    }));
  }

  async getSystemSummary(cancelExisting = false): Promise<SystemSummary> {
    const response = await apiClient.api.get<SystemSummary>('admin/system', {
      signal: cancelExisting ? this.canceller.getSignal(`schema`) : undefined,
    });
    return {
      ...response.data,
      timestamp: convertToDate(response.data.timestamp),
      reportStatsFrom: convertToDate(response.data.reportStatsFrom),
      processStatsFrom: convertToDate(response.data.processStatsFrom),
    };
  }

  async getReportActivity(activitySize: number, cancelExisting = false): Promise<ReportActivity[]> {
    const response = await apiClient.api.get<ReportActivity[]>('admin/activity/reports', {
      signal: cancelExisting ? this.canceller.getSignal(`schema`) : undefined,
    });
    return response.data.map(
      (ra) =>
        ({
          ...ra,
          timestamp: convertToDate(ra.timestamp),
        } as ReportActivity)
    );
  }

  async getUserActivity(activitySize: number, cancelExisting = false): Promise<UserActivity[]> {
    const response = await apiClient.api.get<UserActivity[]>('admin/activity/users', {
      signal: cancelExisting ? this.canceller.getSignal(`schema`) : undefined,
    });
    return response.data.map((ua) => ({
      ...ua,
      timestamp: convertToDate(ua.timestamp),
    }));
  }
}

export default AdminAPI;
