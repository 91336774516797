import { ReactNode } from 'react';
import Logo from './Logo';

interface SideMenuProps {
  showSideMenu: boolean;
  clearSideMenuFn: () => void;
  children: ReactNode;
}

export default function SideMenu({ showSideMenu, clearSideMenuFn, children }: SideMenuProps) {
  return (
    <>
      {/* Overlay - Grey out main body when sidebar is open */}
      <div
        onClick={clearSideMenuFn}
        onKeyDown={clearSideMenuFn}
        hidden={!showSideMenu}
        className="fixed inset-0 transition-opacity backdrop-blur-sm z-30"
        role="button"
        tabIndex={0}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          onClick={clearSideMenuFn}
          onKeyDown={clearSideMenuFn}
          className="absolute inset-0 bg-black opacity-20"
          role="button"
          tabIndex={0}
        />
      </div>

      {/* Side Menu - show on flag only */}
      <aside
        className={`transform top-0 left-0 w-64 bg-gray-100 fixed h-full overflow-auto ease-in-out transition-all duration-300 z-40 ${
          showSideMenu ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <span
          onClick={clearSideMenuFn}
          onKeyDown={clearSideMenuFn}
          className="flex w-full items-center px-10 py-2 border-b"
          role="button"
          tabIndex={0}
        >
          <Logo />
        </span>
        {children}
      </aside>
    </>
  );
}

// -----------------------------------------------------------------------

interface SideMenuSectionProps {
  children: ReactNode;
}

export function SideMenuBottomSection({ children }: SideMenuSectionProps) {
  return <div className="fixed bottom-0 w-full">{children}</div>;
}
