import AdminAPI from '../api/adminApi';

class AdminService {
  api: AdminAPI;

  constructor() {
    this.api = new AdminAPI();
  }
}

// Export only one instance of sevice (singleton)
const adminService = new AdminService();

export default adminService;
