import { ChartBarSquareIcon } from '@heroicons/react/24/outline';

interface LogoProps {
  clickFn?: () => void;
  className?: string;
}

export default function Logo({ clickFn, className }: LogoProps) {
  return (
    <span className={`flex items-center ${className}`} onClick={clickFn} onKeyDown={clickFn} role="button" tabIndex={0}>
      <ChartBarSquareIcon className="h-10 w-10 hover:animate-spin text-secondary" />
      <span className="self-center whitespace-nowrap text-xl font-semibold px-1">{import.meta.env.VITE_APP_TITLE}</span>
    </span>
  );
}

Logo.defaultProps = {
  clickFn: () => {},
  className: 'text-gray-500',
};
