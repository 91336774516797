import { SubReport } from './SubReportBase';

type SpotListSummaryBarProps = {
  subReport: SubReport;
};

function SummaryHeader({ title, value }: { title: string; value: string }) {
  return (
    <div className="text-gray-600 bg-blue-300 px-2 mr-1 rounded-xl">
      <span className="font-medium">{title}: </span>
      {value}
    </div>
  );
}

function SpotListSummaryBar({ subReport }: SpotListSummaryBarProps) {
  return (
    <div className="flex">
      <SummaryHeader title="Period" value="23/5/22 -> 14/6/22" />
      <SummaryHeader title="Media" value="Metro TV" />
      <SummaryHeader title="Dictionary" value="Mobile Services" />
    </div>
  );
}

export default SpotListSummaryBar;
