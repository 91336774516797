import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { RootContext } from '../../../stores/storeProvidor';
import { SelectionType } from '../../../models/selectionModels';
import { EmbeddedSelection } from '../../../models/reportModel';
import { summariseSelectionTree } from '../../../utils/selectionTreeUtils';

interface ReportSummaryPaneProps {
  reportId: string;
}

const ReportSummaryPane = observer(({ reportId }: ReportSummaryPaneProps) => {
  const { activeReportStore } = useContext(RootContext);
  const report = activeReportStore.getActiveReport(reportId);
  const selections = report?.report?.spec.selections;

  const title = (txt: string) => <div className="font-bold text-right">{txt}:</div>;

  const summariseSelection = (allsel: EmbeddedSelection[] | undefined, type: SelectionType) => {
    let msg = 'None';
    let hasSelection = false;
    let isLinked = false;
    if (allsel !== undefined) {
      const sel = allsel.find((s) => s.selectionType === type);
      if (sel?.treeRoot !== undefined) {
        if (sel.linkedId) {
          msg = 'Linked Selection';
          isLinked = true;
        } else {
          const summary = summariseSelectionTree(sel?.treeRoot);
          if (summary.length > 0) {
            msg = summary.join(', ');
            if (msg.length > 80) msg = `${msg.substring(0, 80)}...`;
            hasSelection = true;
          }
        }
      }
    }
    return (
      <div className={`rounded-md px-1 ml-0.5 ${(!hasSelection && 'bg-red-100') || (isLinked && 'bg-green-100')}`}>
        {msg}
      </div>
    );
  };

  return (
    <div className="grid grid-cols-[25%_75%] gap-y-1">
      {title('Periods')}
      {summariseSelection(selections, SelectionType.Period)}

      {title('Dictionary')}
      {summariseSelection(selections, SelectionType.Dictionary)}

      {title('Media')}
      {summariseSelection(selections, SelectionType.Media)}

      {title('Datatypes')}
      {summariseSelection(selections, SelectionType.Datatypes)}

      {title('Other')}
      {summariseSelection(selections, SelectionType.Meta)}
    </div>
  );
});

export default ReportSummaryPane;
