import { ResponsiveHeatMap } from '@nivo/heatmap';

// See https://nivo.rocks/heatmap/

const hmData = [
  {
    id: 'Japan',
    data: [
      {
        x: 'Train',
        y: 52700,
      },
      {
        x: 'Subway',
        y: -54345,
      },
      {
        x: 'Bus',
        y: 45910,
      },
      {
        x: 'Car',
        y: -54276,
      },
      {
        x: 'Boat',
        y: -70518,
      },
      {
        x: 'Moto',
        y: 73429,
      },
      {
        x: 'Moped',
        y: 10659,
      },
      {
        x: 'Bicycle',
        y: -75235,
      },
      {
        x: 'Others',
        y: -51390,
      },
    ],
  },
  {
    id: 'France',
    data: [
      {
        x: 'Train',
        y: -5396,
      },
      {
        x: 'Subway',
        y: -49153,
      },
      {
        x: 'Bus',
        y: 359,
      },
      {
        x: 'Car',
        y: -90432,
      },
      {
        x: 'Boat',
        y: 3438,
      },
      {
        x: 'Moto',
        y: -55760,
      },
      {
        x: 'Moped',
        y: -24633,
      },
      {
        x: 'Bicycle',
        y: -19930,
      },
      {
        x: 'Others',
        y: -87353,
      },
    ],
  },
  {
    id: 'US',
    data: [
      {
        x: 'Train',
        y: -13060,
      },
      {
        x: 'Subway',
        y: 33899,
      },
      {
        x: 'Bus',
        y: -78858,
      },
      {
        x: 'Car',
        y: 93102,
      },
      {
        x: 'Boat',
        y: 93708,
      },
      {
        x: 'Moto',
        y: -56462,
      },
      {
        x: 'Moped',
        y: -3364,
      },
      {
        x: 'Bicycle',
        y: -29982,
      },
      {
        x: 'Others',
        y: -66028,
      },
    ],
  },
  {
    id: 'Germany',
    data: [
      {
        x: 'Train',
        y: 38620,
      },
      {
        x: 'Subway',
        y: 7491,
      },
      {
        x: 'Bus',
        y: 50342,
      },
      {
        x: 'Car',
        y: 26197,
      },
      {
        x: 'Boat',
        y: 8979,
      },
      {
        x: 'Moto',
        y: 57136,
      },
      {
        x: 'Moped',
        y: 22048,
      },
      {
        x: 'Bicycle',
        y: 41596,
      },
      {
        x: 'Others',
        y: -78800,
      },
    ],
  },
  {
    id: 'Norway',
    data: [
      {
        x: 'Train',
        y: 73553,
      },
      {
        x: 'Subway',
        y: 34533,
      },
      {
        x: 'Bus',
        y: -96939,
      },
      {
        x: 'Car',
        y: 47530,
      },
      {
        x: 'Boat',
        y: -5820,
      },
      {
        x: 'Moto',
        y: 45042,
      },
      {
        x: 'Moped',
        y: -96704,
      },
      {
        x: 'Bicycle',
        y: 1372,
      },
      {
        x: 'Others',
        y: 68133,
      },
    ],
  },
  {
    id: 'Iceland',
    data: [
      {
        x: 'Train',
        y: -68521,
      },
      {
        x: 'Subway',
        y: 76542,
      },
      {
        x: 'Bus',
        y: -20204,
      },
      {
        x: 'Car',
        y: 88928,
      },
      {
        x: 'Boat',
        y: -33164,
      },
      {
        x: 'Moto',
        y: -17217,
      },
      {
        x: 'Moped',
        y: 81973,
      },
      {
        x: 'Bicycle',
        y: -63322,
      },
      {
        x: 'Others',
        y: -9499,
      },
    ],
  },
  {
    id: 'UK',
    data: [
      {
        x: 'Train',
        y: 17329,
      },
      {
        x: 'Subway',
        y: 65821,
      },
      {
        x: 'Bus',
        y: -47832,
      },
      {
        x: 'Car',
        y: 11438,
      },
      {
        x: 'Boat',
        y: 27157,
      },
      {
        x: 'Moto',
        y: -44328,
      },
      {
        x: 'Moped',
        y: -84148,
      },
      {
        x: 'Bicycle',
        y: 59954,
      },
      {
        x: 'Others',
        y: 69447,
      },
    ],
  },
  {
    id: 'Vietnam',
    data: [
      {
        x: 'Train',
        y: -57535,
      },
      {
        x: 'Subway',
        y: -85099,
      },
      {
        x: 'Bus',
        y: 48624,
      },
      {
        x: 'Car',
        y: 54411,
      },
      {
        x: 'Boat',
        y: 67318,
      },
      {
        x: 'Moto',
        y: -42695,
      },
      {
        x: 'Moped',
        y: 88416,
      },
      {
        x: 'Bicycle',
        y: -29120,
      },
      {
        x: 'Others',
        y: -45853,
      },
    ],
  },
];

interface HeatmapProps {
  data: number[][];
}

function HeatmapPlot({ data }: HeatmapProps) {
  return (
    <ResponsiveHeatMap
      data={hmData}
      margin={{ top: 60, right: 90, bottom: 60, left: 90 }}
      valueFormat=" >-.2s"
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -90,
        legend: '',
        legendOffset: 46,
      }}
      axisRight={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'country',
        legendPosition: 'middle',
        legendOffset: 70,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'country',
        legendPosition: 'middle',
        legendOffset: -72,
      }}
      colors={{
        type: 'diverging',
        scheme: 'red_yellow_blue',
        divergeAt: 0.5,
        minValue: -100000,
        maxValue: 100000,
      }}
      emptyColor="#555555"
      legends={[
        {
          anchor: 'bottom',
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: 'row',
          tickPosition: 'after',
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: '>-.2s',
          title: 'Value →',
          titleAlign: 'start',
          titleOffset: 4,
        },
      ]}
    />
  );
}

export default HeatmapPlot;
