import _ from 'lodash';
import { DATATYPE_LEVEL_TAG } from '../../stores/selectionStore';
import VizDatatype from './VizDatatype';

interface VizDimension {
  name: string;
  itemCount: number;
  axis: string;
}

export class VizData {
  // cells (2D) - col x row
  private cellData: number[][];

  // row headers (2d) - col x row-levels
  // col headers (2d) - row x col-levels
  private rowHeaders: string[][];

  private colHeaders: string[][];

  // row levels (1d)
  // col levels (1d)
  private rowLevels: string[];

  private colLevels: string[];

  // page headers (1d)
  private pageHeaders: string[];

  // page levels (1d)
  private pageLevels: string[];

  private title: string;

  private reportId: string;

  private stripIds: boolean;

  private dtId: string;

  constructor(reportId: string, title: string, stripIds = true) {
    this.cellData = new Array<number[]>(0);
    this.rowHeaders = new Array<string[]>(0);
    this.colHeaders = new Array<string[]>(0);
    this.pageHeaders = new Array<string>(0);
    this.rowLevels = new Array<string>(0);
    this.colLevels = new Array<string>(0);
    this.pageLevels = new Array<string>(0);
    this.title = title;
    this.reportId = reportId;
    this.stripIds = stripIds;
    this.dtId = 'cost'; // TODO - get from grid extraction
  }

  setColumns(colHeaders: string[][], colLevels: string[]) {
    this.colHeaders = colHeaders;
    this.colLevels = colLevels;
  }

  setRows(rowHeaders: string[][], rowLevels: string[]) {
    this.rowHeaders = rowHeaders;
    this.rowLevels = rowLevels;
  }

  setPages(pageHeaders: string[], pageLevels: string[]) {
    this.pageHeaders = pageHeaders;
    this.pageLevels = pageLevels;
  }

  setCells(cells: number[][]) {
    this.cellData = cells;
  }

  get vizTitle(): string {
    return this.title;
  }

  get datatypeId(): string {
    return this.dtId;
  }

  get maxValue(): number {
    return _.max(_.flatten(this.cellData)) ?? 0;
  }

  get minValue(): number {
    return _.min(_.flatten(this.cellData)) ?? 0;
  }

  getDimensions(ignoreSingleDims: boolean): VizDimension[] {
    const dims = new Array<VizDimension>();

    if (this.rowHeaders.length > 0) {
      const items = new Map<number, Set<string>>();
      this.rowHeaders.forEach((row) => {
        for (let i = 0; i < this.rowLevels.length; i += 1) {
          items.set(i, new Set<string>([...(items.get(i) || []), row[i]]));
        }
      });

      items.forEach((value, key) => {
        if (!ignoreSingleDims || value.size !== 1)
          dims.push({
            name: this.rowLevels[key],
            itemCount: value.size,
            axis: 'row',
          } as VizDimension);
      });
    }

    if (this.colHeaders.length > 0) {
      const items = new Map<number, Set<string>>();
      this.colHeaders.forEach((col) => {
        for (let i = 0; i < this.colLevels.length; i += 1) {
          items.set(i, new Set<string>([...(items.get(i) || []), col[i]]));
        }
      });

      items.forEach((value, key) => {
        if (!ignoreSingleDims || value.size !== 1)
          dims.push({
            name: this.colLevels[key],
            itemCount: value.size,
            axis: 'col',
          } as VizDimension);
      });
    }

    if (!ignoreSingleDims)
      this.pageLevels.forEach((page) =>
        dims.push({
          name: page,
          itemCount: 1,
          axis: 'page',
        } as VizDimension)
      );

    return dims;
  }

  hasMultipleDatatypes(): boolean {
    const dims = this.getDimensions(false);
    return (dims.find((d) => d.name === DATATYPE_LEVEL_TAG)?.itemCount ?? 0) > 1;
  }

  cleanLabel(label: string, forceClean = false): string {
    if (!forceClean && !this.stripIds) return label;
    return label.split('|').pop() ?? '[?]';
  }

  getAxisData(axis: string): string[] {
    const ri = this.rowLevels.indexOf(axis);
    if (ri > -1) return this.rowHeaders.map((r) => this.cleanLabel(r[ri]));
    const ci = this.colLevels.indexOf(axis);
    if (ci > -1) return this.colHeaders.map((r) => this.cleanLabel(r[ci]));
    const pi = this.colLevels.indexOf(axis);
    if (pi > -1) return [this.cleanLabel(this.pageHeaders[pi])]; // Page always has single item
    return [];
  }

  getValuesAsArray(axis: VizDimension, header: string, dt: VizDatatype): number[] {
    if (axis.axis === 'row') {
      const ri = this.rowLevels.indexOf(axis.name);
      const hi = this.rowHeaders.map((l) => this.cleanLabel(l[ri], true)).indexOf(header);
      return this.cellData[hi].map((c) => dt.formatValue(c));
    }

    // Cols
    const ci = this.colLevels.indexOf(axis.name);
    const hi = this.colHeaders.map((l) => this.cleanLabel(l[ci], true)).indexOf(header);
    return this.cellData.map((r) => dt.formatValue(r[hi]));
  }
}

export interface VizOptions {
  vizualisationType: string;
  sourceAxis: VizDimension[];
  datatypes: Map<string, VizDatatype>;
}

export interface VizProps {
  data: VizData;
  options: VizOptions;
}
