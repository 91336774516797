import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import {
  ArrowsPointingInIcon,
  ChartBarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import NiceModal from '@ebay/nice-modal-react';
import { RootContext } from '../../../stores/storeProvidor';
import ReportPageSelect from './ReportPageSelect';
import ActionButton from '../../controls/ActionButton';
import { CellClickContext } from '../../../stores/activeReportStore';
import { GridSection } from '../../controls/table/BaseResultGrid';
import { SubReportType } from './subReports/SubReportBase';
import ResultInfoModal from '../../modals/ResultInfoModal';
import ProgresBar from '../../controls/ProgressBar';
import SettingsModal from '../../modals/SettingsDialog';

interface ReportResultInfoProps {
  reportId: string | undefined;
  className: string;
}

const ReportResultInfoPanel = observer(({ reportId, className }: ReportResultInfoProps) => {
  const { activeReportStore, selectionStore } = useContext(RootContext);

  const showResultInfo = () => {
    if (reportId !== undefined) NiceModal.show(ResultInfoModal, { reportId });
  };

  const showReportSettings = () => {
    if (reportId !== undefined) NiceModal.show(SettingsModal, { reportId });
  };

  if (reportId === undefined) return null;

  const [currentPage, pageCount] = activeReportStore.getCurrentPage(reportId);
  const [schemaName, version] = selectionStore.getCurrentSchemaName();
  const context = {
    reportId,
    page: currentPage,
    section: GridSection.Body,
  } as CellClickContext;

  const selectionDiv = (
    <div className="inline-flex space-x-2 py-2">
      <ActionButton
        onClick={() => activeReportStore.attachSubReport(SubReportType.Drilldown, 'Drilldown', context, true)}
        disabled={!activeReportStore.hasGridSelection(reportId, currentPage)}
      >
        <ArrowsPointingInIcon className="h-4" />
      </ActionButton>
      <ActionButton
        onClick={() => activeReportStore.attachSubReport(SubReportType.Chart, 'Pie-Chart', context, true)}
        disabled={!activeReportStore.hasGridSelection(reportId, currentPage)}
      >
        <ChartPieIcon className="h-4" />
      </ActionButton>
      <ActionButton
        onClick={() => activeReportStore.attachSubReport(SubReportType.Chart, 'Distribution-Chart', context, true)}
        disabled={!activeReportStore.hasGridSelection(reportId, currentPage)}
      >
        <ChartBarIcon className="h-4" />
      </ActionButton>
    </div>
  );

  return (
    <div className={`${className} bg-slate-400 p-2 rounded-xl flex flex-col`}>
      <h1 className="flex-initial text-lg text-center w-full pb-4">
        Results
        <button className="float-left text-slate-400 hover:text-blue-300" onClick={showResultInfo} type="button">
          <InformationCircleIcon className="h-6" />
        </button>
        <button className="float-right text-teal-600 hover:text-teal-100" onClick={showReportSettings} type="button">
          <Cog6ToothIcon className="h-6" />
        </button>
      </h1>
      <div>
        <b>Data: </b>
        {schemaName} @ {version}
      </div>
      <div>
        <b>Pages:</b> Showing page {currentPage + 1} of {pageCount}
      </div>
      <ReportPageSelect
        reportId={reportId}
        onPageChanged={(pageId) => {
          activeReportStore.setPage(reportId, pageId);
        }}
      />
      {selectionDiv}
      <div className="flex-grow" />
      <ProgresBar percent={activeReportStore.getPercentComplete(reportId)} />
    </div>
  );
});

export default ReportResultInfoPanel;
