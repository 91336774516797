import { arrayMove as dndKitArrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';

// -----------------------------------------------------------
// Base types

export interface ItemWithId {
  id: string;
}

export interface BaseStorage<T> {
  sourceGroup: T[];
}

export interface LayoutItemStorage<T> extends BaseStorage<T> {
  pagesGroup: T[];
  rowsGroup: T[];
  colsGroup: T[];
}

export const SOURCE_GROUP = 'sourceGroup';
export const DragIdGroupSeparator = '@';
export const DragIdPrependSeparator = '#';

export function baseId<T extends ItemWithId>(obj: T): string {
  return obj.id.split(DragIdGroupSeparator)[0];
}

export function groupFromId<T extends ItemWithId>(obj: T): string {
  return obj.id.split(DragIdGroupSeparator)[1];
}

// -----------------------------------------------------------
// Array utils

export function removeAtIndex<T>(array: T[], index: number) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function insertAtIndex<T>(array: T[], index: number, item: T) {
  return [...array.slice(0, index), item, ...array.slice(index)];
}

export function arrayMove<T>(array: T[], oldIndex: number, newIndex: number) {
  return dndKitArrayMove(array, oldIndex, newIndex);
}

export function removeById<T extends ItemWithId>(array: T[], item: T) {
  const index = array.findIndex((o) => baseId(o) === baseId(item));
  return index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array;
}

// -----------------------------------------------------------
// Drag management

export function moveBetweenContainers<T extends ItemWithId, S extends BaseStorage<T>>(
  items: S,
  activeContainer: string,
  overContainer: string,
  overIndex: number,
  item: T
): S {
  // Ensure there are no existing items with same id already in array. if so remove before we insert
  const destinationArray = items[overContainer as keyof S] as T[];
  const hasItemAlready = destinationArray.find((x) => baseId(x) === baseId(item)) !== undefined;
  return {
    ...items,
    [activeContainer]: removeById(items[activeContainer as keyof S] as T[], item),
    [overContainer]: hasItemAlready ? destinationArray : insertAtIndex(destinationArray, overIndex, item),
  } as S;
}

export function addNewToContainer<T extends ItemWithId, S extends BaseStorage<T>>(
  items: S,
  overContainer: string,
  overIndex: number,
  item: T
): S {
  // If id (baseid) already exists, dont add a new item
  const hasItem = (items[overContainer as keyof S] as T[]).find((x) => baseId(x) === baseId(item)) !== undefined;
  if (hasItem) return items; // ie no change
  return {
    ...items,
    [overContainer]: insertAtIndex(items[overContainer as keyof S] as T[], overIndex, item),
  } as S;
}

export function removeItemById<T extends ItemWithId, S extends BaseStorage<T>>(items: S, fullId: string): S {
  const activeContainer = groupFromId({ id: fullId } as ItemWithId);
  const delIndex =
    activeContainer === SOURCE_GROUP
      ? -1
      : (items[activeContainer as keyof S] as T[]).findIndex((x) => x.id === fullId);
  if (delIndex >= 0) {
    return {
      ...items,
      [activeContainer]: removeAtIndex(items[activeContainer as keyof S] as T[], delIndex),
    } as S;
  }
  return items;
}

export function isItemBeingUsed<T extends ItemWithId, S extends BaseStorage<T>>(items: S, id: string): boolean {
  const bid = baseId({ id } as ItemWithId);
  const keys = Object.keys(items).filter((x) => x !== SOURCE_GROUP) as (keyof S)[];
  return _.reduce(
    keys,
    (acc, key) => {
      if (acc) return acc;
      return (items[key] as T[]).find((x) => baseId(x) === bid) !== undefined;
    },
    false
  );
}

export function getNewItemForId<T extends ItemWithId>(
  sourceItems: T[],
  bId: string,
  group: string,
  createUnknownItem: (id: string) => T,
  idPrependFn: (() => string) | undefined = undefined
): T {
  // Copy an item - adjust id so group makes it unique for screen
  const orig = sourceItems.filter((x) => baseId(x) === bId);
  if (orig.length === 1) {
    // Found - copy prototype, but update id
    const newId = idPrependFn ? `${idPrependFn()}${DragIdPrependSeparator}${bId}` : bId;
    return {
      ...orig[0],
      id: `${newId}${DragIdGroupSeparator}${group}`,
    } as T;
  }

  // Not found - allow id to propagate
  return {
    ...createUnknownItem(bId),
    id: `${bId}${DragIdGroupSeparator}${group}`,
  } as T;
}

export function getNewItemWithGroupInId<T extends ItemWithId>(item: T, group: string): T {
  return {
    ...item,
    id: `${baseId(item)}${DragIdGroupSeparator}${group}`,
  } as T;
}
