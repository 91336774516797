import { useContext } from 'react';
import { Cog6ToothIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import NiceModal from '@ebay/nice-modal-react';
import { RootContext } from '../../../stores/storeProvidor';
import ReportInfoModal from '../../modals/ReportInfoModal';
import ReportSummaryPane from './ReportSummaryPane';
import SettingsModal from '../../modals/SettingsDialog';

interface ReportEditInfoPanelProps {
  reportId: string | undefined;
  className: string;
}

const ReportEditInfoPanel = observer(({ reportId, className }: ReportEditInfoPanelProps) => {
  const { layoutDragStore } = useContext(RootContext);

  const showReportInfo = () => {
    if (reportId !== undefined) NiceModal.show(ReportInfoModal, { reportId });
  };

  const showReportSettings = () => {
    if (reportId !== undefined) NiceModal.show(SettingsModal, { reportId });
  };

  let msgs: string[] = [];
  msgs = msgs.concat(layoutDragStore.getReportLayoutValidationMessages(reportId ?? ''));

  return (
    <div className={`${className} bg-gray-200 border border-gray-400 shadow-md p-2 rounded-xl pb-6`}>
      <h1 className="flex-initial text-lg text-center w-full pb-4">
        <button className="float-left text-gray-200 hover:text-blue-400" onClick={showReportInfo} type="button">
          <InformationCircleIcon className="h-6" />
        </button>
        <button className="float-right text-teal-600 hover:text-teal-100" onClick={showReportSettings} type="button">
          <Cog6ToothIcon className="h-6" />
        </button>
        Selection Summary
      </h1>
      {reportId && <ReportSummaryPane reportId={reportId} />}
      <div className="mt-4 pt-1 border-t border-gray-400 flex flex-col">
        {msgs.map((m) => (
          <div key={m} className="bg-red-200 border-2 border-red-700 rounded-xl my-1 mx-2 p-2 text-center shadow-lg">
            <div className="inline px-4">
              <ExclamationTriangleIcon className="inline h-8 w-8 text-red-700 pr-1" />
              {m}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default ReportEditInfoPanel;
