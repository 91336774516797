import { useEffect, useState } from 'react';

/*
 * Input with a delay. Use for active filtering...
 */

interface DebouncedInputProps {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  className: string;
  placeholder: string;
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  className,
  placeholder,
}: DebouncedInputProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input className={className} value={value} onChange={(e) => setValue(e.target.value)} placeholder={placeholder} />
  );
}

DebouncedInput.defaultProps = {
  debounce: 500,
};

export default DebouncedInput;
