import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { RootContext } from '../../stores/storeProvidor';
import AppGrid4x6 from './AppGrid';
import { GridCard, GridCardHalf } from '../controls/GridCard';
import { MruEntry, MruList } from '../controls/MruList';
import { useAppTitle } from '../../hooks/useTitle';
import useOpenReport from '../../hooks/useReport';

const AppHome = observer(() => {
  const { t } = useTranslation(['app']);
  const { openReportById } = useOpenReport();
  const { availReportStore } = useContext(RootContext);

  useAppTitle(t('home.title'), ClipboardDocumentIcon);

  useEffect(() => {
    if (availReportStore.recentReportsStale) availReportStore.loadRecentReports();
  }, [availReportStore, availReportStore.recentReportsStale]);

  return (
    <AppGrid4x6>
      <GridCard>
        <MruList title={t('home.recent')}>
          {availReportStore.recentReports.map((r, i) => (
            <MruEntry key={r.id} index={i} name={r.name} tags={r.tags} clickAction={() => openReportById(r.id)} />
          ))}
        </MruList>
      </GridCard>
      <GridCardHalf>
        {/*
        <MruList title="Recent Selections">
          <MruEntry index={0} name="blah" tags={[]} clickAction={() => {}} />
          <MruEntry index={1} name="blah" tags={['GroupM']} clickAction={() => {}} />
          <MruEntry index={2} name="blah" tags={['FY2023']} clickAction={() => {}} />
        </MruList>
          */}
      </GridCardHalf>
      <GridCardHalf>
        {/*
        <MruList title="Recent Workspaces">
          <MruEntry index={0} name="test" tags={['GroupM']} clickAction={() => {}} />
          <MruEntry index={1} name="test" tags={[]} clickAction={() => {}} />
          <MruEntry index={2} name="test" tags={['QA']} clickAction={() => {}} />
        </MruList>
        */}
      </GridCardHalf>
    </AppGrid4x6>
  );
});

export default AppHome;
