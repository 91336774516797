import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { SubReportBaseProps } from './SubReportBase';
import { RootContext } from '../../../../stores/storeProvidor';

export default function SubReportTitleBar({ subReport }: SubReportBaseProps) {
  const { activeReportStore, uiState } = useContext(RootContext);

  let title = subReport?.title ?? 'Unknown';
  if (subReport?.vizData) title = `${subReport?.title} - ${subReport?.vizData?.vizTitle}`;

  return (
    <div className="text-gray-200 w-full flex">
      <div className="mt-1 mr-2">
        <button
          type="button"
          className="inline px-2 rounded-xl h-6 hover:bg-green-400"
          onClick={() => {
            if (subReport?.ownerReportId) activeReportStore.moveToNextSubReport(subReport.ownerReportId, true);
          }}
        >
          <ChevronDoubleLeftIcon className="w-4" />
        </button>
        <button
          type="button"
          className="inline px-2 rounded-xl h-6 hover:bg-green-400"
          onClick={() => {
            if (subReport?.ownerReportId) activeReportStore.moveToNextSubReport(subReport.ownerReportId, false);
          }}
        >
          <ChevronDoubleRightIcon className="w-4" />
        </button>
      </div>
      <div className="grow font-extrabold mt-1">Sub-Report: {title}</div>
      <div className="">
        <button
          type="button"
          className="text-sm text-gray-700 bg-green-300 rounded-xl px-2 py-1"
          onClick={() => {
            uiState.warningAlert = 'Create report from sub-report - Still Todo';
          }}
        >
          Create as Report
        </button>
      </div>
    </div>
  );
}
