import { makeAutoObservable, runInAction } from 'mobx';
import { DateTime } from 'luxon';
import _ from 'lodash';
import type RootStore from './rootStore';
import { AdminUser, CreateOrgDto, CreateUserDto } from '../models/userModel';
import adminService from '../services/adminService';
import { Organisation } from '../models/orgModels';
import { AdminRole, AvailablePermission } from '../models/roleModels';
import { Schema } from '../models/schemaModels';
import { SystemSummary } from '../Components/admin/models/SystemSummary';
import { ReportActivity, UserActivity } from '../Components/admin/models/Activity';

class AdminStore {
  private rootStore: RootStore;

  users: AdminUser[];

  organisations: Organisation[];

  roles: AdminRole[];

  permissions: AvailablePermission[];

  lastRefresh: DateTime | undefined;

  availableSchemas: Schema[];

  systemSummary: SystemSummary | undefined;

  reportActivity: ReportActivity[];

  userActivity: UserActivity[];

  constructor(root: RootStore) {
    this.rootStore = root;

    this.users = [];
    this.organisations = [];
    this.roles = [];
    this.permissions = [];
    this.availableSchemas = [];
    this.systemSummary = undefined;
    this.reportActivity = [];
    this.userActivity = [];

    makeAutoObservable(this);
  }

  async refresh() {
    const forceRefresh =
      this.lastRefresh === undefined || (this.lastRefresh?.diff(DateTime.now(), 'seconds')?.seconds ?? 999) > 60;
    this.lastRefresh = DateTime.now();

    if (forceRefresh || this.users.length === 0) await this.refreshUserList();
    if (forceRefresh || this.organisations.length === 0) await this.refreshOrganisations();
    if (forceRefresh || this.roles.length === 0) await this.refreshRoles();
    if (forceRefresh || this.roles.length === 0) await this.refreshPermissions();
  }

  async refreshUserList() {
    try {
      const respone = await adminService.api.getUsers();
      runInAction(() => {
        this.users = respone;
      });
    } catch (e) {
      runInAction(() => {
        this.users = [];
      });
    }
  }

  async createNewUser(modifiedUser: CreateUserDto) {
    try {
      await adminService.api.createNewUser(modifiedUser);
      this.refreshUserList();
    } catch {
      this.rootStore.uiState.errorAlert = `Failed to create user`;
      throw new Error('Failed to create user');
    }
  }

  async updateUser(modifiedUser: AdminUser) {
    try {
      await adminService.api.updateUser(modifiedUser);
      this.refreshUserList();
    } catch {
      this.rootStore.uiState.errorAlert = `Failed to update user`;
    }
  }

  async bulkUpdateUsers(selectedUsers: AdminUser[], change: { isActive: boolean | undefined }) {
    const ourUid = this.rootStore.activeUserStore.getUser?.id;
    const wantedIds = selectedUsers.map((u) => u.id);
    const userCopy = _.cloneDeep(this.users.filter((u) => wantedIds.includes(u.id))).map((u) => ({
      // TODO - add any other bulk changes here
      ...u,
      isActive: u.id === ourUid ? true : change.isActive ?? u.isActive, // Note cannot deactivate ourselves!
    }));

    try {
      await Promise.all(userCopy.map((u) => adminService.api.updateUser(u)));
      this.refreshUserList();
    } catch {
      this.rootStore.uiState.errorAlert = `Failed to update accounts`;
    }
  }

  async refreshOrganisations() {
    try {
      const respone = await adminService.api.getOrgs();
      runInAction(() => {
        this.organisations = respone;
      });
    } catch (e) {
      runInAction(() => {
        this.organisations = [];
      });
    }
  }

  findOrganisaionById(orgId: number): Organisation | undefined {
    return this.organisations.find((o) => o.id === orgId);
  }

  activeOrganisaions(): Organisation[] {
    return this.organisations.filter((o) => o.isActive);
  }

  async refreshRoles() {
    try {
      const respone = await adminService.api.getRoles();
      runInAction(() => {
        this.roles = respone;
      });
    } catch (e) {
      runInAction(() => {
        this.roles = [];
      });
    }
  }

  async refreshPermissions() {
    try {
      const respone = await adminService.api.getAvailablePermissions();
      runInAction(() => {
        this.permissions = respone;
      });
    } catch (e) {
      runInAction(() => {
        this.permissions = [];
      });
    }
  }

  findRoleById(roleId: string): AdminRole | undefined {
    return this.roles.find((r) => r.id === roleId);
  }

  getRolesByOrg(wantedOrgId: number): AdminRole[] {
    return this.roles.filter((r) => r.organisation.id === wantedOrgId && r.isActive && r.organisation.isActive);
  }

  async updateUsersRole(users: AdminUser[], roleId: number): Promise<void> {
    const ps = users.map((u) => adminService.api.setUserRoles(u.id, roleId));
    await Promise.all(ps);
    this.refreshUserList();
  }

  async copyRole(newRole: AdminRole): Promise<void> {
    try {
      await adminService.api.copyRole(newRole);
      runInAction(() => {
        this.refresh();
      });
    } catch {
      this.rootStore.uiState.errorAlert = `Failed to update roles`;
    }
  }

  async updateRoles(modifiedRoles: AdminRole[]) {
    try {
      const ps = modifiedRoles.map((r) => adminService.api.updateRole(r));
      await Promise.all(ps);
      runInAction(() => {
        this.refreshRoles();
      });
    } catch {
      this.rootStore.uiState.errorAlert = `Failed to update roles`;
    }
  }

  async updateOrg(modifiedOrgs: Organisation[]) {
    try {
      const ps = modifiedOrgs.map((o) => adminService.api.updateOrg(o));
      await Promise.all(ps);
      runInAction(() => {
        this.refreshOrganisations();
      });
    } catch {
      this.rootStore.uiState.errorAlert = `Failed to update organisations`;
    }
  }

  async createNewOrg(modifiedUser: CreateOrgDto) {
    try {
      await adminService.api.createNewOrg(modifiedUser);
      this.refreshOrganisations();
      this.refreshRoles();
    } catch {
      this.rootStore.uiState.errorAlert = `Failed to create organisation`;
      throw new Error('Failed to create organisation');
    }
  }

  getAllUsersForOrg(orgId: number, includeInactive: boolean): AdminUser[] {
    return this.users.filter((u) => u.role.organisation.orgId === orgId && (includeInactive || u.isActive));
  }

  async refreshSchema() {
    try {
      const schema = await adminService.api.getAdminSchema();
      runInAction(() => {
        this.availableSchemas = schema;
      });
    } catch (e) {
      runInAction(() => {
        this.availableSchemas = [];
      });
    }
  }

  getAllSchema(): Schema[] {
    return this.availableSchemas;
  }

  async refreshSystemSummary() {
    try {
      const sysSummary = await adminService.api.getSystemSummary();
      runInAction(() => {
        this.systemSummary = sysSummary;
      });
    } catch (e) {
      runInAction(() => {
        this.systemSummary = undefined;
      });
    }
  }

  getSystemSummary(): SystemSummary | undefined {
    return this.systemSummary;
  }

  async refreshReportActivity() {
    try {
      const act = await adminService.api.getReportActivity(50);
      runInAction(() => {
        this.reportActivity = act;
      });
    } catch (e) {
      runInAction(() => {
        this.reportActivity = [];
      });
    }
  }

  getReportActivity(): ReportActivity[] {
    return this.reportActivity;
  }

  async refreshUserActivity() {
    try {
      const act = await adminService.api.getUserActivity(50);
      runInAction(() => {
        this.userActivity = act;
      });
    } catch (e) {
      runInAction(() => {
        this.userActivity = [];
      });
    }
  }

  getUserActivity(): UserActivity[] {
    return this.userActivity;
  }
}

export default AdminStore;
