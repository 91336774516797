import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import type RootStore from './rootStore';

export interface BufferedRowResult {
  startRow: number; // The first row in the memory
  rowCount: number; // The number of rows in memory
  totalRows: number; // The total number of rows
}

interface RowCurrentResultWithQueue extends BufferedRowResult {
  requestedRow: number | undefined; // The last row requested
}

// Manages virtual grid rows as the grid is scrolled
class RowScrollOffsetStore {
  private rootStore: RootStore;

  private rowQueues: Map<string, RowCurrentResultWithQueue> = new Map<string, RowCurrentResultWithQueue>();

  public readonly uIPaddingRowCount = 50; // Number of rows to load before the requested row

  public readonly rowsToKeepInMemory = 1000; // Number of rows to keep in memory

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this);
  }

  static getId(reportId: string, page: number) {
    return `${reportId}-${page}`;
  }

  setLimits(id: string, current: BufferedRowResult) {
    this.rowQueues.set(id, {
      ...current,
      requestedRow: undefined,
    });
  }

  requestRowIndex(id: string, rowIndex: number, triggerReloadFn: (() => void) | undefined): boolean {
    const rq = this.rowQueues.get(id);
    if (rq === undefined) throw new Error('RowQueue not initialized; call setLimits() first');

    const lastRequestedRow = rq.requestedRow ?? rowIndex;

    // Is the requested row outside our currnet loaded range?
    const endRow = rq.startRow + rq.rowCount - 1;
    if (rowIndex < rq.startRow || rowIndex > endRow) {
      // Yes - track last row that has been requested
      if (lastRequestedRow !== rq.requestedRow) {
        this.rowQueues.set(id, { ...rq, requestedRow: rowIndex });

        // Trigger a timer for the reload of the data
        if (triggerReloadFn) {
          triggerReloadFn();
        }
      }

      // Row is outside memory range - show progress
      return true;
    }

    // Row is inside memory range - no progress needed
    return false;
  }

  getCurrentRowRange(id: string): { from: number | undefined; to: number | undefined } {
    const rq = this.rowQueues.get(id);
    if (rq === undefined) throw new Error('RowQueue not initialized; call setLimits() first');

    if (rq.requestedRow === undefined) return { from: undefined, to: undefined };

    // What range do we want?
    const wantedFromRow = _.max([0, rq.requestedRow - this.uIPaddingRowCount]) ?? 0;
    const wantedToRow =
      _.min([rq.totalRows - 1, rq.requestedRow - this.uIPaddingRowCount + this.rowsToKeepInMemory - 1]) ??
      rq.totalRows - 1;

    // What range do we have?
    const dataFirst = rq.startRow;
    const dataLast = rq.startRow + rq.rowCount + 1;

    // Do we have the data we need? If so skip the load
    if (wantedFromRow >= dataFirst && wantedToRow <= dataLast) return { from: undefined, to: undefined }; // Already have data

    return {
      from: wantedFromRow,
      to: wantedToRow,
    };
  }

  getRowSpec(id: string): RowCurrentResultWithQueue | undefined {
    return this.rowQueues.get(id);
  }

  cleanupRowSpecs(reportId: string) {
    const keysToDelete = Array.from(this.rowQueues.keys()).filter((k) => k.startsWith(reportId));
    keysToDelete.forEach((k) => this.rowQueues.delete(k));
  }
}

export default RowScrollOffsetStore;
