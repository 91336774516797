import { PropsWithChildren, useContext } from 'react';
import { createSignalRContext } from 'react-signalr';
import { observer } from 'mobx-react-lite';
import { RootContext } from '../stores/storeProvidor';
import { type NotifyHubActions } from './notifications';

export const SignalRContext = createSignalRContext<NotifyHubActions>();

const SignalRProvider = observer(({ children }: PropsWithChildren) => {
  const { authStore } = useContext(RootContext);
  const token = authStore.getToken;

  if (token === undefined) {
    return <div>{children}</div>;
  }

  // Connect to SignalR only if we are logged in
  return (
    <SignalRContext.Provider
      accessTokenFactory={() => token}
      dependencies={[token]} // remove previous connection and create a new connection if changed
      url={import.meta.env.VITE_SIGNALR_BASE_URI}
      onError={(error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        return Promise.resolve();
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
});

export default SignalRProvider;
