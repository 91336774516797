import ReportResultGrid from '../ReportResultGrid';
import { SubReportBaseProps } from './SubReportBase';
import SubReportTitleBar from './SubReportTitleBar';

function DrilldownSubReport({ subReport }: SubReportBaseProps) {
  return (
    <div className="h-full w-full p-1 flex flex-col gap-1">
      {subReport ? (
        <>
          <SubReportTitleBar subReport={subReport} />
          <div className="grow w-full bg-white border border-gray-200 rounded-md p-0.5">
            <ReportResultGrid reportId={subReport?.id} options={undefined} />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default DrilldownSubReport;
