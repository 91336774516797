import { ResponsiveBarCanvas } from '@nivo/bar';

// See: https://nivo.rocks/bar/

export type StackGroup = {
  x: string;
} & { [key: string]: number };

type StackedBarplotProps = {
  data: StackGroup[];
};

const bpcData = [
  {
    country: 'AD',
    'hot dog': 168,
    'hot dogColor': 'hsl(201, 70%, 50%)',
    burger: 97,
    burgerColor: 'hsl(72, 70%, 50%)',
    sandwich: 59,
    sandwichColor: 'hsl(190, 70%, 50%)',
    kebab: 188,
    kebabColor: 'hsl(149, 70%, 50%)',
    fries: 111,
    friesColor: 'hsl(258, 70%, 50%)',
    donut: 137,
    donutColor: 'hsl(290, 70%, 50%)',
    junk: 28,
    junkColor: 'hsl(222, 70%, 50%)',
    sushi: 35,
    sushiColor: 'hsl(162, 70%, 50%)',
    ramen: 27,
    ramenColor: 'hsl(17, 70%, 50%)',
    curry: 117,
    curryColor: 'hsl(16, 70%, 50%)',
    udon: 159,
    udonColor: 'hsl(139, 70%, 50%)',
    bagel: 81,
    bagelColor: 'hsl(193, 70%, 50%)',
    yakitori: 158,
    yakitoriColor: 'hsl(28, 70%, 50%)',
    takoyaki: 69,
    takoyakiColor: 'hsl(272, 70%, 50%)',
    tacos: 115,
    tacosColor: 'hsl(208, 70%, 50%)',
    'miso soup': 14,
    'miso soupColor': 'hsl(42, 70%, 50%)',
    tortilla: 162,
    tortillaColor: 'hsl(301, 70%, 50%)',
    tapas: 109,
    tapasColor: 'hsl(151, 70%, 50%)',
    chipirones: 145,
    chipironesColor: 'hsl(40, 70%, 50%)',
    gazpacho: 152,
    gazpachoColor: 'hsl(214, 70%, 50%)',
    soba: 78,
    sobaColor: 'hsl(29, 70%, 50%)',
    bavette: 63,
    bavetteColor: 'hsl(126, 70%, 50%)',
    steak: 37,
    steakColor: 'hsl(143, 70%, 50%)',
    pizza: 172,
    pizzaColor: 'hsl(148, 70%, 50%)',
    spaghetti: 14,
    spaghettiColor: 'hsl(232, 70%, 50%)',
    ravioli: 65,
    ravioliColor: 'hsl(292, 70%, 50%)',
    salad: 150,
    saladColor: 'hsl(296, 70%, 50%)',
    'pad thai': 118,
    'pad thaiColor': 'hsl(354, 70%, 50%)',
    bun: 120,
    bunColor: 'hsl(202, 70%, 50%)',
    waffle: 59,
    waffleColor: 'hsl(56, 70%, 50%)',
    crepe: 94,
    crepeColor: 'hsl(174, 70%, 50%)',
    churros: 40,
    churrosColor: 'hsl(317, 70%, 50%)',
    paella: 146,
    paellaColor: 'hsl(143, 70%, 50%)',
    empanadas: 24,
    empanadasColor: 'hsl(109, 70%, 50%)',
    bruschetta: 138,
    bruschettaColor: 'hsl(292, 70%, 50%)',
    'onion soup': 87,
    'onion soupColor': 'hsl(7, 70%, 50%)',
    cassoulet: 159,
    cassouletColor: 'hsl(118, 70%, 50%)',
    bouillabaisse: 22,
    bouillabaisseColor: 'hsl(264, 70%, 50%)',
    unagi: 134,
    unagiColor: 'hsl(268, 70%, 50%)',
    tempura: 102,
    tempuraColor: 'hsl(124, 70%, 50%)',
    tonkatsu: 57,
    tonkatsuColor: 'hsl(163, 70%, 50%)',
    'shabu-shabu': 25,
    'shabu-shabuColor': 'hsl(266, 70%, 50%)',
    twinkies: 164,
    twinkiesColor: 'hsl(296, 70%, 50%)',
    jerky: 7,
    jerkyColor: 'hsl(161, 70%, 50%)',
    fajitas: 101,
    fajitasColor: 'hsl(3, 70%, 50%)',
    jambalaya: 191,
    jambalayaColor: 'hsl(318, 70%, 50%)',
    meatloaf: 70,
    meatloafColor: 'hsl(212, 70%, 50%)',
    "mac n' cheese": 171,
    "mac n' cheeseColor": 'hsl(56, 70%, 50%)',
    'baked beans': 0,
    'baked beansColor': 'hsl(196, 70%, 50%)',
    popcorn: 15,
    popcornColor: 'hsl(323, 70%, 50%)',
    'buffalo wings': 38,
    'buffalo wingsColor': 'hsl(229, 70%, 50%)',
    'BBQ ribs': 51,
    'BBQ ribsColor': 'hsl(245, 70%, 50%)',
    'apple pie': 101,
    'apple pieColor': 'hsl(356, 70%, 50%)',
    nachos: 111,
    nachosColor: 'hsl(15, 70%, 50%)',
    risotto: 168,
    risottoColor: 'hsl(124, 70%, 50%)',
    tiramisu: 39,
    tiramisuColor: 'hsl(356, 70%, 50%)',
  },
  {
    country: 'AE',
    'hot dog': 127,
    'hot dogColor': 'hsl(338, 70%, 50%)',
    burger: 61,
    burgerColor: 'hsl(337, 70%, 50%)',
    sandwich: 23,
    sandwichColor: 'hsl(321, 70%, 50%)',
    kebab: 92,
    kebabColor: 'hsl(117, 70%, 50%)',
    fries: 119,
    friesColor: 'hsl(316, 70%, 50%)',
    donut: 154,
    donutColor: 'hsl(319, 70%, 50%)',
    junk: 138,
    junkColor: 'hsl(341, 70%, 50%)',
    sushi: 122,
    sushiColor: 'hsl(343, 70%, 50%)',
    ramen: 132,
    ramenColor: 'hsl(70, 70%, 50%)',
    curry: 110,
    curryColor: 'hsl(114, 70%, 50%)',
    udon: 52,
    udonColor: 'hsl(17, 70%, 50%)',
    bagel: 168,
    bagelColor: 'hsl(162, 70%, 50%)',
    yakitori: 93,
    yakitoriColor: 'hsl(280, 70%, 50%)',
    takoyaki: 200,
    takoyakiColor: 'hsl(169, 70%, 50%)',
    tacos: 42,
    tacosColor: 'hsl(288, 70%, 50%)',
    'miso soup': 196,
    'miso soupColor': 'hsl(194, 70%, 50%)',
    tortilla: 146,
    tortillaColor: 'hsl(206, 70%, 50%)',
    tapas: 14,
    tapasColor: 'hsl(265, 70%, 50%)',
    chipirones: 163,
    chipironesColor: 'hsl(171, 70%, 50%)',
    gazpacho: 60,
    gazpachoColor: 'hsl(39, 70%, 50%)',
    soba: 80,
    sobaColor: 'hsl(347, 70%, 50%)',
    bavette: 161,
    bavetteColor: 'hsl(61, 70%, 50%)',
    steak: 97,
    steakColor: 'hsl(143, 70%, 50%)',
    pizza: 117,
    pizzaColor: 'hsl(221, 70%, 50%)',
    spaghetti: 80,
    spaghettiColor: 'hsl(266, 70%, 50%)',
    ravioli: 165,
    ravioliColor: 'hsl(28, 70%, 50%)',
    salad: 91,
    saladColor: 'hsl(27, 70%, 50%)',
    'pad thai': 96,
    'pad thaiColor': 'hsl(150, 70%, 50%)',
    bun: 53,
    bunColor: 'hsl(321, 70%, 50%)',
    waffle: 93,
    waffleColor: 'hsl(64, 70%, 50%)',
    crepe: 141,
    crepeColor: 'hsl(206, 70%, 50%)',
    churros: 99,
    churrosColor: 'hsl(346, 70%, 50%)',
    paella: 14,
    paellaColor: 'hsl(242, 70%, 50%)',
    empanadas: 104,
    empanadasColor: 'hsl(285, 70%, 50%)',
    bruschetta: 194,
    bruschettaColor: 'hsl(90, 70%, 50%)',
    'onion soup': 76,
    'onion soupColor': 'hsl(44, 70%, 50%)',
    cassoulet: 4,
    cassouletColor: 'hsl(53, 70%, 50%)',
    bouillabaisse: 144,
    bouillabaisseColor: 'hsl(212, 70%, 50%)',
    unagi: 52,
    unagiColor: 'hsl(183, 70%, 50%)',
    tempura: 123,
    tempuraColor: 'hsl(264, 70%, 50%)',
    tonkatsu: 80,
    tonkatsuColor: 'hsl(120, 70%, 50%)',
    'shabu-shabu': 169,
    'shabu-shabuColor': 'hsl(73, 70%, 50%)',
    twinkies: 139,
    twinkiesColor: 'hsl(206, 70%, 50%)',
    jerky: 34,
    jerkyColor: 'hsl(251, 70%, 50%)',
    fajitas: 185,
    fajitasColor: 'hsl(161, 70%, 50%)',
    jambalaya: 86,
    jambalayaColor: 'hsl(18, 70%, 50%)',
    meatloaf: 1,
    meatloafColor: 'hsl(144, 70%, 50%)',
    "mac n' cheese": 78,
    "mac n' cheeseColor": 'hsl(316, 70%, 50%)',
    'baked beans': 165,
    'baked beansColor': 'hsl(58, 70%, 50%)',
    popcorn: 166,
    popcornColor: 'hsl(59, 70%, 50%)',
    'buffalo wings': 100,
    'buffalo wingsColor': 'hsl(81, 70%, 50%)',
    'BBQ ribs': 55,
    'BBQ ribsColor': 'hsl(217, 70%, 50%)',
    'apple pie': 192,
    'apple pieColor': 'hsl(356, 70%, 50%)',
    nachos: 18,
    nachosColor: 'hsl(177, 70%, 50%)',
    risotto: 135,
    risottoColor: 'hsl(163, 70%, 50%)',
    tiramisu: 91,
    tiramisuColor: 'hsl(290, 70%, 50%)',
  },
  {
    country: 'AF',
    'hot dog': 199,
    'hot dogColor': 'hsl(354, 70%, 50%)',
    burger: 20,
    burgerColor: 'hsl(121, 70%, 50%)',
    sandwich: 109,
    sandwichColor: 'hsl(238, 70%, 50%)',
    kebab: 22,
    kebabColor: 'hsl(86, 70%, 50%)',
    fries: 123,
    friesColor: 'hsl(190, 70%, 50%)',
    donut: 100,
    donutColor: 'hsl(321, 70%, 50%)',
    junk: 151,
    junkColor: 'hsl(286, 70%, 50%)',
    sushi: 58,
    sushiColor: 'hsl(239, 70%, 50%)',
    ramen: 178,
    ramenColor: 'hsl(102, 70%, 50%)',
    curry: 180,
    curryColor: 'hsl(89, 70%, 50%)',
    udon: 64,
    udonColor: 'hsl(4, 70%, 50%)',
    bagel: 176,
    bagelColor: 'hsl(78, 70%, 50%)',
    yakitori: 41,
    yakitoriColor: 'hsl(134, 70%, 50%)',
    takoyaki: 25,
    takoyakiColor: 'hsl(89, 70%, 50%)',
    tacos: 113,
    tacosColor: 'hsl(349, 70%, 50%)',
    'miso soup': 104,
    'miso soupColor': 'hsl(151, 70%, 50%)',
    tortilla: 83,
    tortillaColor: 'hsl(224, 70%, 50%)',
    tapas: 44,
    tapasColor: 'hsl(230, 70%, 50%)',
    chipirones: 186,
    chipironesColor: 'hsl(266, 70%, 50%)',
    gazpacho: 7,
    gazpachoColor: 'hsl(152, 70%, 50%)',
    soba: 115,
    sobaColor: 'hsl(18, 70%, 50%)',
    bavette: 103,
    bavetteColor: 'hsl(166, 70%, 50%)',
    steak: 10,
    steakColor: 'hsl(185, 70%, 50%)',
    pizza: 158,
    pizzaColor: 'hsl(111, 70%, 50%)',
    spaghetti: 20,
    spaghettiColor: 'hsl(185, 70%, 50%)',
    ravioli: 119,
    ravioliColor: 'hsl(53, 70%, 50%)',
    salad: 178,
    saladColor: 'hsl(332, 70%, 50%)',
    'pad thai': 192,
    'pad thaiColor': 'hsl(258, 70%, 50%)',
    bun: 178,
    bunColor: 'hsl(318, 70%, 50%)',
    waffle: 58,
    waffleColor: 'hsl(8, 70%, 50%)',
    crepe: 136,
    crepeColor: 'hsl(13, 70%, 50%)',
    churros: 26,
    churrosColor: 'hsl(342, 70%, 50%)',
    paella: 37,
    paellaColor: 'hsl(276, 70%, 50%)',
    empanadas: 154,
    empanadasColor: 'hsl(59, 70%, 50%)',
    bruschetta: 151,
    bruschettaColor: 'hsl(136, 70%, 50%)',
    'onion soup': 192,
    'onion soupColor': 'hsl(192, 70%, 50%)',
    cassoulet: 61,
    cassouletColor: 'hsl(341, 70%, 50%)',
    bouillabaisse: 162,
    bouillabaisseColor: 'hsl(311, 70%, 50%)',
    unagi: 152,
    unagiColor: 'hsl(79, 70%, 50%)',
    tempura: 100,
    tempuraColor: 'hsl(341, 70%, 50%)',
    tonkatsu: 51,
    tonkatsuColor: 'hsl(355, 70%, 50%)',
    'shabu-shabu': 15,
    'shabu-shabuColor': 'hsl(199, 70%, 50%)',
    twinkies: 184,
    twinkiesColor: 'hsl(5, 70%, 50%)',
    jerky: 8,
    jerkyColor: 'hsl(214, 70%, 50%)',
    fajitas: 11,
    fajitasColor: 'hsl(298, 70%, 50%)',
    jambalaya: 93,
    jambalayaColor: 'hsl(71, 70%, 50%)',
    meatloaf: 146,
    meatloafColor: 'hsl(19, 70%, 50%)',
    "mac n' cheese": 188,
    "mac n' cheeseColor": 'hsl(219, 70%, 50%)',
    'baked beans': 144,
    'baked beansColor': 'hsl(95, 70%, 50%)',
    popcorn: 157,
    popcornColor: 'hsl(131, 70%, 50%)',
    'buffalo wings': 72,
    'buffalo wingsColor': 'hsl(307, 70%, 50%)',
    'BBQ ribs': 56,
    'BBQ ribsColor': 'hsl(188, 70%, 50%)',
    'apple pie': 187,
    'apple pieColor': 'hsl(55, 70%, 50%)',
    nachos: 9,
    nachosColor: 'hsl(274, 70%, 50%)',
    risotto: 7,
    risottoColor: 'hsl(62, 70%, 50%)',
    tiramisu: 65,
    tiramisuColor: 'hsl(161, 70%, 50%)',
  },
  {
    country: 'AG',
    'hot dog': 161,
    'hot dogColor': 'hsl(175, 70%, 50%)',
    burger: 88,
    burgerColor: 'hsl(162, 70%, 50%)',
    sandwich: 38,
    sandwichColor: 'hsl(145, 70%, 50%)',
    kebab: 199,
    kebabColor: 'hsl(194, 70%, 50%)',
    fries: 99,
    friesColor: 'hsl(161, 70%, 50%)',
    donut: 183,
    donutColor: 'hsl(29, 70%, 50%)',
    junk: 136,
    junkColor: 'hsl(315, 70%, 50%)',
    sushi: 93,
    sushiColor: 'hsl(71, 70%, 50%)',
    ramen: 174,
    ramenColor: 'hsl(193, 70%, 50%)',
    curry: 2,
    curryColor: 'hsl(120, 70%, 50%)',
    udon: 108,
    udonColor: 'hsl(72, 70%, 50%)',
    bagel: 29,
    bagelColor: 'hsl(214, 70%, 50%)',
    yakitori: 155,
    yakitoriColor: 'hsl(43, 70%, 50%)',
    takoyaki: 11,
    takoyakiColor: 'hsl(8, 70%, 50%)',
    tacos: 95,
    tacosColor: 'hsl(2, 70%, 50%)',
    'miso soup': 22,
    'miso soupColor': 'hsl(190, 70%, 50%)',
    tortilla: 157,
    tortillaColor: 'hsl(251, 70%, 50%)',
    tapas: 98,
    tapasColor: 'hsl(333, 70%, 50%)',
    chipirones: 100,
    chipironesColor: 'hsl(8, 70%, 50%)',
    gazpacho: 155,
    gazpachoColor: 'hsl(227, 70%, 50%)',
    soba: 66,
    sobaColor: 'hsl(130, 70%, 50%)',
    bavette: 66,
    bavetteColor: 'hsl(137, 70%, 50%)',
    steak: 153,
    steakColor: 'hsl(78, 70%, 50%)',
    pizza: 115,
    pizzaColor: 'hsl(213, 70%, 50%)',
    spaghetti: 137,
    spaghettiColor: 'hsl(302, 70%, 50%)',
    ravioli: 36,
    ravioliColor: 'hsl(108, 70%, 50%)',
    salad: 195,
    saladColor: 'hsl(258, 70%, 50%)',
    'pad thai': 183,
    'pad thaiColor': 'hsl(99, 70%, 50%)',
    bun: 55,
    bunColor: 'hsl(131, 70%, 50%)',
    waffle: 100,
    waffleColor: 'hsl(97, 70%, 50%)',
    crepe: 110,
    crepeColor: 'hsl(29, 70%, 50%)',
    churros: 175,
    churrosColor: 'hsl(62, 70%, 50%)',
    paella: 97,
    paellaColor: 'hsl(149, 70%, 50%)',
    empanadas: 59,
    empanadasColor: 'hsl(108, 70%, 50%)',
    bruschetta: 196,
    bruschettaColor: 'hsl(193, 70%, 50%)',
    'onion soup': 42,
    'onion soupColor': 'hsl(19, 70%, 50%)',
    cassoulet: 71,
    cassouletColor: 'hsl(25, 70%, 50%)',
    bouillabaisse: 126,
    bouillabaisseColor: 'hsl(47, 70%, 50%)',
    unagi: 69,
    unagiColor: 'hsl(321, 70%, 50%)',
    tempura: 116,
    tempuraColor: 'hsl(141, 70%, 50%)',
    tonkatsu: 27,
    tonkatsuColor: 'hsl(313, 70%, 50%)',
    'shabu-shabu': 8,
    'shabu-shabuColor': 'hsl(329, 70%, 50%)',
    twinkies: 87,
    twinkiesColor: 'hsl(182, 70%, 50%)',
    jerky: 180,
    jerkyColor: 'hsl(266, 70%, 50%)',
    fajitas: 164,
    fajitasColor: 'hsl(56, 70%, 50%)',
    jambalaya: 163,
    jambalayaColor: 'hsl(57, 70%, 50%)',
    meatloaf: 152,
    meatloafColor: 'hsl(148, 70%, 50%)',
    "mac n' cheese": 49,
    "mac n' cheeseColor": 'hsl(64, 70%, 50%)',
    'baked beans': 105,
    'baked beansColor': 'hsl(321, 70%, 50%)',
    popcorn: 115,
    popcornColor: 'hsl(334, 70%, 50%)',
    'buffalo wings': 33,
    'buffalo wingsColor': 'hsl(159, 70%, 50%)',
    'BBQ ribs': 88,
    'BBQ ribsColor': 'hsl(269, 70%, 50%)',
    'apple pie': 45,
    'apple pieColor': 'hsl(10, 70%, 50%)',
    nachos: 156,
    nachosColor: 'hsl(242, 70%, 50%)',
    risotto: 197,
    risottoColor: 'hsl(20, 70%, 50%)',
    tiramisu: 107,
    tiramisuColor: 'hsl(67, 70%, 50%)',
  },
  {
    country: 'AI',
    'hot dog': 45,
    'hot dogColor': 'hsl(62, 70%, 50%)',
    burger: 102,
    burgerColor: 'hsl(152, 70%, 50%)',
    sandwich: 159,
    sandwichColor: 'hsl(179, 70%, 50%)',
    kebab: 87,
    kebabColor: 'hsl(173, 70%, 50%)',
    fries: 140,
    friesColor: 'hsl(319, 70%, 50%)',
    donut: 197,
    donutColor: 'hsl(107, 70%, 50%)',
    junk: 54,
    junkColor: 'hsl(76, 70%, 50%)',
    sushi: 156,
    sushiColor: 'hsl(228, 70%, 50%)',
    ramen: 85,
    ramenColor: 'hsl(141, 70%, 50%)',
    curry: 142,
    curryColor: 'hsl(84, 70%, 50%)',
    udon: 87,
    udonColor: 'hsl(143, 70%, 50%)',
    bagel: 92,
    bagelColor: 'hsl(156, 70%, 50%)',
    yakitori: 14,
    yakitoriColor: 'hsl(233, 70%, 50%)',
    takoyaki: 141,
    takoyakiColor: 'hsl(311, 70%, 50%)',
    tacos: 2,
    tacosColor: 'hsl(99, 70%, 50%)',
    'miso soup': 189,
    'miso soupColor': 'hsl(18, 70%, 50%)',
    tortilla: 80,
    tortillaColor: 'hsl(125, 70%, 50%)',
    tapas: 50,
    tapasColor: 'hsl(324, 70%, 50%)',
    chipirones: 16,
    chipironesColor: 'hsl(327, 70%, 50%)',
    gazpacho: 121,
    gazpachoColor: 'hsl(180, 70%, 50%)',
    soba: 118,
    sobaColor: 'hsl(236, 70%, 50%)',
    bavette: 140,
    bavetteColor: 'hsl(243, 70%, 50%)',
    steak: 129,
    steakColor: 'hsl(162, 70%, 50%)',
    pizza: 170,
    pizzaColor: 'hsl(90, 70%, 50%)',
    spaghetti: 65,
    spaghettiColor: 'hsl(285, 70%, 50%)',
    ravioli: 54,
    ravioliColor: 'hsl(286, 70%, 50%)',
    salad: 113,
    saladColor: 'hsl(336, 70%, 50%)',
    'pad thai': 95,
    'pad thaiColor': 'hsl(215, 70%, 50%)',
    bun: 97,
    bunColor: 'hsl(214, 70%, 50%)',
    waffle: 171,
    waffleColor: 'hsl(30, 70%, 50%)',
    crepe: 193,
    crepeColor: 'hsl(83, 70%, 50%)',
    churros: 158,
    churrosColor: 'hsl(265, 70%, 50%)',
    paella: 6,
    paellaColor: 'hsl(146, 70%, 50%)',
    empanadas: 196,
    empanadasColor: 'hsl(305, 70%, 50%)',
    bruschetta: 23,
    bruschettaColor: 'hsl(18, 70%, 50%)',
    'onion soup': 165,
    'onion soupColor': 'hsl(71, 70%, 50%)',
    cassoulet: 71,
    cassouletColor: 'hsl(283, 70%, 50%)',
    bouillabaisse: 9,
    bouillabaisseColor: 'hsl(335, 70%, 50%)',
    unagi: 44,
    unagiColor: 'hsl(76, 70%, 50%)',
    tempura: 74,
    tempuraColor: 'hsl(274, 70%, 50%)',
    tonkatsu: 9,
    tonkatsuColor: 'hsl(202, 70%, 50%)',
    'shabu-shabu': 152,
    'shabu-shabuColor': 'hsl(24, 70%, 50%)',
    twinkies: 140,
    twinkiesColor: 'hsl(182, 70%, 50%)',
    jerky: 108,
    jerkyColor: 'hsl(261, 70%, 50%)',
    fajitas: 170,
    fajitasColor: 'hsl(120, 70%, 50%)',
    jambalaya: 6,
    jambalayaColor: 'hsl(12, 70%, 50%)',
    meatloaf: 91,
    meatloafColor: 'hsl(223, 70%, 50%)',
    "mac n' cheese": 172,
    "mac n' cheeseColor": 'hsl(5, 70%, 50%)',
    'baked beans': 61,
    'baked beansColor': 'hsl(300, 70%, 50%)',
    popcorn: 52,
    popcornColor: 'hsl(136, 70%, 50%)',
    'buffalo wings': 82,
    'buffalo wingsColor': 'hsl(193, 70%, 50%)',
    'BBQ ribs': 185,
    'BBQ ribsColor': 'hsl(276, 70%, 50%)',
    'apple pie': 157,
    'apple pieColor': 'hsl(15, 70%, 50%)',
    nachos: 179,
    nachosColor: 'hsl(223, 70%, 50%)',
    risotto: 16,
    risottoColor: 'hsl(229, 70%, 50%)',
    tiramisu: 13,
    tiramisuColor: 'hsl(31, 70%, 50%)',
  },
  {
    country: 'AL',
    'hot dog': 99,
    'hot dogColor': 'hsl(149, 70%, 50%)',
    burger: 102,
    burgerColor: 'hsl(39, 70%, 50%)',
    sandwich: 119,
    sandwichColor: 'hsl(313, 70%, 50%)',
    kebab: 42,
    kebabColor: 'hsl(13, 70%, 50%)',
    fries: 55,
    friesColor: 'hsl(282, 70%, 50%)',
    donut: 80,
    donutColor: 'hsl(55, 70%, 50%)',
    junk: 154,
    junkColor: 'hsl(327, 70%, 50%)',
    sushi: 167,
    sushiColor: 'hsl(258, 70%, 50%)',
    ramen: 184,
    ramenColor: 'hsl(156, 70%, 50%)',
    curry: 189,
    curryColor: 'hsl(348, 70%, 50%)',
    udon: 52,
    udonColor: 'hsl(115, 70%, 50%)',
    bagel: 193,
    bagelColor: 'hsl(132, 70%, 50%)',
    yakitori: 176,
    yakitoriColor: 'hsl(322, 70%, 50%)',
    takoyaki: 141,
    takoyakiColor: 'hsl(143, 70%, 50%)',
    tacos: 121,
    tacosColor: 'hsl(239, 70%, 50%)',
    'miso soup': 165,
    'miso soupColor': 'hsl(297, 70%, 50%)',
    tortilla: 133,
    tortillaColor: 'hsl(235, 70%, 50%)',
    tapas: 53,
    tapasColor: 'hsl(156, 70%, 50%)',
    chipirones: 56,
    chipironesColor: 'hsl(228, 70%, 50%)',
    gazpacho: 31,
    gazpachoColor: 'hsl(260, 70%, 50%)',
    soba: 175,
    sobaColor: 'hsl(79, 70%, 50%)',
    bavette: 115,
    bavetteColor: 'hsl(196, 70%, 50%)',
    steak: 124,
    steakColor: 'hsl(336, 70%, 50%)',
    pizza: 62,
    pizzaColor: 'hsl(6, 70%, 50%)',
    spaghetti: 24,
    spaghettiColor: 'hsl(147, 70%, 50%)',
    ravioli: 160,
    ravioliColor: 'hsl(324, 70%, 50%)',
    salad: 11,
    saladColor: 'hsl(308, 70%, 50%)',
    'pad thai': 65,
    'pad thaiColor': 'hsl(161, 70%, 50%)',
    bun: 51,
    bunColor: 'hsl(356, 70%, 50%)',
    waffle: 62,
    waffleColor: 'hsl(270, 70%, 50%)',
    crepe: 199,
    crepeColor: 'hsl(283, 70%, 50%)',
    churros: 160,
    churrosColor: 'hsl(227, 70%, 50%)',
    paella: 140,
    paellaColor: 'hsl(134, 70%, 50%)',
    empanadas: 185,
    empanadasColor: 'hsl(224, 70%, 50%)',
    bruschetta: 72,
    bruschettaColor: 'hsl(251, 70%, 50%)',
    'onion soup': 56,
    'onion soupColor': 'hsl(166, 70%, 50%)',
    cassoulet: 20,
    cassouletColor: 'hsl(284, 70%, 50%)',
    bouillabaisse: 116,
    bouillabaisseColor: 'hsl(126, 70%, 50%)',
    unagi: 199,
    unagiColor: 'hsl(19, 70%, 50%)',
    tempura: 170,
    tempuraColor: 'hsl(313, 70%, 50%)',
    tonkatsu: 96,
    tonkatsuColor: 'hsl(128, 70%, 50%)',
    'shabu-shabu': 4,
    'shabu-shabuColor': 'hsl(170, 70%, 50%)',
    twinkies: 161,
    twinkiesColor: 'hsl(40, 70%, 50%)',
    jerky: 3,
    jerkyColor: 'hsl(17, 70%, 50%)',
    fajitas: 17,
    fajitasColor: 'hsl(313, 70%, 50%)',
    jambalaya: 148,
    jambalayaColor: 'hsl(210, 70%, 50%)',
    meatloaf: 161,
    meatloafColor: 'hsl(353, 70%, 50%)',
    "mac n' cheese": 181,
    "mac n' cheeseColor": 'hsl(95, 70%, 50%)',
    'baked beans': 155,
    'baked beansColor': 'hsl(137, 70%, 50%)',
    popcorn: 106,
    popcornColor: 'hsl(339, 70%, 50%)',
    'buffalo wings': 12,
    'buffalo wingsColor': 'hsl(5, 70%, 50%)',
    'BBQ ribs': 56,
    'BBQ ribsColor': 'hsl(293, 70%, 50%)',
    'apple pie': 39,
    'apple pieColor': 'hsl(36, 70%, 50%)',
    nachos: 92,
    nachosColor: 'hsl(233, 70%, 50%)',
    risotto: 121,
    risottoColor: 'hsl(285, 70%, 50%)',
    tiramisu: 94,
    tiramisuColor: 'hsl(55, 70%, 50%)',
  },
  {
    country: 'AM',
    'hot dog': 74,
    'hot dogColor': 'hsl(162, 70%, 50%)',
    burger: 185,
    burgerColor: 'hsl(345, 70%, 50%)',
    sandwich: 54,
    sandwichColor: 'hsl(234, 70%, 50%)',
    kebab: 86,
    kebabColor: 'hsl(259, 70%, 50%)',
    fries: 70,
    friesColor: 'hsl(134, 70%, 50%)',
    donut: 42,
    donutColor: 'hsl(22, 70%, 50%)',
    junk: 26,
    junkColor: 'hsl(331, 70%, 50%)',
    sushi: 49,
    sushiColor: 'hsl(33, 70%, 50%)',
    ramen: 9,
    ramenColor: 'hsl(100, 70%, 50%)',
    curry: 23,
    curryColor: 'hsl(242, 70%, 50%)',
    udon: 193,
    udonColor: 'hsl(156, 70%, 50%)',
    bagel: 7,
    bagelColor: 'hsl(105, 70%, 50%)',
    yakitori: 81,
    yakitoriColor: 'hsl(267, 70%, 50%)',
    takoyaki: 124,
    takoyakiColor: 'hsl(236, 70%, 50%)',
    tacos: 191,
    tacosColor: 'hsl(332, 70%, 50%)',
    'miso soup': 55,
    'miso soupColor': 'hsl(63, 70%, 50%)',
    tortilla: 28,
    tortillaColor: 'hsl(29, 70%, 50%)',
    tapas: 200,
    tapasColor: 'hsl(108, 70%, 50%)',
    chipirones: 174,
    chipironesColor: 'hsl(316, 70%, 50%)',
    gazpacho: 6,
    gazpachoColor: 'hsl(75, 70%, 50%)',
    soba: 132,
    sobaColor: 'hsl(329, 70%, 50%)',
    bavette: 192,
    bavetteColor: 'hsl(358, 70%, 50%)',
    steak: 53,
    steakColor: 'hsl(182, 70%, 50%)',
    pizza: 169,
    pizzaColor: 'hsl(157, 70%, 50%)',
    spaghetti: 183,
    spaghettiColor: 'hsl(25, 70%, 50%)',
    ravioli: 172,
    ravioliColor: 'hsl(94, 70%, 50%)',
    salad: 32,
    saladColor: 'hsl(234, 70%, 50%)',
    'pad thai': 92,
    'pad thaiColor': 'hsl(167, 70%, 50%)',
    bun: 53,
    bunColor: 'hsl(141, 70%, 50%)',
    waffle: 91,
    waffleColor: 'hsl(94, 70%, 50%)',
    crepe: 75,
    crepeColor: 'hsl(356, 70%, 50%)',
    churros: 37,
    churrosColor: 'hsl(261, 70%, 50%)',
    paella: 183,
    paellaColor: 'hsl(120, 70%, 50%)',
    empanadas: 133,
    empanadasColor: 'hsl(289, 70%, 50%)',
    bruschetta: 87,
    bruschettaColor: 'hsl(309, 70%, 50%)',
    'onion soup': 85,
    'onion soupColor': 'hsl(185, 70%, 50%)',
    cassoulet: 20,
    cassouletColor: 'hsl(301, 70%, 50%)',
    bouillabaisse: 48,
    bouillabaisseColor: 'hsl(109, 70%, 50%)',
    unagi: 179,
    unagiColor: 'hsl(241, 70%, 50%)',
    tempura: 184,
    tempuraColor: 'hsl(215, 70%, 50%)',
    tonkatsu: 15,
    tonkatsuColor: 'hsl(88, 70%, 50%)',
    'shabu-shabu': 149,
    'shabu-shabuColor': 'hsl(94, 70%, 50%)',
    twinkies: 197,
    twinkiesColor: 'hsl(338, 70%, 50%)',
    jerky: 92,
    jerkyColor: 'hsl(215, 70%, 50%)',
    fajitas: 33,
    fajitasColor: 'hsl(352, 70%, 50%)',
    jambalaya: 112,
    jambalayaColor: 'hsl(31, 70%, 50%)',
    meatloaf: 114,
    meatloafColor: 'hsl(17, 70%, 50%)',
    "mac n' cheese": 147,
    "mac n' cheeseColor": 'hsl(56, 70%, 50%)',
    'baked beans': 195,
    'baked beansColor': 'hsl(104, 70%, 50%)',
    popcorn: 68,
    popcornColor: 'hsl(217, 70%, 50%)',
    'buffalo wings': 186,
    'buffalo wingsColor': 'hsl(127, 70%, 50%)',
    'BBQ ribs': 114,
    'BBQ ribsColor': 'hsl(166, 70%, 50%)',
    'apple pie': 75,
    'apple pieColor': 'hsl(295, 70%, 50%)',
    nachos: 189,
    nachosColor: 'hsl(37, 70%, 50%)',
    risotto: 120,
    risottoColor: 'hsl(105, 70%, 50%)',
    tiramisu: 50,
    tiramisuColor: 'hsl(210, 70%, 50%)',
  },
  {
    country: 'AO',
    'hot dog': 21,
    'hot dogColor': 'hsl(129, 70%, 50%)',
    burger: 133,
    burgerColor: 'hsl(203, 70%, 50%)',
    sandwich: 199,
    sandwichColor: 'hsl(94, 70%, 50%)',
    kebab: 197,
    kebabColor: 'hsl(195, 70%, 50%)',
    fries: 72,
    friesColor: 'hsl(352, 70%, 50%)',
    donut: 70,
    donutColor: 'hsl(149, 70%, 50%)',
    junk: 169,
    junkColor: 'hsl(65, 70%, 50%)',
    sushi: 154,
    sushiColor: 'hsl(255, 70%, 50%)',
    ramen: 167,
    ramenColor: 'hsl(229, 70%, 50%)',
    curry: 76,
    curryColor: 'hsl(216, 70%, 50%)',
    udon: 53,
    udonColor: 'hsl(58, 70%, 50%)',
    bagel: 128,
    bagelColor: 'hsl(360, 70%, 50%)',
    yakitori: 75,
    yakitoriColor: 'hsl(43, 70%, 50%)',
    takoyaki: 15,
    takoyakiColor: 'hsl(338, 70%, 50%)',
    tacos: 39,
    tacosColor: 'hsl(125, 70%, 50%)',
    'miso soup': 159,
    'miso soupColor': 'hsl(61, 70%, 50%)',
    tortilla: 134,
    tortillaColor: 'hsl(90, 70%, 50%)',
    tapas: 21,
    tapasColor: 'hsl(151, 70%, 50%)',
    chipirones: 192,
    chipironesColor: 'hsl(8, 70%, 50%)',
    gazpacho: 18,
    gazpachoColor: 'hsl(88, 70%, 50%)',
    soba: 169,
    sobaColor: 'hsl(168, 70%, 50%)',
    bavette: 64,
    bavetteColor: 'hsl(74, 70%, 50%)',
    steak: 80,
    steakColor: 'hsl(163, 70%, 50%)',
    pizza: 32,
    pizzaColor: 'hsl(187, 70%, 50%)',
    spaghetti: 163,
    spaghettiColor: 'hsl(294, 70%, 50%)',
    ravioli: 85,
    ravioliColor: 'hsl(245, 70%, 50%)',
    salad: 199,
    saladColor: 'hsl(70, 70%, 50%)',
    'pad thai': 155,
    'pad thaiColor': 'hsl(9, 70%, 50%)',
    bun: 121,
    bunColor: 'hsl(194, 70%, 50%)',
    waffle: 93,
    waffleColor: 'hsl(329, 70%, 50%)',
    crepe: 35,
    crepeColor: 'hsl(339, 70%, 50%)',
    churros: 126,
    churrosColor: 'hsl(189, 70%, 50%)',
    paella: 63,
    paellaColor: 'hsl(197, 70%, 50%)',
    empanadas: 134,
    empanadasColor: 'hsl(34, 70%, 50%)',
    bruschetta: 44,
    bruschettaColor: 'hsl(190, 70%, 50%)',
    'onion soup': 199,
    'onion soupColor': 'hsl(307, 70%, 50%)',
    cassoulet: 135,
    cassouletColor: 'hsl(239, 70%, 50%)',
    bouillabaisse: 12,
    bouillabaisseColor: 'hsl(285, 70%, 50%)',
    unagi: 105,
    unagiColor: 'hsl(289, 70%, 50%)',
    tempura: 109,
    tempuraColor: 'hsl(150, 70%, 50%)',
    tonkatsu: 174,
    tonkatsuColor: 'hsl(293, 70%, 50%)',
    'shabu-shabu': 167,
    'shabu-shabuColor': 'hsl(211, 70%, 50%)',
    twinkies: 83,
    twinkiesColor: 'hsl(22, 70%, 50%)',
    jerky: 90,
    jerkyColor: 'hsl(316, 70%, 50%)',
    fajitas: 28,
    fajitasColor: 'hsl(205, 70%, 50%)',
    jambalaya: 41,
    jambalayaColor: 'hsl(68, 70%, 50%)',
    meatloaf: 192,
    meatloafColor: 'hsl(140, 70%, 50%)',
    "mac n' cheese": 34,
    "mac n' cheeseColor": 'hsl(31, 70%, 50%)',
    'baked beans': 183,
    'baked beansColor': 'hsl(306, 70%, 50%)',
    popcorn: 178,
    popcornColor: 'hsl(54, 70%, 50%)',
    'buffalo wings': 138,
    'buffalo wingsColor': 'hsl(249, 70%, 50%)',
    'BBQ ribs': 78,
    'BBQ ribsColor': 'hsl(343, 70%, 50%)',
    'apple pie': 162,
    'apple pieColor': 'hsl(282, 70%, 50%)',
    nachos: 117,
    nachosColor: 'hsl(176, 70%, 50%)',
    risotto: 139,
    risottoColor: 'hsl(228, 70%, 50%)',
    tiramisu: 127,
    tiramisuColor: 'hsl(233, 70%, 50%)',
  },
  {
    country: 'AQ',
    'hot dog': 180,
    'hot dogColor': 'hsl(181, 70%, 50%)',
    burger: 122,
    burgerColor: 'hsl(324, 70%, 50%)',
    sandwich: 24,
    sandwichColor: 'hsl(42, 70%, 50%)',
    kebab: 106,
    kebabColor: 'hsl(237, 70%, 50%)',
    fries: 175,
    friesColor: 'hsl(205, 70%, 50%)',
    donut: 109,
    donutColor: 'hsl(42, 70%, 50%)',
    junk: 74,
    junkColor: 'hsl(346, 70%, 50%)',
    sushi: 16,
    sushiColor: 'hsl(122, 70%, 50%)',
    ramen: 96,
    ramenColor: 'hsl(198, 70%, 50%)',
    curry: 63,
    curryColor: 'hsl(295, 70%, 50%)',
    udon: 107,
    udonColor: 'hsl(128, 70%, 50%)',
    bagel: 107,
    bagelColor: 'hsl(168, 70%, 50%)',
    yakitori: 79,
    yakitoriColor: 'hsl(43, 70%, 50%)',
    takoyaki: 110,
    takoyakiColor: 'hsl(317, 70%, 50%)',
    tacos: 134,
    tacosColor: 'hsl(232, 70%, 50%)',
    'miso soup': 82,
    'miso soupColor': 'hsl(132, 70%, 50%)',
    tortilla: 33,
    tortillaColor: 'hsl(106, 70%, 50%)',
    tapas: 54,
    tapasColor: 'hsl(132, 70%, 50%)',
    chipirones: 188,
    chipironesColor: 'hsl(111, 70%, 50%)',
    gazpacho: 75,
    gazpachoColor: 'hsl(343, 70%, 50%)',
    soba: 0,
    sobaColor: 'hsl(141, 70%, 50%)',
    bavette: 139,
    bavetteColor: 'hsl(281, 70%, 50%)',
    steak: 72,
    steakColor: 'hsl(357, 70%, 50%)',
    pizza: 10,
    pizzaColor: 'hsl(203, 70%, 50%)',
    spaghetti: 115,
    spaghettiColor: 'hsl(218, 70%, 50%)',
    ravioli: 35,
    ravioliColor: 'hsl(277, 70%, 50%)',
    salad: 93,
    saladColor: 'hsl(229, 70%, 50%)',
    'pad thai': 5,
    'pad thaiColor': 'hsl(182, 70%, 50%)',
    bun: 34,
    bunColor: 'hsl(15, 70%, 50%)',
    waffle: 5,
    waffleColor: 'hsl(75, 70%, 50%)',
    crepe: 47,
    crepeColor: 'hsl(255, 70%, 50%)',
    churros: 27,
    churrosColor: 'hsl(200, 70%, 50%)',
    paella: 5,
    paellaColor: 'hsl(70, 70%, 50%)',
    empanadas: 108,
    empanadasColor: 'hsl(352, 70%, 50%)',
    bruschetta: 189,
    bruschettaColor: 'hsl(59, 70%, 50%)',
    'onion soup': 4,
    'onion soupColor': 'hsl(242, 70%, 50%)',
    cassoulet: 5,
    cassouletColor: 'hsl(182, 70%, 50%)',
    bouillabaisse: 85,
    bouillabaisseColor: 'hsl(179, 70%, 50%)',
    unagi: 58,
    unagiColor: 'hsl(101, 70%, 50%)',
    tempura: 97,
    tempuraColor: 'hsl(342, 70%, 50%)',
    tonkatsu: 9,
    tonkatsuColor: 'hsl(129, 70%, 50%)',
    'shabu-shabu': 181,
    'shabu-shabuColor': 'hsl(280, 70%, 50%)',
    twinkies: 30,
    twinkiesColor: 'hsl(101, 70%, 50%)',
    jerky: 52,
    jerkyColor: 'hsl(339, 70%, 50%)',
    fajitas: 171,
    fajitasColor: 'hsl(307, 70%, 50%)',
    jambalaya: 192,
    jambalayaColor: 'hsl(66, 70%, 50%)',
    meatloaf: 167,
    meatloafColor: 'hsl(227, 70%, 50%)',
    "mac n' cheese": 198,
    "mac n' cheeseColor": 'hsl(73, 70%, 50%)',
    'baked beans': 0,
    'baked beansColor': 'hsl(201, 70%, 50%)',
    popcorn: 174,
    popcornColor: 'hsl(241, 70%, 50%)',
    'buffalo wings': 178,
    'buffalo wingsColor': 'hsl(38, 70%, 50%)',
    'BBQ ribs': 118,
    'BBQ ribsColor': 'hsl(225, 70%, 50%)',
    'apple pie': 92,
    'apple pieColor': 'hsl(307, 70%, 50%)',
    nachos: 110,
    nachosColor: 'hsl(191, 70%, 50%)',
    risotto: 116,
    risottoColor: 'hsl(45, 70%, 50%)',
    tiramisu: 182,
    tiramisuColor: 'hsl(135, 70%, 50%)',
  },
  {
    country: 'AR',
    'hot dog': 140,
    'hot dogColor': 'hsl(324, 70%, 50%)',
    burger: 100,
    burgerColor: 'hsl(20, 70%, 50%)',
    sandwich: 186,
    sandwichColor: 'hsl(145, 70%, 50%)',
    kebab: 65,
    kebabColor: 'hsl(211, 70%, 50%)',
    fries: 177,
    friesColor: 'hsl(244, 70%, 50%)',
    donut: 143,
    donutColor: 'hsl(12, 70%, 50%)',
    junk: 161,
    junkColor: 'hsl(168, 70%, 50%)',
    sushi: 159,
    sushiColor: 'hsl(83, 70%, 50%)',
    ramen: 124,
    ramenColor: 'hsl(309, 70%, 50%)',
    curry: 135,
    curryColor: 'hsl(329, 70%, 50%)',
    udon: 30,
    udonColor: 'hsl(159, 70%, 50%)',
    bagel: 93,
    bagelColor: 'hsl(140, 70%, 50%)',
    yakitori: 30,
    yakitoriColor: 'hsl(236, 70%, 50%)',
    takoyaki: 177,
    takoyakiColor: 'hsl(322, 70%, 50%)',
    tacos: 114,
    tacosColor: 'hsl(352, 70%, 50%)',
    'miso soup': 128,
    'miso soupColor': 'hsl(293, 70%, 50%)',
    tortilla: 162,
    tortillaColor: 'hsl(228, 70%, 50%)',
    tapas: 85,
    tapasColor: 'hsl(21, 70%, 50%)',
    chipirones: 64,
    chipironesColor: 'hsl(171, 70%, 50%)',
    gazpacho: 58,
    gazpachoColor: 'hsl(149, 70%, 50%)',
    soba: 95,
    sobaColor: 'hsl(197, 70%, 50%)',
    bavette: 41,
    bavetteColor: 'hsl(90, 70%, 50%)',
    steak: 137,
    steakColor: 'hsl(154, 70%, 50%)',
    pizza: 153,
    pizzaColor: 'hsl(140, 70%, 50%)',
    spaghetti: 116,
    spaghettiColor: 'hsl(352, 70%, 50%)',
    ravioli: 57,
    ravioliColor: 'hsl(7, 70%, 50%)',
    salad: 48,
    saladColor: 'hsl(129, 70%, 50%)',
    'pad thai': 199,
    'pad thaiColor': 'hsl(324, 70%, 50%)',
    bun: 48,
    bunColor: 'hsl(146, 70%, 50%)',
    waffle: 153,
    waffleColor: 'hsl(140, 70%, 50%)',
    crepe: 45,
    crepeColor: 'hsl(330, 70%, 50%)',
    churros: 161,
    churrosColor: 'hsl(333, 70%, 50%)',
    paella: 33,
    paellaColor: 'hsl(240, 70%, 50%)',
    empanadas: 56,
    empanadasColor: 'hsl(298, 70%, 50%)',
    bruschetta: 185,
    bruschettaColor: 'hsl(82, 70%, 50%)',
    'onion soup': 168,
    'onion soupColor': 'hsl(55, 70%, 50%)',
    cassoulet: 48,
    cassouletColor: 'hsl(180, 70%, 50%)',
    bouillabaisse: 69,
    bouillabaisseColor: 'hsl(161, 70%, 50%)',
    unagi: 31,
    unagiColor: 'hsl(113, 70%, 50%)',
    tempura: 188,
    tempuraColor: 'hsl(284, 70%, 50%)',
    tonkatsu: 199,
    tonkatsuColor: 'hsl(57, 70%, 50%)',
    'shabu-shabu': 198,
    'shabu-shabuColor': 'hsl(79, 70%, 50%)',
    twinkies: 52,
    twinkiesColor: 'hsl(313, 70%, 50%)',
    jerky: 41,
    jerkyColor: 'hsl(143, 70%, 50%)',
    fajitas: 9,
    fajitasColor: 'hsl(7, 70%, 50%)',
    jambalaya: 93,
    jambalayaColor: 'hsl(186, 70%, 50%)',
    meatloaf: 42,
    meatloafColor: 'hsl(328, 70%, 50%)',
    "mac n' cheese": 96,
    "mac n' cheeseColor": 'hsl(145, 70%, 50%)',
    'baked beans': 91,
    'baked beansColor': 'hsl(62, 70%, 50%)',
    popcorn: 86,
    popcornColor: 'hsl(265, 70%, 50%)',
    'buffalo wings': 198,
    'buffalo wingsColor': 'hsl(20, 70%, 50%)',
    'BBQ ribs': 180,
    'BBQ ribsColor': 'hsl(51, 70%, 50%)',
    'apple pie': 156,
    'apple pieColor': 'hsl(54, 70%, 50%)',
    nachos: 169,
    nachosColor: 'hsl(33, 70%, 50%)',
    risotto: 19,
    risottoColor: 'hsl(150, 70%, 50%)',
    tiramisu: 87,
    tiramisuColor: 'hsl(151, 70%, 50%)',
  },
  {
    country: 'AS',
    'hot dog': 49,
    'hot dogColor': 'hsl(67, 70%, 50%)',
    burger: 136,
    burgerColor: 'hsl(75, 70%, 50%)',
    sandwich: 11,
    sandwichColor: 'hsl(295, 70%, 50%)',
    kebab: 7,
    kebabColor: 'hsl(5, 70%, 50%)',
    fries: 128,
    friesColor: 'hsl(153, 70%, 50%)',
    donut: 152,
    donutColor: 'hsl(298, 70%, 50%)',
    junk: 72,
    junkColor: 'hsl(232, 70%, 50%)',
    sushi: 133,
    sushiColor: 'hsl(88, 70%, 50%)',
    ramen: 106,
    ramenColor: 'hsl(359, 70%, 50%)',
    curry: 171,
    curryColor: 'hsl(109, 70%, 50%)',
    udon: 66,
    udonColor: 'hsl(237, 70%, 50%)',
    bagel: 76,
    bagelColor: 'hsl(96, 70%, 50%)',
    yakitori: 177,
    yakitoriColor: 'hsl(203, 70%, 50%)',
    takoyaki: 90,
    takoyakiColor: 'hsl(59, 70%, 50%)',
    tacos: 3,
    tacosColor: 'hsl(113, 70%, 50%)',
    'miso soup': 68,
    'miso soupColor': 'hsl(226, 70%, 50%)',
    tortilla: 73,
    tortillaColor: 'hsl(345, 70%, 50%)',
    tapas: 166,
    tapasColor: 'hsl(60, 70%, 50%)',
    chipirones: 165,
    chipironesColor: 'hsl(350, 70%, 50%)',
    gazpacho: 52,
    gazpachoColor: 'hsl(72, 70%, 50%)',
    soba: 76,
    sobaColor: 'hsl(37, 70%, 50%)',
    bavette: 73,
    bavetteColor: 'hsl(123, 70%, 50%)',
    steak: 14,
    steakColor: 'hsl(320, 70%, 50%)',
    pizza: 33,
    pizzaColor: 'hsl(195, 70%, 50%)',
    spaghetti: 37,
    spaghettiColor: 'hsl(191, 70%, 50%)',
    ravioli: 39,
    ravioliColor: 'hsl(300, 70%, 50%)',
    salad: 139,
    saladColor: 'hsl(198, 70%, 50%)',
    'pad thai': 136,
    'pad thaiColor': 'hsl(236, 70%, 50%)',
    bun: 91,
    bunColor: 'hsl(5, 70%, 50%)',
    waffle: 70,
    waffleColor: 'hsl(59, 70%, 50%)',
    crepe: 16,
    crepeColor: 'hsl(224, 70%, 50%)',
    churros: 167,
    churrosColor: 'hsl(252, 70%, 50%)',
    paella: 36,
    paellaColor: 'hsl(258, 70%, 50%)',
    empanadas: 107,
    empanadasColor: 'hsl(10, 70%, 50%)',
    bruschetta: 116,
    bruschettaColor: 'hsl(55, 70%, 50%)',
    'onion soup': 37,
    'onion soupColor': 'hsl(2, 70%, 50%)',
    cassoulet: 24,
    cassouletColor: 'hsl(119, 70%, 50%)',
    bouillabaisse: 19,
    bouillabaisseColor: 'hsl(183, 70%, 50%)',
    unagi: 80,
    unagiColor: 'hsl(291, 70%, 50%)',
    tempura: 196,
    tempuraColor: 'hsl(321, 70%, 50%)',
    tonkatsu: 31,
    tonkatsuColor: 'hsl(12, 70%, 50%)',
    'shabu-shabu': 37,
    'shabu-shabuColor': 'hsl(6, 70%, 50%)',
    twinkies: 105,
    twinkiesColor: 'hsl(340, 70%, 50%)',
    jerky: 180,
    jerkyColor: 'hsl(308, 70%, 50%)',
    fajitas: 173,
    fajitasColor: 'hsl(140, 70%, 50%)',
    jambalaya: 77,
    jambalayaColor: 'hsl(156, 70%, 50%)',
    meatloaf: 194,
    meatloafColor: 'hsl(309, 70%, 50%)',
    "mac n' cheese": 112,
    "mac n' cheeseColor": 'hsl(168, 70%, 50%)',
    'baked beans': 30,
    'baked beansColor': 'hsl(157, 70%, 50%)',
    popcorn: 147,
    popcornColor: 'hsl(106, 70%, 50%)',
    'buffalo wings': 84,
    'buffalo wingsColor': 'hsl(115, 70%, 50%)',
    'BBQ ribs': 116,
    'BBQ ribsColor': 'hsl(230, 70%, 50%)',
    'apple pie': 1,
    'apple pieColor': 'hsl(69, 70%, 50%)',
    nachos: 61,
    nachosColor: 'hsl(282, 70%, 50%)',
    risotto: 103,
    risottoColor: 'hsl(35, 70%, 50%)',
    tiramisu: 18,
    tiramisuColor: 'hsl(147, 70%, 50%)',
  },
  {
    country: 'AT',
    'hot dog': 186,
    'hot dogColor': 'hsl(196, 70%, 50%)',
    burger: 45,
    burgerColor: 'hsl(95, 70%, 50%)',
    sandwich: 46,
    sandwichColor: 'hsl(291, 70%, 50%)',
    kebab: 150,
    kebabColor: 'hsl(85, 70%, 50%)',
    fries: 129,
    friesColor: 'hsl(224, 70%, 50%)',
    donut: 194,
    donutColor: 'hsl(272, 70%, 50%)',
    junk: 100,
    junkColor: 'hsl(281, 70%, 50%)',
    sushi: 17,
    sushiColor: 'hsl(180, 70%, 50%)',
    ramen: 121,
    ramenColor: 'hsl(267, 70%, 50%)',
    curry: 32,
    curryColor: 'hsl(118, 70%, 50%)',
    udon: 146,
    udonColor: 'hsl(327, 70%, 50%)',
    bagel: 30,
    bagelColor: 'hsl(268, 70%, 50%)',
    yakitori: 22,
    yakitoriColor: 'hsl(308, 70%, 50%)',
    takoyaki: 121,
    takoyakiColor: 'hsl(263, 70%, 50%)',
    tacos: 96,
    tacosColor: 'hsl(129, 70%, 50%)',
    'miso soup': 61,
    'miso soupColor': 'hsl(114, 70%, 50%)',
    tortilla: 189,
    tortillaColor: 'hsl(117, 70%, 50%)',
    tapas: 132,
    tapasColor: 'hsl(83, 70%, 50%)',
    chipirones: 18,
    chipironesColor: 'hsl(17, 70%, 50%)',
    gazpacho: 133,
    gazpachoColor: 'hsl(45, 70%, 50%)',
    soba: 100,
    sobaColor: 'hsl(154, 70%, 50%)',
    bavette: 143,
    bavetteColor: 'hsl(113, 70%, 50%)',
    steak: 2,
    steakColor: 'hsl(17, 70%, 50%)',
    pizza: 85,
    pizzaColor: 'hsl(76, 70%, 50%)',
    spaghetti: 66,
    spaghettiColor: 'hsl(167, 70%, 50%)',
    ravioli: 11,
    ravioliColor: 'hsl(45, 70%, 50%)',
    salad: 32,
    saladColor: 'hsl(221, 70%, 50%)',
    'pad thai': 171,
    'pad thaiColor': 'hsl(280, 70%, 50%)',
    bun: 150,
    bunColor: 'hsl(249, 70%, 50%)',
    waffle: 158,
    waffleColor: 'hsl(165, 70%, 50%)',
    crepe: 38,
    crepeColor: 'hsl(85, 70%, 50%)',
    churros: 121,
    churrosColor: 'hsl(283, 70%, 50%)',
    paella: 131,
    paellaColor: 'hsl(250, 70%, 50%)',
    empanadas: 105,
    empanadasColor: 'hsl(115, 70%, 50%)',
    bruschetta: 71,
    bruschettaColor: 'hsl(86, 70%, 50%)',
    'onion soup': 186,
    'onion soupColor': 'hsl(174, 70%, 50%)',
    cassoulet: 148,
    cassouletColor: 'hsl(232, 70%, 50%)',
    bouillabaisse: 98,
    bouillabaisseColor: 'hsl(342, 70%, 50%)',
    unagi: 150,
    unagiColor: 'hsl(48, 70%, 50%)',
    tempura: 158,
    tempuraColor: 'hsl(251, 70%, 50%)',
    tonkatsu: 189,
    tonkatsuColor: 'hsl(262, 70%, 50%)',
    'shabu-shabu': 157,
    'shabu-shabuColor': 'hsl(87, 70%, 50%)',
    twinkies: 186,
    twinkiesColor: 'hsl(306, 70%, 50%)',
    jerky: 30,
    jerkyColor: 'hsl(267, 70%, 50%)',
    fajitas: 158,
    fajitasColor: 'hsl(162, 70%, 50%)',
    jambalaya: 154,
    jambalayaColor: 'hsl(135, 70%, 50%)',
    meatloaf: 138,
    meatloafColor: 'hsl(24, 70%, 50%)',
    "mac n' cheese": 143,
    "mac n' cheeseColor": 'hsl(251, 70%, 50%)',
    'baked beans': 24,
    'baked beansColor': 'hsl(196, 70%, 50%)',
    popcorn: 146,
    popcornColor: 'hsl(125, 70%, 50%)',
    'buffalo wings': 70,
    'buffalo wingsColor': 'hsl(174, 70%, 50%)',
    'BBQ ribs': 85,
    'BBQ ribsColor': 'hsl(271, 70%, 50%)',
    'apple pie': 48,
    'apple pieColor': 'hsl(288, 70%, 50%)',
    nachos: 18,
    nachosColor: 'hsl(177, 70%, 50%)',
    risotto: 137,
    risottoColor: 'hsl(224, 70%, 50%)',
    tiramisu: 26,
    tiramisuColor: 'hsl(198, 70%, 50%)',
  },
  {
    country: 'AU',
    'hot dog': 87,
    'hot dogColor': 'hsl(82, 70%, 50%)',
    burger: 140,
    burgerColor: 'hsl(178, 70%, 50%)',
    sandwich: 178,
    sandwichColor: 'hsl(102, 70%, 50%)',
    kebab: 25,
    kebabColor: 'hsl(41, 70%, 50%)',
    fries: 151,
    friesColor: 'hsl(344, 70%, 50%)',
    donut: 132,
    donutColor: 'hsl(89, 70%, 50%)',
    junk: 135,
    junkColor: 'hsl(63, 70%, 50%)',
    sushi: 175,
    sushiColor: 'hsl(59, 70%, 50%)',
    ramen: 52,
    ramenColor: 'hsl(166, 70%, 50%)',
    curry: 55,
    curryColor: 'hsl(94, 70%, 50%)',
    udon: 154,
    udonColor: 'hsl(213, 70%, 50%)',
    bagel: 188,
    bagelColor: 'hsl(289, 70%, 50%)',
    yakitori: 28,
    yakitoriColor: 'hsl(89, 70%, 50%)',
    takoyaki: 171,
    takoyakiColor: 'hsl(70, 70%, 50%)',
    tacos: 78,
    tacosColor: 'hsl(217, 70%, 50%)',
    'miso soup': 27,
    'miso soupColor': 'hsl(266, 70%, 50%)',
    tortilla: 35,
    tortillaColor: 'hsl(35, 70%, 50%)',
    tapas: 196,
    tapasColor: 'hsl(238, 70%, 50%)',
    chipirones: 22,
    chipironesColor: 'hsl(255, 70%, 50%)',
    gazpacho: 173,
    gazpachoColor: 'hsl(295, 70%, 50%)',
    soba: 132,
    sobaColor: 'hsl(275, 70%, 50%)',
    bavette: 105,
    bavetteColor: 'hsl(22, 70%, 50%)',
    steak: 50,
    steakColor: 'hsl(259, 70%, 50%)',
    pizza: 19,
    pizzaColor: 'hsl(165, 70%, 50%)',
    spaghetti: 80,
    spaghettiColor: 'hsl(269, 70%, 50%)',
    ravioli: 113,
    ravioliColor: 'hsl(277, 70%, 50%)',
    salad: 150,
    saladColor: 'hsl(206, 70%, 50%)',
    'pad thai': 145,
    'pad thaiColor': 'hsl(204, 70%, 50%)',
    bun: 118,
    bunColor: 'hsl(168, 70%, 50%)',
    waffle: 183,
    waffleColor: 'hsl(19, 70%, 50%)',
    crepe: 114,
    crepeColor: 'hsl(229, 70%, 50%)',
    churros: 176,
    churrosColor: 'hsl(74, 70%, 50%)',
    paella: 97,
    paellaColor: 'hsl(48, 70%, 50%)',
    empanadas: 62,
    empanadasColor: 'hsl(235, 70%, 50%)',
    bruschetta: 109,
    bruschettaColor: 'hsl(41, 70%, 50%)',
    'onion soup': 83,
    'onion soupColor': 'hsl(275, 70%, 50%)',
    cassoulet: 23,
    cassouletColor: 'hsl(8, 70%, 50%)',
    bouillabaisse: 113,
    bouillabaisseColor: 'hsl(267, 70%, 50%)',
    unagi: 118,
    unagiColor: 'hsl(302, 70%, 50%)',
    tempura: 109,
    tempuraColor: 'hsl(166, 70%, 50%)',
    tonkatsu: 10,
    tonkatsuColor: 'hsl(207, 70%, 50%)',
    'shabu-shabu': 23,
    'shabu-shabuColor': 'hsl(250, 70%, 50%)',
    twinkies: 20,
    twinkiesColor: 'hsl(262, 70%, 50%)',
    jerky: 96,
    jerkyColor: 'hsl(338, 70%, 50%)',
    fajitas: 20,
    fajitasColor: 'hsl(9, 70%, 50%)',
    jambalaya: 0,
    jambalayaColor: 'hsl(141, 70%, 50%)',
    meatloaf: 104,
    meatloafColor: 'hsl(173, 70%, 50%)',
    "mac n' cheese": 37,
    "mac n' cheeseColor": 'hsl(19, 70%, 50%)',
    'baked beans': 69,
    'baked beansColor': 'hsl(199, 70%, 50%)',
    popcorn: 177,
    popcornColor: 'hsl(196, 70%, 50%)',
    'buffalo wings': 126,
    'buffalo wingsColor': 'hsl(45, 70%, 50%)',
    'BBQ ribs': 175,
    'BBQ ribsColor': 'hsl(7, 70%, 50%)',
    'apple pie': 184,
    'apple pieColor': 'hsl(153, 70%, 50%)',
    nachos: 124,
    nachosColor: 'hsl(327, 70%, 50%)',
    risotto: 48,
    risottoColor: 'hsl(336, 70%, 50%)',
    tiramisu: 142,
    tiramisuColor: 'hsl(211, 70%, 50%)',
  },
  {
    country: 'AW',
    'hot dog': 60,
    'hot dogColor': 'hsl(108, 70%, 50%)',
    burger: 155,
    burgerColor: 'hsl(177, 70%, 50%)',
    sandwich: 190,
    sandwichColor: 'hsl(337, 70%, 50%)',
    kebab: 3,
    kebabColor: 'hsl(236, 70%, 50%)',
    fries: 8,
    friesColor: 'hsl(39, 70%, 50%)',
    donut: 56,
    donutColor: 'hsl(203, 70%, 50%)',
    junk: 25,
    junkColor: 'hsl(305, 70%, 50%)',
    sushi: 178,
    sushiColor: 'hsl(40, 70%, 50%)',
    ramen: 87,
    ramenColor: 'hsl(44, 70%, 50%)',
    curry: 53,
    curryColor: 'hsl(345, 70%, 50%)',
    udon: 143,
    udonColor: 'hsl(113, 70%, 50%)',
    bagel: 116,
    bagelColor: 'hsl(217, 70%, 50%)',
    yakitori: 75,
    yakitoriColor: 'hsl(77, 70%, 50%)',
    takoyaki: 87,
    takoyakiColor: 'hsl(169, 70%, 50%)',
    tacos: 172,
    tacosColor: 'hsl(5, 70%, 50%)',
    'miso soup': 116,
    'miso soupColor': 'hsl(174, 70%, 50%)',
    tortilla: 12,
    tortillaColor: 'hsl(129, 70%, 50%)',
    tapas: 149,
    tapasColor: 'hsl(128, 70%, 50%)',
    chipirones: 195,
    chipironesColor: 'hsl(346, 70%, 50%)',
    gazpacho: 196,
    gazpachoColor: 'hsl(302, 70%, 50%)',
    soba: 138,
    sobaColor: 'hsl(142, 70%, 50%)',
    bavette: 181,
    bavetteColor: 'hsl(193, 70%, 50%)',
    steak: 53,
    steakColor: 'hsl(279, 70%, 50%)',
    pizza: 44,
    pizzaColor: 'hsl(153, 70%, 50%)',
    spaghetti: 36,
    spaghettiColor: 'hsl(104, 70%, 50%)',
    ravioli: 67,
    ravioliColor: 'hsl(200, 70%, 50%)',
    salad: 10,
    saladColor: 'hsl(1, 70%, 50%)',
    'pad thai': 80,
    'pad thaiColor': 'hsl(355, 70%, 50%)',
    bun: 87,
    bunColor: 'hsl(123, 70%, 50%)',
    waffle: 136,
    waffleColor: 'hsl(66, 70%, 50%)',
    crepe: 93,
    crepeColor: 'hsl(214, 70%, 50%)',
    churros: 49,
    churrosColor: 'hsl(346, 70%, 50%)',
    paella: 114,
    paellaColor: 'hsl(326, 70%, 50%)',
    empanadas: 52,
    empanadasColor: 'hsl(147, 70%, 50%)',
    bruschetta: 27,
    bruschettaColor: 'hsl(38, 70%, 50%)',
    'onion soup': 155,
    'onion soupColor': 'hsl(121, 70%, 50%)',
    cassoulet: 133,
    cassouletColor: 'hsl(18, 70%, 50%)',
    bouillabaisse: 21,
    bouillabaisseColor: 'hsl(313, 70%, 50%)',
    unagi: 88,
    unagiColor: 'hsl(53, 70%, 50%)',
    tempura: 162,
    tempuraColor: 'hsl(91, 70%, 50%)',
    tonkatsu: 138,
    tonkatsuColor: 'hsl(84, 70%, 50%)',
    'shabu-shabu': 101,
    'shabu-shabuColor': 'hsl(142, 70%, 50%)',
    twinkies: 108,
    twinkiesColor: 'hsl(119, 70%, 50%)',
    jerky: 81,
    jerkyColor: 'hsl(42, 70%, 50%)',
    fajitas: 94,
    fajitasColor: 'hsl(142, 70%, 50%)',
    jambalaya: 167,
    jambalayaColor: 'hsl(52, 70%, 50%)',
    meatloaf: 81,
    meatloafColor: 'hsl(90, 70%, 50%)',
    "mac n' cheese": 175,
    "mac n' cheeseColor": 'hsl(52, 70%, 50%)',
    'baked beans': 107,
    'baked beansColor': 'hsl(330, 70%, 50%)',
    popcorn: 187,
    popcornColor: 'hsl(47, 70%, 50%)',
    'buffalo wings': 146,
    'buffalo wingsColor': 'hsl(108, 70%, 50%)',
    'BBQ ribs': 185,
    'BBQ ribsColor': 'hsl(43, 70%, 50%)',
    'apple pie': 122,
    'apple pieColor': 'hsl(70, 70%, 50%)',
    nachos: 36,
    nachosColor: 'hsl(273, 70%, 50%)',
    risotto: 89,
    risottoColor: 'hsl(48, 70%, 50%)',
    tiramisu: 137,
    tiramisuColor: 'hsl(165, 70%, 50%)',
  },
  {
    country: 'AX',
    'hot dog': 95,
    'hot dogColor': 'hsl(143, 70%, 50%)',
    burger: 130,
    burgerColor: 'hsl(42, 70%, 50%)',
    sandwich: 102,
    sandwichColor: 'hsl(141, 70%, 50%)',
    kebab: 122,
    kebabColor: 'hsl(126, 70%, 50%)',
    fries: 18,
    friesColor: 'hsl(191, 70%, 50%)',
    donut: 103,
    donutColor: 'hsl(293, 70%, 50%)',
    junk: 158,
    junkColor: 'hsl(260, 70%, 50%)',
    sushi: 95,
    sushiColor: 'hsl(53, 70%, 50%)',
    ramen: 100,
    ramenColor: 'hsl(189, 70%, 50%)',
    curry: 80,
    curryColor: 'hsl(11, 70%, 50%)',
    udon: 124,
    udonColor: 'hsl(220, 70%, 50%)',
    bagel: 157,
    bagelColor: 'hsl(340, 70%, 50%)',
    yakitori: 105,
    yakitoriColor: 'hsl(55, 70%, 50%)',
    takoyaki: 143,
    takoyakiColor: 'hsl(212, 70%, 50%)',
    tacos: 191,
    tacosColor: 'hsl(282, 70%, 50%)',
    'miso soup': 76,
    'miso soupColor': 'hsl(254, 70%, 50%)',
    tortilla: 124,
    tortillaColor: 'hsl(119, 70%, 50%)',
    tapas: 39,
    tapasColor: 'hsl(113, 70%, 50%)',
    chipirones: 21,
    chipironesColor: 'hsl(228, 70%, 50%)',
    gazpacho: 37,
    gazpachoColor: 'hsl(159, 70%, 50%)',
    soba: 88,
    sobaColor: 'hsl(294, 70%, 50%)',
    bavette: 143,
    bavetteColor: 'hsl(272, 70%, 50%)',
    steak: 18,
    steakColor: 'hsl(135, 70%, 50%)',
    pizza: 163,
    pizzaColor: 'hsl(63, 70%, 50%)',
    spaghetti: 93,
    spaghettiColor: 'hsl(45, 70%, 50%)',
    ravioli: 16,
    ravioliColor: 'hsl(292, 70%, 50%)',
    salad: 126,
    saladColor: 'hsl(341, 70%, 50%)',
    'pad thai': 109,
    'pad thaiColor': 'hsl(184, 70%, 50%)',
    bun: 71,
    bunColor: 'hsl(343, 70%, 50%)',
    waffle: 54,
    waffleColor: 'hsl(86, 70%, 50%)',
    crepe: 164,
    crepeColor: 'hsl(76, 70%, 50%)',
    churros: 149,
    churrosColor: 'hsl(180, 70%, 50%)',
    paella: 183,
    paellaColor: 'hsl(320, 70%, 50%)',
    empanadas: 199,
    empanadasColor: 'hsl(263, 70%, 50%)',
    bruschetta: 193,
    bruschettaColor: 'hsl(58, 70%, 50%)',
    'onion soup': 114,
    'onion soupColor': 'hsl(55, 70%, 50%)',
    cassoulet: 29,
    cassouletColor: 'hsl(157, 70%, 50%)',
    bouillabaisse: 28,
    bouillabaisseColor: 'hsl(240, 70%, 50%)',
    unagi: 158,
    unagiColor: 'hsl(177, 70%, 50%)',
    tempura: 168,
    tempuraColor: 'hsl(251, 70%, 50%)',
    tonkatsu: 49,
    tonkatsuColor: 'hsl(300, 70%, 50%)',
    'shabu-shabu': 124,
    'shabu-shabuColor': 'hsl(272, 70%, 50%)',
    twinkies: 11,
    twinkiesColor: 'hsl(233, 70%, 50%)',
    jerky: 25,
    jerkyColor: 'hsl(47, 70%, 50%)',
    fajitas: 101,
    fajitasColor: 'hsl(207, 70%, 50%)',
    jambalaya: 57,
    jambalayaColor: 'hsl(344, 70%, 50%)',
    meatloaf: 58,
    meatloafColor: 'hsl(304, 70%, 50%)',
    "mac n' cheese": 45,
    "mac n' cheeseColor": 'hsl(302, 70%, 50%)',
    'baked beans': 45,
    'baked beansColor': 'hsl(67, 70%, 50%)',
    popcorn: 84,
    popcornColor: 'hsl(295, 70%, 50%)',
    'buffalo wings': 140,
    'buffalo wingsColor': 'hsl(223, 70%, 50%)',
    'BBQ ribs': 133,
    'BBQ ribsColor': 'hsl(314, 70%, 50%)',
    'apple pie': 174,
    'apple pieColor': 'hsl(7, 70%, 50%)',
    nachos: 10,
    nachosColor: 'hsl(28, 70%, 50%)',
    risotto: 131,
    risottoColor: 'hsl(102, 70%, 50%)',
    tiramisu: 52,
    tiramisuColor: 'hsl(70, 70%, 50%)',
  },
  {
    country: 'AZ',
    'hot dog': 72,
    'hot dogColor': 'hsl(84, 70%, 50%)',
    burger: 153,
    burgerColor: 'hsl(145, 70%, 50%)',
    sandwich: 188,
    sandwichColor: 'hsl(131, 70%, 50%)',
    kebab: 110,
    kebabColor: 'hsl(302, 70%, 50%)',
    fries: 23,
    friesColor: 'hsl(228, 70%, 50%)',
    donut: 4,
    donutColor: 'hsl(223, 70%, 50%)',
    junk: 105,
    junkColor: 'hsl(60, 70%, 50%)',
    sushi: 10,
    sushiColor: 'hsl(266, 70%, 50%)',
    ramen: 122,
    ramenColor: 'hsl(78, 70%, 50%)',
    curry: 110,
    curryColor: 'hsl(142, 70%, 50%)',
    udon: 0,
    udonColor: 'hsl(337, 70%, 50%)',
    bagel: 108,
    bagelColor: 'hsl(216, 70%, 50%)',
    yakitori: 36,
    yakitoriColor: 'hsl(161, 70%, 50%)',
    takoyaki: 132,
    takoyakiColor: 'hsl(269, 70%, 50%)',
    tacos: 5,
    tacosColor: 'hsl(102, 70%, 50%)',
    'miso soup': 26,
    'miso soupColor': 'hsl(189, 70%, 50%)',
    tortilla: 111,
    tortillaColor: 'hsl(158, 70%, 50%)',
    tapas: 111,
    tapasColor: 'hsl(294, 70%, 50%)',
    chipirones: 31,
    chipironesColor: 'hsl(83, 70%, 50%)',
    gazpacho: 180,
    gazpachoColor: 'hsl(308, 70%, 50%)',
    soba: 30,
    sobaColor: 'hsl(140, 70%, 50%)',
    bavette: 181,
    bavetteColor: 'hsl(108, 70%, 50%)',
    steak: 122,
    steakColor: 'hsl(104, 70%, 50%)',
    pizza: 132,
    pizzaColor: 'hsl(80, 70%, 50%)',
    spaghetti: 146,
    spaghettiColor: 'hsl(25, 70%, 50%)',
    ravioli: 161,
    ravioliColor: 'hsl(258, 70%, 50%)',
    salad: 105,
    saladColor: 'hsl(337, 70%, 50%)',
    'pad thai': 149,
    'pad thaiColor': 'hsl(59, 70%, 50%)',
    bun: 146,
    bunColor: 'hsl(122, 70%, 50%)',
    waffle: 171,
    waffleColor: 'hsl(20, 70%, 50%)',
    crepe: 139,
    crepeColor: 'hsl(277, 70%, 50%)',
    churros: 180,
    churrosColor: 'hsl(216, 70%, 50%)',
    paella: 105,
    paellaColor: 'hsl(200, 70%, 50%)',
    empanadas: 132,
    empanadasColor: 'hsl(39, 70%, 50%)',
    bruschetta: 24,
    bruschettaColor: 'hsl(57, 70%, 50%)',
    'onion soup': 108,
    'onion soupColor': 'hsl(125, 70%, 50%)',
    cassoulet: 75,
    cassouletColor: 'hsl(171, 70%, 50%)',
    bouillabaisse: 45,
    bouillabaisseColor: 'hsl(14, 70%, 50%)',
    unagi: 137,
    unagiColor: 'hsl(52, 70%, 50%)',
    tempura: 36,
    tempuraColor: 'hsl(165, 70%, 50%)',
    tonkatsu: 188,
    tonkatsuColor: 'hsl(27, 70%, 50%)',
    'shabu-shabu': 57,
    'shabu-shabuColor': 'hsl(230, 70%, 50%)',
    twinkies: 106,
    twinkiesColor: 'hsl(197, 70%, 50%)',
    jerky: 115,
    jerkyColor: 'hsl(186, 70%, 50%)',
    fajitas: 46,
    fajitasColor: 'hsl(350, 70%, 50%)',
    jambalaya: 87,
    jambalayaColor: 'hsl(307, 70%, 50%)',
    meatloaf: 146,
    meatloafColor: 'hsl(257, 70%, 50%)',
    "mac n' cheese": 85,
    "mac n' cheeseColor": 'hsl(81, 70%, 50%)',
    'baked beans': 182,
    'baked beansColor': 'hsl(10, 70%, 50%)',
    popcorn: 148,
    popcornColor: 'hsl(64, 70%, 50%)',
    'buffalo wings': 179,
    'buffalo wingsColor': 'hsl(309, 70%, 50%)',
    'BBQ ribs': 14,
    'BBQ ribsColor': 'hsl(358, 70%, 50%)',
    'apple pie': 157,
    'apple pieColor': 'hsl(35, 70%, 50%)',
    nachos: 67,
    nachosColor: 'hsl(42, 70%, 50%)',
    risotto: 13,
    risottoColor: 'hsl(330, 70%, 50%)',
    tiramisu: 199,
    tiramisuColor: 'hsl(9, 70%, 50%)',
  },
  {
    country: 'BA',
    'hot dog': 141,
    'hot dogColor': 'hsl(136, 70%, 50%)',
    burger: 28,
    burgerColor: 'hsl(260, 70%, 50%)',
    sandwich: 146,
    sandwichColor: 'hsl(24, 70%, 50%)',
    kebab: 191,
    kebabColor: 'hsl(252, 70%, 50%)',
    fries: 93,
    friesColor: 'hsl(69, 70%, 50%)',
    donut: 56,
    donutColor: 'hsl(221, 70%, 50%)',
    junk: 61,
    junkColor: 'hsl(281, 70%, 50%)',
    sushi: 8,
    sushiColor: 'hsl(236, 70%, 50%)',
    ramen: 29,
    ramenColor: 'hsl(259, 70%, 50%)',
    curry: 89,
    curryColor: 'hsl(12, 70%, 50%)',
    udon: 120,
    udonColor: 'hsl(34, 70%, 50%)',
    bagel: 83,
    bagelColor: 'hsl(322, 70%, 50%)',
    yakitori: 62,
    yakitoriColor: 'hsl(84, 70%, 50%)',
    takoyaki: 31,
    takoyakiColor: 'hsl(85, 70%, 50%)',
    tacos: 107,
    tacosColor: 'hsl(154, 70%, 50%)',
    'miso soup': 71,
    'miso soupColor': 'hsl(327, 70%, 50%)',
    tortilla: 46,
    tortillaColor: 'hsl(58, 70%, 50%)',
    tapas: 191,
    tapasColor: 'hsl(128, 70%, 50%)',
    chipirones: 147,
    chipironesColor: 'hsl(231, 70%, 50%)',
    gazpacho: 35,
    gazpachoColor: 'hsl(44, 70%, 50%)',
    soba: 73,
    sobaColor: 'hsl(116, 70%, 50%)',
    bavette: 39,
    bavetteColor: 'hsl(122, 70%, 50%)',
    steak: 150,
    steakColor: 'hsl(145, 70%, 50%)',
    pizza: 181,
    pizzaColor: 'hsl(278, 70%, 50%)',
    spaghetti: 155,
    spaghettiColor: 'hsl(67, 70%, 50%)',
    ravioli: 111,
    ravioliColor: 'hsl(142, 70%, 50%)',
    salad: 100,
    saladColor: 'hsl(295, 70%, 50%)',
    'pad thai': 199,
    'pad thaiColor': 'hsl(339, 70%, 50%)',
    bun: 166,
    bunColor: 'hsl(348, 70%, 50%)',
    waffle: 20,
    waffleColor: 'hsl(52, 70%, 50%)',
    crepe: 47,
    crepeColor: 'hsl(6, 70%, 50%)',
    churros: 43,
    churrosColor: 'hsl(109, 70%, 50%)',
    paella: 40,
    paellaColor: 'hsl(222, 70%, 50%)',
    empanadas: 144,
    empanadasColor: 'hsl(354, 70%, 50%)',
    bruschetta: 35,
    bruschettaColor: 'hsl(356, 70%, 50%)',
    'onion soup': 177,
    'onion soupColor': 'hsl(13, 70%, 50%)',
    cassoulet: 151,
    cassouletColor: 'hsl(282, 70%, 50%)',
    bouillabaisse: 8,
    bouillabaisseColor: 'hsl(252, 70%, 50%)',
    unagi: 158,
    unagiColor: 'hsl(80, 70%, 50%)',
    tempura: 59,
    tempuraColor: 'hsl(248, 70%, 50%)',
    tonkatsu: 132,
    tonkatsuColor: 'hsl(289, 70%, 50%)',
    'shabu-shabu': 135,
    'shabu-shabuColor': 'hsl(251, 70%, 50%)',
    twinkies: 113,
    twinkiesColor: 'hsl(250, 70%, 50%)',
    jerky: 18,
    jerkyColor: 'hsl(135, 70%, 50%)',
    fajitas: 85,
    fajitasColor: 'hsl(360, 70%, 50%)',
    jambalaya: 178,
    jambalayaColor: 'hsl(229, 70%, 50%)',
    meatloaf: 175,
    meatloafColor: 'hsl(150, 70%, 50%)',
    "mac n' cheese": 184,
    "mac n' cheeseColor": 'hsl(62, 70%, 50%)',
    'baked beans': 172,
    'baked beansColor': 'hsl(13, 70%, 50%)',
    popcorn: 112,
    popcornColor: 'hsl(157, 70%, 50%)',
    'buffalo wings': 108,
    'buffalo wingsColor': 'hsl(134, 70%, 50%)',
    'BBQ ribs': 60,
    'BBQ ribsColor': 'hsl(160, 70%, 50%)',
    'apple pie': 21,
    'apple pieColor': 'hsl(181, 70%, 50%)',
    nachos: 27,
    nachosColor: 'hsl(255, 70%, 50%)',
    risotto: 20,
    risottoColor: 'hsl(345, 70%, 50%)',
    tiramisu: 102,
    tiramisuColor: 'hsl(195, 70%, 50%)',
  },
  {
    country: 'BB',
    'hot dog': 200,
    'hot dogColor': 'hsl(231, 70%, 50%)',
    burger: 148,
    burgerColor: 'hsl(335, 70%, 50%)',
    sandwich: 70,
    sandwichColor: 'hsl(241, 70%, 50%)',
    kebab: 58,
    kebabColor: 'hsl(192, 70%, 50%)',
    fries: 182,
    friesColor: 'hsl(60, 70%, 50%)',
    donut: 45,
    donutColor: 'hsl(13, 70%, 50%)',
    junk: 119,
    junkColor: 'hsl(102, 70%, 50%)',
    sushi: 178,
    sushiColor: 'hsl(217, 70%, 50%)',
    ramen: 47,
    ramenColor: 'hsl(268, 70%, 50%)',
    curry: 143,
    curryColor: 'hsl(35, 70%, 50%)',
    udon: 47,
    udonColor: 'hsl(42, 70%, 50%)',
    bagel: 135,
    bagelColor: 'hsl(129, 70%, 50%)',
    yakitori: 91,
    yakitoriColor: 'hsl(274, 70%, 50%)',
    takoyaki: 76,
    takoyakiColor: 'hsl(355, 70%, 50%)',
    tacos: 79,
    tacosColor: 'hsl(164, 70%, 50%)',
    'miso soup': 54,
    'miso soupColor': 'hsl(141, 70%, 50%)',
    tortilla: 95,
    tortillaColor: 'hsl(124, 70%, 50%)',
    tapas: 146,
    tapasColor: 'hsl(279, 70%, 50%)',
    chipirones: 131,
    chipironesColor: 'hsl(179, 70%, 50%)',
    gazpacho: 71,
    gazpachoColor: 'hsl(61, 70%, 50%)',
    soba: 163,
    sobaColor: 'hsl(187, 70%, 50%)',
    bavette: 21,
    bavetteColor: 'hsl(183, 70%, 50%)',
    steak: 191,
    steakColor: 'hsl(334, 70%, 50%)',
    pizza: 162,
    pizzaColor: 'hsl(73, 70%, 50%)',
    spaghetti: 115,
    spaghettiColor: 'hsl(149, 70%, 50%)',
    ravioli: 174,
    ravioliColor: 'hsl(139, 70%, 50%)',
    salad: 101,
    saladColor: 'hsl(71, 70%, 50%)',
    'pad thai': 81,
    'pad thaiColor': 'hsl(142, 70%, 50%)',
    bun: 116,
    bunColor: 'hsl(56, 70%, 50%)',
    waffle: 47,
    waffleColor: 'hsl(48, 70%, 50%)',
    crepe: 165,
    crepeColor: 'hsl(167, 70%, 50%)',
    churros: 5,
    churrosColor: 'hsl(184, 70%, 50%)',
    paella: 32,
    paellaColor: 'hsl(177, 70%, 50%)',
    empanadas: 150,
    empanadasColor: 'hsl(240, 70%, 50%)',
    bruschetta: 4,
    bruschettaColor: 'hsl(238, 70%, 50%)',
    'onion soup': 97,
    'onion soupColor': 'hsl(334, 70%, 50%)',
    cassoulet: 173,
    cassouletColor: 'hsl(64, 70%, 50%)',
    bouillabaisse: 29,
    bouillabaisseColor: 'hsl(241, 70%, 50%)',
    unagi: 117,
    unagiColor: 'hsl(98, 70%, 50%)',
    tempura: 125,
    tempuraColor: 'hsl(89, 70%, 50%)',
    tonkatsu: 165,
    tonkatsuColor: 'hsl(29, 70%, 50%)',
    'shabu-shabu': 40,
    'shabu-shabuColor': 'hsl(122, 70%, 50%)',
    twinkies: 82,
    twinkiesColor: 'hsl(261, 70%, 50%)',
    jerky: 42,
    jerkyColor: 'hsl(128, 70%, 50%)',
    fajitas: 106,
    fajitasColor: 'hsl(134, 70%, 50%)',
    jambalaya: 14,
    jambalayaColor: 'hsl(219, 70%, 50%)',
    meatloaf: 103,
    meatloafColor: 'hsl(343, 70%, 50%)',
    "mac n' cheese": 63,
    "mac n' cheeseColor": 'hsl(8, 70%, 50%)',
    'baked beans': 62,
    'baked beansColor': 'hsl(322, 70%, 50%)',
    popcorn: 177,
    popcornColor: 'hsl(12, 70%, 50%)',
    'buffalo wings': 79,
    'buffalo wingsColor': 'hsl(105, 70%, 50%)',
    'BBQ ribs': 127,
    'BBQ ribsColor': 'hsl(55, 70%, 50%)',
    'apple pie': 6,
    'apple pieColor': 'hsl(221, 70%, 50%)',
    nachos: 54,
    nachosColor: 'hsl(131, 70%, 50%)',
    risotto: 109,
    risottoColor: 'hsl(9, 70%, 50%)',
    tiramisu: 55,
    tiramisuColor: 'hsl(112, 70%, 50%)',
  },
  {
    country: 'BD',
    'hot dog': 185,
    'hot dogColor': 'hsl(226, 70%, 50%)',
    burger: 40,
    burgerColor: 'hsl(240, 70%, 50%)',
    sandwich: 2,
    sandwichColor: 'hsl(24, 70%, 50%)',
    kebab: 190,
    kebabColor: 'hsl(57, 70%, 50%)',
    fries: 29,
    friesColor: 'hsl(326, 70%, 50%)',
    donut: 162,
    donutColor: 'hsl(280, 70%, 50%)',
    junk: 31,
    junkColor: 'hsl(215, 70%, 50%)',
    sushi: 126,
    sushiColor: 'hsl(359, 70%, 50%)',
    ramen: 31,
    ramenColor: 'hsl(345, 70%, 50%)',
    curry: 74,
    curryColor: 'hsl(181, 70%, 50%)',
    udon: 37,
    udonColor: 'hsl(318, 70%, 50%)',
    bagel: 161,
    bagelColor: 'hsl(193, 70%, 50%)',
    yakitori: 163,
    yakitoriColor: 'hsl(247, 70%, 50%)',
    takoyaki: 171,
    takoyakiColor: 'hsl(49, 70%, 50%)',
    tacos: 184,
    tacosColor: 'hsl(118, 70%, 50%)',
    'miso soup': 40,
    'miso soupColor': 'hsl(42, 70%, 50%)',
    tortilla: 154,
    tortillaColor: 'hsl(313, 70%, 50%)',
    tapas: 184,
    tapasColor: 'hsl(156, 70%, 50%)',
    chipirones: 66,
    chipironesColor: 'hsl(355, 70%, 50%)',
    gazpacho: 170,
    gazpachoColor: 'hsl(57, 70%, 50%)',
    soba: 79,
    sobaColor: 'hsl(227, 70%, 50%)',
    bavette: 133,
    bavetteColor: 'hsl(275, 70%, 50%)',
    steak: 168,
    steakColor: 'hsl(252, 70%, 50%)',
    pizza: 138,
    pizzaColor: 'hsl(61, 70%, 50%)',
    spaghetti: 95,
    spaghettiColor: 'hsl(220, 70%, 50%)',
    ravioli: 195,
    ravioliColor: 'hsl(358, 70%, 50%)',
    salad: 51,
    saladColor: 'hsl(299, 70%, 50%)',
    'pad thai': 13,
    'pad thaiColor': 'hsl(291, 70%, 50%)',
    bun: 108,
    bunColor: 'hsl(57, 70%, 50%)',
    waffle: 171,
    waffleColor: 'hsl(215, 70%, 50%)',
    crepe: 125,
    crepeColor: 'hsl(266, 70%, 50%)',
    churros: 7,
    churrosColor: 'hsl(347, 70%, 50%)',
    paella: 22,
    paellaColor: 'hsl(102, 70%, 50%)',
    empanadas: 76,
    empanadasColor: 'hsl(184, 70%, 50%)',
    bruschetta: 49,
    bruschettaColor: 'hsl(121, 70%, 50%)',
    'onion soup': 142,
    'onion soupColor': 'hsl(26, 70%, 50%)',
    cassoulet: 71,
    cassouletColor: 'hsl(179, 70%, 50%)',
    bouillabaisse: 42,
    bouillabaisseColor: 'hsl(38, 70%, 50%)',
    unagi: 77,
    unagiColor: 'hsl(46, 70%, 50%)',
    tempura: 112,
    tempuraColor: 'hsl(287, 70%, 50%)',
    tonkatsu: 121,
    tonkatsuColor: 'hsl(89, 70%, 50%)',
    'shabu-shabu': 179,
    'shabu-shabuColor': 'hsl(203, 70%, 50%)',
    twinkies: 68,
    twinkiesColor: 'hsl(215, 70%, 50%)',
    jerky: 170,
    jerkyColor: 'hsl(7, 70%, 50%)',
    fajitas: 63,
    fajitasColor: 'hsl(45, 70%, 50%)',
    jambalaya: 136,
    jambalayaColor: 'hsl(251, 70%, 50%)',
    meatloaf: 144,
    meatloafColor: 'hsl(193, 70%, 50%)',
    "mac n' cheese": 73,
    "mac n' cheeseColor": 'hsl(46, 70%, 50%)',
    'baked beans': 139,
    'baked beansColor': 'hsl(355, 70%, 50%)',
    popcorn: 48,
    popcornColor: 'hsl(76, 70%, 50%)',
    'buffalo wings': 198,
    'buffalo wingsColor': 'hsl(174, 70%, 50%)',
    'BBQ ribs': 31,
    'BBQ ribsColor': 'hsl(289, 70%, 50%)',
    'apple pie': 180,
    'apple pieColor': 'hsl(292, 70%, 50%)',
    nachos: 11,
    nachosColor: 'hsl(178, 70%, 50%)',
    risotto: 53,
    risottoColor: 'hsl(71, 70%, 50%)',
    tiramisu: 32,
    tiramisuColor: 'hsl(94, 70%, 50%)',
  },
  {
    country: 'BE',
    'hot dog': 136,
    'hot dogColor': 'hsl(273, 70%, 50%)',
    burger: 99,
    burgerColor: 'hsl(85, 70%, 50%)',
    sandwich: 158,
    sandwichColor: 'hsl(178, 70%, 50%)',
    kebab: 80,
    kebabColor: 'hsl(272, 70%, 50%)',
    fries: 55,
    friesColor: 'hsl(14, 70%, 50%)',
    donut: 159,
    donutColor: 'hsl(53, 70%, 50%)',
    junk: 105,
    junkColor: 'hsl(148, 70%, 50%)',
    sushi: 77,
    sushiColor: 'hsl(327, 70%, 50%)',
    ramen: 195,
    ramenColor: 'hsl(185, 70%, 50%)',
    curry: 196,
    curryColor: 'hsl(342, 70%, 50%)',
    udon: 23,
    udonColor: 'hsl(208, 70%, 50%)',
    bagel: 23,
    bagelColor: 'hsl(290, 70%, 50%)',
    yakitori: 167,
    yakitoriColor: 'hsl(217, 70%, 50%)',
    takoyaki: 69,
    takoyakiColor: 'hsl(156, 70%, 50%)',
    tacos: 120,
    tacosColor: 'hsl(79, 70%, 50%)',
    'miso soup': 97,
    'miso soupColor': 'hsl(52, 70%, 50%)',
    tortilla: 60,
    tortillaColor: 'hsl(254, 70%, 50%)',
    tapas: 89,
    tapasColor: 'hsl(325, 70%, 50%)',
    chipirones: 47,
    chipironesColor: 'hsl(262, 70%, 50%)',
    gazpacho: 177,
    gazpachoColor: 'hsl(105, 70%, 50%)',
    soba: 66,
    sobaColor: 'hsl(212, 70%, 50%)',
    bavette: 3,
    bavetteColor: 'hsl(256, 70%, 50%)',
    steak: 173,
    steakColor: 'hsl(51, 70%, 50%)',
    pizza: 179,
    pizzaColor: 'hsl(95, 70%, 50%)',
    spaghetti: 166,
    spaghettiColor: 'hsl(68, 70%, 50%)',
    ravioli: 180,
    ravioliColor: 'hsl(87, 70%, 50%)',
    salad: 128,
    saladColor: 'hsl(277, 70%, 50%)',
    'pad thai': 91,
    'pad thaiColor': 'hsl(252, 70%, 50%)',
    bun: 33,
    bunColor: 'hsl(336, 70%, 50%)',
    waffle: 118,
    waffleColor: 'hsl(128, 70%, 50%)',
    crepe: 115,
    crepeColor: 'hsl(82, 70%, 50%)',
    churros: 162,
    churrosColor: 'hsl(83, 70%, 50%)',
    paella: 154,
    paellaColor: 'hsl(112, 70%, 50%)',
    empanadas: 130,
    empanadasColor: 'hsl(198, 70%, 50%)',
    bruschetta: 45,
    bruschettaColor: 'hsl(318, 70%, 50%)',
    'onion soup': 66,
    'onion soupColor': 'hsl(117, 70%, 50%)',
    cassoulet: 103,
    cassouletColor: 'hsl(38, 70%, 50%)',
    bouillabaisse: 187,
    bouillabaisseColor: 'hsl(348, 70%, 50%)',
    unagi: 9,
    unagiColor: 'hsl(192, 70%, 50%)',
    tempura: 186,
    tempuraColor: 'hsl(55, 70%, 50%)',
    tonkatsu: 163,
    tonkatsuColor: 'hsl(218, 70%, 50%)',
    'shabu-shabu': 51,
    'shabu-shabuColor': 'hsl(38, 70%, 50%)',
    twinkies: 108,
    twinkiesColor: 'hsl(31, 70%, 50%)',
    jerky: 70,
    jerkyColor: 'hsl(203, 70%, 50%)',
    fajitas: 7,
    fajitasColor: 'hsl(85, 70%, 50%)',
    jambalaya: 85,
    jambalayaColor: 'hsl(257, 70%, 50%)',
    meatloaf: 79,
    meatloafColor: 'hsl(130, 70%, 50%)',
    "mac n' cheese": 35,
    "mac n' cheeseColor": 'hsl(70, 70%, 50%)',
    'baked beans': 177,
    'baked beansColor': 'hsl(271, 70%, 50%)',
    popcorn: 34,
    popcornColor: 'hsl(357, 70%, 50%)',
    'buffalo wings': 164,
    'buffalo wingsColor': 'hsl(70, 70%, 50%)',
    'BBQ ribs': 28,
    'BBQ ribsColor': 'hsl(111, 70%, 50%)',
    'apple pie': 128,
    'apple pieColor': 'hsl(35, 70%, 50%)',
    nachos: 151,
    nachosColor: 'hsl(64, 70%, 50%)',
    risotto: 28,
    risottoColor: 'hsl(355, 70%, 50%)',
    tiramisu: 73,
    tiramisuColor: 'hsl(48, 70%, 50%)',
  },
  {
    country: 'BF',
    'hot dog': 86,
    'hot dogColor': 'hsl(23, 70%, 50%)',
    burger: 110,
    burgerColor: 'hsl(12, 70%, 50%)',
    sandwich: 176,
    sandwichColor: 'hsl(238, 70%, 50%)',
    kebab: 62,
    kebabColor: 'hsl(279, 70%, 50%)',
    fries: 20,
    friesColor: 'hsl(247, 70%, 50%)',
    donut: 168,
    donutColor: 'hsl(129, 70%, 50%)',
    junk: 82,
    junkColor: 'hsl(329, 70%, 50%)',
    sushi: 149,
    sushiColor: 'hsl(357, 70%, 50%)',
    ramen: 194,
    ramenColor: 'hsl(24, 70%, 50%)',
    curry: 124,
    curryColor: 'hsl(287, 70%, 50%)',
    udon: 182,
    udonColor: 'hsl(149, 70%, 50%)',
    bagel: 42,
    bagelColor: 'hsl(308, 70%, 50%)',
    yakitori: 180,
    yakitoriColor: 'hsl(257, 70%, 50%)',
    takoyaki: 78,
    takoyakiColor: 'hsl(193, 70%, 50%)',
    tacos: 73,
    tacosColor: 'hsl(359, 70%, 50%)',
    'miso soup': 12,
    'miso soupColor': 'hsl(178, 70%, 50%)',
    tortilla: 150,
    tortillaColor: 'hsl(338, 70%, 50%)',
    tapas: 131,
    tapasColor: 'hsl(183, 70%, 50%)',
    chipirones: 16,
    chipironesColor: 'hsl(12, 70%, 50%)',
    gazpacho: 61,
    gazpachoColor: 'hsl(23, 70%, 50%)',
    soba: 174,
    sobaColor: 'hsl(42, 70%, 50%)',
    bavette: 25,
    bavetteColor: 'hsl(120, 70%, 50%)',
    steak: 177,
    steakColor: 'hsl(63, 70%, 50%)',
    pizza: 119,
    pizzaColor: 'hsl(188, 70%, 50%)',
    spaghetti: 68,
    spaghettiColor: 'hsl(159, 70%, 50%)',
    ravioli: 27,
    ravioliColor: 'hsl(214, 70%, 50%)',
    salad: 75,
    saladColor: 'hsl(302, 70%, 50%)',
    'pad thai': 184,
    'pad thaiColor': 'hsl(107, 70%, 50%)',
    bun: 1,
    bunColor: 'hsl(262, 70%, 50%)',
    waffle: 47,
    waffleColor: 'hsl(217, 70%, 50%)',
    crepe: 60,
    crepeColor: 'hsl(249, 70%, 50%)',
    churros: 146,
    churrosColor: 'hsl(256, 70%, 50%)',
    paella: 1,
    paellaColor: 'hsl(313, 70%, 50%)',
    empanadas: 171,
    empanadasColor: 'hsl(39, 70%, 50%)',
    bruschetta: 173,
    bruschettaColor: 'hsl(248, 70%, 50%)',
    'onion soup': 177,
    'onion soupColor': 'hsl(45, 70%, 50%)',
    cassoulet: 83,
    cassouletColor: 'hsl(301, 70%, 50%)',
    bouillabaisse: 57,
    bouillabaisseColor: 'hsl(3, 70%, 50%)',
    unagi: 22,
    unagiColor: 'hsl(252, 70%, 50%)',
    tempura: 80,
    tempuraColor: 'hsl(319, 70%, 50%)',
    tonkatsu: 48,
    tonkatsuColor: 'hsl(26, 70%, 50%)',
    'shabu-shabu': 196,
    'shabu-shabuColor': 'hsl(155, 70%, 50%)',
    twinkies: 62,
    twinkiesColor: 'hsl(310, 70%, 50%)',
    jerky: 110,
    jerkyColor: 'hsl(90, 70%, 50%)',
    fajitas: 45,
    fajitasColor: 'hsl(109, 70%, 50%)',
    jambalaya: 168,
    jambalayaColor: 'hsl(10, 70%, 50%)',
    meatloaf: 73,
    meatloafColor: 'hsl(79, 70%, 50%)',
    "mac n' cheese": 71,
    "mac n' cheeseColor": 'hsl(298, 70%, 50%)',
    'baked beans': 82,
    'baked beansColor': 'hsl(204, 70%, 50%)',
    popcorn: 185,
    popcornColor: 'hsl(345, 70%, 50%)',
    'buffalo wings': 170,
    'buffalo wingsColor': 'hsl(291, 70%, 50%)',
    'BBQ ribs': 123,
    'BBQ ribsColor': 'hsl(101, 70%, 50%)',
    'apple pie': 59,
    'apple pieColor': 'hsl(95, 70%, 50%)',
    nachos: 107,
    nachosColor: 'hsl(303, 70%, 50%)',
    risotto: 191,
    risottoColor: 'hsl(198, 70%, 50%)',
    tiramisu: 186,
    tiramisuColor: 'hsl(170, 70%, 50%)',
  },
];

export default function BarplotCanvas({ data }: StackedBarplotProps) {
  return (
    <ResponsiveBarCanvas
      data={bpcData}
      keys={[
        'hot dog',
        'burger',
        'sandwich',
        'kebab',
        'fries',
        'donut',
        'junk',
        'sushi',
        'ramen',
        'curry',
        'udon',
        'bagel',
        'yakitori',
        'takoyaki',
        'tacos',
        'miso soup',
        'tortilla',
        'tapas',
        'chipirones',
        'gazpacho',
        'soba',
        'bavette',
        'steak',
        'pizza',
        'spaghetti',
        'ravioli',
        'salad',
        'pad thai',
        'bun',
        'waffle',
        'crepe',
        'churros',
        'paella',
        'empanadas',
        'bruschetta',
        'onion soup',
        'cassoulet',
        'bouillabaisse',
        'unagi',
        'tempura',
        'tonkatsu',
        'shabu-shabu',
        'twinkies',
        'jerky',
        'fajitas',
        'jambalaya',
        'meatloaf',
        'mac n cheese',
        'baked beans',
        'popcorn',
        'buffalo wings',
        'BBQ ribs',
        'apple pie',
        'nachos',
        'risotto',
        'tiramisu',
      ]}
      indexBy="country"
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      pixelRatio={1.25}
      padding={0.15}
      innerPadding={0}
      minValue="auto"
      maxValue="auto"
      groupMode="stacked"
      layout="horizontal"
      reverse={false}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'red_blue' }}
      colorBy="id"
      borderWidth={0}
      borderRadius={0}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: 36,
        truncateTickAt: 0,
      }}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'country',
        legendPosition: 'middle',
        legendOffset: 36,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'food',
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      enableGridX
      enableGridY={false}
      enableLabel
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      isInteractive
      legends={[]}
    />
  );
}
