import { splitName } from '../../../utils/helpers';

interface PageLevelProps {
  levelName: string;
  selectedLevel: string;
  levels: string[];
  onLevelChanged: (newLevel: string) => void;
}

function PageLevel({ levelName, selectedLevel, levels, onLevelChanged }: PageLevelProps) {
  return (
    <select
      id={`PageLevel-${levelName}`}
      className="bg-gray-100 border border-gray-500 text-gray-900 text-md rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 "
      onChange={(e) => onLevelChanged(e.target.value)}
      value={selectedLevel}
    >
      {levels.map((v) => {
        const [id, tag] = splitName(v);
        return (
          <option key={id} value={v}>
            {tag}
          </option>
        );
      })}
    </select>
  );
}

export default PageLevel;
