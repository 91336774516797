import { makeAutoObservable } from 'mobx';
import type RootStore from './rootStore';
import { SpotFieldDefintions, SpotListResult, SpotRowDetails } from '../models/reportModel';

const spotDefMock: SpotFieldDefintions = {
  schema: 'aumonthly',
  fields: [
    { tag: 'adId', name: 'Ad Id', format: 'string', meta: undefined },
    { tag: 'startDateTime', name: 'Time', format: 'time', meta: undefined },
    { tag: 'incidence', name: 'Incidence', format: 'int', meta: undefined },
    { tag: 'cost', name: 'Cost', format: 'currency', meta: { dataset: undefined, divisor: 100 } },
    { tag: 'duration', name: 'Duration', format: 'int', meta: undefined },
    { tag: 'nib', name: 'Number-In-Break', format: 'int', meta: undefined },
    { tag: 'pib', name: 'Position-In-Break', format: 'int', meta: undefined },
    { tag: 'bip', name: 'Break-In-Program', format: 'int', meta: undefined },
    { tag: 'product', name: 'Product', format: 'label', meta: { dataset: 'dictionary', divisor: undefined } },
    { tag: 'advertiser', name: 'Advertiser', format: 'label', meta: { dataset: 'dictionary', divisor: undefined } },
    { tag: 'agency', name: 'Agency', format: 'label', meta: { dataset: 'dictionary', divisor: undefined } },
    { tag: 'subCategory', name: 'Sub Category', format: 'label', meta: { dataset: 'dictionary', divisor: undefined } },
    { tag: 'category', name: 'Category', format: 'label', meta: { dataset: 'dictionary', divisor: undefined } },
    {
      tag: 'categoryGroup',
      name: 'Category Group',
      format: 'label',
      meta: { dataset: 'dictionary', divisor: undefined },
    },
    { tag: 'outlet', name: 'Outlet', format: 'label', meta: { dataset: 'media', divisor: undefined } },
    { tag: 'market', name: 'Market', format: 'label', meta: { dataset: 'media', divisor: undefined } },
    { tag: 'marketgroup', name: 'Market Group', format: 'label', meta: { dataset: 'media', divisor: undefined } },
    { tag: 'network', name: 'Network', format: 'label', meta: { dataset: 'media', divisor: undefined } },
    { tag: 'region1', name: 'Region', format: 'label', meta: { dataset: 'media', divisor: undefined } },
    { tag: 'region2', name: 'Region 2', format: 'label', meta: { dataset: 'media', divisor: undefined } },
    { tag: 'mediaType', name: 'Media Type', format: 'label', meta: { dataset: 'media', divisor: undefined } },
    { tag: 'id', name: 'Spot Id', format: 'label', meta: undefined },
  ],
};

// Display order of fields
const spotFieldOrder = [
  'adId',
  'startDateTime',
  'incidence',
  'cost',
  'duration',
  'nib',
  'pib',
  'bip',
  'product',
  'advertiser',
  'agency',
  'subCategory',
  'category',
  'categoryGroup',
  'outlet',
  'market',
  'marketgroup',
  'network',
  'region1',
  'region2',
  'mediaType',
  'id',
];

const spotDataMock: SpotRowDetails[] = [
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-05T11:59:59',
      '1',
      '45100',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000D|TVW7',
      'm00Y|Perth',
      'g006|Western Australia',
      'n001|NET7',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000D|TVW7-20211105115959',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-04T21:28:22',
      '1',
      '250000',
      '30',
      '9',
      '9',
      '2',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000D|TVW7',
      'm00Y|Perth',
      'g006|Western Australia',
      'n001|NET7',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000D|TVW7-20211104212822',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-02T11:59:54',
      '1',
      '150000',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000D|TVW7',
      'm00Y|Perth',
      'g006|Western Australia',
      'n001|NET7',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000D|TVW7-20211102115954',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T22:31:49',
      '1',
      '43300',
      '30',
      '9',
      '9',
      '3',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000D|TVW7',
      'm00Y|Perth',
      'g006|Western Australia',
      'n001|NET7',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000D|TVW7-20211101223149',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T08:34:08',
      '1',
      '12300',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211101083408',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T04:30:53',
      '1',
      '440000',
      '10',
      '5',
      '5',
      '10',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211101043053',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-02T11:59:56',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000A|SAS7',
      'm002|Adelaide',
      'g005|South Australia',
      'n001|NET7',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000A|SAS7-20211102115956',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-04T19:41:59',
      '1',
      '0',
      '30',
      '5',
      '5',
      '6',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000A|SAS7',
      'm002|Adelaide',
      'g005|South Australia',
      'n001|NET7',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000A|SAS7-20211104194159',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-05T12:00:00',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000A|SAS7',
      'm002|Adelaide',
      'g005|South Australia',
      'n001|NET7',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000A|SAS7-20211105120000',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T22:24:46',
      '1',
      '0',
      '30',
      '8',
      '8',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o000A|SAS7',
      'm002|Adelaide',
      'g005|South Australia',
      'n001|NET7',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o000A|SAS7-20211101222446',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-06T04:51:07',
      '1',
      '0',
      '10',
      '1',
      '1',
      '16',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021110604510|7Mate PER7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T09:34:21',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021110109342|7Mate SYD1',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T03:14:43',
      '1',
      '0',
      '10',
      '6',
      '6',
      '17',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021110103144|7Mate PER3',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T06:34:08',
      '1',
      '0',
      '10',
      '1',
      '1',
      '7',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021110106340|7Mate PER8',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-05T05:36:44',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021110505364|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T05:30:54',
      '1',
      '0',
      '10',
      '7',
      '7',
      '10',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211101053054',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T09:34:09',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211101093409',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T09:04:10',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211101090410',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T05:00:55',
      '1',
      '0',
      '10',
      '7',
      '7',
      '10',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211101050055',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-04T19:42:11',
      '1',
      '0',
      '30',
      '6',
      '6',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0001|ATN7',
      'm00M|Sydney',
      'g002|New South Wales',
      'n001|NET7',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0001|ATN7-20211104194211',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-05T12:00:12',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0001|ATN7',
      'm00M|Sydney',
      'g002|New South Wales',
      'n001|NET7',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0001|ATN7-20211105120012',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T22:24:57',
      '1',
      '0',
      '30',
      '10',
      '10',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0001|ATN7',
      'm00M|Sydney',
      'g002|New South Wales',
      'n001|NET7',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0001|ATN7-20211101222457',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-02T12:03:53',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0001|ATN7',
      'm00M|Sydney',
      'g002|New South Wales',
      'n001|NET7',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0001|ATN7-20211102120353',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-05T12:00:03',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0004|HSV7',
      'm00H|Melbourne',
      'g003|Victoria',
      'n001|NET7',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0004-2021110512000|HSV73',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T22:24:50',
      '1',
      '0',
      '30',
      '9',
      '9',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0004|HSV7',
      'm00H|Melbourne',
      'g003|Victoria',
      'n001|NET7',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0004-2021110122245|HSV70',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-04T19:42:02',
      '1',
      '0',
      '30',
      '7',
      '7',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0004|HSV7',
      'm00H|Melbourne',
      'g003|Victoria',
      'n001|NET7',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0004-2021110419420|HSV72',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-02T12:03:46',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0004|HSV7',
      'm00H|Melbourne',
      'g003|Victoria',
      'n001|NET7',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0004-2021110212034|HSV76',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T06:37:29',
      '1',
      '0',
      '10',
      '9',
      '9',
      '6',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021120606372|7Mate SYD9',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T06:38:40',
      '1',
      '0',
      '10',
      '6',
      '6',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211227063840',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T11:14:40',
      '1',
      '0',
      '10',
      '5',
      '5',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211213111440',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T10:55:13',
      '1',
      '0',
      '10',
      '6',
      '6',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211227105513',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T11:29:47',
      '1',
      '0',
      '10',
      '6',
      '6',
      '26',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211206112947',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T06:37:17',
      '1',
      '0',
      '10',
      '7',
      '7',
      '6',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211206063717',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T06:34:20',
      '1',
      '0',
      '10',
      '5',
      '5',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211213063420',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T06:33:47',
      '1',
      '0',
      '10',
      '9',
      '9',
      '6',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211220063347',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-11T05:23:13',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211211052313',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-24T05:35:03',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211224053503',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T10:29:47',
      '1',
      '0',
      '10',
      '6',
      '6',
      '26',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211206102947',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T09:55:13',
      '1',
      '0',
      '10',
      '5',
      '5',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211227095513',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-17T05:07:21',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211217050721',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T06:28:15',
      '1',
      '0',
      '10',
      '5',
      '5',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211206062815',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-25T05:21:03',
      '1',
      '0',
      '10',
      '1',
      '1',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211225052103',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-18T05:23:06',
      '1',
      '0',
      '10',
      '4',
      '4',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211218052306',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T09:41:26',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211220094126',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-31T05:18:56',
      '1',
      '0',
      '10',
      '1',
      '1',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211231051856',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-05T12:00:05',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0007|BTQ7',
      'm008|Brisbane',
      'g004|Queensland',
      'n001|NET7',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0007|BTQ7-20211105120005',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T22:24:50',
      '1',
      '0',
      '30',
      '8',
      '8',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0007|BTQ7',
      'm008|Brisbane',
      'g004|Queensland',
      'n001|NET7',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0007|BTQ7-20211101222450',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-02T12:03:46',
      '1',
      '0',
      '30',
      '1',
      '1',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0007|BTQ7',
      'm008|Brisbane',
      'g004|Queensland',
      'n001|NET7',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0007|BTQ7-20211102120346',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-04T19:42:04',
      '1',
      '0',
      '30',
      '7',
      '7',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o0007|BTQ7',
      'm008|Brisbane',
      'g004|Queensland',
      'n001|NET7',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o0007|BTQ7-20211104194204',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T06:33:47',
      '1',
      '0',
      '10',
      '6',
      '6',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211220063347',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T10:14:25',
      '1',
      '0',
      '10',
      '6',
      '6',
      '26',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211213101425',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T06:33:28',
      '1',
      '0',
      '10',
      '4',
      '4',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211213063328',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-10T05:12:34',
      '1',
      '0',
      '10',
      '1',
      '1',
      '18',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211210051234',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T06:33:58',
      '1',
      '0',
      '10',
      '9',
      '9',
      '6',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021122006335|7Mate SYD8',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T06:38:52',
      '1',
      '0',
      '10',
      '7',
      '7',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021122706385|7Mate SYD2',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T10:41:27',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211220104127',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-01T05:31:06',
      '1',
      '0',
      '10',
      '6',
      '6',
      '11',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021110105310|7Mate SYD6',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T07:55:11',
      '1',
      '0',
      '10',
      '6',
      '6',
      '15',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021122707551|7Mate PER1',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-17T05:11:54',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021121705115|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T04:33:46',
      '1',
      '0',
      '10',
      '6',
      '6',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021122004334|7Mate PER6',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T08:29:45',
      '1',
      '0',
      '10',
      '7',
      '7',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021120608294|7Mate PER5',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T04:44:50',
      '1',
      '0',
      '10',
      '5',
      '5',
      '23',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021122704445|7Mate PER0',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2022-01-02T05:41:57',
      '1',
      '0',
      '10',
      '1',
      '1',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2022010205415|7Mate PER7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T07:41:25',
      '1',
      '0',
      '10',
      '1',
      '1',
      '13',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021122007412|7Mate PER5',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-10T05:08:17',
      '1',
      '0',
      '10',
      '1',
      '1',
      '18',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021121005081|7Mate PER7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-11T05:23:11',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021121105231|7Mate PER1',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-24T05:35:00',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021122405350|7Mate PER0',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T04:28:14',
      '1',
      '0',
      '10',
      '5',
      '5',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021120604281|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T08:14:24',
      '1',
      '0',
      '10',
      '5',
      '5',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021121308142|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T06:34:08',
      '1',
      '0',
      '10',
      '5',
      '5',
      '6',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021121306340|7Mate SYD8',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T11:29:58',
      '1',
      '0',
      '10',
      '5',
      '5',
      '26',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021120611295|7Mate SYD8',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T10:41:38',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021122010413|7Mate SYD8',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T11:14:37',
      '1',
      '0',
      '10',
      '7',
      '7',
      '28',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021121311143|7Mate SYD7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T10:55:24',
      '1',
      '0',
      '10',
      '6',
      '6',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021122710552|7Mate SYD4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T10:11:27',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211220101127',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T06:07:18',
      '1',
      '0',
      '10',
      '9',
      '9',
      '3',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211206060718',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T06:08:41',
      '1',
      '0',
      '10',
      '7',
      '7',
      '13',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211227060841',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-25T05:21:00',
      '1',
      '0',
      '10',
      '1',
      '1',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021122505210|7Mate PER0',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T04:33:27',
      '1',
      '0',
      '10',
      '5',
      '5',
      '26',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021121304332|7Mate PER7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-18T05:23:05',
      '1',
      '0',
      '10',
      '5',
      '5',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021121805230|7Mate PER5',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-31T05:18:54',
      '1',
      '0',
      '10',
      '1',
      '1',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021123105185|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-27T10:25:13',
      '1',
      '0',
      '10',
      '6',
      '6',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211227102513',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T06:03:57',
      '1',
      '0',
      '10',
      '5',
      '5',
      '17',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211213060357',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-20T06:03:47',
      '1',
      '0',
      '10',
      '9',
      '9',
      '2',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211220060347',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-06T10:59:47',
      '1',
      '0',
      '10',
      '5',
      '5',
      '26',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211206105947',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-13T10:44:26',
      '1',
      '0',
      '10',
      '5',
      '5',
      '25',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211213104426',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T10:07:55',
      '1',
      '0',
      '10',
      '5',
      '5',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211129100755',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T06:52:09',
      '1',
      '0',
      '10',
      '5',
      '5',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211129065209',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-04T05:25:47',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211204052547',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T10:37:55',
      '1',
      '0',
      '10',
      '5',
      '5',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211129103755',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T06:23:15',
      '1',
      '0',
      '10',
      '9',
      '9',
      '1',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211129062315',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T06:30:34',
      '1',
      '0',
      '10',
      '5',
      '5',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021110806303|7Mate SYD4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T06:37:01',
      '1',
      '0',
      '10',
      '5',
      '5',
      '5',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211115063701',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T10:23:32',
      '1',
      '0',
      '10',
      '6',
      '6',
      '24',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211115102332',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-03T05:14:39',
      '1',
      '0',
      '10',
      '5',
      '5',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211203051439',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T05:30:20',
      '1',
      '0',
      '10',
      '5',
      '5',
      '13',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211108053020',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-27T05:10:04',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211127051004',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T09:39:14',
      '1',
      '0',
      '10',
      '5',
      '5',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211122093914',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T10:53:34',
      '1',
      '0',
      '10',
      '6',
      '6',
      '24',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211115105334',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T10:50:37',
      '1',
      '0',
      '10',
      '7',
      '7',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211108105037',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T05:55:46',
      '1',
      '0',
      '10',
      '5',
      '5',
      '13',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211122055546',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T10:09:14',
      '1',
      '0',
      '10',
      '7',
      '7',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211122100914',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T10:20:35',
      '1',
      '0',
      '10',
      '6',
      '6',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211108102035',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-19T05:10:11',
      '1',
      '0',
      '10',
      '5',
      '5',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211119051011',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T06:46:22',
      '1',
      '0',
      '10',
      '5',
      '5',
      '6',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211122064622',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-20T05:18:05',
      '1',
      '0',
      '10',
      '5',
      '5',
      '18',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211120051805',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-26T05:30:04',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E9|7Mate BRIS',
      'm008|Brisbane',
      'g004|Queensland',
      'n02P|7Mate',
      'q004|Queensland',
      'r00G|Queensland',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E9|7Mate BRIS-20211126053004',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T06:53:16',
      '1',
      '0',
      '10',
      '7',
      '7',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211129065316',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T06:00:22',
      '1',
      '0',
      '10',
      '5',
      '5',
      '13',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211108060022',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T05:56:22',
      '1',
      '0',
      '10',
      '5',
      '5',
      '13',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EA|7Mate ADE',
      'm002|Adelaide',
      'g005|South Australia',
      'n02P|7Mate',
      'q005|South Australia',
      'r00H|South Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EA|7Mate ADE-20211115055622',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T10:39:25',
      '1',
      '0',
      '10',
      '6',
      '6',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021112210392|7Mate SYD5',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T11:08:06',
      '1',
      '0',
      '10',
      '6',
      '6',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021112911080|7Mate SYD6',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T06:26:33',
      '1',
      '0',
      '10',
      '4',
      '4',
      '3',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021111506263|7Mate SYD3',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T06:25:57',
      '1',
      '0',
      '10',
      '5',
      '5',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021112206255|7Mate SYD7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T11:20:49',
      '1',
      '0',
      '10',
      '5',
      '5',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021110811204|7Mate SYD9',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T06:53:27',
      '1',
      '0',
      '10',
      '10',
      '10',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021112906532|7Mate SYD7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-20T05:18:04',
      '1',
      '0',
      '10',
      '5',
      '5',
      '18',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021112005180|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-26T05:30:02',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021112605300|7Mate PER2',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-03T05:14:38',
      '1',
      '0',
      '10',
      '5',
      '5',
      '21',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021120305143|7Mate PER8',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T04:46:20',
      '1',
      '0',
      '10',
      '5',
      '5',
      '23',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021112204462|7Mate PER0',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-13T05:13:54',
      '1',
      '0',
      '10',
      '1',
      '1',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021111305135|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T08:07:53',
      '1',
      '0',
      '10',
      '6',
      '6',
      '16',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021112908075|7Mate PER3',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-12-04T05:25:45',
      '1',
      '0',
      '10',
      '1',
      '1',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021120405254|7Mate PER5',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T08:20:36',
      '1',
      '0',
      '10',
      '6',
      '6',
      '16',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021110808203|7Mate PER6',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T07:39:12',
      '1',
      '0',
      '10',
      '5',
      '5',
      '17',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021112207391|7Mate PER2',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T11:23:45',
      '1',
      '0',
      '10',
      '5',
      '5',
      '24',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E7|7Mate SYD',
      'm00M|Sydney',
      'g002|New South Wales',
      'n02P|7Mate',
      'q002|New South Wales',
      'r00E|New South Wales',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E7-2021111511234|7Mate SYD5',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T06:25:46',
      '1',
      '0',
      '10',
      '5',
      '5',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211122062546',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T11:07:55',
      '1',
      '0',
      '10',
      '5',
      '5',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211129110755',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T11:20:37',
      '1',
      '0',
      '10',
      '6',
      '6',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211108112037',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T06:26:22',
      '1',
      '0',
      '10',
      '5',
      '5',
      '3',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211115062622',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T06:30:23',
      '1',
      '0',
      '10',
      '5',
      '5',
      '4',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211108063023',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T11:23:34',
      '1',
      '0',
      '10',
      '6',
      '6',
      '24',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211115112334',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T04:37:01',
      '1',
      '0',
      '10',
      '5',
      '5',
      '24',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021111504370|7Mate PER1',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-12T05:10:34',
      '1',
      '0',
      '10',
      '1',
      '1',
      '17',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021111205103|7Mate PER4',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-19T05:10:09',
      '1',
      '0',
      '10',
      '4',
      '4',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021111905100|7Mate PER9',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-29T04:52:07',
      '1',
      '0',
      '10',
      '6',
      '6',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021112904520|7Mate PER7',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-27T05:10:02',
      '1',
      '0',
      '10',
      '1',
      '1',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021112705100|7Mate PER2',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-08T04:30:49',
      '1',
      '0',
      '10',
      '5',
      '5',
      '22',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021110804304|7Mate PER9',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-15T08:23:32',
      '1',
      '0',
      '10',
      '5',
      '5',
      '19',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00EB|7Mate PER',
      'm00Y|Perth',
      'g006|Western Australia',
      'n02P|7Mate',
      'q006|Western Australia',
      'r00I|Western Australia',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00EB-2021111508233|7Mate PER2',
    ],
  },
  {
    index: 0,
    fields: [
      'i005NHO',
      '2021-11-22T10:39:14',
      '1',
      '0',
      '10',
      '5',
      '5',
      '20',
      'p02R44|NFL GPass',
      'a01yfV|NFL Enterprises',
      'y00Ff|Direct Advertiser',
      'u2J9u|Mobile Content/Services',
      'w0CGO|Mobile Content/Services',
      's0CDG|Communications',
      'o00E8|7Mate MELB',
      'm00H|Melbourne',
      'g003|Victoria',
      'n02P|7Mate',
      'q003|Victoria',
      'r00F|Victoria',
      't001|Television',
      'd001|Metropolitan Television',
      'i005NHO-o00E8|7Mate MELB-20211122103914',
    ],
  },
];

class ActiveSpotStore {
  private rootStore: RootStore;

  private spotDefs: Map<string, SpotFieldDefintions> = new Map();

  private spotResults: Map<string, SpotListResult> = new Map();

  constructor(root: RootStore) {
    this.rootStore = root;
    makeAutoObservable(this);
  }

  async getSpotDefinition(schema: string): Promise<SpotFieldDefintions> {
    const sd = this.spotDefs.get(schema);
    if (sd !== undefined) return sd;

    // TODO - get via API
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 10));
    this.spotDefs.set(schema, spotDefMock);
    return spotDefMock;
  }

  getReportSpotResult(reportId: string): SpotListResult | undefined {
    const sr = this.spotResults.get(reportId);
    if (sr !== undefined) return sr;

    // TODO - get via API - no wait

    this.spotResults.set(reportId, {
      spotDef: spotDefMock,
      fieldOrder: spotFieldOrder,
      spots: spotDataMock.map((s, i) => ({ index: i, fields: s.fields } as SpotRowDetails)),
      startRow: 0,
      rowCount: spotDataMock.length,
      totalRows: spotDataMock.length,
    } as SpotListResult);

    return undefined;
  }
}

export default ActiveSpotStore;
