import _ from 'lodash';

export interface AppSettings {
  // NOTE: all members must be type 'string | null' for the setting extraction to work...
  // Null means 'use default' (ie. use the next settings level up)

  // Schema
  currentSchema: string | null;

  currentSchemaVersion: string | null;

  // Locale - sets date format and decimal/thousand-separator for numbers
  locale: string | null;

  // Dates + Times
  dateSize: string | null;

  use12hTime: string | null;

  // Numbers
  lowValueSymbol: string | null;

  blankZeroValues: string | null;

  // Currency values
  currency: string | null; // ISO 4217 currency codes as  defined here: https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes

  currencyDecimalPlaces: string | null;

  currencyDivisor: string | null; // e.g. 1000 to show in thousands

  // Other Values
  otherDecimalPlaces: string | null;

  otherDivisor: string | null;

  // Generic Date/Time formatting - See formats at https://moment.github.io/luxon/#/formatting?id=table-of-tokens
  moyFormat: string | null; // 'number', 'narrow', 'short', 'long'

  dowFormat: string | null; // 'number', 'narrow', 'short', 'long'

  hodFormat: string | null; // 'H', 'HH', 'HH:00', 'h a', 'hh a', 'h:00 a'

  modFormat: string | null; // 'HH:mm', 'h:mm a', 'number'

  absMthFormat: string | null; // 'LL/yy', 'LLL yy', 'yyyy LLLL'

  absWkFormat: string | null; // 'yyyy WW', 'D', 'DD'

  absDayFormat: string | null; // 'D', 'DD'

  absHourFormat: string | null; // 'f', 'F', 'D HH:00'

  absMinFormat: string | null; // 'f', 'F', 'D HH:mm'

  hideConfirmDialogs: string | null; // List of dialog keys to auto-hide

  // Dianostic settings
  showSelectionIds: string | null; // Show IDs in results/selections

  showRowIds: string | null; // Show the row IDs in the result grid

  // Report Templates
  reportTemplateDefault: string | null; // Basic template for initial report
  reportTemplateBasic: string | null; // Basic template for new 'basic-layout' reports
  reportTemplateSpotList: string | null; // Basic template for new 'spot-list' reports
}

// NOTE: these DO NOT align exactly with backend:
//  - ReportLevel is not saved to db, but is needed here in hierarchy
//  - UserLevel has ID = 0 in backend, etc
// Adjust for this in API only...
export enum SettingsLevel {
  ReportLevel = 0, // Save to report only, not to db
  UserLevel = 1,
  OrgLevel = 2,
  GlobalLevel = 3,
}

export const EmptySettings: AppSettings = {
  currentSchema: null,
  currentSchemaVersion: null,
  locale: null,
  dateSize: null,
  use12hTime: null,
  lowValueSymbol: null,
  blankZeroValues: null,
  currency: null,
  currencyDecimalPlaces: null,
  currencyDivisor: null,
  otherDecimalPlaces: null,
  otherDivisor: null,
  moyFormat: null,
  dowFormat: null,
  hodFormat: null,
  modFormat: null,
  absMthFormat: null,
  absWkFormat: null,
  absDayFormat: null,
  absHourFormat: null,
  absMinFormat: null,
  hideConfirmDialogs: null,
  showSelectionIds: null,
  showRowIds: null,
  reportTemplateDefault: null,
  reportTemplateBasic: null,
  reportTemplateSpotList: null,
};

// Get; hide internal null
export function getSettingsValue(settings: AppSettings, key: keyof AppSettings): string | undefined {
  const val = settings[key] as string | null;
  if (val === null) return undefined;
  if (val === undefined) return undefined;
  return val;
}

export function setSettingsValue(
  settings: AppSettings,
  key: keyof AppSettings,
  val: string | undefined | null
): boolean {
  const valOrNull = val != null ? val : null;
  if (settings[key] === valOrNull) return false;
  // eslint-disable-next-line no-param-reassign
  settings[key] = valOrNull;
  return true;
}

export function getSettingsKeys(): (keyof AppSettings)[] {
  return Object.keys(EmptySettings) as (keyof AppSettings)[];
}

export function settingsAreSame(l: AppSettings, r: AppSettings): boolean {
  const keys = getSettingsKeys();
  const different = _.some(keys, (key) => l[key] !== r[key]);
  return !different;
}
