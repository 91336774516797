import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useState } from 'react';
import DialogTextInput from '../controls/form/DialogTextInput';
import { CreateUserDto } from '../../models/userModel';
import DialogSelect from '../controls/form/DialogSelect';
import { RootContext } from '../../stores/storeProvidor';
import Perms from '../../permissions/permissions';
import AdminModalValidationBox from '../admin/controls/AdminModalValidationBox';

const emptyUser = {
  userName: '',
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  orgId: 0,
} as CreateUserDto;

const AddNewUserModal = NiceModal.create(() => {
  const modal = useModal();
  const rctx = useContext(RootContext);
  const adminStore = rctx.getAdminStore();
  const [user, setUser] = useState<CreateUserDto>(emptyUser);
  const [validationMsg, setValidationMsg] = useState<string[]>([]);
  const creatorsOrgId = rctx.activeUserStore.getUser?.orgId;
  const canSetOrganisation = rctx.activeUserStore.hasPermission(Perms.AdminOrgs);

  const org = adminStore.findOrganisaionById(creatorsOrgId ?? 0);
  const allOrgs = adminStore.activeOrganisaions();

  const hasError = (id: string) => validationMsg.some((msg) => msg.startsWith(id));
  const disableSave = () => validationMsg.length > 1 || (validationMsg.length === 1 && !hasError('err'));

  const onConfirm = () => {
    // Validate
    const msg: string[] = [];
    if (user.userName === '') msg.push('username|User Name is required');
    if (user.firstName === '') msg.push('firstname|First Name is required');
    if (user.lastName === '') msg.push('lastname|Last Name is required');
    if (user.email === '') msg.push('email|Email is required');
    if (user.phone === '') msg.push('phone|Phone number is required');
    setValidationMsg(msg);
    if (msg.length !== 0) {
      return;
    }

    // Call the API to create the user
    adminStore.createNewUser(user).then(
      () => {
        modal.remove();
      },
      (e) => {
        setValidationMsg([`err|${e.message}`]);
      }
    );
  };

  const onCancel = () => {
    modal.remove();
  };

  return (
    <Modal show onClose={onCancel} size="sm" popup>
      <Modal.Body>
        <h3 className="pt-6 pb-2">Add a new user</h3>
        <DialogTextInput
          id="username"
          label="User Name"
          defaultValue=""
          onEdit={(v) => setUser({ ...user, userName: v })}
          hasError={hasError('username')}
        />
        <DialogSelect
          id="title"
          label="Title"
          options={['Ms|Ms', 'Mrs|Mrs', 'Mr|Mr', 'Dr|Dr', 'None|None']}
          selectedOption={user.title === '' ? 'None' : user.title}
          defaultOption="None"
          onSelect={(id, val) => {
            setUser({ ...user, title: val === 'None' ? '' : val });
          }}
          disabled={false}
        />
        <DialogTextInput
          id="givenname"
          label="Given Name"
          defaultValue=""
          onEdit={(v) => setUser({ ...user, firstName: v })}
          hasError={hasError('firstname')}
        />
        <DialogTextInput
          id="lastname"
          label="Last Name"
          defaultValue=""
          onEdit={(v) => setUser({ ...user, lastName: v })}
          hasError={hasError('lastname')}
        />
        <DialogTextInput
          id="email"
          label="Email"
          defaultValue=""
          onEdit={(v) => setUser({ ...user, email: v })}
          hasError={hasError('email')}
          inputType="email"
        />
        <DialogTextInput
          id="phone"
          label="Phone"
          defaultValue=""
          onEdit={(v) => setUser({ ...user, phone: v })}
          hasError={hasError('phone')}
          inputType="tel"
        />
        {canSetOrganisation ? (
          <DialogSelect
            id="org"
            label="Organisation"
            options={allOrgs.map((o) => `${o.id}|${o.name}`) ?? []}
            selectedOption={`${user.orgId}`}
            defaultOption={`${org?.id}`}
            onSelect={(id, val) => {
              setUser({ ...user, orgId: parseInt(val, 10) });
            }}
            disabled={false}
          />
        ) : (
          <div className="text-sm text-center">
            User will belong to : <span className="font-bold">{org?.name}</span>
          </div>
        )}

        <AdminModalValidationBox msgs={validationMsg} />

        <div className="flex justify-center gap-4 mt-4">
          <Button onClick={onConfirm} disabled={disableSave()}>
            Save
          </Button>
          <Button color="gray" onClick={onCancel}>
            Discard
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default AddNewUserModal;
