import { useContext } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { RootContext } from '../stores/storeProvidor';
import ConfirmModalWithHide from '../Components/modals/ConfirmModalWithHide';
import { SelectionNodeType, SelectionType } from '../models/selectionModels';
import { getSelectionTypeUserText } from '../utils/selectionTreeUtils';
import { baseKey } from '../stores/selectionDragStore';
import { baseId } from '../utils/dragUtils';
import RenameModal from '../Components/modals/RenameModal';

export default function useSelectionDialogs(updateKey: string) {
  const { selectionDragStore, uiState } = useContext(RootContext);
  const [containerId, embedded, selType] = baseKey(updateKey).split('~');
  const userTextForType = getSelectionTypeUserText(selType as SelectionType);

  const deleteNode = (nodeId: string) => {
    const node = selectionDragStore.findNode(updateKey, baseId({ id: nodeId }), true);
    if (!node) return;
    if (node.nodeType === SelectionNodeType.Root) {
      NiceModal.show(ConfirmModalWithHide, {
        hideKey: 'deleteRoot',
        title: 'Discard Whole Selection',
        body: (
          <div>
            <div className="font-semibold pb-2">Are you sure you want the delete the whole selection?</div>
            <div>All {userTextForType} content will be discarded.</div>
          </div>
        ),
        onYes: () => {
          selectionDragStore.deleteNode(updateKey, node.nid);
        },
      });
      return;
    }

    if (node.nodeType === SelectionNodeType.Folder) {
      NiceModal.show(ConfirmModalWithHide, {
        hideKey: 'deleteFolder',
        title: 'Discard Folder',
        body: (
          <div>
            <div className="font-semibold pb-2">Are you sure you want the delete this folder?</div>
            <div>All content within this folder will be discarded too.</div>
          </div>
        ),
        onYes: () => {
          selectionDragStore.deleteNode(updateKey, node.nid);
        },
      });
      return;
    }

    selectionDragStore.deleteNode(updateKey, node.nid);
  };

  const editNodeSettings = (nodeId: string) => {
    const node = selectionDragStore.findNode(updateKey, baseId({ id: nodeId }), true);
    // TODO: dialog for settings
    uiState.warningAlert = `Settings for ${node?.name ?? 'unknown'} not implemented yet.`;
  };

  const renameNode = (nodeId: string) => {
    const node = selectionDragStore.findNode(updateKey, baseId({ id: nodeId }), true);
    if (!node) return;

    if (
      node.nodeType === SelectionNodeType.Root ||
      node.nodeType === SelectionNodeType.Folder ||
      node.nodeType === SelectionNodeType.LevelFilter
    ) {
      NiceModal.show(RenameModal, {
        title: node.nodeType === SelectionNodeType.Root ? 'Rename Selection' : 'Rename Folder',
        originalName: node.name,
        description:
          node.nodeType === SelectionNodeType.Folder
            ? 'Enter a new name for this folder...'
            : 'Enter a new name for this selection...',
        onConfirm: (name: string) => {
          selectionDragStore.renameTreeNode(updateKey, node.nid, name);
        },
      });
    }
  };

  const addNewNode = (nodeId: string) => {
    const node = selectionDragStore.findNode(updateKey, baseId({ id: nodeId }), true);
    if (!node) return;
    // TODO: dialog for new name?
    selectionDragStore.addNewFolder(updateKey, node.nid, 'New Folder');
  };

  const linkNode = (nodeId: string) => {
    const node = selectionDragStore.findNode(updateKey, baseId({ id: nodeId }), true);
    // TODO: dialog to handle linking
    uiState.warningAlert = `Linking for ${node?.name ?? 'unknown'} not implemented yet.`;
  };

  return {
    deleteNode,
    editNodeSettings,
    renameNode,
    addNewNode,
    linkNode,
  };
}
