import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

// Base of all api calls.
// See https://semaphoreci.com/blog/api-layer-react

const onRequest = (
  config: InternalAxiosRequestConfig<unknown>
): InternalAxiosRequestConfig<unknown> | Promise<InternalAxiosRequestConfig<unknown>> => {
  // eslint-disable-next-line no-console
  console.info(`[request] [${JSON.stringify(config)}]`);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // eslint-disable-next-line no-console
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // eslint-disable-next-line no-console
  console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // eslint-disable-next-line no-console
  console.error(`[response error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

function setupApiLogging(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

class ApiClient {
  private apiInstance: AxiosInstance;

  constructor() {
    this.apiInstance = axios.create({
      baseURL: import.meta.env.VITE_WEB_API_BASE_URI,
      withCredentials: true,
    });

    // Dev only - Hooks to log all requests to console
    if (import.meta.env.VITE_WEB_API_LOG_TO_CONSOLE === 'true') {
      setupApiLogging(this.apiInstance);
    }
  }

  get api() {
    return this.apiInstance;
  }

  updateAccessToken(token: string) {
    // Set the auth token for any request
    this.apiInstance.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      const hdrs = config.headers;
      hdrs.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  clearAccessToken() {
    const myInterceptor = this.apiInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => config
    );
    this.apiInstance.interceptors.request.eject(myInterceptor);
  }
}

// Single instance for all api calls
const apiClient = new ApiClient();
export default apiClient;
