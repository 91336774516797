import { useContext } from 'react';
import { RootContext } from '../../../../../stores/storeProvidor';
import { SelectionType } from '../../../../../models/selectionModels';
import SelectionActionBar from '../SelectionActionBar';
import SelectAccordion from '../SelectAccordion';
import { createStorageKey } from '../../../../../stores/selectionDragStore';
import DatatypeSelectList from './DatatypeSelectList';

interface DatatypeSelectionProps {
  containerId: string;
  isEmbedded: boolean; // If true selection is embedded in a report. containerId is then reportId
}

export default function DatatypeSelection({ containerId, isEmbedded }: DatatypeSelectionProps) {
  const { selectionStore } = useContext(RootContext);
  const dts = selectionStore.getAllDatatypes();
  const stateKey = createStorageKey(containerId, isEmbedded, SelectionType.Datatypes);

  return (
    <div className="select-none flex flex-row h-full w-full">
      <div className="grow">
        <SelectAccordion>
          <SelectAccordion.Panel statekey={stateKey} title="Base Datatypes" hint="" id="dts-base" position={0}>
            <DatatypeSelectList items={dts} />
          </SelectAccordion.Panel>
          <SelectAccordion.Panel statekey={stateKey} title="Custom Datatypes" hint="" id="dts-custom" position={1}>
            <DatatypeSelectList items={[]} />
          </SelectAccordion.Panel>
        </SelectAccordion>
      </div>
      <SelectionActionBar onAdd={undefined} onMoveUp={() => {}} onMoveDown={() => {}} onClearSelection={undefined} />
    </div>
  );
}
