import IconCross from '../../icons/cross';

interface DictBoxFrameProps {
  position: number;
  title: string;
  meta: string;
  children: React.ReactNode;
  onClose: () => void;
}

function DictBoxFrame({ position, title, meta, children, onClose }: DictBoxFrameProps) {
  const fstyle = 'bg-white border border-gray-400 text-sm text-gray-800 rounded-md select-none';
  const posStyle = {
    top: 4 + position * 30,
    left: 4 + position * 20,
    z: 10 + position,
  };

  return (
    <div className={`absolute ${fstyle} w-2/3 h-3/4 flex flex-col shadow-lg`} style={posStyle}>
      <div className="bg-gray-300 w-full rounded-t-md flex">
        <div className="grow ml-1 text-base font-semibold">{title}</div>
        <div className="grow text-xs pt-1">{meta}</div>
        <button type="button" onClick={() => onClose()}>
          <IconCross className="h-4 mr-1 opacity-50 hover:opacity-100" />
        </button>
      </div>
      {children}
    </div>
  );
}

export default DictBoxFrame;
