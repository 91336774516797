import { CheckIcon } from '@heroicons/react/24/outline';
import TagList from './TagList';
import { ListObjectWithTags } from '../../models/listbase';

interface SearchListItemProps {
  index: number;
  item: ListObjectWithTags;
  selected: boolean;
  onClickFn: (obj: ListObjectWithTags) => void;
}

export default function SearchListItem({ onClickFn, item, selected, index }: SearchListItemProps) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className="grid grid-cols-[2rem_1fr_auto] border-0 border-b border-gray-300 h-8 items-center hover:bg-gray-300"
      onClick={() => {
        onClickFn(item);
      }}
      onKeyDown={() => {
        onClickFn(item);
      }}
      tabIndex={index}
    >
      <span className="text-left pl-2">{selected ? <CheckIcon className="h-4 w-4" /> : ''}</span>
      <span className="text-left truncate">{item.name}</span>
      <span className="text-right pr-2 text-clip">
        <TagList tags={item.tags} moreLimit={2} />
      </span>
    </li>
  );
}
