import { useContext, useMemo } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import { RootContext } from '../../../../stores/storeProvidor';
import DrilldownSubReport from './DrilldownSubReport';
import ChartSubReport from './ChartSubReport';
import SpotListSubReport from './SpotListSubReport';
import { SubReportType } from './SubReportBase';
import { ShowProgressFromStatus } from '../../../../models/reportModel';
import ProgressOverlay from '../../../controls/ProgressOverlay';

type SubReportContainerProps = {
  reportId: string | undefined;
  subReportId: string;
};

const SubReportContainer = observer(({ reportId, subReportId }: SubReportContainerProps) => {
  const { activeReportStore } = useContext(RootContext);

  const activeSubReport = useMemo(
    () => (reportId ? activeReportStore.getSubReport(reportId, subReportId) : undefined),
    [activeReportStore, reportId, subReportId]
  );

  let showProgress = false;

  let subReportComponent = <div>{`Sub Report Type ${activeSubReport?.subReportType} Not Found`}</div>;
  if (activeSubReport) {
    switch (activeSubReport.subReportType) {
      case SubReportType.Drilldown: {
        const lr = activeReportStore.getLayoutResult(subReportId);
        showProgress = ShowProgressFromStatus(lr?.runStatus?.status);
        subReportComponent = <DrilldownSubReport subReport={showProgress ? undefined : activeSubReport} />;
        break;
      }
      case SubReportType.Chart:
        subReportComponent = <ChartSubReport subReport={showProgress ? undefined : activeSubReport} />;
        break;
      case SubReportType.SpotList:
        subReportComponent = <SpotListSubReport subReport={showProgress ? undefined : activeSubReport} />;
        break;
      default:
        break;
    }
  }

  return (
    <div className="relative h-full w-full bg-gray-500">
      <ProgressOverlay active={showProgress}>
        <div className="grid grid-flow-row auto-rows-max">
          <div className="h-8 mx-auto text-xl font-semibold">Processing Report</div>
          <ArrowPathIcon className="text-blue-400 animate-spin w-24 h-24 mx-auto" />
        </div>
      </ProgressOverlay>
      {subReportComponent}
    </div>
  );
});

export default SubReportContainer;
