import { useNavigate } from 'react-router-dom';

interface SideMenuLinkProps {
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  navTo?: string | undefined;
  navText: string;
  onClicked: () => void;
  disabled?: boolean | undefined;
  hide?: boolean | undefined;
  badge?: string | undefined;
  badgeColour?: string;
}

function SideMenuLink({ Icon, navTo, navText, onClicked, disabled, hide, badge, badgeColour }: SideMenuLinkProps) {
  const navigate = useNavigate();

  function gotoLink() {
    if (!disabled) {
      if (navTo !== undefined) navigate(navTo);
      onClicked();
    }
  }

  if (hide) {
    return null;
  }

  return (
    <span
      className={`flex items-center p-2 ${disabled ? 'text-gray-300' : 'text-gray-700 hover:bg-green-100'}`}
      onClick={gotoLink}
      onKeyDown={gotoLink}
      role="button"
      tabIndex={0}
    >
      <Icon className="h-8 w-8" />
      <span className="ml-3">{navText}</span>
      {badge ? (
        <span
          className={`inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold rounded-full ${badgeColour}`}
        >
          {badge}
        </span>
      ) : (
        <span />
      )}
    </span>
  );
}

SideMenuLink.defaultProps = {
  navTo: undefined,
  disabled: false,
  hide: false,
  badge: undefined,
  badgeColour: 'text-blue-800 bg-blue-200',
};

export default SideMenuLink;

// -----------------------------------------------------------------------

export function SideMenuSeparator() {
  return <span className="flex items-center border-b my-1" />;
}
