import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  CheckCircleIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import useTimeout from '../../../hooks/useTimeout';
import {
  FILTER_MODE_CONTAINS,
  FILTER_MODE_EXACT,
  FILTER_MODE_NOT,
  FILTER_MODE_STARTS,
  FILTER_ORDER_ID_ASC,
  FILTER_ORDER_ID_DESC,
  FILTER_ORDER_NAME_ASC,
  FILTER_ORDER_NAME_DESC,
  FILTER_ORDER_SELECTED_ASC,
  FILTER_SEPARATOR,
  getNextSortOrder,
} from '../../../utils/datasetFilter';

function DictBoxSearchBarOptionButton({
  children,
  active,
  onClick,
}: {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className={`grow ${active ? 'bg-blue-300' : 'bg-blue-100'} m-0.5 rounded-lg`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

// ----------------------------------------------------------

function DictBoxSearchBar({ onSearch }: { onSearch: (search: string) => void }) {
  const [negated, setNegated] = useState(false);
  const [mode, setMode] = useState(FILTER_MODE_STARTS);
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState(FILTER_ORDER_NAME_ASC);
  const [triggerSearchTimer] = useTimeout(() => {
    // This is the filter string that will be passed to the filterDatasetEntries() function
    onSearch(
      `${sortOrder}${FILTER_SEPARATOR}${
        negated ? FILTER_MODE_NOT : ''
      }${FILTER_SEPARATOR}${mode}${FILTER_SEPARATOR}${searchText}`
    );
  }, 500);

  return (
    <div className="bg-slate-300 w-full flex-col border-b border-t border-gray-400 overflow-clip">
      <div className="flex flex-nowrap">
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            triggerSearchTimer();
          }}
          className="grow h-6 border-1 border-gray-200 text-sm text-center"
        />
        <button type="button" onClick={() => triggerSearchTimer()}>
          <MagnifyingGlassIcon className="h-5 mr-1 bg-blue-200 rounded p-0.5 ml-0.5 opacity-50 hover:opacity-100" />
        </button>
        {searchText.length > 0 && (
          <button
            type="button"
            onClick={() => {
              setSearchText('');
              triggerSearchTimer();
            }}
          >
            <TrashIcon className="h-5 mr-1 bg-blue-200 rounded p-0.5 ml-0.5 opacity-50 hover:opacity-100" />
          </button>
        )}
      </div>
      <div className="flex flex-nowrap">
        <DictBoxSearchBarOptionButton
          active={negated}
          onClick={() => {
            setNegated(!negated);
            triggerSearchTimer();
          }}
        >
          Not
        </DictBoxSearchBarOptionButton>
        <DictBoxSearchBarOptionButton
          active={mode === FILTER_MODE_STARTS}
          onClick={() => {
            setMode(FILTER_MODE_STARTS);
            triggerSearchTimer();
          }}
        >
          Starts With
        </DictBoxSearchBarOptionButton>
        <DictBoxSearchBarOptionButton
          active={mode === FILTER_MODE_CONTAINS}
          onClick={() => {
            setMode(FILTER_MODE_CONTAINS);
            triggerSearchTimer();
          }}
        >
          Contains
        </DictBoxSearchBarOptionButton>
        <DictBoxSearchBarOptionButton
          active={mode === FILTER_MODE_EXACT}
          onClick={() => {
            setMode(FILTER_MODE_EXACT);
            triggerSearchTimer();
          }}
        >
          Exact
        </DictBoxSearchBarOptionButton>
        <DictBoxSearchBarOptionButton
          active
          onClick={() => {
            setSortOrder(getNextSortOrder(sortOrder));
            triggerSearchTimer();
          }}
        >
          {sortOrder === FILTER_ORDER_NAME_ASC && <span>A-&gt;Z</span>}
          {sortOrder === FILTER_ORDER_NAME_DESC && <span>Z-&gt;A</span>}
          {sortOrder === FILTER_ORDER_SELECTED_ASC && <CheckCircleIcon className="h-4 inline text-gray-600" />}
          {sortOrder === FILTER_ORDER_ID_ASC && <ArrowUpOnSquareIcon className="h-4 inline text-gray-600" />}
          {sortOrder === FILTER_ORDER_ID_DESC && <ArrowDownOnSquareIcon className="h-4 inline text-gray-600" />}
        </DictBoxSearchBarOptionButton>
      </div>
    </div>
  );
}

export default DictBoxSearchBar;
