import { useContext } from 'react';
import { DateTime, Info } from 'luxon';
import { RootContext } from '../stores/storeProvidor';
import { AppSettings } from '../models/settings';

type SettingsLookup = (name: keyof AppSettings) => string | undefined;

let cachedLocale = '';
let narrowMonthCache: string[] = [];
let shortMonthCache: string[] = [];
let longMonthCache: string[] = [];
let narrowDowCache: string[] = [];
let shortDowCache: string[] = [];
let longDowCache: string[] = [];

function setupNameCache(locale: string) {
  cachedLocale = locale;
  narrowMonthCache = Info.months('narrow', { locale });
  shortMonthCache = Info.months('short', { locale });
  longMonthCache = Info.months('long', { locale });
  narrowDowCache = Info.weekdays('narrow', { locale });
  shortDowCache = Info.weekdays('short', { locale });
  longDowCache = Info.weekdays('long', { locale });
}

const labelLookup = new Map<string, (value: string, settings: SettingsLookup, locale: string) => string>();

function setupLookup() {
  // For all tags used below, see QueryGenerator.SetupCustomScripts()
  const toNumber = (val: string) => `${Number(val)}`;

  labelLookup.set('qtr', (val: string) => `Q${Number(val)}`);

  labelLookup.set('moy', (val: string, settingFn: SettingsLookup) => {
    const fmt = settingFn('moyFormat') ?? 'short';
    if (fmt === 'narrow') return narrowMonthCache[Number(val) - 1];
    if (fmt === 'short') return shortMonthCache[Number(val) - 1];
    if (fmt === 'long') return longMonthCache[Number(val) - 1];
    return `${Number(val)}`;
  });

  labelLookup.set('dow', (val: string, settingFn: SettingsLookup) => {
    const fmt = settingFn('dowFormat') ?? 'short';
    if (fmt === 'narrow') return narrowDowCache[Number(val) - 1];
    if (fmt === 'short') return shortDowCache[Number(val) - 1];
    if (fmt === 'long') return longDowCache[Number(val) - 1];
    return `${Number(val)}`;
  });

  labelLookup.set('hod', (val: string, settingFn: SettingsLookup) => {
    // See formats at https://moment.github.io/luxon/#/formatting?id=table-of-tokens
    const fmt = settingFn('hodFormat') ?? 'HH:00';
    return DateTime.local(2017, 1, 1, Number(val), 0).toFormat(fmt);
  });

  labelLookup.set('mod', (val: string, settingFn: SettingsLookup) => {
    const h = Number(val.substring(0, 2));
    const m = Number(val.substring(2));
    const fmt = settingFn('modFormat') ?? 'HH:mm';
    if (fmt === 'number') return `${h * 60 + m}`;
    return DateTime.local(2017, 1, 1, h, m).toFormat(fmt);
  });

  labelLookup.set('woy', (val: string) => `W${Number(val)}`);

  labelLookup.set('doy', toNumber);
  labelLookup.set('wom', toNumber);
  labelLookup.set('dom', toNumber);
  labelLookup.set('n', toNumber);
  labelLookup.set('ayr', toNumber);

  labelLookup.set('aqtr', (val: string) => {
    const y = Number(val.substring(0, 4));
    const q = Number(val.substring(4));
    return `${y} Q${q}`;
  });

  labelLookup.set('amth', (val: string, settingFn: SettingsLookup, locale: string) => {
    const y = Number(val.substring(0, 4));
    const m = Number(val.substring(4));
    const fmt = settingFn('absMthFormat') ?? 'LLL yy';
    return DateTime.local(y, m, 1).setLocale(locale).toFormat(fmt);
  });

  labelLookup.set('awk', (val: string, settingFn: SettingsLookup, locale: string) => {
    const y = Number(val.substring(0, 4));
    const m = Number(val.substring(4, 6));
    const d = Number(val.substring(6));
    const fmt = settingFn('absWkFormat') ?? 'D'; // 'yyyy WW';
    return DateTime.local(y, m, d).setLocale(locale).toFormat(fmt);
  });

  labelLookup.set('aday', (val: string, settingFn: SettingsLookup, locale: string) => {
    const y = Number(val.substring(0, 4));
    const m = Number(val.substring(4, 6));
    const d = Number(val.substring(6));
    const fmt = settingFn('absDayFormat') ?? 'D';
    return DateTime.local(y, m, d).setLocale(locale).toFormat(fmt);
  });

  labelLookup.set('ahr', (val: string, settingFn: SettingsLookup, locale: string) => {
    const y = Number(val.substring(0, 4));
    const m = Number(val.substring(4, 6));
    const d = Number(val.substring(6, 8));
    const h = Number(val.substring(8));
    const fmt = settingFn('absHourFormat') ?? 'f';
    return DateTime.local(y, m, d, h, 0).setLocale(locale).toFormat(fmt);
  });

  labelLookup.set('amin', (val: string, settingFn: SettingsLookup, locale: string) => {
    const y = Number(val.substring(0, 4));
    const m = Number(val.substring(4, 6));
    const d = Number(val.substring(6, 8));
    const h = Number(val.substring(8, 10));
    const n = Number(val.substring(10));
    const fmt = settingFn('absMinFormat') ?? 'f';
    return DateTime.local(y, m, d, h, n).setLocale(locale).toFormat(fmt);
  });
}

export default function useHeaderExtract(reportId: string | undefined): (raw: string | undefined) => string {
  const { activeUserStore } = useContext(RootContext);
  const locale = activeUserStore.getLocale(reportId);
  const getSetting = (name: keyof AppSettings) => activeUserStore.getSetting(name, reportId);

  if (labelLookup.size === 0) setupLookup();
  if (shortMonthCache.length === 0 || cachedLocale !== locale) setupNameCache(locale);

  const extractHeaderLabel = (rawName: string | undefined): string => {
    const parts = (rawName ?? '').split('|');
    if (rawName === undefined || parts.length === 0) {
      return '...';
    }

    // If we have separator - return last bit (eg. a dataset item)
    if (parts.length === 2) return parts[parts.length - 1];

    // No separator - use function from labelLookup to format
    if (parts.length === 1) {
      // No separator - may be a generic date?
      const dateBits = rawName.split('_');
      if (dateBits.length === 2) {
        const fn = labelLookup.get(dateBits[0]);
        if (fn) return fn(dateBits[1], getSetting, locale) ?? '[Unknown]';
        return `${dateBits[0]}?`;
      }
    }
    return rawName;
  };

  return extractHeaderLabel;
}
