import IconCross from '../../icons/cross';

interface ReportTabButtonProps {
  id: string;
  label: string;
  clickFn: (id: string) => void;
  closeFn: (id: string) => void;
  active: boolean;
  disabled: boolean;
}

function ReportTabButton({ id, label, clickFn, closeFn, active, disabled }: ReportTabButtonProps) {
  let textColour = 'text-blue-800';
  if (disabled) {
    textColour = 'text-gray-500 bg-transparent cursor-not-allowed';
  } else if (!active) {
    textColour = 'text-gray-200 bg-transparent hover:text-gray-300 hover:bg-gray-500 hover:z-20';
  }

  const handleClick = () => {
    if (!active && !disabled) {
      clickFn(id);
    }
  };

  const handleClose = () => {
    if (!disabled) {
      closeFn(id);
    }
  };

  return (
    <li className={`-mr-1 bg-gray-100 rounded-b-lg active=${active} ${textColour}`}>
      <IconCross className="inline mx-1 items-center hover:animate-ping" onClick={handleClose} />
      <button type="button" className="inline-block py-2 pr-3" onClick={handleClick}>
        {label}
      </button>
    </li>
  );
}

export default ReportTabButton;
