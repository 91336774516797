import { Alert, Button, Label, Modal, Spinner, TextInput } from 'flowbite-react';
import { SyntheticEvent, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RootContext } from '../../stores/storeProvidor';

// If we want to use OnChange(), control has to be sub-component.
// see here: https://stackoverflow.com/questions/46777832/react-noob-onchange-element-loses-focus

interface Props {
  showLogin: boolean;
  onClose: VoidFunction;
}

function LoginDialog({ showLogin, onClose }: Props) {
  const navigate = useNavigate();
  const [loginPending, setLoginPending] = useState(false);
  const { authStore, uiState } = useContext(RootContext);

  async function onSubmit(event: SyntheticEvent) {
    // const api = new AuthApi();
    const target = event.target as typeof event.target & {
      email: { value: string };
      password: { value: string };
    };
    const email = target.email.value;
    const password = target.password.value;

    event.preventDefault(); // Prevents page refresh on form submit

    setLoginPending(true);

    authStore
      .login(email, password)
      .then(() => {
        // Successful login
        setLoginPending(false);
        onClose();
        navigate('/app');
        uiState.infoAlert = `Welcome to ${import.meta.env.VITE_APP_TITLE}`;
      })
      .catch(() => {
        // Login failed
        onClose();
        setLoginPending(false);
        uiState.errorAlert = 'Failed to login. Please check your credentials.';
      });
  }

  function doAllowedClose() {
    if (loginPending === false) onClose();
  }

  // Show main login dialog
  return (
    <Modal show={showLogin} onClose={doAllowedClose}>
      <form className="" onSubmit={onSubmit}>
        <Modal.Header>Login</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div>
              <Label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                Your email:
              </Label>
              <TextInput
                disabled={loginPending}
                type="email"
                name="email"
                id="email"
                className="focus:ring-primary focus:border-primary block w-full p-2.5"
                placeholder="name@company.com"
                required
              />
            </div>
            <div>
              <Label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                Your password:
              </Label>
              <TextInput
                disabled={loginPending}
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="focus:ring-primary focus:border-primary block w-full p-2.5"
                required
              />
            </div>
            <p className="text-xs text-gray-500">
              By logging in you agree to our cookie policy and{' '}
              <Link to="/tos" className="font-bold hover:underline">
                Terms of Service
              </Link>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loginPending} color="green" type="submit">
            {loginPending ? <Spinner /> : <span />}
            <span>&nbsp;Login</span>
          </Button>
          <Button disabled={loginPending} color="gray" onClick={doAllowedClose}>
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default LoginDialog;
