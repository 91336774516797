/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import router from './routes/routes';
import './i18n/config';

import './index.css';
import UseAuth from './auth/UseAuth';
import StoreProvider from './stores/storeProvidor';
import RootStore from './stores/rootStore';
import AlertWrapper from './Components/global/AlertWrapper';
import SignalRProvider from './notification/SignalProvider';
import Notifications from './notification/notifications';
import AutoSave from './stores/AutoSave';
import ContextMenuWrapper from './Components/global/ContextMenu';

const rootStore = new RootStore();

const element = (
  <React.StrictMode>
    <StoreProvider store={rootStore}>
      <SignalRProvider>
        <NiceModal.Provider>
          <UseAuth />
          <AlertWrapper />
          <Notifications />
          <ContextMenuWrapper />
          <RouterProvider router={router} />
          <AutoSave />
        </NiceModal.Provider>
      </SignalRProvider>
    </StoreProvider>
  </React.StrictMode>
);

const domContainer = document.getElementById('root');
const appRoot = createRoot(domContainer!);
appRoot.render(element);
