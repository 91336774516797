import { HTMLProps, useEffect, useRef } from 'react';

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      if (ref.current) ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={`${className} cursor-pointer text-primary border-gray-400 hover:border-blue-700`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

IndeterminateCheckbox.defaultProps = {
  indeterminate: false,
};

export default IndeterminateCheckbox;
