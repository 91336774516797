import { GlobeAsiaAustraliaIcon } from '@heroicons/react/24/outline';
import AdminGrid4x6, { AdminGrid4x6SingleColumn, AdminGrid4x6TripleColumn } from './AdminGrid';
import { useAdminTtile } from '../../hooks/useTitle';

function AdminDataSources() {
  useAdminTtile('Data Management', GlobeAsiaAustraliaIcon);

  return (
    <AdminGrid4x6 className="bg-blue-100">
      <AdminGrid4x6SingleColumn className="p-2">
        <h1>Data Management</h1>
      </AdminGrid4x6SingleColumn>
      <AdminGrid4x6TripleColumn className="text-justify border-x-2 border-gray-300 p-6">
        <p>List available data sources for user</p>
      </AdminGrid4x6TripleColumn>
    </AdminGrid4x6>
  );
}

export default AdminDataSources;
