class Perms {
  public static SuperAdmin = 'SuperAdmin';

  public static AdminOrgs = 'AdminOrgs';

  public static AdminRoles = 'AdminRoles';

  public static AdminUsers = 'AdminUsers';

  public static AdminUserData = 'AdminUserData';

  public static AdminDataSources = 'AdminDataSources';

  public static AccessReports = 'AccessReports';

  public static Selections = 'AccessSelections';

  public static Workspaces = 'AccessWorkspaces';

  public static MonitorStats = 'ViewSiteStats';

  public static ViewQaArea = 'ViewQaArea';

  public static EditGlobalSettings = 'EditGlobalSettings';

  public static EditOrgSettings = 'EditOrgSettings';

  public static get AllAdmin() {
    return [Perms.AdminOrgs, Perms.AdminRoles, Perms.AdminUsers, Perms.AdminUserData, Perms.AdminDataSources];
  }

  public static HasPermission(userPerms: string[], permsNeeded: string | string[]): boolean {
    const wantedPerms = typeof permsNeeded === 'string' ? [permsNeeded] : permsNeeded;
    let granted = userPerms.includes(Perms.SuperAdmin);

    for (let i = 0; !granted && i < wantedPerms.length; i += 1) {
      if (userPerms.includes(wantedPerms[i])) granted = true;
    }

    return granted;
  }
}

export default Perms;
