import { RelativePeriodUnits, getPeriodUnitName } from '../../../../../utils/relativeDateUtils';

interface PeriodUnitEntryProps {
  id: string;
  label: string;
  range: number[];
  selected: number | undefined;
  onChange: (value: number) => void;
}

export function PeriodUnitEntry({ id, label, range, selected, onChange }: PeriodUnitEntryProps) {
  return (
    <>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 mt-2">
        {label} {/* `(${range[0]} -> ${range[1]})` */}
      </label>
      <input
        type="number"
        id={id}
        name={label}
        value={selected || range[0]}
        min={range[0]}
        max={range[1]}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-blue-500 focus:border-blue-500"
        onChange={(e) => onChange(parseInt(e.currentTarget.value, 10))}
      />
    </>
  );
}

// -----------------------------------------------------------------------------------

interface PeriodCountEntryProps {
  id: string;
  units: RelativePeriodUnits;
  range: number[];
  selected: number | undefined;
  onChange: (value: number) => void;
}

export function PeriodCountEntry({ id, units, range, selected, onChange }: PeriodCountEntryProps) {
  const unitName = getPeriodUnitName(units, 2);
  const label = `${unitName} back`;

  const options = [];
  if (range[1] < 100) {
    for (let i = range[0]; i <= range[1]; i += 1) {
      options.push(`${i}:${i} ${getPeriodUnitName(units, i)}`);
    }
  }

  return (
    <>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 mt-2">
        {label} {/* `(${range[0]} -> ${range[1]})` */}
      </label>
      {options.length === 0 ? (
        <input
          type="number"
          id={id}
          name={label}
          value={selected || range[0]}
          min={range[0]}
          max={range[1]}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-blue-500 focus:border-blue-500"
          onChange={(e) => onChange(parseInt(e.currentTarget.value, 10))}
        />
      ) : (
        <select
          id={id}
          value={selected || range[0]}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-blue-500 focus:border-blue-500"
          onChange={(e) => onChange(parseInt(e.currentTarget.value, 10))}
        >
          {options.map((o) => {
            const [oid, otxt] = o.split(':');
            return (
              <option key={oid} value={oid}>
                {otxt}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
}

// -----------------------------------------------------------------------------------

interface PeriodDropdownProps {
  id: string;
  label: string;
  options: string[];
  selected: string | undefined;
  onChange: (value: string) => void;
}

export function PeriodDropdown({ id, label, options, selected, onChange }: PeriodDropdownProps) {
  return (
    <>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 mt-2">
        {label}
      </label>
      <select
        id={id}
        value={selected || options[0].split(':')[0]}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:ring-blue-500 focus:border-blue-500"
        onChange={(e) => onChange(e.currentTarget.value)}
      >
        {options.map((option) => {
          const [oid, otxt] = option.split(':');
          return (
            <option key={oid} value={oid}>
              {otxt}
            </option>
          );
        })}
      </select>
    </>
  );
}
