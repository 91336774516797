import { useContext, useEffect } from 'react';
import { RootContext } from '../stores/storeProvidor';
import { IconObject } from '../utils/types';

export function useAppTitle(title: string, icon: IconObject | undefined) {
  const { uiState } = useContext(RootContext);
  useEffect(() => {
    uiState.setAppTitle(title, icon);
  }, [icon, title, uiState]);
}

export function useAdminTtile(title: string, icon: IconObject | undefined) {
  const { uiState } = useContext(RootContext);
  useEffect(() => {
    uiState.setAdminTitle(title, icon);
  }, [icon, title, uiState]);
}
