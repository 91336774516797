import { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import ReportTabButton from './ReportTabButton';
import { RootContext } from '../../../stores/storeProvidor';

interface ReportTabsProps {
  className: string;
}

const ReportTabs = observer(({ className }: ReportTabsProps) => {
  const { activeReportStore, uiState } = useContext(RootContext);

  const handleClick = useCallback(
    (id: string) => {
      uiState.setActiveReport(id);
    },
    [uiState]
  );

  const handleClose = useCallback(
    (id: string) => {
      activeReportStore.closeActiveReport(id);
    },
    [activeReportStore]
  );

  const deferredId = uiState.getActiveReportId;

  return (
    <div className={className}>
      <ul className="flex flex-wrap text-sm font-medium text-center border-t border-gray-200">
        {activeReportStore.getOpenReportsInSortOrder.map((r) => (
          <ReportTabButton
            key={r?.id}
            id={r?.id ?? '<unknown>'}
            label={r?.name ?? '<unknown>'}
            clickFn={handleClick}
            closeFn={handleClose}
            active={deferredId === r?.id}
            disabled={false}
          />
        ))}
      </ul>
    </div>
  );
});

export default ReportTabs;
