import VizWrapper from '../../../visualisations/VizWrapper';
import { SubReportBaseProps } from './SubReportBase';

function ChartSubReport({ subReport }: SubReportBaseProps) {
  const vizData = subReport?.vizData;
  const vizOptions = subReport?.vizOptions;
  if (subReport === undefined || vizData === undefined || vizOptions === undefined)
    return <div className="h-full w-full p-1 flex flex-col gap-1">Pending...</div>;

  return <VizWrapper subReport={subReport} data={vizData} options={vizOptions} />;
}

export default ChartSubReport;
