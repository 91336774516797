import NiceModal from '@ebay/nice-modal-react';
import ConfirmModal from './ConfirmModal';

export default function showReportTooLargeModal(onYes: () => void) {
  NiceModal.show(ConfirmModal, {
    title: 'Large Report Warning',
    body: (
      <div>
        <p className="mb-2">
          This report is large, and <b>may take significant time to process</b>.
        </p>
        <p className="mb-2">
          If you would still like to attempt to run it, please click <b>Proceed Anyway</b> below.
        </p>
        <p className="mb-2">
          Note: it is usually better to reformat your layout and/or selections to only include items you need.
        </p>
        <p>
          Large data-dumps can be scheduled via the <b>Data</b> menu.
        </p>
      </div>
    ),
    onYes,
    onNo: () => {},
    yesButtonText: 'Proceed Anyway',
    noButtonText: 'Cancel Run',
  });
}
