import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { RootContext } from '../../stores/storeProvidor';
import SelectWithLabel from '../controls/form/SelectWithLabel';
import { useDateFormat } from '../../hooks/useFormat';
import { SchemaAccess } from '../../models/schemaModels';
import { getOrderedVersions } from '../../stores/selectionStore';

interface VersionDef {
  versionId: string;
  access: SchemaAccess;
  firstDate: DateTime;
  lastDate: DateTime;
  updated: DateTime;
  isLatest: boolean;
  note: string;
  tag: string;
}

// See SchemaRepository.SchemaVersionStatus = { "CREATING", "QA", "LIVE", "DELETED" };
function SchemaStatusTag({ status }: { status: string | undefined }) {
  let colour = 'text-gray-100 bg-gray-400';
  let msg = 'Unknown';
  const stat = status?.toUpperCase();
  if (stat === 'CREATING') {
    colour = 'text-orange-500 bg-red-200';
    msg = 'Creation in progress';
  }
  if (stat === 'QA') {
    colour = 'text-yellow-200 bg-orange-500';
    msg = 'Under Test';
  }
  if (stat === 'LIVE') {
    colour = 'text-blue-600 bg-teal-300';
    msg = 'Active';
  }
  if (stat === 'DELETED') msg = 'Deleted';

  return <span className={`italic font-bold px-2 rounded-lg ${colour}`}>{msg}</span>;
}

const DataSourceSelectModal = NiceModal.create(() => {
  const { selectionStore, activeUserStore, uiState } = useContext(RootContext);
  const modal = useModal();
  const { formatDate, formatDateTime } = useDateFormat();

  const canViewQaVersions = true; // TODO: activeUserStore.hasPermission('VIEW_QA_VERSIONS');

  const [selectedSchema, setSelectedSchema] = useState<string | undefined>(activeUserStore.activeSchemaId);
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>(activeUserStore.activeSchemaVersion);

  const onOk = () => {
    // TODO - update current schema/version
    uiState.warningAlert = `TODO: Data source change here to ${selectedSchema}.${selectedVersion}`;

    modal.remove();
  };

  const onCancel = () => {
    modal.remove();
  };

  const availableSources = useMemo(
    () => selectionStore.schema.map((s) => [s.schemaId, s.name]),
    [selectionStore.schema]
  );

  const activeSource = useMemo(() => {
    const sd = selectionStore.schema.filter((s) => s.schemaId === activeUserStore.activeSchemaId);
    return sd.length > 0 ? sd[0] : undefined;
  }, [selectionStore.schema, activeUserStore.activeSchemaId]);

  const availableVersions = useMemo(() => {
    if (activeSource === undefined) return [];
    const validVers = getOrderedVersions(activeSource.versions, canViewQaVersions);
    const vers = validVers.map((v, i) => {
      let tag = '(Active)';
      if (i === 0) tag += '(Latest)'; // First 'LIVE' version is always the latest
      else if (v.access === SchemaAccess.Qa) tag = '(QA)';
      return {
        versionId: v.versionId,
        access: v.access,
        firstDate: v.firstDate,
        lastDate: v.lastDate,
        updated: v.updated,
        note: v.note,
        isLatest: i === 0,
        tag,
      } as VersionDef;
    });
    return vers;
  }, [activeSource, canViewQaVersions]);

  const versionSelections = useMemo(
    () =>
      availableVersions.map((v) => [
        `${activeUserStore.activeSchemaId}-${v.versionId}`,
        `${v.versionId}${v.tag} - Last date: ${formatDate(v.lastDate, 's')} - Updated: ${formatDate(v.updated, 's')}`,
      ]),
    [availableVersions, activeUserStore.activeSchemaId, formatDate]
  );

  const [isVersionLatest, versionId, versionFrom, versionTo, updatedAt, versionStatus, versionNote] = useMemo(() => {
    if (activeSource === undefined || availableVersions.length < 1) return [false, '?', '?', '?'];
    const sd = availableVersions.filter(
      (v) =>
        v.versionId === activeUserStore.activeSchemaVersion ||
        (v.isLatest && activeUserStore.activeSchemaVersion === 'Latest')
    );
    if (sd.length < 1) return [false, '?', '?', '?'];
    const ver = sd[0];
    return [
      ver.isLatest,
      ver.versionId,
      formatDate(ver.firstDate, 's'),
      formatDate(ver.lastDate, 's'),
      formatDateTime(ver.updated, true),
      ver.access,
      ver.note,
    ];
  }, [availableVersions, activeSource, activeUserStore.activeSchemaVersion, formatDate, formatDateTime]);

  return (
    <Modal show onClose={onCancel}>
      <Modal.Header>Data Sources</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          {activeSource === undefined ? (
            <p>No selected data source...</p>
          ) : (
            <>
              <div>
                Selected Data: <b>{activeSource.name}</b>
              </div>
              <div className="table w-full bg-gray-100 p-2 border border-gray-300 rounded-xl shadow-lg">
                <div className="table-row">
                  <span className="table-cell w-1/3 text-right">Name:</span>
                  <span className="table-cell pl-4">{activeSource.name}</span>
                </div>
                <div className="table-row">
                  <span className="table-cell text-right">Description:</span>
                  <span className="table-cell pl-4">{activeSource.description}</span>
                </div>
                <div className="table-row">
                  <span className="table-cell text-right">Schema:</span>
                  <span className="table-cell pl-4">{activeSource.schemaId}</span>
                </div>
                <div className="table-row">
                  <span className="table-cell text-right">Active Version:</span>
                  <span className="table-cell pl-4">
                    {activeUserStore.activeSchemaId}.{versionId}
                    {isVersionLatest ? (
                      <span className="italic font-bold ml-2 px-2 bg-green-200 text-green-600 rounded-lg">Latest</span>
                    ) : (
                      <span className="italic font-bold ml-2 px-2 bg-red-500 text-gray-200 rounded-lg">
                        {activeUserStore.activeSchemaVersion}
                      </span>
                    )}
                  </span>
                </div>
                <div className="table-row">
                  <span className="table-cell text-right">Status:</span>
                  <span className="table-cell pl-4">
                    <SchemaStatusTag status={versionStatus} />
                  </span>
                </div>
                <div className="table-row">
                  <span className="table-cell text-right">Version Date Range:</span>
                  <span className="table-cell pl-4">
                    {versionFrom} to {versionTo}
                  </span>
                </div>
                <div className="table-row">
                  <span className="table-cell text-right">Version Note:</span>
                  <span className="table-cell pl-4">{versionNote}</span>
                </div>
                <div className="table-row">
                  <span className="table-cell text-right">Last Updated:</span>
                  <span className="table-cell pl-4">{updatedAt}</span>
                </div>
              </div>
            </>
          )}
          <SelectWithLabel
            id="datasource"
            label="Available Data Sources"
            selected={selectedSchema ?? '<None>'}
            data={availableSources}
            onChange={setSelectedSchema}
          />
          {(versionSelections?.length ?? 0) > 1 ? (
            <SelectWithLabel
              id="dataver"
              label="Available Versions"
              selected={selectedVersion ?? '<None>'}
              data={versionSelections ?? []}
              onChange={setSelectedVersion}
            />
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onOk} disabled={activeSource?.schemaId === selectedSchema}>
          Update
        </Button>
        <Button color="gray" onClick={onCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default DataSourceSelectModal;
