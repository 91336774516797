import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RootContext } from '../../../stores/storeProvidor';
import PageLevel from './PageLevel';
import { distinct } from '../../../utils/helpers';

interface ReportPageSelectProps {
  reportId: string;
  onPageChanged: (pageId: number) => void;
}

const ReportPageSelect = observer(({ reportId, onPageChanged }: ReportPageSelectProps) => {
  const { activeReportStore, selectionStore } = useContext(RootContext);
  const [currentLevels, setCurrentLevels] = useState<string[]>([]);

  // Use this to trigger memo below when results arrive
  const pageDims = activeReportStore.reportDimentions(reportId);

  const [levelIds, levelNames, namesPerLevel, entriesPerPage] = useMemo(() => {
    if (pageDims !== undefined) {
      const ids = activeReportStore.getResultPageLayoutLevels(reportId);
      const names = ids.map((_v, i) => distinct(pageDims.map((d) => d.levels[i])));
      const byPage: string[][] = pageDims.map((d) => d.levels);
      const idNames = selectionStore.getNamesForBreakoutIds(ids);

      return [ids, idNames, names, byPage];
    }
    return [undefined, undefined, undefined, undefined];
  }, [reportId, activeReportStore, pageDims, selectionStore]);

  const setPage = useCallback(
    (pageId: number | undefined) => {
      const currentPage = activeReportStore.getCurrentPage(reportId)[0];
      if (entriesPerPage !== undefined && entriesPerPage.length > 0) {
        const activeLevels = entriesPerPage[pageId ?? currentPage].map((x) => x);
        setCurrentLevels(activeLevels);

        const p = pageId ?? currentPage;
        if (p !== currentPage) onPageChanged(p);
      }
    },
    [reportId, activeReportStore, entriesPerPage, onPageChanged]
  );

  // Set initial page on tab entry
  useEffect(() => {
    if (reportId !== undefined) setPage(undefined); // , undefined);
  }, [setPage, reportId]);

  const onLevelChanged = (requestedLevelPos: number, levelEntry: string) => {
    if (levelIds === undefined || entriesPerPage === undefined) return;
    const levelPos = requestedLevelPos;

    // Find the 'best' page across all levels while trying to preserve
    // the other selected levels; Note we match excluding any datatypes...

    // Get array of levels we want; ie current levels plus our new level
    const wantedLevels = currentLevels.map((x) => x);
    wantedLevels[levelPos] = levelEntry;

    // Create ordered array of levels to check. Changed level is first, then order by level id
    let checkLevels = Array.from(Array(levelIds.length).keys());
    delete checkLevels[levelPos];
    checkLevels.push(levelPos);
    checkLevels = checkLevels.reverse();

    let pageCombo: string[] | undefined;
    let possiblePages: string[][] = [];
    while (checkLevels.length > 0 && pageCombo === undefined) {
      // Try to match against all possible pages
      possiblePages = entriesPerPage.filter((e) => {
        let match = true;
        checkLevels.forEach((lvl) => {
          if (e[lvl] !== wantedLevels[lvl]) match = false;
        });
        return match;
      });

      // Got a match?
      if (possiblePages.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        pageCombo = possiblePages[0];
      } else {
        // No match - loosen up the search
        checkLevels.pop();
      }
    }

    if (pageCombo !== undefined) {
      const pageId = entriesPerPage.indexOf(pageCombo);
      setPage(pageId);
    } else {
      // Page not found. Why?
      setPage(0);
      // eslint-disable-next-line no-console
      console.log(`Page Not found for: ${wantedLevels}`);
    }
  };

  if (levelIds === undefined || levelIds.length < 1) {
    return null;
  }

  return (
    <div className="space-y-1 py-2">
      {levelIds
        .map((id, levelPos) => (
          <div key={id}>
            <div className="text-xs">{levelNames[levelPos]}:</div>
            <PageLevel
              levelName={id}
              selectedLevel={currentLevels[levelPos]}
              levels={namesPerLevel[levelPos]}
              onLevelChanged={(lvl) => onLevelChanged(levelPos, lvl)}
            />
          </div>
        ))
        .reverse()}
    </div>
  );
});

export default ReportPageSelect;
