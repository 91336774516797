import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import _ from 'lodash';
import { FilterLevel } from '../../../../../models/schemaModels';
import { SelectionNode, SelectionNodeType } from '../../../../../models/selectionModels';
import { RootContext } from '../../../../../stores/storeProvidor';
import SelectAccordion from '../SelectAccordion';
import SelectLevelList from './SelectLevelList';
import SelectLevelSearch from './SelectLevelSearch';
import { baseIdToSourceGroupId, newLevelFilterNode, newNode } from '../../../../../utils/selectionTreeUtils';
import { SelectionTreeItemDragData } from '../../../../../hooks/useSelectionDragging';
import { CreateLevelKey } from '../../../../../stores/selectionDragStore';
import SelectAcordianDragHandle from '../SelectAcordianDragHandle';

interface SelectionLevelPanelProps {
  level: FilterLevel;
  position: number;
  stateKey: string;
}

const SelectionLevelPanel = observer(({ level, position, stateKey }: SelectionLevelPanelProps) => {
  const { selectionStore, selectionDragStore, activeUserStore } = useContext(RootContext);
  const levelKey = CreateLevelKey(stateKey, level.id);

  const searchText = selectionStore.getSearchText(levelKey);
  const updateSearch = (txt: string) => {
    selectionStore.setSearchText(levelKey, txt);
  };

  const showIds = activeUserStore.getSetting('showSelectionIds') === 'true';

  useEffect(() => {
    // Async call to load at start and on search change
    selectionStore.refreshLevelItems(level.id, searchText);
  }, [selectionStore, level.id, searchText]);

  // Get items to display - available once promise above resolves
  const levelItems = selectionStore.getLevelItems(level.id, searchText);
  const selections = selectionDragStore.getLevelSelection(levelKey);
  const hasFilter = searchText.length > 0;

  let hint;
  const totalCount = activeUserStore.formatNumber(levelItems.totalCount, 0);
  if (levelItems.items.length > 0 && selections.size > 0) {
    let selectedItemCount = selections.size;
    if (hasFilter) {
      selectedItemCount = levelItems.items.filter((item) => selections.has(item.split('|')[1])).length;
    }
    hint = hasFilter
      ? `${selections.size} selections filtered to ${selectedItemCount} of ${levelItems.items.length}`
      : `${selectedItemCount} of ${totalCount}`;
  } else {
    hint = `${totalCount} entries`;
  }

  const toggleSelection = () => {
    if (!hasFilter) return; // Only toggle if there is a filter

    const filteredIds = levelItems.items.map((item) => item.split('|')[1]);
    const filteredSelected = _.intersection(filteredIds, Array.from(selections.keys()));

    if (filteredSelected.length > 0) {
      selectionDragStore.updateLevelMultiSelection(levelKey, levelItems.items, false);
    } else {
      selectionDragStore.updateLevelMultiSelection(levelKey, levelItems.items, true);
    }
  };

  const createNodeOnDrop: () => SelectionNode = () => newLevelFilterNode(level.id, level.name, selections);

  const dragHandle = (
    <SelectAcordianDragHandle
      id={baseIdToSourceGroupId(level.id)}
      dragData={
        {
          parentNode: undefined,
          thisNode: newNode(SelectionNodeType.LevelFilter, level.name, level.id), // A temp placeholder while we drag
          createNodeFn: createNodeOnDrop,
        } as SelectionTreeItemDragData
      }
    />
  );

  return (
    <SelectAccordion.Panel
      key={level.id}
      statekey={stateKey}
      title={level.name}
      hint={hint}
      id={level.id}
      position={position}
      dragHandle={dragHandle}
    >
      <div className="h-full flex flex-col">
        <SelectLevelSearch
          levelName={level.name}
          searchText={searchText}
          setsearchText={updateSearch}
          toggleSelect={toggleSelection}
        />
        <SelectLevelList
          levelItems={levelItems.items}
          onChange={(id, name, flag) => selectionDragStore.updateLevelSelection(levelKey, id, name, flag)}
          selectedItems={selections}
          showIds={showIds}
        />
      </div>
    </SelectAccordion.Panel>
  );
});

export default SelectionLevelPanel;
