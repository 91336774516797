import { ArrowDownOnSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';

// ---------------------------------------------------------------------------------

interface SelectLevelButtonProps {
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
  disabled: boolean;
}

function SelectLevelButton({ Icon, onClick, disabled }: SelectLevelButtonProps) {
  return (
    <button type="button" disabled={disabled} className={`w-8 ${disabled ? 'text-gray-300' : ''}`} onClick={onClick}>
      <Icon className="h-6 w-6" />
    </button>
  );
}

// ---------------------------------------------------------------------------------

interface SelectLevelSearchProps {
  levelName: string;
  searchText: string;
  setsearchText: (text: string) => void;
  toggleSelect: () => void;
}

export default function SelectLevelSearch({
  levelName,
  searchText,
  setsearchText,
  toggleSelect,
}: SelectLevelSearchProps) {
  return (
    <div className="flex w-full border-b border-gray-200">
      <input
        type="text"
        value={searchText}
        className="grow text-sm bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-primary"
        placeholder={`Search ${levelName} Level...`}
        onChange={(e) => {
          setsearchText(e.target.value);
        }}
      />
      <SelectLevelButton Icon={ArrowDownOnSquareIcon} onClick={toggleSelect} disabled={searchText.length === 0} />
      <SelectLevelButton Icon={XCircleIcon} onClick={() => setsearchText('')} disabled={searchText.length === 0} />
    </div>
  );
}
