import { BumpSerie, ResponsiveBump } from '@nivo/bump';

// See https://nivo.rocks/bump/

type DumbbellProps = {
  data: { name: string; value1: number; value2: number }[];
};

const bumpData = [
  {
    id: 'Radio',
    data: [
      {
        x: '2000',
        y: 10,
      },
      {
        x: '2001',
        y: 4,
      },
      {
        x: '2002',
        y: 11,
      },
      {
        x: '2003',
        y: 6,
      },
      {
        x: '2004',
        y: 1,
      },
    ],
  },
  {
    id: 'Cinema',
    data: [
      {
        x: '2000',
        y: 7,
      },
      {
        x: '2001',
        y: 3,
      },
      {
        x: '2002',
        y: 9,
      },
      {
        x: '2003',
        y: 2,
      },
      {
        x: '2004',
        y: 8,
      },
    ],
  },
  {
    id: 'Network 7',
    data: [
      {
        x: '2000',
        y: 6,
      },
      {
        x: '2001',
        y: 2,
      },
      {
        x: '2002',
        y: 8,
      },
      {
        x: '2003',
        y: 7,
      },
      {
        x: '2004',
        y: 10,
      },
    ],
  },
  {
    id: 'Regional TV',
    data: [
      {
        x: '2000',
        y: 11,
      },
      {
        x: '2001',
        y: 6,
      },
      {
        x: '2002',
        y: 1,
      },
      {
        x: '2003',
        y: 1,
      },
      {
        x: '2004',
        y: 11,
      },
    ],
  },
  {
    id: 'Nine',
    data: [
      {
        x: '2000',
        y: 4,
      },
      {
        x: '2001',
        y: 9,
      },
      {
        x: '2002',
        y: 4,
      },
      {
        x: '2003',
        y: 3,
      },
      {
        x: '2004',
        y: 3,
      },
    ],
  },
  {
    id: 'TEN',
    data: [
      {
        x: '2000',
        y: 9,
      },
      {
        x: '2001',
        y: 7,
      },
      {
        x: '2002',
        y: 12,
      },
      {
        x: '2003',
        y: 11,
      },
      {
        x: '2004',
        y: 4,
      },
    ],
  },
  {
    id: 'ABC',
    data: [
      {
        x: '2000',
        y: 1,
      },
      {
        x: '2001',
        y: 5,
      },
      {
        x: '2002',
        y: 10,
      },
      {
        x: '2003',
        y: 5,
      },
      {
        x: '2004',
        y: 7,
      },
    ],
  },
  {
    id: 'SBS',
    data: [
      {
        x: '2000',
        y: 8,
      },
      {
        x: '2001',
        y: 10,
      },
      {
        x: '2002',
        y: 6,
      },
      {
        x: '2003',
        y: 8,
      },
      {
        x: '2004',
        y: 6,
      },
    ],
  },
  {
    id: 'Newspapers',
    data: [
      {
        x: '2000',
        y: 2,
      },
      {
        x: '2001',
        y: 12,
      },
      {
        x: '2002',
        y: 5,
      },
      {
        x: '2003',
        y: 9,
      },
      {
        x: '2004',
        y: 9,
      },
    ],
  },
  {
    id: 'Magazines',
    data: [
      {
        x: '2000',
        y: 5,
      },
      {
        x: '2001',
        y: 11,
      },
      {
        x: '2002',
        y: 3,
      },
      {
        x: '2003',
        y: 12,
      },
      {
        x: '2004',
        y: 5,
      },
    ],
  },
  {
    id: 'Fox',
    data: [
      {
        x: '2000',
        y: 3,
      },
      {
        x: '2001',
        y: 8,
      },
      {
        x: '2002',
        y: 2,
      },
      {
        x: '2003',
        y: 4,
      },
      {
        x: '2004',
        y: 2,
      },
    ],
  },
  {
    id: 'Netflix',
    data: [
      {
        x: '2000',
        y: 12,
      },
      {
        x: '2001',
        y: 1,
      },
      {
        x: '2002',
        y: 7,
      },
      {
        x: '2003',
        y: 10,
      },
      {
        x: '2004',
        y: 12,
      },
    ],
  },
];

// TODO: Why does this not work now? See https://nivo.rocks/bump/ for example

function BumpPlot({ data }: DumbbellProps) {
  return (
    <ResponsiveBump
      data={bumpData}
      colors={{ scheme: 'spectral' }}
      lineWidth={3}
      activeLineWidth={6}
      inactiveLineWidth={3}
      inactiveOpacity={0.15}
      pointSize={10}
      activePointSize={16}
      inactivePointSize={0}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={3}
      activePointBorderWidth={3}
      pointBorderColor={{ from: 'serie.color' }}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -36,
        truncateTickAt: 0,
      }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Weeks',
        legendPosition: 'middle',
        legendOffset: 32,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'ranking',
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0,
      }}
      margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
      axisRight={null}
      useMesh={false} // Add this
      interpolation="smooth" // Add this
      xPadding={0.3} // Add this
      xOuterPadding={0.2} // Add this
      yOuterPadding={0.2} // Add this
      theme={{}} // Add this, replace with your theme object
      opacity={1} // Add this
      activeOpacity={1} // Add this
      startLabel={false} // Add this
      startLabelPadding={0} // Add this
      startLabelTextColor="red" // Add this
      endLabel={false} // Add this
      endLabelPadding={0}
      endLabelTextColor="blue"
      inactivePointBorderWidth={3}
      enableGridX={false}
      enableGridY={false}
      isInteractive={false}
      defaultActiveSerieIds={[
        'Radio',
        'Cinema',
        'Network 7',
        'Regional TV',
        'Nine',
        'TEN',
        'ABC',
        'SBS',
        'Newspapers',
        'Magazines',
        'Fox',
        'Netflix',
      ]}
      lineTooltip={(l) => null}
      pointTooltip={(p) => null}
      role="presentation"
      layers={[]}
      renderWrapper
      debugMesh={false}
    />
  );
}

export default BumpPlot;
