import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { BreakoutItem } from './BreakoutItem';
import { Sortable } from '../../../controls/dnd';
import { BreakoutObj, BreakoutType } from '../../../../models/breakoutModels';
import { baseId } from '../../../../utils/dragUtils';

interface AvailableBreakoutListProps {
  collectionId: string;
  className: string;
  availBreakouts: BreakoutObj[];
  active: BreakoutObj | undefined;
  isIdUsed: ((id: string) => boolean) | undefined;
}

export default function AvailableBreakoutList({
  collectionId,
  className,
  availBreakouts,
  active,
  isIdUsed,
}: AvailableBreakoutListProps) {
  const breakoutTypes = new Set(availBreakouts.map((o) => o.type).filter((t) => t !== BreakoutType.UnknownType));
  return (
    <SortableContext id={collectionId} items={availBreakouts.map((x) => x.id)} strategy={rectSortingStrategy}>
      <div className={`${className} select-none overflow-y-scroll`}>
        <div className="mt-2 text-lg font-bold text-center text-slate-500">Available Breakouts</div>
        <div className="flex flex-col">
          {Array.from(breakoutTypes).map((groupType) => (
            <div key={groupType}>
              <h4 className="font-bold text-center my-2 bg-gray-200 italic">
                {availBreakouts.find((b) => b.type === groupType)?.groupName ?? '??'}
              </h4>
              {availBreakouts
                .filter((o) => o.type === groupType)
                .map((boObj) => (
                  <Sortable
                    key={boObj.id}
                    id={boObj.id}
                    useOverlay={false}
                    renderItem={(ref, children, style, listeners, attributes) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <div ref={ref} style={style} {...listeners} {...attributes}>
                        {children}
                      </div>
                    )}
                  >
                    <BreakoutItem
                      obj={boObj}
                      isActive={active ? baseId(boObj) === baseId(active) : false}
                      isSelected={isIdUsed ? isIdUsed(boObj.id) : false}
                    />
                  </Sortable>
                ))}
            </div>
          ))}
        </div>
      </div>
    </SortableContext>
  );
}
