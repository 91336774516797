import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { TopMenuMessage } from './TopMenu';
import { RootContext } from '../../stores/storeProvidor';

interface TopMenuAutoProps {
  clickFn: () => void;
  isApp: boolean;
}

const TopMenuMessageAuto = observer(({ clickFn, isApp }: TopMenuAutoProps) => {
  const { uiState } = useContext(RootContext);
  return (
    <TopMenuMessage clickFn={clickFn} text={uiState.getTopMenuTitle(isApp)} Icon={uiState.getTopMenuIcon(isApp)} />
  );
});

export default TopMenuMessageAuto;
