import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { RootContext } from '../../stores/storeProvidor';
import AlertMsg from './AlertMsg';

const AlertWrapper = observer(() => {
  const { uiState } = useContext(RootContext);
  const { alerts } = uiState;

  if (alerts.length === 0) return null;
  return (
    <div className="fixed right-5 bottom-5 flex flex-col-reverse items-center w-full max-w-xs p-2 bg-transparent rounded-lg gap-2 z-30">
      {alerts.map((a) => (
        <AlertMsg
          key={a.key}
          id={a.key}
          ttl={10000}
          msg={a.msg}
          title={a.title}
          type={a.type}
          closeFn={(id: string) => {
            uiState.closeAlert(id);
          }}
        />
      ))}
    </div>
  );
});

export default AlertWrapper;
