/*
 * Generic method to create a 'tree' from a header definition (array-of-arrays)
 * Each array entry is a header entry. The sub-array is the names of each header
 * layer. For layers index 0 is the innermost (ie a leaf-node).
 * Assumption here is that the header data is given in display order. We should not
 * need to search the upper layers for matches. This should have been done already
 * when before we get the data here. This should speed this up a lot as we don't
 * need to search here
 */

export default function populateTreeForHeader<T>(
  headerAsArray: string[][],
  getDisplayNameFn: (rawName: string) => string,
  getNameForNodeFn: (node: T) => string,
  addNodeFn: (index: number, layer: number, name: string, isLeaf: boolean, parent: T | undefined) => T
) {
  const rootNodes: T[] = [];
  const layerCount = headerAsArray[0].length; // ALL entries are expected to have same layer count!
  const layerLookup = new Array<T | undefined>(layerCount);

  for (let pos = 0; pos < headerAsArray.length; pos += 1) {
    const entry = headerAsArray[pos];

    // Go through layers - outermost first
    for (let layerIndex = layerCount - 1; layerIndex >= 0; layerIndex -= 1) {
      const name = getDisplayNameFn(entry[layerIndex]);
      const nodeForLayer = layerLookup[layerIndex];
      if (nodeForLayer === undefined || getNameForNodeFn(nodeForLayer) !== name) {
        // No match or doesnt exist - clear this and all lower layers
        for (let n = layerIndex; n >= 0; n -= 1) layerLookup[n] = undefined;
        // Add new node
        const newNode = addNodeFn(pos, layerIndex, name, layerIndex === 0, layerLookup[layerIndex + 1]);
        layerLookup[layerIndex] = newNode;
        // Save new root nodes only
        if (layerIndex === layerCount - 1) rootNodes.push(newNode);
      }
      // else Matched node - nothing to create
    }
  }

  return rootNodes;
}

export function generateHeaderSpans(headerAsArray: string[][]): number[][] {
  if (headerAsArray.length === 0) return []; // Empty header

  const width = headerAsArray[0].length;
  if (width <= 1) return []; // No spans needed for single column

  const spans = new Array<number[]>(headerAsArray.length);
  const lastSpan = new Array<number>(width - 1).fill(1);

  spans[headerAsArray.length - 1] = [...lastSpan]; // Last row always has 1 span
  if (headerAsArray[headerAsArray.length - 1].length !== width)
    throw new Error(`Header row length mismatch at position ${headerAsArray.length - 1}`);

  // Check rows in reverse order
  for (let i = headerAsArray.length - 2; i >= 0; i -= 1) {
    if (headerAsArray[i].length !== width) throw new Error(`Header row length mismatch at position ${i}`);
    const thisRow = headerAsArray[i];
    const followingRow = headerAsArray[i + 1];
    for (let n = width - 1; n > 0; n -= 1) {
      const s = thisRow[n] === followingRow[n] ? lastSpan[n - 1] + 1 : 1;
      lastSpan[n - 1] = s;
      if (s === 1) {
        // Reset all lower spans
        for (let m = n - 1; m >= 0; m -= 1) lastSpan[m] = 1;
        break;
      }
    }
    spans[i] = [...lastSpan];
  }

  return spans;
}

/*
 Given the header spans, return the rows that are in the same group as the specified row.
 The 'depth' of the header must be given too.
 */
export function getRowsInHeaderGroup(
  rowHeaderSpans: number[][] | undefined,
  rowindex: number,
  hdrDepth: number
): number[] {
  if (rowHeaderSpans === undefined || rowHeaderSpans.length < 1 || hdrDepth < 0) return [];
  if (rowindex < 0 || rowindex >= rowHeaderSpans.length) return [];
  if (hdrDepth >= rowHeaderSpans[0].length || hdrDepth < 0) return [];

  const result: number[] = [rowindex];

  // Test forwards
  for (let i = rowindex + 1; i < rowHeaderSpans.length; i += 1) {
    const prev = rowHeaderSpans[i - 1][hdrDepth];
    const cur = rowHeaderSpans[i][hdrDepth];
    if (prev - 1 === cur) result.push(i);
    else break;
  }

  // Test backwards
  if (rowindex > 0) {
    for (let i = rowindex - 1; i >= 0; i -= 1) {
      const next = rowHeaderSpans[i + 1][hdrDepth];
      const cur = rowHeaderSpans[i][hdrDepth];
      if (next + 1 === cur) result.push(i);
      else break;
    }
  }

  return result;
}
