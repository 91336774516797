import { AppSettings, EmptySettings } from '../models/settings';
import apiClient from './apiConfig';
import CancelApi from './apiUtils';

class SettingsAPI {
  private canceller = new CancelApi();

  // Get all settings layers
  async getSettings(cancelExisting = false): Promise<AppSettings[]> {
    const response = await apiClient.api.get<AppSettings[]>('settings', {
      signal: cancelExisting ? this.canceller.getSignal('settings') : undefined,
    });
    // NOTE: insert a blank object at the start for the report level; only user/org/global levels are returned from API
    return [EmptySettings, ...response.data];
  }

  // eslint-disable-next-line class-methods-use-this
  async saveSettings(level: number, settings: AppSettings) {
    if (level === 0) throw new Error('Cannot save to report level');
    // NOTE: -1 on level when saving to API (level 0 is report level, and is save with report, not directly to db)
    const response = await apiClient.api.put<AppSettings>(`settings`, { level: level - 1, layer: settings });
    return { status: response.data };
  }

  // Admin use only - allow global/org settings to be individually updated
  async getRawSettings(id: string, name: keyof AppSettings, cancelExisting = false): Promise<string | undefined> {
    const response = await apiClient.api.get<string | undefined>('/settings/raw', {
      params: {
        settingId: id,
        settingName: name,
      },
      signal: cancelExisting ? this.canceller.getSignal('getrawset') : undefined,
    });
    return response.data === '' ? undefined : response.data;
  }

  // eslint-disable-next-line class-methods-use-this
  async saveRawSettings(
    id: string,
    name: keyof AppSettings,
    value: string | undefined,
    cancelExisting = false
  ): Promise<void> {
    const obj = { settingId: id, settingName: name, value };
    await apiClient.api.put<void>('/settings/raw', obj, {
      signal: cancelExisting ? this.canceller.getSignal(`saverawset`) : undefined,
    });
  }
}

export default SettingsAPI;
