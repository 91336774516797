import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useState } from 'react';
import _ from 'lodash';
import { RootContext } from '../../stores/storeProvidor';
import { AppSettings, SettingsLevel, EmptySettings, settingsAreSame } from '../../models/settings';
import Perms from '../../permissions/permissions';
import SettingsLevelTabs from '../controls/settings/SettingsLevelTabs';
import SettingsBottomTabs, { SettingsBottomTab } from '../controls/settings/SettingsBottomTabs';
import { SettingsGeneralPage, SettingsNumberPage, SettingsPeriodPage } from '../controls/settings/SettingsPages';
import { enumFromString } from '../../utils/helpers';

export type SettingsModalProps = {
  reportId: string | undefined;
};

const SettingsModal = NiceModal.create(({ reportId }: SettingsModalProps) => {
  const { uiState, activeUserStore } = useContext(RootContext);
  const modal = useModal();
  const user = activeUserStore.getUser;
  const baselineLevels = activeUserStore.getSettingLevels(reportId);

  const pages = [
    {
      label: 'General',
      value: 'gen',
    },
    {
      label: 'Period Formats',
      value: 'dates',
    },
    {
      label: 'Number Formats',
      value: 'num',
    },
  ] as SettingsBottomTab[];

  const [activePageIndex, setActivePageIndex] = useState<number>(0);
  const activePage = pages[activePageIndex].value;

  // Local copy of settings as we edit
  const [activeLevelTab, setActiveLevelTab] = useState<SettingsLevel>(
    reportId === undefined ? SettingsLevel.UserLevel : SettingsLevel.ReportLevel
  );
  const [workingLevels, setWorkingLevels] = useState<AppSettings[]>(_.cloneDeep(baselineLevels));
  const [actionsOnConfirm, setActionsOnConfirm] = useState<string[]>([]); // Level|Action-to-apply

  // Changed from baseline?
  let isModified = false;
  for (let i = 0; i < baselineLevels.length && !isModified; i += 1) {
    if (reportId !== undefined || i !== SettingsLevel.ReportLevel)
      if (!settingsAreSame(baselineLevels[i], workingLevels[i])) isModified = true;
  }
  // Also if any pending actions
  if (actionsOnConfirm.length > 0) isModified = true;

  // Check access levels for current user
  const lockedLevels: SettingsLevel[] = [];
  if (!activeUserStore.hasPermission(Perms.EditOrgSettings)) lockedLevels.push(SettingsLevel.OrgLevel);
  if (!activeUserStore.hasPermission(Perms.EditGlobalSettings)) lockedLevels.push(SettingsLevel.GlobalLevel);

  const onOk = () => {
    // Apply any stored actions on confirm (user level only)
    actionsOnConfirm.forEach((action) => {
      const [levelStr, actionStr] = action.split('|');
      const level = enumFromString(SettingsLevel, levelStr, SettingsLevel.UserLevel);

      if (actionStr === 'clearhide') {
        workingLevels[level] = { ...workingLevels[level], hideConfirmDialogs: null };
      }
      // Any other actions here...
    });

    const modifedLevels: number[] = [];
    for (let i = 0; i < baselineLevels.length; i += 1) {
      if (!lockedLevels.includes(i) && !settingsAreSame(baselineLevels[i], workingLevels[i])) modifedLevels.push(i);
    }

    activeUserStore.updateSettingLevels(workingLevels, modifedLevels, reportId).then(
      () => {
        if (reportId === undefined) uiState.successAlert = 'Settings updated'; // if we have a report alert is shown on report save
        modal.remove();
      },
      () => {
        uiState.errorAlert = 'Failed to save settings. Please try again when you are online.';
      }
    );
  };

  const onCancel = () => {
    modal.remove();
  };

  const updateWorkingLevel = (newLevelOrAction: AppSettings | string) => {
    if (lockedLevels.includes(activeLevelTab)) {
      uiState.warningAlert = 'You do not have permission to edit this level of settings';
      return;
    }

    if (typeof newLevelOrAction === 'string') {
      // Toggle action
      if (actionsOnConfirm.includes(newLevelOrAction)) {
        setActionsOnConfirm(actionsOnConfirm.filter((a) => a !== newLevelOrAction));
      } else {
        setActionsOnConfirm([...actionsOnConfirm, newLevelOrAction]);
      }
    } else {
      const newLevels = [...workingLevels];
      newLevels[activeLevelTab] = newLevelOrAction;
      setWorkingLevels(newLevels);
    }
  };

  const clearActiveLevel = () => {
    if (lockedLevels.includes(activeLevelTab)) return;
    updateWorkingLevel(EmptySettings);
  };

  return (
    <Modal show onClose={onCancel} size="7xl">
      <div className="p-4 text-slate-600 text-2xl font-bold">
        Update Settings
        {!lockedLevels.includes(activeLevelTab) && activeLevelTab !== SettingsLevel.GlobalLevel && (
          <button
            type="button"
            className="float-right py-2 px-4 text-sm text-gray-100 bg-blue-700 rounded-md"
            onClick={clearActiveLevel}
          >
            Reset All
          </button>
        )}
      </div>
      <SettingsLevelTabs
        showReportTab={reportId !== undefined}
        activeTab={activeLevelTab}
        setActiveTab={setActiveLevelTab}
        orgName={user?.orgName}
        lockedLevels={lockedLevels}
      />
      <Modal.Body>
        {activePage === 'gen' && (
          <SettingsGeneralPage levels={workingLevels} activeLevel={activeLevelTab} updateLevel={updateWorkingLevel} />
        )}
        {activePage === 'dates' && (
          <SettingsPeriodPage levels={workingLevels} activeLevel={activeLevelTab} updateLevel={updateWorkingLevel} />
        )}
        {activePage === 'num' && (
          <SettingsNumberPage levels={workingLevels} activeLevel={activeLevelTab} updateLevel={updateWorkingLevel} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <SettingsBottomTabs tabs={pages} activeTabIndex={activePageIndex} onTabChange={(n) => setActivePageIndex(n)} />
        <Button onClick={onOk} disabled={!isModified}>
          Save
        </Button>
        <Button color="gray" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default SettingsModal;
