import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'flowbite-react';
import { useContext, useState } from 'react';
import DialogTextInput from '../controls/form/DialogTextInput';
import { RootContext } from '../../stores/storeProvidor';
import AdminModalValidationBox from '../admin/controls/AdminModalValidationBox';
import { ContactUser, Organisation } from '../../models/orgModels';
import DialogSelect from '../controls/form/DialogSelect';
import IconCross from '../icons/cross';

const EditOrgModal = NiceModal.create(({ org }: { org: Organisation }) => {
  const modal = useModal();
  const rctx = useContext(RootContext);
  const adminStore = rctx.getAdminStore();
  const orgUsers = adminStore.getAllUsersForOrg(org.id, true);
  const [storedOrg, setStoredOrg] = useState<Organisation>({ ...org });
  const [contactIds, setContactIds] = useState<string[]>(org.contacts.map((c) => c.userId));
  const [activeContactId, setActiveContactId] = useState<string | undefined>(orgUsers[0]?.id);
  const [validationMsg, setValidationMsg] = useState<string[]>([]);

  const contactSelect = orgUsers
    .sort((a, b) =>
      `${a.firstName} ${a.lastName}`.toLowerCase().localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase())
    )
    .map((u) => `${u.id}|${u.firstName} ${u.lastName} ${u.isActive ? '' : '(Inactive)'}`);

  const hasError = (id: string) => validationMsg.some((msg) => msg.startsWith(id));
  const disableSave = () => validationMsg.length > 1 || (validationMsg.length === 1 && !hasError('err'));

  const onConfirm = () => {
    // Validate
    const msg: string[] = [];
    if (storedOrg.name === '') msg.push('username|User Name is required');
    if (storedOrg.description === '') msg.push('firstname|First Name is required');
    if (contactIds.length === 0 && orgUsers.length > 0) msg.push('contact|At least one contact is required');
    setValidationMsg(msg);
    if (msg.length !== 0) {
      return;
    }

    const modOrg = {
      ...storedOrg,
      contacts: contactIds.map((cid) => {
        const usr = orgUsers.filter((u) => u.id === cid)[0];
        return {
          userId: usr.id,
          firstName: usr.firstName,
          lastName: usr.lastName,
          email: usr.email,
          phone: usr.phone,
          sortOrder: 0, // TODO - need to add this to the UI
          isActive: usr.isActive,
        } as ContactUser;
      }),
    } as Organisation;

    // Call the API to create the user
    adminStore.updateOrg([modOrg]).then(
      () => {
        modal.remove();
      },
      (e) => {
        setValidationMsg([`err|${e.message}`]);
      }
    );
  };

  const onCancel = () => {
    modal.remove();
  };

  const addContact = (userId: string | undefined) => {
    if (userId !== undefined && !contactIds.includes(userId)) {
      setContactIds([...contactIds, userId]);
      setValidationMsg([]);
    }
  };

  const removeContact = (userId: string) => {
    if (contactIds.includes(userId)) {
      setContactIds(contactIds.filter((id) => id !== userId));
      setValidationMsg([]);
    }
  };

  return (
    <Modal show onClose={onCancel} size="md" popup>
      <Modal.Body>
        <h3 className="pt-6 pb-2">Edit Organisation: {storedOrg.name}</h3>
        <DialogTextInput
          id="name"
          label="Organisation Name"
          defaultValue={storedOrg.name}
          onEdit={(v) => setStoredOrg({ ...storedOrg, name: v })}
          hasError={hasError('name')}
        />
        <DialogTextInput
          id="description"
          label="Description"
          defaultValue={storedOrg.description}
          onEdit={(v) => setStoredOrg({ ...storedOrg, description: v })}
          hasError={hasError('description')}
        />

        <div className="block text-sm font-medium text-gray-900">Contact List</div>
        <div className="w-full h-64 overflow-x-hidden rounded-lg border border-gray-300">
          {contactIds.map((cid) => {
            const usr = orgUsers.filter((u) => u.id === cid)[0];
            return (
              <div key={cid} className="w-full text-sm p-1">
                <button type="button" onClick={() => removeContact(usr.id)}>
                  <IconCross className="w-6 inline mr-2 text-gray-400" />
                </button>
                <span className="font-bold">
                  {usr?.firstName} {usr?.lastName} {usr?.isActive ? '' : '(Inactive)'}
                </span>
              </div>
            );
          })}
        </div>

        <div className="flex">
          <DialogSelect
            id="contacts"
            label="Add a new Contact"
            options={contactSelect}
            selectedOption={activeContactId}
            defaultOption=""
            onSelect={(id, val) => {
              setActiveContactId(val.split('|')[0]);
            }}
            disabled={false}
          />
          <button
            type="button"
            className="bg-green-300 rounded-lg text-center h-9 px-2 ml-2 mt-5 text-xs"
            onClick={() => addContact(activeContactId)}
          >
            Add As Contact
          </button>
        </div>

        <AdminModalValidationBox msgs={validationMsg} />

        <div className="flex justify-center gap-4 mt-4">
          <Button onClick={onConfirm} disabled={disableSave()}>
            Save
          </Button>
          <Button color="gray" onClick={onCancel}>
            Discard
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default EditOrgModal;
