interface SelectWithLabelProps {
  id: string;
  label: string;
  selected: string;
  data: string[][];
  onChange: (value: string) => void;
}

export default function SelectWithLabel({ id, label, selected, data, onChange }: SelectWithLabelProps) {
  return (
    <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {label}
      <select
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        value={selected}
        onChange={(e) => onChange(e.target.value)}
      >
        {data.map((o, i) => {
          const v = o.length > 0 ? o[0] : i.toString();
          const t = o.length > 1 ? o[1] : v;
          return (
            <option value={v} key={v}>
              {t}
            </option>
          );
        })}
      </select>
    </label>
  );
}
