import { ReactNode } from 'react';
import { TopMenuGridFiller } from '../controls/TopMenu';

interface AdminGridProps {
  children: ReactNode;
  className: string;
}

export default function AdminGrid4x6({ children, className }: AdminGridProps) {
  // Fill screen with grid; First row height must match <TopMenu>
  return (
    <div className={`w-screen h-screen grid grid-cols-4 grid-rows-[3rem_1fr_1fr_1fr_1fr_3rem] ${className}`}>
      <TopMenuGridFiller />
      {children}
    </div>
  );
}

// -----------------------------------------------------------------------------

export function AdminGrid4x6SingleColumn({ children, className }: AdminGridProps) {
  return <div className={`row-start-2 row-end-7 col-span-1 ${className}`}>{children}</div>;
}

// -----------------------------------------------------------------------------

export function AdminGrid4x6DoubleColumn({ children, className }: AdminGridProps) {
  return <div className={`row-start-2 row-end-7 col-span-2 ${className}`}>{children}</div>;
}

// -----------------------------------------------------------------------------

export function AdminGrid4x6TripleColumn({ children, className }: AdminGridProps) {
  return <div className={`row-start-2 row-end-7 col-span-3 ${className}`}>{children}</div>;
}
