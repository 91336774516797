import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useCallback, useContext, useMemo } from 'react';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { observer } from 'mobx-react-lite';
import PreviewGrid from './PreviewGrid';
import AvailableBreakoutList from './AvailableBreakoutList';
import { BreakoutItem } from './BreakoutItem';
import { RootContext } from '../../../../stores/storeProvidor';
import useLayoutDragging from '../../../../hooks/useLayoutDragging';
import { LayoutItemStorage, isItemBeingUsed, SOURCE_GROUP } from '../../../../utils/dragUtils';
import { BreakoutObj, createUnknownBreakout } from '../../../../models/breakoutModels';

// -----------------------------------------------------------------

export interface LayoutPreviewProps {
  reportId: string | undefined;
  onLayoutUpdated: (issues: string[]) => void;
}

const PreviewLayout = observer(({ reportId, onLayoutUpdated }: LayoutPreviewProps) => {
  const { activeReportStore, selectionStore, layoutDragStore, activeUserStore } = useContext(RootContext);
  const schemaId = activeUserStore.activeSchemaId;
  const allBreakouts = useMemo(() => {
    const all = selectionStore.getBreakoutsByType(schemaId);
    return all;
  }, [selectionStore, schemaId]);

  // Drag items held in common store
  const itemGroups = layoutDragStore.getReportLayoutDragItems(reportId, allBreakouts);
  const setItemGroups = useCallback(
    (reportKey: string | undefined, updatedStore: LayoutItemStorage<BreakoutObj>): void => {
      if (reportKey) {
        // Always save drag state
        layoutDragStore.updateReportLayoutDragItems(reportKey, updatedStore);
        activeReportStore.saveReportLayout(reportKey, updatedStore);

        const msgs = layoutDragStore.getReportLayoutValidationMessages(reportKey);
        onLayoutUpdated(msgs);
      }
    },
    [layoutDragStore, activeReportStore, onLayoutUpdated]
  );

  const { handleDragStart, handleDragCancel, handleDragOver, handleDragEnd, getActiveDragObj, removeObjById } =
    useLayoutDragging(reportId, itemGroups, setItemGroups, createUnknownBreakout, false);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const dragObject = getActiveDragObj();

  if (reportId === undefined) return null;

  return (
    <div className="flex flex_row h-full bg-gray-100">
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <AvailableBreakoutList
          collectionId={SOURCE_GROUP}
          className="basis-1/4 bg-white mx-1 border border-gray-200"
          availBreakouts={itemGroups.sourceGroup}
          active={dragObject}
          isIdUsed={(id: string) => isItemBeingUsed(itemGroups, id)}
        />
        <PreviewGrid
          className="basis-3/4 mr-1 bg-white border border-gray-200"
          itemStore={itemGroups}
          active={dragObject}
          removeBreakout={removeObjById}
        />
        <DragOverlay>{dragObject ? <BreakoutItem obj={dragObject} /> : null}</DragOverlay>
      </DndContext>
    </div>
  );
});

export default PreviewLayout;
