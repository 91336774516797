import { ItemWithId } from '../utils/dragUtils';

export const DATATYPE_BREAKOUT_ID = 'dts';

export enum BreakoutType {
  Media,
  PeriodAbsolute,
  PeriodGeneric,
  Dictionary,
  Datatypes,
  Meta,
  UnknownType,
}

export interface BreakoutObj extends ItemWithId {
  name: string;
  description: string;
  groupName: string;
  type: BreakoutType;
}

export interface BreakoutGroups {
  groupName: string;
  groupType: string;
  breakouts: BreakoutObj[];
}

export function createUnknownBreakout(id: string): BreakoutObj {
  return {
    id, // Maintain id - when we save keep original id...
    groupName: '?',
    type: BreakoutType.UnknownType,
    name: `Unknown: ${id}`,
    description: `Object "${id}" does not exist for this schema`,
  } as BreakoutObj;
}
