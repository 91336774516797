// import { ResponsivePie } from '@nivo/pie';

// WHY DOES THIS FAIL? - @nivo/pie is causing a build fail
// TODO - enabling the import above causes a recursive stack fail in the TypeScript build
// Un comment import above. Run: npm run build
// It does work if you run: npm run dev

export type PieDataItem = {
  name: string;
  value: number;
};
type PieChartProps = {
  data: PieDataItem[];
};

function PieChart({ data }: PieChartProps) {
  const data2 = [
    {
      id: 'make',
      label: 'make',
      value: 232,
      color: 'hsl(162, 70%, 50%)',
    },
    {
      id: 'elixir',
      label: 'elixir',
      value: 291,
      color: 'hsl(104, 70%, 50%)',
    },
    {
      id: 'stylus',
      label: 'stylus',
      value: 239,
      color: 'hsl(190, 70%, 50%)',
    },
    {
      id: 'python',
      label: 'python',
      value: 218,
      color: 'hsl(334, 70%, 50%)',
    },
    {
      id: 'lisp',
      label: 'lisp',
      value: 11,
      color: 'hsl(154, 70%, 50%)',
    },
  ];

  /*
  return (
  <ResponsivePie
    data={data2}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 0.2]],
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: 'color',
      modifiers: [['darker', 2]],
    }}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: 'ruby',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'c',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'go',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'python',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'scala',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'lisp',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'elixir',
        },
        id: 'lines',
      },
      {
        match: {
          id: 'javascript',
        },
        id: 'lines',
      },
    ]}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000',
            },
          },
        ],
      },
    ]}
  />
  ); */
  return null;
}

export default PieChart;
