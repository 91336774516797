import { PropsWithChildren } from 'react';
import TagList from './TagList';

type MruListProps = {
  title: string;
};

export function MruList({ title, children }: PropsWithChildren<MruListProps>) {
  return (
    <>
      <h5 className="text-xl font-bold tracking-tight text-gray-900">{title}</h5>
      <ul className="border w-full rounded-lg divide-y">{children}</ul>
    </>
  );
}

// ------------------------------------------------------

type MruEntryProps = {
  index: number;
  name: string;
  tags: string[];
  clickAction: () => void;
};

export function MruEntry({ index, name, tags, clickAction }: MruEntryProps) {
  return (
    <li className="px-2 py-2 hover:bg-green-100">
      <button type="button" onClick={clickAction} className="flex flex-wrap w-full">
        <div className="basis-1/12 font-semibold px-1">{index + 1}.</div>
        <div className="basis-11/12 text-left">{name}</div>
        <div className="basis-full h-0" />
        <div className="ml-auto">
          <TagList tags={tags} moreLimit={2} />
        </div>
      </button>
    </li>
  );
}
