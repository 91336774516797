import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { SelectionNode, SelectionNodeType } from '../../../../../models/selectionModels';
import { baseIdToSourceGroupId, newNode } from '../../../../../utils/selectionTreeUtils';
import SelectAcordianDragHandle from '../SelectAcordianDragHandle';
import { SelectionTreeItemDragData } from '../../../../../hooks/useSelectionDragging';
import SelectAccordion from '../SelectAccordion';

interface PeriodPanelProps {
  title: string;
  hint: string;
  tag: string;
  position: number;
  stateKey: string;
  createNodeOnDropFn: (() => SelectionNode) | undefined;
}

const PeriodPanelPanel = observer(
  ({ title, hint, tag, position, stateKey, createNodeOnDropFn, children }: PropsWithChildren<PeriodPanelProps>) => {
    const dragHandle = (
      <SelectAcordianDragHandle
        id={baseIdToSourceGroupId(tag)}
        dragData={
          {
            parentNode: undefined,
            thisNode: newNode(SelectionNodeType.DateRange, title, tag), // A temp placeholder while we drag
            createNodeFn: createNodeOnDropFn,
          } as SelectionTreeItemDragData
        }
      />
    );

    return (
      <SelectAccordion.Panel
        key={tag}
        statekey={stateKey}
        title={title}
        hint={hint}
        id={tag}
        position={position}
        dragHandle={dragHandle}
      >
        <div className="h-full flex flex-col">{children}</div>
      </SelectAccordion.Panel>
    );
  }
);

export default PeriodPanelPanel;
