import { useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import SearchBar from '../../controls/SearchBar';
import SearchList from '../../controls/SearchList';
import { RootContext } from '../../../stores/storeProvidor';

const ReportList = observer(() => {
  const { availReportStore, uiState } = useContext(RootContext);

  // TODO: Move to stateStore?
  const filteredItems = useMemo(() => {
    const lcFilter = uiState.selectedListFilter.toLowerCase();
    return availReportStore.availableReports.filter((o) => {
      if (lcFilter.length === 0) return true;
      if (o.name.toLowerCase().indexOf(lcFilter) >= 0) return true;
      if (o.tags.join(' ').toLowerCase().indexOf(lcFilter) >= 0) return true;
      return false;
    });
  }, [uiState.selectedListFilter, availReportStore.availableReports]);

  const setFilterText = useCallback(
    (filter: string) => {
      uiState.setSelectedListFilter(filter);
    },
    [uiState]
  );

  const setSeletedIds = useCallback(
    (ids: string[]) => {
      uiState.setSelectedListReportIds(ids);
    },
    [uiState]
  );

  return (
    <div className="flex flex-col w-full h-full bg-slate-100 px-2">
      <h1 className="flex-0 text-slate-500 text-center text-xl py-1">Reports</h1>
      <SearchBar searchText={uiState.selectedListFilter} setsearchText={setFilterText} className="flex-0" />
      <SearchList
        parentClass="flex-1"
        items={filteredItems}
        selections={uiState.selectedListReportIds}
        updateSelections={setSeletedIds}
      />
    </div>
  );
});

export default ReportList;
