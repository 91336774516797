/* eslint-disable no-console */
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useContext, useEffect, useState } from 'react';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import AppGrid4x6 from '../../app/AppGrid';
import { RootContext } from '../../../stores/storeProvidor';
import {
  ItemWithId,
  LayoutItemStorage,
  baseId,
  isItemBeingUsed,
  getNewItemWithGroupInId,
  SOURCE_GROUP,
} from '../../../utils/dragUtils';
import { Droppable, Sortable } from '../../controls/dnd';
import useLayoutDragging from '../../../hooks/useLayoutDragging';

// --------------------------------------------------------------

interface DndItem extends ItemWithId {
  name: string;
  colour: string;
}

// --------------------------------------------------------------

function Item({ obj, isUsed, dragOverlay }: { obj: DndItem | undefined; isUsed: boolean; dragOverlay: boolean }) {
  const style = {
    cursor: dragOverlay ? 'grabbing' : 'grab',
  };

  const usedStyle = isUsed ? 'opacity-75 saturate-0' : '';

  if (!obj) return <div>Unknown Item</div>;

  return (
    <div
      style={style}
      className={`border border-gray-800 basis-6 m-0.5 p-0.5 rounded text-xs ${obj.colour} ${usedStyle}`}
    >
      <div>
        Name: <b>{obj.name}</b>
      </div>
      <div>
        Base Id: <b>{baseId(obj)}</b>
      </div>
      <div>
        Id: <b>{obj.id}</b>
      </div>
    </div>
  );
}

// --------------------------------------------------------------

function DroppableList({
  collectionId,
  items,
  isIdUsed,
}: {
  collectionId: string;
  items: DndItem[];
  isIdUsed: ((id: string) => boolean) | undefined;
}) {
  return (
    <SortableContext id={collectionId} items={items.map((x) => x.id)} strategy={rectSortingStrategy}>
      <Droppable
        id={collectionId}
        renderItem={(ref, children) => (
          <ul className="border border-gray-900 py-2 px-6 rounded-xl bg-white h-64" ref={ref}>
            {children}
          </ul>
        )}
      >
        {items.map((item) => (
          <Sortable
            key={item.id}
            id={item.id}
            useOverlay={false}
            renderItem={(ref, sortChildren, style, listeners, attributes) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li ref={ref} style={style} {...listeners} {...attributes} className="mx-auto w-32">
                {sortChildren}
              </li>
            )}
          >
            <Item obj={item} isUsed={isIdUsed ? isIdUsed(item.id) : false} dragOverlay={false} />
          </Sortable>
        ))}
      </Droppable>
    </SortableContext>
  );
}

// --------------------------------------------------------------

const sourceItems = [
  { id: 'a', name: 'AAA', colour: 'bg-green-300' },
  { id: 'b', name: 'BBB', colour: 'bg-yellow-300' },
  { id: 'c', name: 'CCC', colour: 'bg-blue-400' },
  { id: 'd', name: 'DDD', colour: 'bg-red-400' },
] as DndItem[];

// --------------------------------------------------------------

function DragChecks() {
  const { uiState } = useContext(RootContext);
  useEffect(() => {
    uiState.setAdminTitle('Drag Tests', ArrowRightOnRectangleIcon);
  }, [uiState]);

  const [itemGroups, setItemGroups] = useState<LayoutItemStorage<DndItem>>({
    sourceGroup: sourceItems.map((o) => getNewItemWithGroupInId(o, SOURCE_GROUP)),
    pagesGroup: [],
    rowsGroup: [],
    colsGroup: [],
  });

  const { handleDragStart, handleDragCancel, handleDragOver, handleDragEnd, getActiveDragObj } = useLayoutDragging(
    'demo',
    itemGroups,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (instance: string | undefined, store: LayoutItemStorage<DndItem>) => {
      setItemGroups(store);
    },
    (id: string) => ({ id, name: '???', colour: 'bg-black' } as DndItem),
    true
  );

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const activeDragObj = getActiveDragObj();

  return (
    <AppGrid4x6 className="select-none">
      <div className="col-span-4 row-start-2 row-end-5 m-2 p-4 bg-orange-400 rounded-xl">
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className="flex">
            {Object.keys(itemGroups).map((group) => (
              <div className="mx-6 w-1/4" key={group}>
                <h1>{group}</h1>
                <DroppableList
                  collectionId={group}
                  items={itemGroups[group as keyof LayoutItemStorage<DndItem>]}
                  isIdUsed={group === SOURCE_GROUP ? (id: string) => isItemBeingUsed(itemGroups, id) : undefined}
                />
              </div>
            ))}
          </div>
          <DragOverlay>{activeDragObj ? <Item obj={activeDragObj} dragOverlay isUsed={false} /> : null}</DragOverlay>
        </DndContext>
      </div>
      <div className="col-span-4 row-start-5 row-end-7 m-2 p-4 bg-pink-900 text-pink-100 rounded-xl text-xs">
        <h1>Expected Drag Behaviour</h1>
        Checks:
        <ul className="px-6 list-decimal">
          <li className="hover:text-xl">
            <i>sourceGroup</i> should never be changed. It should always revert to original state.
          </li>
          <li className="hover:text-xl">
            Drag from <i>sourceGroup</i> will create copy in other groups.
          </li>
          <li className="hover:text-xl">
            Drag from <i>sourceGroup</i> should only add to destination group that we <b>drop</b> over. And no others
            (but expect other groups to update on drag)
          </li>
          <li className="hover:text-xl">Each group can only ever contain an item once</li>
          <li className="hover:text-xl">Drag away from groups will remove item</li>
          <li className="hover:text-xl">Drag within group to re-order</li>
          <li className="hover:text-xl">
            Drag between groups is allowed (not <b>to</b> <i>sourceGroup</i> though - see Rule 1. above)
          </li>
        </ul>
      </div>
    </AppGrid4x6>
  );
}

export default DragChecks;
